import { createContext, FC, PropsWithChildren, useContext } from "react";
import { FeatureFlag, FeatureFlags } from "@gemini-ui/constants/templateProps/featureFlags";

export const FeatureFlagContext = createContext<FeatureFlags>(null);

export const FeatureFlagProvider: FC<PropsWithChildren<{ featureFlags: FeatureFlags }>> = ({
  featureFlags,
  children,
}) => {
  if (featureFlags === null || typeof featureFlags !== "object") {
    console.error("The featureFlags prop must be an object.");
  }
  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

// Custom Hook API
export function useFeatureFlag(name: FeatureFlag | FeatureFlag[]): boolean {
  const flags = useContext(FeatureFlagContext);
  if (flags === null) {
    throw new Error(
      "You must wrap your components in a FeatureFlagProvider. Make sure window.initialData.templateProps.featureFlags exists."
    );
  }

  return Array.isArray(name) ? name.every(flag => flags[flag]) : flags[name];
}

export function useFeatureFlags(): FeatureFlags {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error("useFeatureFlags must be used within a FeatureFlagProvider");
  }
  return context;
}
