import { useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { WireTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankAccountType } from "@gemini-ui/constants/paymentMethods";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { usePageRefresh } from "@gemini-ui/contexts";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import AddWireDetailsModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/AddWireDetailsModal";
import CompleteDepositModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/CompleteDepositModal";
import ConfirmWireDetailsModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/ConfirmWireDetailsModal";
import { isBankFrickEnabledForUK } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/utils/isBankFrickEnabledForUK";
import { WireInstructionsModal } from "@gemini-ui/pages/settings/BankSettings/components/WireInstructionsModal";

type Props = {
  currency: CurrencyShortNameFiat;
  geminiEntity: GeminiEntity;
  initialWireFormValues?: Partial<AddWireFormData>;
  isInstitutional: boolean;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  subaccountHashid: string;
  isSettingsOrOnboarding?: boolean;
  scope?: PaymentTypeScope;
};

enum FlowState {
  ADD_WIRE_DETAILS,
  CONFIRM_WIRE_DETAILS,
  COMPLETE_DEPOSIT,
  SHOW_DEPOSIT_INSTRUCTIONS,
}

export interface AddWireFormData {
  accountNumber: string;
  accountType: BankAccountType;
  bankName: string;
  bic: string;
  iban: string;
  institutionNumber: string;
  nameOnAccount: string;
  sortCode: string;
  swiftOrRoutingNumber: string;
  transitNumber: string;
}

const DefaultAddWireFlow = ({
  currency,
  isOpen,
  onBack,
  onClose,
  isInstitutional,
  geminiEntity,
  initialWireFormValues,
  subaccountHashid,
  isSettingsOrOnboarding,
  scope,
}: Props) => {
  const [flowState, setFlowState] = useState(FlowState.ADD_WIRE_DETAILS);
  const [customerBankName, setCustomerBankName] = useState("");
  const [wireDetails, setWireDetails] = useState<WireTransferInfoAPIPayload | null>(null);
  const [userInputWireDetails, setUserInputWireDetails] = useState(initialWireFormValues || {});
  const { requestRefresh } = usePageRefresh();
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const isBankFrickFlowEnabled = isBankFrickEnabledForUK(currency);

  const handleConfirmWireDetails = ({ customerBankName, wireDepositDetails }) => {
    setCustomerBankName(customerBankName);
    setWireDetails(wireDepositDetails);
    setFlowState(FlowState.COMPLETE_DEPOSIT);
    requestRefresh();
  };

  const handleAddWireDetails = values => {
    setUserInputWireDetails(values);
    setFlowState(FlowState.CONFIRM_WIRE_DETAILS);
  };

  if (flowState === FlowState.ADD_WIRE_DETAILS) {
    return (
      <AddWireDetailsModal
        currency={currency}
        defaultValues={userInputWireDetails}
        isOpen={isOpen}
        onBack={onBack}
        onClose={onClose}
        onSubmit={handleAddWireDetails}
      />
    );
  }

  if (flowState === FlowState.CONFIRM_WIRE_DETAILS) {
    return (
      <ConfirmWireDetailsModal
        currency={currency}
        isInstitutional={isInstitutional}
        isOpen={isOpen}
        onBack={() => setFlowState(FlowState.ADD_WIRE_DETAILS)}
        onClose={onClose}
        onSubmit={handleConfirmWireDetails}
        wireDetails={userInputWireDetails}
        subaccountHashid={subaccountHashid}
      />
    );
  }
  if (flowState === FlowState.COMPLETE_DEPOSIT) {
    return isNewAddPaymentsFlowEnabled || isBankFrickFlowEnabled ? (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={onClose}
        paymentMethodType={AddPaymentMethodType.WIRE}
        showDepositInstructions={() => setFlowState(FlowState.SHOW_DEPOSIT_INSTRUCTIONS)}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
      />
    ) : (
      <CompleteDepositModal
        currency={currency}
        customerBankName={customerBankName}
        geminiEntity={geminiEntity}
        isOpen={isOpen}
        onClose={onClose}
        wireDetails={wireDetails}
        subaccountHashid={subaccountHashid}
      />
    );
  }
  if (flowState === FlowState.SHOW_DEPOSIT_INSTRUCTIONS) {
    return (
      <WireInstructionsModal
        currency={currency}
        onClose={onClose}
        isOpen={isOpen}
        subaccountHashid={subaccountHashid}
      />
    );
  }
};

export default DefaultAddWireFlow;
