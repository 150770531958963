import { ReactNode, useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Button, Flex, Modal, Text } from "@gemini-ui/design-system";
import { ReactComponent as WireIcon } from "@gemini-ui/images/icons/wire.svg";
import { SuccessTitle } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/styles";
import ContactSupportLink from "@gemini-ui/pages/settings/DocumentSettings/ContactSupportLink";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onClose: () => void;
  currency: CurrencyShortNameFiat;
}

export const MaximumAccountsModal = ({ onClose, currency }: Props) => {
  const { intl } = useIntl();

  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  const title = (
    <Flex.Column align="center">
      <WireIcon />
      <SuccessTitle>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Sorry, you can only register ten {currency} bank accounts.",
          }),
          {
            currency,
          }
        )}
      </SuccessTitle>
    </Flex.Column>
  );

  return (
    <Modal title={title} isOpen={showModal} shouldCloseOnOverlayClick onClose={closeModal} centered>
      <Text.Body size="sm">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "You are only allowed to register ten {currency} bank accounts. To connect a new account, please <TextLink>contact support</TextLink> to delete one of your existing bank accounts. You can also buy crypto instantly by linking a debit card instead.",
          }),
          {
            currency,
            TextLink: (str: ReactNode) => <ContactSupportLink textToDisplay={str} />,
          }
        )}
      </Text.Body>
      <Button.Group type="centered">
        <Button.Primary
          size="lg"
          data-testid="maximum-xfers-accounts-dismiss"
          onClick={closeModal}
          cta={intl.formatMessage({
            defaultMessage: "Got it",
          })}
        />
      </Button.Group>
    </Modal>
  );
};
