import { Component } from "react";
import { scaleLinear } from "d3-scale";
import { line } from "d3-shape";
import _ from "lodash";
import { SparklineStyle } from "@gemini-ui/components/Sparkline/styles";

interface Props {
  circleRadius: number;
  dataPoints: string[];
  height: number;
  width: number;
  color: string | null;
  withCircle: boolean;
  strokeWidth?: number;
}

class Sparkline extends Component<Props> {
  static defaultProps = {
    circleRadius: 3,
    color: null,
    dataPoints: [],
    height: 11,
    width: 40,
    withCircle: false,
    strokeWidth: 1,
  };

  render() {
    const { dataPoints, width, height, circleRadius, color, withCircle, strokeWidth } = this.props;
    const data = dataPoints.map(parseFloat);
    // Calculate the space we'll need to save at the end of the sparkline to fit a circle (diameter + 1px for border)
    const circleBuffer = circleRadius * 2 + 1;
    // Create functions to scale our x/y coordinates to fit the space we have for the graph
    const x = scaleLinear()
      .range([0, width - circleBuffer])
      .domain([0, data.length - 1]);
    const y = scaleLinear()
      .range([height - circleBuffer, 0])
      .domain([_.min(data), _.max(data)]);

    const sparkline = line<any>()
      .x((d, i) => x(i))
      .y(d => y(d));

    return (
      <SparklineStyle color={color} strokeWidth={strokeWidth}>
        <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
          <g style={{ transform: `translate(0, ${circleBuffer / 2}px)` }}>
            <path d={sparkline(dataPoints)} />
            {withCircle && <circle cx={width - circleBuffer / 2} cy={y(_.last(data))} r={`${circleRadius}px`} />}
          </g>
        </svg>
      </SparklineStyle>
    );
  }
}

export default Sparkline;
