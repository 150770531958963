import { ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { Formik } from "formik";
import * as Yup from "yup";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Refresher } from "@gemini-ui/constants/refresher";
import { Button, Modal, Spacer, Text, useToaster } from "@gemini-ui/design-system";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { trackLinkedFundingMethodSuccess } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import { WORLDPAY_NETWORK_ERROR_MSG } from "@gemini-ui/pages/settings/BankSettings/constants";
import { TwoColumnInputContainer } from "@gemini-ui/pages/settings/BankSettings/debit/styles";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios from "@gemini-ui/services/axios";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const DEBIT_CARD_ADDED_MSG =
  "Your debit card has been successfully verified and can now be used to purchase crypto. Microtransactions will be removed from your bank account.";
const MICRODEPOSIT_AMOUNT_ERROR_MSG = "Amount must be between 1.01 and 1.99.";
const MIN_MICRODEPOSIT = "1.01";
const MAX_MICRODEPOSIT = "1.99";

const isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);

interface VerifyYourCardModalProps {
  onToggle: ToggleDebitCardModal;
  onComplete: () => void;
  uuid?: string;
  selectedCurrency: CurrencyShortNameFiat;
  settingsFlow?: boolean;
  requestRefresh: Refresher["requestRefresh"];
  maxMicroDeposit: MoneyProps<CurrencyShortNameFiat>;
  minMicroDeposit: MoneyProps<CurrencyShortNameFiat>;
  openDebitCardErrorModal?: (debitCardErrorHeading: string, debitCardErrorMessage?: string) => void;
}

export default function VerifyYourCardModal({
  onToggle,
  onComplete,
  selectedCurrency,
  minMicroDeposit,
  maxMicroDeposit,
  uuid,
  settingsFlow,
  requestRefresh,
  openDebitCardErrorModal,
}: VerifyYourCardModalProps) {
  const { intl } = useIntl();
  const { showAlert } = useAlert();
  const { showToast } = useToaster();
  const leadingSymbol = CURRENCIES_DETAIL[selectedCurrency]?.leadingSymbol || "$";

  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  return (
    <Modal.MultiStep
      isOpen
      title={intl.formatMessage({
        defaultMessage: "Verify card",
      })}
      onClose={() => {
        onToggle("verifyCardStepTwoVisible", true)();
        requestRefresh();
      }}
      onBack={onToggle("transactionsSentVisible")}
    >
      {!minMicroDeposit || !maxMicroDeposit ? (
        <Text.Body size="sm">
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Enter the amounts of the 2 transactions from Gemini on your card. <HelpCenterLinkArticlesAddDebitCard>FAQ</HelpCenterLinkArticlesAddDebitCard>",
            }),
            {
              HelpCenterLinkArticlesAddDebitCard: (v: ReactNode) => (
                <HelpCenterLink article={Articles.ADD_DEBIT_CARD}>{v}</HelpCenterLink>
              ),
            }
          )}
        </Text.Body>
      ) : (
        <Text.Body size="sm">
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Enter the amounts of the 2 transactions from Gemini on your card that are between <MoneyMinMicroDeposit></MoneyMinMicroDeposit> and <MoneyMaxMicroDeposit></MoneyMaxMicroDeposit>. <HelpCenterLinkArticlesAddDebitCard>FAQ</HelpCenterLinkArticlesAddDebitCard>",
            }),
            {
              MoneyMinMicroDeposit: () => <Money {...minMicroDeposit} />,
              MoneyMaxMicroDeposit: () => <Money {...maxMicroDeposit} />,
              HelpCenterLinkArticlesAddDebitCard: (v: ReactNode) => (
                <HelpCenterLink article={Articles.ADD_DEBIT_CARD}>{v}</HelpCenterLink>
              ),
            }
          )}
        </Text.Body>
      )}
      <Formik
        initialValues={{ amount1: "", amount2: "" }}
        onSubmit={(values, { setSubmitting }) => {
          const { name, properties } = EVENTS.COMPLETE_MICRO_DEPOSIT;
          axios
            .post(jsRoutes.controllers.cards.CardsController.validateMicroDeposit(uuid).url, {
              firstCentoshi: Number(Number(values.amount1) * 100).toFixed(0),
              secondCentoshi: Number(Number(values.amount2) * 100).toFixed(0),
            })
            .then(res => {
              onToggle("verifyCardStepTwoVisible")();
              if (!isNewAddPaymentsFlowEnabled) {
                showToast({
                  message: DEBIT_CARD_ADDED_MSG,
                });
                requestRefresh();
              } else {
                onToggle("showDebitCardSuccessModal")();
              }
              track(name, { [properties.SUBMITTED_SUCCESSFULLY]: true });
              trackLinkedFundingMethodSuccess(PaymentMethodType.DEBIT_CARD);
              if (!settingsFlow) {
                onComplete();
              }
            })
            .catch(err => {
              setSubmitting(false);
              track(name, { [properties.SUBMITTED_SUCCESSFULLY]: false });
              if (err.response?.data?.form?.errors?.errorType[0] === "MicroDepositMaxAttempts") {
                onToggle("tooManyAttemptsVisible")();
                trackPaymentRegistrationFailure(LinkPaymentType.DEBIT, err.response?.data?.form?.errors?.errorType[0]);
              } else {
                const errorMessage = err.response?.data?.form?.errors?.error?.[0] || WORLDPAY_NETWORK_ERROR_MSG;

                if (isNewDebitCardFlowEnabled) {
                  openDebitCardErrorModal(errorMessage);
                } else {
                  showAlert({
                    type: AlertTypes.ERROR,
                    message: errorMessage,
                    timeout: 5000,
                  });
                }
                trackPaymentRegistrationFailure(LinkPaymentType.DEBIT, errorMessage);
              }
            });
        }}
        validationSchema={Yup.object().shape({
          amount1: Yup.number()
            .min(Number(minMicroDeposit?.value ?? MIN_MICRODEPOSIT), MICRODEPOSIT_AMOUNT_ERROR_MSG)
            .max(Number(maxMicroDeposit?.value ?? MAX_MICRODEPOSIT), MICRODEPOSIT_AMOUNT_ERROR_MSG)
            .required(
              intl.formatMessage({
                defaultMessage: "Required",
              })
            ),
          amount2: Yup.number()
            .min(Number(minMicroDeposit?.value ?? MIN_MICRODEPOSIT), MICRODEPOSIT_AMOUNT_ERROR_MSG)
            .max(Number(maxMicroDeposit?.value ?? MAX_MICRODEPOSIT), MICRODEPOSIT_AMOUNT_ERROR_MSG)
            .required(
              intl.formatMessage({
                defaultMessage: "Required",
              })
            ),
        })}
      >
        {formikBag => {
          const { values, touched, errors, isValid, isSubmitting, handleChange, handleBlur, handleSubmit } = formikBag;
          return (
            <form onSubmit={handleSubmit} css={{ marginBottom: 0 }}>
              <Spacer mt={3}>
                <TwoColumnInputContainer>
                  <Input.Amount
                    name="amount1"
                    value={values.amount1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount1 && errors.amount1}
                    currency={selectedCurrency}
                    alignText="right"
                    placeholder={`${leadingSymbol}0.00`}
                    data-testid="verify-card-modal-amount1"
                    step=".01"
                  />
                  <Input.Amount
                    name="amount2"
                    value={values.amount2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    message={touched.amount2 && errors.amount2}
                    currency={selectedCurrency}
                    alignText="right"
                    placeholder={`${leadingSymbol}0.00`}
                    data-testid="verify-card-modal-amount2"
                    step=".01"
                  />
                </TwoColumnInputContainer>

                <Button.Group type="stacked">
                  <Button.Primary
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    data-testid="verify-card-modal-btn"
                    display="block"
                    type="submit"
                    size="lg"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Submit",
                    })}
                  </Button.Primary>
                  <Button.Tertiary
                    size="lg"
                    pt={2}
                    onClick={onToggle("transactionsSentVisible")}
                    data-testid="back-to-transactions-modal-btn"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Having trouble finding the transactions?",
                    })}
                  </Button.Tertiary>
                </Button.Group>
              </Spacer>
            </form>
          );
        }}
      </Formik>
    </Modal.MultiStep>
  );
}
