import { Fragment } from "react";
import StatusIcon from "@gemini-ui/design-system/Timeline/StatusIcon";
import {
  Connector,
  ConnectorProgress,
  FadeInScaleIcon,
  IconTransition,
  NotStarted,
} from "@gemini-ui/design-system/Timeline/styles";
import { TimelineStepStatus } from "@gemini-ui/design-system/Timeline/types";
import animationStepStatus from "@gemini-ui/design-system/Timeline/utils/animationStepStatus";

interface StepIndicatorProps {
  animationStep: number;
  currentStepNumber: number;
  status: TimelineStepStatus;
  step: number;
  stepsCount: number;
}

function StepIndicator({ animationStep, currentStepNumber, status, step, stepsCount }: StepIndicatorProps) {
  const currentAnimationStatus = animationStepStatus(step, status, animationStep, currentStepNumber, stepsCount);

  return (
    <Fragment>
      <IconTransition>
        <NotStarted />
        <FadeInScaleIcon animate={animationStep >= step}>
          <StatusIcon status={currentAnimationStatus} />
        </FadeInScaleIcon>
      </IconTransition>
      {step !== stepsCount ? (
        <Connector testID={`step-indicator-connector-${step}`}>
          <ConnectorProgress stepComplete={animationStep >= step && step !== currentStepNumber} />
        </Connector>
      ) : null}
    </Fragment>
  );
}

export default StepIndicator;
