import { useMemo } from "react";
import { RegisterOptions } from "react-hook-form";
import * as Yup from "yup";
import passwordValidation from "@gemini-ui/components/Form/utils/passwordValidation";
import { FormValues } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import {
  aptPattern,
  dobPattern,
  firstNamePattern,
  getYearDiff,
  lastNamePattern,
  phonePattern,
  pmBoxPattern,
  poBoxPattern,
  ssnPattern,
  streetPattern,
  zipPattern,
} from "@gemini-ui/pages/Credit/CreditApplication/utils";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

export const getValidationCopy = (intl: IntlShape) => ({
  REQUIRED: intl.formatMessage({ defaultMessage: "Required" }),
  SPECIAL_CHARS: intl.formatMessage({ defaultMessage: "Numbers and special characters not accepted" }),
  PO_BOX_OR_PMB_NOT_ACCEPTED: intl.formatMessage({ defaultMessage: "PO Box or PMB addresses not accepted" }),
  FORTY_CHAR_LIMIT: intl.formatMessage({ defaultMessage: "40 character limit" }),
  INVALID_SSN: intl.formatMessage({ defaultMessage: "Invalid SSN format" }),
});

export const useFormValidation = (): Partial<Record<keyof FormValues, RegisterOptions>> => {
  const { intl } = useIntl();
  const copy = getValidationCopy(intl);

  return useMemo(() => {
    return {
      firstName: {
        required: copy.REQUIRED,
        pattern: { value: firstNamePattern, message: copy.SPECIAL_CHARS },
        minLength: {
          value: 2,
          message: intl.formatMessage({ defaultMessage: "First name must be at least 2 characters" }),
        },
      },
      lastName: {
        required: copy.REQUIRED,
        pattern: { value: lastNamePattern, message: copy.SPECIAL_CHARS },
        minLength: {
          value: 2,
          message: intl.formatMessage({ defaultMessage: "Last name must be at least 2 characters" }),
        },
      },
      middleName: {
        pattern: { value: firstNamePattern, message: copy.SPECIAL_CHARS },
        minLength: {
          value: 2,
          message: intl.formatMessage({ defaultMessage: "Middle name must be at least 2 characters" }),
        },
      },
      email: {
        required: copy.REQUIRED,
        validate: email => {
          return Yup.object()
            .shape({
              email: Yup.string().email(),
            })
            .isValid({ email })
            .then(valid => {
              if (!valid) {
                return intl.formatMessage({ defaultMessage: "Please enter a valid email" });
              }
            });
        },
      },
      phone: {
        required: copy.REQUIRED,
        pattern: {
          value: phonePattern,
          message: intl.formatMessage({ defaultMessage: "Please enter a valid phone number" }),
        },
      },
      password: {
        required: copy.REQUIRED,
        validate: value => {
          if (passwordValidation.message(value, intl).length > 0) {
            return passwordValidation.message(value, intl)[0];
          }

          return true;
        },
      },
      newAppTerms: {
        validate: value => {
          if (!value) {
            return intl.formatMessage({ defaultMessage: "You must agree to the terms to continue" });
          }
        },
      },
      street: {
        required: copy.REQUIRED,
        pattern: {
          value: streetPattern,
          message: intl.formatMessage({ defaultMessage: "Special characters not accepted" }),
        },
        maxLength: { value: 40, message: copy.FORTY_CHAR_LIMIT },
        validate: value => {
          if (poBoxPattern.test(value) || pmBoxPattern.test(value)) {
            return copy.PO_BOX_OR_PMB_NOT_ACCEPTED;
          }
        },
      },
      apt: {
        pattern: {
          value: aptPattern,
          message: intl.formatMessage({ defaultMessage: "Only # and / special characters accepted" }),
        },
        maxLength: { value: 40, message: copy.FORTY_CHAR_LIMIT },
        validate: value => {
          if (poBoxPattern.test(value) || pmBoxPattern.test(value)) {
            return copy.PO_BOX_OR_PMB_NOT_ACCEPTED;
          }
        },
      },
      city: {
        required: copy.REQUIRED,
      },
      state: {
        required: copy.REQUIRED,
      },
      zip: {
        required: copy.REQUIRED,
        pattern: {
          value: zipPattern,
          message: intl.formatMessage({ defaultMessage: "Invalid zip code" }),
        },
        maxLength: { value: 5, message: intl.formatMessage({ defaultMessage: "Must be exactly 5 digits" }) },
      },
      ssn: {
        required: copy.REQUIRED,
        validate: value => {
          if (!RegExp(ssnPattern).test(value.replaceAll("-", ""))) {
            return copy.INVALID_SSN;
          }
        },
      },
      ssnLastFour: {
        required: copy.REQUIRED,
      },
      dob: {
        required: copy.REQUIRED,
        pattern: { value: dobPattern, message: intl.formatMessage({ defaultMessage: "Invalid format (MM/DD/YYYY)" }) },
        validate: value => {
          const minAge = 18;
          const maxAge = 119;

          if (getYearDiff(value) < minAge) {
            return intl.formatMessage(defineMessage({ defaultMessage: "You must be at least {minAge} years old" }), {
              minAge,
            });
          }

          if (getYearDiff(value) >= maxAge || isNaN(getYearDiff(value))) {
            return intl.formatMessage({ defaultMessage: "DOB invalid" });
          }
        },
      },
    };
  }, [copy, intl]);
};
