import { Fragment } from "react";
import { IconArrowsHorizontal } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import { NavIcon, NavIconLabel } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import {
  TRANSFER_BUTTON_ID,
  TransferButtonProps,
} from "@gemini-ui/components/Header/navigation/TransferButton/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { HubbleButton } from "@gemini-ui/design-system";
import { ReactComponent as TransferIcon } from "@gemini-ui/images/icons/nav/transfer.svg";
import { useIntl } from "@gemini-ui/utils/intl";

const TransferButton = ({ onClick }: TransferButtonProps) => {
  const { isModalOpen } = useGlobalModal();
  const { intl } = useIntl();
  const isTransferModalOpen = isModalOpen(GlobalModalType.PerpsOnboardingTransferFormModal);

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  return (
    <Fragment>
      {isHeaderV2Enabled ? (
        <HubbleButton.Tertiary
          data-id={TRANSFER_BUTTON_ID}
          data-testid={TRANSFER_BUTTON_ID}
          aria-label={intl.formatMessage({ defaultMessage: "Transfer header button" })}
          size="sm"
          cta={intl.formatMessage({ defaultMessage: "Transfer" })}
          leftElement={<IconArrowsHorizontal />}
          mr={0.5}
          onClick={onClick}
        />
      ) : (
        <NavIcon data-id={TRANSFER_BUTTON_ID} onClick={onClick} pl={1} pr={1} size="sm" active={isTransferModalOpen}>
          <TransferIcon />
          <NavIconLabel>{intl.formatMessage({ defaultMessage: "Transfer" })}</NavIconLabel>
        </NavIcon>
      )}
    </Fragment>
  );
};

export default TransferButton;
