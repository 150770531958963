import { ReactNode } from "react";
import { CSSObject, SerializedStyles } from "@emotion/react";
import { GlobalAlertTypesProps } from "@gemini-ui/components/GlobalAlert/constants";
import PublicLayoutSignedOut from "@gemini-ui/components/layouts/PublicLayoutSignedOut";
import { SignedInLayout } from "@gemini-ui/components/layouts/SignedInLayout";
import { User } from "@gemini-ui/constants/templateProps/users";

export interface MainLayoutProps extends GlobalAlertTypesProps {
  user?: User;
  children?: ReactNode;
  error?: ReactNode;
  fullHeight?: boolean;
  css?: SerializedStyles | CSSObject;
}

const MainLayout = (props: MainLayoutProps) => {
  // PublicLayoutSignedOut shouldn't happen, but just in case...
  const Layout = !props.user ? PublicLayoutSignedOut : SignedInLayout;

  return <Layout {...props} />;
};

export default MainLayout;
