import { ReactNode } from "react";
import { IconChevronRightSmall } from "@hubble/icons";
import { useEffectOnce } from "react-use";
import { CURRENCIES_DETAIL } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { Button, Card, Flex, ListItem, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const StakeTypeCard = ({
  title,
  description,
  onClick,
  ...props
}: {
  title: string;
  description: ReactNode;
  onClick: () => void;
}) => {
  return (
    <Card as="button" mb={2} onClick={onClick} padding="none" {...props}>
      <ListItem size="dense" padding="sm" alignItems="center" right={<IconChevronRightSmall />} hasDivider={false}>
        <Text.Heading size="sm">{title}</Text.Heading>
        <Text.Body size="sm">{description}</Text.Body>
      </ListItem>
    </Card>
  );
};

export type Props = {
  onMoreInfoClick?: () => void;
  onDidSelectType?: (value: GrowProviderType) => void;
  pooledStakeApy: number;
  privateStakeApy: number;
};

export const PickETHStakingType = ({
  pooledStakeApy,
  privateStakeApy,
  onDidSelectType = null,
  // NOTE: This modal entry has been moved to Phase II of private staking.
  // Default to null for now to skip rendering the "What's the Difference" button.
  onMoreInfoClick = null,
}: Props) => {
  const { intl } = useIntl();

  useEffectOnce(() => {
    track(EVENTS.VIEW_PICK_STAKING_PRODUCT.name, {
      // Currently, ETH is already hardcoded in this view.
      [EVENTS.VIEW_PICK_STAKING_PRODUCT.properties.CURRENCY]: CURRENCIES_DETAIL.ETH.symbol,
    });
  });

  return (
    <Flex flexDirection="column" align="start">
      <StakeTypeCard
        data-testid="pooled-eth-staking-button"
        title={intl.formatMessage({ defaultMessage: "Staking" })}
        description={intl.formatMessage(
          defineMessage({
            defaultMessage: "Up to {pooledStakeApy}% APR<br></br>Stake <b>any</b> amount of ETH",
          }),
          {
            pooledStakeApy,
            b: (v: ReactNode) => <b>{v}</b>,
            br: () => <br />,
          }
        )}
        onClick={() => onDidSelectType?.(GrowProviderType.POOLED_STAKING)}
      />
      <StakeTypeCard
        data-testid="private-eth-staking-button"
        title={intl.formatMessage({ defaultMessage: "Staking Pro" })}
        description={intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Up to {privateStakeApy}% APR<br></br>Purchase personal validator of <b>32 ETH</b><br></br>View data on beaconcha.in",
          }),
          { privateStakeApy, b: (v: ReactNode) => <b>{v}</b>, br: () => <br /> }
        )}
        onClick={() => onDidSelectType?.(GrowProviderType.PRIVATE_STAKING)}
      />
      {Boolean(onMoreInfoClick) && (
        <Button.Secondary
          data-testid="eth-staking-types-learn-more-btn"
          cta={intl.formatMessage({ defaultMessage: "What's the difference?" })}
          onClick={onMoreInfoClick}
        />
      )}
    </Flex>
  );
};
