/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationDetails = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M195.016 186.408C177.32 191.272 54.5037 188.648 50.0557 187.048C49.8957 186.984 47.5277 185.608 48.8397 184.712C50.4717 183.592 54.6637 182.44 62.6637 181.544C84.1357 179.112 90.0237 179.112 103.528 179.432C107.432 179.528 117.512 179.592 125.384 179.368C136.456 179.016 145.704 179.464 147.656 179.272C156.712 178.408 169.16 178.44 195.016 186.408Z"
      fill={contentPrimary}
    />
    <path
      d="M131.623 189.607C93.5108 189.607 52.4548 188.359 49.8948 187.431C49.7348 187.367 48.1348 186.503 48.0068 185.447C47.9748 185.127 48.0388 184.679 48.5828 184.327C50.4708 183.047 55.3348 181.927 62.5988 181.095C84.0388 178.663 89.8628 178.663 103.527 178.983C106.919 179.047 117.159 179.143 125.351 178.919C132.039 178.727 138.151 178.791 142.215 178.855C144.903 178.887 146.855 178.919 147.591 178.855C157.223 177.959 169.671 178.215 195.143 186.023C195.335 186.087 195.463 186.247 195.431 186.439C195.431 186.631 195.303 186.791 195.111 186.855C187.527 188.903 160.423 189.607 131.623 189.607ZM93.9268 179.687C85.7348 179.687 78.4388 180.167 62.6948 181.959C53.7028 182.983 50.3108 184.199 49.0948 185.063C48.8708 185.223 48.8708 185.319 48.8708 185.351C48.9028 185.799 49.9268 186.503 50.2148 186.663C54.5668 188.263 171.111 190.727 193.351 186.375C169.063 179.047 157.031 178.823 147.655 179.687C146.855 179.751 144.999 179.751 142.183 179.687C138.151 179.623 132.039 179.559 125.383 179.751C117.159 180.007 106.919 179.911 103.495 179.815C99.9108 179.751 96.8708 179.687 93.9268 179.687Z"
      fill={contentPrimary}
    />
    <path
      d="M197.766 168.775L139.91 114.311C141.254 112.903 152.774 101.159 167.014 103.399C173.382 104.391 178.534 107.943 179.942 108.903C186.086 113.191 189.126 118.311 189.702 119.367C195.494 129.447 195.078 142.247 195.078 142.247C195.078 142.247 198.182 143.367 201.318 145.863C203.206 147.367 208.326 151.879 207.75 157.927C207.334 162.759 203.302 167.047 197.766 168.775Z"
      fill={white}
    />
    <path
      d="M197.767 169.319C197.639 169.319 197.511 169.255 197.383 169.159L139.527 114.695C139.431 114.599 139.367 114.471 139.367 114.311C139.367 114.151 139.431 114.023 139.527 113.927C140.391 112.999 143.655 109.767 148.359 107.015C154.695 103.303 160.999 101.895 167.111 102.855C171.431 103.527 175.975 105.479 180.263 108.455C186.023 112.455 189.127 117.223 190.183 119.079C195.495 128.295 195.687 139.591 195.655 141.831C196.583 142.215 199.111 143.335 201.703 145.383C204.423 147.559 208.903 152.071 208.327 157.895C207.847 162.887 203.655 167.431 197.927 169.223C197.863 169.319 197.799 169.319 197.767 169.319ZM140.679 114.311L197.895 168.167C203.079 166.439 206.823 162.343 207.271 157.863C207.783 152.551 203.591 148.327 201.063 146.279C198.023 143.847 195.015 142.759 194.983 142.759C194.759 142.695 194.631 142.471 194.631 142.247C194.631 142.119 194.951 129.479 189.319 119.655C188.295 117.863 185.287 113.255 179.687 109.383C175.559 106.503 171.143 104.615 166.983 103.975C161.127 103.047 155.047 104.391 148.935 107.975C144.839 110.343 141.895 113.095 140.679 114.311Z"
      fill={black}
    />
    <path
      d="M172.071 127.463C172.071 127.463 178.471 124.135 182.471 130.855C184.135 133.095 181.671 136.711 183.879 140.135C186.087 143.559 188.295 141.447 194.407 146.279C200.519 151.111 195.431 158.663 195.431 158.663L150.023 114.247C150.023 114.247 160.423 106.407 174.343 112.679C188.263 118.951 192.263 138.279 192.263 138.279"
      fill={white}
    />
    <path
      d="M195.464 159.239C195.336 159.239 195.176 159.175 195.08 159.079L149.672 114.631C149.544 114.535 149.512 114.375 149.512 114.215C149.512 114.055 149.608 113.927 149.736 113.831C149.832 113.735 160.584 105.863 174.6 112.199C188.584 118.503 192.776 137.991 192.808 138.183C192.872 138.471 192.68 138.759 192.392 138.823C192.104 138.887 191.816 138.695 191.752 138.407C191.72 138.215 187.624 119.271 174.152 113.191C162.44 107.911 153.16 112.903 150.92 114.343L195.368 157.863C196.36 156.103 198.92 150.567 194.12 146.759C191.08 144.359 189.096 143.719 187.496 143.207C185.928 142.695 184.68 142.311 183.496 140.455C182.216 138.439 182.376 136.423 182.536 134.663C182.664 133.287 182.76 132.071 182.12 131.207L182.088 131.175C178.376 124.935 172.648 127.847 172.392 127.975C172.136 128.103 171.784 128.007 171.656 127.751C171.528 127.495 171.624 127.175 171.88 127.015C174.248 125.767 179.592 124.935 182.984 130.599C183.848 131.783 183.72 133.255 183.592 134.759C183.464 136.455 183.304 138.183 184.392 139.879C185.384 141.415 186.28 141.703 187.816 142.183C189.416 142.695 191.624 143.367 194.792 145.927C201.224 151.047 195.976 158.983 195.912 159.047C195.816 159.175 195.688 159.271 195.528 159.271C195.496 159.239 195.464 159.239 195.464 159.239Z"
      fill={black}
    />
    <path
      d="M152.008 115.463C152.008 115.463 159.816 110.759 165.992 112.487C171.528 114.023 175.88 114.023 183.048 122.119C188.008 127.687 191.88 138.311 191.88 138.311C191.88 138.311 190.76 121.191 175.368 112.679C162.888 105.799 150.184 113.991 150.184 113.991L152.008 115.463Z"
      fill={black}
    />
    <path
      d="M172.293 127.591C174.821 126.727 177.253 128.199 179.173 129.607C181.029 130.919 181.093 132.359 180.933 136.903C180.773 141.447 184.837 143.911 185.669 143.975C186.501 144.007 191.301 145.671 192.421 146.759C193.541 147.847 195.749 149.095 195.717 152.295C195.685 155.495 195.141 158.023 195.141 158.023C195.141 158.023 199.909 152.391 196.101 147.431C191.525 141.479 185.669 143.015 183.653 139.239C181.381 134.983 184.933 132.679 181.701 129.255C176.485 123.751 172.293 127.591 172.293 127.591Z"
      fill={black}
    />
    <path
      d="M146.022 110.631C147.654 109.639 153.382 105.671 160.39 103.335C155.974 103.335 147.622 107.783 143.814 111.047C142.438 112.231 141.062 113.255 139.91 114.343C159.206 132.487 178.47 150.663 197.766 168.807C198.918 168.295 200.198 167.623 201.51 166.823C203.366 165.671 204.55 164.295 205.766 163.175C204.262 164.135 202.95 165.255 201.126 166.183C200.038 166.727 198.95 167.239 197.894 167.687C183.366 153.799 173.094 143.463 158.662 130.151C154.342 126.151 148.742 120.231 146.438 118.471C144.39 116.903 143.142 115.559 142.63 113.831L146.022 110.631Z"
      fill={black}
    />
    <path
      d="M197.764 169.191C197.668 169.191 197.54 169.159 197.476 169.063L139.62 114.631C139.524 114.535 139.492 114.439 139.492 114.311C139.492 114.183 139.556 114.087 139.62 113.991C140.356 113.319 141.156 112.647 141.988 111.975C142.5 111.559 143.012 111.143 143.524 110.695C147.268 107.495 155.684 102.887 160.356 102.887C160.58 102.887 160.74 103.047 160.772 103.239C160.804 103.431 160.676 103.655 160.484 103.719C154.532 105.735 149.572 108.871 147.172 110.375C146.788 110.631 146.468 110.823 146.244 110.951L143.14 113.895C143.62 115.271 144.676 116.519 146.724 118.087C148.036 119.111 150.468 121.511 153.028 124.039C155.044 126.023 157.092 128.071 158.948 129.799C168.388 138.535 176.132 146.023 184.324 153.959C188.612 158.119 193.06 162.439 197.988 167.143C198.98 166.695 199.972 166.247 200.932 165.767C202.052 165.191 203.012 164.519 203.908 163.879C204.42 163.495 204.964 163.111 205.54 162.759C205.732 162.631 205.988 162.695 206.116 162.855C206.244 163.047 206.212 163.271 206.052 163.431C205.732 163.719 205.38 164.039 205.028 164.391C204.132 165.255 203.108 166.247 201.7 167.111C200.484 167.847 199.204 168.551 197.892 169.127C197.86 169.191 197.796 169.191 197.764 169.191ZM140.548 114.311L197.828 168.263C198.468 167.975 199.076 167.655 199.684 167.335C199.14 167.591 198.628 167.815 198.084 168.071C197.924 168.135 197.732 168.103 197.604 167.975C192.58 163.175 188.1 158.823 183.748 154.599C175.556 146.663 167.844 139.175 158.404 130.439C156.548 128.711 154.468 126.663 152.452 124.647C149.892 122.119 147.492 119.751 146.212 118.759C143.876 116.967 142.724 115.559 142.244 113.895C142.212 113.735 142.244 113.575 142.372 113.447L145.732 110.279C145.764 110.247 145.764 110.247 145.796 110.215C146.02 110.087 146.34 109.863 146.756 109.639C148.42 108.583 151.364 106.727 154.98 105.031C150.948 106.599 146.468 109.319 144.1 111.335C143.588 111.783 143.044 112.199 142.532 112.615C141.828 113.223 141.156 113.767 140.548 114.311Z"
      fill={black}
    />
    <path
      d="M98.5681 146.279C100.392 134.727 101.608 125.415 102.408 119.079C103.208 112.711 103.656 108.359 105.032 97.6395C105.992 90.1515 106.632 85.5115 107.464 80.1675C108.296 74.9835 109.064 70.6955 109.64 67.6235C107.272 67.2075 95.6561 65.4795 85.2881 73.0315C83.4961 74.3435 76.1041 79.9115 73.8641 89.4475C72.7761 94.1195 73.2241 98.4075 73.4481 100.679C73.8961 105.255 75.0481 108.679 75.9121 111.271C76.8081 113.991 77.4481 115.847 78.8561 118.055C80.6161 120.871 82.6641 122.759 84.0721 123.879C83.2081 125.095 81.9921 127.079 81.2561 129.735C80.8081 131.271 80.0721 134.055 80.7761 137.511C80.9681 138.375 81.6401 141.639 83.9761 144.039C87.2721 147.463 93.0321 148.231 98.5681 146.279Z"
      fill={white}
    />
    <path
      d="M92.6138 147.847C88.9978 147.847 85.7339 146.663 83.5578 144.455C81.9258 142.791 80.8058 140.487 80.1978 137.639C79.4298 133.991 80.2938 131.047 80.6778 129.607C81.2538 127.623 82.1178 125.735 83.3018 124.007C81.3498 122.375 79.6858 120.487 78.3418 118.343C76.8698 116.007 76.2298 114.087 75.3658 111.431C74.5018 108.871 73.3498 105.351 72.8698 100.711C72.6458 98.3748 72.1978 94.0228 73.3178 89.2868C75.3338 80.6148 81.4778 75.0788 84.9658 72.5508C94.7578 65.4148 105.542 66.3108 109.734 67.0468C109.862 67.0788 109.99 67.1428 110.086 67.2708C110.182 67.3988 110.214 67.5268 110.182 67.6868C109.414 71.8148 108.678 76.0388 108.006 80.2308C107.206 85.2868 106.566 89.8628 105.574 97.6708C104.71 104.551 104.198 108.839 103.75 112.647C103.494 114.791 103.27 116.839 102.982 119.111C101.894 127.975 100.614 137.127 99.1418 146.343C99.1098 146.535 98.9818 146.695 98.7898 146.759C96.6778 147.495 94.5978 147.847 92.6138 147.847ZM103.942 67.6868C98.9178 67.6868 92.0058 68.7748 85.5738 73.4788C82.2138 75.9428 76.2938 81.2868 74.3418 89.6068C73.2858 94.1828 73.7018 98.4068 73.9258 100.679C74.3738 105.191 75.5258 108.647 76.3578 111.175C77.2218 113.767 77.8618 115.655 79.2378 117.863C80.5818 120.007 82.3098 121.927 84.3258 123.559C84.5498 123.751 84.5818 124.071 84.4218 124.295C83.1738 126.023 82.2458 127.943 81.6698 129.959C81.2858 131.303 80.5178 134.087 81.1898 137.479C81.7338 140.103 82.7578 142.215 84.2618 143.751C87.3018 146.855 92.6778 147.687 98.0218 145.927C99.4298 136.839 100.71 127.815 101.798 119.047C102.086 116.775 102.31 114.727 102.566 112.583C103.014 108.775 103.526 104.487 104.39 97.6068C105.382 89.7668 106.022 85.1908 106.822 80.1348C107.462 76.1028 108.166 72.0708 108.902 68.1028C107.718 67.8468 105.99 67.6868 103.942 67.6868Z"
      fill={black}
    />
    <path
      d="M92.7436 97.9915C92.7436 97.9915 85.6716 99.3675 86.7596 107.144C86.8876 109.928 91.0476 111.208 91.4636 115.272C91.8796 119.336 88.8395 119.048 87.1115 126.664C85.3835 134.28 94.0876 136.968 94.0876 136.968L96.3596 122.952C97.5436 115.464 97.2236 108.456 98.3436 101.384C99.3996 94.5675 99.6235 89.8635 100.552 83.8475C100.968 80.5195 101.352 77.2235 101.768 73.8955C101.768 73.8955 88.7436 74.2795 81.7996 87.8795C75.0156 101.224 82.9196 117.256 83.8476 119.048"
      fill={white}
    />
    <path
      d="M94.0551 137.511C93.9911 137.511 93.9591 137.511 93.8951 137.479C93.7991 137.447 91.5911 136.743 89.5751 135.079C86.8551 132.807 85.7991 129.863 86.5671 126.535C87.4631 122.567 88.7431 120.647 89.6711 119.271C90.5671 117.927 91.1111 117.127 90.9191 115.335C90.6951 113.319 89.5111 112.071 88.3591 110.823C87.3031 109.703 86.3111 108.647 86.2471 107.175C85.3511 100.647 90.0551 97.9589 92.6791 97.4149C92.9671 97.3509 93.2551 97.5429 93.3191 97.8309C93.3831 98.1189 93.1911 98.4069 92.9031 98.4709C92.6471 98.5349 86.3431 99.8789 87.3351 107.015C87.3351 107.047 87.3351 107.047 87.3351 107.079C87.3671 108.167 88.1991 109.031 89.1591 110.055C90.3751 111.335 91.7831 112.807 92.0071 115.175C92.2311 117.383 91.4951 118.439 90.5991 119.815C89.6711 121.223 88.4871 122.951 87.6231 126.727C86.9511 129.671 87.8471 132.199 90.2791 134.183C91.5271 135.207 92.8711 135.847 93.6391 136.167L95.7831 122.791C96.3911 119.015 96.5831 115.271 96.8071 111.687C96.9991 108.263 97.2231 104.711 97.7671 101.223C98.3111 97.7349 98.6311 94.7589 98.9511 91.9109C99.2391 89.1909 99.5271 86.5989 99.9751 83.6869L101.095 74.4069C98.4391 74.6949 88.0711 76.6149 82.2471 88.0389C79.4631 93.5109 78.8551 100.103 80.4231 107.591C81.6071 113.255 83.6871 117.543 84.2951 118.727C84.4231 118.983 84.3271 119.335 84.0711 119.463C83.8151 119.591 83.4631 119.495 83.3351 119.239C82.4071 117.415 74.4071 101.063 81.2871 87.5589C88.2631 73.8309 101.575 73.2549 101.703 73.2549C101.863 73.2549 102.023 73.3189 102.119 73.4149C102.215 73.5429 102.279 73.7029 102.247 73.8309L101.031 83.7829C100.583 86.6949 100.295 89.2549 100.007 91.9749C99.6871 94.8549 99.3671 97.7989 98.8231 101.319C98.2791 104.743 98.0871 108.135 97.8951 111.687C97.6711 115.303 97.4791 119.047 96.8711 122.887L94.5991 136.903C94.5671 137.063 94.4711 137.191 94.3431 137.287C94.2471 137.479 94.1511 137.511 94.0551 137.511Z"
      fill={black}
    />
    <path
      d="M109.32 67.5907C106.952 67.1747 95.9764 65.4467 85.6084 73.0307C83.8164 74.3427 76.4244 79.9107 74.1844 89.4467C73.0964 94.1187 73.5444 98.4067 73.7684 100.679C74.2164 105.255 75.3684 108.679 76.2324 111.271C77.1284 113.991 77.7684 115.847 79.1764 118.055C80.9364 120.871 82.6964 122.983 83.6884 123.975C83.0804 125.191 82.3124 127.079 81.5764 129.735C81.1284 131.271 80.6804 134.023 81.0964 137.511C81.4484 140.583 83.8164 143.111 86.1524 145.511C86.1524 145.511 80.8084 139.495 81.9924 133.031C83.0484 127.175 84.3924 123.879 84.3924 123.879C84.3924 123.879 81.6404 120.935 78.9524 116.007C76.8084 112.071 74.9844 106.567 74.8564 101.447C74.5684 90.0227 75.6884 85.7987 82.0564 77.6707C85.0964 73.7987 90.7604 70.5347 96.4884 69.0307C103.24 67.3027 109.32 67.5907 109.32 67.5907Z"
      fill={black}
    />
    <path
      d="M86.1182 146.087C85.9902 146.087 85.8302 146.023 85.7342 145.927C83.2062 143.335 80.9022 140.743 80.5182 137.607C80.0702 133.767 80.6462 130.951 80.9982 129.639C81.7662 126.951 82.4702 125.191 82.9822 124.135C81.8942 123.015 80.2942 120.999 78.6622 118.407C77.1902 116.071 76.5502 114.151 75.6862 111.495C74.8222 108.935 73.6702 105.415 73.1902 100.775C72.9662 98.4393 72.5182 94.0873 73.6382 89.3513C75.6542 80.6793 81.7982 75.1433 85.2862 72.6153C94.9822 65.5433 105.382 66.3753 109.414 67.0793C109.702 67.1433 109.894 67.3993 109.862 67.6873C109.83 67.9753 109.574 68.1673 109.286 68.1673C109.222 68.1673 103.238 67.9113 96.5821 69.6713C90.7261 71.2073 85.3182 74.4713 82.4382 78.1193C76.2622 86.0233 75.0462 89.9593 75.3342 101.543C75.4622 106.055 76.9662 111.399 79.3662 115.847C81.9902 120.647 84.6782 123.591 84.7102 123.591C84.8382 123.751 84.9022 123.975 84.8062 124.167C84.8062 124.199 83.4942 127.495 82.4382 133.223C81.3182 139.335 86.4381 145.159 86.4701 145.223C86.6621 145.447 86.6622 145.767 86.4382 145.991C86.3742 146.055 86.2462 146.087 86.1182 146.087ZM82.0542 129.895C82.0542 129.895 82.0542 129.927 82.0542 129.895C81.9262 130.375 81.7662 130.983 81.6382 131.783C81.7982 131.111 81.9262 130.503 82.0542 129.895ZM82.7582 76.0713C79.6542 79.0153 76.1022 83.4953 74.6622 89.5753C73.6382 93.9913 73.9902 98.1193 74.2142 100.423C74.0222 89.4793 75.4302 85.1913 81.5742 77.4153C81.9582 76.9673 82.3422 76.5193 82.7582 76.0713ZM93.7982 69.3513C92.4222 69.8313 91.0462 70.4073 89.6382 71.1433C90.9502 70.4393 92.3582 69.8633 93.7982 69.3513Z"
      fill={black}
    />
    <path
      d="M101.192 76.2952C101.192 76.2952 92.1684 77.5112 88.4244 82.7272C85.0644 87.3992 81.6724 90.1192 81.1284 100.903C80.7444 108.359 84.3604 119.047 84.3604 119.047C84.3604 119.047 74.5044 104.967 81.2244 88.7112C86.6644 75.5272 101.704 73.9912 101.704 73.9912L101.192 76.2952Z"
      fill={black}
    />
    <path
      d="M92.9336 98.471C90.4376 99.367 89.4456 102.055 88.8056 104.359C88.1976 106.535 88.9976 107.687 92.0056 111.175C94.9816 114.631 93.3496 119.079 92.7416 119.655C92.1336 120.199 89.4136 124.519 89.2216 126.055C89.0296 127.591 88.0696 129.959 90.1176 132.455C92.1656 134.951 94.1816 136.583 94.1816 136.583C94.1816 136.583 86.9496 135.143 86.8216 128.935C86.6616 121.447 92.1976 118.983 91.3976 114.759C90.5016 109.991 86.3096 110.439 86.6616 105.735C87.2376 98.087 92.9336 98.471 92.9336 98.471Z"
      fill={black}
    />
    <path
      d="M132.487 153.511C132.295 153.095 132.455 152.583 132.871 152.391C137.415 149.959 144.039 147.239 152.263 146.471C156.615 146.055 166.727 145.223 176.455 150.567C178.183 151.527 181.415 153.319 184.519 156.839C187.047 159.687 188.263 162.311 190.151 166.407C190.887 167.975 192.167 171.367 193.703 177.031C194.567 180.263 195.111 183.143 195.463 185.479C195.559 186.055 195.143 186.631 194.567 186.727C192.551 187.111 190.055 187.495 187.207 187.687C185.063 187.847 182.247 188.039 178.631 187.687C174.375 187.303 171.783 187.047 169.415 186.343C167.271 185.703 163.239 184.455 158.567 181.895C151.111 177.831 146.535 172.871 143.207 169.287C139.687 165.479 137.607 162.439 137.031 161.575C135.015 158.599 133.543 155.815 132.487 153.511Z"
      fill={white}
    />
    <path
      d="M182.663 188.391C181.447 188.391 180.071 188.327 178.599 188.199C174.311 187.815 171.719 187.559 169.255 186.823C167.271 186.215 163.143 184.967 158.279 182.343C150.727 178.215 146.151 173.255 142.791 169.639C139.591 166.183 137.511 163.303 136.551 161.863C134.823 159.303 133.287 156.551 131.975 153.735C131.655 153.063 131.943 152.263 132.583 151.911C138.791 148.583 145.383 146.567 152.199 145.927C156.135 145.543 166.631 144.551 176.711 150.087C178.343 150.983 181.703 152.807 184.903 156.455C187.463 159.367 188.743 162.055 190.631 166.151C191.111 167.207 192.455 170.343 194.215 176.839C194.951 179.623 195.559 182.503 195.975 185.351C196.103 186.247 195.527 187.047 194.663 187.207C192.231 187.687 189.735 188.007 187.239 188.199C186.055 188.263 184.519 188.391 182.663 188.391ZM132.967 153.287C134.247 156.039 135.751 158.727 137.447 161.255C138.375 162.663 140.423 165.511 143.559 168.903C146.855 172.487 151.399 177.351 158.791 181.383C163.527 183.975 167.591 185.191 169.543 185.767C171.911 186.471 174.439 186.727 178.663 187.111C182.311 187.463 185.223 187.239 187.143 187.111C189.575 186.919 192.039 186.599 194.439 186.151C194.727 186.087 194.951 185.799 194.887 185.511C194.471 182.695 193.895 179.879 193.159 177.127C191.431 170.695 190.119 167.623 189.639 166.599C187.719 162.471 186.535 159.975 184.071 157.159C180.967 153.639 177.735 151.879 176.167 151.015C166.375 145.639 156.135 146.631 152.295 146.983C145.639 147.623 139.175 149.575 133.095 152.839C132.967 152.935 132.903 153.127 132.967 153.287Z"
      fill={black}
    />
    <path
      d="M146.026 163.111C146.058 162.215 146.41 158.919 148.586 155.495C152.074 149.991 157.578 148.135 158.666 147.783C166.474 145.287 173.258 148.967 175.466 150.183C178.122 151.623 184.202 154.919 186.282 162.183C188.33 169.351 184.97 175.303 184.522 176.039C180.874 182.279 174.698 184.007 173.098 184.391C166.314 186.023 160.842 183.207 157.61 181.351C145.13 174.183 146.026 163.111 146.026 163.111Z"
      fill={white}
    />
    <path
      d="M169.062 185.415C163.654 185.415 159.43 182.983 157.382 181.831C150.758 178.055 147.878 173.159 146.63 169.735C145.286 166.087 145.478 163.335 145.478 163.079C145.478 162.631 145.702 159.047 148.102 155.207C151.398 149.959 156.486 147.911 158.502 147.271C166.534 144.711 173.446 148.487 175.718 149.703C178.406 151.143 184.678 154.567 186.79 162.055C188.838 169.255 185.638 175.207 184.998 176.359C181.446 182.407 175.59 184.391 173.254 184.967C171.814 185.287 170.406 185.415 169.062 185.415ZM146.566 163.143C146.566 163.271 145.862 173.991 157.926 180.871C160.358 182.279 166.086 185.543 173.03 183.847C175.238 183.303 180.774 181.447 184.102 175.751C184.71 174.695 187.718 169.063 185.798 162.311C183.782 155.271 177.798 152.007 175.238 150.631C173.062 149.447 166.438 145.863 158.854 148.263C157.382 148.743 152.262 150.663 149.062 155.751C146.758 159.399 146.598 162.759 146.566 163.143C146.566 163.143 146.566 163.111 146.566 163.143Z"
      fill={black}
    />
    <path
      d="M174.055 169.703C173.767 170.247 172.391 172.807 169.415 173.639C166.407 174.471 163.911 172.967 162.791 172.295C161.895 171.783 158.503 169.735 158.119 165.959C157.895 163.655 158.887 161.863 159.239 161.255C159.559 160.711 160.775 158.695 163.303 157.767C166.599 156.519 169.575 158.055 170.311 158.439C171.175 158.887 173.351 160.071 174.439 162.695C175.879 166.151 174.279 169.255 174.055 169.703Z"
      fill={black}
    />
    <path
      d="M167.591 174.279C165.287 174.279 163.463 173.191 162.567 172.647C158.887 170.439 157.895 167.815 157.703 166.023C157.543 164.391 157.927 162.727 158.887 161.095C159.911 159.367 161.383 158.087 163.143 157.415C164.743 156.807 167.303 156.455 170.503 158.119C171.271 158.535 173.671 159.751 174.823 162.599C176.263 166.119 174.727 169.319 174.407 169.927C173.287 172.039 171.559 173.511 169.511 174.055C168.839 174.215 168.199 174.279 167.591 174.279ZM165.959 157.703C164.967 157.703 164.103 157.895 163.431 158.151C161.831 158.759 160.519 159.911 159.591 161.479C158.727 162.951 158.343 164.455 158.503 165.927C158.759 168.295 160.263 170.311 162.983 171.943C164.103 172.615 166.439 174.023 169.287 173.223C171.111 172.711 172.679 171.399 173.703 169.479L174.055 169.671L173.703 169.479C173.991 168.935 175.399 166.023 174.087 162.855C173.031 160.295 170.855 159.175 170.151 158.791C168.551 157.991 167.143 157.703 165.959 157.703Z"
      fill={black}
    />
    <path
      d="M176.168 165.224C176.072 165.416 175.624 166.248 174.632 166.536C173.64 166.824 172.808 166.312 172.424 166.088C172.136 165.928 171.016 165.224 170.856 163.976C170.792 163.208 171.112 162.632 171.24 162.408C171.336 162.216 171.752 161.576 172.584 161.256C173.672 160.84 174.664 161.352 174.92 161.48C175.208 161.64 175.944 162.024 176.296 162.888C176.776 164.04 176.232 165.096 176.168 165.224Z"
      fill={white}
    />
    <path
      d="M174.023 167.016C173.191 167.016 172.551 166.632 172.231 166.44C170.919 165.64 170.567 164.68 170.471 164.04C170.407 163.432 170.567 162.824 170.887 162.248C171.271 161.608 171.783 161.16 172.423 160.904C172.999 160.68 173.927 160.552 175.079 161.16C175.367 161.32 176.231 161.736 176.647 162.792C177.159 164.072 176.615 165.224 176.487 165.448C176.071 166.216 175.431 166.76 174.695 166.952C174.471 166.984 174.247 167.016 174.023 167.016ZM173.447 161.512C173.159 161.512 172.903 161.576 172.711 161.64C172.231 161.832 171.847 162.152 171.559 162.632C171.303 163.08 171.175 163.528 171.239 163.944C171.303 164.648 171.783 165.256 172.583 165.736C172.935 165.928 173.639 166.376 174.471 166.152C175.015 165.992 175.463 165.608 175.783 165.032C175.879 164.872 176.295 164.008 175.911 163.048C175.623 162.376 175.111 162.024 174.727 161.832C174.247 161.608 173.831 161.512 173.447 161.512Z"
      fill={black}
    />
    <path
      d="M179.272 152.487C172.36 146.183 156.808 145.191 146.952 147.271C140.488 148.615 135.88 150.919 132.68 152.551C132.744 152.967 132.84 153.511 133.096 154.087C133.384 154.855 133.8 155.431 134.088 155.847C135.816 154.887 138.344 153.607 141.512 152.359C146.184 150.535 153.992 147.463 163.848 148.199C167.304 148.423 172.872 149.319 179.272 152.487Z"
      fill={black}
    />
    <path
      d="M135.143 151.303C135.143 153.607 135.463 155.399 135.751 156.551C136.679 160.327 138.791 164.071 138.791 164.071C137.479 162.503 135.911 160.391 134.567 157.639C133.671 155.783 132.807 154.343 132.359 152.839C133.223 152.455 134.311 151.719 135.143 151.303Z"
      fill={black}
    />
    <path
      d="M138.824 164.487C138.696 164.487 138.568 164.423 138.504 164.327C136.808 162.343 135.368 160.135 134.216 157.799C133.96 157.287 133.704 156.807 133.48 156.327C132.84 155.111 132.296 154.055 132.008 152.935C131.944 152.743 132.04 152.519 132.232 152.423C132.648 152.231 133.128 151.943 133.576 151.655C134.056 151.367 134.536 151.079 134.984 150.855C135.112 150.791 135.272 150.791 135.4 150.887C135.528 150.951 135.592 151.111 135.592 151.239C135.592 153.031 135.784 154.759 136.2 156.391C137.128 160.071 139.176 163.783 139.208 163.815C139.304 164.007 139.272 164.263 139.08 164.391C138.984 164.487 138.888 164.487 138.824 164.487ZM132.904 153.063C133.192 153.991 133.672 154.919 134.216 155.975C134.44 156.455 134.696 156.935 134.952 157.447C135.432 158.407 135.944 159.335 136.52 160.263C136.072 159.143 135.624 157.895 135.336 156.647C134.952 155.175 134.76 153.607 134.728 152.007C134.504 152.135 134.248 152.295 133.992 152.455C133.64 152.679 133.256 152.903 132.904 153.063Z"
      fill={black}
    />
    <path
      d="M104.264 113.255C103.816 113.127 103.528 112.711 103.624 112.231C104.584 107.175 106.664 100.327 111.24 93.4471C113.672 89.7991 119.4 81.4471 129.672 77.2231C131.496 76.4871 134.952 75.1111 139.592 74.9191C143.4 74.7591 146.184 75.4631 150.568 76.5511C152.264 76.9671 155.688 78.1191 161.032 80.4871C164.104 81.8311 166.664 83.2391 168.68 84.4551C169.192 84.7751 169.384 85.4151 169.096 85.9271C168.136 87.7511 166.856 89.8951 165.224 92.2311C164.008 93.9911 162.376 96.2951 159.848 98.8871C156.872 101.959 155.048 103.783 152.968 105.191C151.112 106.439 147.624 108.807 142.664 110.823C134.792 114.055 128.072 114.503 123.208 114.823C118.024 115.175 114.376 114.855 113.32 114.759C109.8 114.503 106.728 113.895 104.264 113.255Z"
      fill={white}
    />
    <path
      d="M118.534 115.559C116.71 115.559 114.918 115.495 113.35 115.335C110.278 115.047 107.174 114.535 104.166 113.767C103.43 113.575 102.982 112.871 103.142 112.135C104.454 105.223 107.046 98.8233 110.854 93.1273C113.062 89.8313 118.886 81.0634 129.51 76.7114C131.206 76.0074 134.758 74.5673 139.622 74.3753C143.494 74.2153 146.374 74.9514 150.758 76.0394C151.878 76.3274 155.142 77.2553 161.318 80.0073C163.942 81.1913 166.566 82.5353 169.03 84.0073C169.798 84.4553 170.054 85.4153 169.638 86.2153C168.454 88.3913 167.142 90.5353 165.734 92.5833C164.614 94.1833 162.918 96.6473 160.294 99.3033C157.286 102.375 155.462 104.231 153.35 105.671C151.622 106.823 148.07 109.255 142.95 111.367C135.014 114.631 128.262 115.079 123.334 115.399C121.702 115.527 120.07 115.559 118.534 115.559ZM104.422 112.743C107.366 113.479 110.406 113.991 113.446 114.279C115.11 114.439 118.63 114.631 123.238 114.343C128.102 114.023 134.726 113.575 142.534 110.375C147.526 108.327 151.046 105.927 152.71 104.807C154.758 103.431 156.55 101.607 159.494 98.5674C162.054 95.9434 163.718 93.5753 164.838 91.9753C166.246 89.9593 167.526 87.8793 168.678 85.7033C168.838 85.4473 168.742 85.0953 168.454 84.9353C166.022 83.4953 163.462 82.1514 160.87 80.9994C154.79 78.2794 151.59 77.3513 150.502 77.0953C146.086 75.9753 143.398 75.3353 139.686 75.4633C135.014 75.6553 131.59 77.0633 129.926 77.7353C119.59 81.9593 113.894 90.5353 111.75 93.7353C108.038 99.3033 105.51 105.543 104.198 112.327C104.134 112.519 104.23 112.679 104.422 112.743Z"
      fill={black}
    />
    <path
      d="M120.261 108.775C119.589 108.167 117.253 105.831 115.941 101.991C113.861 95.8151 115.877 90.3431 116.293 89.2871C119.301 81.6391 126.405 78.727 128.741 77.735C131.525 76.5831 137.925 73.927 144.869 76.903C151.749 79.847 154.245 86.183 154.533 86.983C157.061 93.767 154.533 99.623 153.829 101.095C150.853 107.399 145.189 109.863 141.701 111.207C128.293 116.455 120.261 108.775 120.261 108.775Z"
      fill={white}
    />
    <path
      d="M132.615 113.607C129.831 113.607 127.526 113.095 125.766 112.487C122.086 111.239 120.071 109.351 119.879 109.191C119.527 108.903 116.871 106.471 115.431 102.183C113.383 96.1347 115.207 90.6627 115.815 89.0947C118.887 81.2547 126.183 78.2467 128.583 77.2547C131.399 76.1027 137.99 73.3827 145.126 76.4227C152.006 79.3667 154.63 85.6067 155.078 86.8227C157.542 93.3827 155.399 99.1747 154.343 101.383C151.079 108.231 144.678 110.695 141.926 111.783C138.438 113.095 135.303 113.607 132.615 113.607ZM120.615 108.391C120.711 108.487 128.615 115.751 141.543 110.759C144.167 109.735 150.31 107.367 153.35 100.903C154.342 98.8547 156.359 93.3827 154.023 87.2067C153.607 86.0547 151.11 80.1987 144.646 77.4147C137.926 74.5347 131.623 77.1267 128.935 78.2467C126.663 79.2067 119.687 82.0547 116.775 89.4787C116.199 90.9187 114.503 96.1027 116.423 101.799C117.831 105.895 120.327 108.135 120.615 108.391C120.615 108.391 120.615 108.359 120.615 108.391Z"
      fill={black}
    />
    <path
      d="M143.56 90.9513C143.816 91.5593 145 94.4073 143.688 97.4153C142.376 100.455 139.464 101.511 138.152 101.991C137.128 102.375 133.16 103.815 129.8 101.607C127.752 100.263 126.952 98.2473 126.696 97.5433C126.472 96.9033 125.608 94.5673 126.504 91.8473C127.688 88.2633 130.952 86.8553 131.752 86.5033C132.712 86.0873 135.144 85.0633 138.056 85.9593C141.864 87.0473 143.368 90.4713 143.56 90.9513Z"
      fill={black}
    />
    <path
      d="M134.024 103.207C132.04 103.207 130.6 102.599 129.576 101.959C128.104 100.999 127.016 99.5593 126.312 97.6713C125.576 95.6553 125.512 93.6073 126.12 91.6873C126.696 89.9593 128.104 87.6233 131.624 86.0873C132.488 85.7033 135.08 84.5833 138.216 85.5113C142.088 86.6633 143.688 90.0873 143.976 90.7593C144.968 93.0953 145 95.5273 144.104 97.5753C142.728 100.775 139.624 101.895 138.312 102.375C136.68 102.983 135.24 103.207 134.024 103.207ZM135.752 86.0233C133.96 86.0233 132.52 86.6313 131.976 86.8873C128.744 88.2953 127.464 90.4393 126.952 91.9753C126.376 93.7033 126.44 95.5593 127.112 97.3833C127.752 99.0793 128.744 100.391 130.056 101.255C132.168 102.631 134.856 102.759 138.024 101.607C139.336 101.127 142.056 100.135 143.304 97.2873C144.104 95.4313 144.072 93.2553 143.176 91.1433C142.92 90.5353 141.448 87.4313 137.96 86.3753C137.16 86.1193 136.424 86.0233 135.752 86.0233Z"
      fill={black}
    />
    <path
      d="M142.503 88.8391C142.471 89.0631 142.279 90.0551 141.383 90.6631C140.455 91.2711 139.463 91.0471 139.015 90.9511C138.663 90.8551 137.287 90.5671 136.743 89.3511C136.423 88.6151 136.551 87.8791 136.615 87.6551C136.679 87.4311 136.839 86.6311 137.607 86.0231C138.567 85.2551 139.751 85.4471 140.039 85.4791C140.391 85.5431 141.255 85.7031 141.895 86.4711C142.727 87.4631 142.535 88.6791 142.503 88.8391Z"
      fill={white}
    />
    <path
      d="M139.976 91.623C139.528 91.623 139.112 91.527 138.888 91.463C137.256 91.079 136.552 90.215 136.264 89.543C135.976 88.935 135.944 88.231 136.104 87.495C136.264 86.727 136.68 86.055 137.256 85.575C137.8 85.159 138.728 84.679 140.104 84.935C140.456 84.999 141.48 85.159 142.28 86.087C143.272 87.239 143.08 88.647 143.016 88.935C142.856 89.863 142.376 90.663 141.64 91.111C141.096 91.527 140.488 91.623 139.976 91.623ZM139.368 85.959C138.664 85.959 138.216 86.215 137.928 86.439C137.544 86.759 137.256 87.207 137.128 87.751C137.096 87.943 136.968 88.519 137.224 89.127C137.512 89.767 138.152 90.215 139.112 90.439C139.496 90.535 140.328 90.727 141.064 90.247C141.544 89.927 141.832 89.415 141.96 88.775L142.504 88.871L141.96 88.775C141.992 88.583 142.12 87.623 141.448 86.823C140.904 86.183 140.2 86.055 139.912 86.023C139.72 85.991 139.56 85.959 139.368 85.959Z"
      fill={black}
    />
    <path
      d="M128.775 77.7354C119.367 81.0954 111.271 92.9674 108.423 98.2474C105.287 104.071 104.071 109.447 103.527 112.999C103.911 113.191 104.423 113.415 105.031 113.575C105.831 113.767 106.535 113.799 107.047 113.767C107.239 111.431 107.655 108.679 108.487 105.671C109.575 101.703 112.679 94.7594 114.183 92.3274C122.279 79.3354 125.639 80.2634 128.775 77.7354Z"
      fill={black}
    />
    <path
      d="M73.7344 186.471C73.8624 176.711 75.5584 153.927 83.0784 146.727C89.4464 140.231 96.0704 141.287 97.9264 138.791C100.486 135.399 103.174 127.047 105.286 124.647C112.934 116.711 130.47 113.319 142.63 112.615C142.63 112.615 144.614 144.007 122.822 164.807C97.3504 189.159 73.7344 186.471 73.7344 186.471Z"
      fill={white}
    />
    <path
      d="M75.9434 187.111C74.5354 187.111 73.7354 187.015 73.6714 187.015C73.3834 186.983 73.1914 186.759 73.1914 186.471C73.2234 183.239 73.8954 154.791 82.6954 146.343C86.6634 142.311 90.7274 141.127 93.6714 140.231C95.4634 139.719 96.8714 139.303 97.4794 138.471C98.6954 136.839 100.007 133.959 101.255 131.175C102.535 128.295 103.751 125.575 104.871 124.295C108.423 120.615 114.215 117.639 122.119 115.399C130.471 113.063 138.535 112.295 142.599 112.071C142.887 112.039 143.143 112.295 143.175 112.583C143.175 112.647 143.655 120.615 141.447 131.015C139.399 140.615 134.567 154.343 123.207 165.191C109.959 177.831 97.2234 183.079 88.8714 185.255C83.0154 186.823 78.5034 187.111 75.9434 187.111ZM74.2794 185.991C75.8794 186.087 81.1914 186.215 88.6474 184.263C96.8714 182.119 109.383 176.935 122.439 164.455C133.575 153.799 138.343 140.295 140.359 130.855C142.215 122.119 142.183 115.143 142.119 113.223C129.671 114.023 112.903 117.575 105.671 125.063C104.679 126.183 103.495 128.839 102.247 131.655C100.967 134.503 99.6554 137.415 98.3754 139.175C97.5434 140.295 95.9754 140.743 93.9914 141.319C91.1434 142.151 87.2394 143.303 83.4634 147.143C80.3274 150.183 77.7994 156.615 76.1674 165.767C74.6634 174.375 74.3434 182.887 74.2794 185.991Z"
      fill={black}
    />
    <path
      d="M73.7344 186.471C78.2464 182.375 89.8944 165.127 92.6784 161.863C96.7744 157.063 104.198 154.855 108.518 150.247C112.614 145.863 113.254 140.263 117.926 136.295C122.598 132.327 140.134 116.295 142.598 112.615"
      fill={white}
    />
    <path
      d="M73.7359 187.016C73.5759 187.016 73.4479 186.952 73.3199 186.824C73.1279 186.6 73.1279 186.248 73.3519 186.056C76.2959 183.4 82.4079 174.92 86.8879 168.744C89.3519 165.32 91.3039 162.632 92.2639 161.512C94.4399 158.952 97.5439 157.096 100.552 155.336C103.272 153.736 106.088 152.072 108.104 149.896C109.928 147.944 111.08 145.704 112.2 143.528C113.544 140.872 114.952 138.12 117.576 135.912C122.344 131.88 139.688 116.008 142.152 112.36C142.312 112.104 142.664 112.04 142.888 112.2C143.144 112.36 143.208 112.712 143.048 112.936C140.52 116.648 123.048 132.648 118.248 136.712C115.784 138.792 114.504 141.32 113.128 144.008C111.976 146.248 110.792 148.584 108.872 150.632C106.728 152.904 103.848 154.632 101.064 156.264C98.1519 157.992 95.1119 159.784 93.0319 162.216C92.0719 163.336 90.0559 166.152 87.6879 169.384C82.9519 175.944 77.0319 184.136 74.0239 186.856C73.9919 186.984 73.8639 187.016 73.7359 187.016Z"
      fill={black}
    />
    <path
      d="M73.7344 186.471C73.7664 184.743 73.8304 182.663 73.9904 180.263C74.6624 169.159 77.4144 152.647 83.5584 146.695C89.8624 140.199 96.4544 141.255 98.3104 138.759C100.838 135.367 103.494 127.015 105.606 124.615C113.19 116.679 130.982 112.583 142.662 113.191C142.662 113.191 113.894 117.255 108.23 125.159C103.494 131.751 102.79 138.439 100.39 140.839C97.9904 143.239 86.3424 146.855 84.2624 151.751C79.3664 163.303 74.3104 186.439 74.3104 186.439"
      fill={black}
    />
  </Fragment>
));
IllustrationDetails.displayName = "IllustrationDetails";
