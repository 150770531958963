import { AnchorHTMLAttributes, ButtonHTMLAttributes, ComponentProps, CSSProperties, Fragment } from "react";
import styled from "@emotion/styled";
import { Column, ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Flex } from "@gemini-ui/design-system/Flex";
import { size as sizeToken, Spacing, text } from "@gemini-ui/design-system/primitives";
import { SkeletonLoader } from "@gemini-ui/design-system/SkeletonLoader";
import { testIds } from "@gemini-ui/design-system/Table/testIds";

// Constants
export const INTERACTIVE_CELL_DATA_ATTR = "data-hbl-table-interactive-cell";
export const INTERACTIVE_CELL_TEXT_UNDERLINE_DATA_ATTR = "data-interactive-cell-text-underline";

export const SIZE_MAP = {
  sm: "24px",
  md: "52px",
  lg: "84px",
  auto: "auto",
};

export const ALIGN_MAP = {
  center: "center",
  end: "flex-end",
  start: "flex-start",
};

// Base styles
export const Table = styled("table")`
  display: block;
  width: 100%;
  height: 100%;
`;

export const TBody = styled("tbody")`
  display: block;
  height: 100%;
  overflow-y: auto;
`;

export const TRow = styled("tr")`
  display: flex;
  background: ${({ theme }) => theme.colorScheme.list.background.enabled};
  &:hover:not([aria-hidden="true"]),
  &:focus-within {
    background: ${({ theme }) => theme.colorScheme.list.background.hover};
  }
  &:last-of-type td {
    border-bottom: unset;
  }
`;

export const THeaderRow = styled("tr")`
  display: flex;
  background: ${({ theme }) => theme.colorScheme.list.background.enabled};
`;

export const THead = styled("thead")<{ offset?: number }>(({ offset }) => ({
  display: "block",
  margin: 0,
  ...(typeof offset !== "undefined"
    ? {
        position: "sticky",
        top: offset,
        zIndex: 1,
      }
    : {}),
}));

type TCellBaseProps = { justifyContent?: keyof typeof ALIGN_MAP };
type RowSizeProp = { size: keyof typeof SIZE_MAP };

export const TCellBase = styled("td")<TCellBaseProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${({ justifyContent }) => ALIGN_MAP[justifyContent] || ALIGN_MAP.start};
  margin: 0;
  padding: 0 ${Spacing.scale[1]};
  min-width: 0;
  overflow: hidden;
  color: ${({ theme }) => theme.colorScheme.content.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colorScheme.border.primary};
`;

export const TCell = styled(TCellBase)<RowSizeProp & TCellBaseProps>`
  font-size: ${({ size }) => (size === "md" ? text.typesets.body.sm.fontSize : text.typesets.body.md.fontSize)};
  font-weight: ${text.typesets.body.sm.fontWeight};
  line-height: ${({ size }) => (size === "md" ? text.typesets.body.sm.lineHeight : text.typesets.body.md.lineHeight)};
  height: ${props => SIZE_MAP[props.size]};
  justify-content: ${({ justifyContent }) => ALIGN_MAP[justifyContent] || ALIGN_MAP.start};
  text-align: ${({ justifyContent }) => justifyContent || "start"};

  > [${INTERACTIVE_CELL_DATA_ATTR}] {
    display: flex;
    height: 100%;
    width: calc(100% + ${Spacing.scale[2]});
    margin-inline-end: -${Spacing.scale[1]};
    margin-inline-start: -${Spacing.scale[1]};
    padding-inline-start: ${Spacing.scale[1]};
    padding-inline-end: ${Spacing.scale[1]};
    align-items: center;
    justify-content: ${({ justifyContent }) => ALIGN_MAP[justifyContent] || ALIGN_MAP.start};
    text-align: ${({ justifyContent }) => justifyContent || "start"};
    outline: none;
  }

  &:first-of-type {
    padding-inline-start: ${Spacing.scale[2.5]};

    > [${INTERACTIVE_CELL_DATA_ATTR}] {
      width: calc(100% + ${Spacing.scale[1]});
      margin-inline-end: -${Spacing.scale[1]};
      margin-inline-start: 0;
      padding-inline-start: 0;
      padding-inline-end: ${Spacing.scale[1]};
    }
  }

  &:last-of-type {
    padding-inline-end: ${Spacing.scale[2.5]};
    > [${INTERACTIVE_CELL_DATA_ATTR}] {
      width: calc(100% + ${Spacing.scale[1]});
      margin-inline-end: 0;
      margin-inline-start: -${Spacing.scale[1]};
      padding-inline-start: ${Spacing.scale[1]};
      padding-inline-end: 0;
    }
  }
`;

// Interactive cells
type LinkCellProps = {
  as?: "a" | typeof Link;
  route: string;
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

export const LinkCell = ({ as = "a", route, ...rest }: LinkCellProps) => {
  const baseProps = {
    ...rest,
    [INTERACTIVE_CELL_DATA_ATTR]: true,
    css: {
      outline: "none",
      "&:hover,&:focus": {
        "[data-interactive-cell-text-underline]": { textDecoration: "underline", textUnderlineOffset: 2 },
      },
    },
  };
  return as === "a" ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...baseProps} href={route} />
  ) : (
    <Link {...baseProps} to={route} />
  );
};

export function ButtonCell(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const btnProps = {
    ...props,
    [INTERACTIVE_CELL_DATA_ATTR]: true,
  };

  return (
    <button
      {...btnProps}
      // https://www.trysmudford.com/blog/a-good-reset/
      css={{
        WebkitAppearance: "none",
        borderRadius: 0,
        textAlign: "inherit",
        background: "none",
        boxShadow: "none",
        padding: 0,
        cursor: "pointer",
        border: "none",
        color: "inherit",
        font: "inherit",
        outline: "none",
        "&:hover,&:focus": {
          "[data-interactive-cell-text-underline]": {
            textDecoration: "underline",
            textUnderlineOffset: 2,
          },
        },
      }}
    />
  );
}

// Loaders
function SingleLineLoader({ size, ...rest }: ComponentProps<typeof SkeletonLoader> & RowSizeProp) {
  return (
    <SkeletonLoader
      width="50%"
      height={getFontSizesFromRowSize(size).primary}
      data-testid={testIds.singleLineLoader}
      {...rest}
    />
  );
}

function DoubleLineLoader({ size }: RowSizeProp) {
  const { primary, secondary } = getFontSizesFromRowSize(size);

  return (
    <Fragment>
      <SkeletonLoader width="50%" mb={1} height={primary} data-testid={testIds.doubleLineLoader} />
      <SkeletonLoader width="50%" height={secondary} />
    </Fragment>
  );
}

function NameWithLogoLoader({ size }: RowSizeProp) {
  const diameter = size === "md" ? sizeToken[24] : sizeToken[40];
  return (
    <Flex align="center" css={{ width: "100%" }} data-testid={testIds.nameWithLogoLoader}>
      <SkeletonLoader height={diameter} width={diameter} radiusSize="full" mr={1} />
      <SkeletonLoader width="50%" height={getFontSizesFromRowSize(size).primary} />
    </Flex>
  );
}

const LOADER_MAP = {
  singleLine: SingleLineLoader,
  doubleLine: DoubleLineLoader,
  nameWithLogo: NameWithLogoLoader,
};

export function LoadingState({ justifyContent, size, variant }: { variant?: string } & RowSizeProp & TCellBaseProps) {
  const hasLoader = typeof variant === "string";
  if (!hasLoader) return null;

  const Component = LOADER_MAP[variant];

  return (
    <Flex css={{ width: "100%" }} flexDirection="column" align={ALIGN_MAP[justifyContent] || ALIGN_MAP.start}>
      <Component size={size} />
    </Flex>
  );
}

// Utils
const ROW_SIZE_TO_PRIMARY_TYPESET_SIZE_MAP: Record<RowSizeProp["size"], keyof typeof text.typesets.body> = {
  sm: "xs",
  md: "sm",
  lg: "md",
  auto: "md",
};

const ROW_SIZE_TO_SECONDARY_TYPESET_SIZE_MAP: Record<RowSizeProp["size"], keyof typeof text.typesets.body> = {
  sm: "xs",
  md: "xs",
  lg: "sm",
  auto: "sm",
};

function getFontSizesFromRowSize(size: RowSizeProp["size"]) {
  return {
    primary: text.typesets.body[ROW_SIZE_TO_PRIMARY_TYPESET_SIZE_MAP[size]].fontSize,
    secondary: text.typesets.body[ROW_SIZE_TO_SECONDARY_TYPESET_SIZE_MAP[size]].fontSize,
  };
}

export function getCellStyles(column: Column<any>, userColumnDef: ColumnDef<any>) {
  // column.columnDef includes defaultColumn properties making impossible to know whether
  // the size property is user-defined or from built-in defaults.
  // Must check size properties from user-defined column def.
  const { flex, padding } = column.columnDef;
  const { size: userDefinedSize, minSize: minWidth, maxSize: maxWidth } = userColumnDef;

  const styles: CSSProperties = {
    padding,
    minWidth,
    maxWidth,
    flex: isNaN(flex) ? 1 : flex,
  };

  if (userDefinedSize) {
    styles.flex = "0 0 auto";
    styles.width = column.getSize();
  }
  return styles;
}
