import * as Sentry from "@sentry/browser";
import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { WatchlistResponseItem, WatchlistTypes } from "@gemini-ui/constants/watchlist";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { getError } from "@gemini-ui/utils/error";

const watchlistUrl = `/watchlist`;
const enableUrl = (currency, defaultFiat) => `/watchlist/${currency}${defaultFiat}/FavoritesWatch/enable`;
const disableUrl = (currency, defaultFiat) => `/watchlist/${currency}${defaultFiat}/FavoritesWatch/disable`;

export const getWatchlistIsEnabled = (currency: CurrencyShortName, defaultFiat: CurrencyShortNameFiat) => {
  return axios.get(watchlistUrl, { id: CACHE_ID.watchlist, cache: { ttl: 1000 } }).then(res => {
    if (res.status === 200 && res.data) {
      const currencyObject = res.data.find(
        item => item.tradingPair === `${currency}${defaultFiat}` && item.watchType === WatchlistTypes.FAVORITES_WATCH
      );

      if (currencyObject.watchType === WatchlistTypes.FAVORITES_WATCH) {
        return currencyObject.isEnabled;
      }
    }
  });
};

export const getAllEnabledWatchlist = async (): Promise<WatchlistResponseItem[]> => {
  let returnData = [];

  try {
    const { data } = await axios.get(watchlistUrl);

    returnData = data.filter(asset => asset.isEnabled);
  } catch (error) {
    const message = getError(error);
    Sentry.captureMessage(`Error fetching /watchlist: ${message}`);
  }

  return returnData;
};

export const putEnableWatchlistFavorite = (
  enabled: boolean,
  currency: CurrencyShortName,
  defaultFiat: CurrencyShortNameFiat
) => {
  if (axios.storage) axios.storage.remove(CACHE_ID.watchlist);
  const url = enabled ? disableUrl(currency, defaultFiat) : enableUrl(currency, defaultFiat);

  return axios.put(url).then(res => res.status === 200);
};
