export function BankofAmericaIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#DC1531" />
      <path
        d="M25.457 15.548a43.779 43.779 0 0 0-6.195-2.22c-.686.13-1.35.283-2.015.457 2.1.632 4.158 1.415 6.13 2.373a37.347 37.347 0 0 1 2.08-.61ZM22.262 16.506a44.302 44.302 0 0 0-6.087-2.416c-.75.218-1.48.457-2.187.697a43.05 43.05 0 0 1 6.26 2.459c.664-.261 1.328-.5 2.014-.74ZM16.582 24.537a53.735 53.735 0 0 1 14.017-7.248c.817.435 1.618.9 2.401 1.393a56.698 56.698 0 0 0-13.31 7.987 43.83 43.83 0 0 0-3.108-2.132ZM27.127 15.635a48.04 48.04 0 0 0-14.575 6.66c.986.5 1.972 1.023 2.936 1.589 4.352-3.047 9.174-5.441 14.318-7.009-.878-.434-1.757-.87-2.679-1.24ZM14.181 15.592a41.25 41.25 0 0 0-7.18 3.112l.023.01c.72.28 1.441.56 2.141.86a39.332 39.332 0 0 1 6.966-3.307 29.311 29.311 0 0 0-1.95-.675ZM17.054 16.614c.771.305 1.543.632 2.293 1.001a48.4 48.4 0 0 0-6.945 3.505 45.024 45.024 0 0 0-2.293-1.132 48.853 48.853 0 0 1 6.945-3.374Z"
        fill="#fff"
      />
    </svg>
  );
}
