import { Fragment, ReactElement, ReactNode } from "react";
import PopperJS from "popper.js";
import { Manager, Popper, Reference } from "react-popper";
import Portal from "@gemini-ui/components/Popover/Portal";
const defaultStyles = {
  zIndex: 9999,
};

interface PopoverProps {
  id?: string;
  children?: ReactNode;
  renderComponent: ReactElement;
  isOpen?: boolean;
  placement?: PopperJS.Placement;
  hasBackdrop?: boolean;
  hasPortal?: boolean;
  className?: string;
  onClose?: (e?: any) => void;
  popoverStyle?: {}; // react style object
  doNotCloseIfTargetContainsClass?: string;
  "data-testid"?: string;
  portalRootSelector?: string;
  modifiers?: PopperJS.Modifiers;
}

const Popover = ({
  id,
  children,
  renderComponent,
  isOpen,
  placement = "bottom",
  hasBackdrop = false,
  hasPortal = true,
  className,
  onClose,
  popoverStyle,
  doNotCloseIfTargetContainsClass,
  "data-testid": dataTestId,
  portalRootSelector,
  modifiers,
}: PopoverProps) => {
  const maybeRenderPortal = (component: ReactNode) => {
    if (hasPortal) {
      return (
        <Portal
          id={id}
          data-testid={dataTestId}
          onClose={onClose}
          hasBackdrop={hasBackdrop}
          doNotCloseIfTargetContainsClass={doNotCloseIfTargetContainsClass}
          portalRootSelector={portalRootSelector}
        >
          {component}
        </Portal>
      );
    }
    return <Fragment>{component}</Fragment>;
  };

  return (
    <Manager>
      <Reference>
        {props => (
          <div data-testid="Popover" {...props}>
            {children}
          </div>
        )}
      </Reference>
      {isOpen &&
        Boolean(renderComponent) &&
        maybeRenderPortal(
          <Popper placement={placement} modifiers={modifiers}>
            {({ ref, style, placement }) => (
              <div
                className={className}
                ref={ref}
                style={{ ...style, ...popoverStyle, ...defaultStyles }}
                data-placement={placement}
              >
                {renderComponent}
              </div>
            )}
          </Popper>
        )}
    </Manager>
  );
};

export default Popover;
type PopoverPlacement = PopperJS.Placement;
export { PopoverPlacement };
