import { ReactNode } from "react";
import styled from "@emotion/styled";
import { border, Button, Flex, Spacing, Text } from "@gemini-ui/design-system";

export const ModalTitle = ({ children }: { children: ReactNode }) => (
  <Text.Heading size="md" overflowWrap="break-word">
    {children}
  </Text.Heading>
);

export const PresetDepositButtonsContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.scale[1]};
  padding: ${Spacing.scale[1]};
  margin-top: ${Spacing.scale[2]};
  background-color: ${({ theme }) => theme.colorScheme.card.background.filled};
  border-radius: ${border.radius.full};
  overflow: clip;
`;

export const PresetDepositAmountButton = styled(Button.Secondary)`
  flex-grow: 1;
`;
