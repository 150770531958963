import { ReactNode } from "react";
import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { SourceType, UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { SupportedStakingPaymentMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { calculateValidatorsAvailableToUnstake } from "@gemini-ui/pages/Earn/utils";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import {
  getEligibleBanks,
  getEligiblePayPalAccounts,
  getGeminiBalance,
  getVerifiedDebitCards,
} from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { DepositLimits, UsePaymentData } from "@gemini-ui/services/transfer/types";
import { BankType, DebitCardType, PayPalAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const FundingSourceMethodType = {
  isTradingBalance: (paymentMethod: SupportedStakingPaymentMethod): paymentMethod is SourceType.TRADING_BALANCE => {
    return paymentMethod === SourceType.TRADING_BALANCE;
  },
  isFiatBalance: (paymentMethod: SupportedStakingPaymentMethod): paymentMethod is AccountType.BALANCE => {
    return paymentMethod === AccountType.BALANCE;
  },
  isBankAccount: (paymentMethod: SupportedStakingPaymentMethod): paymentMethod is BankType => {
    return (paymentMethod as BankType)?.paymentMethodType === PaymentMethodType.BANK;
  },
  isDebitCard: (paymentMethod: SupportedStakingPaymentMethod): paymentMethod is DebitCardType => {
    return (paymentMethod as DebitCardType)?.paymentMethodType === PaymentMethodType.DEBIT_CARD;
  },
  isPayPalAccount: (paymentMethod: SupportedStakingPaymentMethod): paymentMethod is PayPalAccountType => {
    return (paymentMethod as PayPalAccountType)?.paymentMethodType === PaymentMethodType.PAYPAL;
  },
};

interface PaymentMethodsBuilderProps {
  intl: IntlShape;
  defaultFiat: CurrencyShortNameFiat;
  balances?: CurrencyBalances;
  currency?: CurrencyShortName;
  paymentMethodData: Pick<UsePaymentData, "paymentMethods" | "limits">;
  providerType: GrowProviderType;
}

interface UnstakeSourceBuilderProps {
  intl: IntlShape;
  provider: InterestProvider;
}

export const createTradingBalancePaymentMethod = ({
  intl,
  balances,
  currency,
  paymentMethodData,
}: PaymentMethodsBuilderProps): SelectOptionProps<SourceType.TRADING_BALANCE>[] => {
  const currencyTradingBalance = currency ? getGeminiBalance(balances ?? {}, currency) : null;

  const method = [];
  if (currencyTradingBalance > 0) {
    method.push({
      value: SourceType.TRADING_BALANCE,
      ...getPaymentMethodLabel<SourceType.TRADING_BALANCE>({
        paymentMethod: SourceType.TRADING_BALANCE,
        balances,
        currency,
        intl,
        limits: paymentMethodData.limits,
      }),
    });
  }

  return method;
};

export const createFiatBalancePaymentMethod = ({
  intl,
  balances,
  defaultFiat,
  paymentMethodData,
}: PaymentMethodsBuilderProps): SelectOptionProps<AccountType.BALANCE>[] => {
  const cashBalance = getGeminiBalance(balances ?? {}, defaultFiat);

  const method = [];
  if (cashBalance > 0) {
    method.push({
      value: AccountType.BALANCE,
      ...getPaymentMethodLabel<AccountType.BALANCE>({
        paymentMethod: AccountType.BALANCE,
        balances,
        defaultFiat,
        intl,
        limits: paymentMethodData.limits,
      }),
    });
  }

  return method;
};

export const createAchPaymentMethods = ({
  intl,
  defaultFiat,
  paymentMethodData,
}: PaymentMethodsBuilderProps): SelectOptionProps<BankType>[] => {
  const eligibleBanks = getEligibleBanks(paymentMethodData.paymentMethods, defaultFiat);

  return eligibleBanks.map(bank => ({
    value: bank,
    ...getPaymentMethodLabel<BankType>({
      paymentMethod: bank,
      limits: paymentMethodData.limits,
      intl,
    }),
  }));
};

export const createDebitPaymentMethods = ({
  intl,
  defaultFiat,
  paymentMethodData,
}: PaymentMethodsBuilderProps): SelectOptionProps<DebitCardType>[] => {
  const eligibleCards = getVerifiedDebitCards(paymentMethodData.paymentMethods, defaultFiat);

  return eligibleCards.map(card => ({
    value: card,
    ...getPaymentMethodLabel({
      paymentMethod: card,
      defaultFiat,
      limits: paymentMethodData.limits,
      intl,
    }),
  }));
};

export const createPayPalPaymentMethods = ({
  intl,
  defaultFiat,
  paymentMethodData,
}: PaymentMethodsBuilderProps): SelectOptionProps<PayPalAccountType>[] => {
  const eligibleAccounts = getEligiblePayPalAccounts(paymentMethodData.paymentMethods, defaultFiat);

  return eligibleAccounts.map(account => ({
    value: account,
    ...getPaymentMethodLabel({
      paymentMethod: account,
      defaultFiat,
      limits: paymentMethodData.limits,
      intl,
    }),
  }));
};

export const createPaymentMethodOptions = (
  props: PaymentMethodsBuilderProps
): SelectOptionProps<SupportedStakingPaymentMethod>[] => {
  if (props.providerType === GrowProviderType.PRIVATE_STAKING) {
    return createTradingBalancePaymentMethod({ ...props });
  }

  return [
    ...createTradingBalancePaymentMethod({ ...props }),
    ...createFiatBalancePaymentMethod({ ...props }),
    ...createAchPaymentMethods({ ...props }),
    ...createDebitPaymentMethods({ ...props }),
    ...createPayPalPaymentMethods({ ...props }),
  ];
};

export const createPooledUnstakeSourceOptions = ({
  intl,
  provider,
}: UnstakeSourceBuilderProps): SelectOptionProps<UnstakeSourceType>[] => {
  return [
    {
      value: UnstakeSourceType.pooledBalance,
      ...getUnstakeSourceLabel({
        source: UnstakeSourceType.pooledBalance,
        earningInterest: provider.earningInterest,
        intl,
      }),
    },
  ];
};

export const createPrivateUnstakeSourceOptions = ({
  intl,
  provider,
}: UnstakeSourceBuilderProps): SelectOptionProps<UnstakeSourceType>[] => {
  return [
    {
      value: UnstakeSourceType.availableRewardsBalance,
      ...getUnstakeSourceLabel({
        source: UnstakeSourceType.availableRewardsBalance,
        totalAvailableRewardBalance: provider.availableBalance.totalAvailableRewardBalance,
        intl,
      }),
    },
    {
      value: UnstakeSourceType.validatorBalance,
      ...getUnstakeSourceLabel({
        source: UnstakeSourceType.validatorBalance,
        totalAvailableDepositBalance: provider.availableBalance.totalAvailableDepositBalance,
        intl,
      }),
    },
  ];
};

export const createUnstakeSourceOptions = (providerType: GrowProviderType, props: UnstakeSourceBuilderProps) => {
  return providerType === GrowProviderType.POOLED_STAKING
    ? createPooledUnstakeSourceOptions({ ...props })
    : createPrivateUnstakeSourceOptions({ ...props });
};

export const getPaymentMethodLabel = <T extends SupportedStakingPaymentMethod = SupportedStakingPaymentMethod>({
  paymentMethod,
  balances,
  currency,
  defaultFiat,
  limits,
  intl,
}: {
  defaultFiat?: CurrencyShortNameFiat;
  currency?: CurrencyShortName;
  balances?: CurrencyBalances;
  limits: DepositLimits;
  paymentMethod: T;
  intl: IntlShape;
}): {
  label: ReactNode;
  subLabel: ReactNode;
} => {
  if (FundingSourceMethodType.isFiatBalance(paymentMethod)) {
    const cashBalance = getGeminiBalance(balances ?? {}, defaultFiat);
    return {
      label: intl.formatMessage(defineMessage({ defaultMessage: "{defaultFiat} Balance" }), { defaultFiat }),
      subLabel: <Money value={cashBalance} currency={defaultFiat} hideTrailingSign />,
    };
  }

  if (FundingSourceMethodType.isTradingBalance(paymentMethod)) {
    const currencyTradingBalance = currency ? getGeminiBalance(balances ?? {}, currency) : null;
    return {
      label: intl.formatMessage(defineMessage({ defaultMessage: "{currency} Balance" }), { currency }),
      subLabel:
        currencyTradingBalance > 0 ? (
          <Money currency={currency} value={currencyTradingBalance} />
        ) : (
          intl.formatMessage({ defaultMessage: "No balance" })
        ),
    };
  }

  if (FundingSourceMethodType.isDebitCard(paymentMethod) && limits?.card?.daily) {
    const total = limits.card.daily.total;
    return {
      label: paymentMethod.displayName,
      subLabel: intl.formatMessage(defineMessage({ defaultMessage: "<Money></Money> limit" }), {
        Money: () => <Money {...total} hideTrailingSign />,
      }),
    };
  }

  if (FundingSourceMethodType.isPayPalAccount(paymentMethod) && limits?.payPal?.daily) {
    const total = limits.payPal.daily.total;
    return {
      label: paymentMethod.displayName,
      subLabel: intl.formatMessage(defineMessage({ defaultMessage: "<Money></Money> limit" }), {
        Money: () => <Money {...total} hideTrailingSign />,
      }),
    };
  }

  if (FundingSourceMethodType.isBankAccount(paymentMethod) && limits?.ach?.daily) {
    const limitReached = Number(limits.ach.daily.available.value) === 0;
    return {
      label: paymentMethod.displayName,
      subLabel: limitReached
        ? intl.formatMessage({
            defaultMessage: "Amount exceeds remaining daily limit",
          })
        : `${paymentMethod?.accountType} - ${paymentMethod.lastFour}`,
    };
  }

  return {
    label: paymentMethod?.displayName || "",
    subLabel: null,
  };
};

export const getUnstakeSourceLabel = ({
  source,
  earningInterest,
  totalAvailableDepositBalance,
  totalAvailableRewardBalance,
  intl,
}: {
  source: UnstakeSourceType;
  earningInterest?: InterestProvider["earningInterest"];
  totalAvailableDepositBalance?: InterestProvider["availableBalance"]["totalAvailableDepositBalance"];
  totalAvailableRewardBalance?: InterestProvider["availableBalance"]["totalAvailableRewardBalance"];
  intl: IntlShape;
}): {
  label: ReactNode;
  subLabel: ReactNode;
} => {
  switch (source) {
    case UnstakeSourceType.pooledBalance:
      if (!earningInterest) break;
      return {
        label: intl.formatMessage({ defaultMessage: "Staked balance" }),
        subLabel: <Money {...earningInterest} />,
      };
    case UnstakeSourceType.availableRewardsBalance:
      if (!totalAvailableRewardBalance) break;
      return {
        label: intl.formatMessage({ defaultMessage: "Rewards" }),
        subLabel: <Money {...totalAvailableRewardBalance} />,
      };
    case UnstakeSourceType.validatorBalance:
      if (!totalAvailableDepositBalance) break;
      const numValidators = calculateValidatorsAvailableToUnstake(Number(totalAvailableDepositBalance.value));
      return {
        label: intl.formatMessage({ defaultMessage: "Validators" }),
        subLabel: intl.formatMessage(
          defineMessage({ defaultMessage: "{numValidators} Validators • <Money></Money>" }),
          {
            numValidators,
            Money: () => <Money {...totalAvailableDepositBalance} />,
          }
        ),
      };
    default:
      return {
        label: source || "",
        subLabel: null,
      };
  }
};
