import { EVENTS, track } from "@gemini-ui/analytics";
import { LinkPaymentAnalyticsType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { trackLinkedFundingMethodSuccess } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";

export const trackPaymentRegistrationSuccess = (linkPaymentType: LinkPaymentAnalyticsType) => {
  const { PAYMENT_METHOD_REGISTRATION_SUCCESS } = EVENTS;
  const { name, properties } = PAYMENT_METHOD_REGISTRATION_SUCCESS;
  track(name, {
    [properties.PAYMENT_METHOD_TYPE]: linkPaymentType,
  });
  trackLinkedFundingMethodSuccess(linkPaymentType);
};
