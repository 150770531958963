/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationDriver = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M123.321 176.333C124.217 190.061 113.593 198.701 116.697 212.589C111.321 215.405 99.6094 220.589 84.0254 220.077C70.8094 219.661 60.8574 215.341 55.6094 212.589C56.6014 208.173 58.0734 201.613 59.6734 193.581C62.5214 179.405 63.1614 174.349 64.3774 168.781C64.3774 168.781 66.5214 149.613 78.8734 133.901C85.0814 125.997 124.313 88.8446 132.313 94.5086C140.313 100.173 111.033 132.205 111.033 132.205L123.321 176.333Z"
      fill={black}
    />
    <path
      d="M86.1367 220.397C85.4327 220.397 84.7287 220.397 84.0247 220.365C74.1687 220.045 64.2807 217.453 55.4807 212.845C55.3527 212.781 55.2887 212.653 55.3207 212.525C56.7287 206.285 58.1047 199.885 59.3847 193.517C60.9207 185.869 61.8167 180.941 62.5207 177.005C63.1287 173.677 63.5447 171.277 64.1207 168.717C64.1527 168.557 66.3927 149.293 78.6807 133.709C81.7527 129.805 93.1766 118.477 104.761 108.877C114.937 100.461 128.025 91.0845 132.505 94.2525C133.369 94.8605 133.849 95.7565 133.977 96.9405C134.969 106.093 113.337 130.125 111.353 132.269L123.577 176.237C123.577 176.269 123.577 176.269 123.577 176.301C123.993 182.701 121.913 188.045 119.929 193.197C117.561 199.341 115.289 205.101 116.953 212.493C116.985 212.621 116.921 212.749 116.793 212.813C110.105 216.333 99.4807 220.397 86.1367 220.397ZM55.9607 212.397C64.6327 216.909 74.3287 219.437 84.0567 219.757C98.1047 220.205 109.369 216.045 116.377 212.397C114.777 204.941 117.145 198.861 119.417 192.973C121.401 187.885 123.449 182.605 123.033 176.365L110.777 132.269C110.745 132.173 110.777 132.077 110.841 131.981C111.065 131.725 134.393 106.093 133.401 96.9725C133.305 95.9485 132.889 95.2125 132.153 94.7005C128.889 92.3965 119.289 97.5805 105.113 109.293C93.5287 118.861 82.1687 130.157 79.0967 134.029C66.9367 149.485 64.6967 168.589 64.6647 168.749C64.0887 171.309 63.6727 173.709 63.0647 177.037C62.3607 180.973 61.4967 185.901 59.9287 193.549C58.7127 199.917 57.3687 206.221 55.9607 212.397Z"
      fill={black}
    />
    <path
      d="M55.6079 213.164C55.5759 213.164 55.5119 213.164 55.4799 213.132C55.1599 213.068 54.9679 212.748 55.0639 212.428C57.4319 203.02 59.0319 194.06 60.5999 185.388C64.2799 165.036 67.4799 147.468 78.4559 133.548C81.5279 129.644 92.9839 118.284 104.6 108.684C119.544 96.3321 128.984 91.4361 132.728 94.0601C133.656 94.7001 134.2 95.6921 134.328 96.9721C135.352 106.476 112.504 131.596 111.544 132.652C111.32 132.908 110.936 132.908 110.712 132.684C110.456 132.46 110.456 132.076 110.68 131.852C110.904 131.596 134.136 106.06 133.176 97.1001C133.08 96.1721 132.728 95.5001 132.056 95.0521C128.952 92.8761 119.256 98.1881 105.368 109.644C93.8159 119.18 82.4559 130.444 79.4159 134.316C68.6319 148.012 65.4639 165.452 61.8159 185.644C60.2479 194.348 58.6159 203.308 56.2479 212.78C56.1199 212.972 55.8959 213.164 55.6079 213.164Z"
      fill={white}
    />
    <path
      d="M116.696 213.164C116.44 213.164 116.184 212.972 116.12 212.716C114.392 205.068 116.824 198.892 119.128 192.908C121.112 187.852 123.128 182.604 122.744 176.396C122.712 176.076 122.968 175.788 123.288 175.756C123.608 175.724 123.896 175.98 123.928 176.3C124.344 182.764 122.264 188.14 120.248 193.324C117.88 199.404 115.64 205.164 117.272 212.46C117.336 212.78 117.144 213.1 116.824 213.164C116.792 213.164 116.728 213.164 116.696 213.164Z"
      fill={white}
    />
    <path
      d="M129.143 154.252C137.847 155.724 138.935 165.004 132.119 172.972C125.815 180.3 119.415 184.844 115.447 180.748C111.063 176.268 129.143 154.252 129.143 154.252Z"
      fill={black}
    />
    <path
      d="M118.968 182.892C117.432 182.892 116.12 182.316 115 181.164C112.952 179.052 114.616 173.932 120.152 165.484C124.088 159.468 128.632 153.932 128.696 153.868C128.824 153.708 129.048 153.612 129.24 153.676C132.888 154.284 135.512 156.3 136.6 159.308C138.072 163.372 136.504 168.748 132.568 173.356C127.224 179.564 122.712 182.764 119.16 182.892C119.096 182.892 119.032 182.892 118.968 182.892ZM129.368 154.892C122.52 163.276 112.952 177.388 115.832 180.332C116.76 181.292 117.816 181.74 119.096 181.676C122.264 181.58 126.584 178.412 131.64 172.556C135.32 168.268 136.792 163.34 135.48 159.692C134.584 157.196 132.408 155.5 129.368 154.892Z"
      fill={white}
    />
    <path
      d="M125.306 140.588C136.538 142.508 138.234 153.26 128.41 164.012C119.29 174.028 113.594 179.084 107.61 174.764C101.626 170.476 125.306 140.588 125.306 140.588Z"
      fill={black}
    />
    <path
      d="M111.992 176.908C110.424 176.908 108.856 176.396 107.256 175.244C106.616 174.764 106.232 174.06 106.104 173.164C105.016 165.356 124.024 141.228 124.856 140.204C124.984 140.044 125.208 139.948 125.432 139.98C130.264 140.812 133.656 143.308 134.904 146.988C136.632 151.948 134.424 158.316 128.856 164.396C122.168 171.724 116.984 176.908 111.992 176.908ZM125.56 141.228C123.416 143.98 106.328 166.124 107.288 173.004C107.384 173.612 107.608 174.028 107.96 174.316C109.272 175.276 110.584 175.724 111.96 175.724C116.12 175.724 120.792 171.5 127.96 163.628C133.144 157.964 135.288 151.884 133.752 147.404C132.664 144.204 129.752 142.028 125.56 141.228Z"
      fill={white}
    />
    <path
      d="M107.609 167.596C116.697 166.412 131.193 142.956 130.585 133.868C129.977 125.292 121.721 123.564 114.809 125.74C111.673 128.14 107.609 167.596 107.609 167.596Z"
      fill={black}
    />
    <path
      d="M107.608 168.204C107.448 168.204 107.32 168.14 107.192 168.044C107.064 167.916 107 167.724 107.032 167.564C107.032 167.468 108.056 157.58 109.464 147.5C112.312 126.924 113.848 125.74 114.456 125.292C114.52 125.26 114.584 125.228 114.648 125.196C119.384 123.724 124.056 124.108 127.192 126.252C129.592 127.884 130.968 130.508 131.224 133.868C131.896 143.468 117.016 166.988 107.736 168.204C107.64 168.204 107.64 168.204 107.608 168.204ZM115.064 126.284C112.984 128.332 109.848 151.82 108.28 166.86C112.12 165.836 117.368 160.78 122.232 153.388C127.16 145.9 130.264 138.06 129.976 133.932C129.784 130.924 128.6 128.684 126.456 127.212C123.672 125.324 119.416 124.972 115.064 126.284Z"
      fill={white}
    />
    <path
      d="M86.1043 220.684C85.4003 220.684 84.6963 220.684 83.9923 220.652C74.1043 220.332 64.1843 217.708 55.3843 213.1C55.0963 212.94 54.9683 212.588 55.1283 212.3C55.2883 212.012 55.6403 211.884 55.9283 212.044C64.6003 216.588 74.3283 219.148 84.0563 219.468C98.1683 219.916 109.432 215.692 116.376 212.044C116.664 211.884 117.016 212.012 117.176 212.3C117.336 212.588 117.208 212.94 116.92 213.1C110.136 216.62 99.4483 220.684 86.1043 220.684Z"
      fill={white}
    />
    <path
      d="M150.71 47.4679C151.51 46.9559 152.566 47.0839 153.238 47.7559C157.526 52.1079 162.038 58.6999 166.582 64.4279C171.638 70.7959 175.862 75.4039 179.35 81.0359C169.43 90.4759 160.726 97.2919 148.886 107.084C138.87 115.372 126.902 125.868 117.398 132.844C116.566 133.452 115.446 133.356 114.742 132.62C109.558 127.276 104.15 121.228 98.7424 114.444C93.6864 108.108 89.2384 101.932 85.3984 96.1079C94.8704 87.7879 105.398 79.8839 117.014 71.2439C128.63 62.5399 139.958 54.1879 150.71 47.4679Z"
      fill={white}
    />
    <path
      d="M116.186 133.836C115.482 133.836 114.81 133.58 114.298 133.036C108.698 127.244 103.29 121.1 98.2338 114.796C93.5298 108.908 89.0178 102.7 84.8578 96.4276C84.6978 96.1716 84.7298 95.8516 84.9538 95.6596C94.1698 87.5636 104.314 79.9156 116.602 70.7636C128.858 61.6116 139.898 53.5156 150.394 46.9556C151.45 46.2836 152.794 46.4436 153.658 47.3396C156.634 50.3476 159.61 54.3156 162.778 58.4756C164.186 60.3316 165.626 62.2196 167.066 64.0436C168.474 65.8036 169.818 67.4356 171.098 69.0036C174.458 73.0676 177.338 76.5876 179.866 80.7156C180.026 80.9396 179.962 81.2596 179.77 81.4516C171.77 89.0356 164.442 95.0836 155.93 102.092C153.818 103.82 151.61 105.644 149.306 107.564C146.554 109.836 143.706 112.236 140.666 114.796C132.794 121.42 124.666 128.268 117.786 133.356C117.274 133.676 116.73 133.836 116.186 133.836ZM86.1378 96.2036C90.2018 102.316 94.5858 108.332 99.1938 114.06C104.218 120.332 109.594 126.444 115.162 132.204C115.674 132.716 116.474 132.78 117.05 132.364C123.93 127.308 132.026 120.492 139.898 113.868C142.906 111.308 145.786 108.908 148.538 106.636C150.874 104.716 153.05 102.892 155.162 101.164C163.546 94.2836 170.778 88.3316 178.586 80.9396C176.154 77.0676 173.37 73.6756 170.17 69.7716C168.89 68.2036 167.546 66.5716 166.138 64.7796C164.698 62.9556 163.258 61.0676 161.85 59.2116C158.714 55.0516 155.738 51.1476 152.826 48.1716C152.346 47.6916 151.61 47.5956 151.034 47.9476C140.57 54.4756 129.594 62.5716 117.338 71.6916C105.21 80.7156 95.2258 88.2676 86.1378 96.2036Z"
      fill={black}
    />
    <path
      d="M140.377 100.748C140.185 100.748 140.025 100.652 139.897 100.524C139.705 100.268 139.769 99.8839 140.025 99.6919C143.513 97.0999 145.913 94.9559 148.921 92.2679C150.073 91.2439 151.257 90.1879 152.665 88.9719C154.233 87.5959 155.993 86.2199 157.721 84.8759C160.665 82.5399 163.481 80.3639 165.305 78.2839C165.529 78.0279 165.881 78.0279 166.137 78.2199C166.393 78.4439 166.393 78.7959 166.201 79.0519C164.313 81.2279 161.465 83.4679 158.457 85.8039C156.761 87.1479 155.001 88.4919 153.465 89.8679C152.089 91.0839 150.905 92.1399 149.753 93.1639C146.681 95.8839 144.281 98.0279 140.761 100.652C140.601 100.716 140.505 100.748 140.377 100.748Z"
      fill={black}
    />
    <path
      d="M135.576 92.8759C135.384 92.8759 135.224 92.8119 135.096 92.6519C134.904 92.3959 134.936 92.0119 135.192 91.8199L144.76 84.3959C145.016 84.2039 145.4 84.2359 145.592 84.4919C145.784 84.7479 145.752 85.1319 145.496 85.3239L135.96 92.7479C135.832 92.8439 135.704 92.8759 135.576 92.8759Z"
      fill={black}
    />
    <path
      d="M151.127 80.6845C150.935 80.6845 150.775 80.6205 150.647 80.4605C150.455 80.2045 150.487 79.8205 150.743 79.6285L160.311 72.2045C160.567 72.0125 160.951 72.0445 161.143 72.3005C161.335 72.5565 161.303 72.9405 161.047 73.1325L151.479 80.5565C151.383 80.6205 151.255 80.6845 151.127 80.6845Z"
      fill={black}
    />
    <path
      d="M129.111 86.1886C128.919 86.1886 128.759 86.0926 128.631 85.9326C128.439 85.6766 128.503 85.2926 128.759 85.1006C130.967 83.5006 133.047 81.7726 135.223 79.9486C137.559 77.9646 139.991 75.9486 142.775 73.9326L144.247 72.8766C148.663 69.6766 151.863 67.3726 155.639 65.6766C155.927 65.5486 156.279 65.6766 156.407 65.9646C156.535 66.2526 156.407 66.6046 156.119 66.7326C152.471 68.3966 149.303 70.6686 144.951 73.8046L143.479 74.8606C140.727 76.8446 138.327 78.8606 135.991 80.8126C133.783 82.6686 131.703 84.4286 129.431 86.0286C129.367 86.1246 129.239 86.1886 129.111 86.1886Z"
      fill={black}
    />
    <path
      d="M123.865 80.6837C123.673 80.6837 123.481 80.5877 123.385 80.4277C123.193 80.1717 123.257 79.7877 123.513 79.5957C126.297 77.6437 128.313 76.0757 130.457 74.4437C132.345 73.0037 134.329 71.4677 136.889 69.6437C138.425 68.5237 139.961 67.3717 141.465 66.2517C145.337 63.3397 148.953 60.6197 152.281 58.9877C152.569 58.8277 152.921 58.9557 153.081 59.2437C153.241 59.5317 153.113 59.8837 152.825 60.0437C149.593 61.6117 146.009 64.3317 142.201 67.1797C140.697 68.2997 139.161 69.4517 137.593 70.5717C135.033 72.3957 133.081 73.8997 131.193 75.3397C129.017 77.0037 127.001 78.5717 124.217 80.5237C124.089 80.6517 123.993 80.6837 123.865 80.6837Z"
      fill={black}
    />
    <path
      d="M117.401 125.132C117.241 125.132 117.049 125.068 116.953 124.908L94.4894 97.6762C94.2974 97.4202 94.3294 97.0682 94.5534 96.8442C97.4654 94.3162 100.025 92.5562 103.513 90.1562C104.089 89.7722 104.697 89.3562 105.305 88.9082C106.553 88.0442 107.737 87.1802 108.889 86.3162C111.545 84.3642 113.817 82.6682 116.089 81.7082C116.313 81.6122 116.569 81.6762 116.761 81.8362C120.089 85.4202 123.641 89.7722 126.681 93.5162C128.441 95.6922 130.233 97.7402 131.929 99.6922C134.617 102.764 137.145 105.676 138.937 108.396C139.097 108.652 139.033 109.004 138.809 109.196L117.753 125.004C117.657 125.1 117.529 125.132 117.401 125.132ZM95.7694 97.3882L117.529 123.756L137.689 108.652C135.961 106.124 133.593 103.404 131.097 100.524C129.369 98.5402 127.577 96.4922 125.817 94.2842C122.873 90.6362 119.449 86.4762 116.249 82.9562C114.233 83.8842 112.089 85.4522 109.657 87.2762C108.505 88.1402 107.321 89.0042 106.041 89.9002C105.401 90.3482 104.825 90.7642 104.249 91.1482C100.889 93.4202 98.4574 95.0842 95.7694 97.3882Z"
      fill={black}
    />
    <path
      d="M135.577 110.54C130.553 105.516 127.289 101.228 123.865 103.116C119.897 105.324 119.481 102.924 119.385 100.172C119.289 96.2044 115.065 90.7324 110.713 93.2604C105.689 96.2044 109.881 103.372 114.745 104.108C118.873 104.716 115.865 108.428 115.385 108.844C113.113 110.924 114.745 114.86 119.545 122.252L135.577 110.54Z"
      fill={black}
    />
    <path
      d="M88.4727 155.404C104.025 136.748 103.065 113.804 113.337 113.804C123.609 113.804 120.985 166.188 104.025 190.316"
      fill={black}
    />
    <path
      d="M104.024 190.924C103.896 190.924 103.8 190.892 103.672 190.828C103.416 190.636 103.352 190.284 103.544 189.996C110.04 180.748 115.128 166.06 117.528 149.74C119.608 135.436 119.128 122.22 116.344 116.844C115.512 115.212 114.488 114.38 113.368 114.38C108.408 114.38 106.168 120.46 103.064 128.844C100.12 136.812 96.44 146.764 88.952 155.756C88.728 156.012 88.376 156.044 88.12 155.82C87.864 155.596 87.832 155.244 88.056 154.988C95.416 146.156 99.064 136.3 101.976 128.428C105.24 119.564 107.608 113.164 113.368 113.164C114.968 113.164 116.344 114.188 117.4 116.268C120.344 121.932 120.856 135.116 118.712 149.868C116.312 166.38 111.128 181.228 104.536 190.636C104.376 190.828 104.216 190.924 104.024 190.924Z"
      fill={white}
    />
    <path
      d="M173.113 129.836C173.017 129.836 172.921 129.804 172.825 129.772C172.537 129.612 172.441 129.26 172.601 128.972L176.089 122.54C176.249 122.252 176.601 122.156 176.889 122.316C177.177 122.476 177.273 122.828 177.113 123.116L173.625 129.548C173.529 129.74 173.337 129.836 173.113 129.836Z"
      fill={contentPrimary}
    />
    <path
      d="M162.808 148.812C162.712 148.812 162.616 148.78 162.52 148.748C162.232 148.588 162.136 148.236 162.296 147.948L165.784 141.516C165.944 141.228 166.296 141.132 166.584 141.292C166.872 141.452 166.968 141.804 166.808 142.092L163.32 148.524C163.224 148.684 163.032 148.812 162.808 148.812Z"
      fill={contentPrimary}
    />
    <path
      d="M182.423 143.02C182.327 143.02 182.231 142.988 182.135 142.956L175.703 139.468C175.415 139.308 175.319 138.956 175.479 138.668C175.639 138.38 175.991 138.284 176.279 138.444L182.711 141.932C182.999 142.092 183.095 142.444 182.935 142.732C182.839 142.892 182.615 143.02 182.423 143.02Z"
      fill={contentPrimary}
    />
    <path
      d="M163.447 132.717C163.351 132.717 163.255 132.685 163.159 132.653L156.727 129.165C156.439 129.005 156.343 128.653 156.503 128.365C156.663 128.077 157.015 127.981 157.303 128.141L163.735 131.629C164.023 131.789 164.119 132.141 163.959 132.429C163.863 132.589 163.671 132.717 163.447 132.717Z"
      fill={contentPrimary}
    />
    <path
      d="M166.84 126.348C166.584 126.348 166.36 126.188 166.264 125.932L165.048 121.836C164.952 121.516 165.144 121.196 165.432 121.1C165.752 121.004 166.072 121.196 166.168 121.484L167.384 125.58C167.48 125.9 167.288 126.22 167 126.316C166.936 126.348 166.904 126.348 166.84 126.348Z"
      fill={contentPrimary}
    />
    <path
      d="M173.817 149.964C173.561 149.964 173.337 149.804 173.241 149.548L172.025 145.452C171.929 145.132 172.121 144.812 172.409 144.716C172.729 144.62 173.049 144.812 173.145 145.1L174.361 149.196C174.457 149.516 174.265 149.836 173.977 149.932C173.945 149.964 173.881 149.964 173.817 149.964Z"
      fill={contentPrimary}
    />
    <path
      d="M179.48 133.228C179.224 133.228 179 133.068 178.904 132.812C178.808 132.492 179 132.172 179.288 132.076L183.384 130.86C183.704 130.764 184.024 130.956 184.12 131.244C184.216 131.564 184.024 131.884 183.736 131.98L179.64 133.196C179.576 133.228 179.512 133.228 179.48 133.228Z"
      fill={contentPrimary}
    />
    <path
      d="M155.867 140.204C155.611 140.204 155.387 140.045 155.291 139.789C155.195 139.468 155.387 139.149 155.675 139.052L159.771 137.836C160.091 137.74 160.411 137.932 160.507 138.22C160.603 138.54 160.411 138.861 160.123 138.957L156.027 140.173C155.963 140.205 155.931 140.204 155.867 140.204Z"
      fill={contentPrimary}
    />
    <path
      d="M185.657 44.4925C185.561 44.4925 185.497 44.4605 185.401 44.4285L180.697 42.3165C180.409 42.1885 180.281 41.8366 180.409 41.5486C180.537 41.2606 180.889 41.1326 181.177 41.2606L185.881 43.3726C186.169 43.5006 186.297 43.8525 186.169 44.1405C186.073 44.3645 185.881 44.4925 185.657 44.4925Z"
      fill={contentPrimary}
    />
    <path
      d="M199.481 50.7318C199.385 50.7318 199.321 50.6998 199.225 50.6678L194.521 48.5558C194.233 48.4278 194.105 48.0758 194.233 47.7878C194.361 47.4998 194.713 47.3718 195.001 47.4998L199.705 49.6118C199.993 49.7398 200.121 50.0918 199.993 50.3798C199.929 50.6038 199.705 50.7318 199.481 50.7318Z"
      fill={contentPrimary}
    />
    <path
      d="M192.281 41.9644C192.185 41.9644 192.121 41.9324 192.025 41.9004C191.737 41.7724 191.609 41.4204 191.737 41.1324L193.849 36.4284C193.977 36.1404 194.329 36.0124 194.617 36.1404C194.905 36.2684 195.033 36.6204 194.905 36.9084L192.793 41.6124C192.697 41.8364 192.505 41.9644 192.281 41.9644Z"
      fill={contentPrimary}
    />
    <path
      d="M186.043 55.8199C185.947 55.8199 185.883 55.7879 185.787 55.7559C185.499 55.6279 185.371 55.2759 185.499 54.9879L187.611 50.2839C187.739 49.9959 188.091 49.8679 188.379 49.9959C188.667 50.1239 188.795 50.4759 188.667 50.7639L186.555 55.4679C186.459 55.6919 186.267 55.8199 186.043 55.8199Z"
      fill={contentPrimary}
    />
    <path
      d="M180.698 50.1565C180.474 50.1565 180.25 49.9965 180.154 49.7725C180.026 49.4525 180.186 49.1325 180.506 49.0045L183.322 47.9485C183.642 47.8205 183.962 47.9805 184.09 48.3005C184.218 48.6205 184.058 48.9405 183.738 49.0685L180.922 50.1245C180.826 50.1245 180.762 50.1565 180.698 50.1565Z"
      fill={contentPrimary}
    />
    <path
      d="M196.889 44.012C196.665 44.012 196.441 43.852 196.345 43.628C196.217 43.308 196.377 42.988 196.697 42.86L199.513 41.804C199.833 41.676 200.153 41.836 200.281 42.156C200.409 42.476 200.249 42.796 199.929 42.924L197.113 43.98C197.049 44.012 196.985 44.012 196.889 44.012Z"
      fill={contentPrimary}
    />
    <path
      d="M187.672 39.852C187.448 39.852 187.224 39.692 187.128 39.468L186.072 36.652C185.944 36.332 186.104 36.012 186.424 35.884C186.744 35.756 187.064 35.916 187.192 36.236L188.248 39.052C188.376 39.372 188.216 39.692 187.896 39.82C187.832 39.852 187.736 39.852 187.672 39.852Z"
      fill={contentPrimary}
    />
    <path
      d="M193.816 56.0757C193.592 56.0757 193.368 55.9157 193.272 55.6917L192.216 52.8757C192.088 52.5557 192.248 52.2357 192.568 52.1077C192.888 51.9797 193.208 52.1397 193.336 52.4597L194.392 55.2757C194.52 55.5957 194.36 55.9157 194.04 56.0437C193.944 56.0437 193.88 56.0757 193.816 56.0757Z"
      fill={contentPrimary}
    />
    <path
      d="M69.8812 66.8282C69.6572 66.8282 69.4652 66.7322 69.3692 66.5082L66.9052 61.9962C66.7452 61.7082 66.8412 61.3562 67.1292 61.1962C67.4172 61.0362 67.7692 61.1322 67.9292 61.4202L70.3932 65.9322C70.5532 66.2202 70.4572 66.5722 70.1692 66.7322C70.0732 66.7962 69.9772 66.8282 69.8812 66.8282Z"
      fill={contentPrimary}
    />
    <path
      d="M77.1468 80.1397C76.9228 80.1397 76.7308 80.0437 76.6348 79.8197L74.1708 75.3077C74.0108 75.0197 74.1068 74.6677 74.3948 74.5077C74.6828 74.3477 75.0348 74.4437 75.1948 74.7317L77.6588 79.2437C77.8188 79.5317 77.7228 79.8837 77.4348 80.0437C77.3388 80.1397 77.2428 80.1397 77.1468 80.1397Z"
      fill={contentPrimary}
    />
    <path
      d="M76.6672 68.8124C76.4432 68.8124 76.2512 68.7164 76.1552 68.4924C75.9952 68.2044 76.0912 67.8524 76.3792 67.6924L80.8912 65.2284C81.1792 65.0684 81.5312 65.1644 81.6912 65.4524C81.8512 65.7404 81.7552 66.0924 81.4672 66.2524L76.9552 68.7164C76.8592 68.8124 76.7632 68.8124 76.6672 68.8124Z"
      fill={contentPrimary}
    />
    <path
      d="M63.3547 76.1084C63.1307 76.1084 62.9387 76.0124 62.8427 75.7884C62.6827 75.5004 62.7787 75.1484 63.0667 74.9884L67.5787 72.5243C67.8667 72.3643 68.2187 72.4603 68.3787 72.7483C68.5387 73.0363 68.4427 73.3883 68.1547 73.5483L63.6427 76.0124C63.5467 76.0764 63.4507 76.1084 63.3547 76.1084Z"
      fill={contentPrimary}
    />
    <path
      d="M65.3996 69.2281C65.3356 69.2281 65.3036 69.2281 65.2396 69.1961L62.3596 68.3641C62.0396 68.2681 61.8796 67.9481 61.9436 67.6281C62.0396 67.3081 62.3596 67.1481 62.6796 67.2121L65.5596 68.0441C65.8796 68.1401 66.0396 68.4601 65.9756 68.7801C65.8796 69.0361 65.6556 69.2281 65.3996 69.2281Z"
      fill={contentPrimary}
    />
    <path
      d="M82.0402 74.0924C81.9762 74.0924 81.9442 74.0924 81.8802 74.0604L79.0002 73.2284C78.6802 73.1324 78.5202 72.8124 78.5842 72.4924C78.6802 72.1724 79.0002 72.0124 79.3202 72.0764L82.2002 72.9084C82.5202 73.0044 82.6802 73.3244 82.6162 73.6444C82.5202 73.9324 82.2962 74.0924 82.0402 74.0924Z"
      fill={contentPrimary}
    />
    <path
      d="M74.2969 64.364C74.2329 64.364 74.2009 64.364 74.1369 64.332C73.8169 64.236 73.6569 63.916 73.7209 63.596L74.5529 60.716C74.6489 60.396 74.9689 60.236 75.2889 60.3C75.6089 60.396 75.7689 60.716 75.7049 61.036L74.8729 63.916C74.7769 64.204 74.5529 64.364 74.2969 64.364Z"
      fill={contentPrimary}
    />
    <path
      d="M69.4024 81.0037C69.3384 81.0037 69.3064 81.0037 69.2424 80.9717C68.9224 80.8757 68.7624 80.5557 68.8264 80.2357L69.6584 77.3557C69.7544 77.0357 70.0744 76.8757 70.3944 76.9397C70.7144 77.0357 70.8744 77.3557 70.8104 77.6757L69.9784 80.5557C69.9144 80.8117 69.6584 81.0037 69.4024 81.0037Z"
      fill={contentPrimary}
    />
  </Fragment>
));
IllustrationDriver.displayName = "IllustrationDriver";
