import { useState } from "react";
import * as React from "react";
import { useTheme } from "@emotion/react";
import { IconStarFilled, IconStarOutlined } from "@hubble/icons";
import { Link } from "react-router-dom";
import { CurrencyShortName, isCurrency, SupportedCurrencyPairs } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Money, PriceProps } from "@gemini-ui/components/Money";
import Sparkline from "@gemini-ui/components/Sparkline";
import { WatchlistResponseItem } from "@gemini-ui/constants/watchlist";
import { usePageData } from "@gemini-ui/contexts";
import {
  Button,
  Flex,
  size,
  Spacing,
  Table,
  Text,
  useFormatPercentageDelta,
  useToaster,
} from "@gemini-ui/design-system";
import { formatPercentage } from "@gemini-ui/design-system/utils/numberFormatting";
import { TradableAssets, useMarketAlerts, useWatchlist } from "@gemini-ui/pages/RetailTrade/AssetDetail/hooks";
import { CurrencyIconProps, PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { AssetLabel, CellText, CellTextProps, TruncateWrapper } from "@gemini-ui/pages/RetailTrade/Table/Cells/styles";
import { testIds } from "@gemini-ui/pages/RetailTrade/Table/Cells/testIds";
import { getCurrencyRoute } from "@gemini-ui/pages/RetailTrade/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const Asset = React.memo(function ({
  as,
  currency,
  Icon,
  name,
  route,
  className,
  onClick,
}: {
  as?: React.ComponentProps<typeof Table.LinkCell>["as"];
  currency: CurrencyShortName;
  Icon: CurrencyIconProps["Icon"];
  name: CurrencyIconProps["name"];
  route: CurrencyIconProps["route"];
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) {
  return (
    <Table.LinkCell as={as} data-testid={testIds.asset} route={route} className={className} onClick={onClick}>
      <AssetName Icon={Icon} name={name} currency={currency} />
    </Table.LinkCell>
  );
});

export function AssetName({
  Icon,
  name,
  currency,
  endArdornment,
}: Pick<CurrencyIconProps, "Icon" | "name"> & { currency: CurrencyShortName; endArdornment?: React.ReactNode }) {
  const { colorScheme } = useTheme();

  const assetLabelProps: React.ComponentProps<typeof AssetLabel> = {
    size: "md",
    title: name,
    as: "div",
    [Table.INTERACTIVE_CELL_TEXT_UNDERLINE_DATA_ATTR]: true,
    children: name,
  };

  let label = <AssetLabel {...assetLabelProps} />;
  label = endArdornment ? (
    <Flex alignItems="center">
      {label}
      {endArdornment}
    </Flex>
  ) : (
    label
  );

  return (
    <Flex alignItems="center" css={{ height: "100%", width: "100%" }}>
      <Icon css={{ minWidth: size[40], height: size[40], marginInlineEnd: Spacing.scale[1.5], flex: "none" }} />
      <TruncateWrapper>
        {label}
        <Text.Body size="sm" as="div" color={colorScheme.content.secondary}>
          {currency}
        </Text.Body>
      </TruncateWrapper>
    </Flex>
  );
}

const trackViewDetails = () => {
  track(EVENTS.VIEW_DETAILS_FROM_MARKET.name, {
    [EVENTS.VIEW_DETAILS_FROM_MARKET.properties.VIEW_DETAILS_TAPPED]: true,
  });
};

export function ViewDetails({
  currency,
  tradingPair,
}: {
  currency: CurrencyShortName;
  tradingPair: SupportedCurrencyPairs;
}) {
  const { intl } = useIntl();

  return (
    <Button.Secondary
      onClick={trackViewDetails}
      as={Link}
      to={getCurrencyRoute(currency, tradingPair, false)}
      data-testid={testIds.viewDetails}
      size="sm"
      role="link"
      cta={intl.formatMessage({ defaultMessage: "View details" })}
      type={null}
    />
  );
}

export function ToggleWatchList({
  currency,
  enabledWatchlist,
  assetsTradable,
  refetchWatchlist,
}: {
  currency: CurrencyShortName;
  enabledWatchlist: {
    favoritesWatch: WatchlistResponseItem[];
    marketMovementWatch: WatchlistResponseItem[];
  };
  assetsTradable: TradableAssets;
  refetchWatchlist: (disableLoading: boolean) => Promise<void>;
}) {
  const { showToast } = useToaster();
  const { defaultFiat } = usePageData().templateProps.account;
  const { intl } = useIntl();

  const [marketAlertEnabled, setMarketAlertEnabled] = useState(
    Boolean(enabledWatchlist.marketMovementWatch.find(asset => asset.tradingPair === `${currency}${defaultFiat}`))
  );

  const [favoriteEnabled, setFavoriteEnabled] = useState(
    Boolean(enabledWatchlist.favoritesWatch.find(asset => asset.tradingPair === `${currency}${defaultFiat}`))
  );

  const onToggleComplete = () => {
    refetchWatchlist(false);
  };

  const asset = assetsTradable[currency];
  const disabled = !asset.isTradable;

  const { handleToggleIsWatching, isToggling } = useWatchlist(
    currency,
    disabled,
    true,
    favoriteEnabled,
    onToggleComplete
  );
  const { handleToggleChange } = useMarketAlerts(currency, true, marketAlertEnabled);

  const handleClick = () => {
    handleToggleIsWatching();

    const enabled = !favoriteEnabled;

    setMarketAlertEnabled(!marketAlertEnabled);
    setFavoriteEnabled(enabled);

    // Enable market alert if it isn't already
    if (!marketAlertEnabled) {
      handleToggleChange();
    }

    showToast({
      message: intl.formatMessage(
        defineMessage({
          defaultMessage: "{asset} {enabled, select, true {added to} other {removed from}} your watchlist.",
        }),
        {
          enabled,
          asset: asset.displayName,
        }
      ),
    });
  };

  return (
    <Button.Tertiary
      data-testid={`watchlist-button-${currency}`}
      aria-label={`watchlist-button-${currency}`}
      onClick={handleClick}
      disabled={disabled}
      loading={isToggling}
      icon={favoriteEnabled ? <IconStarFilled /> : <IconStarOutlined />}
    />
  );
}

export const PriceMobile = React.memo(
  (props: {
    currency: CurrencyShortName;
    value: string | number;
    percentDelta: string;
    priceDelta: string;
    tradingPair: SupportedCurrencyPairs;
    as?: React.ComponentProps<typeof Table.LinkCell>["as"];
  }) => {
    const { as, currency, tradingPair } = props;

    return (
      <Table.LinkCell
        as={as}
        data-testid={testIds.priceMobile}
        tabIndex={-1} // Make full row pressable on mobile web. Remove tab stop.
        route={getCurrencyRoute(currency, tradingPair, false)}
      >
        <PriceConsolidated {...props} />
      </Table.LinkCell>
    );
  }
);

export function Price({
  tradingPair,
  value,
  hideTrailingSign = true,
  ...rest
}: {
  value: PriceProps["value"];
  tradingPair: PriceProps["tradingPair"];
  hideTrailingSign?: PriceProps["hideTrailingSign"];
} & React.ComponentPropsWithoutRef<typeof Text.Body>) {
  return (
    <CellText {...rest} numeric data-testid={testIds.price}>
      <Money.Price value={value} tradingPair={tradingPair} hideTrailingSign={hideTrailingSign} />
    </CellText>
  );
}

export function PercentDelta({
  currency,
  parseToFloat,
  value,
  prefix = "",
  suffix = "",
  ...rest
}: {
  currency?: CurrencyShortName;
  parseToFloat?: boolean;
  value: string;
  prefix?: string;
  suffix?: string;
} & CellTextProps) {
  const { intl } = useIntl();
  const [percentage, color] = useFormatPercentageDelta({
    parseToFloat,
    value,
    locale: intl.locale,
    isContentSecondary: rest.isSecondary,
  });

  return (
    <GUSDCell currency={currency}>
      <CellText {...rest} numeric data-testid={testIds.percentDelta} color={color}>
        {prefix + percentage + suffix}
      </CellText>
    </GUSDCell>
  );
}

export function PriceDelta({
  currency,
  value,
  tradingPair,
  isSecondary,
  ...rest
}: {
  currency?: CurrencyShortName;
  value: string | number;
  tradingPair: PriceProps["tradingPair"];
} & CellTextProps) {
  const { colorScheme } = useTheme();

  if (isCurrency.GUSD(currency)) return null;

  const statusColor = colorScheme.status.default.content;
  let color: string = colorScheme.content[isSecondary ? "secondary" : "primary"];
  let isPositive = false;
  const parsed = parseFloat(`${value}`);

  if (!Number.isNaN(parsed) && parsed !== 0) {
    isPositive = parsed > 0;
    color = isPositive ? statusColor.positive : statusColor.negative;
  }

  return (
    <CellText {...rest} data-testid={testIds.priceDelta} color={color} isSecondary={isSecondary} numeric>
      {isPositive && "+"}
      <Money.Price tradingPair={tradingPair} value={value} hideTrailingSign />
    </CellText>
  );
}

export function PriceConsolidated({
  currency,
  hideTrailingSign,
  percentDelta,
  parseToFloat,
  priceDelta,
  tradingPair,
  value,
}: {
  currency?: CurrencyShortName;
  hideTrailingSign?: PriceProps["hideTrailingSign"];
  percentDelta?: string;
  parseToFloat?: boolean;
  priceDelta?: string;
  tradingPair: SupportedCurrencyPairs;
  value: string | number;
}) {
  const priceDeltaEl =
    priceDelta != null && !isCurrency.GUSD(currency) ? (
      <PriceDelta value={priceDelta} tradingPair={tradingPair} isSecondary />
    ) : null;

  const percentDeltaEl =
    percentDelta != null && !isCurrency.GUSD(currency) ? (
      <PercentDelta
        parseToFloat={parseToFloat}
        prefix={priceDelta ? "  (" : ""}
        value={percentDelta}
        suffix={priceDelta ? ")" : ""}
        isSecondary
      />
    ) : null;

  return (
    <NumericCell
      primaryProps={{
        children: <Money.Price value={value} tradingPair={tradingPair} hideTrailingSign={hideTrailingSign} />,
      }}
      secondaryProps={
        priceDeltaEl || percentDeltaEl
          ? {
              children: (
                <div>
                  {priceDeltaEl}
                  {percentDeltaEl}
                </div>
              ),
            }
          : undefined
      }
    />
  );
}

export function MiniChart({
  currency,
  tradingPair,
  allPairDetails,
}: {
  allPairDetails: PairDetail[];
  currency: CurrencyShortName;
  tradingPair: SupportedCurrencyPairs;
}) {
  const { colorScheme } = useTheme();

  if (isCurrency.GUSD(currency)) return null;

  const data = allPairDetails.find(pair => pair.symbol === tradingPair);
  // This is currently only used on the Retail homepage for the Watchlist section's sparklines.
  // allPairDetails is currently calling /alldetails/1m?priceCurrency=USD&skip=6.
  // Since skip=6, the data points are every 6 minutes, so grab 240 data points to get 24 hrs. (6 min * 240 = 1440 min = 24 hrs)
  const range = data && data.range.length >= 240 ? data.range.slice(-240) : [];
  const color =
    Number(data?.["24hrPercentDelta"]) < 0
      ? colorScheme.dataVisualization.marketNegative
      : colorScheme.dataVisualization.marketPositive;

  const dataPoints = range.map(point => String(point.y));

  return (
    <div data-testid={testIds.miniChart}>
      <Sparkline width={48} height={24} dataPoints={dataPoints} color={color} strokeWidth={1.4} />
    </div>
  );
}

function GUSDCell({ currency, children }: { currency?: CurrencyShortName; children: React.ReactNode }) {
  return <React.Fragment>{isCurrency.GUSD(currency) ? null : children}</React.Fragment>;
}

export interface DefaultCellProps {
  primaryProps: React.ComponentPropsWithoutRef<typeof CellText>;
  secondaryProps?: React.ComponentPropsWithoutRef<typeof CellText>;
}

export function DefaultCell({ primaryProps, secondaryProps }: DefaultCellProps) {
  return (
    <TruncateWrapper>
      <CellText as="div" {...primaryProps} />
      {secondaryProps && secondaryProps.children != null ? <CellText as="div" isSecondary {...secondaryProps} /> : null}
    </TruncateWrapper>
  );
}

export function NumericCell({ primaryProps, secondaryProps }: DefaultCellProps) {
  return (
    <DefaultCell
      primaryProps={{ ...primaryProps, numeric: true }}
      secondaryProps={{ ...secondaryProps, numeric: true }}
    />
  );
}

export function PortfolioPercent({ value }: { value?: string }) {
  const { intl } = useIntl();

  return <NumericCell primaryProps={{ children: formatPercentage(value, intl.locale) }} />;
}
