import { forwardRef } from "react";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import { BaseText } from "@gemini-ui/design-system/Text/styles";

type CustomizableLegalProps = Omit<BaseTextProps, "color" | "fontSize">;

const LegalText = forwardRef<HTMLParagraphElement, CustomizableLegalProps>((props: CustomizableLegalProps, ref) => {
  return <BaseText ref={ref} fontSize="12px" lineHeight={Spacing.scale[2]} color={Colors.gray[500]} {...props} />;
});

export default LegalText;
