import { forwardRef, Ref, useEffect, useRef } from "react";
import { IconClose } from "@hubble/icons";
import { TransitionStatus } from "react-transition-group/Transition";
import { Button } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Text } from "@gemini-ui/design-system/Text";
import { TOASTER_DURATIONS, ToastProps } from "@gemini-ui/design-system/Toaster/constants";
import { ToastContainer, ToastContent } from "@gemini-ui/design-system/Toaster/styles";
import { useIntl } from "@gemini-ui/utils/intl";

export const Toast = forwardRef(
  (
    {
      "data-testid": dataTestId = "toast",
      message,
      transitionStatus,
      hideToast,
      onDismiss,
      uuid,
    }: ToastProps & { hideToast?: (uuid: string) => void; transitionStatus: TransitionStatus },
    ref: Ref<HTMLDivElement>
  ) => {
    const { intl } = useIntl();
    const toastTimeout = useRef(null);

    useEffect(() => {
      if (hideToast) {
        toastTimeout.current = setTimeout(() => {
          hideToast(uuid);
        }, TOASTER_DURATIONS.timeout);

        return () => clearTimeout(toastTimeout.current);
      }
    }, [uuid, hideToast]);

    return (
      <ToastContainer transitionStatus={transitionStatus} role="alert" aria-live="polite" ref={ref}>
        <ToastContent transitionStatus={transitionStatus}>
          <Text.Body as="div" bold size="sm" mr={2} data-testid={`${dataTestId}-message`}>
            {message}
          </Text.Body>
          <Flex shrink={0}>
            <Button.Tertiary
              data-testid={`${dataTestId}-close-button`}
              onClick={() => {
                hideToast?.(uuid);
                onDismiss?.();
              }}
              icon={<IconClose />}
              size="sm"
              aria-label={intl.formatMessage({ defaultMessage: "Close toast" })}
            />
          </Flex>
        </ToastContent>
      </ToastContainer>
    );
  }
);
