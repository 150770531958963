import { useEffect } from "react";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { Checkbox, Form, Spacer, Text, useForm } from "@gemini-ui/design-system";
import { SSNInput, SubmitButton } from "@gemini-ui/pages/Credit/CreditApplication/components";
import { FIELD_NAMES, FormValues, OnboardingComponentProps } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { getNewUserFormCopy } from "@gemini-ui/pages/Credit/CreditApplication/copy";
import { handleNewAppSubmit } from "@gemini-ui/pages/Credit/CreditApplication/handlers/handleNewAppSubmit";
import { useFormValidation, useSendEmail } from "@gemini-ui/pages/Credit/CreditApplication/hooks";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/testIds";
import { StyledList } from "@gemini-ui/pages/Credit/CreditApplication/Views/styles";
import { useIntl } from "@gemini-ui/utils/intl";

export const ExistingUserProvePrefill = ({ send, user }: OnboardingComponentProps) => {
  const {
    handleSubmit,
    register,
    setFocus,
    watch,
    trigger,
    control,
    formState: { errors, isValid, isSubmitting, touchedFields },
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      ssnLastFour: "",
      newAppTerms: false,
    },
  });
  const { showAlert } = useAlert();
  const validation = useFormValidation();
  const { intl } = useIntl();
  const copy = getNewUserFormCopy(intl);

  const [newAppTerms] = watch([FIELD_NAMES.newAppTerms]);

  const [, handleSendVerificationEmail] = useSendEmail();

  useEffect(() => {
    setFocus(FIELD_NAMES.ssnLastFour);
  }, [setFocus]);

  const onSubmit = async values => {
    await handleNewAppSubmit({ send, values, showAlert, isLoggedIn: user.isLoggedIn, handleSendVerificationEmail });
  };

  return (
    <div>
      <ViewTitle>
        <Text.Heading>{intl.formatMessage({ defaultMessage: "Let’s get started!" })}</Text.Heading>
        <Text.Body>
          {intl.formatMessage({ defaultMessage: "Enter your information below to begin pre-qualification." })}
        </Text.Body>
      </ViewTitle>
      <Form data-testid={testIds.form.newUserApplication} onSubmit={handleSubmit(onSubmit)}>
        <SSNInput<FormValues>
          name={FIELD_NAMES.ssnLastFour}
          control={control}
          rules={validation.ssnLastFour}
          initialType="password"
          data-testid={testIds.input.nationalIdNumber}
          label={intl.formatMessage({ defaultMessage: "Social security number" })}
          placeholder="XXXX"
          error={touchedFields.ssnLastFour && errors?.ssnLastFour?.message}
          inputSize="lg"
          message={intl.formatMessage({ defaultMessage: "Enter the last 4 digits of your SSN" })}
          isLastFour
        />
        <Spacer mb={2}>
          <Text.Body size="xs" mb={1}>
            {copy.REVIEW_TERMS}
          </Text.Body>
          <Checkbox
            {...register(FIELD_NAMES.newAppTerms, {
              ...validation.newAppTerms,
              onChange: () => trigger(FIELD_NAMES.newAppTerms),
            })}
            data-testid={testIds.checkbox.newAppTerms}
            error={errors?.newAppTerms?.message}
            checked={Boolean(newAppTerms)}
          >
            <Text.Body size="xs">{copy.AGREE_TO_TERMS}</Text.Body>
          </Checkbox>
          <StyledList>
            <li>
              <Text.Body size="xs">{copy.ELECTRONIC_DELIVERY_CONSENT_STATEMENT}</Text.Body>
            </li>
            <li>
              <Text.Body size="xs">{copy.AUTOMATED_AUTHORIZATION}</Text.Body>
            </li>
          </StyledList>
          <Text.Body size="xs">{copy.AGREE_TO_GEMINI_ACCOUNT}</Text.Body>
          <StyledList>
            <li>
              <Text.Body size="xs">{copy.USER_AGREEMENT}</Text.Body>
            </li>
            <li>
              <Text.Body size="xs">{copy.PRIVACY_POLICY}</Text.Body>
            </li>
          </StyledList>
        </Spacer>
        <SubmitButton
          data-testid={testIds.button.submitNewApp}
          type="submit"
          loading={isSubmitting}
          disabled={!isValid}
        >
          {intl.formatMessage({ defaultMessage: "Continue" })}
        </SubmitButton>
      </Form>
    </div>
  );
};
