import { useImperativeHandle, useRef } from "react";
import * as React from "react";
import BigNumber from "bignumber.js";
import { bigNumberToDecimalString, CURRENCIES_DETAIL } from "@gemini-common/scripts/constants/currencies";
import { AmountInputProps } from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input/Input";
import { InputLeftElement, InputRightElement } from "@gemini-ui/design-system/__deprecated__/Input/InputElements";
import { AmountLabel } from "@gemini-ui/design-system/__deprecated__/Input/styles";
import { setReactInputValue } from "@gemini-ui/design-system/__deprecated__/Input/utils";
import { Colors } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";
import { useIntl } from "@gemini-ui/utils/intl";

export const AmountInput = React.forwardRef<HTMLInputElement, AmountInputProps>(
  (
    {
      ["data-testid"]: dataTestId,
      currency,
      helperText,
      isApprox,
      label,
      maxLabel,
      maxValue,
      onMaxButtonClick,
      value,
      ...props
    },
    forwardRef
  ) => {
    const { intl } = useIntl();
    const inputRef = useRef(null);
    useImperativeHandle(forwardRef, () => inputRef.current);

    const leadingSymbol = CURRENCIES_DETAIL[currency]?.isNotional && CURRENCIES_DETAIL[currency].leadingSymbol;

    return (
      <Input
        type="number"
        step="any"
        placeholder="0"
        autoComplete="off"
        data-testid={dataTestId}
        value={value}
        label={
          <AmountLabel>
            <span>{label || intl.formatMessage({ defaultMessage: "Amount" })}</span>
            {(Boolean(maxValue) || Boolean(onMaxButtonClick)) && (
              <Text.Body size="xs">
                <Text.Link
                  data-testid={`${dataTestId}-max-button`}
                  bold
                  href="#"
                  onMouseDown={e => e.preventDefault()}
                  onClick={e => {
                    e.preventDefault();
                    if (maxValue) {
                      // Set max value based on currency precision. Then fire onChange.
                      const val = bigNumberToDecimalString(new BigNumber(maxValue), currency);
                      setReactInputValue(inputRef.current, val);
                    }
                    // optionally call maxButtonClick handler if set
                    onMaxButtonClick && onMaxButtonClick(e);
                  }}
                >
                  {maxLabel || intl.formatMessage({ defaultMessage: "Max" })}
                </Text.Link>
              </Text.Body>
            )}
          </AmountLabel>
        }
        pl={Boolean(leadingSymbol) && 3}
        leftElement={
          Boolean(leadingSymbol) && (
            <InputLeftElement alignItems="center">
              <Text.Body color={Colors.gray[600]} size="md" data-testid={`${dataTestId}-symbol`} ml={1.5} mr={0.5}>
                {leadingSymbol}
              </Text.Body>
            </InputLeftElement>
          )
        }
        pr={8}
        rightElement={
          <InputRightElement>
            <Text.Label variant="tertiary" bold data-testid={`${dataTestId}-currency`} mr={2}>
              {currency}
            </Text.Label>
          </InputRightElement>
        }
        helperText={
          helperText && (
            <React.Fragment>
              {isApprox && intl.formatMessage({ defaultMessage: "approx. " })}
              {helperText}
            </React.Fragment>
          )
        }
        ref={inputRef}
        {...props}
      />
    );
  }
);
