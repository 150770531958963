import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { Flex, Modal, Segment, Spacer, Table, Text, useToaster } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import {
  DEPOSIT,
  TRADE,
  TRANSFER_LIMIT_AND_TIMINGS_TABS,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/transferLimitAndTiming/constants";
import {
  getColumns,
  getTabDataById,
  getTransferLimitAndTimingLabelsByTabId,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/transferLimitAndTiming/helpers";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const TransferLimitAndTimingModal = ({ isOpen, title, onBack, currency, subaccountHashid, isInstitutional }) => {
  const [selectedTab, setSelectedTab] = useState(DEPOSIT);
  const { intl } = useIntl();
  const theme = useTheme();
  const labels = getTransferLimitAndTimingLabelsByTabId(intl);
  const [depositLimits, setDepositLimits] = useState(null);
  const [withdrawalLimits, setWithdrawalLimits] = useState(null);
  const isInstitutionalPaypalDisabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_INSTITUTIONAL_PAYPAL_DISABLED) && isInstitutional;
  const {
    templateProps: {
      user: { countryCode },
      account: { geminiEntity },
    },
  } = usePageData();
  const { showToast } = useToaster();

  const fetchWithdrawalLimits = useCallback(async () => {
    try {
      const { data: withdrawalLimits } = await axios.get(`/payments/transfers/limits/withdraw?currency=${currency}`, {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      });
      return withdrawalLimits;
    } catch (error) {
      const message = getError(error);
      Sentry.captureMessage(`Error fetching withdrawal limits: ${message}`);
      showToast({ message: message });
    }
  }, [currency, showToast, subaccountHashid]);
  const fetchDepositLimits = useCallback(async () => {
    try {
      const { data: depositLimits } = await axios.get(`/payments/transfers/limits/deposit?currency=${currency}`, {
        headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
      });
      return depositLimits;
    } catch (error) {
      const message = getError(error);
      Sentry.captureMessage(`Error fetching deposit limits: ${message}`);
      showToast({ message: message });
    }
  }, [currency, showToast, subaccountHashid]);
  const fetchLimits = useCallback(async () => {
    const [depositLimits, withdrawalLimits] = await Promise.all([fetchDepositLimits(), fetchWithdrawalLimits()]);
    setDepositLimits(depositLimits);
    setWithdrawalLimits(withdrawalLimits);
  }, [fetchDepositLimits, fetchWithdrawalLimits]);

  useEffect(() => {
    fetchLimits();
  }, [currency, fetchLimits, subaccountHashid]);

  const onTabClick = e => setSelectedTab(e.target.id);
  const tableTabs = TRANSFER_LIMIT_AND_TIMINGS_TABS.filter(tab =>
    isInstitutionalPaypalDisabled ? tab !== TRADE : true
  );
  const tableData = getTabDataById(depositLimits, withdrawalLimits, currency, countryCode, geminiEntity, intl)[
    selectedTab
  ].filter(tabData => (isInstitutionalPaypalDisabled ? tabData.transferType !== LinkPaymentType.PAYPAL : true));
  return (
    <Modal isOpen={isOpen} onClose={onBack} onBack={onBack} title={title} hasLargeTitle>
      {depositLimits && withdrawalLimits ? (
        <Spacer mt={3}>
          <Segment value={selectedTab} variant="background" stretch={true}>
            {tableTabs.map(tab => (
              <Segment.Button id={tab} key={tab} onClick={onTabClick} data-testid={tab} value={tab} cta={labels[tab]} />
            ))}
          </Segment>
          <Spacer m={1}>
            <Table columns={getColumns(intl)} data={tableData} rowSize="md" />
          </Spacer>
          <Text.Body size="xs" mt={2} pl={2} pr={2} color={theme.colorScheme.content.secondary}>
            {selectedTab === TRADE
              ? intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "When placing an order with the above payment methods, after trade is executed, funds take the respective transfer time to settle, during which the assets are placed on hold. For a complete list of fees, see <TransferFeeLink>transfer fee schedule</TransferFeeLink> or <DesktopFeeLink>desktop fee schedule</DesktopFeeLink>.",
                  }),
                  {
                    TransferFeeLink: (str: ReactNode) => (
                      <Text.Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.gemini.com/fees/transfer-fee-schedule#section-fiat-transfer-fees"
                        color={theme.colorScheme.content.secondary}
                      >
                        {str}
                      </Text.Link>
                    ),
                    DesktopFeeLink: (str: ReactNode) => (
                      <Text.Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.gemini.com/fees/web-fee-schedule"
                        color={theme.colorScheme.content.secondary}
                      >
                        {str}
                      </Text.Link>
                    ),
                  }
                )
              : intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "For a complete list of fees, see <TransferFeeLink>transfer fee schedule</TransferFeeLink> or <DesktopFeeLink>desktop fee schedule</DesktopFeeLink>.",
                  }),
                  {
                    TransferFeeLink: (str: ReactNode) => (
                      <Text.Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.gemini.com/fees/transfer-fee-schedule#section-fiat-transfer-fees"
                        color={theme.colorScheme.content.secondary}
                        onClick={() => {
                          const { name } = EVENTS.OPEN_TRANSFER_FEE_SCHEDULE_LINK;
                          track(name);
                        }}
                      >
                        {str}
                      </Text.Link>
                    ),
                    DesktopFeeLink: (str: ReactNode) => (
                      <Text.Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.gemini.com/fees/web-fee-schedule"
                        color={theme.colorScheme.content.secondary}
                        onClick={() => {
                          const { name } = EVENTS.OPEN_DESKTOP_FEE_SCHEDULE_LINK;
                          track(name);
                        }}
                      >
                        {str}
                      </Text.Link>
                    ),
                  }
                )}
          </Text.Body>
        </Spacer>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <SpinnerAnimation />
        </Flex>
      )}
    </Modal>
  );
};

export default TransferLimitAndTimingModal;
