import { ReactNode } from "react";
import { Colors, Flex, ListItem, Text } from "@gemini-ui/design-system";
import { ListItemProps } from "@gemini-ui/design-system/ListItem/constants";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props extends ListItemProps {
  label: string | ReactNode;
  labelTestId?: string;
  text: string | ReactNode;
  textTestId?: string;
  subText?: string | ReactNode;
  subTextTestId?: string;
}

const SummaryLineItem = ({ label, labelTestId, text, textTestId, subText, subTextTestId, ...listItemProps }: Props) => {
  const { intl } = useIntl();

  const isTotal = label === intl.formatMessage({ defaultMessage: "Total" });

  return (
    <ListItem
      size="dense"
      padding="sm"
      alignItems="center"
      {...listItemProps}
      right={
        <Flex flexDirection="column" alignItems="flex-end">
          <Text.Body size="md" bold={isTotal} data-testid={textTestId} align="right">
            {text}
          </Text.Body>
          {subText && (
            <Text.Body size="sm" data-testid={subTextTestId} align="right" color={Colors.gray[600]}>
              {subText}
            </Text.Body>
          )}
        </Flex>
      }
    >
      <Text.Body bold={isTotal} size="md" data-testid={labelTestId}>
        {label}
      </Text.Body>
    </ListItem>
  );
};

export default SummaryLineItem;
