import { AxiosError } from "axios";

export const downgradeError = (axiosError: AxiosError): boolean => {
  const data = axiosError.response?.data as { error: string };
  const responseErrorMsg = axiosError.response ? data?.error : "";
  const unauthenticated = axiosError.status === 401 && responseErrorMsg === "needsLogin";
  const forbidden = axiosError.status === 403 && responseErrorMsg === "noAccess";

  return unauthenticated || forbidden;
};
