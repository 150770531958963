import { Fragment, InvalidEvent, useEffect, useState } from "react";
import { CardColorEnum, Name } from "@gemini-ui/client/credit";
import { Colors, Text } from "@gemini-ui/design-system";
import blackBack2x from "@gemini-ui/images/credit/credit-card-black-back-blank@2x.png";
import black2x from "@gemini-ui/images/credit/credit-card-black-blank@2x.png";
import roseBack2x from "@gemini-ui/images/credit/credit-card-rose-back-blank@2x.png";
import rose2x from "@gemini-ui/images/credit/credit-card-rose-blank@2x.png";
import silverBack2x from "@gemini-ui/images/credit/credit-card-silver-back-blank@2x.png";
import silver2x from "@gemini-ui/images/credit/credit-card-silver-blank@2x.png";
import {
  StyledCardFace,
  StyledCardSheen,
  StyledDepth,
  StyledFloatContainer,
  StyledInnerFloatContainer,
  StyledName,
} from "@gemini-ui/images/credit/styles";
import { CARD_COLOR_TYPE } from "@gemini-ui/pages/Credit/CreditDashboard/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const COLORS = {
  black2x,
  blackBack2x,
  silver2x,
  silverBack2x,
  rose2x,
  roseBack2x,
};

interface Props {
  variant: CARD_COLOR_TYPE;
  className?: string;
  name?: Name;
  cardImagesLoaded: boolean;
  setCardImagesLoaded: (cardImagesLoaded: boolean) => void;
}

const errorHandler = (src: string) => (e: InvalidEvent<HTMLImageElement>) => {
  e.target.onerror = null;
  e.target.src = src;
};

const ANIMATION_TIME = 1000;

const CreditCardPicker3d = (props: Props) => {
  const { className, variant, name, cardImagesLoaded: loaded, setCardImagesLoaded: setLoaded } = props;

  const [flip, setFlip] = useState(false);
  const [animating, setAnimating] = useState(false);
  const getSrc = (c: string, isBack?: boolean) => COLORS[`${c}${isBack ? "Back" : ""}2x`];
  const srcFront = getSrc(variant.toLowerCase());
  const srcBack = getSrc(variant.toLowerCase(), true);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const { intl } = useIntl();
  const nameOnCard = `${name.firstName ?? intl.formatMessage({ defaultMessage: "Satoshi" })} ${
    name.lastName ?? intl.formatMessage({ defaultMessage: "Nakamoto" })
  }`;

  let animationTimeout = null;

  useEffect(() => {
    if (!loaded) {
      const frontImagesPromise = new Promise((resolve, reject) => {
        let counter = 0;
        Object.keys(CardColorEnum).forEach(c => {
          const srcFront = getSrc(c.toLowerCase());
          const imgFront = new Image();
          imgFront.src = srcFront;
          imgFront.onload = () => {
            counter++;
            if (counter === Object.keys(CardColorEnum).length - 1) {
              resolve(true);
            }
          };
          imgFront.onerror = () => reject(false);
        });
      });

      const backImagesPromise = new Promise((resolve, reject) => {
        let counter = 0;
        Object.keys(CardColorEnum).forEach(c => {
          const imgBack = new Image();
          imgBack.src = srcBack;
          imgBack.onload = () => {
            counter++;
            if (counter === Object.keys(CardColorEnum).length - 1) {
              resolve(true);
            }
          };
          imgBack.onerror = () => reject(false);
        });
      });

      Promise.all([frontImagesPromise, backImagesPromise])
        .then(() => {
          if (!loaded) setLoaded(true);
        })
        .catch(err => console.log(err));
    }

    return () => clearTimeout(animationTimeout);
  }, [animating, animationTimeout, loaded, setLoaded, srcBack]);

  const handleFlip = () => {
    if (!animating) {
      setAnimating(true);
      setFlip(!flip);
    }

    animationTimeout = setTimeout(() => setAnimating(false), ANIMATION_TIME);
    return;
  };

  return (
    <Fragment>
      <StyledFloatContainer onClick={handleFlip} loaded={loaded}>
        <StyledInnerFloatContainer isFlipped={flip} id="card-container">
          <StyledCardFace
            isBackface
            src={srcBack}
            className={className}
            alt={`credit-card-${variant.toLocaleLowerCase()}`}
            onError={errorHandler(srcBack)}
          />
          <StyledName>
            <Text.Body>{nameOnCard}</Text.Body>
          </StyledName>
          <StyledCardSheen animating={animating} isBackface />
          <StyledDepth isSafari={isSafari} />
          <StyledDepth isSafari={isSafari} />
          <StyledDepth isSafari={isSafari} />
          <StyledCardFace
            src={srcFront}
            className={className}
            alt={`credit-card-${variant.toLocaleLowerCase()}`}
            onError={errorHandler(srcFront)}
          />
          <StyledCardSheen animating={animating} />
        </StyledInnerFloatContainer>
      </StyledFloatContainer>

      <Text.Body size="xs" center mt={2}>
        <Text.Link color={Colors.gray[600]} onClick={handleFlip} data-testid="tap-to-flip">
          {intl.formatMessage(defineMessage({ defaultMessage: `Tap to see {side}` }), {
            side: flip ? "front" : "back",
          })}
        </Text.Link>
      </Text.Body>
    </Fragment>
  );
};

export default CreditCardPicker3d;
