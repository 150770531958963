import { PlaidLinkOnSuccess } from "react-plaid-link";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";

export interface PlaidLinkProps {
  linkToken: string;
  handlePlaidNotSupported: () => void;
  isOpen: boolean;
  onClose: () => void;
  isRedirect?: boolean;
  onSuccess: PlaidLinkOnSuccess;
  onError: (error?: string) => void;
}

export type UsePlaidLinkTokenOptions = {
  initialLinkToken?: string;
};

export interface PlaidModalProps {
  userCountryCode: CountryAbbreviation;
  isPlaidRuxEnabled: boolean;
  isOpen: boolean;
  handlePlaidSuccess: () => void;
  onSuccess?: () => void;
  onClose: () => void;
  onBack?: () => void;
  handlePlaidUnsupportedModalSubmit: () => void;
  openGiactModal?: () => void;
  isSettingsOrOnboarding?: boolean;
  openSelectPaymentMethodModal?: () => void;
  scope?: PaymentTypeScope;
  subaccountHashid: string;
  onSuccessCallback?: () => void;
}

export enum RuxLoadingState {
  USE_RUX,
  DONT_USE_RUX,
}

export enum PlaidModalState {
  LOADING,
  PLAID_RUX_DISCLAIMER,
  PLAID_DISCLAIMER,
  LINK_ERROR,
  LINK_BANK,
  UNSUPPORTED,
  LINK_PPI_BANK_SUCCESS,
  BANK_BLOCKED,
  SUPPORTED_BANKS,
}

export type PlaidErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const INSTITUTION_NOT_FOUND = "institution_not_found";
const SEARCH_INSTITUTION = "SEARCH_INSTITUTION";
const SELECT_INSTITUTION = "SELECT_INSTITUTION";
const PLAID_LINK_TOKEN_STORAGE_KEY = "PLAID_LINK_TOKEN";
const GENERIC_PLAID_ERROR_MESSAGE =
  "An error occurred when attempting to link your bank with Plaid. Please try again, or link your bank account via wire.";

export {
  GENERIC_PLAID_ERROR_MESSAGE,
  INSTITUTION_NOT_FOUND,
  PLAID_LINK_TOKEN_STORAGE_KEY,
  SEARCH_INSTITUTION,
  SELECT_INSTITUTION,
};
