import { useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { maxBy } from "lodash";
import { track } from "@gemini-ui/analytics";
import { EVENTS } from "@gemini-ui/analytics/constants/events";
import { TRADE_EVENTS } from "@gemini-ui/analytics/constants/trade";
import { LoadingErrorSection } from "@gemini-ui/components/Services/LoadingErrorSection";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Modal, SkeletonLoader } from "@gemini-ui/design-system";
import AgreementSummary from "@gemini-ui/pages/Earn/Deposit/AcceptAgreement/AgreementSummary";
import StakingAgreement from "@gemini-ui/pages/Earn/Deposit/AcceptAgreement/StakingAgreement";
import AssetDisclaimer from "@gemini-ui/pages/Earn/Deposit/AssetDisclaimer";
import { stakingAssetsWithDisclaimers } from "@gemini-ui/pages/Earn/Deposit/AssetDisclaimer/constants";
import CountryTaxInformation from "@gemini-ui/pages/Earn/Deposit/CountryTaxInformation";
import { countryHasStakingTaxImplications } from "@gemini-ui/pages/Earn/Deposit/CountryTaxInformation/constants";
import PlaceDeposit from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit";
import ReviewBuyDeposit from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit";
import { ReviewDeposit } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit";
import { ModalTitle } from "@gemini-ui/pages/Earn/Deposit/styles";
import Success from "@gemini-ui/pages/Earn/Deposit/Success";
import {
  DepositAccountState,
  DepositFlowModal,
  DepositModalView,
  DepositProps,
  RecurringDepositState,
  SourceType,
} from "@gemini-ui/pages/Earn/Deposit/types";
import {
  createAchPaymentMethods,
  createDebitPaymentMethods,
  createFiatBalancePaymentMethod,
  createPayPalPaymentMethods,
} from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { StakingFundingSourcesScreen } from "@gemini-ui/pages/Earn/GrowBuy/screens/StakingFundingSourcesScreen";
import { useGrowAvailability } from "@gemini-ui/pages/Earn/hooks/useGrowAvailability";
import { PickETHStakingType } from "@gemini-ui/pages/Earn/PrivateETHStaking/PickETHStakingType";
import { TradePaymentMethodType } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { getPaymentMethodType } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { useAccountBalances } from "@gemini-ui/services/account/balances";
import axios from "@gemini-ui/services/axios";
import { PaymentMethodEligibility, usePaymentData } from "@gemini-ui/services/transfer/paymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

const initialAccountState = {
  earnAgreement: "",
  masterLoanAgreement: "",
};

const initialRecurringDepositState = {
  triggerHour: "",
  startOn: new Date(),
  utcTime: null,
};

const { BankSettingsController } = jsRoutes.controllers.settings;

export const DepositFlow = (props: DepositProps) => {
  const {
    onClose,
    initialAccountState: initialAccountStateFromProps,
    initialRecurringDepositState: initialRecurringDepositStateFromProps,
  } = props;

  const { intl } = useIntl();

  const {
    templateProps: {
      account: { defaultFiat },
      user: { countryCode },
    },
  } = usePageData();

  const paymentMethodData = usePaymentData(defaultFiat, PaymentMethodEligibility.BUY);
  const balancesData = useAccountBalances();

  const {
    asset,
    currency: contextCurrency,
    provider: contextProvider,
    depositStatus: { source },
    router: { currentView: modalView },
    closeModal,
    goBack,
    setModalView,
    updateDepositStatus,
  } = useGrowBuy();

  const { providersRequiringStakingAgreement, isUK } = useGrowAvailability();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<TradePaymentMethodType>();
  const [recurringState, setRecurringState] = useState<RecurringDepositState>({
    ...initialRecurringDepositState,
    ...initialRecurringDepositStateFromProps,
  });

  const [accountState, setAccountState] = useState<DepositAccountState>({
    ...initialAccountState,
    ...initialAccountStateFromProps,
  });

  const requiresStakingAgreement = isUK && providersRequiringStakingAgreement.includes(contextProvider?.id);

  const _isMounted = useRef(false);

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const updateUserSettings = React.useCallback(() => {
    if (!accountState.canManageBankAccounts) {
      axios.get(BankSettingsController.get().url).then(({ data }) => {
        const { canManageBankAccounts, isPlaidRuxEnabled, isPlaidSupported, userDetails } = data.pageProps;
        if (!_isMounted.current) return;
        setAccountState(prevState => ({
          ...prevState,
          canManageBankAccounts,
          isPlaidRuxEnabled,
          isPlaidSupported,
          userDetails,
        }));
      });
    }
  }, [accountState.canManageBankAccounts]);

  useEffect(() => {
    updateUserSettings();
  }, [updateUserSettings]);

  const toggleModal = (view: DepositFlowModal) => {
    setModalView(view);
  };

  const onToggle = (view: DepositFlowModal, close?: boolean) => () => {
    toggleModal(view);
    if (close) {
      closeModal();
      onClose?.();
    }
  };

  const toggleSelectPaymentMethod = (paymentMethod: TradePaymentMethodType) => () => {
    setSelectedPaymentMethod(paymentMethod);
  };

  // TODO: This is only used once, move it to the appropriate component
  const maxPooledStakeApy = useMemo(
    () =>
      maxBy(
        asset?.interestProviders
          .filter(x => x.providerType === GrowProviderType.POOLED_STAKING)
          .map(x => x.annualInterestYieldTiers)
          .flat(),
        x => x?.yield
      )?.yield ?? 0,
    [asset]
  );

  // TODO: This is only used once, move it to the appropriate component
  const maxPrivateStakeApy = useMemo(
    () =>
      maxBy(
        asset?.interestProviders
          .filter(x => x.providerType === GrowProviderType.PRIVATE_STAKING)
          .map(x => x.annualInterestYieldTiers)
          .flat(),
        x => x?.yield
      )?.yield ?? 0,
    [asset]
  );

  const isLoadingPaymentData = paymentMethodData.isLoading || balancesData.loading;
  const fundingSourceOptions = React.useMemo(() => {
    if (isLoadingPaymentData) return [];
    if (!asset?.currency) return [];

    const paymentMethodsProps = {
      providerType: contextProvider?.providerType,
      paymentMethodData,
      balances: balancesData.data,
      currency: asset.currency,
      defaultFiat,
      intl,
    };

    return [
      ...createFiatBalancePaymentMethod(paymentMethodsProps),
      ...createAchPaymentMethods(paymentMethodsProps),
      ...createDebitPaymentMethods(paymentMethodsProps),
      ...createPayPalPaymentMethods(paymentMethodsProps),
    ];
  }, [
    contextProvider?.providerType,
    paymentMethodData,
    defaultFiat,
    isLoadingPaymentData,
    balancesData.data,
    asset?.currency,
    intl,
  ]);

  const selectedFundingSourceOption = fundingSourceOptions.find(o => o.value === selectedPaymentMethod);

  if (!asset) return null;

  return (
    <React.Fragment>
      {/* Currently uses a Modal */}
      {modalView === DepositModalView.PICK_ETH_STAKING_TYPE && (
        <React.Fragment>
          <ModalTitle>{intl.formatMessage({ defaultMessage: "Choose staking method" })}</ModalTitle>
          <PickETHStakingType
            pooledStakeApy={maxPooledStakeApy}
            privateStakeApy={maxPrivateStakeApy}
            onDidSelectType={providerType => {
              // Reset the deposit state when the providerType changes
              // Update the shared context
              updateDepositStatus({ providerType });

              track(EVENTS.SELECT_STAKING_PROVIDER_TYPE.name, {
                [EVENTS.SELECT_STAKING_PROVIDER_TYPE.properties.CURRENCY]: contextCurrency,
                [EVENTS.SELECT_STAKING_PROVIDER_TYPE.properties.PROVIDER_TYPE]: providerType,
              });

              // Segue to the inputs screen
              onToggle(DepositModalView.PLACE_DEPOSIT)();
            }}
          />
        </React.Fragment>
      )}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.PLACE_DEPOSIT && (
        <PlaceDeposit
          onSubmit={() => {
            // TODO: Do we still need this?
            toggleSelectPaymentMethod(null);
          }}
          requiresStakingAgreement={
            requiresStakingAgreement && !Boolean(Number(contextProvider?.earningInterest?.value ?? 0))
          }
        />
      )}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.AGREEMENT_SUMMARY && <AgreementSummary onToggle={onToggle} />}

      {modalView === DepositModalView.STAKING_AGREEMENT && (
        <StakingAgreement
          provider={contextProvider}
          onAccept={() => {
            if (countryHasStakingTaxImplications(countryCode)) {
              onToggle(DepositModalView.TAX_INFORMATION)();
            } else if (stakingAssetsWithDisclaimers.includes(contextCurrency)) {
              onToggle(DepositModalView.ASSET_DISCLAIMER)();
            } else if (source === SourceType.BANK_OR_CARD) {
              onToggle?.(DepositModalView.REVIEW_BUY_DEPOSIT)();
            } else {
              onToggle?.(DepositModalView.REVIEW_DEPOSIT)();
            }
          }}
          onDecline={goBack}
        />
      )}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.TAX_INFORMATION && (
        <CountryTaxInformation
          countryCode={countryCode}
          onAccept={() => {
            if (stakingAssetsWithDisclaimers.includes(contextCurrency)) {
              onToggle(DepositModalView.ASSET_DISCLAIMER)();
            } else if (source === SourceType.BANK_OR_CARD) {
              onToggle?.(DepositModalView.REVIEW_BUY_DEPOSIT)();
            } else {
              onToggle?.(DepositModalView.REVIEW_DEPOSIT)();
            }
          }}
        />
      )}

      {modalView === DepositModalView.ASSET_DISCLAIMER && (
        <AssetDisclaimer
          currency={contextCurrency}
          onAccept={() => {
            if (source === SourceType.BANK_OR_CARD) {
              onToggle?.(DepositModalView.REVIEW_BUY_DEPOSIT)();
            } else {
              onToggle?.(DepositModalView.REVIEW_DEPOSIT)();
            }
          }}
        />
      )}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.REVIEW_DEPOSIT && <ReviewDeposit />}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.REVIEW_BUY_DEPOSIT &&
        (balancesData.loading ? (
          <SkeletonLoader height="60px" width="100%" radiusSize="md" />
        ) : balancesData.error ? (
          <LoadingErrorSection
            refetchData={() => balancesData.fetchAccountBalances()}
            errorMessage={intl.formatMessage({
              defaultMessage: "An error occurred while loading balances. Please try again.",
            })}
          />
        ) : (
          <ReviewBuyDeposit
            // TODO: Keeping these as props for now, but paymentMethod and recurringState can be moved to GrowBuyContext
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            recurringState={recurringState}
            setRecurringState={setRecurringState}
            paymentMethodData={paymentMethodData}
            balanceData={balancesData}
          />
        ))}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.SELECT_PAYMENT_METHOD &&
        (balancesData.loading ? (
          <SkeletonLoader height="60px" width="100%" radiusSize="md" />
        ) : balancesData.error ? (
          <LoadingErrorSection
            refetchData={() => balancesData.fetchAccountBalances()}
            errorMessage={intl.formatMessage({
              defaultMessage: "An error occurred while loading balances. Please try again.",
            })}
          />
        ) : (
          <StakingFundingSourcesScreen
            paymentMethodData={paymentMethodData}
            providerType={contextProvider?.providerType}
            transactionType={GrowTransactionType.STAKE}
            options={fundingSourceOptions}
            selected={selectedFundingSourceOption}
            onDidSelectFundingSource={selected => {
              const paymentMethod = selected.value as TradePaymentMethodType;
              setSelectedPaymentMethod(paymentMethod);
              onToggle(DepositModalView.REVIEW_BUY_DEPOSIT)();
              track(TRADE_EVENTS.SELECT_PAYMENT_METHOD.name, {
                METHOD_ID: paymentMethod !== AccountType.BALANCE ? paymentMethod?.id : undefined,
                METHOD_TYPE: getPaymentMethodType(paymentMethod),
              });
            }}
          />
        ))}

      {/* Currently uses a Modal */}
      {modalView === DepositModalView.SUCCESS && (
        <Success
          onDone={() => {
            closeModal();
          }}
        />
      )}
    </React.Fragment>
  );
};

const Deposit = (props: DepositProps) => {
  const { onClose } = props;
  const { goBack } = useGrowBuy();

  return (
    <Modal.MultiStep isOpen onBack={goBack} onClose={onClose}>
      <DepositFlow {...props} />
    </Modal.MultiStep>
  );
};

export default Deposit;
