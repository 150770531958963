import { Dispatch, useState } from "react";
import * as React from "react";
import { Controller, FieldError, UseFormReturn } from "react-hook-form";
import { optimizelyClient } from "@gemini-ui/analytics";
import { StyledForm } from "@gemini-ui/components/StyledForm";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankDetailsFormType } from "@gemini-ui/constants/giact";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Fieldset, Input, Modal, Radio, Spacer, Text } from "@gemini-ui/design-system";
import {
  getInstantAccessMessage,
  getWhereDoIFindThisDetails,
  GiactModalState,
} from "@gemini-ui/pages/settings/BankSettings/components/GiactModal/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";

type GiactRegisterBankAccountProps = {
  control: UseFormReturn<BankDetailsFormType>["control"];
  errors: Partial<Record<keyof BankDetailsFormType, FieldError>>;
  handleFallbackToWire: () => void;
  handleGiactClose: () => void;
  isOpen: boolean;
  onBack?: () => void;
  register: UseFormReturn<BankDetailsFormType>["register"];
  setModalState: Dispatch<string>;
  trigger: UseFormReturn<BankDetailsFormType>["trigger"];
};

export const GiactRegisterBankAccount = ({
  control,
  errors,
  handleFallbackToWire,
  handleGiactClose,
  isOpen,
  onBack,
  register,
  setModalState,
  trigger,
}: GiactRegisterBankAccountProps) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { countryCode },
    },
  } = usePageData();
  const isUsUser = countryCode === "us";
  const { usBankAccountNumber, routingNumber, accountType: accountTypeSchema } = getFieldData(intl, isUsUser);
  const [showInstantAccessInfo, setShowInstantAccessInfo] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await trigger();
    isValid && setModalState(GiactModalState.Confirm);
  };
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);

  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  const accountType = register("accountType", {
    required: intl.formatMessage({ defaultMessage: "Please select an account type." }),
  });
  return isNewAddPaymentsFlowEnabled ? (
    <Modal.MultiStep
      onBack={onBack}
      title={intl.formatMessage({ defaultMessage: "Enter info manually" })}
      isOpen={isOpen}
      onClose={handleGiactClose}
    >
      <Text.Body size="sm" mt={1} mb={3}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Only bank accounts in your name can be used to fund your Gemini account. Bank accounts added manually are not eligible for <InstantAccessLink>instant access</InstantAccessLink> and cannot be used for instant purchases.<InstantAccessInfoContainer></InstantAccessInfoContainer>",
          }),
          {
            InstantAccessLink: (v: React.ReactNode) => (
              <Text.Link onClick={() => setShowInstantAccessInfo(true)} underline={!showInstantAccessInfo}>
                {v}
              </Text.Link>
            ),
            InstantAccessInfoContainer: () => {
              // transfer-time is hardcoded for now need to replace once API is ready
              return showInstantAccessInfo ? (
                <Text.Body size="sm" mt={2}>
                  {getInstantAccessMessage(intl).MESSAGE}
                </Text.Body>
              ) : null;
            },
          }
        )}
      </Text.Body>
      <StyledForm onSubmit={handleSubmit}>
        <Spacer mb={2.5}>
          <Controller
            name="accountNumber"
            rules={{
              required: intl.formatMessage({ defaultMessage: "Please enter a valid account number." }),
            }}
            control={control}
            // Avoid passing ref to function component
            render={({ field: { ref, ...rest }, fieldState }) => (
              <Input
                data-testid="giact-account-number"
                defaultValue={undefined}
                name={usBankAccountNumber.apiField}
                label={usBankAccountNumber.label}
                error={fieldState.error && fieldState.error.message}
                mask={usBankAccountNumber.mask}
                {...rest}
              />
            )}
          />
        </Spacer>
        <Controller
          name="routingNumber"
          rules={{
            required: intl.formatMessage({ defaultMessage: "Please enter a valid Wire routing number." }),
            validate: {
              length: v => v.length === 9 || intl.formatMessage({ defaultMessage: "Must be exactly 9 digits." }),
            },
          }}
          control={control}
          // Avoid passing ref to function component
          render={({ field: { ref, ...rest }, fieldState }) => (
            <Input
              data-testid="giact-routing-number"
              defaultValue={undefined}
              name={routingNumber.apiField}
              label={routingNumber.label}
              error={fieldState.error && fieldState.error.message}
              message={intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "<ShowMoreInfo>Where do i find this?</ShowMoreInfo><DetailedInfoContainer></DetailedInfoContainer>",
                }),
                {
                  ShowMoreInfo: (v: React.ReactNode) => (
                    <Text.Link
                      data-testid="fallback-to-wire-verification-link"
                      onClick={() => setShowMoreInfo(true)}
                      underline={!showMoreInfo}
                    >
                      {v}
                    </Text.Link>
                  ),
                  DetailedInfoContainer: () =>
                    showMoreInfo ? (
                      <React.Fragment>
                        <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_ONE}</Text.Body>
                        <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_TWO}</Text.Body>
                        <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_THREE}</Text.Body>
                        <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_FOUR}</Text.Body>
                      </React.Fragment>
                    ) : null,
                }
              )}
              mask={routingNumber.mask}
              {...rest}
            />
          )}
        />
        <Fieldset
          error={errors.accountType && errors.accountType.message}
          legend={accountTypeSchema.label}
          name={accountTypeSchema.apiField}
          mt={2.5}
        >
          <Radio value="Checking" {...accountType}>
            {intl.formatMessage({ defaultMessage: "Checking" })}
          </Radio>
          <Radio value="Savings" {...accountType}>
            {intl.formatMessage({ defaultMessage: "Savings" })}
          </Radio>
        </Fieldset>
        <Button.Group>
          <Button.Primary type="submit" data-testid="giact-register-continue" size="md">
            {intl.formatMessage({
              defaultMessage: "Review",
            })}
          </Button.Primary>
        </Button.Group>
      </StyledForm>
    </Modal.MultiStep>
  ) : (
    <Modal.MultiStep
      onBack={onBack}
      title={intl.formatMessage({ defaultMessage: "Add bank account" })}
      isOpen={isOpen}
      onClose={handleGiactClose}
    >
      <Text.Body size="sm" mt={1} mb={3}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Only bank accounts held in your name can be used.",
          })
        )}
      </Text.Body>
      <form onSubmit={handleSubmit}>
        <Spacer mb={2.5}>
          <Controller
            name="routingNumber"
            rules={{
              required: intl.formatMessage({ defaultMessage: "Please provide a routing number." }),
              validate: {
                length: v => v.length === 9 || intl.formatMessage({ defaultMessage: "Must be exactly 9 digits." }),
              },
            }}
            control={control}
            // Avoid passing ref to function component
            render={({ field: { ref, ...rest }, fieldState }) => (
              <Input
                data-testid="giact-routing-number"
                defaultValue={undefined}
                name={routingNumber.apiField}
                label={routingNumber.label}
                error={fieldState.error && fieldState.error.message}
                message={intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "Don't have an ACH routing number? <FallbackToWire>Register your account with a wire routing number</FallbackToWire>",
                  }),
                  {
                    FallbackToWire: (v: React.ReactNode) => (
                      <Text.Link data-testid="fallback-to-wire-verification-link" onClick={handleFallbackToWire}>
                        {v}
                      </Text.Link>
                    ),
                  }
                )}
                mask={routingNumber.mask}
                {...rest}
              />
            )}
          />
        </Spacer>
        <Controller
          name="accountNumber"
          rules={{
            required: intl.formatMessage({ defaultMessage: "Please provide an account number." }),
          }}
          control={control}
          // Avoid passing ref to function component
          render={({ field: { ref, ...rest }, fieldState }) => (
            <Input
              data-testid="giact-account-number"
              defaultValue={undefined}
              name={usBankAccountNumber.apiField}
              label={usBankAccountNumber.label}
              error={fieldState.error && fieldState.error.message}
              mask={usBankAccountNumber.mask}
              {...rest}
            />
          )}
        />
        <Fieldset
          error={errors.accountType && errors.accountType.message}
          legend={accountTypeSchema.label}
          name={accountTypeSchema.apiField}
          mt={2.5}
        >
          <Radio defaultChecked value="Checking" {...accountType}>
            {intl.formatMessage({ defaultMessage: "Checking" })}
          </Radio>
          <Radio value="Savings" {...accountType}>
            {intl.formatMessage({ defaultMessage: "Savings" })}
          </Radio>
        </Fieldset>
        <Button.Group>
          <Button.Tertiary data-testid="giact-cancel" onClick={handleGiactClose} size="md">
            {intl.formatMessage({
              defaultMessage: "Cancel",
            })}
          </Button.Tertiary>
          <Button.Primary type="submit" data-testid="giact-register-continue" size="md">
            {intl.formatMessage({
              defaultMessage: "Continue",
            })}
          </Button.Primary>
        </Button.Group>
      </form>
    </Modal.MultiStep>
  );
};
