import { FocusEvent, Fragment, MouseEvent, ReactNode, useMemo, useRef, useState } from "react";
import { IconArrowsVertical } from "@hubble/icons";
import MaskedInput from "react-text-mask";
import { CurrencyShortName, isFiatCurrency } from "@gemini-common/scripts/constants/currencies";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, Message, Spacing } from "@gemini-ui/design-system";
import { ConversionTooltip } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/ConversionTooltip";
import { MaskedStakingInput } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/MaskedInput";
import { ScalableInput, StyledGrid } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/styles";
import { sanitizeMaskedInputValue } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { CURRENCY_DETAILS_V2 } from "@gemini-ui/pages/RetailTrade/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export interface InputProps {
  value?: string;

  /**
   * The last trade price to display in the conversion rate.
   */
  lastTradePrice: string;

  /**
   * The currencies to display in the input.
   */
  displayCurrencies: [CurrencyShortName, CurrencyShortName];

  /**
   * Any errors to display
   */
  error?: ReactNode;

  onChange?: (value: string) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onCurrencySwitch?: (currency: CurrencyShortName) => void;
}

const StakingInput = ({
  value,
  lastTradePrice,
  displayCurrencies,
  error,
  onBlur,
  onChange,
  onCurrencySwitch,
}: InputProps) => {
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const { intl } = useIntl();

  const inputRef = useRef<MaskedInput>();
  const [currencyOrder, setCurrencyOrder] = useState<InputProps["displayCurrencies"]>(displayCurrencies);

  // In the event this component is uncontrolled, we need to keep track of the value locally to display the conversion value
  const [_localValue, setLocalValue] = useState<string | undefined>(value);

  const handleSwitchCurrencyDisplays = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Typecast this b/c `.reverse()` will return `CurrencyShortName[]` instead of pair.
    const newOrder = [...currencyOrder].reverse() as InputProps["displayCurrencies"];

    setCurrencyOrder(newOrder);
    onCurrencySwitch?.(newOrder[0]);
  };

  /**
   * `primaryCurrency` - The currency that is the predominant display.
   * `secondaryCurrency` - The currency that is the reference display.
   */
  const { primaryCurrencyDetails, secondaryCurrencyDetails } = useMemo(
    () => ({
      primaryCurrencyDetails: CURRENCY_DETAILS_V2(currencyOrder[0]),
      secondaryCurrencyDetails: CURRENCY_DETAILS_V2(currencyOrder[1]),
    }),
    [currencyOrder]
  );

  const isFiat = isFiatCurrency(primaryCurrencyDetails.currency.symbol);

  return (
    <Fragment>
      <Flex flexDirection="column" alignItems="center" pt={12} pb={12} gap={Spacing.scale[2]}>
        <StyledGrid>
          <div />
          <MaskedStakingInput
            providerType={GrowProviderType.POOLED_STAKING}
            ref={inputRef}
            currencyDetails={primaryCurrencyDetails.currency}
            isFiat={isFiat}
            value={value}
            placeholder={
              isFiat
                ? `${primaryCurrencyDetails.currency.leadingSymbol}0`
                : `0 ${primaryCurrencyDetails.currency.symbol}`
            }
            onChange={e => {
              const _value = sanitizeMaskedInputValue(e.target.value);
              onChange?.(_value);
              setLocalValue(_value);
            }}
            onBlur={onBlur}
            render={(ref, inputProps) => (
              <ScalableInput data-testid={testIds.buyModule.input.inputAmount} ref={ref} {...inputProps} />
            )}
          />
          <Button.Tertiary
            data-testid={testIds.buyModule.input.switchBtn}
            aria-label={intl.formatMessage({ defaultMessage: "Switch currency display" })}
            icon={<IconArrowsVertical />}
            onClick={handleSwitchCurrencyDisplays}
            css={{ visibility: "hidden" }} // TODO: Re-enable this after we narrow down testing
          />
        </StyledGrid>

        <ConversionTooltip
          currency={secondaryCurrencyDetails.currency.symbol}
          value={value || _localValue}
          defaultFiat={defaultFiat}
          lastTradePrice={lastTradePrice}
          hideTrailingSign={secondaryCurrencyDetails.currency.symbol === defaultFiat}
        />

        {error && <Message data-testid={testIds.buyModule.input.error} error={error} />}
      </Flex>
    </Fragment>
  );
};

export default StakingInput;
