import { Component, PropsWithChildren, ReactNode } from "react";
import * as Sentry from "@sentry/browser";
import { IntlShape, withIntl } from "@gemini-ui/utils/intl";

interface Props {
  renderFallback?: (error: Error) => ReactNode;
  sentryContext?: Record<string, any>;
  intl: IntlShape;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  state = { error: null };

  componentDidCatch(error: Error) {
    const { sentryContext } = this.props;
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(sentryContext);
      Sentry.captureException(error);
    });
  }

  render() {
    const { renderFallback, intl } = this.props;
    const { error } = this.state;

    if (error) {
      return renderFallback ? (
        renderFallback(error)
      ) : (
        <h1>{intl.formatMessage({ defaultMessage: "Something went wrong." })}</h1>
      );
    }

    return this.props.children;
  }
}

export default withIntl(ErrorBoundary);
