import { IconStaticStatusAlert } from "@hubble/icons";
import { MessageProps } from "@gemini-ui/design-system/forms/Message/constants";
import { StyledErrorMessage, StyledMessage } from "@gemini-ui/design-system/forms/Message/styles";
import { Spacer } from "@gemini-ui/design-system/primitives/Spacer";

/**
 * Help text message, can serve as an error or a standard message to be displayed beneath a form field.
 */
export function Message(props: MessageProps) {
  const { ["data-testid"]: dataTestId, disabled, error, id, message, mt, size = "sm" } = props;

  if (Boolean(error)) {
    return (
      <StyledErrorMessage data-testid={dataTestId} id={id} size="sm">
        <IconStaticStatusAlert aria-hidden size="xs" />
        <Spacer as="span" ml={1}>
          {error}
        </Spacer>
      </StyledErrorMessage>
    );
  }

  return (
    <StyledMessage data-testid={dataTestId} disabled={disabled} id={id} mt={mt} size={size}>
      {message}
    </StyledMessage>
  );
}
