import { IconSelection, IconTableSortAscending, IconTableSortDescending } from "@hubble/icons";
import _ from "lodash";
import { Column, Columns, Sort } from "@gemini-ui/components/Table/constants";
import { TableHeader } from "@gemini-ui/components/Table/styles";
import { Flex } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  columns: Columns;
  onSort?: Function;
  sort?: Sort;
  overrideFontSize?: string;
}

const SORT_DESC = "desc";
const SORT_ASC = "asc";
const ICON_DOWN = "down";
const ICON_UP = "up";

export default function Head({ columns, sort, onSort, overrideFontSize }: Props) {
  const { intl } = useIntl();

  const onSortClick = (column: Column) => {
    if (onSort && typeof sort === "object" && column.sortBy) {
      const direction = sort.direction === SORT_ASC ? SORT_DESC : SORT_ASC;
      onSort({ column, direction });
    }
  };

  return (
    <thead>
      <tr>
        {columns.map((column, i) => {
          const sortable = typeof column.sortBy === "function";
          let icon = null;
          if (sortable) {
            icon = <IconSelection size="sm" data-testid="icon-selection" />;

            if (typeof sort === "object" && (column.label === sort.field || column.sortBy({}) === sort.field)) {
              const direction = sort.direction === SORT_ASC ? ICON_UP : ICON_DOWN;
              icon =
                direction === ICON_UP ? (
                  <IconTableSortAscending size="sm" data-testid="icon-sort-asc" />
                ) : (
                  <IconTableSortDescending size="sm" data-testid="icon-sort-desc" />
                );
            }
          }
          const overrideProps = {
            ...(overrideFontSize && { fontSize: overrideFontSize }),
          };
          return (
            <th
              key={i}
              align={_.get(column, "align", "left")}
              style={column.styles}
              aria-label={
                !column.label
                  ? intl.formatMessage({
                      defaultMessage: "No Header",
                    })
                  : undefined
              }
              className={column.className}
            >
              {column.label && (
                <TableHeader
                  {...overrideProps}
                  size="xs"
                  bold
                  as="div"
                  onClick={() => onSortClick(column)}
                  role="button"
                  tabIndex={-1}
                  style={{
                    outline: 0,
                    cursor: sortable ? "pointer" : "default",
                    textAlign: "inherit",
                  }}
                >
                  <Flex alignItems="center">
                    {column.label}
                    {sortable && icon}
                  </Flex>
                </TableHeader>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
