import MaskedInput from "react-text-mask";
import { MaskInputProps } from "@gemini-ui/design-system/__deprecated__/Input/constants";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input/Input";

export const InputWithMask = ({ mask, render, ...remainingProps }: MaskInputProps) => {
  return (
    <MaskedInput
      autoComplete="off"
      guide={false}
      mask={mask}
      {...remainingProps}
      render={
        render ||
        ((ref, props) => (
          <Input data-testid={remainingProps["data-testid"]} name={remainingProps.name} {...props} ref={ref} />
        ))
      }
    />
  );
};
