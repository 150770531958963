import { InProgress, StatusAlert, StatusSuccess } from "@gemini-ui/design-system/Timeline/styles";
import { TimelineStepStatus } from "@gemini-ui/design-system/Timeline/types";

interface Props {
  status: TimelineStepStatus;
}
function StatusIcon({ status }: Props) {
  if (status === TimelineStepStatus.Success) {
    return <StatusSuccess size="md" role="presentation" aria-hidden="false" />;
  } else if (status === TimelineStepStatus.Alert) {
    return <StatusAlert size="md" role="presentation" aria-hidden="false" />;
  }
  return <InProgress role="presentation" aria-hidden="false" />;
}

export default StatusIcon;
