import { cloneElement, Fragment, ReactNode, useEffect, useMemo } from "react";
import { addHours, format, startOfHour } from "date-fns";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { InterestProvider } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Card, Colors, Flex, List, ListItem, Spacer, Spacing, Text } from "@gemini-ui/design-system";
import LinkText from "@gemini-ui/design-system/Text/LinkText";
import { buildTransferSummaryViews, renderMap } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit/renderer";
import { SourceType, TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { GEMINI_STAKING_AGREEMENT_URL, getMaxAccrualText } from "@gemini-ui/pages/Earn/Deposit/utils";
import { DepositReviewHeader } from "@gemini-ui/pages/Earn/GrowBuy/components/DepositReviewHeader";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { SupportedStakingPaymentMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { LoadingState } from "@gemini-ui/pages/Earn/GrowBuy/screens/Review/LoadingState";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import {
  monthlyScheduleItems,
  recurringOrderDropdownItems,
  twiceMonthlyScheduleItems,
  weeklyScheduleItems,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/RecurringBuyDropdowns";
import { BuySellOrderSummary } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/screens/ReviewOrder/BuySellOrderSummary";
import { getStartOnDate } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import {
  Action,
  OrderQuote,
  OrderQuoteError,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { getPaymentMethodType } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { isInstantOrderQuote, isRetailTradePaymentMethodType, isTransferQuote } from "@gemini-ui/services/retail/utils";
import { StakingDepositMethod } from "@gemini-ui/services/staking/constants";
import { useStakingQuote } from "@gemini-ui/services/staking/stakingQuote";
import { TimeFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const StakingDepositReview = ({
  provider,
  currency,
  amount,
  paymentMethod,
  onSubmit,
  onQuoteError,
  isLoading,
}: {
  provider: InterestProvider;
  currency: CurrencyShortNameSupportedCryptos;
  amount: string | number;
  paymentMethod: SupportedStakingPaymentMethod;
  onSubmit: (quote: OrderQuote | TransferQuote) => void;
  onQuoteError?: (error: string | OrderQuoteError) => void;
  isLoading?: boolean;
}) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const { goBack } = useGrowBuy();

  const { quoteType, quoteArgs } = useMemo(() => {
    if (paymentMethod === SourceType.TRADING_BALANCE) {
      return { quoteType: StakingDepositMethod.TRANSFER, quoteArgs: { providerId: provider?.id, currency, amount } };
    }

    const instantOrderArgs = {
      to: currency,
      from: defaultFiat,
      totalSpend: amount,
      paymentMethodUuid: undefined,
      paymentMethodType: getPaymentMethodType(paymentMethod),
      internalReference: null,
      isRecurringOrder: false, // TODO: Add recurring order support
      cardAccountType: null,
      earnProviderId: provider?.id,
    };

    if (isRetailTradePaymentMethodType(paymentMethod)) {
      instantOrderArgs.paymentMethodUuid = paymentMethod.id;
    }

    return { quoteType: StakingDepositMethod.BUY_AND_STAKE, quoteArgs: instantOrderArgs };
  }, [paymentMethod, amount, currency, defaultFiat, provider?.id]);

  const { data: quote, loading, error, fetchQuote } = useStakingQuote();

  useEffect(() => {
    fetchQuote(quoteType, quoteArgs);
  }, [quoteType, quoteArgs, fetchQuote]);

  useEffect(() => {
    if (error) {
      onQuoteError?.(error);
      goBack();
    }
  }, [error, onQuoteError, goBack]);

  const sanitizedQuote = Boolean(quote)
    ? isInstantOrderQuote(quote)
      ? {
          amount: quote.quote.quantity,
          amountNotional: quote.quote.approxNotional,
          validatorCount: null,
        }
      : {
          amount: quote.amount,
          amountNotional: quote.notionalAmount,
          validatorCount: quote.validatorsResponse.numberOfValidators,
        }
    : {};

  const accrualText = useMemo(
    () => getMaxAccrualText(provider?.maxAccrueDelay, intl),
    [provider?.maxAccrueDelay, intl]
  );

  const rows = useMemo(() => {
    let views = {};
    if (isTransferQuote(quote)) {
      views = buildTransferSummaryViews({
        intl,
        provider,
        transferQuote: quote,
      });
    } else if (isInstantOrderQuote(quote)) {
    }

    return renderMap[provider?.providerType]?.map(key => views?.[key]) ?? [];
  }, [provider, quote, intl]);

  const instantOrderSummaryProps = useMemo(() => {
    const schedule = recurringOrderDropdownItems(intl)[0].value;
    const recurringFrequency = {
      weekly: weeklyScheduleItems(intl)[0].value,
      twiceMonthly: twiceMonthlyScheduleItems(intl)[0].value,
      monthly: monthlyScheduleItems(intl)[0].value,
    };
    const triggerHour = format(startOfHour(addHours(new Date(), 1)), TimeFormats.Hour24Minute);
    const startOn = getStartOnDate(triggerHour, {
      schedule: schedule,
      weeklyFrequency: recurringFrequency.weekly,
      twiceMonthlyFrequency: recurringFrequency.twiceMonthly,
      monthlyFrequency: recurringFrequency.monthly,
    });

    return {
      action: Action.BUY,
      schedule,
      recurringFrequency,
      triggerHour,
      startOn,
    };
  }, [intl]);

  if (loading) return <LoadingState />;

  return (
    <Fragment>
      <Flex flexDirection="column" gap={Spacing.scale[2]}>
        {Boolean(quote) && (
          <Flex flexDirection="column" align="center" gap={Spacing.scale[1]} mb={2}>
            <DepositReviewHeader
              providerType={provider.providerType}
              amount={sanitizedQuote?.amount}
              notionalAmount={sanitizedQuote?.amountNotional}
              validatorCount={sanitizedQuote?.validatorCount}
            />
          </Flex>
        )}

        <Card variant="filled" padding="none">
          {isInstantOrderQuote(quote) ? (
            <BuySellOrderSummary
              {...instantOrderSummaryProps}
              // TODO: Avoid typecasting here
              selectedPaymentMethod={paymentMethod as TradePaymentMethodType}
              isBuyAndEarn={false}
              isBuyAndStake={true}
              isBuyAndWrapFil={false}
              hasVATFee={false}
              disclaimer={null}
              quote={quote.quote}
              tradingFee={quote.quote.fee.value}
              noTradingFees={quote.quote.formData.promoMultiplier === 0}
              rewardAmount={null}
            />
          ) : (
            <List>
              {rows.map((row, idx) => {
                // It's assumed that the row is a `ListItem`, but there may be cases where we want
                // to have custom list elements - like in breaking down tiered rates. In this scenario,
                // we can use the `override` prop to pass in a custom element.
                if (row?.override) return cloneElement(row?.override, { key: idx });
                return <ListItem key={idx} size="dense" padding="sm" alignItems="center" hasDivider={true} {...row} />;
              })}
            </List>
          )}

          <Spacer p={2}>
            <Text.Legal data-testid={testIds.deposit.reviewDeposit.disclaimer}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    'Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>By clicking "confirm," you are agreeing to stake your crypto according to the terms of the Gemini Trust Company, LLC Staking Agreement. Please note that the time to start receiving staking rewards varies by network, but our customers typically start earning rewards {accrualText} of staking. <Link>Read more</Link>',
                }),
                {
                  br: () => <br />,
                  Link: (str: ReactNode) => (
                    <LinkText
                      href={GEMINI_STAKING_AGREEMENT_URL}
                      target="_blank"
                      rel="noreferrer"
                      color={Colors.gray[500]}
                    >
                      {str}
                    </LinkText>
                  ),
                  accrualText,
                }
              )}
            </Text.Legal>
          </Spacer>
        </Card>
      </Flex>

      <Button.Group type="stacked">
        <Button.Primary
          data-testid={testIds.deposit.reviewDeposit.completeButton}
          onClick={() => onSubmit?.(quote)}
          loading={isLoading}
          disabled={loading}
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Confirm order", description: "Complete this deposit" })}
        />
      </Button.Group>
    </Fragment>
  );
};
