import { useTheme } from "@emotion/react";
import {
  IconBrandPayPal,
  IconLightningFilled,
  IconPlus,
  IconRewardOutlined,
  IconStaking,
  IconWalletOutlined,
} from "@hubble/icons";
import { getPlaidInstitutionFromName } from "@gemini-ui/components/Icons/PaymentMethod";
import { BalanceIcon } from "@gemini-ui/components/Icons/PaymentMethod/BalanceIcon";
import { BankIcon } from "@gemini-ui/components/Icons/PaymentMethod/BankIcon";
import { CardIconSquare } from "@gemini-ui/components/Icons/PaymentMethod/CardIcon";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { usePageData } from "@gemini-ui/contexts";
import { IconBadge } from "@gemini-ui/design-system";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { SupportedStakingPaymentMethod, SupportedUnstakingMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { isRetailTradePaymentMethodType } from "@gemini-ui/services/retail/utils";
import { BankType, DebitCardType } from "@gemini-ui/transformers/PaymentMethods";

export const FundingSourceIcon = ({
  fundingSource,
  ...styleProps
}: {
  fundingSource?: SupportedStakingPaymentMethod | SupportedUnstakingMethod;
  backgroundColor?: string;
  color?: string;
}) => {
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const { isDark, colorScheme } = useTheme();

  if (!fundingSource) {
    return <IconBadge size="lg" icon={<IconPlus />} {...styleProps} />;
  }

  if (fundingSource === UnstakeSourceType.pooledBalance) {
    return <IconBadge size="lg" icon={<IconStaking />} {...styleProps} />;
  }

  if (fundingSource === UnstakeSourceType.availableRewardsBalance) {
    return <IconBadge size="lg" icon={<IconRewardOutlined />} {...styleProps} />;
  }

  if (fundingSource === UnstakeSourceType.validatorBalance) {
    return <IconBadge size="lg" icon={<IconLightningFilled />} {...styleProps} />;
  }

  if (fundingSource === AccountType.BALANCE) {
    return (
      <BalanceIcon
        size="lg"
        defaultFiat={defaultFiat}
        backgroundColor={colorScheme.iconBadge.background.bold}
        iconProps={{
          color: colorScheme.iconBadge.content.bold,
        }}
      />
    );
  }

  if (isRetailTradePaymentMethodType(fundingSource)) {
    switch (fundingSource.paymentMethodType) {
      case PaymentMethodType.BANK:
        return (
          <BankIcon size="lg" institution={getPlaidInstitutionFromName((fundingSource as BankType).institutionName)} />
        );
      case PaymentMethodType.DEBIT_CARD:
        return <CardIconSquare size="lg" cardIssuer={(fundingSource as DebitCardType).cardIssuer} />;
      case PaymentMethodType.PAYPAL:
        return <IconBadge size="lg" icon={<IconBrandPayPal color={isDark ? "#ffffff" : "#253B80"} />} />;
      default:
        return null;
    }
  }

  return <IconBadge size="lg" icon={<IconWalletOutlined />} {...styleProps} />;
};
