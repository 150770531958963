import { ReactNode } from "react";
import styled from "@emotion/styled";
import GlobalAlert from "@gemini-ui/components/GlobalAlert";
import { GlobalAlertTypesProps } from "@gemini-ui/components/GlobalAlert/constants";
import Header from "@gemini-ui/components/Header";
import HeaderWrapper from "@gemini-ui/components/Header/HeaderWrapper";

interface SignedInLayoutProps extends GlobalAlertTypesProps {
  error?: ReactNode;
  fullHeight?: boolean;
  children?: ReactNode;
}

const Container = styled("div")<{ fullHeight: boolean }>`
  padding-top: 52px !important;

  ${({ fullHeight }) =>
    fullHeight &&
    `
      min-height: 100vh;
      display: flex;

      @media (max-width: 991px) {
        flex-direction: column;
      }
  `}
`;

export const SignedInLayout = ({ children, fullHeight, red, yellow, green, error }: SignedInLayoutProps) => {
  return (
    <div className="MainLayout" data-testid="SignedInLayout">
      <HeaderWrapper className="HeaderWrapper">
        <Header />
        <GlobalAlert green={green} red={red || error} yellow={yellow} />
      </HeaderWrapper>
      <Container fullHeight={fullHeight}>{children}</Container>
    </div>
  );
};
