import { useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Button, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import CurrencySelector from "@gemini-ui/pages/settings/BankSettings/components/CurrencySelector";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onToggle: ToggleDebitCardModal;
  defaultFiat: CurrencyShortNameFiat;
  supportedFiat: CurrencyShortNameFiat[];
  updateCurrency: (currency) => void;
  onBack: () => void;
  defaultCurrency?: CurrencyShortNameFiat;
}

const SelectDebitCurrencyModal = ({
  onToggle,
  defaultFiat,
  supportedFiat,
  updateCurrency,
  onBack,
  defaultCurrency,
}: Props) => {
  const { intl } = useIntl();

  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyShortNameFiat | null>(defaultCurrency);
  const handleCurrencySelect = () => {
    updateCurrency(selectedCurrency);
    onToggle("addBillingAddressVisible")();
  };

  return (
    <Modal.MultiStep
      isOpen
      title={intl.formatMessage({
        defaultMessage: "Select debit card currency",
      })}
      onClose={onToggle("selectDebitCurrencyVisible", true)}
      onBack={onBack}
    >
      <Text.Body size="sm" mt={1}>
        {intl.formatMessage({
          defaultMessage:
            "Note: Debit cards can only be used to purchase crypto. To withdraw fiat currency, please link a bank account.",
        })}
      </Text.Body>
      <Spacer mt={5}>
        <CurrencySelector
          onSelect={currency => setSelectedCurrency(currency)}
          defaultCurrency={defaultFiat}
          currencies={supportedFiat}
        />
      </Spacer>
      <Button.Group>
        <Button.Tertiary
          data-testid="cancel-change-default-fiat"
          onClick={onToggle("selectDebitCurrencyVisible", true)}
        ></Button.Tertiary>

        <Button.Primary
          disabled={!selectedCurrency}
          data-testid="change-default-fiat"
          onClick={handleCurrencySelect}
          size="lg"
        >
          {intl.formatMessage({
            defaultMessage: "Next",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal.MultiStep>
  );
};

export default SelectDebitCurrencyModal;
