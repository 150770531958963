import { ReactNode } from "react";
import { Articles, generateHelpCenterUrl } from "@gemini-ui/components/HelpCenterLink";
import { CurrentMethodState } from "@gemini-ui/components/TwoFactorAuth/types";
import { Text } from "@gemini-ui/design-system";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const getTitleCopy = (intl: IntlShape, currentMethod: CurrentMethodState, blankedPhone?: string) => {
  switch (currentMethod) {
    case CurrentMethodState.Passkey:
      return intl.formatMessage({ defaultMessage: "Verify it's you" });
    case CurrentMethodState.Authy:
      return intl.formatMessage({ defaultMessage: "Enter your confirmation code" });
    case CurrentMethodState.TextMessage:
      return intl.formatMessage(defineMessage({ defaultMessage: "Enter the code we sent to {blankedPhone}" }), {
        blankedPhone,
      });
    default:
      return intl.formatMessage({ defaultMessage: "Verify it's you" });
  }
};

export const getSubTitleCopy = (intl: IntlShape, currentMethod: CurrentMethodState, type: "page" | "modal") => {
  switch (currentMethod) {
    case CurrentMethodState.Passkey:
      return type === "modal"
        ? intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Before you continue, use your passkey or security key so we can confirm it’s really you. <link>Get support</link>",
            }),
            {
              link: (str: ReactNode) => (
                <Text.Link href={generateHelpCenterUrl({ article: Articles.AUTHY_OVERVIEW, intl })}>{str}</Text.Link>
              ),
            }
          )
        : intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Before you sign-in, use your passkey or security key so we can confirm it’s really you. <link>Get support</link>",
            }),
            {
              link: (str: ReactNode) => (
                <Text.Link href={generateHelpCenterUrl({ article: Articles.AUTHY_OVERVIEW, intl })}>{str}</Text.Link>
              ),
            }
          );
    case CurrentMethodState.Authy:
      return intl.formatMessage({ defaultMessage: "Please enter the code generated by your Authy app" });
    default:
      return null;
  }
};

export const getCurrentMethod = (hasCredentials: boolean, isSMS: boolean) => {
  if (hasCredentials) {
    return CurrentMethodState.Passkey;
  } else if (isSMS) {
    return CurrentMethodState.TextMessage;
  } else {
    return CurrentMethodState.Authy;
  }
};

export const getFallbackType = (isSMS: boolean) => {
  return isSMS ? CurrentMethodState.TextMessage : CurrentMethodState.Authy;
};
