/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationOneMoreStep = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M183.102 143.992C178.686 144.056 172.062 144.12 163.998 144.12C142.718 144.152 140.83 143.8 126.142 143.896C109.598 144.024 107.102 144.536 91.7739 144.408C83.4859 144.344 76.7019 144.152 72.2539 143.992C72.2539 136.888 72.2539 129.752 72.2539 122.648C76.2859 122.712 82.4619 122.776 89.9819 122.744C102.11 122.68 104.734 122.392 117.662 122.296C128.414 122.2 127.454 122.392 140.126 122.296C151.902 122.2 154.462 122.04 162.142 122.072C171.198 122.136 178.494 122.424 183.102 122.68C183.102 129.784 183.102 136.888 183.102 143.992Z"
      fill={black}
    />
    <path
      d="M95.9348 145.112C94.6548 145.112 93.2468 145.112 91.7428 145.112C85.2148 145.08 78.6548 144.92 72.1908 144.696C71.8388 144.696 71.5508 144.376 71.5508 144.024V122.68C71.5508 122.488 71.6148 122.328 71.7428 122.2C71.8708 122.072 72.0308 122.008 72.2228 122.008C78.0468 122.104 84.0308 122.136 89.9508 122.104C96.1588 122.072 99.8388 121.976 103.743 121.88C107.455 121.784 111.327 121.688 117.631 121.656C122.879 121.624 125.343 121.624 127.935 121.656C130.719 121.688 133.599 121.72 140.063 121.656C145.215 121.624 148.671 121.56 151.423 121.528C155.103 121.464 157.759 121.432 162.111 121.464C169.151 121.496 176.223 121.72 183.103 122.072C183.455 122.104 183.743 122.392 183.743 122.744V144.088C183.743 144.44 183.455 144.76 183.071 144.76C176.767 144.824 170.335 144.888 163.935 144.888C153.119 144.92 147.359 144.824 142.271 144.76C137.471 144.696 133.311 144.632 126.079 144.696C118.751 144.76 114.111 144.888 110.047 144.984C105.759 145.016 101.919 145.112 95.9348 145.112ZM72.8948 143.352C79.1028 143.576 85.4708 143.704 91.7428 143.768C100.255 143.832 104.799 143.704 110.047 143.544C114.143 143.416 118.783 143.288 126.111 143.256C133.343 143.192 137.503 143.256 142.335 143.32C147.423 143.384 153.151 143.48 163.967 143.448C170.111 143.448 176.319 143.384 182.431 143.32V123.256C175.775 122.936 168.927 122.744 162.111 122.712C157.791 122.68 155.135 122.744 151.455 122.776C148.671 122.808 145.247 122.872 140.095 122.904C133.599 122.936 130.719 122.936 127.935 122.904C125.343 122.872 122.879 122.872 117.631 122.904C111.327 122.968 107.487 123.064 103.743 123.128C99.8388 123.224 96.1268 123.32 89.9188 123.352C84.2228 123.384 78.4948 123.352 72.8628 123.288V143.352H72.8948Z"
      fill={contentPrimary}
    />
    <path
      d="M183.103 122.648C181.887 122.008 180.031 121.048 177.663 119.96C174.143 118.328 173.791 118.424 171.231 117.112C169.023 115.992 168.415 115.512 165.279 113.912C163.967 113.24 162.879 112.728 162.143 112.376H126.143H93.1507C92.0307 112.888 90.3346 113.72 88.2866 114.776C85.5026 116.184 85.0867 116.472 82.8787 117.56C80.6387 118.68 80.0627 118.84 77.2147 120.184C75.0387 121.208 73.3107 122.072 72.2227 122.648H183.103Z"
      fill={white}
    />
    <path
      d="M183.103 123.32H72.2226C71.9026 123.32 71.6466 123.096 71.5826 122.808C71.5186 122.52 71.6466 122.2 71.9346 122.072C73.5666 121.24 75.2626 120.408 76.9586 119.608C78.2386 119 79.0706 118.616 79.7746 118.296C80.6706 117.88 81.3746 117.56 82.5906 116.984C83.7746 116.408 84.4465 116.056 85.2465 115.64C85.9505 115.288 86.7186 114.872 87.9986 114.2C89.6305 113.368 91.2626 112.568 92.8946 111.8C92.9906 111.768 93.0866 111.736 93.1826 111.736H162.111C162.207 111.736 162.303 111.768 162.399 111.8C163.455 112.28 164.511 112.824 165.567 113.336C167.263 114.2 168.255 114.744 169.119 115.224C169.887 115.64 170.527 116.024 171.551 116.536C172.927 117.24 173.663 117.528 174.591 117.912C175.359 118.232 176.351 118.616 177.983 119.384C179.839 120.248 181.663 121.144 183.455 122.072C183.711 122.2 183.871 122.52 183.775 122.808C183.647 123.128 183.391 123.32 183.103 123.32ZM75.0386 122.008H180.319C179.359 121.528 178.367 121.048 177.375 120.6C175.775 119.864 174.847 119.48 174.047 119.16C173.087 118.776 172.351 118.456 170.911 117.752C169.887 117.24 169.183 116.824 168.447 116.408C167.583 115.928 166.623 115.384 164.959 114.52C163.967 114.008 162.975 113.528 161.983 113.048H93.2786C91.7106 113.784 90.1426 114.552 88.5746 115.352C87.2946 115.992 86.5266 116.408 85.8226 116.792C85.0226 117.208 84.3506 117.56 83.1346 118.168C81.8866 118.776 81.1505 119.128 80.2865 119.512C79.5825 119.832 78.7506 120.216 77.4706 120.824C76.6706 121.208 75.8706 121.592 75.0386 122.008Z"
      fill={contentPrimary}
    />
    <path
      d="M201.279 181.272C196.799 181.272 185.695 181.272 181.119 181.272C160.575 181.272 150.303 181.272 142.463 181.24C113.247 181.144 116.895 180.856 102.175 180.984C92.3509 181.048 84.5429 181.24 68.1269 181.272C62.1429 181.272 57.2469 181.272 54.0469 181.272C54.0469 171.832 54.0469 162.36 54.0469 152.92C56.3189 152.92 63.9989 152.92 66.2709 152.92C84.9269 152.92 86.6869 152.888 90.8149 152.92C103.263 153.016 102.719 153.272 116.703 153.464C122.495 153.56 133.503 153.688 147.231 153.464C161.375 153.24 163.711 152.856 176.063 152.696C186.815 152.568 195.551 152.76 201.279 152.92C201.279 162.36 201.279 171.832 201.279 181.272Z"
      fill={black}
    />
    <path
      d="M61.087 181.944C58.719 181.944 56.351 181.944 54.047 181.944C53.695 181.944 53.375 181.656 53.375 181.272V152.92C53.375 152.728 53.439 152.568 53.567 152.44C53.695 152.312 53.855 152.248 54.047 152.248C56.319 152.248 63.999 152.248 66.271 152.248C72.863 152.248 77.343 152.248 80.575 152.248C86.399 152.248 88.159 152.248 90.847 152.248C97.439 152.28 100.351 152.376 103.775 152.504C106.751 152.6 110.143 152.696 116.735 152.792C122.079 152.856 133.247 153.016 147.231 152.792C154.143 152.664 158.143 152.536 162.047 152.376C166.015 152.216 169.759 152.088 176.063 152.024C184.511 151.928 193.023 151.992 201.311 152.248C201.663 152.248 201.951 152.568 201.951 152.92V181.272C201.951 181.656 201.663 181.944 201.279 181.944C196.799 181.944 185.695 181.944 181.119 181.944C160.607 181.944 150.303 181.944 142.463 181.912C127.871 181.848 121.535 181.752 116.895 181.688C112.319 181.624 109.535 181.592 102.175 181.624C98.815 181.656 95.711 181.688 92.415 181.72C86.111 181.784 78.943 181.88 68.127 181.912C65.759 181.944 63.423 181.944 61.087 181.944ZM54.719 180.6C59.103 180.6 63.615 180.6 68.127 180.6C78.943 180.568 86.111 180.504 92.415 180.408C95.711 180.376 98.847 180.344 102.175 180.312C109.567 180.248 112.351 180.312 116.927 180.376C121.567 180.44 127.903 180.536 142.463 180.6C150.303 180.632 160.607 180.632 181.119 180.632C185.471 180.632 195.775 180.632 200.607 180.632V153.592C192.543 153.368 184.287 153.304 176.063 153.4C169.759 153.464 166.047 153.624 162.079 153.752C158.175 153.88 154.143 154.04 147.231 154.168C133.215 154.392 122.047 154.232 116.671 154.168C110.047 154.072 106.655 153.976 103.679 153.88C100.287 153.784 97.375 153.688 90.783 153.624C88.095 153.592 86.335 153.592 80.543 153.624C77.311 153.624 72.831 153.624 66.239 153.624C64.127 153.624 57.503 153.624 54.655 153.624V180.6H54.719Z"
      fill={contentPrimary}
    />
    <path
      d="M201.279 152.919C198.911 151.671 196.991 150.743 195.647 150.167C192.735 148.855 192.479 148.919 190.495 147.895C188.831 147.031 188.031 146.487 185.599 145.239C184.543 144.695 183.679 144.279 183.103 144.023H72.2229C71.5509 144.439 70.5909 145.015 69.4069 145.655C68.0629 146.391 67.0709 146.839 64.0949 148.215C60.2229 149.975 59.8709 150.103 58.5269 150.743C56.5749 151.639 55.0389 152.439 54.0469 152.919H201.279Z"
      fill={white}
    />
    <path
      d="M201.279 153.592H54.0468C53.7268 153.592 53.4708 153.368 53.4068 153.08C53.3428 152.792 53.4708 152.472 53.7588 152.344C55.2308 151.608 56.7348 150.872 58.2388 150.136C59.0388 149.752 59.5188 149.56 60.5748 149.08C61.2788 148.76 62.2708 148.312 63.8068 147.608C66.8468 146.232 67.7748 145.784 69.0868 145.08C70.0148 144.568 70.9748 144.024 71.8708 143.448C71.9668 143.384 72.0948 143.352 72.2228 143.352H183.103C183.199 143.352 183.295 143.384 183.391 143.416C184.223 143.8 185.087 144.216 185.919 144.632C187.295 145.336 188.191 145.816 188.959 146.264C189.567 146.584 190.111 146.904 190.847 147.288C191.967 147.864 192.511 148.088 193.311 148.408C193.919 148.664 194.719 148.984 195.967 149.528C197.855 150.36 199.743 151.32 201.631 152.312C201.887 152.44 202.047 152.76 201.951 153.048C201.855 153.336 201.599 153.592 201.279 153.592ZM56.8628 152.248H198.527C197.471 151.736 196.415 151.224 195.391 150.776C194.143 150.232 193.407 149.912 192.767 149.656C191.935 149.304 191.327 149.08 190.175 148.472C189.439 148.088 188.863 147.768 188.255 147.416C187.455 146.968 186.623 146.52 185.279 145.816C184.511 145.432 183.711 145.048 182.943 144.664H72.4148C71.5508 145.208 70.6228 145.72 69.7268 146.232C68.3828 146.968 67.4548 147.384 64.3508 148.792C62.8148 149.496 61.8228 149.944 61.1188 150.264C60.0628 150.744 59.6148 150.936 58.7828 151.32C58.1428 151.64 57.5028 151.928 56.8628 152.248Z"
      fill={contentPrimary}
    />
    <path
      d="M35.8711 190.167C36.7671 189.751 38.1431 189.079 39.8391 188.215C43.1671 186.487 43.4551 186.103 45.8871 184.983C47.8071 184.087 48.1591 184.055 50.7831 182.871C52.1911 182.231 53.3111 181.655 54.0791 181.271"
      fill={black}
    />
    <path
      d="M35.87 190.84C35.614 190.84 35.39 190.712 35.262 190.456C35.102 190.136 35.262 189.72 35.582 189.56C36.894 188.952 38.206 188.312 39.518 187.64C41.022 186.872 41.886 186.36 42.654 185.944C43.55 185.432 44.254 185.016 45.598 184.408C46.494 183.992 47.07 183.736 47.678 183.512C48.382 183.224 49.118 182.936 50.494 182.296C51.614 181.784 52.702 181.24 53.758 180.696C54.078 180.536 54.494 180.664 54.654 180.984C54.814 181.304 54.686 181.72 54.366 181.88C53.278 182.424 52.158 183 51.038 183.512C49.63 184.152 48.862 184.472 48.19 184.76C47.614 185.016 47.038 185.24 46.174 185.624C44.894 186.232 44.222 186.616 43.326 187.096C42.59 187.512 41.662 188.024 40.158 188.824C38.846 189.496 37.502 190.168 36.158 190.776C36.03 190.84 35.934 190.84 35.87 190.84Z"
      fill={contentPrimary}
    />
    <path
      d="M219.485 190.168C217.661 189.336 216.157 188.568 215.037 187.992C212.317 186.616 211.869 186.264 209.693 185.208C207.901 184.344 207.613 184.312 205.309 183.224C203.581 182.424 202.173 181.72 201.277 181.24"
      fill={black}
    />
    <path
      d="M219.486 190.84C219.39 190.84 219.294 190.808 219.198 190.776C217.694 190.072 216.19 189.336 214.75 188.6C213.566 187.992 212.798 187.576 212.158 187.224C211.326 186.776 210.654 186.392 209.438 185.816C208.542 185.4 208.03 185.176 207.422 184.92C206.846 184.664 206.174 184.376 205.054 183.864C203.678 183.224 202.302 182.552 200.99 181.88C200.67 181.72 200.542 181.304 200.702 180.984C200.862 180.664 201.278 180.536 201.598 180.696C202.91 181.368 204.254 182.04 205.63 182.68C206.75 183.224 207.422 183.48 207.966 183.736C208.574 183.992 209.086 184.216 210.014 184.664C211.262 185.272 211.934 185.624 212.798 186.104C213.47 186.456 214.206 186.872 215.39 187.48C216.83 188.216 218.334 188.952 219.806 189.624C220.126 189.784 220.286 190.168 220.126 190.52C219.998 190.712 219.742 190.84 219.486 190.84Z"
      fill={contentPrimary}
    />
    <path
      d="M148.16 75.352C144.832 70.104 137.76 68.28 132.256 71.256C129.408 72.792 127.552 75.224 125.984 77.976C125.696 78.488 125.024 78.968 124.448 79.096C121.536 79.768 118.816 80.824 116.512 82.776C111.616 86.968 110.72 94.36 114.592 98.968C116.48 101.24 118.976 102.648 121.728 103.608C127.392 105.592 133.6 102.904 135.808 97.56C136.224 96.536 136.48 95.416 136.832 94.232C139.968 94.456 142.72 93.464 145.216 91.544C150.112 87.768 151.488 80.536 148.16 75.352ZM127.392 81.464L136 82.808L134.656 91.384L126.048 90.04L127.392 81.464ZM124.896 81.528L123.648 89.56L115.424 88.28C116.512 84.376 121.76 81.688 124.896 81.528ZM125.216 101.88C121.568 101.656 118.56 100.024 116.224 97.272C114.656 95.448 114.176 93.24 114.656 90.712L134.208 93.752C134.176 98.456 129.952 102.2 125.216 101.88ZM128.224 79.064C129.952 74.264 135.36 71.288 140.192 72.408C144.832 73.464 148.064 77.656 147.584 82.104L128.224 79.064ZM137.056 91.864L138.4 83.192L147.104 84.536C146.272 88.792 141.536 92.216 137.056 91.864Z"
      fill={contentPrimary}
    />
    <path
      d="M104.286 82.5196C103.102 78.9356 102.014 78.3916 99.2299 77.2396C99.0379 77.1756 99.0699 76.9196 99.2299 76.8556C101.854 75.9916 103.23 75.1596 104.286 71.7036C104.35 71.5116 104.638 71.5116 104.67 71.7036C105.63 75.0636 106.686 76.2156 109.086 76.8876C109.278 76.9516 109.31 77.1756 109.118 77.2716C106.622 78.5836 105.63 79.1916 104.67 82.5196C104.606 82.7116 104.35 82.7116 104.286 82.5196Z"
      fill={contentPrimary}
    />
    <path
      d="M155.391 74.1683C154.431 71.2883 153.567 70.8403 151.295 69.8803C151.167 69.8163 151.167 69.6243 151.295 69.5603C153.439 68.8563 154.527 68.1843 155.391 65.4003C155.455 65.2403 155.679 65.2403 155.711 65.4003C156.479 68.1203 157.343 69.0483 159.263 69.5923C159.423 69.6243 159.423 69.8163 159.295 69.9123C157.279 70.9683 156.479 71.4483 155.711 74.1683C155.647 74.3283 155.455 74.3283 155.391 74.1683Z"
      fill={contentPrimary}
    />
    <path
      d="M152.159 102.36C151.423 100.12 150.751 99.7682 148.959 99.0322C148.863 99.0002 148.863 98.8402 148.959 98.7762C150.623 98.2322 151.455 97.7202 152.127 95.5442C152.159 95.4162 152.351 95.4162 152.383 95.5442C152.991 97.6562 153.663 98.3922 155.167 98.8082C155.295 98.8402 155.295 99.0002 155.199 99.0642C153.631 99.8962 152.991 100.28 152.383 102.36C152.383 102.488 152.223 102.488 152.159 102.36Z"
      fill={contentPrimary}
    />
    <path
      d="M116.352 116.312C116.352 117.496 124.768 117.432 126.432 117.336C131.808 117.08 139.008 117.016 138.88 116.088C138.752 115.16 129.728 114.84 127.168 114.968C124.608 115.064 116.352 115.16 116.352 116.312Z"
      fill={black}
    />
  </Fragment>
));
IllustrationOneMoreStep.displayName = "IllustrationOneMoreStep";
