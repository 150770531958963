import { Dispatch } from "react";
import { Action, ActionType, State } from "@gemini-ui/components/TransferControl/reducer";
import { Flex, Form, Input, Text, useForm } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

type ConfirmAddressFormValues = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postalCode?: string;
};

interface Props {
  state: State;
  dispatch: Dispatch<Action>;
}

function ConfirmAddress({ state, dispatch }: Props) {
  const { intl } = useIntl();
  const { transferControl } = testIds;
  const { ownerAddressLine1, ownerAddressLine2, ownerCity, ownerPostalCode } = state;

  const {
    formState: { errors },
    register,
  } = useForm<ConfirmAddressFormValues>({
    defaultValues: {
      addressLine1: ownerAddressLine1 || "",
      addressLine2: ownerAddressLine2 || "",
      city: ownerCity || "",
      postalCode: ownerPostalCode || "",
    },
  });

  const postalCodeCopy = intl.formatMessage({
    defaultMessage: "Postal code",
  });

  return (
    <Flex flexDirection="column">
      <Text.Body size="sm" mb={3}>
        {intl.formatMessage({
          defaultMessage: "Please provide the sender’s address. If you don't know this information, click continue.",
        })}
      </Text.Body>

      <Form errors={errors}>
        <Input
          mt={1}
          id="addressLine1"
          inputSize="lg"
          data-testid={transferControl.confirmSenderAddressLine1}
          placeholder={intl.formatMessage({
            defaultMessage: "600 Third Avenue",
          })}
          label={intl.formatMessage({
            defaultMessage: "Address line 1",
          })}
          {...register("addressLine1")}
          onChange={e => {
            dispatch({ type: ActionType.OWNER_ADDRESS_LINE_1, payload: e.currentTarget.value });
          }}
          error={errors?.addressLine1?.message}
        />

        <Input
          mt={1}
          id="addressLine2"
          inputSize="lg"
          data-testid={transferControl.confirmSenderAddressLine2}
          placeholder={intl.formatMessage({
            defaultMessage: "Unit or apartment number",
          })}
          label={intl.formatMessage({
            defaultMessage: "Address line 2",
          })}
          {...register("addressLine2")}
          onChange={e => {
            dispatch({ type: ActionType.OWNER_ADDRESS_LINE_2, payload: e.currentTarget.value });
          }}
          error={errors?.addressLine2?.message}
        />

        <Input
          mt={1}
          id="city"
          inputSize="lg"
          data-testid={transferControl.confirmSenderCity}
          placeholder={intl.formatMessage({
            defaultMessage: "City, town, or subdivision",
          })}
          label={intl.formatMessage({
            defaultMessage: "City",
          })}
          {...register("city")}
          onChange={e => {
            dispatch({ type: ActionType.OWNER_CITY, payload: e.currentTarget.value });
          }}
          error={errors?.city?.message}
        />

        <Input
          mt={1}
          id="postalCode"
          inputSize="lg"
          data-testid={transferControl.confirmSenderPostalCode}
          placeholder={postalCodeCopy}
          label={postalCodeCopy}
          {...register("postalCode")}
          onChange={e => {
            dispatch({ type: ActionType.OWNER_POSTAL_CODE, payload: e.currentTarget.value });
          }}
          error={errors?.postalCode?.message}
        />
      </Form>
    </Flex>
  );
}

export default ConfirmAddress;
