import { Reducer, useReducer } from "react";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import { useMedia } from "react-use";
import { useTransferControlConfig } from "@gemini-ui/components/TransferControl/config";
import { Action, ActionType, initState, reducer, State } from "@gemini-ui/components/TransferControl/reducer";
import { ModalStep, OwnershipStep, OwnerType, SenderType } from "@gemini-ui/components/TransferControl/types";
import { TransferType } from "@gemini-ui/constants/custody";
import { usePageData } from "@gemini-ui/contexts";
import { Button, mediaQuery, Modal, Text, text, useToaster } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import axios from "@gemini-ui/services/axios";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { useIntl } from "@gemini-ui/utils/intl";

const HistoryController = jsRoutes.controllers.order.HistoryController;

interface Props {
  isOpen: boolean;
  attestation: TransferType | null;
  onModalClose: () => void;
  onSubmitAttestation?: () => void;
}

export function TransferControl({ isOpen, attestation, onModalClose, onSubmitAttestation }: Props) {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();
  const { transferControl } = testIds;

  const dateReceived = attestation ? format(attestation?.timestamp, DateFormats.MonthAbbrevDayYear) : "";
  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initState);
  const views = useTransferControlConfig({
    state,
    dispatch,
    onClose,
    onStepChange,
    submitAttestation,
    assetAmount: attestation?.amount,
    dateReceived,
    isUkTravelRuleEnabled,
  });

  const isMobile = useMedia(mediaQuery.mobileXsDown);
  const { showToast } = useToaster();

  if (!attestation) return null;

  function onClose() {
    dispatch({ type: ActionType.RESET });
    onModalClose();
  }

  function onStepChange(step: ModalStep, value?: any) {
    if (step === ModalStep.SelectSender) {
      dispatch({ type: ActionType.RESET });
      return;
    }

    dispatch({ type: ActionType.MODAL_STEP, payload: step });

    if (step === ModalStep.ConfirmOwnership && value) {
      dispatch({ type: ActionType.SENDER_TYPE, payload: value });

      let ownerShipStep: OwnershipStep;

      switch (value) {
        case SenderType.MY_SELF:
          ownerShipStep = OwnershipStep.ReviewSelf;
          break;
        case SenderType.SOMEONE_ELSE:
          ownerShipStep = OwnershipStep.ConfirmOwner;
          break;
        case SenderType.NOT_RECOGNIZED:
          ownerShipStep = OwnershipStep.ReviewUnknown;
          break;
      }

      dispatch({ type: ActionType.CONFIRM_OWNERSHIP_STEP, payload: ownerShipStep });
    }
  }

  async function submitAttestation() {
    try {
      const body: Record<string, Record<string, string | boolean>> = {};

      switch (state.senderType) {
        case SenderType.MY_SELF:
          if (isUkTravelRuleEnabled) body.fcaSelfToSelf = {};
          else body.selfToSelf = {};
          break;
        case SenderType.SOMEONE_ELSE:
          const isIndividual = state.ownerType === OwnerType.INDIVIDUAL;
          const country = state.ownerCountryCode.toUpperCase();

          if (isUkTravelRuleEnabled) {
            body.fcaThirdParty = {
              firstName: isIndividual ? state.ownerFirstName : state.ownerName,
              lastName: isIndividual ? state.ownerLastName : "",
              isIndividual,
              country,
              addressLine1: state.ownerAddressLine1 || "",
              addressLine2: state.ownerAddressLine2 || "",
              city: state.ownerCity || "",
              postalCode: state.ownerPostalCode || "",
            };
          } else {
            body.thirdParty = {
              name: state.ownerName,
              isIndividual,
              country,
            };
          }
          break;
        case SenderType.NOT_RECOGNIZED: // not used for UK Travel Rule; this option is hidden
          body.unrecognized = {};
          break;
      }

      const encodedId = encodeURI(attestation?.depositId);
      const singaporeRoute = HistoryController.attestDepositSource(encodedId);
      const ukRoute = HistoryController.attestUkDepositSource(encodedId);
      const attestDepositRoute = isUkTravelRuleEnabled ? ukRoute : singaporeRoute;
      const resp = await axios.post(attestDepositRoute.url, body);

      dispatch({ type: ActionType.IS_SUBMITTING, payload: false });

      const isComplyAdvantageMismatchError = resp?.data?.status === "account frozen";
      const success = resp?.status === 200;

      if (
        (state.confirmOwnershipStep === OwnershipStep.ReviewSelf ||
          state.confirmOwnershipStep === OwnershipStep.ReviewThirdParty) &&
        success
      ) {
        onStepChange(ModalStep.Complete);
      }

      if (!isComplyAdvantageMismatchError && !success) {
        showToast({
          message: intl.formatMessage({
            defaultMessage: "There was an error processing your request. Please try again",
          }),
          "data-testid": transferControl.submissionError,
        });
      }

      if (
        (state.confirmOwnershipStep === OwnershipStep.ReviewThirdParty && isComplyAdvantageMismatchError) ||
        (state.confirmOwnershipStep === OwnershipStep.ReviewUnknown && success)
      ) {
        onStepChange(ModalStep.ComplyAdvantageMismatch);
      }
    } catch (e) {
      Sentry.captureException(e);

      const clientError = e?.response?.status >= 400 && e?.response?.status < 500;

      if (clientError) {
        onStepChange(ModalStep.Error);
      }
    } finally {
      if (onSubmitAttestation) {
        onSubmitAttestation();
      }
    }
  }

  let canGoBack = {};

  if (state.modalStep === ModalStep.ConfirmOwnership) {
    canGoBack = {
      onBack: () => {
        switch (state.confirmOwnershipStep) {
          case OwnershipStep.ReviewSelf:
          case OwnershipStep.ConfirmOwner:
          case OwnershipStep.ReviewUnknown:
            onStepChange(ModalStep.SelectSender);
            break;
          case OwnershipStep.ConfirmAddress:
          case OwnershipStep.ReviewThirdParty:
            if (isUkTravelRuleEnabled && state.confirmOwnershipStep === OwnershipStep.ReviewThirdParty) {
              dispatch({ type: ActionType.CONFIRM_OWNERSHIP_STEP, payload: OwnershipStep.ConfirmAddress });
            } else {
              dispatch({ type: ActionType.CONFIRM_OWNERSHIP_STEP, payload: OwnershipStep.ConfirmOwner });
            }
            break;
        }
      },
    };
  }

  const view = views[state.modalStep];
  let maxWidth = isMobile ? "80%" : "60%";

  if (state.confirmOwnershipStep === OwnershipStep.ConfirmAddress) maxWidth = "100%";

  return (
    <Modal.MultiStep
      isOpen={isOpen}
      title={
        <Text.Body
          as="span"
          css={{ ...text.base.scale[24], maxWidth, display: "block" }}
          bold
          align="left"
          mt={state.modalStep === ModalStep.Complete ? 5 : 0}
          data-testid={transferControl.attestationTitle}
        >
          {view.title}
        </Text.Body>
      }
      headerImage={view.headerImage}
      centered={view.centered}
      onClose={onClose}
      {...canGoBack}
    >
      {view.component}

      {view.ctas?.length && (
        <Button.Group>
          <Button.Tertiary {...view.ctas[0]} />
          <Button.Primary {...view.ctas[1]} />
        </Button.Group>
      )}
    </Modal.MultiStep>
  );
}

export default TransferControl;
