import { Fragment, ReactNode } from "react";
import { GrowSuccessAnimation } from "@gemini-ui/components/GrowSuccessAnimation";
import { Money } from "@gemini-ui/components/Money";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, Text } from "@gemini-ui/design-system";
import { PlaceRedeemInfo } from "@gemini-ui/pages/Earn/Redeem/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends Pick<PlaceRedeemInfo, "amount" | "currency" | "provider" | "redemptionStatus"> {
  onDone?: () => void;
}

export const Success = ({ amount, currency, provider, onDone }: Props) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();
  const { providerType } = provider;

  return (
    <Fragment>
      <GrowSuccessAnimation />
      <Flex flexDirection="column" align="start" mt={2} mb={4}>
        <Text.Heading size="lg" mb={1}>
          {intl.formatMessage({ defaultMessage: "Success!" })}
        </Text.Heading>
        <Text.Body size="sm" data-testid={testIds.redeem.success.message}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You {providerType, select, Earn {redeemed <b><Money></Money></b> from your Earn} other {unstaked <b><Money></Money></b> from your Staking}} balance into your trading balance. You can view your new balance in your Portfolio.",
            }),
            {
              providerType,
              Money: () => <Money currency={currency} value={amount} />,
              b: (v: ReactNode) => <b>{v}</b>,
              defaultFiat,
            }
          )}
        </Text.Body>
      </Flex>

      <Button.Group type="action">
        <Button.Tertiary
          size="md"
          data-testid={testIds.redeem.success.portfolioButton}
          onClick={() => window.location.assign(jsRoutes.controllers.dashboard.DashboardController.portfolio().url)}
          cta={intl.formatMessage({ defaultMessage: "Go to Portfolio" })}
        />
        <Button.Primary
          size="md"
          data-testid={testIds.redeem.success.doneButton}
          onClick={() => {
            onDone?.();
          }}
          cta={intl.formatMessage({ defaultMessage: "Done" })}
        />
      </Button.Group>
    </Fragment>
  );
};
