import { ReactNode, useEffect, useRef, useState } from "react";
import { InfiniteScrollContainer } from "@gemini-ui/components/InfiniteScrollWrapper/styles";
import { useScroll } from "@gemini-ui/components/InfiniteScrollWrapper/useScroll";

const OFFSET = 400;
const BUFFER = 500;

interface InfiniteScrollWrapperProps {
  buffer?: number; // added to the scroll position to keep track of the current position
  children: ReactNode;
  "data-testid"?: string;
  height?: number; // the height of the container
  loading?: boolean; // to prevent duplicate fetches if user scrolls faster than response time
  offset?: number; // how far the user must scroll before triggering fetch
  onLoadMore: () => void;
  resetPosition?: boolean; // useful if the list has a filtering mechanism
}

export function useInfiniteScroll<ElementType extends HTMLElement>({
  buffer = BUFFER,
  loading = false,
  offset = OFFSET,
  onLoadMore,
  resetPosition = false,
  throttle = 200,
}) {
  const scrollRef = useRef<ElementType>(null);
  const { y } = useScroll(scrollRef, throttle);
  const [currentPosition, setCurrentPosition] = useState(offset);

  useEffect(() => {
    if (resetPosition && currentPosition > offset && !loading) {
      setCurrentPosition(offset);
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0);
      }
      return;
    }
    if (y > currentPosition && !loading) {
      setCurrentPosition(y + buffer);
      onLoadMore();
    }
  }, [buffer, currentPosition, loading, offset, onLoadMore, resetPosition, y]);

  return scrollRef;
}

/**
 * Adds infinite scroll support for a customizable list container that needs to load more data on scroll
 */
export function InfiniteScrollWrapper({
  buffer = BUFFER,
  children,
  ["data-testid"]: dataTestId,
  height,
  loading = false,
  offset = OFFSET,
  onLoadMore,
  resetPosition = false,
}: InfiniteScrollWrapperProps) {
  const scrollRef = useInfiniteScroll<HTMLDivElement>({ buffer, loading, offset, onLoadMore, resetPosition });
  return (
    <InfiniteScrollContainer data-testid={dataTestId} height={height} ref={scrollRef}>
      {children}
    </InfiniteScrollContainer>
  );
}
