import { IconChevronLeftLarge, IconChevronRightLarge } from "@hubble/icons";
import { PaginationState } from "@tanstack/react-table";
import { Button } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import { testIds } from "@gemini-ui/design-system/Table/testIds";
import { Text } from "@gemini-ui/design-system/Text";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type TablePaginationProps = {
  nextPage: () => void;
  previousPage: () => void;
  getCanNextPage: () => boolean;
  getCanPreviousPage: () => boolean;
  getPageCount: () => number;
  paginationState: PaginationState;
  loading?: boolean;
  hideTotalPageCount?: boolean;
};

export const TablePagination = ({
  loading,
  nextPage,
  previousPage,
  getCanNextPage,
  getCanPreviousPage,
  getPageCount,
  paginationState,
  hideTotalPageCount,
}: TablePaginationProps) => {
  const { intl } = useIntl();

  const pageCount = getPageCount();
  if (pageCount <= 1) return null;

  return (
    <Flex m={2} align="center" justify="center">
      <Button.Tertiary
        display="inline"
        size="sm"
        disabled={!getCanPreviousPage() || loading}
        onClick={() => previousPage()}
        aria-label={intl.formatMessage({ defaultMessage: "Previous page" })}
        data-testid={testIds.previousPaginationButton}
        icon={<IconChevronLeftLarge />}
      />

      <Text.Body size="xs" ml={1} mr={1} data-testid={testIds.paginationCounter}>
        {hideTotalPageCount
          ? paginationState.pageIndex + 1
          : intl.formatMessage(
              defineMessage({
                defaultMessage: "{currentPage} of {pageCount}",
              }),
              { currentPage: paginationState.pageIndex + 1, pageCount }
            )}
      </Text.Body>

      <Button.Tertiary
        display="inline"
        size="sm"
        disabled={!getCanNextPage() || loading}
        onClick={() => nextPage()}
        aria-label={intl.formatMessage({ defaultMessage: "Next page" })}
        data-testid={testIds.nextPaginationButton}
        icon={<IconChevronRightLarge />}
      />
    </Flex>
  );
};
