/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationSuccess = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M189.695 166.144C175.743 183.584 142.975 183.744 131.871 183.424C120.767 183.104 83.1671 184.832 43.8711 197.056V122.4C86.9751 119.232 116.063 118.752 128.095 103.776C140.127 88.7998 135.423 61.3438 144.031 56.4798C156.639 49.3118 160.831 66.9438 160.511 82.1438C160.191 97.3758 159.231 134.464 138.335 145.536L189.695 166.144Z"
      fill={black}
    />
    <path
      d="M43.8708 197.408C43.8068 197.408 43.7108 197.376 43.6788 197.344C43.5828 197.28 43.5508 197.184 43.5508 197.056V122.4C43.5508 122.208 43.6788 122.08 43.8708 122.048C48.1268 121.728 52.2228 121.44 56.1908 121.184C92.8628 118.624 117.087 116.96 127.839 103.552C134.143 95.712 135.807 84.576 137.247 74.752C138.527 66.048 139.647 58.56 143.871 56.16C147.391 54.144 150.495 53.92 153.087 55.488C159.167 59.104 161.087 71.648 160.863 82.144C160.575 96.32 159.775 133.792 139.135 145.504L189.823 165.824C189.919 165.856 190.015 165.952 190.015 166.048C190.047 166.144 190.015 166.272 189.951 166.368C183.935 173.92 173.759 179.104 159.775 181.792C151.519 183.392 141.887 184.064 131.871 183.776C120.639 183.456 83.0068 185.28 43.9988 197.408C43.9348 197.376 43.9028 197.408 43.8708 197.408ZM44.2228 122.72V196.576C83.1668 184.544 120.671 182.752 131.871 183.072C141.855 183.36 151.455 182.688 159.647 181.088C173.247 178.464 183.167 173.472 189.119 166.272L138.207 145.856C138.079 145.792 137.983 145.696 137.983 145.568C137.983 145.44 138.047 145.312 138.175 145.248C159.103 134.144 159.871 96.384 160.191 82.144C160.511 66.4 156.575 58.368 152.735 56.096C150.367 54.688 147.487 54.912 144.191 56.8C140.287 59.04 139.199 66.368 137.919 74.88C136.447 84.8 134.783 96.032 128.351 104.032C117.407 117.664 93.0548 119.36 56.1908 121.92C52.3508 122.112 48.3508 122.4 44.2228 122.72Z"
      fill={black}
    />
    <path
      d="M186.049 95.9997C179.521 97.4397 159.041 107.744 159.073 115.488C159.105 127.296 175.137 123.84 186.785 118.816C203.681 111.52 209.601 99.6156 202.273 91.1356C197.121 85.1836 187.265 81.9837 160.065 94.5917L110.785 135.904L178.145 170.72"
      fill={black}
    />
    <path
      d="M178.11 171.04C178.046 171.04 178.014 171.04 177.95 171.008L110.59 136.192C110.494 136.128 110.43 136.032 110.398 135.936C110.398 135.808 110.43 135.712 110.526 135.616L159.806 94.3037C159.838 94.2717 159.87 94.2717 159.87 94.2397C182.366 83.8397 195.518 82.8157 202.494 90.8797C205.246 94.0797 206.238 97.8237 205.342 101.728C203.806 108.352 197.086 114.72 186.878 119.104C182.686 120.928 168.478 126.496 161.886 122.176C159.774 120.8 158.686 118.528 158.686 115.456C158.686 112.992 160.606 108.864 169.886 103.04C176.094 99.1357 182.782 96.3197 185.95 95.6157C186.142 95.5837 186.334 95.6797 186.366 95.8717C186.398 96.0637 186.302 96.2557 186.11 96.2877C183.006 96.9597 176.414 99.7757 170.27 103.616C163.262 108.032 159.39 112.224 159.39 115.456C159.39 118.272 160.382 120.352 162.27 121.6C164.574 123.104 168.222 123.488 173.118 122.656C177.086 121.984 181.886 120.512 186.622 118.464C196.638 114.144 203.198 107.968 204.67 101.568C205.502 97.8877 204.574 94.3357 201.982 91.3277C198.974 87.8397 195.006 86.2077 189.534 86.2077C189.502 86.2077 189.438 86.2077 189.406 86.2077C182.238 86.2397 172.67 89.0557 160.222 94.8477L111.358 135.808L178.27 170.368C178.43 170.464 178.494 170.656 178.43 170.848C178.366 170.976 178.238 171.04 178.11 171.04Z"
      fill={black}
    />
    <path
      d="M202.402 107.296C210.37 108.832 220.034 123.168 194.722 134.144C173.794 143.2 160.61 140.96 159.042 133.664C157.858 128.192 161.474 123.776 164.962 123.104"
      fill={black}
    />
    <path
      d="M171.106 140.416C167.81 140.416 164.994 139.872 162.85 138.784C160.61 137.632 159.17 135.904 158.722 133.76C158.146 131.104 158.626 128.448 160.066 126.272C161.282 124.416 163.106 123.136 164.93 122.784C165.122 122.752 165.314 122.88 165.346 123.072C165.378 123.264 165.25 123.456 165.058 123.488C163.426 123.808 161.762 124.992 160.642 126.688C159.298 128.704 158.85 131.168 159.394 133.632C159.81 135.584 161.09 137.12 163.17 138.176C169.314 141.344 181.058 139.712 194.594 133.856C209.506 127.392 211.074 120.32 210.658 116.672C210.146 111.936 206.018 108.384 202.338 107.68C202.146 107.648 202.018 107.456 202.05 107.264C202.082 107.072 202.274 106.944 202.466 106.976C206.402 107.744 210.786 111.52 211.33 116.576C211.746 120.416 210.178 127.84 194.85 134.464C185.794 138.4 177.506 140.416 171.106 140.416Z"
      fill={black}
    />
    <path
      d="M207.392 126.24C216.352 128.096 218.624 145.536 194.496 151.04C169.6 156.736 165.248 151.52 164.288 146.272C163.84 143.872 166.304 140.672 168.32 139.936"
      fill={black}
    />
    <path
      d="M176.832 153.856C172.608 153.856 169.44 153.152 167.328 151.744C165.472 150.528 164.384 148.8 163.936 146.336C163.488 143.84 165.952 140.416 168.192 139.616C168.384 139.552 168.576 139.648 168.64 139.84C168.704 140.032 168.608 140.224 168.416 140.288C166.08 141.12 164.256 144.416 164.608 146.24C165.024 148.512 165.984 150.08 167.68 151.2C171.936 154.016 180.672 153.856 194.368 150.72C208.416 147.52 213.344 140.256 213.28 134.56C213.248 130.592 210.752 127.328 207.264 126.592C207.072 126.56 206.944 126.368 207.008 126.176C207.04 125.984 207.232 125.856 207.424 125.92C211.232 126.72 213.952 130.24 213.984 134.56C214.048 140.544 208.96 148.096 194.528 151.392C187.328 153.024 181.44 153.856 176.832 153.856Z"
      fill={black}
    />
    <path
      d="M209.987 143.2C215.683 145.504 219.203 160.288 195.491 164.96C180.707 167.872 170.275 167.488 169.539 160.288C169.251 157.44 171.299 154.976 173.475 153.472"
      fill={black}
    />
    <path
      d="M181.121 167.04C176.769 167.04 173.633 166.304 171.649 164.832C170.177 163.744 169.345 162.24 169.153 160.352C168.897 157.824 170.433 155.168 173.217 153.216C173.377 153.12 173.601 153.152 173.697 153.312C173.793 153.472 173.761 153.696 173.601 153.792C172.641 154.464 169.505 156.928 169.825 160.288C169.985 161.984 170.721 163.296 172.033 164.288C175.585 166.912 183.201 167.04 195.361 164.672C206.433 162.496 213.153 157.632 213.729 151.36C214.113 147.488 211.969 144.448 209.793 143.552C209.601 143.488 209.537 143.264 209.601 143.104C209.665 142.912 209.889 142.848 210.049 142.912C212.481 143.904 214.817 147.2 214.401 151.424C214.209 153.472 213.249 156.48 209.825 159.36C206.561 162.112 201.729 164.128 195.457 165.344C189.761 166.464 184.961 167.04 181.121 167.04Z"
      fill={black}
    />
    <path
      d="M43.8731 122.4C40.5771 128.928 35.0411 142.048 34.9771 159.456C34.9131 177.184 40.5771 190.496 43.8731 197.056"
      fill={black}
    />
    <path
      d="M43.8733 197.728C43.6173 197.728 43.3613 197.6 43.2653 197.344C39.1373 189.184 34.2413 175.968 34.3053 159.424C34.3373 146.496 37.4413 133.6 43.2653 122.048C43.4253 121.696 43.8413 121.568 44.1933 121.728C44.5453 121.888 44.6733 122.304 44.5133 122.656C38.7853 133.984 35.7133 146.72 35.6813 159.392C35.6173 175.68 40.4493 188.64 44.5133 196.672C44.6733 197.024 44.5453 197.44 44.1933 197.6C44.0653 197.728 43.9693 197.728 43.8733 197.728Z"
      fill={white}
    />
    <path
      d="M43.8725 197.728C43.5845 197.728 43.2965 197.536 43.2005 197.248C43.0725 196.896 43.2964 196.48 43.6484 196.384C57.3444 192.192 71.4564 188.928 85.6004 186.656C102.336 183.968 117.728 183.168 131.84 182.72C132.608 182.688 133.504 182.688 134.464 182.656C147.008 182.368 176.352 181.664 189.152 165.696C189.376 165.408 189.824 165.344 190.112 165.6C190.4 165.824 190.464 166.272 190.208 166.56C177.024 183.04 147.232 183.744 134.496 184.032C133.536 184.064 132.64 184.064 131.872 184.096C117.792 184.544 102.496 185.344 85.8244 188C71.7444 190.272 57.6965 193.504 44.0645 197.696C44.0005 197.728 43.9365 197.728 43.8725 197.728Z"
      fill={white}
    />
    <path
      d="M138.337 146.208C138.081 146.208 137.857 146.08 137.729 145.824C137.537 145.472 137.665 145.056 138.017 144.896C158.753 133.888 159.553 96.2563 159.841 82.0803C160.033 72.2563 158.241 59.5843 152.545 56.2243C150.305 54.9123 147.553 55.1683 144.417 57.0243C139.521 59.9043 138.529 67.6163 137.409 76.5763C136.225 85.8883 134.881 96.4483 128.673 104.16C117.633 117.888 93.2169 119.584 56.2889 122.176C52.3209 122.464 48.1929 122.752 43.9689 123.04C43.5849 123.072 43.2649 122.784 43.2329 122.4C43.2009 122.016 43.4889 121.696 43.8729 121.664C48.1289 121.344 52.2249 121.056 56.1929 120.8C92.7689 118.24 116.929 116.576 127.617 103.296C133.569 95.8723 134.881 85.5363 136.033 76.3843C137.217 67.0723 138.241 59.0083 143.745 55.8083C147.361 53.6963 150.593 53.4403 153.281 55.0403C159.521 58.7203 161.505 71.4243 161.281 82.1123C160.993 96.5123 160.193 134.72 138.721 146.112C138.561 146.208 138.433 146.208 138.337 146.208Z"
      fill={white}
    />
    <path
      d="M168.48 124.16C165.664 124.16 163.392 123.616 161.696 122.496C159.488 121.056 158.336 118.688 158.336 115.488C158.304 107.2 179.2 96.7675 185.856 95.3275C186.24 95.2315 186.592 95.4875 186.688 95.8715C186.784 96.2555 186.528 96.6075 186.144 96.7035C179.648 98.1115 159.68 108.352 159.712 115.52C159.712 118.272 160.608 120.192 162.432 121.376C167.328 124.576 178.048 121.856 186.432 118.208C196.32 113.92 202.848 107.872 204.288 101.536C205.12 97.9515 204.192 94.5275 201.664 91.5835C198.72 88.1915 194.848 86.5915 189.472 86.5915C189.44 86.5915 189.376 86.5915 189.344 86.5915C182.208 86.6235 172.704 89.4395 160.256 95.1995C159.904 95.3595 159.488 95.1995 159.328 94.8475C159.168 94.4955 159.328 94.0795 159.68 93.9195C182.336 83.4235 195.584 82.4315 202.688 90.6555C205.536 93.9515 206.528 97.7915 205.632 101.824C204.096 108.576 197.28 115.008 186.976 119.456C183.584 120.928 178.464 122.848 173.248 123.712C171.552 124 169.952 124.16 168.48 124.16Z"
      fill={white}
    />
    <path
      d="M171.168 140.8C167.776 140.8 164.928 140.224 162.656 139.072C160.32 137.856 158.816 136.064 158.336 133.792C157.728 131.04 158.24 128.288 159.744 126.048C161.024 124.128 162.912 122.752 164.832 122.4C165.216 122.336 165.568 122.592 165.632 122.944C165.696 123.328 165.44 123.68 165.088 123.744C163.552 124.032 161.984 125.184 160.896 126.784C159.616 128.736 159.168 131.104 159.712 133.472C160.128 135.328 161.312 136.768 163.296 137.792C169.344 140.896 180.992 139.264 194.432 133.44C209.152 127.072 210.688 120.16 210.304 116.608C209.792 112.032 205.824 108.576 202.272 107.904C201.888 107.84 201.664 107.456 201.728 107.104C201.792 106.752 202.176 106.464 202.528 106.56C206.592 107.328 211.104 111.264 211.68 116.48C212.672 125.632 201.664 131.84 194.976 134.72C185.824 138.752 177.6 140.8 171.168 140.8Z"
      fill={white}
    />
    <path
      d="M176.831 154.208C172.511 154.208 169.311 153.504 167.135 152.064C165.183 150.784 164.063 148.992 163.615 146.432C163.103 143.712 165.727 140.192 168.095 139.328C168.447 139.2 168.863 139.392 168.991 139.744C169.119 140.096 168.927 140.512 168.575 140.64C166.751 141.28 164.607 144.224 164.991 146.208C165.375 148.384 166.303 149.888 167.903 150.944C172.031 153.664 180.895 153.472 194.335 150.432C208.191 147.264 213.055 140.192 212.991 134.624C212.959 130.816 210.591 127.68 207.263 127.008C206.879 126.944 206.655 126.56 206.719 126.176C206.783 125.792 207.167 125.568 207.551 125.632C211.551 126.464 214.367 130.144 214.399 134.592C214.463 140.704 209.311 148.448 194.655 151.776C187.391 153.376 181.471 154.208 176.831 154.208Z"
      fill={white}
    />
    <path
      d="M181.152 167.36C176.704 167.36 173.504 166.592 171.456 165.088C169.92 163.936 169.024 162.336 168.832 160.352C168.576 157.728 170.144 154.944 173.056 152.896C173.376 152.672 173.792 152.768 174.016 153.056C174.24 153.376 174.144 153.792 173.856 154.016C172.928 154.656 169.92 157.024 170.24 160.192C170.4 161.792 171.072 163.04 172.32 163.936C175.744 166.464 183.488 166.592 195.36 164.256C206.272 162.112 212.864 157.376 213.472 151.264C213.728 148.448 212.416 144.896 209.76 143.808C209.408 143.648 209.248 143.264 209.376 142.912C209.504 142.56 209.92 142.4 210.272 142.528C213.664 143.904 215.168 148.128 214.848 151.392C214.656 153.504 213.664 156.608 210.144 159.552C206.816 162.336 201.952 164.384 195.616 165.6C189.824 166.784 185.024 167.36 181.152 167.36Z"
      fill={white}
    />
    <path
      d="M203.424 226.624C200.8 218.656 198.368 217.44 192.128 214.816C191.744 214.656 191.744 214.08 192.16 213.952C198.048 212.032 201.024 210.176 203.424 202.496C203.552 202.048 204.192 202.048 204.32 202.496C206.464 209.984 208.8 212.576 214.144 214.016C214.56 214.112 214.592 214.688 214.24 214.88C208.672 217.792 206.464 219.168 204.32 226.592C204.192 227.04 203.584 227.072 203.424 226.624Z"
      fill={contentPrimary}
    />
    <path
      d="M83.6817 78.3361C81.0577 70.3681 78.6257 69.1521 72.3857 66.5281C72.0017 66.3681 72.0017 65.7921 72.4177 65.6641C78.3057 63.7441 81.2817 61.8881 83.6817 54.2081C83.8097 53.7601 84.4497 53.7601 84.5777 54.2081C86.7217 61.6961 89.0577 64.2881 94.4017 65.7281C94.8177 65.8241 94.8497 66.4001 94.4977 66.5921C88.9297 69.5041 86.7217 70.8801 84.5777 78.3041C84.4177 78.7521 83.8097 78.7841 83.6817 78.3361Z"
      fill={contentPrimary}
    />
    <path
      d="M228.894 197.536C227.614 193.984 226.366 193.216 223.55 192C223.166 191.84 223.166 191.264 223.582 191.136C226.238 190.208 227.742 189.184 228.926 185.76C229.054 185.344 229.662 185.344 229.79 185.792C230.846 189.12 232.03 190.464 234.398 191.232C234.782 191.36 234.814 191.872 234.462 192.064C231.966 193.408 230.814 194.24 229.758 197.504C229.63 197.92 229.022 197.952 228.894 197.536Z"
      fill={contentPrimary}
    />
  </Fragment>
));
IllustrationSuccess.displayName = "IllustrationSuccess";
