import { Colors, Flex, Text, text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const UkGeneralRiskWarning = () => {
  const { intl, buildGeminiPublicLink } = useIntl();

  return (
    <Flex pl={2} pr={2} justifyContent="center" backgroundColor={Colors.brandCyan}>
      <Text color={Colors.black} pb={1} pt={1} {...text.base.scale[14]}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Don't invest unless you're prepared to lose all the money you invest. This is a high-risk investment and you are unlikely to be protected if something goes wrong. <link>Take 2 mins to learn more</link>",
          }),
          {
            link: (str: React.ReactNode) => (
              <Text.Link
                href={buildGeminiPublicLink("uk-fca-risks")}
                target="_blank"
                color={Colors.black}
                fontWeight={text.base.weight.bold}
                css={{ ":hover": { color: Colors.white, opacity: 1 } }}
              >
                {str}
              </Text.Link>
            ),
          }
        )}
      </Text>
    </Flex>
  );
};

export default UkGeneralRiskWarning;
