import { Fragment } from "react";
import { IconProfileOutlined } from "@hubble/icons";
import { getMyAccountMenuData } from "@gemini-ui/components/Header/navigation/data/geminiMenuData";
import { HubbleMenuAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/HubbleMenuAccordion";
import { ItemTitle } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/components";
import { StyledItemLink } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/styles";
import { Text } from "@gemini-ui/design-system";
import { useEarnDistributionsFlags } from "@gemini-ui/pages/Earn/hooks/useEarnDistributionsFlags";
import { useIsQualifiedForCustodyBilling } from "@gemini-ui/pages/settings/Billing/utils";
import { useIntl } from "@gemini-ui/utils/intl";

export const AccountAccordion = ({ isCustodyAccount, isInstitutional }) => {
  const { intl } = useIntl();

  const { isEarnDistributionsPageEnabled } = useEarnDistributionsFlags();
  const { isQualified, hasFetched } = useIsQualifiedForCustodyBilling();
  const shouldShowBillingTab = isQualified && hasFetched;

  return (
    <HubbleMenuAccordion
      leftElement={
        <ItemTitle icon={<IconProfileOutlined size="sm" />} title={intl.formatMessage({ defaultMessage: "Account" })} />
      }
      content={
        <Fragment>
          {getMyAccountMenuData(
            intl,
            shouldShowBillingTab,
            isCustodyAccount,
            isEarnDistributionsPageEnabled,
            isInstitutional
          ).map(item => (
            <StyledItemLink
              indent
              label=""
              key={item.name}
              href={item.url}
              target={item.target}
              onClick={item.onClick}
              leftElement={
                <Text.Body fontSize="12px" lineHeight="16px" bold>
                  {item.name}
                </Text.Body>
              }
            />
          ))}
        </Fragment>
      }
    />
  );
};
