import { ReactNode } from "react";
import { HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { Button, Colors, Modal, Spacer, Spacing, Text } from "@gemini-ui/design-system";
import { ReactComponent as CircleCheckIcon } from "@gemini-ui/images/icons/circle-check-thick.svg";
import { FlexContainer } from "@gemini-ui/pages/settings/BankSettings/styles";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const WireSuccess = ({ isOpen, onClose, approved }) => {
  const { intl } = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {approved ? (
        <Spacer pt={3} pb={4}>
          <FlexContainer justifyContent="center">
            <CircleCheckIcon width={Spacing.scale[5]} fill={Colors.gray[800]} />
          </FlexContainer>
          <Text.Heading size="md" mt={2} mb={2} center>
            {intl.formatMessage({
              defaultMessage: "Wire transfers approved",
            })}
          </Text.Heading>
          <Text.Body size="sm" center>
            {intl.formatMessage({
              defaultMessage: "You can now use this bank account to make wire transfers",
            })}
          </Text.Body>
        </Spacer>
      ) : (
        <Spacer pt={3} pb={4}>
          <Text.Heading size="md" mb={1}>
            {intl.formatMessage({
              defaultMessage: "Your bank’s wire routing information is under review",
            })}
          </Text.Heading>
          <Text.Body size="sm">
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "We are reviewing your financial institution's wire information. Please allow 24 hours for this process to complete. If you have further questions, please contact <HelpCenterLink>Gemini customer support</HelpCenterLink>.",
              }),
              {
                HelpCenterLink: (v: ReactNode) => <HelpCenterLink>{v}</HelpCenterLink>,
              }
            )}
          </Text.Body>
        </Spacer>
      )}
      <Button.Group align={approved ? "center" : "right"}>
        <Button.Primary data-testid="closeEnableWireSuccess" onClick={onClose}>
          {approved
            ? intl.formatMessage({
                defaultMessage: "Done",
                description: "Done with flow and close modal button text",
              })
            : intl.formatMessage({
                defaultMessage: "Close",
                description: "Close modal button text",
              })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

export const RenderComponent = WireSuccess;
export default WireSuccess;
