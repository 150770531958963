import { Fragment, ReactNode } from "react";
import styled from "@emotion/styled";
import { ReactComponent as ErrorIcon } from "@gemini-ui/images/icons/red_error_bang.svg";
import { AccountError, IconWrapper } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/styles";

interface Props {
  errorCopy: ReactNode;
  linkText: string;
  handleClick: () => void;
  earnStyle?: boolean;
}

const StyledErrorIcon = styled(ErrorIcon)`
  height: 14px;
  width: 14px;
`;

const RetailAccountError = ({ errorCopy, linkText, handleClick, earnStyle }: Props) => {
  return (
    <AccountError>
      <IconWrapper earnStyle={earnStyle}>
        <StyledErrorIcon />
      </IconWrapper>
      <div>
        {errorCopy}{" "}
        {linkText && (
          <Fragment>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a role="button" tabIndex={0} onClick={handleClick}>
              {linkText}
            </a>
          </Fragment>
        )}
      </div>
    </AccountError>
  );
};

export default RetailAccountError;
