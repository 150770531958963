import { Fragment, ReactNode, useMemo } from "react";
import * as React from "react";
import { useTheme } from "@emotion/react";
import { IconRewardFilled } from "@hubble/icons";
import { isCurrency } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Money, MoneyComponentProps } from "@gemini-ui/components/Money";
import { PaymentMethodDisplay } from "@gemini-ui/components/PaymentMethodDisplay";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Badge, Card, List, ListItem, Spacer, Text, Tooltip } from "@gemini-ui/design-system";
import {
  monthlyScheduleItems,
  twiceMonthlyScheduleItems,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/RecurringBuyDropdowns";
import { ReviewRateTiers } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/screens/ReviewOrder/ReviewRateTiers";
import {
  getComponentCopy,
  getHoldPeriodTime,
  recurringOrderMessage,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import {
  Action,
  BuyingFrequency,
  CurrencyOrderQuote,
  RecurringFrequency,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { useAchDisabledFlow } from "@gemini-ui/pages/transfers/utils/useAchDisabledFlow";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import { shouldUseDecimalOverride } from "@gemini-ui/utils/shouldUseDecimalOverride";

const FeeTooltip = ({
  copy,
  overlay,
}: {
  copy: string;
  overlay: React.ComponentProps<typeof Tooltip.Info>["overlay"];
}) => {
  const { colorScheme } = useTheme();

  return (
    <Spacer ml={0.5}>
      <Tooltip.Info data-testid={testIds.buySell.promoInfoTooltip} overlay={overlay} placement="top">
        <Text.Body size="sm" color={colorScheme.content.secondary}>
          {copy}
        </Text.Body>
      </Tooltip.Info>
    </Spacer>
  );
};

const FeesAndTaxesTooltip = ({ copy }) => {
  const { intl } = useIntl();

  return (
    <FeeTooltip
      copy={copy}
      overlay={
        <React.Fragment>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "This includes a transaction fee to facilitate the trade and Value Added Tax (19%) withheld to be paid to tax regulators. Learn more about our <TextLink>fee schedules</TextLink>.",
            }),
            {
              TextLink: (v: React.ReactNode) => (
                <Text.Link target="_blank" href="https://www.gemini.com/fees/web-fee-schedule">
                  {v}
                </Text.Link>
              ),
            }
          )}
        </React.Fragment>
      }
    />
  );
};

const PromoInfoTooltip = ({ copy }) => {
  const { intl } = useIntl();

  const body = React.useMemo(() => {
    return intl.formatMessage({
      defaultMessage: "Enjoy fee free trading throughout the duration of this promo",
    });
  }, [intl]);

  return <FeeTooltip copy={copy} overlay={body} />;
};

export const BuySellOrderSummary = ({
  action,
  recurringFrequency,
  schedule,
  triggerHour,
  startOn,
  selectedPaymentMethod,
  isBuyAndEarn,
  isBuyAndStake,
  isBuyAndWrapFil,
  hasVATFee,
  disclaimer,
  quote,
  tradingFee,
  noTradingFees,
  rewardAmount,
}: {
  action: Action;
  recurringFrequency: RecurringFrequency;
  schedule: BuyingFrequency;
  triggerHour: string;
  startOn: Date;
  selectedPaymentMethod: TradePaymentMethodType;
  isBuyAndEarn: boolean;
  isBuyAndStake: boolean;
  isBuyAndWrapFil: boolean;
  hasVATFee: boolean;
  disclaimer: ReactNode;
  quote: CurrencyOrderQuote;
  tradingFee: string | number;
  noTradingFees: boolean;
  rewardAmount: string | null;
}) => {
  const { colorScheme } = useTheme();
  const { intl } = useIntl();

  const copy = getComponentCopy(intl);

  const instantOrder = schedule === BuyingFrequency.Once;
  const isBalance = selectedPaymentMethod === AccountType.BALANCE;
  const isCard = !isBalance && selectedPaymentMethod?.paymentMethodType === PaymentMethodType.DEBIT_CARD;
  const isBuy = action === Action.BUY;
  const isSell = action === Action.SELL;
  const isGUSDSellOrder = isCurrency.GUSD(quote.formData.from) && isSell;

  const paymentMethodHasWithdrawHold =
    !isBalance &&
    !isBuyAndStake &&
    [PaymentMethodType.DEBIT_CARD, PaymentMethodType.PAYPAL, PaymentMethodType.BANK].includes(
      selectedPaymentMethod?.paymentMethodType
    );

  const moneyProps: Pick<MoneyComponentProps, "decimalsOverride"> = useMemo(() => {
    if (instantOrder) {
      const toCurrency = action === Action.BUY ? quote.formData.to : quote.formData.from;
      const pair = `${toCurrency}${quote.formData.priceCurrency}`;
      const decimalsOverride = shouldUseDecimalOverride(toCurrency, quote.formData.priceCurrency, pair);
      if (decimalsOverride) {
        return { decimalsOverride };
      }
    }
    return {};
  }, [action, instantOrder, quote.formData.from, quote.formData.priceCurrency, quote.formData.to]);

  const maybeRenderRecurringOrderMessage = () => {
    const { twiceMonthly, monthly } = recurringFrequency;
    return (
      <Text.Body size="sm">
        {recurringOrderMessage({
          schedule,
          triggerHour,
          startOn,
          twiceMonthlyFrequency: twiceMonthlyScheduleItems(intl).find(v => v.value[0] === twiceMonthly?.[0]).label,
          monthlyFrequency: monthlyScheduleItems(intl).find(v => v.value[0] === monthly?.[0]).label,
          intl,
        })}
      </Text.Body>
    );
  };

  const getHoldPeriodCopy = () =>
    selectedPaymentMethod !== AccountType.BALANCE ? getHoldPeriodTime(selectedPaymentMethod, intl) : null;

  const scheduleString = () => {
    switch (schedule) {
      case BuyingFrequency.Daily:
        return intl.formatMessage({ defaultMessage: "daily" });
      case BuyingFrequency.Weekly:
        return intl.formatMessage({ defaultMessage: "weekly" });
      case BuyingFrequency.Biweekly:
        return intl.formatMessage({ defaultMessage: "twice monthly" });
      case BuyingFrequency.Monthly:
        return intl.formatMessage({ defaultMessage: "monthly" });
    }
    return null;
  };

  const achDisabledFlow = useAchDisabledFlow();

  return (
    <Card mt={3} padding="none" variant="filled">
      <List>
        <ListItem
          padding="sm"
          size="dense"
          right={
            <Text.Body size="sm" data-testid={testIds.buySell.reviewOrderType}>
              {isBuy ? (
                <Fragment>
                  {isBuyAndStake || isBuyAndEarn
                    ? intl.formatMessage(
                        defineMessage({
                          defaultMessage: "Buy and {isBuyAndStake, select, true {stake} other {earn}}",
                          description: "Specifies the type of buy, i.e. 'Buy and stake' or 'Buy and earn'",
                        }),
                        {
                          isBuyAndStake,
                        }
                      )
                    : intl.formatMessage(
                        defineMessage({
                          defaultMessage: "{instantOrder, select, true {Instant} other {Recurring}} buy",
                          description: "Specifies the type of buy, i.e. 'Instant buy' or 'Recurring buy'",
                        }),
                        {
                          instantOrder,
                        }
                      )}
                </Fragment>
              ) : (
                copy.INSTANT_SELL
              )}
            </Text.Body>
          }
        >
          <Text.Body size="sm" color={colorScheme.content.secondary}>
            {copy.TYPE}
          </Text.Body>
        </ListItem>

        {!instantOrder && (
          <ListItem
            alignItems="center"
            padding="sm"
            size="dense"
            right={
              <div data-testid={testIds.buySell.reviewFrequency}>
                <Text.Body size="sm">
                  <Money hideTrailingSign {...quote.totalSpend} />
                  {" " + scheduleString()}
                </Text.Body>
                {maybeRenderRecurringOrderMessage()}
              </div>
            }
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {copy.FREQUENCY}
            </Text.Body>
          </ListItem>
        )}

        <ListItem
          alignItems="center"
          padding="sm"
          size="dense"
          right={
            <PaymentMethodDisplay
              defaultFiat={quote.approxUsd.currency}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          }
        >
          <Text.Body size="sm" color={colorScheme.content.secondary}>
            {isSell ? copy.TO : copy.PAYMENT_METHOD}
          </Text.Body>
        </ListItem>

        {paymentMethodHasWithdrawHold && (
          <ListItem padding="sm" size="dense" right={<Text.Body size="sm">{getHoldPeriodCopy()}</Text.Body>}>
            <Tooltip.Info
              data-testid={testIds.buySell.availableWithdrawalTooltip}
              overlay={intl.formatMessage({
                defaultMessage:
                  "This hold period is required to prevent fraud and ensure that the transfer of funds is complete before initiating a withdrawal or transfer on this order. Hold times vary by payment method.",
              })}
              onVisibleChange={isVisible => isVisible && track(EVENTS.VIEWED_HOLD_PERIOD_TOOLTIP)}
            >
              <Text.Body size="sm" color={colorScheme.content.secondary}>
                {intl.formatMessage({
                  defaultMessage: "Available to withdraw",
                })}
              </Text.Body>
            </Tooltip.Info>
          </ListItem>
        )}

        {isBuyAndWrapFil && (
          <ListItem
            padding="sm"
            size="dense"
            right={
              <Text.Body size="sm">
                <Money hideTrailingSign {...quote.quantity} />
              </Text.Body>
            }
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {copy.WRAPPED_AMOUNT}
            </Text.Body>
          </ListItem>
        )}

        {(isBuyAndStake || isBuyAndEarn) && (
          <Fragment>
            <ListItem
              padding="sm"
              size="dense"
              right={
                <Text.Body
                  size="sm"
                  data-testid={isBuyAndStake ? testIds.buySell.stakingBalance : testIds.buySell.earnBalance}
                >
                  {intl.formatMessage({
                    defaultMessage: "Staking balance",
                    description: "Indicates whether the balance shown is for Staking or Earn",
                  })}
                </Text.Body>
              }
            >
              <Text.Body size="sm" color={colorScheme.content.secondary}>
                {copy.TO}
              </Text.Body>
            </ListItem>

            <ReviewRateTiers quote={quote} />
          </Fragment>
        )}

        {instantOrder && !isGUSDSellOrder && (
          <ListItem
            padding="sm"
            size="dense"
            right={
              <Text.Body size="sm">
                <Money
                  data-testid={testIds.buySell.reviewOrderMarketPrice}
                  hideTrailingSign
                  currency={isSell ? quote.formData.to : quote.formData.from}
                  value={quote.formData.price}
                  {...moneyProps}
                />
              </Text.Body>
            }
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "{currency} price",
                  description: "For example, 'ETH price'",
                }),
                {
                  currency: isSell ? quote.formData.from : quote.formData.to,
                }
              )}
            </Text.Body>
          </ListItem>
        )}

        {action === Action.BUY && (
          <ListItem
            padding="sm"
            size="dense"
            right={
              <Text.Body size="sm">
                <Money hideTrailingSign {...quote.approxUsd} />
              </Text.Body>
            }
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {copy.SUBTOTAL}
            </Text.Body>
          </ListItem>
        )}

        <ListItem
          padding="sm"
          size="dense"
          right={
            Number(tradingFee) > 0 ? (
              <Text.Body size="sm" data-testid={testIds.buySell.orderSellTransactionFee}>
                <Money
                  hideTrailingSign
                  value={tradingFee}
                  currency={quote.fee.currency}
                  data-testid={testIds.buySell.orderTransactionFeeAmountSummary}
                />
              </Text.Body>
            ) : (
              <Badge data-testid={testIds.buySell.orderSellTransactionFeeBadge} status="info">
                {intl.formatMessage({ defaultMessage: "No fee" })}
              </Badge>
            )
          }
          data-testid={testIds.buySell.orderTransactionFeeSummarySection}
        >
          {hasVATFee ? (
            <FeesAndTaxesTooltip copy={copy.FEES_TAXES} />
          ) : (
            <Fragment>
              {noTradingFees ? (
                <PromoInfoTooltip copy={copy.FEE} />
              ) : (
                <Text.Body size="sm" color={colorScheme.content.secondary}>
                  {copy.FEE}
                </Text.Body>
              )}
            </Fragment>
          )}
        </ListItem>

        {rewardAmount && (
          <ListItem
            padding="sm"
            size="dense"
            right={
              <Badge data-testid={testIds.buySell.orderReward} status="reward" icon={<IconRewardFilled />}>
                {rewardAmount}
              </Badge>
            }
            data-testid={testIds.buySell.orderRewardsSection}
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {copy.REWARDS}
            </Text.Body>
          </ListItem>
        )}

        {quote.depositFee?.value !== "0" && (
          <ListItem
            padding="sm"
            size="dense"
            right={
              <Text.Body size="sm">
                <Money hideTrailingSign {...quote.depositFee} />
              </Text.Body>
            }
          >
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {achDisabledFlow ? copy.WAIVED : isCard ? copy.CARD_PROCESSING_FEE : copy.PROCESSING_FEE}
            </Text.Body>
          </ListItem>
        )}

        <ListItem
          padding="sm"
          size="dense"
          right={
            <Text.Body size="sm" bold>
              {isSell ? (
                <Money hideTrailingSign {...quote.proceeds} data-testid={testIds.buySell.orderSellProceeds} />
              ) : (
                <Money hideTrailingSign {...quote.totalSpend} data-testid={testIds.buySell.orderTotalSummery} />
              )}
            </Text.Body>
          }
          data-testid={testIds.buySell.orderTotalSummarySection}
        >
          <Text.Body size="sm" bold>
            {isSell ? copy.TOTAL_DUE_TO_YOU : copy.TOTAL}
          </Text.Body>
        </ListItem>

        <ListItem padding="sm" size="dense">
          {disclaimer}
        </ListItem>
      </List>
    </Card>
  );
};
