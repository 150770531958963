import { cloneElement, useEffect, useState } from "react";
import { kebabCase } from "lodash";
import { MENU_SIZE_TO_TEXT_SIZE_MAP, TabNavProps, TabProps } from "@gemini-ui/design-system/TabNavigation/constants";
import {
  OuterContainer,
  SectionHeader,
  SectionTitleLink,
  TabContainer,
} from "@gemini-ui/design-system/TabNavigation/styles";
import { Text } from "@gemini-ui/design-system/Text";

const TabNavigation = ({
  tabs,
  children,
  defaultTab = tabs?.[0],
  size = "md",
  onChangeTab,
  ...restProps
}: TabNavProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  // Allow the parent to update the `defaultTab` after mounting
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setActiveTab(defaultTab);
    }
    return () => {
      isMounted = false;
    };
  }, [defaultTab]);

  return (
    <OuterContainer {...restProps}>
      <TabNavigation.NavContainer data-testid="TabNav-SectionHeader">
        {tabs.map((tab: string) => (
          <TabNavigation.Tab
            key={tab}
            id={kebabCase(tab)}
            data-testid={`TabNav-${kebabCase(tab)}`}
            active={activeTab === tab}
            href={`#${kebabCase(tab)}`}
            onClick={e => {
              e.preventDefault();
              setActiveTab(tab);
              onChangeTab?.(tab);
            }}
            size={size}
          >
            {tab}
          </TabNavigation.Tab>
        ))}
      </TabNavigation.NavContainer>
      {/* Passing a unique key to each child because otherwise React will render the same instanse of the component
          when multiple tabs render the same component but we need a new instance of the component for each new tab.
      */}
      <TabContainer>{cloneElement(children?.[tabs.indexOf(activeTab)], { key: activeTab })}</TabContainer>
    </OuterContainer>
  );
};

TabNavigation.Tab = ({ size, children, ...props }: TabProps) => (
  <SectionTitleLink size={size} {...props}>
    <Text.Body size={MENU_SIZE_TO_TEXT_SIZE_MAP[size]} bold color="inherit">
      {children}
    </Text.Body>
  </SectionTitleLink>
);
TabNavigation.NavContainer = SectionHeader;

export default TabNavigation;
