import { useImperativeHandle, useRef } from "react";
import * as React from "react";
import { uniqueId } from "lodash";
import MaskedInput from "react-text-mask";
import { Flex } from "@gemini-ui/design-system/Flex";
import { InputProps } from "@gemini-ui/design-system/forms/Input/constants";
import {
  InputWrapper,
  LeftElementWrapper,
  RightElementWrapper,
  StyledInput,
} from "@gemini-ui/design-system/forms/Input/styles";
import { Label } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { cssShorthandPropsFilter, Spacer } from "@gemini-ui/design-system/primitives/Spacer";

const INPUT_ID_PREFIX = "input-";

const onWheelDefault = (event: React.WheelEvent<HTMLInputElement>) => {
  if (event.currentTarget.type === "number") {
    event.currentTarget.blur();
  }
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, forwardRef) => {
  const {
    className,
    ["data-testid"]: dataTestId,
    disabled,
    error,
    hideLabel,
    id,
    label,
    leftElement,
    leftOuterElement,
    mask,
    message,
    name,
    readOnly,
    rightElement,
    rightOuterElement,
    inputSize = "md",
    value,
    numeric,
    onWheel = onWheelDefault,
    ...htmlInputProps
  } = props;

  const inputRef = useRef(null);
  useImperativeHandle(forwardRef, () => (mask ? inputRef.current.inputElement : inputRef.current));

  const inputId = React.useMemo(() => (Boolean(id) ? id : uniqueId(INPUT_ID_PREFIX)), [id]);
  const hasMessage = Boolean(message) || Boolean(error);

  return (
    <Spacer data-testid={`${dataTestId}-wrapper`} className={className} {...cssShorthandPropsFilter(props)}>
      <Label disabled={disabled} htmlFor={inputId as string} mb={1} visiblyHidden={hideLabel}>
        {label}
      </Label>
      <Flex>
        {leftOuterElement}
        <InputWrapper hasError={Boolean(error)} inputSize={inputSize} isDisabled={disabled} isReadOnly={readOnly}>
          {Boolean(leftElement) && <LeftElementWrapper>{leftElement}</LeftElementWrapper>}
          {mask ? (
            <MaskedInput
              aria-describedby={hasMessage ? `${inputId}-message` : null}
              autoComplete="off"
              guide={false}
              ref={inputRef}
              mask={mask}
              {...props}
              render={(ref, inputProps) => <StyledInput {...inputProps} ref={ref} />}
            />
          ) : (
            <StyledInput
              aria-describedby={hasMessage ? `${inputId}-message` : null}
              disabled={disabled}
              id={inputId}
              inputSize={inputSize}
              name={name}
              key={name}
              readOnly={readOnly}
              ref={inputRef}
              value={value}
              data-testid={dataTestId}
              numeric={numeric}
              onWheel={onWheel}
              {...htmlInputProps}
            />
          )}

          {Boolean(rightElement) && <RightElementWrapper>{rightElement}</RightElementWrapper>}
        </InputWrapper>
        {rightOuterElement}
      </Flex>
      {hasMessage && (
        <Message data-testid={`${dataTestId}-message`} disabled={disabled} error={error} message={message} />
      )}
    </Spacer>
  );
});
