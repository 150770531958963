/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationTouchId = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M111.374 176.999C102.243 169.205 79.0646 150.36 71.5324 156.118C69.3803 157.776 69.0313 160.975 69.0313 162.632C69.0022 172.578 77.3487 177.348 85.8115 190.58C88.5743 194.884 93.7218 204.714 95.8739 214.224C104.104 220.04 120.797 225.595 140.078 225.595C157.76 225.595 175.529 219.604 182.305 214.224L178.67 174.876C177.07 167.286 129.347 136.953 117.569 143.7C102.941 152.076 93.6055 170.426 93.6055 170.426"
      fill={black}
    />
    <path
      d="M140.051 225.856C131.094 225.856 121.991 224.634 113.732 222.337C106.432 220.301 100.034 217.509 95.7012 214.427C95.6431 214.398 95.614 214.339 95.5849 214.252C93.4328 204.597 88.0818 194.622 85.5807 190.696C82.6144 186.072 79.6771 182.495 77.0888 179.354C72.2322 173.45 68.7133 169.175 68.7424 162.603C68.7424 160.829 69.0913 157.601 71.3597 155.885C74.7041 153.326 81.3057 155.129 90.99 161.236C92.7349 162.341 94.567 163.562 96.4864 164.929C100.412 158.822 107.799 148.905 117.425 143.409C122.311 140.617 132.519 144.136 140.226 147.597C157.238 155.216 177.886 169.699 178.963 174.788V174.817L182.598 214.165C182.598 214.252 182.569 214.339 182.481 214.398C175.356 220.039 157.558 225.856 140.051 225.856ZM96.1084 214.019C107.189 221.784 126.15 225.274 140.051 225.274C153.341 225.274 173.088 220.999 181.987 214.078L178.352 174.904C177.363 170.368 158.198 156.292 139.935 148.12C129.204 143.322 121.293 141.838 117.658 143.903C108.119 149.341 100.791 159.171 96.9227 165.249C101.372 168.419 106.258 172.287 111.522 176.766C111.638 176.853 111.667 177.056 111.551 177.173C111.464 177.289 111.26 177.318 111.144 177.202C105.851 172.694 100.994 168.855 96.6028 165.715C94.7997 168.535 93.84 170.455 93.8109 170.513C93.7527 170.658 93.5783 170.717 93.4328 170.629C93.2874 170.571 93.2293 170.397 93.3165 170.251C93.3456 170.193 94.3344 168.245 96.1665 165.395C83.894 156.67 75.4021 153.471 71.6796 156.321C69.6148 157.892 69.2949 160.945 69.2949 162.603C69.2658 168.972 72.7266 173.159 77.5251 179.005C80.1134 182.175 83.0797 185.752 86.0461 190.405C88.7797 194.622 93.9272 204.335 96.1084 214.019Z"
      fill={black}
    />
    <path
      d="M172.245 151.552C175.996 150.505 178.468 150.709 180.998 152.163C188.88 156.699 186.96 175.515 182.889 185.839C178.963 195.814 170.994 207.215 165.236 205.324C161.048 203.957 161.107 197.21 161.746 193.633"
      fill={black}
    />
    <path
      d="M166.574 205.819C166.08 205.819 165.614 205.731 165.178 205.586C160.874 204.161 160.845 197.385 161.485 193.604C161.514 193.459 161.659 193.342 161.805 193.372C161.95 193.401 162.066 193.546 162.037 193.691C161.397 197.298 161.397 203.754 165.353 205.062C168.697 206.168 172.565 202.271 174.572 199.857C177.596 196.222 180.621 190.929 182.685 185.752C184.837 180.256 186.204 173.014 186.204 166.849C186.204 162.603 185.535 155.071 180.911 152.424C178.41 150.97 176.026 150.825 172.361 151.843C172.216 151.872 172.07 151.785 172.012 151.639C171.983 151.494 172.07 151.348 172.216 151.29C175.967 150.243 178.585 150.418 181.202 151.93C189.578 156.758 186.989 176.33 183.209 185.927C181.144 191.161 178.09 196.512 175.008 200.206C171.925 203.899 169.046 205.819 166.574 205.819Z"
      fill={black}
    />
    <path
      d="M154.272 139.92C158.14 136.837 160.757 136.401 164.16 137.36C174.63 140.327 174.513 159.782 169.337 176.883C164.102 194.157 154.33 208.058 147.264 204.859C140.197 201.66 143.134 190.318 144.53 186.858"
      fill={black}
    />
    <path
      d="M149.386 205.615C148.601 205.615 147.874 205.441 147.147 205.121C139.992 201.893 142.755 190.493 144.268 186.77C144.326 186.625 144.5 186.567 144.646 186.625C144.791 186.683 144.849 186.858 144.791 187.003C143.308 190.609 140.603 201.573 147.379 204.627C148.775 205.266 150.375 205.208 152.061 204.423C158.052 201.689 164.887 190.609 169.074 176.824C173.32 162.836 173.611 149.4 169.889 142.566C168.493 140.007 166.544 138.349 164.101 137.651C160.582 136.662 158.052 137.302 154.475 140.152C154.359 140.239 154.184 140.239 154.068 140.094C153.981 139.978 153.981 139.803 154.126 139.687C157.878 136.691 160.524 136.023 164.276 137.069C166.864 137.797 168.929 139.541 170.412 142.246C174.222 149.197 173.931 162.836 169.656 176.941C165.439 190.871 158.489 202.096 152.352 204.888C151.305 205.383 150.317 205.615 149.386 205.615Z"
      fill={black}
    />
    <path
      d="M124.52 150.999C125.422 78.1784 125.654 57.2686 111.317 57.2686C97.125 57.2686 98.3464 89.3458 99.8587 122.325C100.557 138.029 98.8408 159.898 97.125 164.319"
      fill={black}
    />
    <path
      d="M97.1245 164.58C97.0954 164.58 97.0663 164.58 97.0372 164.551C96.8918 164.493 96.8337 164.318 96.8627 164.173C98.5786 159.723 100.265 137.796 99.5674 122.295C98.375 95.6271 97.1245 68.0575 105.209 59.5947C106.896 57.8498 108.961 56.9482 111.316 56.9482C114.254 56.9482 116.551 57.8207 118.325 59.6238C125.828 67.2432 125.537 91.7301 124.781 150.97C124.781 151.115 124.665 151.261 124.49 151.261C124.345 151.261 124.2 151.144 124.2 150.97C124.927 93.6495 125.247 67.4759 117.889 60.0309C116.202 58.3151 114.079 57.5008 111.287 57.5008C109.048 57.5008 107.158 58.3151 105.587 59.9728C97.677 68.2611 98.8985 95.7143 100.12 122.237C100.818 137.796 99.102 159.84 97.3571 164.347C97.3571 164.493 97.2408 164.58 97.1245 164.58Z"
      fill={black}
    />
    <path
      d="M130.423 148.178C134.291 161.149 111.113 193.284 128.62 202.823C139.089 208.523 150.053 177.842 153.194 160.742C156.364 143.467 153.194 125.989 140.892 125.931C132.255 125.902 128.155 133.434 124.607 143.002"
      fill={black}
    />
    <path
      d="M131.239 203.783C130.308 203.783 129.378 203.55 128.505 203.056C117.512 197.094 122.282 182.262 126.47 169.175C129.116 160.945 131.617 153.151 130.163 148.266C130.105 148.12 130.192 147.975 130.338 147.917C130.483 147.858 130.657 147.946 130.687 148.12C132.199 153.18 129.669 161.062 126.993 169.379C124.87 176.01 122.66 182.902 122.311 188.864C121.933 195.553 124.027 200.031 128.738 202.59C130.308 203.434 131.966 203.463 133.682 202.649C141.912 198.723 150.142 175.777 152.905 160.713C155.319 147.568 153.894 135.877 149.183 130.177C147.031 127.588 144.239 126.251 140.894 126.221C140.865 126.221 140.836 126.221 140.836 126.221C132.78 126.221 128.622 132.968 124.899 143.089C124.841 143.234 124.696 143.322 124.55 143.263C124.405 143.205 124.318 143.06 124.376 142.914C128.185 132.561 132.461 125.669 140.836 125.669C140.865 125.669 140.894 125.669 140.894 125.669C144.413 125.698 147.321 127.094 149.59 129.828C154.417 135.644 155.9 147.509 153.458 160.8C150.782 175.399 142.697 198.955 133.915 203.143C133.013 203.579 132.112 203.783 131.239 203.783Z"
      fill={black}
    />
    <path
      d="M182.307 214.747C182.017 214.747 181.784 214.514 181.755 214.224L178.12 174.876C178.091 174.556 178.323 174.294 178.614 174.265C178.934 174.236 179.196 174.469 179.225 174.76L182.86 214.107C182.889 214.427 182.656 214.689 182.366 214.718C182.336 214.747 182.307 214.747 182.307 214.747Z"
      fill={white}
    />
    <path
      d="M172.245 151.552C175.996 150.505 178.468 150.709 180.998 152.163C188.88 156.699 186.96 175.515 182.889 185.839C178.963 195.814 170.994 207.215 165.236 205.324C161.048 203.957 161.107 197.21 161.746 193.633"
      fill={black}
    />
    <path
      d="M166.575 206.109C166.051 206.109 165.557 206.022 165.062 205.876C163.027 205.207 161.66 203.317 161.136 200.438C160.613 197.588 160.991 194.68 161.195 193.575C161.253 193.255 161.544 193.051 161.834 193.109C162.154 193.167 162.358 193.458 162.3 193.749C161.689 197.268 161.66 203.55 165.411 204.8C170.384 206.429 178.207 196.221 182.366 185.635C186.554 174.991 188.008 156.873 180.708 152.656C178.266 151.261 175.939 151.086 172.391 152.104C172.1 152.191 171.78 152.017 171.693 151.726C171.606 151.435 171.78 151.115 172.071 151.028C175.91 149.952 178.586 150.155 181.261 151.697C189.753 156.612 187.223 176.358 183.384 186.042C179.981 194.825 172.74 206.08 166.575 206.109Z"
      fill={white}
    />
    <path
      d="M154.272 139.919C158.14 136.836 160.757 136.4 164.16 137.359C174.63 140.326 174.513 159.781 169.337 176.882C164.102 194.156 154.33 208.057 147.264 204.858C140.197 201.659 143.134 190.317 144.53 186.857"
      fill={black}
    />
    <path
      d="M149.385 205.876C148.571 205.876 147.786 205.702 147.03 205.353C139.672 202.037 142.464 190.434 144.034 186.624C144.151 186.333 144.471 186.188 144.761 186.304C145.052 186.42 145.198 186.74 145.081 187.031C143.627 190.579 140.952 201.339 147.524 204.306C148.862 204.917 150.345 204.829 151.974 204.102C157.906 201.398 164.682 190.376 168.812 176.678C173.029 162.777 173.349 149.399 169.655 142.623C168.289 140.122 166.398 138.523 164.014 137.854C161.076 137.01 158.604 137.156 154.62 140.326C154.387 140.529 154.009 140.471 153.835 140.239C153.631 140.006 153.69 139.628 153.922 139.453C157.703 136.429 160.524 135.731 164.304 136.778C166.98 137.534 169.103 139.308 170.615 142.1C174.454 149.108 174.163 162.835 169.888 177.027C165.642 191.044 158.633 202.328 152.439 205.149C151.363 205.644 150.374 205.876 149.385 205.876Z"
      fill={white}
    />
    <path
      d="M130.423 148.179C134.291 161.149 111.113 193.285 128.62 202.823C139.089 208.523 150.053 177.842 153.194 160.742C156.364 143.467 154.328 127.763 140.892 125.262C132.4 123.692 125.654 129.683 123.24 137.535"
      fill={black}
    />
    <path
      d="M131.237 204.074C130.248 204.074 129.288 203.812 128.357 203.318C117.161 197.24 121.959 182.292 126.205 169.088C128.823 160.887 131.324 153.152 129.899 148.324C129.812 148.033 129.986 147.713 130.277 147.626C130.568 147.539 130.888 147.713 130.975 148.004C132.516 153.152 129.986 161.062 127.281 169.437C122.977 182.844 118.528 196.687 128.91 202.329C130.422 203.143 131.935 203.172 133.592 202.387C141.735 198.49 149.907 175.632 152.67 160.655C154.357 151.436 154.386 143.467 152.757 137.622C150.867 130.904 146.854 126.949 140.834 125.815C136.995 125.088 133.301 125.96 130.161 128.316C127.252 130.497 125.013 133.812 123.821 137.709C123.733 138 123.414 138.174 123.123 138.087C122.832 138 122.657 137.68 122.745 137.389C123.995 133.289 126.409 129.741 129.492 127.443C132.894 124.913 136.878 123.982 141.037 124.739C147.522 125.931 151.856 130.177 153.833 137.331C155.52 143.351 155.491 151.465 153.775 160.887C151.07 175.545 142.957 199.188 134.087 203.434C133.098 203.841 132.167 204.074 131.237 204.074Z"
      fill={white}
    />
    <path
      d="M124.52 150.999C125.422 78.1784 125.654 57.2686 111.317 57.2686C97.125 57.2686 98.3464 89.3458 99.8587 122.325C100.557 138.029 98.8408 159.898 97.125 164.319"
      fill={black}
    />
    <path
      d="M97.1261 164.842C97.068 164.842 96.9807 164.842 96.9226 164.813C96.6317 164.696 96.4863 164.377 96.6027 164.086C98.3185 159.665 100.005 137.796 99.3073 122.324C98.0858 95.598 96.8353 67.9703 105.007 59.4202C106.752 57.6171 108.875 56.6865 111.318 56.6865C114.343 56.6865 116.698 57.5881 118.53 59.4493C126.121 67.1269 125.83 91.672 125.074 150.999C125.074 151.319 124.812 151.551 124.521 151.551C124.201 151.551 123.969 151.29 123.969 150.97C124.696 93.7077 125.016 67.5922 117.745 60.2345C116.117 58.5768 114.081 57.7916 111.347 57.7916C109.166 57.7916 107.363 58.5768 105.851 60.1763C98.0277 68.3774 99.3073 96.9939 100.441 122.237C101.023 135.382 99.7435 159.113 97.6787 164.464C97.5624 164.726 97.3588 164.842 97.1261 164.842Z"
      fill={white}
    />
    <path
      d="M95.8738 214.194C90.1447 187.643 69.0312 176.999 69.0312 162.603C69.0312 151.407 82.4089 148.062 111.374 176.97C121.989 187.585 132.226 200.584 137.257 211.432"
      fill={black}
    />
    <path
      d="M95.8756 214.746C95.6138 214.746 95.3812 214.572 95.323 214.31C92.2694 200.205 84.9989 190.812 78.6009 182.524C73.1626 175.515 68.4805 169.466 68.4805 162.602C68.4805 158.618 70.109 155.768 73.0172 154.547C80.5203 151.435 94.625 159.491 111.783 176.591C123.358 188.136 133.071 201.078 137.782 211.227C137.928 211.518 137.782 211.838 137.521 211.983C137.23 212.1 136.91 211.983 136.765 211.722C132.112 201.688 122.456 188.863 110.998 177.405C101.895 168.303 93.7817 161.846 86.9183 158.24C81.2183 155.216 76.5652 154.314 73.4535 155.594C70.9524 156.612 69.5856 159.113 69.5856 162.602C69.5856 169.088 74.1514 174.991 79.4734 181.855C85.6387 189.823 93.3163 199.74 96.4281 214.077C96.4863 214.368 96.3118 214.688 95.9919 214.746C95.9337 214.746 95.9046 214.746 95.8756 214.746Z"
      fill={white}
    />
    <path
      d="M115.128 87.0772C108.236 87.0772 105.008 83.4129 105.008 75.5899C105.008 68.494 108.585 64.7715 112.104 64.7715C113.761 64.7715 115.245 65.5858 116.321 67.098C117.484 68.6975 118.095 71.0241 118.095 73.7869C118.095 78.6144 116.262 80.3303 115.186 80.9119C114.023 81.5517 112.627 81.4645 111.435 80.7083C109.748 79.6032 108.876 77.4512 109.079 74.9501C109.283 72.6236 110.039 70.9659 111.173 70.4715C111.784 70.2098 112.424 70.2679 112.976 70.6751C113.965 71.4021 114.401 73.0016 114.14 74.921C114.11 75.2409 113.82 75.4445 113.5 75.4154C113.18 75.3864 112.976 75.0955 113.005 74.7756C113.238 73.0016 112.773 71.9256 112.307 71.5766C112.075 71.4021 111.871 71.3731 111.638 71.4894C111.057 71.7511 110.388 72.8271 110.184 75.0374C109.981 77.5093 110.999 79.0797 112.017 79.7486C112.86 80.3012 113.791 80.3594 114.605 79.9231C116.088 79.1088 116.931 76.8986 116.931 73.7869C116.931 71.2567 116.379 69.1628 115.361 67.7669C114.488 66.5746 113.325 65.9057 112.046 65.9057C108.178 65.9057 106.084 70.9077 106.084 75.619C106.084 84.8089 110.882 85.9721 115.07 85.9721C115.39 85.9721 115.623 86.2339 115.623 86.5247C115.623 86.8155 115.448 87.0772 115.128 87.0772Z"
      fill={white}
    />
    <path
      d="M111.549 44.6184C111.229 44.6184 110.996 44.3567 110.996 44.0368C111.083 36.8535 110.996 21.1784 110.996 21.033C110.996 20.7131 111.258 20.4805 111.549 20.4805C111.869 20.4805 112.101 20.7422 112.101 21.033C112.101 21.1784 112.188 36.8535 112.101 44.0658C112.13 44.3566 111.869 44.6184 111.549 44.6184Z"
      fill={contentPrimary}
    />
    <path
      d="M142.899 76.4914C140.078 76.4914 137.315 76.4914 135.338 76.4623C135.018 76.4623 134.785 76.2006 134.785 75.8807C134.785 75.5608 135.047 75.3281 135.338 75.3281C141.212 75.4154 154.183 75.3281 154.328 75.3281C154.648 75.3281 154.881 75.5899 154.881 75.8807C154.881 76.2006 154.619 76.4332 154.328 76.4332C154.27 76.4623 148.483 76.4914 142.899 76.4914Z"
      fill={contentPrimary}
    />
    <path
      d="M76.5943 76.4914C73.7734 76.4914 71.0106 76.4914 69.033 76.4623C68.7131 76.4623 68.4805 76.2006 68.4805 75.8807C68.4805 75.5608 68.7422 75.3281 69.0621 75.3281C74.9366 75.4154 87.9071 75.3281 88.0525 75.3281C88.3724 75.3281 88.6051 75.5899 88.6051 75.8807C88.6051 76.2006 88.3434 76.4332 88.0525 76.4332C87.9362 76.4623 82.178 76.4914 76.5943 76.4914Z"
      fill={contentPrimary}
    />
    <path
      d="M94.5938 56.6291C94.4484 56.6291 94.3321 56.5709 94.2157 56.4837C94.0994 56.3964 83.63 46.6831 79.5003 42.4662C79.2968 42.2336 79.2968 41.8846 79.5003 41.681C79.733 41.4774 80.082 41.4774 80.2855 41.681C84.357 45.8979 94.8555 55.553 94.9428 55.6694C95.1754 55.8729 95.1754 56.2219 94.9719 56.4546C94.8846 56.5709 94.7392 56.6291 94.5938 56.6291Z"
      fill={contentPrimary}
    />
    <path
      d="M128.068 56.6574C127.923 56.6574 127.777 56.5992 127.661 56.4829C127.457 56.2502 127.457 55.9012 127.69 55.6977C127.806 55.6104 138.159 45.8099 142.376 41.7093C142.609 41.5057 142.958 41.5057 143.162 41.7093C143.365 41.9129 143.365 42.291 143.162 42.4945C138.945 46.5951 128.592 56.3956 128.475 56.512C128.33 56.5992 128.184 56.6574 128.068 56.6574Z"
      fill={contentPrimary}
    />
    <path
      d="M122.455 46.8867C122.368 46.8867 122.281 46.8576 122.223 46.8286C121.932 46.6831 121.816 46.3632 121.961 46.0724C121.99 46.0143 125.189 39.2964 127.865 34.0616C128.01 33.7999 128.359 33.6836 128.621 33.829C128.883 33.9744 128.999 34.3234 128.853 34.5851C126.178 39.7908 122.979 46.4796 122.95 46.5668C122.863 46.7704 122.659 46.8867 122.455 46.8867Z"
      fill={contentPrimary}
    />
    <path
      d="M133.564 65.411C133.332 65.411 133.099 65.2656 133.041 65.033C132.954 64.7421 133.099 64.4222 133.39 64.335C133.477 64.3059 140.747 61.9212 146.418 60.2345C146.709 60.1472 147.029 60.3217 147.116 60.6125C147.204 60.9033 147.029 61.2232 146.738 61.3105C141.126 62.9681 133.826 65.3819 133.768 65.3819C133.681 65.3819 133.622 65.411 133.564 65.411Z"
      fill={contentPrimary}
    />
    <path
      d="M90.145 65.2946C90.0868 65.2946 90.0578 65.2946 89.9996 65.2655C89.9123 65.2364 82.5256 63.2007 76.9128 61.3685C76.6219 61.2813 76.4475 60.9614 76.5638 60.6706C76.651 60.3798 76.9709 60.2053 77.2617 60.3216C82.8455 62.1247 90.2322 64.1604 90.2904 64.1895C90.5812 64.2767 90.7557 64.5675 90.6685 64.8874C90.6103 65.1201 90.3777 65.2946 90.145 65.2946Z"
      fill={contentPrimary}
    />
    <path
      d="M101.663 46.8858C101.459 46.8858 101.285 46.7695 101.168 46.595C101.139 46.5369 97.475 39.9644 94.7704 34.7296C94.625 34.4679 94.7413 34.1189 95.003 33.9735C95.2648 33.8281 95.6137 33.9444 95.7592 34.2062C98.4638 39.4118 102.099 45.9552 102.157 46.0425C102.303 46.3042 102.215 46.6532 101.954 46.7986C101.866 46.8568 101.75 46.8858 101.663 46.8858Z"
      fill={contentPrimary}
    />
  </Fragment>
));
IllustrationTouchId.displayName = "IllustrationTouchId";
