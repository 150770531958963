import { useTheme } from "@emotion/react";
import { CurrencyShortNameFiat, CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { Flex, Text } from "@gemini-ui/design-system";
import { ValidatorAmountHeader } from "@gemini-ui/pages/Earn/components/ReviewTransactionHeader/ValidatorAmountHeader";
import { PrimaryCurrencyDisplay } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";

interface ReviewTransactionHeaderProps {
  amount?: MoneyProps<CurrencyShortNameSupportedCryptos>;
  amountNotional?: MoneyProps<CurrencyShortNameFiat>;
  validatorCount?: number;
  userPreferredCurrencyDisplay?: PrimaryCurrencyDisplay;
  unstakeSource?: UnstakeSourceType;
}

export const ReviewTransactionHeader = ({
  userPreferredCurrencyDisplay,
  amount,
  amountNotional,
  validatorCount,
  unstakeSource,
}: ReviewTransactionHeaderProps) => {
  const { colorScheme } = useTheme();

  const isValidatorTransaction = unstakeSource === UnstakeSourceType.validatorBalance;

  const isCryptoPrimaryDisplay = userPreferredCurrencyDisplay === PrimaryCurrencyDisplay.CRYPTO;

  const [transferPrimaryCurrency, transferSecondaryCurrency] = isCryptoPrimaryDisplay
    ? [amount, amountNotional]
    : [amountNotional, amount];

  if (isValidatorTransaction) {
    return <ValidatorAmountHeader validatorCount={validatorCount} cryptoValue={amount} />;
  }

  return (
    <Flex flexDirection="column" align="center">
      <Text.Display data-testid={testIds.deposit.reviewDeposit.titlePrimary} size="xs" numeric>
        <Money
          data-testid={testIds.deposit.reviewDeposit.titlePrimary}
          hideTrailingSign={!isCryptoPrimaryDisplay}
          {...transferPrimaryCurrency}
        />
      </Text.Display>
      <Text.Body size="sm" color={colorScheme.content.secondary} bold>
        <Money
          data-testid={testIds.deposit.reviewDeposit.titleSecondary}
          hideTrailingSign={isCryptoPrimaryDisplay}
          {...transferSecondaryCurrency}
        />
      </Text.Body>
    </Flex>
  );
};
