import { forwardRef } from "react";
import { HubbleProvider } from "@hubble/web";
import { CardDefaultElement, CardPolymorphic } from "@gemini-ui/design-system/cards/Card/constants";
import { CardWrapper } from "@gemini-ui/design-system/cards/Card/styles";

export const Card = forwardRef(function ForwardedCard(
  {
    ["aria-checked"]: ariaChecked,
    as = CardDefaultElement,
    children,
    customFilledBgColor,
    isRadio,
    padding = "md",
    variant = "outlined",
    ...props
  },
  ref
) {
  // https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
  // Please be sure to use as="button" when using isRadio!
  const ROLE_RADIO = isRadio && { role: "radio", "aria-checked": ariaChecked, type: "button" };

  if (variant !== "filled" && customFilledBgColor) {
    throw new Error("The customFilledBgColor prop can only be used with the 'filled' variant.");
  }

  if (variant === "inverse") {
    return (
      <CardWrapper padding={padding} variant="inverse" {...props}>
        <HubbleProvider scheme="dark">{children}</HubbleProvider>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper
      as={as}
      customFilledBgColor={customFilledBgColor}
      padding={padding}
      ref={ref}
      variant={variant}
      {...ROLE_RADIO}
      {...props}
    >
      {children}
    </CardWrapper>
  );
}) as CardPolymorphic;
