import { Card, Flex, ListItem, SkeletonLoader, Spacing } from "@gemini-ui/design-system";
import { CardProps } from "@gemini-ui/design-system/cards/Card/constants";

export const LoadingCard = (props: CardProps) => {
  return (
    <Card padding="none" {...props}>
      <ListItem padding="sm" hasDivider={false} alignItems="center">
        <Flex align="center" gap={Spacing.scale[1]}>
          <SkeletonLoader radiusSize="full" height={Spacing.scale[4]} width={Spacing.scale[4]} />
          <Flex flexDirection="column" gap={Spacing.scale[1]}>
            <SkeletonLoader height={Spacing.scale[2]} width="250px" />
            <SkeletonLoader height={Spacing.scale[1.5]} width="150px" />
          </Flex>
        </Flex>
      </ListItem>
    </Card>
  );
};
