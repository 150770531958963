import { Fragment, ReactNode, useState } from "react";
import { IconChevronDownSmall, IconChevronUpSmall } from "@hubble/icons";
import { Flex, HubbleMenu } from "@gemini-ui/design-system";

export interface HubbleMenuAccordionProps {
  content?: ReactNode;
  label?: string;
  leftElement?: ReactNode;
  openIcon?: ReactNode;
  closeIcon?: ReactNode;
}

export const HubbleMenuAccordion = ({ content, label, leftElement, openIcon, closeIcon }: HubbleMenuAccordionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const openElement = openIcon || <IconChevronDownSmall data-testid="default-accordion-open-icon" />;
  const closeElement = closeIcon || <IconChevronUpSmall data-testid="default-accordion-close-icon" />;

  const toggleIcon = isCollapsed ? openElement : closeElement;

  const toggleCollapsed = e => {
    e.preventDefault();
    setIsCollapsed(d => !d);
  };

  return (
    <Fragment>
      <HubbleMenu.Item
        leftElement={leftElement}
        rightElement={toggleIcon}
        label={label}
        style={{ borderRadius: "999px", padding: "10px 16px" }}
        onClick={e => toggleCollapsed(e)}
      />
      <Flex flexDirection="column" gap="4px">
        {!isCollapsed && content}
      </Flex>
    </Fragment>
  );
};
