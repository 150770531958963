import { Flex } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { OptionIconWrapper, OptionLabel } from "@gemini-ui/design-system/forms/Select/styles";

export const SourceDropdownItem = ({ label, icon }: SelectOptionProps) => (
  <OptionLabel>
    {icon && <OptionIconWrapper className="transfer-type-icon">{icon}</OptionIconWrapper>}
    <Flex justifyContent="center" flexDirection="column">
      {label}
    </Flex>
  </OptionLabel>
);
