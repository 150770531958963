import { usePageData } from "@gemini-ui/contexts";
import { Flex, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import { useIntl } from "@gemini-ui/utils/intl";

function ComplyAdvantageMismatch() {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();

  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);

  return (
    <Flex flexDirection="column" data-testid={testIds.transferControl.complyAdvantageMessage}>
      <Text.Body size="sm" mb={3}>
        {intl.formatMessage({
          defaultMessage: "Additional information is required. Our Customer Support team will reach out to you.",
        })}
      </Text.Body>

      <Text.Body size="sm">
        {isUkTravelRuleEnabled
          ? intl.formatMessage({
              defaultMessage: "In the meantime, your account will be frozen until our review is complete.",
            })
          : intl.formatMessage({
              defaultMessage: "In the meantime, withdrawals will be unavailable until our review is complete.",
            })}
      </Text.Body>
    </Flex>
  );
}

export default ComplyAdvantageMismatch;
