import { Flex, Text } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { OptionIconWrapper, OptionLabel } from "@gemini-ui/design-system/forms/Select/styles";

export const DropdownItem = ({ label, icon, subLabel }: SelectOptionProps) => (
  <OptionLabel>
    {icon && <OptionIconWrapper>{icon}</OptionIconWrapper>}
    <Flex justifyContent="center" flexDirection="column">
      {label}
      <Text.Body size="xs" color="#676868">
        {subLabel}
      </Text.Body>
    </Flex>
  </OptionLabel>
);
