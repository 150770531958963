import { Component, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import ClipboardPlugin from "clipboard";

interface Props {
  getText: () => string;
  onSuccess: () => void;
  render: (arg0: (e: MouseEvent<HTMLSpanElement>) => void) => ReactNode;
}

export default class Clipboard extends Component<Props> {
  clipboard: ClipboardPlugin;

  constructor(props: Props) {
    super(props);

    const { getText, onSuccess } = props;
    // Create a noop node to satisfy the plugin
    const node = document.createElement("a");
    this.clipboard = new ClipboardPlugin(node, { text: getText });
    if (onSuccess) this.clipboard.on("success", onSuccess);
  }

  onClick = (e: MouseEvent<any>) => {
    if (e) e.preventDefault();
    // Trigger underlying click handler
    this.clipboard.onClick(e);
  };

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    return this.props.render(this.onClick);
  }
}

export const useClipboard = () => {
  const [copied, setCopied] = useState(false);
  const timeout = useRef<number>();

  useEffect(() => {
    if (copied) {
      timeout.current = window.setTimeout(() => {
        setCopied(false);
      }, 1000);
    }

    return () => clearTimeout(timeout.current);
  }, [copied]);

  return {
    copied,
    setCopied,
  };
};
