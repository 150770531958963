import { FC, PropsWithChildren, ReactNode } from "react";
import { Button, Tooltip } from "@gemini-ui/design-system";
import { ButtonProps } from "@gemini-ui/design-system/Button";

type Props = {
  tooltipMessage: ReactNode;
} & ButtonProps;

const DisabledButtonWithTooltip: FC<PropsWithChildren<Props>> = ({ tooltipMessage, children, ...buttonProps }) => {
  return (
    <Tooltip overlay={tooltipMessage}>
      <Button.Secondary disabled {...buttonProps}>
        {children}
      </Button.Secondary>
    </Tooltip>
  );
};

export default DisabledButtonWithTooltip;
