import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import { IconMinus, IconPlus } from "@hubble/icons";
import { useMedia } from "react-use";
import { Money } from "@gemini-ui/components/Money";
import { usePageData } from "@gemini-ui/contexts";
import { Card, Colors, Input, Text } from "@gemini-ui/design-system";
import { FundingSourceSelect } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/FundingSourceSelect";
import { getMaxNumValidators } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/utils";
import { PresetDepositAmountButton, PresetDepositButtonsContainer } from "@gemini-ui/pages/Earn/Deposit/styles";
import { PlaceDepositInfo, SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  validatorRatio: number;
  source: SourceType;
  setSource: (source: SourceType) => void;
  setAmount: (amount: PlaceDepositInfo["amount"]) => void;
  quoteError?: string;
}

const DOT = " • ";

interface MessageProps {
  /** current fiat price of the currency */
  ask: string;
  amount: PlaceDepositInfo["amount"];
}

const Message = ({ amount, ask }: MessageProps) => {
  const { currency } = useGrowBuy();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const value = amount === "NaN" ? 0 : parseInt(amount || "0");

  return (
    <Text.Body size="xs">
      {/* TODO: Can't be hardcoding this, awaiting API */}
      <Money currency={currency} value={value} />
      {DOT}
      {/* TODO: Can't be hardcoding this, awaiting API */}
      <Money currency={defaultFiat} value={value * parseFloat(ask)} />
    </Text.Body>
  );
};

const ActionButton = ({ onClick, enableMarginTop = false, disabled = false, cta = "", Icon = null }) => {
  return (
    <PresetDepositAmountButton
      onClick={onClick}
      disabled={disabled}
      mt={enableMarginTop ? 1 : undefined}
      cta={cta || undefined}
      size="sm"
    >
      {/* #B3B3B4 is from the Figma designs and does not exist in our Colors object */}
      {Boolean(Icon) && <Icon size="xs" color={disabled ? "#B3B3B4" : Colors.black} />}
    </PresetDepositAmountButton>
  );
};

export const PrivateStakingInputs = ({ source, setSource, setAmount, validatorRatio, quoteError }: Props) => {
  const { intl } = useIntl();
  const isMobile = useMedia(BREAKPOINTS.mobileDown);

  const {
    transactionType,
    currency,
    asset,
    depositStatus: { amount },
    updateDepositStatus,
  } = useGrowBuy();

  const [numValidators, setNumValidators] = useState(Boolean(amount) ? String(Number(amount) / validatorRatio) : "");
  const [error, setError] = useState(quoteError ?? "");

  const maxNumValidators = useMemo(() => getMaxNumValidators(asset, validatorRatio), [asset, validatorRatio]);

  const validate = useCallback(
    (numValidators: string) => {
      setNumValidators(numValidators);

      const amount = (parseInt(numValidators) * validatorRatio).toFixed(0);

      updateDepositStatus({
        amount,
        source,
      });

      setAmount(amount);
      setSource(source);

      // TODO: Move form validation to the Yup schema

      if (!Boolean(numValidators)) return;

      if (parseInt(numValidators) < 1) {
        return setError(intl.formatMessage({ defaultMessage: "Number of desired validators must be greater than 0." }));
      }

      if (parseInt(numValidators) > maxNumValidators) {
        return setError(
          intl.formatMessage(
            defineMessage({
              defaultMessage: "You do not have enough {currency} to purchase {numValidators} validators.",
            }),
            {
              currency,
              numValidators,
            }
          )
        );
      }

      // By default, remove any errors if we've passed validations
      setError("");
    },
    [intl, maxNumValidators, currency, setSource, setAmount, updateDepositStatus, source, validatorRatio]
  );

  const increment = useCallback(() => {
    const value = Math.min(maxNumValidators, parseInt(numValidators || "0") + 1).toString();
    validate(value);
  }, [maxNumValidators, numValidators, validate]);

  const decrement = useCallback(() => {
    const value = Math.max(0, parseInt(numValidators || "0") - 1).toString();
    validate(value);
  }, [numValidators, validate]);

  const max = useCallback(() => {
    validate(maxNumValidators.toString());
  }, [maxNumValidators, validate]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validate(e.target.value);
    },
    [validate]
  );

  return (
    <Fragment>
      {transactionType === GrowTransactionType.STAKE && (
        <FundingSourceSelect
          source={source}
          onChange={setSource}
          error={null}
          availableForEarningInterest={asset?.availableForEarningInterest}
          availableForEarningInterestNotional={asset?.availableForEarningInterestNotional}
        />
      )}

      <Card variant="filled" padding="none" mt={1} p={2}>
        <Input
          inputSize="lg"
          label={
            transactionType === GrowTransactionType.STAKE
              ? intl.formatMessage({ defaultMessage: "Enter validator amount" })
              : intl.formatMessage({ defaultMessage: "Unstake amount" })
          }
          name="numValidators"
          data-testid="staking-validator-amount"
          type="number"
          value={numValidators.toString()}
          placeholder="0"
          rightElement={intl.formatMessage({ defaultMessage: "Validators" })}
          onChange={onChange}
          message={<Message ask={asset?.ask} amount={amount} />}
          error={error}
        />

        <div>
          <PresetDepositButtonsContainer>
            <ActionButton onClick={decrement} disabled={parseInt(numValidators || "0") < 1} Icon={IconMinus} />
            <ActionButton
              onClick={increment}
              disabled={parseInt(numValidators || "0") >= maxNumValidators}
              enableMarginTop={isMobile}
              Icon={IconPlus}
            />
            <ActionButton
              onClick={max}
              disabled={parseInt(numValidators || "0") >= maxNumValidators}
              cta={intl.formatMessage({ defaultMessage: "Stake max" })}
            />
          </PresetDepositButtonsContainer>
        </div>
      </Card>
    </Fragment>
  );
};
