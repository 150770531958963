import { VerificationPendingModalProps } from "@gemini-ui/components/Header/VerificationPendingModal/index";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const VerificationPendingModal = LazyModalWrapper(() => import("./index"));

function LazyLoadVerificationPendingModal(props: VerificationPendingModalProps) {
  return <VerificationPendingModal {...props} />;
}

export default LazyLoadVerificationPendingModal;
