import { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { EVENTS, track } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { CONSENTED_FORMS, InterestProvider } from "@gemini-ui/constants/earn";
import { usePageData, usePageRefresh } from "@gemini-ui/contexts";
import { Modal } from "@gemini-ui/design-system";
import { ModalProps } from "@gemini-ui/design-system/Modal";
import Agreement, { consentUrl } from "@gemini-ui/pages/Earn/Deposit/AcceptAgreement/Agreement";
import axios from "@gemini-ui/services/axios";
import { fetchAgreement } from "@gemini-ui/services/earn/fetchAgreement";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onAccept?: () => void;
  onDecline?: () => void;
  provider: InterestProvider;
}

const StakingAgreement = ({ onAccept, onDecline, provider }: Props) => {
  const { intl } = useIntl();
  const { showAlert } = useAlert();
  const { requestRefresh } = usePageRefresh();
  const {
    templateProps: {
      user: { countryCode },
    },
  } = usePageData();

  const [agreement, setAgreement] = useState<string>(null);
  const [disabled, setDisabled] = useState(true);

  useEffectOnce(() => {
    track(EVENTS.VIEW_STAKING_AGREEMENT.name, {
      [EVENTS.VIEW_STAKING_AGREEMENT.properties.COUNTRY_CODE]: countryCode,
    });
  });

  useEffect(() => {
    let cancelRequest = false;

    fetchAgreement(CONSENTED_FORMS.STAKING_AGREEMENT, provider?.id)
      .then(response => {
        if (cancelRequest) return;
        setAgreement(response.body);
        setDisabled(false);
      })
      .catch(err => {
        return err;
      });

    return () => {
      cancelRequest = true;
    };
  }, [provider?.id]);

  const acceptOnClick = useCallback(() => {
    track(EVENTS.ACCEPT_STAKING_AGREEMENT.name, {
      [EVENTS.ACCEPT_STAKING_AGREEMENT.properties.COUNTRY_CODE]: countryCode,
    });

    const payload = {
      ...(provider?.id ? { providerId: provider?.id } : {}),
      consentFormId: CONSENTED_FORMS.STAKING_AGREEMENT,
    };

    axios
      .post(consentUrl, payload)
      .then(() => {
        requestRefresh();
        onAccept?.();
      })
      .catch(err => {
        showAlert({ type: AlertTypes.ERROR, message: getError(err) });
      });
  }, [onAccept, provider?.id, showAlert, requestRefresh, countryCode]);

  const declineOnClick = useCallback(() => {
    track(EVENTS.DECLINE_STAKING_AGREEMENT.name, {
      [EVENTS.DECLINE_STAKING_AGREEMENT.properties.COUNTRY_CODE]: countryCode,
    });

    onDecline?.();
  }, [onDecline, countryCode]);

  return (
    <Agreement
      title={intl.formatMessage({ defaultMessage: "Staking agreement" })}
      acceptOnClick={acceptOnClick}
      declineOnClick={declineOnClick}
      agreement={agreement}
      disabled={disabled}
    />
  );
};

export default StakingAgreement;

export const StakingAgreementModal = ({ isOpen, onClose, ...rest }: Props & ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <StakingAgreement {...rest} />
    </Modal>
  );
};
