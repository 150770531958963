import { Fragment } from "react";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { AnnualInterestYieldTier, GrowProviderRestriction } from "@gemini-ui/constants/earn";
import { Card, List, ListItem, Text } from "@gemini-ui/design-system";
import { useRateDetailsFormatter } from "@gemini-ui/pages/Earn/hooks";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { formatApyText, FormattedInterestTier, isEligibleForApy } from "@gemini-ui/pages/Earn/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const FormattedRateTier = (props: {
  currency: CurrencyShortName;
  interestTier: FormattedInterestTier;
  maxThreshold: string | number;
}) => {
  const { intl } = useIntl();
  const { currency, interestTier, maxThreshold } = props;

  if (Boolean(interestTier?.range)) {
    return (
      <Fragment>
        {interestTier.range.min} - <Money currency={currency} value={interestTier.range.max} />
      </Fragment>
    );
  }

  // If there's no `interestTier.range`, we know this is the highest tier available, so list the `maxThreshold`
  if (!Boolean(interestTier?.range) && maxThreshold) {
    return (
      <Fragment>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Above {maxThreshold}",
            description: "e.g. 'Above 500 BTC",
          }),
          {
            maxThreshold: (
              <Money
                currency={currency}
                value={maxThreshold}
                data-testid={testIds.deposit.rateDetails.rateDetails.maxThreshold}
              />
            ),
          }
        )}
      </Fragment>
    );
  }
};

interface Props {
  interestTiers: AnnualInterestYieldTier[];
  currency: CurrencyShortName;
  hasHeading?: boolean;
  restriction?: GrowProviderRestriction;
}

export const RateDetailsList = ({ interestTiers, currency, hasHeading = true, restriction }: Props) => {
  const { intl } = useIntl();

  const { onlyHasOneRateTier, formattedInterestTiers, maxThreshold } = useRateDetailsFormatter(interestTiers);

  if (formattedInterestTiers.length === 0) return null;

  return (
    <Card variant="filled" padding="none">
      <List data-testid={testIds.deposit.rateDetails.rateDetails.list}>
        {hasHeading && (
          <ListItem padding="sm" size="dense">
            <Text.Body size="md" bold>
              {intl.formatMessage({ defaultMessage: "Rate tiers" })}
            </Text.Body>
          </ListItem>
        )}

        {formattedInterestTiers.map((_data, _i) => (
          <ListItem
            key={_i}
            data-testid={`${testIds.deposit.rateDetails.rateDetails.listItem}-${_i}`}
            right={
              <Text.Body size="sm" data-testid={`${testIds.deposit.rateDetails.rateDetails.rangeDescription}-${_i}`}>
                {onlyHasOneRateTier ? (
                  intl.formatMessage({ defaultMessage: "Entire balance" })
                ) : (
                  <FormattedRateTier maxThreshold={maxThreshold} interestTier={_data} currency={currency} />
                )}
              </Text.Body>
            }
            size="dense"
            padding="sm"
          >
            <Text.Body size="sm" data-testid={`${testIds.deposit.rateDetails.rateDetails.yield}-${_i}`}>
              {formatApyText({
                annualInterestYieldTiers: [_data],
                withApySuffix: true,
                eligible: isEligibleForApy(restriction),
                intl,
              })}
            </Text.Body>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};
