import { FC, PropsWithChildren } from "react";
import { HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
interface SupportLinkProps {
  showText?: boolean;
  className?: string;
}

export const SupportLink: FC<PropsWithChildren<SupportLinkProps>> = ({ className, showText = true }) => {
  const { intl } = useIntl();

  const link = (
    <HelpCenterLink className={className} data-testid="customer-support-link">
      {intl.formatMessage({
        defaultMessage: "Gemini Customer Support",
      })}
    </HelpCenterLink>
  );
  if (showText) {
    return (
      <span data-testid="customer-support-link">
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "contact {link}",
            description: "Gemini Customer Support",
          }),
          {
            link,
          }
        )}
      </span>
    );
  } else {
    return link;
  }
};
const GEMINI_SUPPORT_LINK_DISPLAY_NAME = "Gemini.SupportLink";
SupportLink.displayName = GEMINI_SUPPORT_LINK_DISPLAY_NAME;

export default SupportLink;
