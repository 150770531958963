import { Children, useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

const VirtualizedMenuList = ({ children, maxHeight, itemHeight = 48, options, getValue }) => {
  const itemsLength = Children.count(children);
  const height = useMemo(() => {
    if (itemsLength === 0) {
      return itemHeight;
    }
    return Math.min(maxHeight, itemsLength * itemHeight);
  }, [itemsLength, itemHeight, maxHeight]);
  if (!children || !itemsLength) return null;
  const [value] = getValue();
  const initialOffset: number = options.indexOf(value) * itemHeight;

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <FixedSizeList
          width={width}
          height={height}
          itemSize={itemHeight}
          itemCount={itemsLength}
          initialScrollOffset={initialOffset}
        >
          {({ index, style }) => <div style={style}>{Children.toArray(children)[index]}</div>}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export { VirtualizedMenuList };
