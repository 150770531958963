import { ReactNode } from "react";
import { last, sortBy } from "lodash";
import { CurrencyShortName, currencyToUpper } from "@gemini-common/scripts/constants/currencies";
import Num from "@gemini-common/scripts/Num";
import { EVENTS } from "@gemini-ui/analytics/constants/events";
import { MoneyProps } from "@gemini-ui/components/Money";
import {
  AnnualInterestYieldTier,
  EarnEligibilityInfo,
  GrowAsset,
  GrowAssets,
  GrowProviderRestriction,
  GrowProviderType,
  InterestProvider,
  StakingBalanceSnapshot,
} from "@gemini-ui/constants/earn";
import { StakingBalanceState } from "@gemini-ui/pages/Earn/StakingAssetDetailsPage/types";
import { UnfulfilledRedeem } from "@gemini-ui/pages/RetailTrade/constants";
import { PendingBalanceRow } from "@gemini-ui/pages/RetailTrade/Portfolio/constants";
import { CURRENCY_DETAILS_V2 } from "@gemini-ui/pages/RetailTrade/utils";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const isEligibleForApy = (restriction: GrowProviderRestriction) => {
  // If the asset cannot be deposited, we assume the user is ineligible for the APY
  return !(
    restriction === GrowProviderRestriction.BLOCK_ORDERS || restriction === GrowProviderRestriction.BLOCK_DEPOSITS
  );
};

const getGrowBalancesByProviderType = (
  assets: GrowAssets = {}
): Record<
  GrowProviderType,
  {
    earningInterest: number;
    earningInterestNotional: number;
    earnUnfulfilledRedeemTransactionsTotal: number;
    earnUnfulfilledRedeemTransactionsTotalNotional: number;
  }
> => {
  const totalBalanceByProviderType = Object.values(assets).reduce(
    (acc, cur) => {
      for (const provider of cur?.interestProviders) {
        if (!Boolean(provider.providerType)) return acc;
        const {
          earningInterest,
          earningInterestNotional,
          earnUnfulfilledRedeemTransactionsTotal,
          earnUnfulfilledRedeemTransactionsTotalNotional,
        } = provider;
        acc[provider.providerType].earningInterest += Num.parse(earningInterest?.value ?? 0);
        acc[provider.providerType].earningInterestNotional += Num.parse(earningInterestNotional?.value ?? 0);
        acc[provider.providerType].earnUnfulfilledRedeemTransactionsTotal += Num.parse(
          earnUnfulfilledRedeemTransactionsTotal?.value ?? 0
        );
        acc[provider.providerType].earnUnfulfilledRedeemTransactionsTotalNotional += Num.parse(
          earnUnfulfilledRedeemTransactionsTotalNotional?.value ?? 0
        );
      }

      return acc;
    },
    {
      [GrowProviderType.EARN]: {
        earningInterest: 0,
        earningInterestNotional: 0,
        earnUnfulfilledRedeemTransactionsTotal: 0,
        earnUnfulfilledRedeemTransactionsTotalNotional: 0,
      },
      [GrowProviderType.POOLED_STAKING]: {
        earningInterest: 0,
        earningInterestNotional: 0,
        earnUnfulfilledRedeemTransactionsTotal: 0,
        earnUnfulfilledRedeemTransactionsTotalNotional: 0,
      },
      [GrowProviderType.PRIVATE_STAKING]: {
        earningInterest: 0,
        earningInterestNotional: 0,
        earnUnfulfilledRedeemTransactionsTotal: 0,
        earnUnfulfilledRedeemTransactionsTotalNotional: 0,
      },
      [GrowProviderType.PROMOTIONAL]: {
        earningInterest: 0,
        earningInterestNotional: 0,
        earnUnfulfilledRedeemTransactionsTotal: 0,
        earnUnfulfilledRedeemTransactionsTotalNotional: 0,
      },
    }
  );

  return totalBalanceByProviderType;
};

const NOT_APPLICABLE = "N/A";
const RATE_DISPLAY_THRESHOLD_TEXT = "-";

export const formatInterestYields = (
  interestYields: AnnualInterestYieldTier[],
  intl: IntlShape,
  highestYieldOnly?: boolean,
  eligible = true
): string => {
  try {
    if (!eligible) return RATE_DISPLAY_THRESHOLD_TEXT;

    // Sort by lowest -> highest yield
    const sortedYields = sortBy(interestYields, x => x.yield);
    const highestYield = Num.formatInterestRate(last(sortedYields).yield);

    // Though we might have access to the internationalization context here,
    // it does not make sense to translate a response that is all numbers and symbols.
    // Return the raw calculation string instead.
    return sortedYields.length === 1 || highestYieldOnly
      ? `${highestYield}%`
      : `${Num.formatInterestRate(sortedYields[0].yield)}% - ${highestYield}%`;
  } catch (e) {
    // Return a default string if we dont have access to `intl`
    if (!intl) return NOT_APPLICABLE;

    // Otherwise, return a translated string
    return intl.formatMessage({ defaultMessage: "N/A", description: "Abbreviation for not applicable" });
  }
};

/**
 * Given `annualInterestYieldTiers`, you can auto-format the yields to your liking.
 *
 * @example
 * "0.30% - 3.50%" // Default (multiple tiers)
 * "3.50%" // Default (single tier)
 *
 * "0.30% - 3.50% APY" // `withApySuffix`
 * "Up to 3.50%" // `withUpToPrefix`
 * "3.50%" // `highestYieldOnly` (multiple tiers)
 *
 * "3.50% APY" // `withApySuffix` and `highestYieldOnly`
 * "Up to 3.50% APY" // `withUpToPrefix` and `withApySuffix`
 */
export const formatApyText = ({
  annualInterestYieldTiers,
  highestYieldOnly = false,
  withApySuffix = false,
  withAprSuffix = false,
  withUpToPrefix = false,
  eligible = true,
  intl,
}: {
  annualInterestYieldTiers: AnnualInterestYieldTier[] | undefined;
  /**
   * Only return the highest yield.
   * @example "3.50%" // Even if there are multiple tiers
   */
  highestYieldOnly?: boolean;
  /**
   * Add " APY" text suffix after interest rate values
   * @example "0.30% - 3.50% APY"
   */
  withApySuffix?: boolean;

  /**
   * Add " APR" text suffix after interest rate values
   * @example "0.30% - 3.50% APR"
   */
  withAprSuffix?: boolean;

  /**
   * Only format the highest yield (if multiple exist), instead of a range, and prefix with "Up to "
   *
   * Note: if there is only one tier available, this prefix will not be used.
   *
   * Automatically implies `highestYieldOnly === true`
   *
   * @example
   * "Up to 3.50%" // Multiple interest tiers, 3.5 is the highest
   * "3.50%" // Single interest tier
   */
  withUpToPrefix?: boolean;
  /**
   * Whether the user is eligible for the APY
   */
  eligible?: boolean;
  intl: IntlShape;
}) => {
  if (!Boolean(annualInterestYieldTiers) || !annualInterestYieldTiers.length) return "";

  // We only want to use `withUpToPrefix` if there are multiple tiers
  const shouldUseUpToPrefix = withUpToPrefix && annualInterestYieldTiers.length > 1;

  const apy = formatInterestYields(annualInterestYieldTiers, intl, shouldUseUpToPrefix || highestYieldOnly, eligible);

  // Determine if the text should be displayed with a suffix
  // APR will be prioritized over APY if both are true
  const hasSuffix = withApySuffix || withAprSuffix;

  const suffix = hasSuffix
    ? withAprSuffix
      ? intl.formatMessage({ defaultMessage: "APR", description: "Abbreviation for Annual Percentage Rate" })
      : intl.formatMessage({ defaultMessage: "APY", description: "Abbreviation for Annual Percentage Yield" })
    : null;

  // Return the APY directly if it was requested without text
  if (!suffix && !shouldUseUpToPrefix) return apy;

  // If the APY should be hidden but more text was requested,
  // return an empty string to prevent "- APY" or "Up to - APY"
  if (!eligible) return "";

  // Otherwise, return the translated string
  return intl.formatMessage(
    defineMessage({
      defaultMessage:
        "{shouldUseUpToPrefix, select, true {Up to } other {}}{apy}{shouldUseSuffix, select, true { {suffix}} other {}}",
    }),
    { shouldUseUpToPrefix, apy, shouldUseSuffix: Boolean(suffix), suffix }
  );
};

export interface FormattedInterestTier extends AnnualInterestYieldTier {
  range?: {
    min: number;
    max: number;
    diff: number;
  };
}

export const formatInterestTiers = (
  annualInterestYieldTiers: AnnualInterestYieldTier[] | undefined
): FormattedInterestTier[] => {
  const tiers = annualInterestYieldTiers ?? [];

  // Format the tiers so we can render the range as a list
  const formattedInterestTiers = tiers.reduce<FormattedInterestTier[]>((acc, cur, idx) => {
    // If there is no threshold to compare, skip
    if (!Boolean(cur.threshold)) return [...acc, cur];

    const min = idx > 0 && Boolean(acc[idx - 1].threshold) ? Num.parse(acc[idx - 1].threshold.value) : 0;
    const max = Num.parse(cur.threshold.value);

    const tier = {
      ...cur,
      range: {
        min,
        max,
        diff: max - min,
      },
    };
    return [...acc, tier];
  }, []);

  return formattedInterestTiers;
};

export const formatLongFormTieredRateSentences = (
  intl: IntlShape,
  formattedInterestTiers: FormattedInterestTier[],
  currency: CurrencyShortName
): ReactNode => {
  if (!(formattedInterestTiers.length > 0)) return null;

  const sentences = formattedInterestTiers.map((data, i) => {
    const { yield: curYield, threshold: curThreshold } = data;
    const { threshold: prevThreshold } = formattedInterestTiers[i - 1] ?? {};

    if (i === 0)
      return intl.formatMessage(
        defineMessage({
          defaultMessage:
            "You will receive {yield}% on the first {threshold} {symbol}, regardless of your total balance.",
        }),
        { yield: Num.formatInterestRate(curYield), threshold: curThreshold?.value ?? "", symbol: currency }
      );
    if (i === formattedInterestTiers.length - 1)
      return intl.formatMessage(
        defineMessage({ defaultMessage: "Anything above {threshold} {symbol} will earn {yield}%." }),
        {
          threshold: prevThreshold?.value ?? "",
          yield: Num.formatInterestRate(curYield),
          symbol: currency,
        }
      );
    return intl.formatMessage(
      defineMessage({
        defaultMessage: "Anything between {threshold1} {symbol} and {threshold2} {symbol} will earn {yield}%.",
      }),
      {
        threshold1: prevThreshold?.value ?? "",
        threshold2: curThreshold?.value ?? "",
        yield: Num.formatInterestRate(curYield),
        symbol: currency,
      }
    );
  });

  return intl.formatMessage(
    defineMessage({
      defaultMessage:
        "As your total Earn balance increases, your total earnings will reflect the deduction of a different Agent Fee on incremental amounts over established levels (“tiers”). {tierSentences} These amounts represent current tiers and associated fees and are subject to change.*",
    }),
    { tierSentences: sentences.join(" ") }
  );
};

function getHighestYield(yieldTiers: AnnualInterestYieldTier[] = []): number | null {
  if (!yieldTiers.length) return null;
  return [...yieldTiers].sort((a, b) => b.yield - a.yield)[0].yield;
}

export const highestYieldProvider = (providers: InterestProvider[] = []): InterestProvider | null => {
  if (!providers.length) return null;

  return [...providers].sort((a, b) => {
    return (getHighestYield(b?.annualInterestYieldTiers) ?? 0) - (getHighestYield(a?.annualInterestYieldTiers) ?? 0);
  })[0];
};

export const mapGrowAssets = (assets: GrowAssets[]): GrowAssets => {
  if (!assets) return {};

  const assetMap = {};
  for (const asset of assets) {
    const [symbol, val] = Object.entries(asset)[0];
    assetMap[symbol] = val;
  }

  return assetMap;
};

export const GrowTrackingEvent = {
  ELIGIBILITY: {
    TERMS_AGREEMENT: {
      [GrowProviderType.EARN]: EVENTS.ACCEPT_EARN_TERMS_AGREEMENT,
    },
    MASTER_LOAN_AGREEMENT: {
      [GrowProviderType.EARN]: EVENTS.ACCEPT_MASTER_LOAN_AGREEMENT,
    },
  },
  BEGIN_WITHDRAW: {
    [GrowProviderType.EARN]: EVENTS.BEGIN_EARN_REDEEM,
  },
  ENTER_WITHDRAW_AMOUNT: {
    [GrowProviderType.EARN]: EVENTS.ENTER_EARN_REDEEM_AMOUNT,
  },
  WITHDRAW: {
    [GrowProviderType.EARN]: EVENTS.EARN_REDEEM,
    [GrowProviderType.POOLED_STAKING]: EVENTS.PLACE_UNSTAKE,
  },
  RETAIL_TOGGLE: {
    [GrowProviderType.EARN]: EVENTS.RETAIL_EARN_TOGGLE,
  },
};

export const GROW_HOMEPAGE_BREAKPOINT = BREAKPOINTS.tabletDownLarge;

export const createGrowBalanceState = (fromAssets: GrowAssets) => {
  const balances = getGrowBalancesByProviderType(fromAssets);

  const { earningInterest: earnBalance, earnUnfulfilledRedeemTransactionsTotal: redeemBalance } =
    balances?.[GrowProviderType.EARN];
  const { earningInterest: pooledStakeBalance, earnUnfulfilledRedeemTransactionsTotal: pooledUnstakeBalance } =
    balances?.[GrowProviderType.POOLED_STAKING];
  const { earningInterest: privateStakeBalance, earnUnfulfilledRedeemTransactionsTotal: privateUnstakeBalance } =
    balances?.[GrowProviderType.POOLED_STAKING];

  return {
    hasEarnBalance: Boolean(earnBalance),
    hasEarnBalanceOrPendingRedemptions: Boolean(earnBalance) || Boolean(redeemBalance),
    hasPendingPrivateUnstakes: Boolean(privateUnstakeBalance),
    hasPendingRedemptions: Boolean(redeemBalance),
    hasPendingUnstakes: Boolean(pooledUnstakeBalance) || Boolean(privateUnstakeBalance),
    hasPrivateStakingBalance: Boolean(privateStakeBalance),
    hasPrivateStakingBalanceOrPendingUnstakes: Boolean(privateStakeBalance) || Boolean(privateUnstakeBalance),
    hasPooledStakingBalance: Boolean(pooledStakeBalance),
    hasPooledStakingBalanceOrPendingUnstakes: Boolean(pooledStakeBalance) || Boolean(pooledUnstakeBalance),
  };
};

/**
 * Deep merge objects, adding number (and number string) values together, and concatenating arrays.
 *
 * @param providers
 * @returns
 */
function deepMergeCombine(obj1, obj2) {
  const result = { ...obj1 };

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!result.hasOwnProperty(key)) {
        result[key] = obj2[key];
        continue;
      } else if (typeof obj2[key] === "object" && !Array.isArray(obj2[key]) && obj2[key] !== null) {
        result[key] = deepMergeCombine(result[key] ?? {}, obj2[key]);
      } else if (Array.isArray(obj2[key]) && Array.isArray(result[key])) {
        result[key] = result[key].concat(obj2[key]);
      } else if (typeof obj2[key] === "number" && typeof result[key] === "number") {
        result[key] = result[key] + obj2[key];
      } else if (!isNaN(obj2[key]) || !isNaN(result[key])) {
        // MoneyProps can have string values, so we need to convert them to numbers
        result[key] = Number(result?.[key] ?? 0) + Number(obj2?.[key] ?? 0);
      } else {
        result[key] = obj2[key];
      }
    }
  }

  return result;
}

/**
 * Merge an array of InterestProvider objects into a single object.
 * Beware, string values like `id`, `name`, and `fullname` will be overwritten by the last array element.
 *
 * @param providers
 * @returns
 */
const mergeProviderBalances = (providers: InterestProvider[]): Partial<InterestProvider> => {
  let res = {};
  for (const obj of providers) {
    res = deepMergeCombine(res, obj);
  }
  return res;
};

const addMoneyProps = (a: MoneyProps, b: MoneyProps) => ({
  currency: a?.currency ?? b?.currency,
  value: Number(a?.value ?? 0) + Number(b?.value ?? 0),
});

/**
 * Convenience utility to parse and combine balances and metadata of
 * Pooled and Private staking providers.
 *
 * This will combine data by provider type - so if an asset has multiple
 * pooled staking providers, for example, the data will reflect the combined
 * values.
 *
 * @param asset The GrowAsset to parse data from
 */
export const getCombinedStakingData = (asset?: GrowAsset): StakingBalanceSnapshot => {
  const [pooledProviders, privateProviders] = [
    asset?.interestProviders.filter(p => p.providerType === GrowProviderType.POOLED_STAKING) ?? [],
    asset?.interestProviders.filter(p => p.providerType === GrowProviderType.PRIVATE_STAKING) ?? [],
  ];

  const combinedPooledBalances = mergeProviderBalances(pooledProviders);
  const combinedPrivateBalances = mergeProviderBalances(privateProviders);

  const hasPooledStakingBalance = pooledProviders.some(p => Number(p.earningInterest.value) > 0);
  const hasPrivateStakingBalance = privateProviders.some(p => Number(p.earningInterest.value) > 0);
  const balanceState =
    hasPooledStakingBalance && hasPrivateStakingBalance
      ? StakingBalanceState.HAS_POOLED_AND_PRIVATE
      : hasPooledStakingBalance
      ? StakingBalanceState.HAS_POOLED
      : hasPrivateStakingBalance
      ? StakingBalanceState.HAS_PRIVATE
      : StakingBalanceState.NO_BALANCE;

  return {
    combinedAnnualInterestYieldTiers: [
      ...(combinedPooledBalances?.annualInterestYieldTiers ?? []),
      ...(combinedPrivateBalances?.annualInterestYieldTiers ?? []),
    ],
    pooledAnnualInterestYieldTiers: combinedPooledBalances?.annualInterestYieldTiers,
    privateAnnualInterestYieldTiers: combinedPrivateBalances?.annualInterestYieldTiers,
    pooledAnnualPercentageRatePast30Days: combinedPooledBalances?.annualPercentageRatePast30Days,
    combinedInterestEarnedToDatePast30Days: addMoneyProps(
      combinedPooledBalances?.interestEarnedToDatePast30Days,
      combinedPrivateBalances?.interestEarnedToDatePast30Days
    ),
    combinedInterestEarnedToDatePast30DaysNotional: addMoneyProps(
      combinedPooledBalances?.interestEarnedToDatePast30DaysNotional,
      combinedPrivateBalances?.interestEarnedToDatePast30DaysNotional
    ),
    combinedInterestEarnedToDate: addMoneyProps(
      combinedPooledBalances?.interestEarnedToDate,
      combinedPrivateBalances?.interestEarnedToDate
    ),
    combinedInterestEarnedToDateNotional: addMoneyProps(
      combinedPooledBalances?.interestEarnedToDateNotional,
      combinedPrivateBalances?.interestEarnedToDateNotional
    ),
    hasPendingPooledUnstakes: combinedPooledBalances?.earnUnfulfilledRedeemTransactionsCount > 0,
    hasPendingPrivateUnstakes: combinedPrivateBalances?.earnUnfulfilledRedeemTransactionsCount > 0,
    pooledStakingBalance: combinedPooledBalances?.earningInterest,
    pooledStakingBalanceNotional: combinedPooledBalances?.earningInterestNotional,
    privateStakingBalance: combinedPrivateBalances?.earningInterest,
    privateStakingBalanceNotional: combinedPrivateBalances?.earningInterestNotional,
    combinedStakingBalance: addMoneyProps(
      combinedPooledBalances?.earningInterest,
      combinedPrivateBalances?.earningInterest
    ),
    combinedStakingBalanceNotional: addMoneyProps(
      combinedPooledBalances?.earningInterestNotional,
      combinedPrivateBalances?.earningInterestNotional
    ),
    privateInterestEarnedToDateByType: combinedPrivateBalances?.interestEarnedToDateByType,
    privateFeeDetails: combinedPrivateBalances?.feeDetails,
    privateValidatorDetails: combinedPrivateBalances?.validatorDetails,
    hasPooledStakingBalance,
    hasPrivateStakingBalance,
    balanceState,
    pooledProviders,
    privateProviders,
    pooledInterestEarnedToDate: combinedPooledBalances?.interestEarnedToDate,
    pooledInterestEarnedToDateNotional: combinedPooledBalances?.interestEarnedToDateNotional,
    privateInterestEarnedToDate: combinedPrivateBalances?.interestEarnedToDate,
    privateInterestEarnedToDateNotional: combinedPrivateBalances?.interestEarnedToDateNotional,
    pooledInterestEarnedToDatePast30Days: combinedPooledBalances?.interestEarnedToDatePast30Days,
    pooledInterestEarnedToDatePast30DaysNotional: combinedPooledBalances?.interestEarnedToDatePast30DaysNotional,
    privateInterestEarnedToDatePast30Days: combinedPrivateBalances?.interestEarnedToDatePast30Days,
    privateInterestEarnedToDatePast30DaysNotional: combinedPrivateBalances?.interestEarnedToDatePast30DaysNotional,
    privateNetRewards: combinedPrivateBalances?.netRewards,
    privateAvailableBalance: combinedPrivateBalances?.availableBalance,
    privateWithdrawals: combinedPrivateBalances?.withdrawals,
  };
};

/**
 * Utility function to group `unfulfilledRedeems` transactions by currency symbol.
 * This is useful when displaying the pending state of a currency.
 *
 * Note: You should call this from a `useMemo` hook to avoid unnecessary re-renders.
 *
 * @param unfulfilledRedeems Array of UnfulfilledRedeem transactions
 * @returns Record of currency symbol to PendingBalanceRow data
 */
export function createPendingCurrencyMap(
  unfulfilledRedeems: UnfulfilledRedeem[]
): Partial<Record<CurrencyShortName, PendingBalanceRow>> {
  return unfulfilledRedeems.reduce((acc, cur) => {
    const { amountRemaining, amountRemainingNotional, providerType } = cur;
    const { currency } = amountRemaining;
    const { currency: fiatCurrency } = amountRemainingNotional;

    // Combine Earn related data
    const isEarnRedeem = providerType === GrowProviderType.EARN;
    const currencyEarnRedeemCount = acc?.[currency]?.pendingEarnRedemptionCount ?? 0;

    // Combine Staking (pooled and private) related data
    const isUnstake =
      providerType === GrowProviderType.POOLED_STAKING || providerType === GrowProviderType.PRIVATE_STAKING;
    const currencyUnstakeCount = acc?.[currency]?.pendingUnstakeCount ?? 0;

    const newVal = {
      [currency]: {
        currency,
        // We have to use the non-memoized version here in case this function is used outside of a render component.
        details: CURRENCY_DETAILS_V2(currencyToUpper(currency)),
        totalRemainingAmount: {
          currency,
          value: Number(acc?.[currency]?.totalRemainingAmount?.value ?? 0) + Number(amountRemaining.value ?? 0),
        },
        totalRemainingAmountNotional: {
          currency: fiatCurrency,
          value:
            Number(acc?.[currency]?.totalRemainingAmountNotional?.value ?? 0) +
            Number(amountRemainingNotional.value ?? 0),
        },
        pendingEarnRedemptionCount: isEarnRedeem ? currencyEarnRedeemCount + 1 : currencyEarnRedeemCount,
        pendingUnstakeCount: isUnstake ? currencyUnstakeCount + 1 : currencyUnstakeCount,
      },
    };
    return {
      ...acc,
      ...newVal,
    };
  }, {} as Partial<Record<CurrencyShortName, PendingBalanceRow>>);
}

// TODO: This value should NOT be hardcoded. Ultimately this should come from the BE asset/provider object.
export const ETH_PER_VALIDATOR = 32;

export function calculateEthValidatorValue(amount: number) {
  return amount * ETH_PER_VALIDATOR;
}

// TODO: The value should come from the BE. This is a temporary solution.
// See: https://iceland.slack.com/archives/C03A8QEUQV6/p1683740892648309
export function calculateValidatorsAvailableToUnstake(amount: number) {
  return Math.max(0, Math.floor(amount / ETH_PER_VALIDATOR));
}

export const STAKING_LEARN_MORE_ROUTE = "https://www.gemini.com/staking";

/**
 * Utility to determine if a given Grow asset has multiple provider types available.
 *
 * Note: this util does NOT check for providerTypes and eligibility cross-over.
 *
 * @param asset
 * @param eligibilityInfo
 * @returns
 */
export const checkStakingAssetProductOptions = (
  asset: GrowAsset,
  eligibilityInfo: Pick<EarnEligibilityInfo, "eligibleForStaking" | "eligibleForPrivateStaking">
) => {
  const assetNonEarnProviderTypes = [
    ...new Set(asset.interestProviders.map(p => p.providerType).filter(p => p !== GrowProviderType.EARN)),
  ];

  const canStakeAssetPooled =
    assetNonEarnProviderTypes.includes(GrowProviderType.POOLED_STAKING) && eligibilityInfo?.eligibleForStaking;
  const canStakeAssetPrivate =
    assetNonEarnProviderTypes.includes(GrowProviderType.PRIVATE_STAKING) && eligibilityInfo?.eligibleForPrivateStaking;

  return {
    canStakeAssetPooled,
    canStakeAssetPrivate,
    canStakeAssetMultipleProviderTypes: canStakeAssetPooled && canStakeAssetPrivate,
    canStakeSomeProviderType: canStakeAssetPooled || canStakeAssetPrivate,
  };
};

/**
 * Utility to determine the provider types available to unstake given a list of  `interestProviders`.
 *
 * @param assetProviders
 * @returns
 */
export const getUnstakeProviderTypes = (assetProviders: InterestProvider[]) => {
  const supportedUnstakingProviders = [GrowProviderType.POOLED_STAKING, GrowProviderType.PRIVATE_STAKING];
  const [hasStakingBalance, hasPrivateStakingBalance, ...unusedProviderTypes] = supportedUnstakingProviders.map(
    providerType =>
      assetProviders?.some(p => p?.providerType === providerType && Boolean(Number(p?.earningInterest?.value ?? 0)))
  );

  return {
    canUnstakeAssetPooled: hasStakingBalance,
    canUnstakeAssetPrivate: hasPrivateStakingBalance,
    canUnstakeAssetMultipleProviderTypes: hasStakingBalance && hasPrivateStakingBalance,
    canUnstakeSomeProviderType: hasStakingBalance || hasPrivateStakingBalance,
  };
};
