import { useMemo } from "react";
import * as React from "react";
import { GrowSuccessAnimation } from "@gemini-ui/components/GrowSuccessAnimation";
import { Money } from "@gemini-ui/components/Money";
import { Button, Card, Flex, List, ListItem, Spacing, Text } from "@gemini-ui/design-system";
import { SourceType, TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { BuyingFrequency, OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const Success = ({ onDone }: { onDone?: () => void }) => {
  const { intl } = useIntl();

  const {
    currency,
    depositStatus: { schedule, quote: orderQuote, source },
  } = useGrowBuy();

  const isPurchaseOrder = source === SourceType.BANK_OR_CARD;

  /**
   * Parse the notional and crypto totals to display in success message.
   * There are 2 different shapes that the orderQuote can take depending on
   * whether the user is buying or transferring.
   */
  const { cryptoAmount, notionalAmount } = useMemo(() => {
    return {
      cryptoAmount: isPurchaseOrder
        ? (orderQuote as OrderQuote)?.quote?.quantity
        : (orderQuote as TransferQuote)?.amount,
      notionalAmount: isPurchaseOrder
        ? (orderQuote as OrderQuote)?.quote?.totalSpend
        : (orderQuote as TransferQuote)?.notionalAmount,
    };
  }, [isPurchaseOrder, orderQuote]);

  const recurringText = React.useMemo(() => {
    switch (schedule) {
      case BuyingFrequency.Daily:
        return intl.formatMessage({ defaultMessage: "Daily" });
      case BuyingFrequency.Weekly:
        return intl.formatMessage({ defaultMessage: "Weekly" });
      case BuyingFrequency.Biweekly:
        return intl.formatMessage({ defaultMessage: "Bi-Weekly" });
      case BuyingFrequency.Monthly:
        return intl.formatMessage({ defaultMessage: "Monthly" });
      default:
        return intl.formatMessage({ defaultMessage: "Once" });
    }
  }, [intl, schedule]);

  return (
    <React.Fragment>
      <GrowSuccessAnimation />
      <Text.Heading size="md">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Success! You {isPurchaseOrder, select, true {bought and staked} other {staked}} {currency}.",
          }),
          { isPurchaseOrder, currency }
        )}
      </Text.Heading>
      <Flex mt={1} flexDirection="column" gap={Spacing.scale[2]}>
        <Text.Body size="md">
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You will begin receiving rewards when your {currency} is moved on chain. View details to check the status of your stake.",
            }),
            { currency }
          )}
        </Text.Body>

        <Card variant="filled" padding="none">
          <List>
            <ListItem
              size="dense"
              padding="sm"
              alignItems="center"
              right={
                <Text.Body size="md" data-testid={testIds.deposit.success.frequency}>
                  {recurringText}
                </Text.Body>
              }
            >
              <Text.Body size="md">{intl.formatMessage({ defaultMessage: "Frequency" })}</Text.Body>
            </ListItem>
            <ListItem
              size="dense"
              padding="sm"
              alignItems="center"
              right={
                <Text.Body size="md">
                  <Money data-testid={testIds.deposit.success.orderQuantity} {...cryptoAmount} />
                </Text.Body>
              }
            >
              <Text.Body size="md">{intl.formatMessage({ defaultMessage: "Quantity" })}</Text.Body>
            </ListItem>
            <ListItem
              size="dense"
              padding="sm"
              alignItems="center"
              right={
                <Text.Body size="md">
                  <Money data-testid={testIds.deposit.success.orderTotal} {...notionalAmount} hideTrailingSign />
                </Text.Body>
              }
            >
              <Text.Body size="md">{intl.formatMessage({ defaultMessage: "Total" })}</Text.Body>
            </ListItem>
          </List>
        </Card>
      </Flex>
      <Button.Group type="stacked">
        <Button.Primary
          size="lg"
          data-testid={testIds.deposit.success.doneButton}
          onClick={() => {
            onDone?.();
          }}
          cta={intl.formatMessage({ defaultMessage: "Done" })}
        />
        {Boolean(currency) && (
          <Button.Secondary
            size="lg"
            data-testid={testIds.deposit.success.detailsButton}
            onClick={() => {
              return window.location.assign(`/stake/${currency}`);
            }}
            cta={intl.formatMessage({ defaultMessage: "Details" })}
          />
        )}
      </Button.Group>
    </React.Fragment>
  );
};

export default Success;
