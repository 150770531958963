import { ChangeEvent, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useController } from "react-hook-form";
import { MonthDayYearMask } from "@gemini-ui/components/DatePicker/utils";
import { getPlaceHolder } from "@gemini-ui/components/LegalIdEntry/utils/getLegalIdInputMask";
import { Form, Input, Select, Text, useForm } from "@gemini-ui/design-system";
import { FormRow } from "@gemini-ui/design-system/forms/Form";
import { useCardContext } from "@gemini-ui/pages/Credit/CreditApplication/CardContext";
import { SSNInput, SubmitButton } from "@gemini-ui/pages/Credit/CreditApplication/components";
import { FIELD_NAMES, FormValues, NEURO_IDS } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { useFormValidation } from "@gemini-ui/pages/Credit/CreditApplication/hooks";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/testIds";
import { objectToDropdownArray } from "@gemini-ui/pages/register/Register/utils";
import { LegalIdType } from "@gemini-ui/pages/register/Verify/types";
import US_States from "@gemini-ui/US_States";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface ManualEntryFormProps {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
}

export const ManualEntryForm = ({ initialValues, onSubmit, isSubmitting }: ManualEntryFormProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const { handleSubmit, register, trigger, control, formState, setValue } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: initialValues,
  });
  const { errors, isValid, touchedFields } = formState;
  const validation = useFormValidation();
  const { field: stateSelect } = useController({
    name: FIELD_NAMES.state,
    control,
    rules: validation.state,
  });
  const stateItems = objectToDropdownArray(US_States.stateMenu);
  const registerFirstName = register(FIELD_NAMES.firstName);
  const registerLastName = register(FIELD_NAMES.lastName);

  const { cardName, setCardName } = useCardContext();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCardName({ ...cardName, [event.target.name]: event.target.value });

    if (event.target.name === FIELD_NAMES.firstName) registerFirstName.onChange(event);
    if (event.target.name === FIELD_NAMES.lastName) registerLastName.onChange(event);
  };

  const { socureId } = initialValues;
  useEffect(() => {
    if (socureId) {
      setValue(FIELD_NAMES.socureId, socureId);
    }
  }, [setValue, socureId]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ViewTitle mb={2}>
        <Text.Heading size="xs">{intl.formatMessage({ defaultMessage: "Full Legal Name" })}</Text.Heading>
        <Text.Body>
          {intl.formatMessage({ defaultMessage: "Enter your name as it appears on official documents" })}
        </Text.Body>
      </ViewTitle>
      <Input
        {...registerFirstName}
        data-testid={testIds.input.firstName}
        type="text"
        label={intl.formatMessage({ defaultMessage: "Legal first name" })}
        placeholder={intl.formatMessage({ defaultMessage: "William" })}
        error={touchedFields.firstName && errors?.firstName?.message}
        data-nid-target={NEURO_IDS.firstName}
        inputSize="lg"
        autoComplete="given-name"
        onChange={handleNameChange}
        readOnly
      />
      <Input
        {...register(FIELD_NAMES.middleName)}
        data-testid={testIds.input.middleName}
        type="text"
        label={intl.formatMessage({ defaultMessage: "Legal middle name (optional)" })}
        error={touchedFields.middleName && errors?.middleName?.message}
        inputSize="lg"
        autoComplete="additional-name"
      />
      <Input
        {...registerLastName}
        data-testid={testIds.input.lastName}
        type="text"
        label={intl.formatMessage({ defaultMessage: "Legal last name" })}
        placeholder={intl.formatMessage({ defaultMessage: "Smith" })}
        error={touchedFields.lastName && errors?.lastName?.message}
        data-nid-target={NEURO_IDS.lastName}
        inputSize="lg"
        autoComplete="family-name"
        onChange={handleNameChange}
        mb={4}
        readOnly
      />
      <ViewTitle mb={2}>
        <Text.Heading size="xs">{intl.formatMessage({ defaultMessage: "Primary Address" })}</Text.Heading>
        <Text.Body>{intl.formatMessage({ defaultMessage: "Enter the address of your primary residence" })}</Text.Body>
      </ViewTitle>
      <Input
        {...register(FIELD_NAMES.street, validation.street)}
        data-testid={testIds.input.addressStreet}
        type="text"
        label={intl.formatMessage({ defaultMessage: "Street address" })}
        placeholder={intl.formatMessage({ defaultMessage: "800 E. Main St." })}
        error={touchedFields.street && errors?.street?.message}
        data-nid-target={NEURO_IDS.address}
        inputSize="lg"
        autoComplete="address-line1"
      />
      <Input
        {...register(FIELD_NAMES.apt, validation.apt)}
        data-testid={testIds.input.addressApartment}
        type="text"
        label={intl.formatMessage({ defaultMessage: "Apt, Suite, Bldg (optional)" })}
        placeholder={intl.formatMessage({ defaultMessage: "501" })}
        error={touchedFields.apt && errors?.apt?.message}
        data-nid-target={NEURO_IDS.address}
        inputSize="lg"
        autoComplete="address-line2"
      />
      <Input
        {...register(FIELD_NAMES.city, validation.city)}
        data-testid={testIds.input.addressCity}
        type="text"
        label={intl.formatMessage({ defaultMessage: "City" })}
        placeholder={intl.formatMessage({ defaultMessage: "New York City" })}
        error={touchedFields.city && errors?.city?.message}
        data-nid-target={NEURO_IDS.city}
        inputSize="lg"
        autoComplete="address-level2"
      />
      <FormRow>
        <Select
          label={intl.formatMessage({ defaultMessage: "State" })}
          options={stateItems}
          data-testid={testIds.input.addressRegion}
          error={touchedFields.state && errors?.state?.message}
          size="lg"
          {...stateSelect}
        />
        <Input
          {...register(FIELD_NAMES.zip, { ...validation.zip, onChange: () => trigger(FIELD_NAMES.zip) })}
          data-testid={testIds.input.addressPostalCode}
          type="text"
          label={intl.formatMessage({ defaultMessage: "Zip" })}
          placeholder={intl.formatMessage({ defaultMessage: "55555" })}
          error={touchedFields.zip && errors?.zip?.message}
          data-nid-target={NEURO_IDS.zip}
          inputSize="lg"
          autoComplete="postal-code"
          mb={4}
        />
      </FormRow>
      <ViewTitle mb={2}>
        <Text.Heading size="xs">{intl.formatMessage({ defaultMessage: "Basic Identity Information" })}</Text.Heading>
        <Text.Body>
          {intl.formatMessage(
            defineMessage({ defaultMessage: "We are required to collect this information. {moreInfo}" }),
            {
              moreInfo: (
                <Text.Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gemini.com/legal/user-agreement#section-patriot-act-notification"
                  color={colorScheme.content.secondary}
                >
                  More info
                </Text.Link>
              ),
            }
          )}
        </Text.Body>
      </ViewTitle>
      <SSNInput<FormValues>
        name={FIELD_NAMES.ssn}
        control={control}
        rules={validation.ssn}
        initialType="password"
        data-testid={testIds.input.nationalIdNumber}
        label={intl.formatMessage({ defaultMessage: "Social Security Number" })}
        placeholder={getPlaceHolder(LegalIdType.SSN)}
        error={touchedFields.ssn && errors?.ssn?.message}
        inputSize="lg"
      />
      <Input
        {...register(FIELD_NAMES.dob, validation.dob)}
        mask={MonthDayYearMask}
        data-testid={testIds.input.dateOfBirth}
        label={intl.formatMessage({ defaultMessage: "Date of birth" })}
        placeholder={DateFormats.MonthDayYear.toLowerCase()}
        title={DateFormats.MonthDayYear.toLowerCase()}
        error={touchedFields.dob && errors?.dob?.message}
        data-nid-target={NEURO_IDS.dob}
        inputSize="lg"
        mb={3}
        autoComplete="bday"
      />
      <Text.Body mt={3} mb={1} color={colorScheme.content.secondary}>
        {intl.formatMessage({
          defaultMessage: `You are providing 'written instructions' to WebBank under the Fair Credit Reporting Act 
            authorizing WebBank to obtain information from your personal credit profile or other information from one 
            or more credit reporting agencies ("Consumer Report"). You authorize WebBank, Gemini Constellation, and 
            their agents to obtain your Consumer Report to validate your identity and to determine if you are prequalified 
            for credit options.`,
        })}
      </Text.Body>
      <Text.Body color={colorScheme.content.secondary}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "By submitting your info, you agree to our {link}. This will not affect your credit score.",
          }),
          {
            link: (
              <Text.Link
                href="https://www.gemini.com/legal/credit-report-authorization"
                target="_blank"
                color={colorScheme.content.secondary}
              >
                Credit Report Authorization
              </Text.Link>
            ),
          }
        )}
      </Text.Body>
      <SubmitButton type="submit" loading={isSubmitting} disabled={!isValid}>
        {intl.formatMessage({ defaultMessage: "Pre-qualify" })}
      </SubmitButton>
    </Form>
  );
};
