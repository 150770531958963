import { createContext, Fragment, MouseEvent, ReactNode, useContext, useState } from "react";
import { IconChevronDownSmall, IconChevronUpSmall, IconMoreHorizontal } from "@hubble/icons";
import Popover, { PopoverPlacement } from "@gemini-ui/components/Popover";
import { Button, ButtonProps } from "@gemini-ui/design-system/Button";
import { Flex } from "@gemini-ui/design-system/Flex";
import { MenuButtonWrapper, MenuContainer, MenuItem, MenuUL } from "@gemini-ui/design-system/Menu/styles";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";
import { useIntl } from "@gemini-ui/utils/intl";

interface MenuProps {
  ["data-testid"]: string;
  renderMenu: ReactNode;
  isEllipses?: boolean;
  menuText?: "Actions" | string;
  className?: string;
  hideOnMobile?: boolean;
  flush?: boolean;
  id?: string /* id must be passed to render portal with MenuContainer root */;
  children?: ReactNode;
  MenuButton?: typeof Button.Tertiary;
  size?: ButtonProps["size"];
  placement?: PopoverPlacement;
  stayOpen?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>, isOpening: boolean) => void;
}

const MenuContext = createContext({
  isOpen: false,
  toggleOpen: () => {},
});

/**
 * @deprecated Please use HubbleMenu instead.
 * const { HubbleMenu } from "@gemini-ui/design-system";
 */
const Menu = ({
  ["data-testid"]: dataTestId,
  renderMenu,
  isEllipses = false,
  menuText = "Actions",
  className,
  hideOnMobile,
  flush,
  id,
  children,
  MenuButton = Button.Tertiary,
  size,
  placement = "bottom-end",
  stayOpen,
  onClick,
}: MenuProps) => {
  const { intl } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (event?: MouseEvent<HTMLButtonElement>) => {
    // Only MenuButton clicks have event present. When MenuItem is clicked, event is undefined
    if (event) {
      event.stopPropagation();
      event.preventDefault();

      if (onClick) onClick(event, !isOpen);
    }

    if (isOpen && stayOpen) {
      return;
    }

    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <MenuContainer className={className} hideOnMobile={hideOnMobile} id={id}>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement={placement}
        renderComponent={
          <MenuContext.Provider value={{ toggleOpen: handleOpen, isOpen }}>{renderMenu}</MenuContext.Provider>
        }
        popoverStyle={{ marginTop: Spacing.scale[1] }}
        data-testid={dataTestId}
        portalRootSelector={id && `#${id}`}
      >
        <MenuButtonWrapper>
          <MenuButton
            flush={flush}
            data-testid={`${dataTestId}-toggle`}
            size={size}
            onClick={handleOpen}
            rightElement={!isEllipses && !children && (isOpen ? <IconChevronUpSmall /> : <IconChevronDownSmall />)}
            icon={isEllipses && <IconMoreHorizontal />}
            aria-label={isEllipses && intl.formatMessage({ defaultMessage: "Actions" })}
          >
            {!isEllipses && <Fragment>{Boolean(children) ? children : menuText}</Fragment>}
          </MenuButton>
        </MenuButtonWrapper>
      </Popover>
    </MenuContainer>
  );
};

Menu.UL = MenuUL;

interface MenuListItemProps {
  children: ReactNode;
  className?: string;
  ["data-testid"]?: string;
  disabled?: boolean;
  href?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  color?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
}

const ListItem = ({
  className,
  ["data-testid"]: dataTestId,
  disabled,
  href = "#",
  children,
  onClick,
  color = Colors.gray[800],
  iconLeft,
  iconRight,
}: MenuListItemProps) => {
  const { toggleOpen } = useContext(MenuContext);

  const handleClick = event => {
    event.stopPropagation();
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
    toggleOpen();
  };

  return (
    <li className={className}>
      <MenuItem data-testid={dataTestId} href={href} onClick={handleClick} disabled={disabled}>
        {Boolean(iconLeft) && (
          <Flex align="center" pr={1.5}>
            {iconLeft}
          </Flex>
        )}
        <Flex.Column flexGrow={1} textAlign="left">
          <Text.Body as="div" color={disabled ? Colors.gray[300] : color} size="sm" pt={1.5} pb={1.5}>
            {children}
          </Text.Body>
        </Flex.Column>
        {Boolean(iconRight) && (
          <Flex align="center" ml="auto" pl={1.5} textAlign="right">
            {iconRight}
          </Flex>
        )}
      </MenuItem>
    </li>
  );
};

Menu.LI = ListItem;

export { Menu };
