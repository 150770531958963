import { Fragment } from "react";
import { useEffectOnce } from "react-use";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Button, Text } from "@gemini-ui/design-system";
import { Modal, ModalProps } from "@gemini-ui/design-system/Modal";
import { stakingAssetDisclaimerMap } from "@gemini-ui/pages/Earn/Deposit/AssetDisclaimer/constants";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useCurrencyDetailsV2 } from "@gemini-ui/pages/RetailTrade/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  currency: CurrencyShortNameSupportedCryptos;
  onAccept?: () => void;
}

const AssetDisclaimer = ({ currency, onAccept }: Props) => {
  const { intl } = useIntl();

  const { currency: currencyDetails } = useCurrencyDetailsV2(currency);

  useEffectOnce(() => {
    track(EVENTS.VIEW_ASSET_INFORMATION_DISCLAIMER.name, {
      [EVENTS.VIEW_ASSET_INFORMATION_DISCLAIMER.properties.CURRENCY]: currency,
    });
  });

  return (
    <Fragment>
      <Text.Heading size="md">
        {intl.formatMessage(defineMessage({ defaultMessage: "Before you stake {currency}" }), {
          currency: currencyDetails.symbol,
        })}
      </Text.Heading>

      {stakingAssetDisclaimerMap?.[currency]}

      <Button.Group type="action">
        <Button.Primary
          data-testid={testIds.deposit.assetDisclaimer.confirmButton}
          onClick={() => {
            track(EVENTS.ACCEPT_ASSET_INFORMATION_DISCLAIMER.name, {
              [EVENTS.ACCEPT_ASSET_INFORMATION_DISCLAIMER.properties.CURRENCY]: currency,
            });

            onAccept?.();
          }}
          size="md"
          cta={intl.formatMessage({ defaultMessage: "Agree and continue" })}
        />
      </Button.Group>
    </Fragment>
  );
};

export default AssetDisclaimer;

export const StakingAssetDisclaimerModal = ({ currency, onAccept, ...modalProps }: Props & ModalProps) => {
  return (
    <Modal {...modalProps}>
      <AssetDisclaimer currency={currency} onAccept={onAccept} />
    </Modal>
  );
};
