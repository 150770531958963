export enum SENTRY_TAGS {
  Team = "team",
}
export enum SENTRY_TEAMS {
  CryptoBack = "cryptoback",
  Custody = "custody",
  Growth = "growth",
  InstitutionalStrategies = "institutional-strategies",
  Leaderboard = "leaderboard",
  Payments = "payments",
  Staking = "staking",
  TradingExperience = "trading-experience",
  Transfers = "transfers",
}
