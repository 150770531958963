import { Fragment, useState } from "react";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat, orderCurrencies } from "@gemini-common/scripts/constants/currencies";
import { Card, Flex, Spacer, Text } from "@gemini-ui/design-system";
import CryptoIcon from "@gemini-ui/images/icons/cdn/CryptoIcon";

type Props = {
  onSelect: (currency: CurrencyShortNameFiat) => void;
  defaultCurrency: CurrencyShortNameFiat;
  currencies: CurrencyShortNameFiat[];
};

const CurrencyOption = ({ onSelect, isSelected, currency }) => {
  return (
    <Card
      as="button"
      isRadio
      aria-checked={isSelected}
      mb={2}
      onClick={onSelect}
      padding="sm"
      data-testid={`${currency}-currency-option`}
    >
      <Flex alignItems="center">
        <Spacer mr={1.5}>
          <CryptoIcon symbol={currency} />
        </Spacer>
        <Text.Body mr={1.5} bold>
          {currency}
        </Text.Body>
        <Text.Body size="sm">{CURRENCIES_DETAIL[currency].name}</Text.Body>
      </Flex>
    </Card>
  );
};

const CurrencySelector = ({ defaultCurrency, currencies, onSelect }: Props) => {
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyShortNameFiat | null>(defaultCurrency);

  const handleSelectCurrency = currency => {
    onSelect(currency);
    setSelectedCurrency(currency);
  };
  return (
    <Fragment>
      {orderCurrencies(currencies, defaultCurrency).map(currency => (
        <CurrencyOption
          key={currency}
          onSelect={() => handleSelectCurrency(currency)}
          isSelected={currency === selectedCurrency}
          currency={currency}
        />
      ))}
    </Fragment>
  );
};

export default CurrencySelector;
