import { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";
import {
  IconArrowsCircle,
  IconArrowsHorizontal,
  IconBankOutlined,
  IconBox,
  IconClockOutlined,
  IconCreditCard,
  IconDocumentOutlined,
  IconKeyOutlined,
  IconNotificationOnOutlined,
  IconProfileCircleOutlined,
  IconProfileMultiple,
  IconProfileOutlined,
  IconReceipt,
  IconSendOutlined,
  IconShieldCheckOutlined,
  IconWalletOutlined,
  IconWarningOutlined,
} from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { userHasCustodyAccount } from "@gemini-ui/components/Header/navigation/utils";
import { ActiveSettingsTab } from "@gemini-ui/components/layouts/SettingsLayout";
import { COMMON_TRANSFER_ITEMS } from "@gemini-ui/components/Transfer/navigation/transferItems";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { FeatureFlags } from "@gemini-ui/constants/templateProps/featureFlags";
import { User } from "@gemini-ui/constants/templateProps/users";
import { getAccountData } from "@gemini-ui/pages/Credit/CreditDashboard/utils";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";
import { useEarnDistributionsFlags } from "@gemini-ui/pages/Earn/hooks/useEarnDistributionsFlags";
import { useIsQualifiedForCustodyBilling } from "@gemini-ui/pages/settings/Billing/utils";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";

export const HEADER_MENU = "Header menu";

export const SETTINGS_PAGE_NAME = {
  profile: "profile",
  accountLevel: "accountLevel",
  security: "security",
  notifications: "notifications",
  bank: "bank",
  whitelist: "whitelist",
  api: "api",
  applications: "applications",
  users: "users",
  newAccount: "newAccount",
  clearing: "clearing",
  settlement: "settlement",
  billing: "billing",
  documents: "documents",
  card: "card",
  transactionHistory: "transactionHistory",
  earnVote: "earnVote",
  earnDistributionSummary: "earnDistributionSummary",
};

export const getTransferMenuData = (intl: IntlShape) =>
  [
    {
      ...COMMON_TRANSFER_ITEMS["deposit-cash"],
      name: intl.formatMessage({
        defaultMessage: "Deposit cash",
      }),
      analyticsProperties: {
        [EVENTS.DEPOSIT_START.properties.INITIATED_FROM]: HEADER_MENU,
      },
    },
    {
      ...COMMON_TRANSFER_ITEMS["withdraw-cash"],
      name: intl.formatMessage({
        defaultMessage: "Withdraw cash",
      }),
      analyticsProperties: {
        [EVENTS.WITHDRAW_START.properties.INITIATED_FROM]: HEADER_MENU,
      },
    },
    {
      ...COMMON_TRANSFER_ITEMS["withdraw-crypto"],
      name: intl.formatMessage({
        defaultMessage: "Send crypto",
      }),
      icon: IconSendOutlined,
      analyticsProperties: {
        [EVENTS.DEPOSIT_START.properties.INITIATED_FROM]: HEADER_MENU,
      },
    },
    {
      ...COMMON_TRANSFER_ITEMS["deposit-crypto"],
      name: intl.formatMessage({
        defaultMessage: "Receive crypto",
      }),
      analyticsProperties: {
        [EVENTS.DEPOSIT_START.properties.INITIATED_FROM]: HEADER_MENU,
      },
    },
  ] as const;

const transferBetweenAccountsItem = (intl: IntlShape) => ({
  ...COMMON_TRANSFER_ITEMS["transfer-between"],
  name: intl.formatMessage({
    defaultMessage: "Move between accounts",
  }),
  analyticsProperties: {
    [EVENTS.TRANSFER_BETWEEN_START.properties.INITIATED_FROM]: HEADER_MENU,
  },
});

const derivativeDebitCardFundingItem = (intl: IntlShape) => ({
  ...COMMON_TRANSFER_ITEMS["derivative-debit-card-funding"],
  name: intl.formatMessage({
    defaultMessage: "Fund account",
  }),
  analyticsProperties: {
    [EVENTS.DERIVATIVE_DEBIT_CARD_FUNDING_START.properties.INITIATED_FROM]: HEADER_MENU,
  },
});

export const getOtherTransferMenuData = (moreThanOneAccount: boolean, intl: IntlShape) => {
  if (moreThanOneAccount) {
    if (optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING)) {
      return [transferBetweenAccountsItem(intl), derivativeDebitCardFundingItem(intl)];
    }
    return [transferBetweenAccountsItem(intl)];
  }

  return [];
};

const handleRewardClick = e => {
  track(EVENTS.REWARD_ENTRY_POINT.name, { [EVENTS.REWARD_ENTRY_POINT.properties.INITIATED_FROM]: "Mobile web header" });
};
export const getMyAccountMenuData = (
  intl: IntlShape,
  isQualifiedForBilling: boolean,
  isCustodyAccount: boolean,
  shouldDisplayEarnDistributionMaterials: boolean,
  isInstitutional: boolean
) => [
  {
    name: intl.formatMessage({
      defaultMessage: "Profile",
    }),
    url: jsRoutes.controllers.settings.ProfileSettingsController.get().url,
  },
  ...(shouldDisplayEarnDistributionMaterials
    ? [
        {
          name: intl.formatMessage({
            defaultMessage: "Earn distributions",
          }),
          url: jsRoutes.com.gemini.earn.controllers.webserver.EarnFinalDistributionController.getEarnFinalDistributionSummary()
            .url,
        },
      ]
    : []),
  {
    name: intl.formatMessage({
      defaultMessage: "Notifications",
    }),
    url: jsRoutes.controllers.settings.NotificationSettingsController.get().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Security",
    }),
    url: jsRoutes.controllers.settings.SecuritySettingsController.get().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Approved Addresses",
    }),
    url: jsRoutes.controllers.settings.WhitelistSettingsController.list().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Payment methods",
    }),
    url: jsRoutes.controllers.settings.BankSettingsController.get().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "API",
    }),
    url: jsRoutes.controllers.settings.ApiSettingsController.list().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Applications",
    }),
    url: jsRoutes.controllers.settings.SecuritySettingsController.applicationSettings().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "User Management",
    }),
    url: jsRoutes.controllers.settings.UserManagementController.list().url,
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Add New Account",
    }),
    url: jsRoutes.controllers.settings.NewAccountSettingsController.get().url,
  },
  ...(optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_REWARDS_REFERRALS_V2) &&
  !isCustodyAccount &&
  !isInstitutional
    ? [
        {
          name: intl.formatMessage({ defaultMessage: "Rewards" }),
          url: jsRoutes.com.gemini.web.server.onboarding.controllers.services.RewardsController.getRewardsPage().url,
          onClick: handleRewardClick,
        },
      ]
    : []),
  {
    name: intl.formatMessage({
      defaultMessage: "Balance & History",
    }),
    url: jsRoutes.controllers.retail.BalanceControllerV2.get().url,
  },

  ...(optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BILLING_SETTINGS_PAGE) && isQualifiedForBilling
    ? [
        {
          name: intl.formatMessage({
            defaultMessage: "Billing",
          }),
          url: jsRoutes.controllers.settings.BillingSettingsController.get().url,
        },
      ]
    : []),

  ...(optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DOCUMENT_SETTINGS_PAGE)
    ? [
        {
          name: intl.formatMessage({
            defaultMessage: "Statements and history",
          }),
          url: jsRoutes.controllers.settings.DocumentSettingsController.get().url,
        },
      ]
    : []),

  {
    name: intl.formatMessage({
      defaultMessage: "Support center",
    }),
    url: "https://gemini24.zendesk.com/hc/en-us",
    target: "_blank",
  },
  {
    name: intl.formatMessage({
      defaultMessage: "Legal",
    }),
    url: jsRoutes.controllers.settings.LegalSettingsController.get().url,
  },
];

interface SettingsPages {
  name: string;
  link: string;
  header: string;
  subHeader: string;
  icon: FunctionComponent<PropsWithChildren<unknown>>;
}

export const settingsData = (
  featureFlags: FeatureFlags,
  user: User,
  intl: IntlShape,
  isQualifiedForBilling: boolean,
  shouldDisplayEarnVotingMaterials: boolean,
  shouldDisplayEarnDistributionMaterials: boolean
): SettingsPages[] => {
  const taxUpdatesFeatureFlagEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_TAX_UPDATES);

  return [
    {
      name: SETTINGS_PAGE_NAME.profile,
      link: jsRoutes.controllers.settings.ProfileSettingsController.get().url,
      header: intl.formatMessage({
        defaultMessage: "Account",
      }),
      subHeader: intl.formatMessage({ defaultMessage: "Profile info, trading interface" }),
      icon: IconProfileCircleOutlined,
    },
    ...(shouldDisplayEarnVotingMaterials
      ? [
          {
            name: SETTINGS_PAGE_NAME.earnVote,
            link: jsRoutes.controllers.settings.EarnVotingInfoController.get().url,
            header: intl.formatMessage({
              defaultMessage: "Earn voting materials",
            }),
            subHeader: intl.formatMessage({ defaultMessage: "Earn voting materials, Kroll code" }),
            icon: IconDocumentOutlined,
          },
        ]
      : []),
    ...(shouldDisplayEarnDistributionMaterials
      ? [
          {
            name: SETTINGS_PAGE_NAME.earnDistributionSummary,
            link: jsRoutes.com.gemini.earn.controllers.webserver.EarnFinalDistributionController.getEarnFinalDistributionSummary()
              .url,
            header: intl.formatMessage({
              defaultMessage: "Earn distributions",
            }),
            subHeader: intl.formatMessage({ defaultMessage: "Earn distribution materials and information" }),
            icon: IconDocumentOutlined,
          },
        ]
      : []),
    {
      name: SETTINGS_PAGE_NAME.accountLevel,
      link: jsRoutes.controllers.settings.AccountLevelSettingsController.getAccountLevelPage().url,
      header: intl.formatMessage({ defaultMessage: "Account limits" }),
      subHeader: intl.formatMessage({ defaultMessage: "Funding and purchase limits" }),
      icon: IconWarningOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.security,
      link: jsRoutes.controllers.settings.SecuritySettingsController.get().url,
      header: intl.formatMessage({ defaultMessage: "Security" }),
      subHeader: intl.formatMessage({ defaultMessage: "Login, password, 2FA" }),
      icon: IconShieldCheckOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.notifications,
      link: jsRoutes.controllers.settings.NotificationSettingsController.get().url,
      header: intl.formatMessage({ defaultMessage: "Notifications" }),
      subHeader: intl.formatMessage({ defaultMessage: "Email and push notifications" }),
      icon: IconNotificationOnOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.bank,
      link: jsRoutes.controllers.settings.BankSettingsController.get().url,
      header: intl.formatMessage({ defaultMessage: "Payment methods" }),
      subHeader: intl.formatMessage(defineMessage({ defaultMessage: "{methods}" }), {
        methods: featureFlags.AddDebitCards
          ? intl.formatMessage(defineMessage({ defaultMessage: "Debit card, bank account" }))
          : intl.formatMessage(defineMessage({ defaultMessage: "Bank account" })),
      }),
      icon: IconBankOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.whitelist,
      link: jsRoutes.controllers.settings.WhitelistSettingsController.list().url,
      header: intl.formatMessage({ defaultMessage: "Approved addresses" }),
      subHeader: intl.formatMessage({ defaultMessage: "Manage crypto withdrawals" }),
      icon: IconWalletOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.api,
      link: jsRoutes.controllers.settings.ApiSettingsController.list().url,
      header: intl.formatMessage({ defaultMessage: "API" }),
      subHeader: intl.formatMessage({ defaultMessage: "Programmatic access" }),
      icon: IconKeyOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.applications,
      link: jsRoutes.controllers.settings.SecuritySettingsController.applicationSettings().url,
      header: intl.formatMessage({ defaultMessage: "Applications" }),
      subHeader: intl.formatMessage({ defaultMessage: "Manage connected apps" }),
      icon: IconBox,
    },
    {
      name: SETTINGS_PAGE_NAME.users,
      link: jsRoutes.controllers.settings.UserManagementController.list().url,
      header: intl.formatMessage({ defaultMessage: "Manage users" }),
      subHeader: intl.formatMessage({ defaultMessage: "Manage users, roles" }),
      icon: IconProfileOutlined,
    },
    {
      name: SETTINGS_PAGE_NAME.newAccount,
      link: jsRoutes.controllers.settings.NewAccountSettingsController.get().url,
      header: intl.formatMessage({ defaultMessage: "Manage accounts" }),
      subHeader: intl.formatMessage({ defaultMessage: "Exchange, custody accounts" }),
      icon: IconProfileMultiple,
    },

    ...(user.isInstitutional
      ? [
          {
            name: SETTINGS_PAGE_NAME.clearing,
            link: jsRoutes.controllers.settings.CounterpartySettingsController.list().url,
            header: intl.formatMessage({ defaultMessage: "Clearing", description: "Institutional settings menu item" }),
            subHeader: intl.formatMessage({ defaultMessage: "Counter parties aliases" }),
            icon: IconArrowsHorizontal,
          },
        ]
      : []),

    ...(featureFlags.SettlementEnabled &&
    featureFlags.SettlementSettings &&
    (!featureFlags.SettlementInstitutionalOnly || (featureFlags.SettlementInstitutionalOnly && user.isInstitutional))
      ? [
          {
            name: SETTINGS_PAGE_NAME.settlement,
            // TODO add the backend controller when created
            link: jsRoutes.controllers.settings.SettlementSettingsController.get().url,
            header: intl.formatMessage({ defaultMessage: "Settlement", description: "Settlement settings menu item" }),
            subHeader: intl.formatMessage({ defaultMessage: "Manage aliases and defaults" }),
            icon: IconArrowsCircle,
          },
        ]
      : []),

    ...(optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_BILLING_SETTINGS_PAGE) &&
    userHasCustodyAccount(user) &&
    isQualifiedForBilling
      ? [
          {
            name: SETTINGS_PAGE_NAME.billing,
            link: jsRoutes.controllers.settings.BillingSettingsController.get().url,
            header: intl.formatMessage({
              defaultMessage: "Billing",
            }),
            subHeader: intl.formatMessage({ defaultMessage: "Invoices, make payments" }),
            icon: IconReceipt,
          },
        ]
      : []),
    ...(taxUpdatesFeatureFlagEnabled
      ? [
          {
            name: SETTINGS_PAGE_NAME.transactionHistory,
            link: `${jsRoutes.controllers.settings.DocumentSettingsController.get().url}/transaction-history`,
            header: intl.formatMessage({ defaultMessage: "Transaction history" }),
            subHeader: intl.formatMessage({ defaultMessage: "Transactions" }),
            icon: IconClockOutlined,
          },
        ]
      : []),

    ...(optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_DOCUMENT_SETTINGS_PAGE)
      ? [
          {
            name: SETTINGS_PAGE_NAME.documents,
            link: jsRoutes.controllers.settings.DocumentSettingsController.get().url,
            header: taxUpdatesFeatureFlagEnabled
              ? intl.formatMessage({ defaultMessage: "Statements and taxes" })
              : intl.formatMessage({ defaultMessage: "Statements and history" }),
            subHeader: intl.formatMessage({ defaultMessage: "Tax documents, account statements" }),
            icon: IconDocumentOutlined,
          },
        ]
      : []),
  ];
};

export const useSettingsData = (featureFlags: FeatureFlags, user: User, activeSettingsPage: ActiveSettingsTab) => {
  const { intl, locale } = useIntl();
  const [settings, setSettings] = useState([]);
  const { isQualified, hasFetched } = useIsQualifiedForCustodyBilling(activeSettingsPage);
  const isQualifiedForBilling = hasFetched && isQualified;

  const { isEarnVotingMaterialsPageEnabled, isEarnDistributionsPageEnabled } = useEarnDistributionsFlags();

  useEffect(() => {
    const newSettingsData = settingsData(
      featureFlags,
      user,
      intl,
      isQualifiedForBilling,
      isEarnVotingMaterialsPageEnabled,
      isEarnDistributionsPageEnabled
    );
    setSettings(newSettingsData);
    if (!featureFlags.GeminiCreditCardEnabled) {
      return;
    }
    const getCreditAcountData = async () => {
      try {
        if (await getAccountData()) {
          const newSettings = [...newSettingsData];
          newSettings.splice(4, 0, {
            name: SETTINGS_PAGE_NAME.card,
            link: CC_PATH.SETTINGS,
            header: intl.formatMessage({ defaultMessage: "Card settings" }),
            subHeader: intl.formatMessage({ defaultMessage: "Manage your Gemini card" }),
            icon: IconCreditCard,
          });
          setSettings(newSettings);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    getCreditAcountData();
  }, [
    featureFlags,
    featureFlags.GeminiCreditCardEnabled,
    intl,
    locale,
    user,
    isQualifiedForBilling,
    isEarnVotingMaterialsPageEnabled,
    isEarnDistributionsPageEnabled,
  ]);

  return settings;
};
