import { Component, ReactNode } from "react";
import { debounce, intersection } from "lodash";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Button, Dropdown, Modal, Spacer, Text } from "@gemini-ui/design-system";
import { DefaultDropdownItemProps } from "@gemini-ui/design-system/Dropdown/constants";
import { getAddressComponents } from "@gemini-ui/pages/register/Verify/utils/usePlacesApi";
import { ToggleDebitCardModal } from "@gemini-ui/pages/settings/BankSettings/AddDebitCardFlow/constants";
import axios from "@gemini-ui/services/axios";
import { defineMessage, IntlShape, withIntl } from "@gemini-ui/utils/intl";

const MAX_RESULTS = 3;
const MIN_SEARCH_LENGTH = 3;
const ADDRESS_TYPE_BLOCKLIST = ["ESTABLISHMENT", "POINT_OF_INTEREST"];

interface AddBillingAddressModalProps {
  onToggle: ToggleDebitCardModal;
  updateBillingAddress: (BillingAddress) => void;
  intl: IntlShape;
}

interface LocationDropdownItem extends DefaultDropdownItemProps {
  addressComponents: AddressComponent[];
}

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

interface AddBillingAddressModalState {
  searchTerm: string;
  searchResults: object[];
  selectedValue: string | LocationDropdownItem;
  autocompleteErrorMsg: string;
}

const LOAD_OPTIONS_DEBOUNCE_MS = 200;
const CHECK_LOCATION_CONTROLLER_URL =
  jsRoutes.com.gemini.web.server.onboarding.controllers.services.CheckLocationController.locationDetails().url;

class AddBillingAddressModal extends Component<AddBillingAddressModalProps, AddBillingAddressModalState> {
  debouncedFetchAutocomplete: (searchTerm: string, callback: Function) => void;
  isNewDebitCardFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.DEBIT_CARD_FLOW_REVAMP);

  constructor(props: AddBillingAddressModalProps) {
    super(props);
    this.debouncedFetchAutocomplete = debounce(this.fetchAutocomplete.bind(this), LOAD_OPTIONS_DEBOUNCE_MS);
  }

  state = {
    searchTerm: "",
    searchResults: [],
    selectedValue: null,
    autocompleteErrorMsg: null,
  };

  fetchAutocomplete = (searchTerm, callback) => {
    axios
      .post(CHECK_LOCATION_CONTROLLER_URL, {
        address: searchTerm,
      })
      .then(res => {
        const filteredResults = res.data.results.filter(result => {
          return intersection(result.types, ADDRESS_TYPE_BLOCKLIST).length === 0;
        });
        const formattedResults = filteredResults.slice(0, MAX_RESULTS).map(result => ({
          label: result.formatted_address,
          value: result.formatted_address,
          addressComponents: result.address_components,
          icon: "",
        }));
        this.setState({ searchResults: formattedResults, autocompleteErrorMsg: null }, () => {
          callback(formattedResults);
        });
      })
      .catch(err => {
        const { intl } = this.props;
        this.setState({
          autocompleteErrorMsg: intl.formatMessage({
            defaultMessage:
              "We're having trouble finding your address.  Please try entering it manually by clicking the link below.",
          }),
        });
      });
  };

  handleLoadOptions = (searchTerm, callback) => {
    this.setState({ searchTerm });

    if (searchTerm.length <= MIN_SEARCH_LENGTH) {
      callback([]);
      return;
    }

    this.debouncedFetchAutocomplete(searchTerm, callback);
  };

  handleAddAddressClick = e => {
    e.preventDefault();
    this.props.onToggle("selectCountryVisible")();
  };

  handleChange = (newValue: string | LocationDropdownItem) => {
    const components = typeof newValue !== "string" && newValue.addressComponents;

    const { address1, address2, city, state, zip, country } = getAddressComponents(components);

    this.props.updateBillingAddress({ address1, address2, city, state, zip, country });

    this.setState({ selectedValue: newValue, searchTerm: "" });
  };

  render() {
    const { onToggle, intl } = this.props;
    const { selectedValue, searchTerm, autocompleteErrorMsg } = this.state;
    return (
      <Modal.MultiStep
        isOpen
        title={intl.formatMessage({
          defaultMessage: "Add billing address",
        })}
        onClose={onToggle("addBillingAddressVisible", true)}
        onBack={onToggle("selectDebitCurrencyVisible")}
        hasDropdown
      >
        <Text.Body size="sm">
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "Enter your billing address below or <TextLink>add it manually</TextLink>.",
            }),
            {
              TextLink: (v: ReactNode) => (
                <Text.Link href="#" onClick={this.handleAddAddressClick}>
                  {v}
                </Text.Link>
              ),
            }
          )}
        </Text.Body>
        <Spacer mt={5}>
          <Dropdown
            name="billing-address"
            data-testid="add-billing-address-modal-input"
            label={intl.formatMessage({
              defaultMessage: "Billing Address",
            })}
            // @ts-expect-error
            onChange={this.handleChange}
            loadOptions={this.handleLoadOptions}
            value={selectedValue}
            placeholder=""
            menuIsOpen={searchTerm.length > MIN_SEARCH_LENGTH}
            error={autocompleteErrorMsg}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <Button.Group>
            <Button.Primary
              data-testid="add-billing-address-modal-btn"
              disabled={!selectedValue}
              onClick={onToggle("addBillingAddressManualVisible")}
              type="submit"
              cta={intl.formatMessage({
                defaultMessage: "Next",
              })}
              size="lg"
            />
          </Button.Group>
        </Spacer>
      </Modal.MultiStep>
    );
  }
}

export default withIntl(AddBillingAddressModal);
