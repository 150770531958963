import { Fragment, ReactNode, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useEffectOnce } from "react-use";
import { CURRENCIES_DETAIL } from "@gemini-common/scripts/constants/currencies";
import { Badge, Card, List, ListItem, SkeletonLoader, Text } from "@gemini-ui/design-system";
import { StakingQueueResponse } from "@gemini-ui/pages/Earn/Deposit/types";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const fetchActivationDaysPlaceholder = (): Promise<StakingQueueResponse> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        activationDays: 10,
        exitDays: 10,
      });
    }, 5000);
  });
};

const StakingEthDisclaimer = () => {
  const { intl } = useIntl();
  const { isEthAssetActivationQueueDisplayEnabled } = useGrowFeatureFlags();

  const [loading, setLoading] = useState(false);
  const [activationDays, setActivationDays] = useState<number>(null);

  useEffectOnce(() => {
    let isMounted = true;

    const getActivationDays = async () => {
      try {
        setLoading(true);

        // TODO: This is just a placeholder
        const { activationDays } = await fetchActivationDaysPlaceholder();
        if (isMounted) setActivationDays(activationDays);
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    if (isEthAssetActivationQueueDisplayEnabled) getActivationDays();

    return () => {
      isMounted = false;
    };
  });

  return (
    <Fragment>
      {isEthAssetActivationQueueDisplayEnabled && (
        <Card padding="none" variant="filled">
          <List>
            <ListItem
              padding="sm"
              right={
                loading ? (
                  <SkeletonLoader width="62px" height="24px" radiusSize="sm" />
                ) : (
                  <Badge status="neutral">
                    {intl.formatMessage(
                      defineMessage({ defaultMessage: "{days, plural, one {{days} day} other {{days} days}}" }),
                      { days: activationDays }
                    )}
                  </Badge>
                )
              }
            >
              <Text.Body size="md" bold>
                {intl.formatMessage({ defaultMessage: "Est. activation time" })}
              </Text.Body>
            </ListItem>
          </List>
        </Card>
      )}

      <Text.Body size="md">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Please note that we process staking requests in groups of <b>32 ETH</b>. We require a minimum daily request of 32 ETH before proceeding. Once the balance of staking requests reaches 32 ETH, we will move it on-chain to begin the staking process and start earning rewards.<br></br><br></br>Your staked ETH will be included in the overall balance, but you will not receive rewards until it is activated on-chain.",
          }),
          {
            b: (v: ReactNode) => <b>{v}</b>,
            br: () => <br />,
          }
        )}
      </Text.Body>
    </Fragment>
  );
};

export const stakingAssetDisclaimerMap = {
  [CURRENCIES_DETAIL.ETH.symbol]: <StakingEthDisclaimer />,
};

export const stakingAssetsWithDisclaimers = Object.keys(stakingAssetDisclaimerMap);
