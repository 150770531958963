import { Fragment } from "react";
import { useTheme } from "@emotion/react";
import { IconCheck } from "@hubble/icons";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { Money } from "@gemini-ui/components/Money";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Badge, Flex, ListItem, Spacing, Text } from "@gemini-ui/design-system";
import { getAvailableForWithdrawal } from "@gemini-ui/pages/transfers/utils";
import { useIntl } from "@gemini-ui/utils/intl";

interface AccountListProps {
  accounts: Subaccount[];
  heading: string;
  selectedAsset?: CurrencyShortName;
  sourceAccountHash: string;
  onAccountClick: (account: Subaccount) => void;
  showBadge?: boolean;
}

const AccountList = ({
  accounts,
  heading,
  selectedAsset,
  sourceAccountHash,
  onAccountClick,
  showBadge,
}: AccountListProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <Fragment>
      <Text.Heading mt={3} mb={1} size="sm">
        {heading}
      </Text.Heading>
      {accounts?.length > 0 ? (
        accounts.map(account => (
          <ListItem
            aria-disabled={account.hashid === sourceAccountHash}
            style={{ cursor: account.hashid !== sourceAccountHash && "pointer" }}
            key={account.hashid}
            left={
              <Flex alignItems="center">
                <Text.Body
                  color={
                    account.hashid === sourceAccountHash ? colorScheme.content.secondary : colorScheme.content.primary
                  }
                  bold
                  size="sm"
                >
                  {account?.name}
                </Text.Body>
                {account.hashid === sourceAccountHash && showBadge && (
                  <Badge size="sm" status="neutral" ml={1.5}>
                    {intl.formatMessage({ defaultMessage: "From" })}
                  </Badge>
                )}
              </Flex>
            }
            padding="sm"
            onClick={() => account.hashid !== sourceAccountHash && onAccountClick(account)}
            right={
              selectedAsset && (
                <Flex gap={Spacing.scale["0.5"]} align="center">
                  <Text.Body
                    size="sm"
                    color={
                      account.hashid === sourceAccountHash ? colorScheme.content.secondary : colorScheme.content.primary
                    }
                  >
                    <Money currency={selectedAsset} value={getAvailableForWithdrawal(account, selectedAsset)} />
                  </Text.Body>
                  {account.hashid === sourceAccountHash && <IconCheck data-testid="selected-account" />}
                </Flex>
              )
            }
          />
        ))
      ) : (
        <Flex.Column mt={2} ml={3}>
          <Text.Body size="sm" bold color={colorScheme.content.primary}>
            {intl.formatMessage({ defaultMessage: "No results found" })}
          </Text.Body>
        </Flex.Column>
      )}
    </Fragment>
  );
};

export default AccountList;
