import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { usePageRefresh } from "@gemini-ui/contexts";
import { PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import axios from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

type TimeFrame = "1m" | "5m" | "15m" | "30m" | "1hr" | "6hr" | "1day";

export const getAllPairDetails = async (timeFrame: TimeFrame = "1m", defaultFiat: CurrencyShortNameFiat) => {
  try {
    const response = await axios.get<PairDetail[]>(
      `${jsRoutes.controllers.mobile.AssetDetailController.getAllPairsDetails(timeFrame, defaultFiat).url}&skip=6`,
      { retry: 3 }
    );

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const useAllPairDetails = (timeFrame: TimeFrame = "1m", defaultFiat: CurrencyShortNameFiat) => {
  const { refreshCount } = usePageRefresh();
  const [isLoadingPairDetails, setIsLoadingPairDetails] = useState(true);
  const [pairDetailsError, setPairDetailsError] = useState<string>(null);
  const [allPairDetails, setAllPairDetails] = useState<PairDetail[]>([]);

  const fetchAllPairDetails = useCallback(() => {
    getAllPairDetails(timeFrame, defaultFiat)
      .then(data => {
        if (data) {
          setAllPairDetails(data);
          setPairDetailsError(null);
        }
        setIsLoadingPairDetails(false);
      })
      .catch(error => {
        setIsLoadingPairDetails(false);
        setPairDetailsError(getError(error));
      });
  }, [timeFrame, defaultFiat]);

  useEffect(() => {
    fetchAllPairDetails();
  }, [fetchAllPairDetails, refreshCount]);

  return {
    allPairDetails,
    pairDetailsError,
    isLoadingPairDetails,
    fetchAllPairDetails,
  };
};
