import { IconCheck, IconChevronDownSmall } from "@hubble/icons";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { border, Button, Flex, HubbleMenu as Menu, Spacing } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const ProviderTypeSelector = ({
  options,
  selected,
  onChange,
}: {
  options: SelectOptionProps<GrowProviderType>[];
  selected: GrowProviderType;
  onChange: (providerType: GrowProviderType) => void;
}) => {
  const { intl } = useIntl();

  const label = (providerType: GrowProviderType) =>
    intl.formatMessage(
      defineMessage({
        defaultMessage: "{providerType, select, PrivateStaking {Solo} Staking {Shared} other {Type}}",
      }),
      { providerType }
    );

  if (!(options.length > 0)) return null;

  return (
    <Menu>
      <Menu.Trigger>
        <Button.Tertiary
          disabled={options.length < 2}
          css={{
            borderRadius: border.radius.md,
            paddingRight: Spacing.scale[2],
            paddingLeft: Spacing.scale[2],
          }}
        >
          {label(selected)}
          <IconChevronDownSmall size="sm" css={{ marginLeft: Spacing.scale[1] }} />
        </Button.Tertiary>
      </Menu.Trigger>
      <Menu.Content width="379" align="end">
        {options.map((option, idx) => (
          <Menu.Item
            key={`${option.value}-${idx}`}
            label={option.label as string}
            description={option.subLabel as string}
            rightElement={
              option.value === selected && (
                <Flex ml={2}>
                  <IconCheck size="sm" />
                </Flex>
              )
            }
            onClick={() => onChange(option.value)}
          />
        ))}
      </Menu.Content>
    </Menu>
  );
};
