import { Fragment } from "react";
import { countriesList } from "@gemini-common/scripts/constants/Countries";
import { State } from "@gemini-ui/components/TransferControl/reducer";
import { OwnerType, SenderType } from "@gemini-ui/components/TransferControl/types";
import { usePageData } from "@gemini-ui/contexts";
import { Card, List, ListItem, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  dateReceived: string;
  state: State;
}

function ConfirmMessage({ dateReceived, state }: Props) {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();

  const {
    senderType,
    ownerFirstName,
    ownerLastName,
    ownerName,
    ownerCountryCode,
    ownerType,
    ownerAddressLine1,
    ownerAddressLine2,
    ownerCity,
    ownerPostalCode,
  } = state;
  const { transferControl } = testIds;

  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);
  const isIndividual = ownerType === OwnerType.INDIVIDUAL;
  const senderName = isUkTravelRuleEnabled && isIndividual ? `${ownerFirstName} ${ownerLastName}` : ownerName;
  const hasAddress =
    Boolean(ownerAddressLine1) || Boolean(ownerAddressLine2) || Boolean(ownerCity) || Boolean(ownerPostalCode);

  let fromSender = "";

  switch (senderType) {
    case SenderType.MY_SELF:
      fromSender = intl.formatMessage({ defaultMessage: "Myself" });
      break;
    case SenderType.SOMEONE_ELSE:
      fromSender = ownerType.toLocaleLowerCase();
      break;
    case SenderType.NOT_RECOGNIZED:
      fromSender = intl.formatMessage({ defaultMessage: "Unknown" });
      break;
  }

  return (
    <Card variant="filled" padding="none" style={{ width: "100%" }} mt={2}>
      <List>
        <ListItem
          padding="sm"
          right={
            <Text.Body css={{ textTransform: "capitalize" }} data-testid={transferControl.confirmMessageFromSender}>
              {fromSender}
            </Text.Body>
          }
        >
          <Text.Body>{intl.formatMessage({ defaultMessage: "From" })}</Text.Body>
        </ListItem>

        {![SenderType.MY_SELF, SenderType.NOT_RECOGNIZED].includes(senderType) && (
          <Fragment>
            <ListItem
              padding="sm"
              right={<Text.Body data-testid={transferControl.confirmMessageOwnerName}>{senderName}</Text.Body>}
            >
              <Text.Body>{intl.formatMessage({ defaultMessage: "Name" })}</Text.Body>
            </ListItem>
            <ListItem
              padding="sm"
              right={
                <Text.Body data-testid={transferControl.confirmMessageOwnerCountry}>
                  {countriesList[ownerCountryCode]}
                </Text.Body>
              }
            >
              <Text.Body>{intl.formatMessage({ defaultMessage: "Country of residence/incorporation" })}</Text.Body>
            </ListItem>

            {isUkTravelRuleEnabled && hasAddress && (
              <ListItem
                padding="sm"
                right={
                  <div>
                    {ownerAddressLine1 && (
                      <Text.Body data-testid={transferControl.confirmMessageOwnerAddressLine1}>
                        {ownerAddressLine1}
                      </Text.Body>
                    )}
                    {ownerAddressLine2 && (
                      <Text.Body data-testid={transferControl.confirmMessageOwnerAddressLine2}>
                        {ownerAddressLine2}
                      </Text.Body>
                    )}
                    {(ownerCity || ownerPostalCode) && (
                      <Text.Body data-testid={transferControl.confirmMessageOwnerAddressLine3}>
                        {ownerCity}
                        {ownerCity && ownerPostalCode && ", "}
                        {ownerPostalCode}
                      </Text.Body>
                    )}
                  </div>
                }
              >
                <Text.Body>{intl.formatMessage({ defaultMessage: "Address" })}</Text.Body>
              </ListItem>
            )}
          </Fragment>
        )}

        <ListItem
          padding="sm"
          right={<Text.Body data-testid={transferControl.confirmMessageDate}>{dateReceived}</Text.Body>}
        >
          <Text.Body>{intl.formatMessage({ defaultMessage: "Date" })}</Text.Body>
        </ListItem>
      </List>
    </Card>
  );
}

export default ConfirmMessage;
