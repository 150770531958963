/*
Loaded from webpack.config.js as an "entry point" on each page.  In practice, along with renderer-loader.js, this
makes each page view run something like this:

require(prerender.js);
page = require(ThisPage.js);
require(renderer.js).render(page);

Thus, this file gets loaded before the any other JS.
*/
// eslint-disable-next-line import/no-commonjs
const debugTool = require("expose-loader?debugTool!debug"); // expose debug on the global scope
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import * as Sentry from "@sentry/browser";
import { setupTransformedCurrencyData } from "@gemini-common/scripts/utils/transformCurrencyData";
import {
  initializeBraze,
  initializeDataDogRUM,
  initializeGoogleAnalytics,
  initializeMixpanel,
  initializeOptimizely,
  initializeSift,
} from "@gemini-ui/analytics";
import { initializeDataDogLogging } from "@gemini-ui/analytics/datadog";
import { DataDogCredentials } from "@gemini-ui/constants/initialData/datadog";
import { setupSentry } from "@gemini-ui/sentry/setup";
import axios from "@gemini-ui/services/axios";
import { getIsLoggedInCcpaEnabled, isAnalyticsSuppressed } from "@gemini-ui/utils/ccpa";
import { consoleLogRecruitmentRocket } from "@gemini-ui/utils/consoleRocket";
import { getCcpaConfigForLoggedOut, getCookieSettings } from "@gemini-ui/utils/cookie";

const initializeGATracking = async () => {
  try {
    let isSuppressed = getCookieSettings().isSuppressed || false;
    if (!isSuppressed) {
      const route =
        jsRoutes.com.gemini.web.server.onboarding.controllers.services.MarketingLocationController.checkLocation().url;
      const response = await axios.get(route);
      isSuppressed = isAnalyticsSuppressed(response.data?.state);
    }
    // downstream components can leverage cookie rather than continuing to hit the api

    if (getCookieSettings().allowAnalytics && !isSuppressed) {
      const isLoggedInCcpaEnabled = await getIsLoggedInCcpaEnabled(window, jsRoutes);

      const isCcpaEnabled = isLoggedInCcpaEnabled || getCcpaConfigForLoggedOut();
      if (!isCcpaEnabled) {
        initializeGoogleAnalytics();
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

try {
  const initialData = JSON.parse(document.getElementById("initialData").textContent);
  const optimizelyElement = document.getElementById("optimizelyDatafile");

  setupSentry(initialData.templateProps?.user?.email);
  setupTransformedCurrencyData();

  const optimizelyData =
    optimizelyElement?.textContent.trim() && // if optimizely is down, this element comes back empty with whitespace and JSON.parse breaks
    JSON.parse(optimizelyElement.textContent);

  // going to be read further in renderer.js
  window.initialData = initialData;

  window.optimizelyData = optimizelyData;

  window.__DEV__ = __DEV__;

  if (__DEV__) {
    debugTool.enable("*,-sockjs*,-Dispatchr*,-FormStore*,-OrderBook*,-gemini:Analytics");
    loadDevMessages();
    loadErrorMessages();
  } else {
    debugTool.disable();
  }

  const user = initialData.templateProps.user;
  const dataDogCredentials: DataDogCredentials = initialData.datadog;
  initializeOptimizely(optimizelyData, {
    user: user,
    isGeminiEmployee: initialData.templateProps.isEmployee,
    geminiEntity: initialData.templateProps?.account?.geminiEntity,
  });
  initializeGATracking();
  if (getCookieSettings().allowAnalytics) {
    initializeDataDogRUM(dataDogCredentials, user);
    initializeMixpanel(initialData.mixpanel.token);
    initializeBraze(user, initialData.braze);
  }

  initializeDataDogLogging(dataDogCredentials, user);
  initializeSift(initialData);
  consoleLogRecruitmentRocket(initialData.mode);
  require("@gemini-ui/canary"); // detect phishing sites who might use our JS code against us
} catch (initError) {
  Sentry.captureException(initError);
  console.error(initError);
  require("@gemini-ui/renderer").oops();
}
