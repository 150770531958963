import { Fragment } from "react";
import { ClassNames } from "@emotion/react";
import { IconHelpOutlined, IconInfoOutlined } from "@hubble/icons";
import { HubbleProvider } from "@hubble/web";
import RCTooltip from "rc-tooltip";
import { CLS_PREFIX, OFFSETS, TooltipProps, TooltipWithIconProps } from "@gemini-ui/design-system/Tooltip/constants";
import {
  getStyles,
  IconContainer,
  TooltipChildrenContainer,
  TooltipText,
} from "@gemini-ui/design-system/Tooltip/styles";

const Tooltip = (props: TooltipProps) => {
  const {
    ["data-testid"]: testID,
    align,
    children,
    containerTestId,
    defaultVisible,
    disabled,
    overlayInnerStyle,
    childrenStyle = {},
    maxWidth = 250,
    width,
    mouseEnterDelay = 0.2,
    onVisibleChange,
    overlay,
    overlayClassName,
    placement = "top",
    textAlign = "start",
    trigger,
    visible,
    zIndex = 10000,
    showArrow,
  } = props;
  if (disabled) return <Fragment>{children}</Fragment>;

  // The existence of these prop keys sets RCTooltip to behave a "controlled" tooltip.
  const visibilityProps: { visible?: boolean; defaultVisible?: boolean } = {};

  if (typeof visible === "boolean") {
    visibilityProps.visible = visible;
  }

  if (typeof defaultVisible === "boolean") {
    visibilityProps.defaultVisible = defaultVisible;
  }

  return (
    <ClassNames>
      {({ css, cx, theme }) => (
        <RCTooltip
          align={{ ...align, offset: align?.offset || OFFSETS[placement] }}
          destroyTooltipOnHide={{ keepParent: false }}
          mouseEnterDelay={mouseEnterDelay}
          onVisibleChange={onVisibleChange}
          overlay={typeof overlay === "string" ? overlay : <HubbleProvider scheme="dark">{overlay}</HubbleProvider>}
          overlayClassName={cx(overlayClassName, [
            css(getStyles({ theme, textAlign, maxWidth, width }), { label: CLS_PREFIX }),
          ])}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement}
          prefixCls={CLS_PREFIX}
          showArrow={showArrow}
          trigger={trigger}
          zIndex={zIndex}
          {...visibilityProps}
        >
          <TooltipChildrenContainer className={css(childrenStyle)} data-testid={containerTestId || testID}>
            {children}
          </TooltipChildrenContainer>
        </RCTooltip>
      )}
    </ClassNames>
  );
};

const TooltipWithIcon = ({ children, disabled, icon, inline, ...rest }: TooltipWithIconProps) => {
  if (disabled) return <Fragment>{children}</Fragment>;

  return (
    <IconContainer inline={inline}>
      {children && <TooltipText inline={inline}>{children}</TooltipText>}
      <Tooltip {...rest}>{icon}</Tooltip>
    </IconContainer>
  );
};

Tooltip.Info = (props: Omit<TooltipWithIconProps, "icon">) => (
  <TooltipWithIcon {...props} icon={<IconInfoOutlined size="xs" />} />
);
Tooltip.Question = (props: Omit<TooltipWithIconProps, "icon">) => (
  <TooltipWithIcon {...props} icon={<IconHelpOutlined size="xs" />} />
);

export default Tooltip;
