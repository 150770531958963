import { DragEvent, useState } from "react";
import { IconArrowOut } from "@hubble/icons";
import { DropzoneProps } from "@gemini-ui/design-system/FileUploader/constants";
import { getFileUploaderCopy } from "@gemini-ui/design-system/FileUploader/i18n";
import {
  DropzoneWrapper,
  FlexWrapper,
  SmallDeviceContent,
  WideDeviceContent,
} from "@gemini-ui/design-system/FileUploader/styles";
import { Flex } from "@gemini-ui/design-system/Flex";
import { useIntl } from "@gemini-ui/utils/intl";
const DROP_EFFECT: "none" | "copy" | "link" | "move" = "copy";
const EFFECT_ALLOWED:
  | "none"
  | "copy"
  | "copyLink"
  | "copyMove"
  | "link"
  | "linkMove"
  | "move"
  | "all"
  | "uninitialized" = "copyMove";
export const Dropzone = ({
  disabled,
  error,
  onClick,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
  onDragStart,
  ["data-testid"]: dataTestId,
}: DropzoneProps) => {
  const [dropDepth, setDropDepth] = useState(0);
  const { intl } = useIntl();
  const { BROWSE_FILES, DRAG_N_DROP } = getFileUploaderCopy(intl);

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = DROP_EFFECT;
    setDropDepth(dropDepth + 1);
    onDragEnter && onDragEnter(e);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDepth(dropDepth - 1);
    onDragLeave && onDragLeave(e);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDragOver && onDragOver(e);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDepth(0);
    onDrop && onDrop(e);
  };

  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.effectAllowed = EFFECT_ALLOWED;
    onDragStart && onDragStart(e);
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") onClick();
  };

  return (
    <DropzoneWrapper
      data-testid={dataTestId}
      disabled={disabled}
      error={error}
      onClick={!disabled ? onClick : null}
      onDragStart={!disabled ? handleDragStart : null}
      dropzone={dropDepth > 0}
      onDragEnter={!disabled ? handleDragEnter : null}
      onDragLeave={!disabled ? handleDragLeave : null}
      onDragOver={!disabled ? handleDragOver : null}
      onDrop={!disabled ? handleDrop : null}
      onKeyDown={!disabled ? handleKeyDown : null}
      tabIndex={!disabled ? 0 : null}
    >
      <FlexWrapper>
        <Flex alignItems="center" minWidth={0}>
          <IconArrowOut color={disabled ? "inherit" : undefined} />
        </Flex>
        <Flex flex="1">
          <SmallDeviceContent color={disabled && "inherit"}>{BROWSE_FILES}</SmallDeviceContent>
          <WideDeviceContent color={disabled && "inherit"}>{DRAG_N_DROP(disabled)}</WideDeviceContent>
        </Flex>
      </FlexWrapper>
    </DropzoneWrapper>
  );
};
