import { Fragment, useState } from "react";
import { CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { InitialWireFormType } from "@gemini-ui/constants/giact";
import { GeminiAccount, GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Flex, IllustrationWelcome, Modal } from "@gemini-ui/design-system";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import DefaultAddWireFlow from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/DefaultAddWireFlow";
import EnableWireModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/EnableWireModal";
import FedWireFlow from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/FedWireFlow";
import SelectCurrencyModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/SelectCurrencyModal";
import { isBankFrickEnabledForUK } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/utils/isBankFrickEnabledForUK";
import XfersFlow from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow";
import { XfersPagePropsType } from "@gemini-ui/transformers/PaymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  defaultFiat: GeminiAccount["defaultFiat"];
  onModalClose: () => void;
  isInstitutional: boolean;
  bankAccountUuid?: string;
  isInternational?: boolean;
  isModalOpen: boolean;
  setAjaxData: (data: any) => void;
  geminiEntity: GeminiEntity;
  initialWireFormValues?: InitialWireFormType;
  isSettingsOrOnboarding?: boolean;
  onBack?: () => void;
  xfers?: XfersPagePropsType;
  subaccountHashid: string;
  scope?: PaymentTypeScope;
}

enum ModalState {
  SELECT_CURRENCY,
  XFERS_FLOW,
  FED_WIRE_FLOW,
  DEFAULT_FLOW,
  ENABLE_WIRE_FROM_ACH,
  RTP_FLOW,
}

const getModalStateForSelectedCurrency = (currency: CurrencyShortNameFiat): ModalState => {
  const isRTPEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SGD_JPM_TRANSFERS_ENABLED);

  if (!isCurrency.SGD(currency)) return ModalState.DEFAULT_FLOW;

  if (isRTPEnabled) return ModalState.RTP_FLOW;

  return ModalState.XFERS_FLOW;
};

const getInitialModalState = (bankAccountUuid: string, currency: CurrencyShortNameFiat) => {
  const isNewPaymentMethodsFFEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  if (isCurrency.USD(currency) && !isBankFrickEnabledForUK(currency)) {
    track(EVENTS.START_MANUAL_BANK_INFO_ENTRY.name);
    return ModalState.FED_WIRE_FLOW;
  }
  if (bankAccountUuid) return ModalState.ENABLE_WIRE_FROM_ACH;

  if (isNewPaymentMethodsFFEnabled) return getModalStateForSelectedCurrency(currency);

  return ModalState.SELECT_CURRENCY;
};
const AddWireFundingSourceFlow = ({
  defaultFiat,
  onModalClose,
  bankAccountUuid,
  isInstitutional,
  isModalOpen,
  isInternational,
  setAjaxData,
  geminiEntity,
  initialWireFormValues,
  isSettingsOrOnboarding,
  onBack,
  xfers,
  subaccountHashid,
  scope,
}: Props) => {
  const isNewPaymentMethodsFFEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  const { currency, ...initialFormValues } = initialWireFormValues ?? {};
  const [modalState, setModalState] = useState<ModalState>(
    getInitialModalState(bankAccountUuid, isNewPaymentMethodsFFEnabled ? defaultFiat : currency)
  );
  const { requestRefresh } = usePageRefresh();
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyShortNameFiat | null>(
    isNewPaymentMethodsFFEnabled ? defaultFiat : currency
  );
  const { intl } = useIntl();

  const onSubmitCurrencySelect = (currency: CurrencyShortNameFiat) => {
    let newModalState = getModalStateForSelectedCurrency(currency);

    if (isCurrency.USD(currency) && !isBankFrickEnabledForUK(currency)) {
      newModalState = ModalState.FED_WIRE_FLOW;
    }

    setSelectedCurrency(currency);
    setModalState(newModalState);
    track(EVENTS.START_MANUAL_BANK_INFO_ENTRY.name);
  };

  const onEnableWireSubmit = data => {
    setAjaxData(data);
    requestRefresh();
  };

  if (modalState === ModalState.ENABLE_WIRE_FROM_ACH) {
    return (
      <EnableWireModal
        onSubmit={onEnableWireSubmit}
        isOpen={isModalOpen}
        isInternational={isInternational}
        onClose={onModalClose}
        bankAccountUuid={bankAccountUuid}
        subaccountHashid={subaccountHashid}
      />
    );
  }

  if (modalState === ModalState.SELECT_CURRENCY) {
    return (
      <SelectCurrencyModal
        onSubmit={onSubmitCurrencySelect}
        isOpen={isModalOpen}
        defaultFiat={selectedCurrency || defaultFiat}
        onClose={onModalClose}
        onBack={onBack}
      />
    );
  }
  if (modalState === ModalState.DEFAULT_FLOW) {
    return (
      <DefaultAddWireFlow
        geminiEntity={geminiEntity}
        isInstitutional={isInstitutional}
        isOpen={isModalOpen}
        currency={selectedCurrency}
        onBack={isNewPaymentMethodsFFEnabled ? onBack : () => setModalState(ModalState.SELECT_CURRENCY)}
        onClose={onModalClose}
        initialWireFormValues={initialFormValues}
        subaccountHashid={subaccountHashid}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
      />
    );
  }
  if (modalState === ModalState.FED_WIRE_FLOW) {
    return (
      <FedWireFlow
        geminiEntity={geminiEntity}
        currency={selectedCurrency}
        isOpen={isModalOpen}
        onBack={isNewPaymentMethodsFFEnabled ? onBack : () => setModalState(ModalState.SELECT_CURRENCY)}
        onClose={onModalClose}
        isInstitutional={isInstitutional}
        initialWireFormValues={initialFormValues}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        subaccountHashid={subaccountHashid}
        scope={scope}
      />
    );
  }
  if (modalState === ModalState.XFERS_FLOW) {
    return (
      <XfersFlow
        isOpen={isModalOpen}
        onClose={onModalClose}
        onBack={isNewPaymentMethodsFFEnabled ? onBack : () => setModalState(ModalState.SELECT_CURRENCY)}
        currency={selectedCurrency}
        xfers={xfers}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
        subaccountHashid={subaccountHashid}
      />
    );
  }
  if (modalState === ModalState.RTP_FLOW) {
    track(EVENTS.OPEN_SINGAPORE_FAST_BANK_REGISTRATION.name);
    return (
      <Fragment>
        <Modal
          title={intl.formatMessage({ defaultMessage: "Add bank account" })}
          isOpen={isModalOpen}
          onClose={onModalClose}
          headerImage={
            <Flex justify="center" mb={4}>
              <IllustrationWelcome />
            </Flex>
          }
          testId="plaid-link-error-modal"
        >
          {intl.formatMessage({
            defaultMessage:
              "Make a deposit to add your bank account as a payment method with your Gemini account. May take 1-2 business days.",
          })}
          <Button.Group>
            <Button.Tertiary data-testid="fast-cancel" onClick={onModalClose} size="md">
              {intl.formatMessage({
                defaultMessage: "Cancel",
              })}
            </Button.Tertiary>
            <Button.Primary
              type="submit"
              data-testid="fast-register-continue"
              onClick={() => {
                window.location.assign("/transfer/deposit/SGD");
              }}
              size="md"
            >
              {intl.formatMessage({
                defaultMessage: "Make a deposit",
              })}
            </Button.Primary>
          </Button.Group>
        </Modal>
      </Fragment>
    );
  }
};
export default AddWireFundingSourceFlow;
