import { useTheme } from "@emotion/react";
import { IconMarket } from "@hubble/icons";
import { NotificationProduct } from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import { Colors, getColor, IconBadge } from "@gemini-ui/design-system";

interface ProductIconBadgeProps {
  product: NotificationProduct;
}

export function ProductIconBadge({ product }: ProductIconBadgeProps) {
  const theme = useTheme();

  const getProductIcon = () => {
    switch (product) {
      case NotificationProduct.MarketAlerts:
        return IconMarket;
      case NotificationProduct.CustomPriceAlerts:
        return IconMarket;
      default:
        return IconMarket;
    }
  };

  const ProductIcon = getProductIcon();

  return <IconBadge backgroundColor={getColor(Colors.gray[100], Colors.gray[700])({ theme })} icon={<ProductIcon />} />;
}
