import { useAsyncFn } from "react-use";
import { applicationApi } from "@gemini-ui/client/api";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { getError } from "@gemini-ui/utils/error";

export const useSendEmail = () => {
  const { showAlert } = useAlert();

  return useAsyncFn(async () => {
    try {
      const { data } = await applicationApi.sendEmailVerificationTokenPrefill();
      return data;
    } catch (error) {
      showAlert({ type: AlertTypes.ERROR, message: getError(error) });
      return null;
    }
  });
};
