/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationWelcome = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M113.938 105.789C114.577 109.341 117.33 113.405 119.41 118.237C122.034 124.413 123.666 129.469 126.354 132.733C127.378 124.957 128.402 120.285 129.394 112.477L228.241 53.3086L113.938 105.789Z"
      fill={black}
    />
    <path
      d="M126.351 133.436C126.159 133.436 125.967 133.34 125.839 133.18C123.759 130.62 122.287 126.972 120.559 122.78C120.015 121.404 119.439 119.996 118.799 118.524C118.063 116.764 117.199 115.132 116.399 113.532C115.023 110.844 113.711 108.284 113.295 105.916C113.231 105.628 113.391 105.308 113.679 105.18L227.983 52.6997C228.303 52.5397 228.687 52.6677 228.847 52.9877C229.007 53.3077 228.911 53.6917 228.591 53.8837L130.031 112.892C129.519 116.636 129.039 119.74 128.527 122.716C128.015 125.788 127.503 128.924 126.991 132.828C126.959 133.084 126.767 133.308 126.511 133.372C126.447 133.404 126.383 133.436 126.351 133.436ZM114.703 106.172C115.183 108.188 116.303 110.396 117.583 112.924C118.415 114.524 119.247 116.188 120.015 117.98C120.655 119.484 121.231 120.892 121.775 122.268C123.215 125.82 124.399 128.732 125.871 131.004C126.319 127.836 126.735 125.148 127.183 122.492C127.695 119.42 128.207 116.284 128.719 112.38C128.751 112.188 128.847 111.996 129.039 111.9L217.423 58.9717L114.703 106.172Z"
      fill={contentPrimary}
    />
    <path
      d="M126.355 132.764C133.107 128.188 139.923 123.484 146.739 118.62C176.659 97.2439 203.763 75.2598 228.243 53.3398C195.283 72.0278 159.763 97.3399 129.395 109.404L126.355 132.764Z"
      fill={white}
    />
    <path
      d="M126.353 133.436C126.225 133.436 126.097 133.404 126.001 133.34C125.777 133.212 125.649 132.956 125.681 132.7L128.721 109.34C128.753 109.116 128.913 108.892 129.137 108.796C149.329 100.764 172.209 86.6204 194.353 72.9244C205.425 66.0764 216.913 58.9724 227.889 52.7644C228.177 52.6044 228.561 52.6684 228.753 52.9564C228.945 53.2444 228.913 53.6284 228.657 53.8524C202.737 77.0524 175.281 99.0364 147.089 119.196C140.401 123.964 133.521 128.732 126.705 133.34C126.609 133.372 126.481 133.436 126.353 133.436ZM130.001 109.884L127.185 131.388C133.617 127.036 140.049 122.556 146.321 118.076C172.753 99.2284 198.481 78.7484 222.929 57.1484C213.713 62.5244 204.241 68.3804 195.057 74.0764C173.009 87.6764 150.193 101.788 130.001 109.884Z"
      fill={contentPrimary}
    />
    <path
      d="M74.9297 98.4286C96.6897 90.9406 119.378 83.6126 142.93 76.5406C172.306 67.7406 200.786 60.0606 228.21 53.3086C190.098 70.8126 152.018 88.2846 113.906 105.789C108.242 105.149 103.89 104.285 97.6177 103.133C90.0977 101.789 79.3137 100.637 74.9297 98.4286Z"
      fill={white}
    />
    <path
      d="M113.937 106.461C113.905 106.461 113.873 106.461 113.873 106.461C108.945 105.885 105.009 105.181 99.9852 104.253C99.1852 104.093 98.3852 103.965 97.5532 103.805C95.5692 103.453 93.3932 103.101 91.1212 102.749C84.6572 101.725 78.0012 100.701 74.6732 99.0366C74.4172 98.9086 74.2892 98.6526 74.3212 98.3966C74.3532 98.1406 74.5132 97.8846 74.7692 97.8206C97.2012 90.1086 120.081 82.7486 142.801 75.9326C170.929 67.5166 199.633 59.6766 228.113 52.7006C228.433 52.6046 228.785 52.7966 228.913 53.1166C229.041 53.4366 228.881 53.7886 228.561 53.9486L114.257 106.429C114.129 106.461 114.033 106.461 113.937 106.461ZM76.8172 98.4926C80.3052 99.7086 85.8732 100.573 91.3132 101.437C93.6172 101.789 95.7932 102.141 97.7772 102.493C98.6092 102.653 99.4412 102.781 100.209 102.941C105.137 103.837 109.009 104.573 113.809 105.117L221.489 55.6766C195.281 62.2366 168.945 69.4686 143.121 77.1806C121.009 83.8366 98.7052 91.0046 76.8172 98.4926Z"
      fill={black}
    />
    <path
      d="M167.123 119.805C162.323 118.109 153.875 116.093 149.043 114.461C139.667 111.293 136.307 109.917 129.395 109.373C141.363 103.517 154.195 98.1406 166.131 90.3646C187.923 76.1566 209.17 65.6926 228.242 53.3086C217.458 66.8766 205.331 81.0526 191.667 95.4206C183.379 104.125 175.187 112.253 167.123 119.805Z"
      fill={white}
    />
    <path
      d="M167.122 120.476C167.058 120.476 166.962 120.476 166.898 120.444C164.37 119.548 160.754 118.524 157.234 117.564C154.066 116.7 151.09 115.868 148.818 115.1C147.762 114.748 146.802 114.428 145.874 114.108C138.674 111.644 135.378 110.524 129.298 110.044C129.01 110.012 128.754 109.788 128.69 109.5C128.626 109.212 128.786 108.924 129.042 108.764C131.922 107.356 135.001 105.335 137.913 103.959C147.525 98.6296 156.676 94.9695 165.7 89.1135C177.028 81.7535 187.556 75.5593 198.34 69.4153C208.452 63.6553 217.679 58.6449 227.794 52.7641C228.082 52.5721 228.565 52.5778 228.787 52.8772C229.042 53.2202 228.979 53.5221 228.787 53.7781C217.267 68.3061 204.85 82.4281 192.05 95.8681C184.146 104.188 175.89 112.412 167.506 120.284C167.474 120.412 167.314 120.476 167.122 120.476ZM131.794 108.956C136.626 109.532 139.986 110.684 146.322 112.828C147.218 113.148 148.21 113.468 149.234 113.82C151.474 114.588 154.418 115.388 157.586 116.252C160.946 117.18 164.402 118.14 166.962 119.036C175.218 111.26 183.346 103.164 191.154 94.9401C202.962 82.5241 214.418 69.4361 225.17 56.0281C217.01 61.1801 208.562 66.0121 199.666 71.0681C188.882 77.2121 177.746 83.5481 166.482 90.9081C157.394 96.8281 147.762 101.372 138.45 105.756C136.274 106.844 134.034 107.9 131.794 108.956Z"
      fill={black}
    />
    <path
      d="M84.1455 192.284C68.1135 192.284 56.9775 190.172 51.7295 186.044C49.5215 184.316 48.3695 182.236 48.3055 179.9C47.9535 166.3 67.2175 147.484 108.914 120.7C109.234 120.508 109.65 120.604 109.842 120.892C110.034 121.212 109.938 121.628 109.65 121.82C68.9455 147.964 49.2975 166.972 49.6495 179.868C49.7135 181.788 50.6735 183.516 52.5615 184.988C58.3855 189.532 72.5615 191.58 92.5295 190.748C114.61 189.852 143.474 185.532 175.986 178.268C176.338 178.172 176.69 178.428 176.786 178.78C176.882 179.132 176.626 179.484 176.274 179.58C143.698 186.876 114.77 191.196 92.5935 192.092C89.6495 192.22 86.8335 192.284 84.1455 192.284Z"
      fill={contentPrimary}
    />
    <path
      d="M76.5613 166.492C76.4013 166.492 76.2733 166.428 76.1453 166.332C75.8573 166.108 75.8253 165.692 76.0493 165.404C76.1773 165.244 89.0733 149.82 112.657 139.804C113.009 139.676 113.393 139.804 113.521 140.156C113.649 140.508 113.521 140.892 113.169 141.02C89.8733 150.908 77.2013 166.076 77.0733 166.236C76.9453 166.396 76.7533 166.492 76.5613 166.492Z"
      fill={contentPrimary}
    />
    <path
      d="M129.393 179.421C129.073 179.421 128.785 179.197 128.721 178.877C128.657 178.525 128.913 178.173 129.265 178.109C154.353 173.885 197.009 161.757 206.641 157.405C206.961 157.245 207.377 157.405 207.537 157.725C207.697 158.045 207.537 158.461 207.217 158.621C197.553 163.005 154.673 175.197 129.521 179.421C129.457 179.421 129.425 179.421 129.393 179.421Z"
      fill={contentPrimary}
    />
    <path
      d="M80.1459 205.628C76.3379 205.628 72.4659 205.564 68.6899 205.372C45.9379 204.22 33.5219 199.42 31.8579 191.068C31.3779 188.604 31.3779 180.668 37.2339 173.372C37.4579 173.084 37.8739 173.052 38.1619 173.276C38.4499 173.5 38.4819 173.916 38.2579 174.204C33.3299 180.348 32.4979 187.644 33.1379 190.812C34.6739 198.492 46.6419 202.94 68.7219 204.028C86.7379 204.924 106.322 203.324 115.762 202.556H115.922C116.274 202.524 116.626 202.812 116.626 203.164C116.658 203.516 116.37 203.868 116.018 203.868H115.858C108.434 204.508 94.5779 205.628 80.1459 205.628Z"
      fill={contentPrimary}
    />
    <path
      d="M37.9994 112.188C35.4714 104.54 33.1674 103.388 27.1514 100.86C26.7674 100.7 26.7994 100.156 27.1834 100.028C32.8474 98.2042 35.7274 96.4122 38.0314 89.0522C38.1594 88.6362 38.7674 88.6362 38.8954 89.0522C40.9754 96.2522 43.2154 98.7162 48.3354 100.124C48.7194 100.22 48.7834 100.764 48.4314 100.956C43.0874 103.772 40.9754 105.052 38.8954 112.188C38.7354 112.604 38.1594 112.604 37.9994 112.188Z"
      fill={contentPrimary}
    />
    <path
      d="M212.37 136.988C214.898 129.34 217.202 128.188 223.218 125.66C223.602 125.5 223.57 124.956 223.186 124.828C217.554 122.972 214.674 121.212 212.37 113.82C212.242 113.404 211.634 113.404 211.506 113.82C209.426 121.02 207.186 123.484 202.066 124.892C201.682 124.988 201.618 125.532 201.97 125.724C207.314 128.54 209.426 129.82 211.506 136.956C211.666 137.404 212.242 137.404 212.37 136.988Z"
      fill={contentPrimary}
    />
    <path
      d="M92.3034 61.5C91.0874 58.108 89.8714 57.34 87.1514 56.188C86.7674 56.028 86.7994 55.484 87.1834 55.356C89.7434 54.46 91.1834 53.468 92.3034 50.204C92.4314 49.82 93.0074 49.82 93.1354 50.236C94.1274 53.436 95.2794 54.716 97.5834 55.452C97.9354 55.58 97.9674 56.092 97.6474 56.252C95.2474 57.532 94.1274 58.332 93.1354 61.468C93.0394 61.884 92.4634 61.916 92.3034 61.5Z"
      fill={contentPrimary}
    />
  </Fragment>
));
IllustrationWelcome.displayName = "IllustrationWelcome";
