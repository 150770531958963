import { SectionMessage } from "@gemini-ui/design-system";
import { IntlShape } from "@gemini-ui/utils/intl";

const VerificationLockoutV2 = ({ intl }: { intl: IntlShape }) => {
  return (
    <SectionMessage
      heading={intl.formatMessage({
        defaultMessage: "Identity verification pending",
      })}
      mt={1}
      mb={1}
      statusType="warning"
    >
      {intl.formatMessage({
        defaultMessage: "Your identity needs to be verified before transferring funds",
      })}
    </SectionMessage>
  );
};

export default VerificationLockoutV2;
