import { Dispatch, Fragment, ReactNode } from "react";
import ConfirmAddress from "@gemini-ui/components/TransferControl/ConfirmOwnership/confirmAddress";
import ConfirmMessage from "@gemini-ui/components/TransferControl/ConfirmOwnership/confirmMessage";
import ConfirmSender from "@gemini-ui/components/TransferControl/ConfirmOwnership/confirmSender";
import { Action, State } from "@gemini-ui/components/TransferControl/reducer";
import { OwnershipStep, SenderType } from "@gemini-ui/components/TransferControl/types";
import { Flex, SectionMessage, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  state: State;
  dispatch: Dispatch<Action>;
  getIsConfirmSenderIsValid: (isValid: boolean) => void;
  dateReceived: string;
  isUkTravelRuleEnabled: boolean;
}

const { transferControl } = testIds;

function ConfirmOwnership({ state, dateReceived, getIsConfirmSenderIsValid, dispatch, isUkTravelRuleEnabled }: Props) {
  const { intl } = useIntl();

  const { senderType } = state;

  return (
    <Flex flexDirection="column">
      {senderType === SenderType.NOT_RECOGNIZED && (
        <SectionMessage
          statusType="warning"
          heading={intl.formatMessage({
            defaultMessage: "Warning",
          })}
          data-testid={transferControl.unknownSenderWarningContainer}
        >
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "<text1>By designating an unknown sender, funds in your account will be unavailable for withdrawal, pending review.</text1> <text2>If you are uncertain of the full identity of the sender, we strongly recommend canceling this entry and attempting to ascertain the sending wallet ownership information so that you can complete the attestation and avoid any delays in making withdrawals.</text2>",
            }),
            {
              text1: (v: ReactNode) => <Text.Body size="sm">{v}</Text.Body>,

              text2: (v: ReactNode) => (
                <Text.Body mt={2} size="sm">
                  {v}
                </Text.Body>
              ),
            }
          )}
        </SectionMessage>
      )}
      {state.confirmOwnershipStep !== OwnershipStep.ConfirmOwner ? (
        <Fragment>
          {isUkTravelRuleEnabled && state.confirmOwnershipStep === OwnershipStep.ConfirmAddress ? (
            <ConfirmAddress state={state} dispatch={dispatch} />
          ) : (
            <ConfirmMessage dateReceived={dateReceived} state={state} />
          )}
        </Fragment>
      ) : (
        <ConfirmSender
          state={state}
          getIsConfirmSenderIsValid={getIsConfirmSenderIsValid}
          dispatch={dispatch}
          isConfirmOwnershipValid={state.isConfirmOwnershipValid}
          dateReceived={dateReceived}
        />
      )}
    </Flex>
  );
}

export default ConfirmOwnership;
