import { forwardRef } from "react";
import { CSSObject, SerializedStyles } from "@emotion/react";
import { text } from "@gemini-ui/design-system/primitives";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import { BaseText } from "@gemini-ui/design-system/Text/styles";

type BodyTextSize = Extract<keyof (typeof text)["typesets"]["body"], "xl" | "lg" | "md" | "sm" | "xs">;

interface BodyProps extends BaseTextProps {
  size?: BodyTextSize;
  css?: SerializedStyles | CSSObject;
}

function getStyles(size: BodyTextSize = "md", numeric: boolean) {
  const tokens: {
    fontFamily: string;
    fontSize: string;
    lineHeight: string;
    letterSpacing?: string;
  } = numeric ? text.typesets.numeric.body[size] : text.typesets.body[size];

  if (!tokens) {
    throw new Error(`Unknown Text.Body size prop: ${size}`);
  }
  const { fontSize, lineHeight, letterSpacing } = tokens;

  return { fontSize, lineHeight, letterSpacing };
}

const BodyText = forwardRef<HTMLParagraphElement, BodyProps>(
  ({ numeric, size = "md", as = "p", ...restProps }: BodyProps, ref) => {
    return <BaseText ref={ref} as={as} {...getStyles(size, numeric)} numeric={numeric} {...restProps} />;
  }
);

export default BodyText;
