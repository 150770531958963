import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconCheckCircleFilled } from "@hubble/icons";
import {
  CheckProps,
  CircleProps,
  CircleVariation,
  CircularProgressProps,
  LoaderProps,
} from "@gemini-ui/design-system/FileUploader/constants";
import {
  AnimatedCircle,
  CircleProgressWrapper,
  InnerLoader,
  ToggleItem,
} from "@gemini-ui/design-system/FileUploader/styles";
import { Colors } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils";

const LOADER_DURATION = 100;
const CHECK_DURATION = 500;
const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(Number(percentage)) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : Number(percentage);
};

const CIRCLE_VARIATIONS: { [key: string]: CircleVariation } = {
  BASE: "base",
  SUCCESS: "success",
  LOADING: "loading",
};

const Circle = ({ size, variation, percentage = 100, ...props }: CircleProps) => {
  const r = size / 2;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <AnimatedCircle
      duration={LOADER_DURATION}
      r={r - 1}
      cx={size / 2}
      cy={size / 2}
      fill="transparent"
      variation={variation}
      strokeWidth="2px"
      strokeDasharray={circ}
      strokeDashoffset={strokePct}
    ></AnimatedCircle>
  );
};

const Loader = ({ size, percentage }: LoaderProps) => {
  return (
    <InnerLoader>
      <svg width={size} height={size}>
        <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
          <Circle size={size} variation={CIRCLE_VARIATIONS.BASE} />
          <Circle
            size={size}
            variation={percentage === 100 ? CIRCLE_VARIATIONS.SUCCESS : CIRCLE_VARIATIONS.LOADING}
            percentage={percentage}
          />
        </g>
      </svg>
    </InnerLoader>
  );
};

export const CircularProgress = ({ size = 20, percentage }: CircularProgressProps) => {
  const pct = cleanPercentage(percentage);
  return (
    <CircleProgressWrapper duration={CHECK_DURATION} size={size}>
      <Loader percentage={pct} size={size} />
      <Check percentage={pct} />
    </CircleProgressWrapper>
  );
};

const Check = ({ percentage }: CheckProps) => {
  const theme = useTheme();
  const [completed, setCompleted] = useState(percentage === 100);
  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        setCompleted(true);
      }, LOADER_DURATION);
    }
  }, [percentage]);
  return (
    <ToggleItem isHidden={!completed}>
      <IconCheckCircleFilled size="sm" color={getColor(Colors.green[600], Colors.green[300])({ theme })} />
    </ToggleItem>
  );
};
