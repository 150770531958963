/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationPassport = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M220.544 171.417C197.632 154.137 192.32 105.561 162.944 88.8887L126.176 115.289L163.872 162.489C168.256 165.913 173.664 170.777 178.848 177.433C183.328 183.161 186.432 188.697 188.608 193.241C191.328 193.369 200.032 193.369 208.544 187.417C216.608 181.721 219.616 174.073 220.544 171.417Z"
      fill={black}
    />
    <path
      d="M189.886 193.849C189.406 193.849 188.99 193.849 188.574 193.817C188.35 193.817 188.158 193.689 188.094 193.497C185.438 187.929 182.174 182.649 178.398 177.785C174.078 172.249 169.086 167.257 163.518 162.937C163.486 162.905 163.454 162.873 163.422 162.841L125.694 115.641C125.598 115.513 125.566 115.353 125.566 115.193C125.566 115.033 125.662 114.905 125.79 114.809L162.558 88.409C162.75 88.281 162.974 88.281 163.166 88.377C179.39 97.593 188.254 116.249 196.83 134.297C203.902 149.177 210.558 163.193 220.83 170.937C221.022 171.097 221.118 171.353 221.022 171.577C219.742 175.353 216.414 182.521 208.83 187.865C201.694 192.921 194.302 193.849 189.886 193.849ZM188.958 192.665C193.086 192.793 200.734 192.153 208.19 186.937C215.294 181.945 218.494 175.353 219.838 171.609C209.534 163.673 202.878 149.625 195.838 134.809C187.39 117.049 178.686 98.681 162.942 89.593L126.974 115.385L164.254 162.041C169.854 166.425 174.91 171.449 179.294 177.049C183.038 181.913 186.302 187.161 188.958 192.665Z"
      fill={white}
    />
    <path
      d="M67.3906 202.873C83.1986 204.153 100.159 204.665 119.903 204.601C138.847 204.537 154.559 204.089 170.399 202.873C170.975 192.441 169.823 181.145 170.847 169.209C171.903 157.305 172.543 146.105 168.991 139.961C158.079 140.633 146.783 139.737 135.167 139.961C113.215 140.345 92.4146 141.177 72.8626 139.961C67.6466 150.105 72.5746 182.777 67.3906 202.873Z"
      fill={white}
    />
    <path
      d="M116.96 205.177C97.8239 205.177 82.0159 204.633 67.3599 203.449C67.1999 203.449 67.0399 203.353 66.9439 203.225C66.8479 203.097 66.8159 202.905 66.8479 202.745C69.4079 192.825 69.4719 179.577 69.5359 167.897C69.5999 155.577 69.6639 144.921 72.3519 139.705C72.4479 139.513 72.6719 139.385 72.8959 139.385C88.9279 140.377 105.76 139.993 123.552 139.609C127.392 139.513 131.264 139.449 135.168 139.385C140.032 139.289 144.864 139.385 149.536 139.513C156.256 139.641 162.624 139.801 168.992 139.385C169.216 139.385 169.408 139.481 169.536 139.673C173.152 145.977 172.512 157.273 171.456 169.273C170.88 175.961 170.976 182.457 171.072 188.729C171.168 193.657 171.232 198.329 170.976 202.905C170.976 203.193 170.72 203.417 170.464 203.449C155.584 204.601 140.48 205.113 119.936 205.177C118.944 205.177 117.952 205.177 116.96 205.177ZM68.1279 202.361C83.3279 203.545 99.8079 204.089 119.936 204.025C140.224 203.961 155.2 203.449 169.888 202.329C170.112 197.945 170.016 193.465 169.952 188.729C169.856 182.425 169.728 175.897 170.336 169.145C171.36 157.529 172 146.585 168.736 140.537C162.464 140.889 156.192 140.761 149.568 140.633C144.896 140.537 140.064 140.441 135.264 140.505C131.36 140.569 127.488 140.665 123.648 140.729C105.952 141.113 89.2479 141.465 73.2799 140.505C70.8799 145.625 70.8159 156.441 70.7519 167.833C70.6239 179.385 70.5599 192.377 68.1279 202.361Z"
      fill={black}
    />
    <path
      d="M168.575 139.961C172.575 129.177 169.823 92.7611 171.967 77.0491C158.559 76.6971 140.991 76.6651 122.239 76.6971C103.167 76.7291 86.5587 77.5291 71.7107 77.0491C70.6547 86.2971 70.9427 96.7931 70.0787 108.281C69.1187 121.017 68.8307 133.817 72.3827 139.961"
      fill={white}
    />
    <path
      d="M168.575 140.537C168.511 140.537 168.447 140.537 168.383 140.505C168.095 140.409 167.935 140.057 168.031 139.769C170.303 133.625 170.367 118.809 170.399 104.473C170.431 94.1047 170.463 84.2487 171.295 77.5927C157.567 77.2407 139.551 77.2407 122.239 77.2407C114.623 77.2407 107.455 77.3687 100.511 77.4967C90.4952 77.6887 80.9912 77.8487 72.2232 77.5927C71.6792 82.5527 71.5192 87.8647 71.3592 93.4967C71.1992 98.2327 71.0712 103.129 70.6872 108.249C69.7912 120.281 69.3752 133.401 72.9272 139.609C73.0872 139.897 72.9912 140.217 72.7032 140.377C72.4152 140.537 72.0952 140.441 71.9352 140.153C68.2232 133.689 68.6392 120.345 69.5352 108.121C69.9192 103.033 70.0792 98.1367 70.2072 93.4007C70.3992 87.5447 70.5592 82.0087 71.1352 76.8567C71.1672 76.5687 71.4232 76.3447 71.7112 76.3447C80.6072 76.6327 90.2392 76.4407 100.479 76.2487C107.423 76.1207 114.591 75.9927 122.207 75.9607C139.807 75.9287 158.111 75.9607 171.935 76.3127C172.095 76.3127 172.255 76.3767 172.351 76.5047C172.447 76.6327 172.511 76.7927 172.479 76.9527C171.583 83.5447 171.551 93.6247 171.519 104.313C171.487 118.745 171.423 133.657 169.055 139.993C169.023 140.377 168.799 140.537 168.575 140.537Z"
      fill={black}
    />
    <path
      d="M170.559 195.353C154.623 196.377 137.663 196.281 119.807 196.281C101.983 196.281 84.7673 196.377 68.8633 195.353C68.8633 193.273 69.4393 191.161 69.4393 189.081H170.559V195.353Z"
      fill={black}
    />
    <path
      d="M102.942 132.729C99.7105 132.729 96.9265 132.665 94.2065 132.633C91.0385 132.569 87.7425 132.505 83.8065 132.537C83.4865 132.537 83.2305 132.281 83.2305 131.961C83.2305 131.641 83.4865 131.385 83.8065 131.385C87.7425 131.353 91.0385 131.417 94.2065 131.481C100.286 131.609 106.59 131.737 117.694 131.225C122.782 131.001 130.526 131.129 138.014 131.257C143.806 131.353 149.278 131.449 152.958 131.353C153.278 131.353 153.534 131.609 153.534 131.897C153.534 132.217 153.278 132.473 152.99 132.473C149.278 132.569 143.806 132.473 138.014 132.377C130.558 132.249 122.814 132.121 117.79 132.345C111.614 132.633 106.942 132.729 102.942 132.729Z"
      fill={black}
    />
    <path
      d="M87.9368 128.473C87.7768 128.473 87.6488 128.409 87.5208 128.313L84.8008 125.593C84.5768 125.369 84.5768 125.017 84.8008 124.793C85.0248 124.569 85.3768 124.569 85.6008 124.793L88.3208 127.513C88.5448 127.737 88.5448 128.089 88.3208 128.313C88.2248 128.409 88.0648 128.473 87.9368 128.473Z"
      fill={black}
    />
    <path
      d="M85.2168 128.473C85.0568 128.473 84.9288 128.409 84.8008 128.313C84.5768 128.089 84.5768 127.737 84.8008 127.513L87.5208 124.793C87.7448 124.569 88.0968 124.569 88.3208 124.793C88.5448 125.017 88.5448 125.369 88.3208 125.593L85.6008 128.313C85.5048 128.409 85.3448 128.473 85.2168 128.473Z"
      fill={black}
    />
    <path
      d="M104.832 128.025C104.448 128.025 104.192 127.897 104.032 127.641C103.616 127.033 104.096 126.201 105.952 124.377C110.88 119.513 112.128 115.513 110.816 114.041C109.664 112.729 105.984 113.305 100.704 117.561C96.0638 121.337 94.8478 124.569 95.1678 125.817C95.2638 126.201 95.4878 126.425 95.9038 126.489C97.7278 126.809 101.824 124.121 106.528 117.401C106.72 117.145 107.072 117.081 107.328 117.273C107.584 117.465 107.648 117.817 107.456 118.073C102.112 125.721 97.8558 128.025 95.6798 127.641C94.8478 127.481 94.2718 126.937 94.0478 126.105C93.5998 124.345 94.8798 120.825 99.9998 116.697C105.184 112.505 109.792 111.161 111.68 113.305C113.344 115.161 112.48 119.577 106.784 125.209C105.952 126.041 105.504 126.553 105.28 126.841C106.272 126.617 108.352 125.721 109.984 125.017C114.784 122.969 116.96 122.169 117.728 123.097C118.144 123.577 117.92 124.217 117.664 124.697C118.272 124.505 119.2 124.121 119.968 123.801C123.488 122.361 126.08 121.433 127.296 122.329C127.616 122.553 127.968 123.001 127.904 123.833C127.84 124.537 128.096 124.793 128.32 124.953C130.304 126.457 138.208 123.577 140.832 122.617C141.12 122.521 141.472 122.649 141.568 122.969C141.664 123.257 141.536 123.609 141.216 123.705C133.44 126.521 129.376 127.161 127.648 125.849C127.2 125.529 126.72 124.889 126.784 123.737C126.816 123.353 126.688 123.289 126.656 123.257C125.856 122.681 122.336 124.089 120.416 124.889C117.728 125.977 116.864 126.297 116.384 125.721C116.128 125.433 116.16 125.017 116.48 124.505C116.672 124.185 116.768 123.993 116.8 123.865C115.904 123.737 112.512 125.209 110.432 126.105C107.36 127.385 105.728 128.025 104.832 128.025Z"
      fill={black}
    />
    <path
      d="M158.559 155.193C158.527 155.193 158.527 155.193 158.495 155.193C152.159 154.585 138.335 154.329 131.039 154.681C128.831 154.777 127.007 154.841 125.055 154.873C122.687 154.937 120.223 155.001 116.927 155.161C116.607 155.161 116.351 154.937 116.319 154.617C116.287 154.297 116.543 154.041 116.863 154.009C120.191 153.817 122.655 153.753 125.023 153.721C126.975 153.689 128.799 153.625 130.975 153.529C138.303 153.177 152.223 153.433 158.591 154.041C158.911 154.073 159.135 154.361 159.103 154.681C159.103 154.969 158.847 155.193 158.559 155.193Z"
      fill={black}
    />
    <path
      d="M116.928 163.033C116.608 163.033 116.384 162.777 116.352 162.489C116.352 162.169 116.576 161.913 116.896 161.881C124.704 161.593 131.68 161.433 138.208 161.465C142.112 161.465 145.92 161.593 149.6 161.689C152.864 161.785 155.904 161.881 158.56 161.881C158.88 161.881 159.136 162.137 159.136 162.457C159.136 162.777 158.88 163.033 158.56 163.033C155.904 163.033 152.832 162.937 149.568 162.841C145.888 162.745 142.08 162.617 138.208 162.617C131.68 162.617 124.736 162.745 116.928 163.033Z"
      fill={black}
    />
    <path
      d="M134.496 170.905H116.928C116.608 170.905 116.352 170.649 116.352 170.329C116.352 170.009 116.608 169.753 116.928 169.753H134.496C134.816 169.753 135.072 170.009 135.072 170.329C135.072 170.649 134.816 170.905 134.496 170.905Z"
      fill={black}
    />
    <path
      d="M158.558 170.905H140.99C140.67 170.905 140.414 170.649 140.414 170.329C140.414 170.009 140.67 169.753 140.99 169.753H158.558C158.878 169.753 159.134 170.009 159.134 170.329C159.134 170.649 158.878 170.905 158.558 170.905Z"
      fill={black}
    />
    <path
      d="M136.862 179.033C130.078 179.033 123.518 178.937 116.894 178.777C116.574 178.777 116.318 178.521 116.35 178.201C116.35 177.881 116.638 177.625 116.926 177.657C123.966 177.849 130.878 177.945 138.078 177.913C141.822 177.913 145.438 177.849 148.926 177.785C152.286 177.721 155.486 177.657 158.558 177.657C158.878 177.657 159.134 177.913 159.134 178.233C159.134 178.553 158.878 178.809 158.558 178.809C155.518 178.809 152.318 178.873 148.958 178.937C145.47 179.001 141.854 179.065 138.078 179.065C137.694 179.033 137.278 179.033 136.862 179.033Z"
      fill={black}
    />
    <path
      d="M102.398 182.457C101.438 175.673 101.438 170.425 97.6945 169.881C93.3425 169.273 94.3985 167.161 95.9665 165.017C98.2065 161.881 98.1105 155.193 93.2785 154.649C87.6785 154.009 86.7185 161.977 90.0785 165.369C92.8945 168.249 88.4145 169.337 87.8065 169.401C84.8305 169.657 83.8065 173.689 83.2305 182.169L102.398 182.457Z"
      fill={black}
    />
    <path
      d="M99.9337 183.353C98.1097 183.353 95.7737 183.321 92.8937 183.289C86.1417 183.193 79.4217 183.033 79.3577 183.033C79.0377 183.033 78.8137 182.777 78.8137 182.457L78.4297 148.281C78.4297 148.121 78.4937 147.993 78.5897 147.865C78.6857 147.769 78.8457 147.705 79.0057 147.705C79.2617 147.705 104.926 147.929 105.534 147.929C105.854 147.929 106.078 148.185 106.11 148.473C106.174 149.817 107.582 181.721 107.582 182.457C107.582 182.617 107.518 182.777 107.39 182.905C107.134 183.097 106.878 183.353 99.9337 183.353ZM79.9337 181.913C89.3737 182.137 103.87 182.361 106.398 182.041C106.27 178.969 105.438 159.865 104.958 149.081C101.502 149.049 83.1657 148.889 79.5497 148.857L79.9337 181.913Z"
      fill={black}
    />
    <path
      d="M181.664 127.897C173.568 123.865 163.84 103.257 157.152 106.041C150.432 108.825 159.712 132.857 173.12 146.073"
      fill={black}
    />
    <path
      d="M173.118 146.617C172.958 146.617 172.83 146.553 172.702 146.457C167.454 141.305 162.398 134.009 158.814 126.457C155.39 119.225 153.758 112.665 154.494 108.921C154.846 107.193 155.646 106.041 156.926 105.529C161.182 103.769 166.078 109.945 171.262 116.473C174.878 121.017 178.59 125.721 181.918 127.385C182.206 127.513 182.302 127.865 182.174 128.153C182.046 128.441 181.694 128.537 181.406 128.409C177.854 126.617 174.046 121.817 170.366 117.177C165.662 111.257 160.83 105.145 157.374 106.585C156.478 106.937 155.902 107.801 155.646 109.145C154.238 116.313 162.238 134.553 173.534 145.657C173.758 145.881 173.758 146.233 173.534 146.457C173.406 146.553 173.246 146.617 173.118 146.617Z"
      fill={white}
    />
    <path
      d="M39.9337 177.465C39.8697 177.465 39.7737 177.433 39.7097 177.401L35.1657 175.353C34.8777 175.225 34.7497 174.873 34.8777 174.585C35.0057 174.297 35.3577 174.169 35.6457 174.297L40.1897 176.345C40.4777 176.473 40.6057 176.825 40.4777 177.113C40.3497 177.337 40.1577 177.465 39.9337 177.465Z"
      fill={contentPrimary}
    />
    <path
      d="M53.3127 183.513C53.2487 183.513 53.1526 183.481 53.0886 183.449L48.5447 181.401C48.2567 181.273 48.1286 180.921 48.2566 180.633C48.3846 180.345 48.7367 180.217 49.0247 180.345L53.5686 182.393C53.8566 182.521 53.9846 182.873 53.8566 183.161C53.7606 183.385 53.5367 183.513 53.3127 183.513Z"
      fill={contentPrimary}
    />
    <path
      d="M46.335 175.033C46.271 175.033 46.1751 175.001 46.1111 174.969C45.8231 174.841 45.6951 174.489 45.8231 174.201L47.8711 169.657C47.999 169.369 48.351 169.241 48.639 169.369C48.927 169.497 49.055 169.849 48.927 170.137L46.879 174.681C46.783 174.905 46.559 175.033 46.335 175.033Z"
      fill={contentPrimary}
    />
    <path
      d="M40.3194 188.441C40.2554 188.441 40.1594 188.409 40.0954 188.377C39.8074 188.249 39.6794 187.897 39.8074 187.609L41.8554 183.065C41.9834 182.777 42.3354 182.649 42.6234 182.777C42.9114 182.905 43.0394 183.257 42.9114 183.545L40.8634 188.089C40.7354 188.313 40.5114 188.441 40.3194 188.441Z"
      fill={contentPrimary}
    />
    <path
      d="M35.1341 182.937C34.9101 182.937 34.6861 182.809 34.5901 182.553C34.4941 182.265 34.6221 181.913 34.9101 181.817L37.6301 180.793C37.9181 180.665 38.2701 180.825 38.3661 181.113C38.4621 181.401 38.3341 181.753 38.0461 181.849L35.3581 182.905C35.2941 182.937 35.2301 182.937 35.1341 182.937Z"
      fill={contentPrimary}
    />
    <path
      d="M50.8138 177.017C50.5898 177.017 50.3658 176.889 50.2698 176.633C50.1738 176.345 50.3018 175.993 50.5898 175.897L53.3098 174.873C53.5978 174.745 53.9498 174.905 54.0458 175.193C54.1418 175.481 54.0138 175.833 53.7258 175.929L51.0058 176.953C50.9738 176.985 50.8778 177.017 50.8138 177.017Z"
      fill={contentPrimary}
    />
    <path
      d="M41.8886 172.985C41.6646 172.985 41.4406 172.857 41.3446 172.601L40.3206 169.881C40.2246 169.593 40.3526 169.241 40.6406 169.145C40.9286 169.017 41.2806 169.177 41.3766 169.465L42.4006 172.185C42.4966 172.473 42.3686 172.825 42.0806 172.921C42.0486 172.985 41.9846 172.985 41.8886 172.985Z"
      fill={contentPrimary}
    />
    <path
      d="M47.8378 188.664C47.6138 188.664 47.3898 188.536 47.2938 188.28L46.2698 185.56C46.1738 185.272 46.3018 184.92 46.5898 184.824C46.8778 184.728 47.2298 184.856 47.3258 185.144L48.3498 187.864C48.4458 188.152 48.3178 188.504 48.0298 188.6C47.9658 188.664 47.9018 188.664 47.8378 188.664Z"
      fill={contentPrimary}
    />
    <path
      d="M45.8254 59.5131C45.6334 59.5131 45.4094 59.3851 45.3134 59.1931L42.0814 52.8891C41.9214 52.6011 42.0494 52.2491 42.3374 52.1211C42.6254 51.9931 42.9774 52.0891 43.1054 52.3771L46.3374 58.6811C46.4974 58.9691 46.3694 59.3211 46.0814 59.4491C45.9854 59.4811 45.9214 59.5131 45.8254 59.5131Z"
      fill={contentPrimary}
    />
    <path
      d="M55.3293 78.073C55.1373 78.073 54.9133 77.945 54.8173 77.753L51.5853 71.449C51.4253 71.161 51.5533 70.809 51.8413 70.681C52.1293 70.521 52.4813 70.649 52.6093 70.937L55.8413 77.241C56.0013 77.529 55.8733 77.881 55.5853 78.009C55.5213 78.073 55.4253 78.073 55.3293 78.073Z"
      fill={contentPrimary}
    />
    <path
      d="M55.1051 62.4889C54.9131 62.4889 54.6891 62.3609 54.5931 62.1689C54.4331 61.8809 54.5611 61.5289 54.8491 61.4009L61.1531 58.1689C61.4411 58.0409 61.7931 58.1369 61.9211 58.4249C62.0811 58.7129 61.9531 59.0649 61.6651 59.1929L55.3611 62.4249C55.2651 62.4889 55.1691 62.4889 55.1051 62.4889Z"
      fill={contentPrimary}
    />
    <path
      d="M36.5114 72.025C36.3194 72.025 36.0954 71.897 35.9994 71.705C35.8394 71.417 35.9674 71.065 36.2554 70.937L42.5594 67.705C42.8474 67.577 43.1994 67.673 43.3274 67.961C43.4554 68.249 43.3594 68.601 43.0714 68.729L36.7674 71.961C36.7034 71.993 36.6074 72.025 36.5114 72.025Z"
      fill={contentPrimary}
    />
    <path
      d="M39.5831 62.6173C39.5191 62.6173 39.4551 62.6173 39.4231 62.5853L35.4871 61.3053C35.1991 61.2093 35.0071 60.8893 35.1031 60.6013C35.1991 60.3133 35.5191 60.1214 35.8071 60.2174L39.7431 61.4973C40.0311 61.5933 40.2231 61.9133 40.1271 62.2013C40.0631 62.4893 39.8391 62.6173 39.5831 62.6173Z"
      fill={contentPrimary}
    />
    <path
      d="M62.2706 69.9133C62.2066 69.9133 62.1426 69.9133 62.1106 69.8813L58.1746 68.6013C57.8866 68.5053 57.6946 68.1853 57.7906 67.8973C57.8866 67.6093 58.2066 67.4173 58.4946 67.5133L62.4306 68.7933C62.7186 68.8893 62.9106 69.2093 62.8146 69.4973C62.7186 69.7853 62.4946 69.9133 62.2706 69.9133Z"
      fill={contentPrimary}
    />
    <path
      d="M51.9674 56.2812C51.9034 56.2812 51.8394 56.2812 51.8074 56.2492C51.5194 56.1532 51.3274 55.8332 51.4234 55.5452L52.7034 51.6092C52.7994 51.3212 53.1194 51.1292 53.4074 51.2252C53.6954 51.3212 53.8874 51.6412 53.7914 51.9292L52.5114 55.8652C52.4474 56.1212 52.2234 56.2812 51.9674 56.2812Z"
      fill={contentPrimary}
    />
    <path
      d="M44.6705 78.9374C44.6065 78.9374 44.5425 78.9374 44.5105 78.9054C44.2225 78.8094 44.0305 78.4894 44.1265 78.2014L45.4065 74.2654C45.5025 73.9774 45.8225 73.7854 46.1105 73.8814C46.3985 73.9774 46.5905 74.2974 46.4945 74.5854L45.2145 78.5214C45.1505 78.8094 44.9265 78.9374 44.6705 78.9374Z"
      fill={contentPrimary}
    />
    <path
      d="M196.13 77.2729C195.938 77.2729 195.746 77.1769 195.618 76.9849L193.218 72.6009C193.058 72.3129 193.154 71.9609 193.442 71.8329C193.73 71.6729 194.082 71.7689 194.21 72.0569L196.61 76.4409C196.77 76.7289 196.674 77.0809 196.386 77.2089C196.322 77.2409 196.226 77.2729 196.13 77.2729Z"
      fill={contentPrimary}
    />
    <path
      d="M203.2 90.1694C203.008 90.1694 202.816 90.0734 202.688 89.8814L200.288 85.4974C200.128 85.2094 200.224 84.8574 200.512 84.7294C200.8 84.5694 201.152 84.6654 201.28 84.9534L203.68 89.3374C203.84 89.6254 203.744 89.9774 203.456 90.1054C203.392 90.1374 203.296 90.1694 203.2 90.1694Z"
      fill={contentPrimary}
    />
    <path
      d="M202.718 79.1932C202.526 79.1932 202.334 79.0972 202.206 78.9052C202.046 78.6172 202.142 78.2652 202.43 78.1372L206.814 75.7372C207.102 75.5772 207.454 75.6732 207.582 75.9612C207.742 76.2492 207.646 76.6012 207.358 76.7292L202.974 79.1292C202.91 79.1932 202.814 79.1932 202.718 79.1932Z"
      fill={contentPrimary}
    />
    <path
      d="M189.823 86.2655C189.631 86.2655 189.439 86.1695 189.311 85.9775C189.151 85.6895 189.247 85.3375 189.535 85.2095L193.919 82.8095C194.207 82.6495 194.559 82.7455 194.687 83.0335C194.847 83.3215 194.751 83.6735 194.463 83.8015L190.079 86.2015C190.015 86.2335 189.919 86.2655 189.823 86.2655Z"
      fill={contentPrimary}
    />
    <path
      d="M191.837 79.5766C191.773 79.5766 191.741 79.5766 191.677 79.5446L188.893 78.7126C188.605 78.6166 188.413 78.2966 188.509 78.0086C188.605 77.7206 188.925 77.5286 189.213 77.6246L191.997 78.4566C192.285 78.5526 192.477 78.8726 192.381 79.1606C192.285 79.4166 192.061 79.5766 191.837 79.5766Z"
      fill={contentPrimary}
    />
    <path
      d="M207.904 84.2807C207.84 84.2807 207.808 84.2807 207.744 84.2487L204.96 83.4167C204.672 83.3207 204.48 83.0007 204.576 82.7127C204.672 82.4247 204.992 82.2327 205.28 82.3287L208.064 83.1607C208.352 83.2567 208.544 83.5767 208.448 83.8647C208.384 84.1207 208.16 84.2807 207.904 84.2807Z"
      fill={contentPrimary}
    />
    <path
      d="M200.417 74.8733C200.353 74.8733 200.321 74.8733 200.257 74.8413C199.969 74.7453 199.777 74.4253 199.873 74.1373L200.705 71.3533C200.801 71.0653 201.121 70.8733 201.409 70.9693C201.697 71.0653 201.889 71.3853 201.793 71.6733L200.961 74.4573C200.897 74.7133 200.673 74.8733 200.417 74.8733Z"
      fill={contentPrimary}
    />
    <path
      d="M195.71 90.969C195.646 90.969 195.614 90.969 195.55 90.937C195.262 90.841 195.07 90.521 195.166 90.233L195.998 87.449C196.094 87.161 196.414 86.969 196.702 87.065C196.99 87.161 197.182 87.481 197.086 87.769L196.254 90.553C196.19 90.809 195.966 90.969 195.71 90.969Z"
      fill={contentPrimary}
    />
  </Fragment>
));
IllustrationPassport.displayName = "IllustrationPassport";
