import { Fragment } from "react";
import { Toolbar } from "@gemini-ui/design-system";
import {
  StakingPresetFunding,
  StakingPresetFundingType,
} from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/constants";
import { useIntl } from "@gemini-ui/utils/intl";

export interface StakingPresetsProps {
  fundingType: StakingPresetFundingType;
  onClickAmount?: (value: string) => void;
  onClickPercent?: (value: number) => void;
}

export function StakingPresets({
  fundingType = StakingPresetFunding.FIAT,
  onClickAmount,
  onClickPercent,
}: StakingPresetsProps) {
  const { intl } = useIntl();

  const presets = {
    fiat: (
      <Fragment>
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "$25" })} onClick={() => onClickAmount?.("25")} />
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "$50" })} onClick={() => onClickAmount?.("50")} />
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "$100" })} onClick={() => onClickAmount?.("100")} />
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "$500" })} onClick={() => onClickAmount?.("500")} />
      </Fragment>
    ),
    crypto: (
      <Fragment>
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "Half" })} onClick={() => onClickPercent?.(0.5)} />
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "Max" })} onClick={() => onClickPercent?.(1)} />
      </Fragment>
    ),
  };

  return <Toolbar stretch={true}>{presets[fundingType]}</Toolbar>;
}
