import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Colors } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const CopyrightWrapper = styled.div`
  font-size: 8px;
  color: ${Colors.gray[600]};
  padding-top: 0;
  text-align: center;
  margin-bottom: 50px;
`;

const Copyright = () => {
  const { intl, buildGeminiPublicLink } = useIntl();

  const year = new Date().getFullYear();
  return (
    <CopyrightWrapper>
      {intl.formatMessage(
        defineMessage({
          defaultMessage:
            "Trust is Our Product™<br></br>For trademarks and patents, please see the <a>Legal Notice</a>.<br></br>© Copyright {year} Gemini Trust Company, LLC. NMLS #1518126",
        }),
        {
          br: () => <br />,
          a: (v: ReactNode) => (
            <a href={buildGeminiPublicLink("legal/legal-notice")} target="_blank" rel="noopener noreferrer">
              {v}
            </a>
          ),
          year,
        }
      )}
    </CopyrightWrapper>
  );
};

export default Copyright;
