import { FC, Fragment, PropsWithChildren, ReactNode } from "react";
import { GlobalAlertTypesProps } from "@gemini-ui/components/GlobalAlert/constants";
import { useSettingsData } from "@gemini-ui/components/Header/navigation/data/geminiMenuData";
import MainLayout from "@gemini-ui/components/layouts/MainLayout";
import { NavButton, NavMenuContainer, StyledButtonGroup } from "@gemini-ui/components/layouts/SettingsLayout/styles";
import { FeatureFlags } from "@gemini-ui/constants/templateProps/featureFlags";
import { User } from "@gemini-ui/constants/templateProps/users";
import { PageLayout, Spacer } from "@gemini-ui/design-system";

export type ActiveSettingsTab =
  | "profile"
  | "security"
  | "bank"
  | "api"
  | "users"
  | "privacy"
  | "notifications"
  | "whitelist"
  | "newAccount"
  | "card"
  | "clearing"
  | "legal"
  | "documents"
  | "accountLevel"
  | "applications"
  | "billing"
  | "settlement"
  | "transactionHistory"
  | "earnVote"
  | "earnDistributionSummary";

export interface Props extends GlobalAlertTypesProps {
  title: string;
  active: ActiveSettingsTab;
  error?: ReactNode;
  user: User;
  isExchangeSettings?: boolean;
  isSandbox?: boolean;
  featureFlags: FeatureFlags;
  sidePanel?: ReactNode;
}

const SettingsLayout: FC<PropsWithChildren<Props>> = props => {
  const { active, children, featureFlags, user, sidePanel } = props;
  const settings = useSettingsData(featureFlags, user, active);
  const activeHeader = settings.find(setting => setting.name === active)?.header || "";

  return (
    <MainLayout {...props}>
      <PageLayout>
        <PageLayout.SecondaryNav label={activeHeader}>
          <NavMenuContainer>
            <StyledButtonGroup type="stacked">
              {settings.map((data, index) => {
                return (
                  <NavButton
                    href={data.link}
                    key={`navLink-${index}`}
                    data-testid={`navLink-${data.name}`}
                    active={active === data.name}
                    cta={data.header}
                    leftElement={
                      <Fragment>
                        <data.icon />
                        <Spacer mr={0.5} />
                      </Fragment>
                    }
                    mb={settings.length - 2 === index ? 0 : 1}
                  />
                );
              })}
            </StyledButtonGroup>
          </NavMenuContainer>
        </PageLayout.SecondaryNav>

        <PageLayout.Content>
          <Spacer mt={4}>{children}</Spacer>
        </PageLayout.Content>

        {sidePanel && (
          <PageLayout.SidePanel>
            <Spacer mt={4}>{sidePanel}</Spacer>
          </PageLayout.SidePanel>
        )}
      </PageLayout>
    </MainLayout>
  );
};

export default SettingsLayout;
