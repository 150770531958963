import { AxiosError } from "axios";
import { trackBrazeEvent } from "@gemini-ui/analytics";
import { CREDIT_CARD_EVENTS_V2, CreditTrackEvent, CreditTrackEventV2 } from "@gemini-ui/analytics/constants/creditCard";
import { applicationApi } from "@gemini-ui/client/api";
import { ApplicationPrefill, ApplicationStatus } from "@gemini-ui/client/credit";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { VerifyPhoneOrRegister } from "@gemini-ui/pages/Credit/CreditApplication/components";
import { HandleSubmit } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { handleCreditExceptions } from "@gemini-ui/pages/Credit/CreditApplication/handlers/handleCreditExceptions";
import { mapFormValuesToApplication } from "@gemini-ui/pages/Credit/CreditApplication/handlers/mapFormValuesToApplication";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { initPixelTracking, trackAnalyticsEvent } from "@gemini-ui/pages/Credit/CreditApplication/tracking/analytics";
import { isEmailVerified, logAffiliateTrackingId } from "@gemini-ui/pages/Credit/CreditApplication/utils";
import { CreditError } from "@gemini-ui/pages/Credit/utils/errorCodes";
import { getError } from "@gemini-ui/utils/error";

const VERIFY_PHONE_OR_REGISTER = "VerifyPhoneOrRegister";
export const getNewAppError = (e: AxiosError<{ message: string; code: CreditError }>) =>
  e.response?.data.message === VERIFY_PHONE_OR_REGISTER ||
  e.response?.data.code === CreditError.VerifyPhoneOrRegister ? (
    <VerifyPhoneOrRegister />
  ) : (
    getError(e)
  );

interface HandleNewApp extends HandleSubmit {
  isLoggedIn?: boolean;
  handleSendVerificationEmail?: () => void;
}

export const handleNewAppSubmit = async ({
  send,
  showAlert,
  values,
  isLoggedIn,
  handleSendVerificationEmail,
}: HandleNewApp) => {
  try {
    const applicationData: ApplicationPrefill = mapFormValuesToApplication(values);
    initPixelTracking(applicationData?.email);
    logAffiliateTrackingId(true);
    const handleNextState = (type: ClientState, data: ApplicationStatus) => {
      send({
        type,
        appData: applicationData,
        statusHistory: data.statusHistory,
      });
    };

    const { data } = await applicationApi.createApplicationPrefill(applicationData);

    if (window.nid) window.nid("identify", data?.userId);

    trackBrazeEvent(CREDIT_CARD_EVENTS_V2[CreditTrackEventV2.CC_BEGIN_CREDIT_PREQUAL].name);
    trackAnalyticsEvent({
      trackingEvent: CreditTrackEvent.CREDIT_START_APPLICATION,
      properties: { applicationData },
    });
    if (!isLoggedIn) {
      trackAnalyticsEvent({ trackingEvent: CreditTrackEvent.BEGIN_CREDIT_PREQUAL, properties: { applicationData } });
      handleSendVerificationEmail();
      handleNextState(ClientState.EMAIL_VERIFY, data);
    } else {
      if (!isEmailVerified) {
        handleSendVerificationEmail();
        handleNextState(ClientState.EMAIL_VERIFY, data);
      } else {
        handleNextState(ClientState.SMS_VERIFY, data);
      }
    }
  } catch (e) {
    if (!handleCreditExceptions({ e, send })) {
      showAlert({ type: AlertTypes.ERROR, message: getNewAppError(e) });
    }
  }
};
