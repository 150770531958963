import { IconMinus, IconPlus } from "@hubble/icons";
import { Toolbar } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  isIncrementDisabled?: boolean;
  isDecrementDisabled?: boolean;
  isHalfDisabled?: boolean;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
  onClickPercent: (value: number) => void;
}

export function StakingProPresets({
  isIncrementDisabled,
  isDecrementDisabled,
  isHalfDisabled = false,
  onIncrementClick,
  onDecrementClick,
  onClickPercent,
}: Props) {
  const { intl } = useIntl();

  return (
    <Toolbar stretch={true}>
      <Toolbar.Button
        aria-label={intl.formatMessage({ defaultMessage: "Increment count" })}
        icon={<IconPlus />}
        onClick={() => onIncrementClick()}
        disabled={isIncrementDisabled}
      />
      <Toolbar.Button
        aria-label={intl.formatMessage({ defaultMessage: "Decrement count" })}
        icon={<IconMinus />}
        onClick={() => onDecrementClick()}
        disabled={isDecrementDisabled}
      />
      <Toolbar.Seperator />
      {!isHalfDisabled && (
        <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "Half" })} onClick={() => onClickPercent(0.5)} />
      )}
      <Toolbar.Button cta={intl.formatMessage({ defaultMessage: "Max" })} onClick={() => onClickPercent(1)} />
    </Toolbar>
  );
}
