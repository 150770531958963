import { forwardRef, Fragment } from "react";
import { Card } from "@gemini-ui/design-system/cards/Card";
import { CardMediaPolymorphic, ColumnWidths } from "@gemini-ui/design-system/cards/CardMedia/constants";
import { CardMediaGrid } from "@gemini-ui/design-system/cards/CardMedia/styles";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Spacer } from "@gemini-ui/design-system/primitives";

const NONE = "none";

export const CardMedia = forwardRef(function ForwardedCardMedia(
  {
    children,
    columnWidths = "equal" as ColumnWidths,
    footer,
    hasContentPadding,
    header,
    media,
    mediaPlacement = "top",
    padding = "md",
    variant,
    ...props
  },
  ref
) {
  const hasTwoColumns = mediaPlacement !== "top";

  const spacerMap = {
    none: null,
    sm: "2",
    md: "3",
  };

  const AUTO = "auto";
  const spacerValue = spacerMap[padding];
  const contentSpacerValue = hasContentPadding && padding !== "none" ? spacerValue : null;

  const contents = () => {
    if (mediaPlacement === "top")
      return (
        <div>
          <div>{media}</div>
          <Spacer as="header" pl={spacerValue} pr={spacerValue}>
            <div>{header}</div>
          </Spacer>
          <Spacer pl={contentSpacerValue} pr={contentSpacerValue}>
            {children}
          </Spacer>
          <Spacer as="footer" pb={spacerValue} pl={spacerValue} pr={spacerValue}>
            <div>{footer}</div>
          </Spacer>
        </div>
      );

    return (
      <Fragment>
        {mediaPlacement === "left" && <div>{media}</div>}
        <Flex flexDirection="column">
          <Spacer as="header" pl={spacerValue} pr={spacerValue} pt={spacerValue}>
            {header}
          </Spacer>
          <Spacer mb={AUTO} pl={contentSpacerValue} pr={contentSpacerValue}>
            {children}
          </Spacer>
          <Spacer as="footer" pb={spacerValue} pl={spacerValue} pr={spacerValue}>
            {footer}
          </Spacer>
        </Flex>
        {mediaPlacement === "right" && <div>{media}</div>}
      </Fragment>
    );
  };

  return (
    <Card ref={ref} variant={variant} {...props} padding={NONE}>
      <CardMediaGrid columnWidths={columnWidths} hasTwoColumns={hasTwoColumns} padding={padding}>
        {contents()}
      </CardMediaGrid>
    </Card>
  );
}) as CardMediaPolymorphic;
