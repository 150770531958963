import { MouseEvent, ReactNode } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Card, Flex, ListItem, Text } from "@gemini-ui/design-system";
import { CardProps } from "@gemini-ui/design-system/cards/Card/constants";
import { ForwardRefComponent } from "@gemini-ui/design-system/polymorphic";
import { FundingSourceIcon } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSourceIcon";
import { SupportedStakingPaymentMethod, SupportedUnstakingMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

const StyledCardButton = styled(Card)<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled &&
    `
    pointer-events: none;
    transition: none;

    &:hover,
    &:focus {
      background-color: ${theme.colorScheme.card.background.outlined.enabled};
      border-color: ${theme.colorScheme.card.border.outlined.enabled};
    }

    &:active {
      transform: none;
    }
  `}
` as unknown as ForwardRefComponent<"button", CardProps>;

export interface FundingSourceDisplayCardProps {
  isDisabled?: boolean;
  label?: ReactNode;
  subLabel?: ReactNode;
  value: SupportedStakingPaymentMethod | SupportedUnstakingMethod;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const FundingSourceCard = ({ value, isDisabled, label, onClick, subLabel }: FundingSourceDisplayCardProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <StyledCardButton
      data-testid={testIds.buyModule.fundingSource.buttonCardFilled}
      as="button"
      onClick={onClick}
      padding="none"
      disabled={isDisabled}
    >
      <ListItem
        padding="sm"
        hasDivider={false}
        left={
          <FundingSourceIcon
            fundingSource={value}
            backgroundColor={colorScheme.iconBadge.background.bold}
            color={colorScheme.iconBadge.content.bold}
          />
        }
        right={
          <Text.Body
            size="xs"
            bold
            pl={1}
            pr={1}
            color={
              isDisabled
                ? colorScheme.action.content.tertiary.neutral.disabled
                : colorScheme.action.content.tertiary.neutral.enabled
            }
          >
            {intl.formatMessage({ defaultMessage: "Change" })}
          </Text.Body>
        }
        alignItems="center"
      >
        <Flex flexDirection="column">
          <Text.Body size="sm">{label}</Text.Body>
          {subLabel && (
            <Text.Body size="xs" color={colorScheme.content.secondary}>
              {subLabel}
            </Text.Body>
          )}
        </Flex>
      </ListItem>
    </StyledCardButton>
  );
};
