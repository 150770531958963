import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { usePageRefresh } from "@gemini-ui/contexts";
import { MarketFilter } from "@gemini-ui/pages/RetailTrade/constants";
import axios from "@gemini-ui/services/axios";

export type MarketFilterResponse = {
  results: [
    {
      function: "market_filters";
      output: {
        filters: MarketFilter[];
      };
    }
  ];
};

export const getMarketFilters = async (defaultFiat?: CurrencyShortNameFiat) => {
  const bridge = jsRoutes.controllers.graphql.bridge.GraphQlBridgeController.dispatch().url;
  return await axios
    .post<MarketFilterResponse>(
      bridge,
      {
        functions: [{ function: "market_filters", input: { notionalCurrency: defaultFiat } }],
      },
      { retry: 3 }
    )
    .then(res => get(res, "data.results[0].output.filters", []))
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useRetailMarketFilters = (defaultFiat: CurrencyShortNameFiat) => {
  const { refreshCount } = usePageRefresh();
  const [isLoadingMarketFilters, setIsLoadingMarketFilters] = useState(true);
  const [marketFilters, setMarketFilters] = useState<MarketFilter[]>([]);
  const fetchMarketFilters = useCallback(() => {
    getMarketFilters(defaultFiat)
      .then(data => {
        setMarketFilters(data);
        setIsLoadingMarketFilters(false);
      })
      .catch(() => setIsLoadingMarketFilters(false));
  }, [defaultFiat]);

  useEffect(() => {
    fetchMarketFilters();
  }, [fetchMarketFilters, refreshCount]);

  return {
    marketFilters,
    fetchMarketFilters,
    isLoadingMarketFilters,
  };
};
