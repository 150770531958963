export function USBankIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#D52842" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.371 24.375 10.365 13h19.477v11.375l-9.739 3.281-9.732-3.281ZM25.84 18.34a.773.773 0 0 0-.358-.582 1.082 1.082 0 0 0-.682-.232c-.435 0-.934.093-.934.616 0 .233.188.338.358.42.242.1.63.169 1.08.248.504.088 1.086.19 1.63.368 1.028.337 1.933.925 1.933 2.234 0 2.223-2.151 2.822-4.114 2.822s-3.99-.756-4.037-2.822h2.938a.98.98 0 0 0 .376.71c.24.17.534.253.829.233.405 0 1.046-.152 1.046-.617 0-.466-.264-.582-1.698-.85-2.351-.401-3.303-1.163-3.303-2.513 0-2.019 2.198-2.543 3.814-2.543 1.763 0 3.85.478 3.926 2.531l-2.804-.023Zm-5.747 5.679h-2.974v-1.018h-.03a2.95 2.95 0 0 1-2.662 1.216 2.619 2.619 0 0 1-2.003-.738 2.564 2.564 0 0 1-.77-1.974v-5.492h3.085v4.195c0 .925.112 1.576 1.017 1.576.528 0 1.228-.261 1.228-1.541v-4.23h3.085l.024 8.006Z"
        fill="#fff"
      />
    </svg>
  );
}
