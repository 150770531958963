import { useMemo } from "react";
import { Theme, useTheme } from "@emotion/react";
import { getPercentageNumberFormat, toPercentFloat } from "@gemini-ui/design-system/utils/numberFormatting";

export const ERROR_MSG = "useFormatPercentageDelta: `value` is invalid.";

type ContentColor = Theme["colorScheme"]["content"]["primary"] | Theme["colorScheme"]["content"]["secondary"];

type StatusColor =
  | Theme["colorScheme"]["status"]["default"]["content"]["positive"]
  | Theme["colorScheme"]["status"]["default"]["content"]["negative"];

export type UseFormatPercentageDeltaProps = {
  value: string | number | bigint;
  locale: string;
  isContentSecondary?: boolean;
  parseToFloat?: boolean;
};

/**
 * ### `useFormatPercentageDelta`
 * #### This hook returns a tuple with the formatted + localized percentage string and delta color from a numerical value.
 *
 * @param value - `string` | `number` | `bigint`
 * @param locale
 * @param {boolean} isContentSecondary - A `boolean`. If `true`, returns the secondary content color when rounded value is 0.
 * @param {boolean} [parseToFloat=true] - A `boolean`. If `true`, parses value to percent float.
 * @returns [string, string] - A Tuple containing the formatted string and corresponding delta color.
 */
export function useFormatPercentageDelta({
  locale,
  value,
  isContentSecondary,
  parseToFloat = true,
}: UseFormatPercentageDeltaProps): [string, ContentColor | StatusColor] {
  const { colorScheme } = useTheme();

  // `numFormat.format` returns 0.00% for invalid values.
  // However, expose error in DEV for debugging.
  if (__DEV__ && Number.isNaN(parseFloat(`${value}`))) {
    throw new Error(ERROR_MSG);
  }

  const output: [string, ContentColor | StatusColor] = useMemo(() => {
    const parsed = parseToFloat ? toPercentFloat(value) : Number(value);

    let color: ContentColor | StatusColor = colorScheme.content[isContentSecondary ? "secondary" : "primary"];
    try {
      const numFormat = getPercentageNumberFormat(locale, true);
      const parts = numFormat.formatToParts(parsed);

      /** The color should always match the sign of the number AFTER rounding. Inspecting the parts leads to more accurate results for very small values.
       *
       * @example
       * 0.000000000345 is positive but with our rounding configuration, it returns 0.
       * The color should then be neutral.
       */
      const signPart = parts.find(p => p.type === "minusSign" || p.type === "plusSign");

      if (signPart) {
        const statusColor = colorScheme.status.default.content;
        color = signPart.type === "plusSign" ? statusColor.positive : statusColor.negative;
      }

      return [numFormat.format(parsed), color];
    } catch (e) {
      return [String(parsed), color];
    }
  }, [colorScheme.content, colorScheme.status.default.content, isContentSecondary, locale, parseToFloat, value]);

  return output;
}
