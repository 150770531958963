import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import {
  DefaultDropdownItemProps,
  DropdownProps,
  GroupedDropdownItemProps,
} from "@gemini-ui/design-system/Dropdown/constants";
import { CaretIcon, dropdownStyles, ItemIconContainer } from "@gemini-ui/design-system/Dropdown/styles";
import { getDisplayLabel, getIconAltText, isGroupedItems } from "@gemini-ui/design-system/Dropdown/utils";
import { VirtualizedMenuList } from "@gemini-ui/design-system/Dropdown/VirtualizedMenuList";
import { Flex } from "@gemini-ui/design-system/Flex";
import { FormElementLabel, FormElementMessage, Label } from "@gemini-ui/design-system/formElements";
import { Spacer } from "@gemini-ui/design-system/primitives";
import { Text } from "@gemini-ui/design-system/Text";
import { useIntl } from "@gemini-ui/utils/intl";

// Defining replacement components outside render
// https://github.com/JedWatson/react-select/issues/2810#issuecomment-569117980
const CustomInputComponent = props => (
  <components.Input
    {...props}
    data-testid={props.selectProps.inputId}
    autoComplete={props.selectProps.autoComplete || "nope"}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus={props.selectProps.autoFocus}
  />
);

const CustomDropdownIndicator = dropdownProps => (
  <components.DropdownIndicator {...dropdownProps}>
    <CaretIcon />
  </components.DropdownIndicator>
);

const CustomMenuList = props => {
  return <VirtualizedMenuList {...props} itemHeight={props.selectProps.virtualizedMenuHeight} />;
};

function Dropdown<ValueType>(props: DropdownProps<ValueType>) {
  const {
    "data-testid": dataTestId,
    autoComplete,
    autoFocus,
    className,
    componentOverrides = {},
    componentStyles = {},
    defaultMenuIsOpen,
    disabled = false,
    error,
    isClearable,
    items = [],
    label,
    loadOptions,
    menuIsOpen,
    message,
    name,
    escapeClearsValue,
    onBlur,
    onChange,
    onFocus,
    onInputChange,
    onMenuClose,
    onMenuOpen,
    placeholder = "Select option",
    readOnly,
    typeahead = true,
    value,
    isMulti,
    variant = "lg",
    virtualizedItemHeight,
    isLoading,
    ...remainingProps
  } = props;
  const { intl } = useIntl();

  const formatOptionLabel = (
    { value, label, icon, selectedLabel, subLabel }: DefaultDropdownItemProps<ValueType>,
    { context }
  ) => {
    return (
      <div>
        <Flex align="center" maxWidth="100%">
          {icon && (
            <ItemIconContainer>
              {typeof icon === "string" ? <img src={icon} alt={getIconAltText<ValueType>(label, value, intl)} /> : icon}
            </ItemIconContainer>
          )}
          {getDisplayLabel(selectedLabel, label, context)}
        </Flex>
        {Boolean(subLabel) && <Spacer mb={1.5}>{subLabel}</Spacer>}
      </div>
    );
  };

  const formatGroupLabel = ({ label }: GroupedDropdownItemProps<ValueType>) => {
    return (
      <Text.Body size="xs" bold as="div">
        {label}
      </Text.Body>
    );
  };

  const isGrouped = isGroupedItems(items);
  const sharedProps = {
    isMulti,
    inputId: dataTestId,
    variant,
    readOnly,
    defaultMenuIsOpen,
    menuIsOpen: readOnly ? false : menuIsOpen,
    value,
    escapeClearsValue,
    onChange: readOnly ? undefined : onChange,
    onBlur,
    onFocus,
    onInputChange,
    onMenuClose,
    onMenuOpen,
    isDisabled: disabled,
    isSearchable: readOnly ? false : typeahead,
    autoFocus,
    autoComplete,
    styles: { ...dropdownStyles, ...componentStyles },
    error,
    placeholder,
    formatOptionLabel,
    formatGroupLabel,
    className: name,
    classNamePrefix: name,
    components: {
      Input: CustomInputComponent,
      DropdownIndicator: CustomDropdownIndicator,
      ...(!isGrouped &&
        Boolean(virtualizedItemHeight) && {
          MenuList: CustomMenuList,
        }),
      ...componentOverrides,
    },
    virtualizedItemHeight,
  };

  return (
    <Label data-testid={`${dataTestId}-label`} className={className} {...remainingProps}>
      <FormElementLabel variant={variant} error={Boolean(error)} readOnly={readOnly} disabled={disabled}>
        {label}
      </FormElementLabel>
      {loadOptions ? (
        // @ts-expect-error 02/15/2022 This component should be deprecated soon
        <AsyncSelect
          loadOptions={loadOptions}
          noOptionsMessage={() => intl.formatMessage({ defaultMessage: "No results found." })}
          isClearable={isClearable}
          defaultOptions={items}
          isLoading={isLoading}
          {...sharedProps}
          {...remainingProps.selectProps}
        />
      ) : (
        // @ts-expect-error 02/15/2022 This component should be deprecated soon
        <Select
          options={items}
          noOptionsMessage={() => intl.formatMessage({ defaultMessage: "No items found." })}
          {...sharedProps}
          {...remainingProps.selectProps}
        />
      )}
      {(Boolean(message) || Boolean(error)) && (
        <FormElementMessage
          error={Boolean(error)}
          message={error || message}
          dataTestId={dataTestId}
          variant={variant}
        />
      )}
    </Label>
  );
}

/** @deprecated  please use the Select component instead */
export default Dropdown;
