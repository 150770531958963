import { AriaAttributes, MouseEvent, ReactNode, ThHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { IconTableSortAscending, IconTableSortDescending } from "@hubble/icons";
import { SortDirection } from "@tanstack/react-table";
import { Spacing, text } from "@gemini-ui/design-system/primitives";
import { TCellBase } from "@gemini-ui/design-system/Table/styles";
import { testIds } from "@gemini-ui/design-system/Table/testIds";
import { ScreenReaderOnly } from "@gemini-ui/design-system/utils/ScreenReaderOnly";

const ROW = "row";
const ROW_REVERSE = "row-reverse";

const SortToggle = styled(TCellBase.withComponent("button"))`
  cursor: pointer;
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0 -${Spacing.scale[1]};
  flex-direction: ${({ justifyContent }) => (justifyContent === "end" ? ROW_REVERSE : ROW)};
  justify-content: flex-start;
  &:hover,
  &:focus[data-focus-visible-added] {
    outline: none;
    background: ${({ theme }) => theme.colorScheme.list.background.hover};
  }
`;

const THeaderCell = styled(TCellBase.withComponent("th"))`
  height: 44px;
  font-size: ${text.typesets.body.smBold.fontSize};
  font-weight: ${text.typesets.body.smBold.fontWeight};
  line-height: ${text.typesets.body.smBold.lineHeight};

  &:first-of-type {
    padding-inline-start: 20px;
    ${SortToggle} {
      margin-inline-start: -20px;
      padding-inline-start: 20px;
    }
  }
  &:last-of-type {
    padding-inline-end: 20px;
    ${SortToggle} {
      margin-inline-end: -20px;
      padding-inline-end: 20px;
    }
  }
`;

const VISIBLE = "visible";
const HIDDEN = "hidden";

const IconWrapper = styled("span")<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? VISIBLE : HIDDEN)};
  line-height: 0;
`;

const LabelWrapper = styled("span")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SORT_DIR_MAP: Record<SortDirection, AriaAttributes["aria-sort"]> = {
  asc: "ascending",
  desc: "descending",
};

export function HeaderCell({
  children,
  justifyContent = "start",
  sortDirection,
  visiblyHidden,
  onSortingChange,
  ...rest
}: {
  children: ReactNode;
  justifyContent: "start" | "end" | "center";
  sortDirection: SortDirection | false;
  visiblyHidden?: boolean;
  onSortingChange?: (e: MouseEvent<HTMLButtonElement>) => void;
} & ThHTMLAttributes<HTMLTableCellElement>) {
  const label = <LabelWrapper>{children}</LabelWrapper>;

  const canSort = typeof onSortingChange === "function";
  const ariaSort = sortDirection ? SORT_DIR_MAP[sortDirection] : canSort ? "none" : undefined;
  const Icon = sortDirection === "desc" ? IconTableSortDescending : IconTableSortAscending;

  const headerContent =
    !visiblyHidden && canSort ? (
      <SortToggle data-testid={testIds.sortableHeaderToggle} justifyContent={justifyContent} onClick={onSortingChange}>
        {label}
        <IconWrapper visible={Boolean(sortDirection)} data-testid={testIds.sortIcon + ariaSort} aria-hidden>
          <Icon />
        </IconWrapper>
      </SortToggle>
    ) : (
      label
    );

  return (
    <THeaderCell
      {...rest}
      justifyContent={justifyContent}
      aria-sort={ariaSort}
      title={typeof children === "string" ? children : undefined}
    >
      {visiblyHidden ? (
        <ScreenReaderOnly data-testid={testIds.hiddenColumnHeader}>{headerContent}</ScreenReaderOnly>
      ) : (
        headerContent
      )}
    </THeaderCell>
  );
}
