import { createContext, ReactNode, useContext, useMemo } from "react";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { GrowAsset, GrowAssets } from "@gemini-ui/constants/earn";
import { useStakeWindow } from "@gemini-ui/pages/Earn/hooks/useStakeWindow";
import { mapGrowAssets } from "@gemini-ui/pages/Earn/utils";
import { useStakingAssetData } from "@gemini-ui/services/staking/assetData";

interface StakingAssetsContext {
  assets: Partial<Record<CurrencyShortNameSupportedCryptos, GrowAsset>>;
  /**
   * The asset for the current page, based on the currency in the URL.
   * If the currency is not supported by Grow, this will be `undefined`,
   * or if the URL does not contain a currency.
   */
  pageAsset: GrowAsset;
}

const StakingAssetsContext = createContext<StakingAssetsContext>({
  assets: {},
  pageAsset: undefined,
});

export const StakingAssetsProvider = ({
  children,
  assets: initialAssets,
}: {
  children: ReactNode;
  assets?: GrowAssets[];
}) => {
  const { currency } = useStakeWindow();
  const { data } = useStakingAssetData();

  const values = useMemo(() => {
    const assets = mapGrowAssets(data ? data : initialAssets);
    return {
      assets,
      pageAsset: assets[currency],
    };
  }, [data, initialAssets, currency]);

  return <StakingAssetsContext.Provider value={values}>{children}</StakingAssetsContext.Provider>;
};

export const useStakingAssets = () => {
  const context = useContext(StakingAssetsContext);
  if (!context) {
    throw new Error("useStakingAssets must be used within a StakingAssetsProvider");
  }
  return context;
};
