import { Fragment, ReactNode, useState } from "react";
import { optimizelyClient } from "@gemini-ui/analytics";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankAccountType } from "@gemini-ui/constants/paymentMethods";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Fieldset, Form, Input, Modal, Radio, Text, useForm } from "@gemini-ui/design-system";
import { getWhereDoIFindThisDetails } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/FedWireFlow/constants";
import { defineMessage, IntlShape, useIntl } from "@gemini-ui/utils/intl";
import { getFieldData } from "@gemini-ui/utils/wireFunding";

type AddWireDetailsAccountType = Exclude<BankAccountType, BankAccountType.Brokerage>;

export interface AddWireDetailsFormData {
  accountNumber: string;
  accountType: AddWireDetailsAccountType;
  nameOnAccount: string;
  swiftOrRoutingNumber: string;
}

type AddWireDetailsModalProps = {
  defaultValues?: Partial<AddWireDetailsFormData>;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (formData: Partial<AddWireDetailsFormData>) => void;
};

const getComponentCopy = (intl: IntlShape) => ({
  MODAL_TITLE: intl.formatMessage({
    defaultMessage: "Add bank account for wire transfers",
  }),
  MODAL_BODY: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "<HelpCenterLinkArticlesAddBankAccount>Need help? See FAQ.</HelpCenterLinkArticlesAddBankAccount>",
    }),
    {
      HelpCenterLinkArticlesAddBankAccount: (v: ReactNode) => (
        <HelpCenterLink article={Articles.ADD_BANK_ACCOUNT}>{v}</HelpCenterLink>
      ),
    }
  ),
  ACTION_PRIMARY: intl.formatMessage({
    defaultMessage: "Continue",
  }),
});

const getComponentCopyForRetailAndInstitutionalWireTransfer = (intl: IntlShape, isInstitutional: boolean) => ({
  MODAL_TITLE: intl.formatMessage({
    defaultMessage: "Add bank account for wire transfer",
  }),
  MODAL_BODY: !isInstitutional
    ? intl.formatMessage({
        defaultMessage: "This must be an individual bank account under your name.",
      })
    : null,
  ACTION_PRIMARY: intl.formatMessage({
    defaultMessage: "Review",
  }),
});

const AddWireDetailsModal = ({ isOpen, onClose, onBack, onSubmit, defaultValues = {} }: AddWireDetailsModalProps) => {
  const getDefaultNameOnAccount = (isAddPaymentsRevampEnabled, fullName) => {
    if (!isAddPaymentsRevampEnabled) return "";
    return fullName;
  };
  const {
    templateProps: {
      user: { isInstitutional, countryCode, fullName },
    },
  } = usePageData();
  const isUsUser = countryCode === "us";
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      accountNumber: "",
      bic: "",
      swiftOrRoutingNumber: "",
      accountType: null,
      nameOnAccount: getDefaultNameOnAccount(isNewAddPaymentsFlowEnabled, fullName),
      ...defaultValues,
    },
  });

  const { intl } = useIntl();

  const copy = !isNewAddPaymentsFlowEnabled
    ? getComponentCopy(intl)
    : getComponentCopyForRetailAndInstitutionalWireTransfer(intl, isInstitutional);
  const { nameOnAccount, swiftOrRoutingNumber, accountNumber, accountType } = getFieldData(intl, isUsUser, false);

  return isNewAddPaymentsFlowEnabled ? (
    <Modal.MultiStep isOpen={isOpen} onBack={onBack} onClose={onClose} title={copy.MODAL_TITLE}>
      <Text.Body size="sm" mt={1} mb={3}>
        {copy.MODAL_BODY}
      </Text.Body>
      <Form onSubmit={handleSubmit(onSubmit)} errors={formState.errors} mb={0}>
        {isInstitutional ? (
          <Input
            {...register("nameOnAccount", nameOnAccount.validation)}
            data-testid={`${nameOnAccount.apiField}Input`}
            error={formState.errors?.nameOnAccount?.message as string}
            label={nameOnAccount.label}
            message={nameOnAccount.helperText}
          />
        ) : null}
        <Input
          {...register("accountNumber", accountNumber.validation)}
          data-testid={`${accountNumber.apiField}Input`}
          error={formState.errors?.accountNumber?.message}
          label={accountNumber.label}
          mask={accountNumber.mask}
        />

        <Input
          {...register("swiftOrRoutingNumber", swiftOrRoutingNumber.validation)}
          data-testid={`${swiftOrRoutingNumber.apiField}Input`}
          error={formState.errors?.swiftOrRoutingNumber?.message}
          label={swiftOrRoutingNumber.label}
          mask={swiftOrRoutingNumber.mask}
          message={intl.formatMessage(
            defineMessage({
              defaultMessage:
                "<ShowMoreInfo>Where do i find this?</ShowMoreInfo><DetailedInfoContainer></DetailedInfoContainer>",
            }),
            {
              ShowMoreInfo: (v: ReactNode) => (
                <Text.Link data-testid="fallback-to-wire-verification-link" onClick={() => setShowMoreInfo(true)}>
                  {v}
                </Text.Link>
              ),
              DetailedInfoContainer: () =>
                showMoreInfo ? (
                  <Fragment>
                    <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_ONE}</Text.Body>
                    <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_TWO}</Text.Body>
                    <Text.Body size="xs">{getWhereDoIFindThisDetails(intl).LINE_THREE}</Text.Body>
                  </Fragment>
                ) : null,
            }
          )}
        />

        <Fieldset
          data-testid="account-selector"
          error={formState.errors?.accountType?.message}
          legend={accountType.label}
        >
          <Radio value={BankAccountType.Checking} {...register("accountType", accountType.validation)}>
            {intl.formatMessage({
              defaultMessage: "Checking",
            })}
          </Radio>
          <Radio value={BankAccountType.Savings} {...register("accountType", accountType.validation)}>
            {intl.formatMessage({
              defaultMessage: "Savings",
            })}
          </Radio>
        </Fieldset>
        <Button.Group>
          <Button.Primary data-testid="add-wire-detail-next-button" type="submit">
            {copy.ACTION_PRIMARY}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal.MultiStep>
  ) : (
    <Modal.MultiStep isOpen={isOpen} onBack={onBack} onClose={onClose} title={copy.MODAL_TITLE}>
      <Text.Body size="sm" mt={1} mb={3}>
        {copy.MODAL_BODY}
      </Text.Body>
      <Form onSubmit={handleSubmit(onSubmit)} errors={formState.errors}>
        <Input
          {...register("nameOnAccount", nameOnAccount.validation)}
          data-testid={`${nameOnAccount.apiField}Input`}
          error={formState.errors?.nameOnAccount?.message as string}
          label={nameOnAccount.label}
          message={nameOnAccount.helperText}
        />
        <Fragment>
          <Input
            {...register("swiftOrRoutingNumber", swiftOrRoutingNumber.validation)}
            data-testid={`${swiftOrRoutingNumber.apiField}Input`}
            error={formState.errors?.swiftOrRoutingNumber?.message}
            label={swiftOrRoutingNumber.label}
            mask={swiftOrRoutingNumber.mask}
          />

          <Input
            {...register("accountNumber", accountNumber.validation)}
            data-testid={`${accountNumber.apiField}Input`}
            error={formState.errors?.accountNumber?.message}
            label={accountNumber.label}
            mask={accountNumber.mask}
          />
        </Fragment>

        <Fieldset
          data-testid="account-selector"
          error={formState.errors?.accountType?.message}
          legend={accountType.label}
        >
          <Radio
            data-testid="checking-account-type"
            value={BankAccountType.Checking}
            {...register("accountType", accountType.validation)}
          >
            {intl.formatMessage({
              defaultMessage: "Checking",
            })}
          </Radio>
          <Radio
            data-testid="savings-account-type"
            value={BankAccountType.Savings}
            {...register("accountType", accountType.validation)}
          >
            {intl.formatMessage({
              defaultMessage: "Savings",
            })}
          </Radio>
        </Fieldset>
        <Button.Group>
          <Button.Primary data-testid="add-wire-detail-next-button" type="submit">
            {copy.ACTION_PRIMARY}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal.MultiStep>
  );
};

export default AddWireDetailsModal;
