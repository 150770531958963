import { FeedbackModalProps } from "@gemini-ui/components/Header/FeedbackModal/constants";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const FeedbackModal = LazyModalWrapper(() => import("./index"));

function LazyLoadFeedbackModal({ isOpen, onClose, modalType }: FeedbackModalProps) {
  return <FeedbackModal modalType={modalType} isOpen={isOpen} onClose={onClose} />;
}

export default LazyLoadFeedbackModal;
