import { createContext, PropsWithChildren, useContext, useMemo, useReducer } from "react";
import { isCurrency } from "@gemini-common/scripts/constants/currencies";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { DepositModalView } from "@gemini-ui/pages/Earn/Deposit/types";
import { GrowBuyActionType } from "@gemini-ui/pages/Earn/GrowBuy/context/actions";
import { defaultValues } from "@gemini-ui/pages/Earn/GrowBuy/context/constants";
import { reducer } from "@gemini-ui/pages/Earn/GrowBuy/context/reducers";
import { GrowBuyContextValues, GrowBuyState, GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { constructInitialState, filterStakingAssets } from "@gemini-ui/pages/Earn/GrowBuy/context/utils";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { useStakingAssets } from "@gemini-ui/pages/Earn/hooks/useStakingAssets";
import { checkStakingAssetProductOptions } from "@gemini-ui/pages/Earn/utils";

const GrowBuyProviderContext = createContext(defaultValues);

type ProviderProps = PropsWithChildren<{
  initialState?: Partial<GrowBuyState>;
}>;

const GrowBuyProvider = ({ initialState: initialStateFromProps = {}, children }: ProviderProps) => {
  const { eligibleForStaking, eligibleForPrivateStaking } = useGrowFeatureFlags();
  const { assets } = useStakingAssets();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const stakingAssets = filterStakingAssets(Object.values(assets));

  const [state, dispatch] = useReducer(
    reducer,
    constructInitialState({ asset: stakingAssets?.[0], initialState: initialStateFromProps })
  );

  const { asset, assetProviders, provider } = useMemo(() => {
    const _asset = assets[state.currency];

    if (!_asset) return { asset: null, providers: [] };

    const stakingProductEligibility = checkStakingAssetProductOptions(_asset, {
      eligibleForStaking,
      eligibleForPrivateStaking,
    });

    const { canStakeAssetPooled } = stakingProductEligibility;
    let { canStakeAssetPrivate } = stakingProductEligibility;

    const assetPooledProviders = _asset?.interestProviders?.filter(
      p => p?.providerType === GrowProviderType.POOLED_STAKING
    );
    const assetPrivateProviders = _asset?.interestProviders?.filter(
      p => p?.providerType === GrowProviderType.PRIVATE_STAKING
    );

    // TODO: This can be removed when the following issue is resolved:
    // This is a temporary workaround for an issue where `eligibleForPrivateStaking` returns `false`
    // if the user has all of their ETH staked. We need to check if they have an existing balance and
    // override the eligibility check if they do so that they can unstake.
    if (
      state.transactionType === GrowTransactionType.UNSTAKE &&
      state.providerType === GrowProviderType.PRIVATE_STAKING
    ) {
      if (assetPrivateProviders.some(p => Number(p?.earningInterest?.value ?? 0) > 0)) canStakeAssetPrivate = true;
    }

    // Check if the user has a balance in any of the providers. Users may become ineligible for staking, but have an existing balanace which can be unstaked. (i.e. SG users)
    const hasPooledStakingBalance = assetPooledProviders.some(p => Number(p?.earningInterest?.value ?? 0) > 0);
    const hasPrivateStakingBalance = assetPrivateProviders.some(p => Number(p?.earningInterest?.value ?? 0) > 0);

    const _assetProviders = [
      ...(canStakeAssetPooled || hasPooledStakingBalance ? assetPooledProviders : []),
      ...(canStakeAssetPrivate || hasPrivateStakingBalance ? assetPrivateProviders : []),
    ];

    // This will only return the first provider that matches the desired provider type
    const _provider = _assetProviders?.find(p => p?.providerType === state.providerType);

    return {
      asset: _asset,
      assetProviders: _assetProviders,
      provider: _provider,
    };
  }, [
    state.currency,
    state.transactionType,
    state.providerType,
    assets,
    eligibleForStaking,
    eligibleForPrivateStaking,
  ]);

  /**
   * We need to expose this value to the context b/c UI flows may change depending
   * on whether the user is eligible for private staking.
   *
   * NOTE: If a currency has not been set in this context, this will return `null`
   */
  const shouldDisplayStakingMethodPicker = useMemo(() => {
    if (!state?.currency) return null;
    return isCurrency.ETH(state?.currency) && eligibleForPrivateStaking && eligibleForStaking;
  }, [state?.currency, eligibleForPrivateStaking, eligibleForStaking]);

  const value: GrowBuyContextValues = useMemo(
    () => ({
      ...state,
      asset,
      assetProviders,
      provider,
      tradingPair: Boolean(state?.currency) ? state.currency + defaultFiat : null,
      shouldDisplayStakingMethodPicker,
      stakingAssets,

      /** General state items */
      instantiateGrowTransaction: value => dispatch({ type: GrowBuyActionType.INSTANTIATE_GROW_TRANSACTION, value }),
      setCurrency: value => dispatch({ type: GrowBuyActionType.SET_CURRENCY, value }),
      setTransactionType: value => dispatch({ type: GrowBuyActionType.SET_TRANSACTION_TYPE, value }),
      setPaymentMethod: value => dispatch({ type: GrowBuyActionType.SET_PAYMENT_METHOD, value }),

      /** Router */
      openModal: () => dispatch({ type: GrowBuyActionType.OPEN_MODAL }),
      closeModal: () => dispatch({ type: GrowBuyActionType.CLOSE_MODAL }),
      setModalView: value => dispatch({ type: GrowBuyActionType.SEGUE_MODAL_VIEW, value }),
      // Don't allow back if we're on the modal entry or we're on the success screen
      goBack:
        state.router.currentView === DepositModalView.SUCCESS || state.router?.viewStack?.length < 2
          ? null
          : () => dispatch({ type: GrowBuyActionType.POP_MODAL_VIEW }),

      /** Deposit */
      updateDepositStatus: value => dispatch({ type: GrowBuyActionType.UPDATE_DEPOSIT_STATUS, value }),
      setQuote: value => dispatch({ type: GrowBuyActionType.SET_QUOTE, value }),
      resetQuote: () => dispatch({ type: GrowBuyActionType.RESET_QUOTE }),
      setDepositError: value => dispatch({ type: GrowBuyActionType.SET_DEPOSIT_ERROR, value }),

      /** Redeem */
      updateRedeemStatus: value => dispatch({ type: GrowBuyActionType.UPDATE_REDEEM_STATUS, value }),
    }),
    [state, asset, assetProviders, provider, defaultFiat, shouldDisplayStakingMethodPicker, stakingAssets]
  );

  return <GrowBuyProviderContext.Provider value={value}>{children}</GrowBuyProviderContext.Provider>;
};

const useGrowBuy = () => {
  const context = useContext(GrowBuyProviderContext);

  if (context === undefined) {
    throw new Error(`useGrowBuy must be used within a GrowBuyProviderContext`);
  }

  return context;
};

export { GrowBuyProvider, useGrowBuy };
