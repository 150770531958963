import { useTheme } from "@emotion/react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { Money, MoneyComponentProps } from "@gemini-ui/components/Money";
import { Text } from "@gemini-ui/design-system";
import { sanitizeMaskedInputValue } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { calculateValueIn } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";

export const ConversionTooltip = ({
  currency,
  value = "0",
  defaultFiat,
  lastTradePrice,
  ...rest
}: { defaultFiat: CurrencyShortNameFiat; lastTradePrice: string } & MoneyComponentProps) => {
  const { colorScheme } = useTheme();

  const approxValue = calculateValueIn({
    value: sanitizeMaskedInputValue(value.toString()),
    direction: currency === defaultFiat ? defaultFiat : "crypto",
    lastTradePrice,
    defaultFiat,
  });

  return (
    <Text.Body size="sm" color={colorScheme.content.secondary} bold>
      <Money data-testid={testIds.buyModule.input.conversionAmount} currency={currency} value={approxValue} {...rest} />
    </Text.Body>
  );
};
