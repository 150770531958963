import { Fragment } from "react";
import {
  INTERFACE_MODES,
  TEST_IDS,
} from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/constants";
import { useTradeUI } from "@gemini-ui/components/Header/navigation/TradingInterfaceSwitcher/utils";
import { usePageData } from "@gemini-ui/contexts";
import { HubbleMenu } from "@gemini-ui/design-system";
import { isDerivativesEnabled } from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";

export const AccountMenuInterfaceSwitcher = () => {
  const { intl } = useIntl();

  const {
    templateProps: { user },
  } = usePageData();
  const { isActiveTrader, isUpdatingUI, switchTradeUI } = useTradeUI();

  const isDerivativesAccount = isDerivativesEnabled(user);

  const mode = isActiveTrader ? INTERFACE_MODES.activeTrader : INTERFACE_MODES.simple;

  // Switch interfaces or route to active interface if not on it's page
  const handleSwitcher = (val: string) => {
    if (val !== mode) {
      switchTradeUI();
    } else {
      window.location.assign(jsRoutes.controllers.dashboard.DashboardController.main().url);
    }
  };

  return (
    <Fragment>
      <HubbleMenu.RadioGroup value={mode} onValueChange={val => handleSwitcher(val)}>
        <HubbleMenu.Label>{intl.formatMessage({ defaultMessage: "Trading mode" })}</HubbleMenu.Label>
        <HubbleMenu.RadioItem
          data-testid={TEST_IDS.simple_interface_button}
          value={INTERFACE_MODES.simple}
          label={intl.formatMessage({ defaultMessage: "Simple" })}
          description={intl.formatMessage({ defaultMessage: "Simple and easy-to-use" })}
          disabled={isDerivativesAccount || isUpdatingUI}
        />
        <HubbleMenu.RadioItem
          data-testid={TEST_IDS.active_interface_button}
          value={INTERFACE_MODES.activeTrader}
          label={intl.formatMessage({ defaultMessage: "ActiveTrader™" })}
          description={intl.formatMessage({ defaultMessage: "Access advanced orders and charts" })}
          disabled={isUpdatingUI}
        />
      </HubbleMenu.RadioGroup>
    </Fragment>
  );
};
