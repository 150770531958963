import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { AccountMenuTitleText } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/styles";
import {
  AccountMenuTitleProps,
  ControlledTextProps,
} from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/types";
import { Flex, Tooltip } from "@gemini-ui/design-system";

export const AccountMenuTitle = ({
  isLink = false,
  groupName,
  institutionName,
  title,
  userName,
}: AccountMenuTitleProps) => {
  const { colorScheme } = useTheme();

  const mainText = isLink ? title : userName;
  const subText = groupName;

  return (
    <Flex flexDirection="column" p={!isLink && 1}>
      <ControlledText text={mainText} bold />
      {institutionName && <ControlledText text={subText} size="xs" color={colorScheme.content.secondary} />}
    </Flex>
  );
};

export const ControlledText = ({ text, size = "sm", color, bold = false }: ControlledTextProps) => {
  const ref = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    ref.current && setShowTooltip(ref.current.offsetWidth > 199);
  }, [ref]);

  return (
    <Flex>
      <Tooltip overlay={text} disabled={!showTooltip} placement="left">
        <AccountMenuTitleText ref={ref} size={size} color={color && color} bold={bold}>
          {text}
        </AccountMenuTitleText>
      </Tooltip>
    </Flex>
  );
};
