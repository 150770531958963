import { createContext, FC, PropsWithChildren, useContext } from "react";
import { Refresher } from "@gemini-ui/constants/refresher";

export type RefreshContextType = {
  refreshCount: number;
} & Refresher;

const RefresherContext = createContext<RefreshContextType | null>(null);
export const usePageRefresh = () => useContext(RefresherContext);

// In a future PR, move the AutoRefresh logic into this provider.
export const RefresherProvider: FC<PropsWithChildren<RefreshContextType>> = ({ children, ...refresherProps }) => (
  <RefresherContext.Provider value={refresherProps}>{children}</RefresherContext.Provider>
);
