import { ReactNode, useMemo } from "react";
import * as Sentry from "@sentry/browser";
import BigNumber from "bignumber.js";
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getDate,
  isAfter,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parse,
  setDate,
  setDay,
} from "date-fns";
import { format as formatTZ } from "date-fns-tz";
import {
  CURRENCIES_DETAIL,
  CurrencyShortName,
  CurrencyShortNameFiat,
  CurrencyShortNameSupportedCryptos,
  isCurrency,
  SupportedCurrencyPairs,
} from "@gemini-common/scripts/constants/currencies";
import {
  track,
  trackBingEvent,
  trackFacebookPixelEvent,
  trackGTMEvent,
  trackRedditEvent,
  trackTikTokEvent,
  trackTwitterEvent,
} from "@gemini-ui/analytics";
import {
  AnalyticsProperty,
  BingEvents,
  BingVariableRevenueProperty,
  FacebookEvents,
  GoogleAnalyticsEvents,
  GoogleTagManagerEvents,
  RedditEvents,
  TikTokEvents,
  TwitterEvents,
} from "@gemini-ui/analytics/constants/events";
import { TRADE_EVENTS } from "@gemini-ui/analytics/constants/trade";
import { trackGoogleAnalyticsEvent } from "@gemini-ui/analytics/googleTracking";
import { snapchatTrack, snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import { setTradePeopleProperties } from "@gemini-ui/analytics/tradeEvents";
import { ReferralProgress, ReferralTier } from "@gemini-ui/client/rewards-v2";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { Balances, CurrencyBalances } from "@gemini-ui/constants/balances";
import { GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { GUSD_CHART_FIXTURE, GUSD_PAIR_DETAIL_FIXTURE } from "@gemini-ui/constants/GUSD";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Text } from "@gemini-ui/design-system";
import { highestYieldProvider } from "@gemini-ui/pages/Earn/utils";
import { AssetSelectOptionProps } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/components/AssetSelector/components";
import {
  BuyReviewFlowModal,
  QuoteError,
  QuoteErrorType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/constants";
import { SubTitle } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/SuccessModal/styles";
import {
  Action,
  BuyingFrequency,
  DAYS_NUM_MAP,
  TrackTrade,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType, PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { orderAchMethods, orderDebitCards } from "@gemini-ui/pages/RetailTrade/PaymentMethod/SelectPaymentMethod/utils";
import {
  getEligibleBanks,
  getEligiblePayPalAccounts,
  getGeminiBalance,
  isAmountInRemainingLimit,
} from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { RewardTierResponse } from "@gemini-ui/pages/RewardsHub/apiHooks";
import { UsePaymentData } from "@gemini-ui/services/transfer/types";
import { DebitCardType, RetailTradePaymentMethodType } from "@gemini-ui/transformers/PaymentMethods";
import { DateFormats, TimeFormats } from "@gemini-ui/utils/dateTimeFormats";
import { formatNumber } from "@gemini-ui/utils/formatNumber";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export enum CalculatedDirection {
  Crypto = "crypto",
}

export const calculateValueIn = ({
  value,
  direction = "crypto",
  lastTradePrice,
  defaultFiat,
  convertFrom,
  action,
  isLimitOrder = false,
  limitPrice = "",
}: {
  value: string;
  direction: "crypto" | CurrencyShortNameFiat;
  lastTradePrice: string;
  defaultFiat: CurrencyShortNameFiat;
  convertFrom?: CurrencyShortNameSupportedCryptos;
  action?: Action;
  isLimitOrder?: boolean;
  limitPrice?: string;
}) => {
  if (!value) return "0";

  const currentPrice = Number(lastTradePrice || "0");

  // user is buying crypto
  if (direction === "crypto") {
    if (isLimitOrder) return `${Number(value) / Number(limitPrice)}`;
    else return `${Number(value) / currentPrice}`;
  }
  // user is selling crypto
  if (direction === defaultFiat || (action === Action.CONVERT && convertFrom)) {
    if (isLimitOrder) return `${Number(value) * Number(limitPrice)}`;
    else return `${Number(value) * currentPrice}`;
  }

  return "0";
};

// TODO: track interface
export const trackTrade = ({
  assetEarnApy,
  assetName,
  currency,
  dates = [],
  dayOfWeek,
  defaultFiat,
  fee,
  pageName,
  orderType,
  paymentMethod,
  price,
  quantity,
  quickBuy,
  schedule,
  side,
  total,
  tradingPair,
  triggerHour,
  usedSuggestedAmounts,
  value,
}: TrackTrade) => {
  try {
    const { name, properties } = TRADE_EVENTS.PLACE_TRADE;
    const tradeProperties: AnalyticsProperty = {
      [properties.CURRENCY_PAIR]: tradingPair,
      [properties.FEE]: fee,
      [properties.FREQUENCY]: schedule,
      [properties.ORDER_TYPE]: orderType,
      [properties.PAYMENT_METHOD]: paymentMethod,
      [properties.PRICE]: price,
      [properties.QUANTITY]: quantity,
      [properties.QUICK_BUY]: quickBuy,
      [properties.RECURRING_DAY_OF_MONTH]: dates.length === 1 ? String(dates[0]) : undefined,
      [properties.RECURRING_DAY_OF_WEEK]: dayOfWeek,
      [properties.RECURRING_TWICE_MONTHLY]: dates.length === 2 ? dates : undefined,
      [properties.SIDE]: side,
      [properties.TIME_EXECUTED]: triggerHour,
      [properties.TOTAL]: total,
      [properties.USED_SUGGESTED_AMOUNTS]: usedSuggestedAmounts,
      [properties.ASSET_EARN_APY]: assetEarnApy,
      [properties.ASSET_NAME]: assetName,
      [properties.CURRENCY]: currency,
      [properties.VALUE]: value,
      [properties.PAGE_NAME]: pageName,
    } as const;
    const totalValue = parseFloat(total.toString()).toFixed(2);
    trackGoogleAnalyticsEvent(GoogleAnalyticsEvents.PlaceTrade, {
      side,
    });
    track(name, tradeProperties, { optimizely: true });
    snapchatTrack(snapEvents.placeTrade);
    setTradePeopleProperties();
    trackFacebookPixelEvent(FacebookEvents.Purchase, { currency: defaultFiat, value: totalValue });
    trackTwitterEvent(TwitterEvents.PlaceTrade);

    trackRedditEvent(RedditEvents.PlaceTrade);
    trackTikTokEvent(TikTokEvents.PlaceTrade);
    trackBingEvent(BingEvents.PlaceTrade, {
      [BingVariableRevenueProperty.RevenueValue]: totalValue,
      [BingVariableRevenueProperty.Currency]: defaultFiat,
    });
    trackGTMEvent(GoogleTagManagerEvents.TRADE);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getStartOnDate = (
  triggerHour: string,
  { schedule, weeklyFrequency, twiceMonthlyFrequency, monthlyFrequency }
) => {
  const now = new Date();
  const todayWithTime = parse(triggerHour, TimeFormats.Hour24Minute, new Date());
  let selectedDate = todayWithTime;

  if (schedule === BuyingFrequency.Daily) {
    if (isAfter(now, todayWithTime)) {
      selectedDate = addDays(todayWithTime, 1);
    }
  } else if (schedule === BuyingFrequency.Weekly) {
    selectedDate = setDay(selectedDate, DAYS_NUM_MAP[weeklyFrequency]);
    if (isAfter(now, selectedDate)) {
      selectedDate = addWeeks(selectedDate, 1);
    }
  } else if (schedule === BuyingFrequency.Biweekly) {
    const [firstDate, secondDate] = twiceMonthlyFrequency;
    selectedDate = setDate(selectedDate, firstDate);
    if (isAfter(now, selectedDate)) {
      selectedDate = setDate(selectedDate, secondDate);
      if (isAfter(now, selectedDate)) {
        selectedDate = addMonths(setDate(selectedDate, firstDate), 1);
      }
    }
  } else if (schedule === BuyingFrequency.Monthly) {
    const [day] = monthlyFrequency;
    selectedDate = setDate(selectedDate, day);
    if (getDate(now) > day) {
      selectedDate = addMonths(selectedDate, 1);
    }
  }

  return selectedDate;
};

export const formattedTime = triggerHour => {
  return triggerHour && formatTZ(parse(triggerHour, TimeFormats.Hour24Minute, new Date()), "h a z");
};

export const recurringOrderMessage = ({
  schedule,
  triggerHour,
  startOn,
  twiceMonthlyFrequency,
  monthlyFrequency,
  intl,
}: {
  schedule: BuyingFrequency;
  triggerHour: string;
  startOn: Date;
  twiceMonthlyFrequency: string | ReactNode;
  monthlyFrequency: string | ReactNode;
  intl: IntlShape;
}) => {
  const time = formattedTime(triggerHour);
  if (schedule === BuyingFrequency.Daily) {
    return intl.formatMessage(
      defineMessage({
        defaultMessage: "Around • {timeString}",
      }),
      {
        timeString: time,
      }
    );
  }
  if (schedule === BuyingFrequency.Weekly) {
    return intl.formatMessage(
      defineMessage({
        defaultMessage: "{dayOfWeek} • Around {timeString}",
      }),
      {
        dayOfWeek: localizeDayOfWeek({ startOn, intl }),
        timeString: time,
      }
    );
  }
  if (schedule === BuyingFrequency.Biweekly) {
    return intl.formatMessage(
      defineMessage({
        defaultMessage: "{biweeklyStr} • Around {timeString}",
      }),
      {
        biweeklyStr: twiceMonthlyFrequency,
        timeString: time,
      }
    );
  }
  if (schedule === BuyingFrequency.Monthly) {
    return intl.formatMessage(
      defineMessage({
        defaultMessage: "On the {monthlyStr} • Around {timeString}",
      }),
      {
        monthlyStr: monthlyFrequency,
        timeString: time,
      }
    );
  }
};

const localizeDayOfWeek = ({ startOn, intl }: { startOn: Date; intl: IntlShape }) => {
  if (isMonday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Monday" });
  } else if (isTuesday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Tuesday" });
  } else if (isWednesday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Wednesday" });
  } else if (isThursday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Thursday" });
  } else if (isFriday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Friday" });
  } else if (isSaturday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Saturday" });
  } else if (isSunday(startOn)) {
    return intl.formatMessage({ defaultMessage: "Sunday" });
  } else {
    return "";
  }
};

type getToAndFromCurrencyArgs = {
  action: Action;
  defaultFiat: CurrencyShortNameFiat;
  currency: CurrencyShortName;
  convertTo: CurrencyShortName;
  isAmountDenotedInPriceCurrency: boolean;
};

export const getToAndFromCurrency = ({
  action,
  defaultFiat,
  currency,
  convertTo,
  isAmountDenotedInPriceCurrency,
}: getToAndFromCurrencyArgs) => {
  let fromCurrency: CurrencyShortName, toCurrency: CurrencyShortName, totalSpendCurrency: CurrencyShortName;

  if (action === Action.BUY) {
    fromCurrency = defaultFiat;
    toCurrency = currency;
    totalSpendCurrency = isAmountDenotedInPriceCurrency ? fromCurrency : toCurrency;
  } else if (action === Action.CONVERT) {
    fromCurrency = currency;
    toCurrency = convertTo;
  } else {
    fromCurrency = currency;
    toCurrency = defaultFiat;
    totalSpendCurrency = isAmountDenotedInPriceCurrency ? toCurrency : fromCurrency;
  }

  return { toCurrency, fromCurrency, totalSpendCurrency };
};

export const getQuoteError = (err, intl: IntlShape): string => getTradeQuoteError(err, intl).message;

export const getTradeQuoteError = (quoteError, intl: IntlShape): QuoteError => {
  const defaultError = intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." });
  const noValueEnteredError = "Cannot parse parameter as a valid number";
  const missingSpendParameter = "Missing parameter: totalSpend";
  const error400 = quoteError?.response?.data?.error;
  const errorData = error400?.data;
  let message = "";
  let errorType = QuoteErrorType.Order;

  if (error400) {
    if (error400 === noValueEnteredError || error400 === missingSpendParameter) {
      message = intl.formatMessage({ defaultMessage: "Please enter a valid number to continue." });
      errorType = QuoteErrorType.Form;
    } else {
      message = defaultError;
    }
  }
  if (errorData === "Your order quantity is below the market minimum.") {
    message = errorData;
  }
  if (!message) {
    message = quoteError?.response?.data?.quoteError?.data || defaultError;
  }

  return { message, errorType };
};

export const getHoldPeriodTime = (selectedPaymentMethod: RetailTradePaymentMethodType, intl: IntlShape) => {
  const days = {
    [PaymentMethodType.DEBIT_CARD]: intl.formatMessage({ defaultMessage: "24 hours" }),
    [PaymentMethodType.BANK]: intl.formatMessage({ defaultMessage: "4-5 business days" }),
    [PaymentMethodType.PAYPAL]: intl.formatMessage({ defaultMessage: "4-5 business days" }),
  };

  return days[selectedPaymentMethod.paymentMethodType];
};

const getDateOrdinal = (date: number) => format(parse(date.toString(), DateFormats.Day, new Date()), "do");

export const getRecurringMessage = ({
  action,
  recurringOrderDetails,
  currency,
  orderNotionalValue,
  isBuyAndEarn,
  isBuyAndStake,
  intl,
  textAlign = "center",
}: {
  action?: Action;
  recurringOrderDetails: {
    schedule: BuyingFrequency;
    time?: string;
    day?: string;
    dates?: number[];
  };
  currency: CurrencyShortName;
  orderNotionalValue: MoneyProps;
  isBuyAndEarn?: boolean;
  isBuyAndStake?: boolean;
  intl: IntlShape;
  textAlign?: "left" | "center";
}) => {
  const styleProps = { textAlign };

  const { schedule, day, time, dates } = recurringOrderDetails;
  switch (schedule) {
    case BuyingFrequency.Daily:
      return (
        <SubTitle data-testid={testIds.buySell.successModalRecurringMessageDetails} {...styleProps}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You {action} a recurring buy order for <b><OrderValue></OrderValue></b> of <b>{currency}</b>. It will reoccur daily at approximately {time}.{earnMessage}",
            }),
            {
              action: "placed",
              b: (v: ReactNode) => <strong>{v}</strong>,
              OrderValue: () => <Money {...orderNotionalValue} />,
              currency,
              time: formattedTime(time),
              earnMessage: isBuyAndEarn
                ? " This purchase will be transferred to Earn and begin earning interest within one business day."
                : "",
            }
          )}
        </SubTitle>
      );
    case BuyingFrequency.Weekly:
      return (
        <SubTitle data-testid={testIds.buySell.successModalRecurringMessageDetails} {...styleProps}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You {action} a recurring buy order for <b><OrderValue></OrderValue></b> of <b>{currency}</b>. It will reoccur weekly on {day}s at approximately {time}.{earnMessage}",
            }),
            {
              action: "placed",
              b: (v: ReactNode) => <strong>{v}</strong>,
              OrderValue: () => <Money {...orderNotionalValue} />,
              currency,
              day,
              time: formattedTime(time),
              earnMessage: isBuyAndEarn
                ? " This purchase will be transferred to Earn and begin earning interest within one business day."
                : "",
            }
          )}
        </SubTitle>
      );
    case BuyingFrequency.Biweekly:
      return (
        <SubTitle data-testid={testIds.buySell.successModalRecurringMessageDetails} {...styleProps}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You {action} a recurring buy order for <b><OrderValue></OrderValue></b> of <b>{currency}</b>. It will reoccur on the {ordinalDateStart} and {ordinalDateEnd} of every month at approximately {time}.{earnMessage}",
            }),
            {
              action: "placed",
              b: (v: ReactNode) => <strong>{v}</strong>,
              OrderValue: () => <Money {...orderNotionalValue} />,
              currency,
              ordinalDateStart: getDateOrdinal(dates[0]),
              ordinalDateEnd: getDateOrdinal(dates[1]),
              time: formattedTime(time),
              earnMessage: isBuyAndEarn
                ? " This purchase will be transferred to Earn and begin earning interest within one business day."
                : "",
            }
          )}
        </SubTitle>
      );

    case BuyingFrequency.Monthly:
      return (
        <SubTitle data-testid={testIds.buySell.successModalRecurringMessageDetails} {...styleProps}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "You {action} a recurring buy order for <b><OrderValue></OrderValue></b> of <b>{currency}</b>. It will reoccur on the {ordinalDateStart} of every month at approximately {time}.{earnMessage}",
            }),
            {
              action: "placed",
              b: (v: ReactNode) => <strong>{v}</strong>,
              OrderValue: () => <Money {...orderNotionalValue} />,
              currency,
              ordinalDateStart: getDateOrdinal(dates[0]),
              time: formattedTime(time),
              earnMessage: isBuyAndEarn
                ? " This purchase will be transferred to Earn and begin earning interest within one business day."
                : "",
            }
          )}
        </SubTitle>
      );
    default:
      return null;
  }
};

export const getComponentCopy = (intl: IntlShape, currency?: CurrencyShortName, crypto?: SupportedCurrencyPairs) => ({
  INSTANT: intl.formatMessage({
    defaultMessage: "Instant",
    description: "Specifies what type of order the user will be making",
  }),
  INSTANT_SELL: intl.formatMessage({
    defaultMessage: "Instant sell",
    description: "Specifies what type of order the user will be making",
  }),
  CONVERT: intl.formatMessage({
    defaultMessage: "Convert",
    description: "Specifies what type of order the user will be making",
  }),
  LIMIT: intl.formatMessage({
    defaultMessage: "Limit",
    description: "Specifies what type of order the user will be making",
  }),
  ERROR_HEADING: intl.formatMessage({ defaultMessage: "Unable to fetch order details" }),
  LOOKING_FOR_ORDER_TYPES: intl.formatMessage({ defaultMessage: "Looking for more order types?" }),
  SWITCH_TO_AT: intl.formatMessage({ defaultMessage: "Switch to ActiveTrader™" }),
  SWITCH: intl.formatMessage({ defaultMessage: "Switch" }),
  ORDER_TYPES_HEADER: intl.formatMessage({ defaultMessage: "Order types" }),
  ORDER_TYPE: intl.formatMessage({ defaultMessage: "Order type" }),
  ORDER_STATUS: intl.formatMessage({
    defaultMessage: "Order status",
  }),
  OPEN: intl.formatMessage({
    defaultMessage: "Open",
  }),
  CANCELED: intl.formatMessage({
    defaultMessage: "Canceled",
  }),
  FILLED: intl.formatMessage({
    defaultMessage: "Filled",
  }),
  PARTIALLY_FILLED: intl.formatMessage({
    defaultMessage: "Partially filled",
  }),
  QUANTITY: intl.formatMessage({
    defaultMessage: "Quantity",
  }),
  INITIATED_ON: intl.formatMessage({
    defaultMessage: "Initiated on",
  }),
  UPDATED_ON: intl.formatMessage({
    defaultMessage: "Updated on",
  }),
  FILLED_ON: intl.formatMessage({
    defaultMessage: "Filled on",
  }),
  CANCELED_ON: intl.formatMessage({
    defaultMessage: "Canceled on",
  }),
  INSTANT_DESCRIPTION: intl.formatMessage({
    defaultMessage: "Buy crypto right now or set up future buys at the current market price.",
  }),
  LIMIT_DESCRIPTION: intl.formatMessage({
    defaultMessage: "Buy crypto when your custom (limit) price is reached.",
  }),
  LIMIT_PRICE_LABEL: intl.formatMessage({
    defaultMessage: "Limit price",
    description: "The label for an input which specifies the limit of a price at which to execute the order",
  }),
  AMOUNT_LABEL: intl.formatMessage({
    defaultMessage: "Amount",
    description: "The amount entered of the selected currency.",
  }),
  LIMIT_PRICE_MESSAGE: intl.formatMessage({ defaultMessage: "Current market price:" }),
  INFO_ICON_BTN_LABEL: intl.formatMessage({ defaultMessage: "Limit order information" }),
  ENTER_AS_CURRENCY: intl.formatMessage(
    defineMessage({
      defaultMessage: "Enter amount as {currency}",
      description: "Describes a button action to enter the amount in the user's currency",
    }),
    { currency }
  ),
  ENTER_AS_CRYPTO: intl.formatMessage(
    defineMessage({
      defaultMessage: "Enter amount as {crypto}",
      description: "Describes a button action to enter the amount in the selected crytpocurrency",
    }),
    { crypto }
  ),
  LIMIT_ORDER: intl.formatMessage({
    defaultMessage: "Limit order",
    description: "A heading to clearly indicate the type of order being made",
  }),
  LEARN_MORE: intl.formatMessage({
    defaultMessage: "Learn more",
  }),
  CLOSE: intl.formatMessage({
    defaultMessage: "Close",
  }),
  CANCEL_ORDER: intl.formatMessage({
    defaultMessage: "Cancel order",
  }),
  DONE: intl.formatMessage({
    defaultMessage: "Done",
  }),
  LIMIT_PRICE_INFO: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "<BoldLabel>Limit price:</BoldLabel><SubLabel>The price at which you would like your order to fill.</SubLabel>",
      description: "Information describing the limit price input field",
    }),
    {
      BoldLabel: (str: ReactNode) => (
        <Text.Body bold size="sm">
          {str}
        </Text.Body>
      ),
      SubLabel: (str: ReactNode) => (
        <Text.Body size="sm" mb={3}>
          {str}
        </Text.Body>
      ),
    }
  ),
  AMOUNT_INFO: intl.formatMessage(
    defineMessage({
      defaultMessage:
        "<BoldLabel>Amount:</BoldLabel><SubLabel>The quantity you wish to trade when your limit price is reached.</SubLabel>",
      description: "Information describing the amount input field for a limit order",
    }),
    {
      BoldLabel: (str: ReactNode) => (
        <Text.Body bold size="sm">
          {str}
        </Text.Body>
      ),
      SubLabel: (str: ReactNode) => <Text.Body size="sm">{str}</Text.Body>,
    }
  ),
  LIMIT_PRICE: intl.formatMessage({
    defaultMessage: "Limit price",
  }),
  DETAILS: intl.formatMessage({
    defaultMessage: "Details",
  }),
  TYPE: intl.formatMessage({ defaultMessage: "Type" }),
  FROM: intl.formatMessage({ defaultMessage: "From" }),
  TO: intl.formatMessage({ defaultMessage: "To" }),
  EXCHANGE_RATE: intl.formatMessage({ defaultMessage: "Exchange rate" }),
  RECEIVING: intl.formatMessage({ defaultMessage: "Receiving" }),
  FREQUENCY: intl.formatMessage({
    defaultMessage: "Frequency",
  }),
  CARD_PROCESSING_FEE: intl.formatMessage({
    defaultMessage: "Platform fee",
  }),
  PROCESSING_FEE: intl.formatMessage({
    defaultMessage: "Processing fee",
  }),
  WRAPPED_AMOUNT: intl.formatMessage({
    defaultMessage: "Wrapped amount",
  }),
  FILLED_QUANTITY: intl.formatMessage({
    defaultMessage: "Filled quantity",
  }),
  FILLED_PRICE: intl.formatMessage({
    defaultMessage: "Filled price",
  }),
  LIMIT_BUY: intl.formatMessage({ defaultMessage: "Limit buy" }),
  LIMIT_SELL: intl.formatMessage({ defaultMessage: "Limit sell" }),
  PAYMENT_METHOD: intl.formatMessage({
    defaultMessage: "Payment method",
  }),
  CRYPTO_LIMIT_PRICE: intl.formatMessage(
    defineMessage({
      defaultMessage: "{currency} limit price",
      description: "For example, 'ETH limit price'",
    }),
    {
      currency,
    }
  ),
  ENTERED_AMOUNT: intl.formatMessage({
    defaultMessage: "Entered amount",
  }),
  FEE: intl.formatMessage({
    defaultMessage: "Fee",
  }),
  REWARDS: intl.formatMessage({
    defaultMessage: "Estimated rewards",
  }),
  CONVERT_FEES_TOOLTIP: intl.formatMessage({
    defaultMessage:
      "Transaction fees are the sum of Gemini fees and market spread. The spread varies due to market demand for the convert pair.",
  }),
  NOTIONAL_VALUE: intl.formatMessage({
    defaultMessage: "Notional value",
  }),
  SUBTOTAL: intl.formatMessage({
    defaultMessage: "Subtotal",
  }),
  TOTAL: intl.formatMessage({
    defaultMessage: "Total",
  }),
  TOTAL_DUE_TO_YOU: intl.formatMessage({
    defaultMessage: "Total due to you",
  }),
  EDIT: intl.formatMessage({
    defaultMessage: "Edit",
  }),
  FEES_TAXES: intl.formatMessage({
    defaultMessage: "Fees & taxes",
  }),
  READ_MORE: intl.formatMessage({ defaultMessage: "Read more", description: "Click to see more text" }),
  READ_LESS: intl.formatMessage({ defaultMessage: "Read less", description: "Click to see less text" }),
  TERMS: intl.formatMessage({ defaultMessage: "Terms and Conditions" }),
  OK: intl.formatMessage({ defaultMessage: "Ok" }),
  OPEN_LIMIT_ORDERS: intl.formatMessage({ defaultMessage: "Open limit orders" }),
  VIEW_MORE: intl.formatMessage({ defaultMessage: "View more" }),
  WAIVED: intl.formatMessage({ defaultMessage: "Waived" }),
});

export const getConvertTitle = (currency: CurrencyShortName, intl: IntlShape) => {
  switch (currency) {
    case CURRENCIES_DETAIL.EFIL.symbol:
      return intl.formatMessage({ defaultMessage: "Unwrap" });
    case CURRENCIES_DETAIL.FIL.symbol:
      return intl.formatMessage({ defaultMessage: "Wrap" });
    default:
      return intl.formatMessage({ defaultMessage: "Convert" });
  }
};

export const getActionText = (currency: CurrencyShortName, action: Action, intl: IntlShape) => {
  switch (action) {
    case Action.CONVERT:
      return getConvertTitle(currency, intl);
    case Action.BUY:
      return intl.formatMessage({ defaultMessage: "Buy" });
    case Action.SELL:
      return intl.formatMessage({ defaultMessage: "Sell" });
    default:
      return action;
  }
};

export const getBuySellButtonCta = (action: Action, currency: CurrencyShortName, intl: IntlShape) => {
  if (isCurrency.EFIL(currency)) {
    return action === Action.BUY
      ? intl.formatMessage({ defaultMessage: "Buy and wrap FIL" })
      : intl.formatMessage({ defaultMessage: "Unwrap my EFIL" });
  }

  return intl.formatMessage({ defaultMessage: "Review order" });
};

export const getActionTypeMessage = (currency: CurrencyShortName, intl: IntlShape) => {
  switch (currency) {
    case CURRENCIES_DETAIL.EFIL.symbol:
      return intl.formatMessage({ defaultMessage: "unwrapped" });
    case CURRENCIES_DETAIL.FIL.symbol:
      return intl.formatMessage({ defaultMessage: "wrapped" });
    default:
      return intl.formatMessage({ defaultMessage: "converted" });
  }
};

const getConvertTypeMessage = (currency: CurrencyShortName, intl: IntlShape) => {
  switch (currency) {
    case CURRENCIES_DETAIL.EFIL.symbol:
      return intl.formatMessage({ defaultMessage: "unwrap" });
    case CURRENCIES_DETAIL.FIL.symbol:
      return intl.formatMessage({ defaultMessage: "wrap" });
    default:
      return intl.formatMessage({ defaultMessage: "convert" });
  }
};

export const useDefaultPaymentMethod = ({
  balances,
  defaultFiat,
  paymentMethodData,
  recurringOrderViaDebitCardEnabled,
  frequency,
}: {
  balances: CurrencyBalances;
  paymentMethodData: UsePaymentData;
  defaultFiat: CurrencyShortNameFiat;
  recurringOrderViaDebitCardEnabled: boolean;
  frequency: BuyingFrequency;
}) => {
  const { paymentMethods, limits } = paymentMethodData;
  const isRecurringOrderDisabled = !recurringOrderViaDebitCardEnabled && frequency !== BuyingFrequency.Once;

  const visibleCards = paymentMethods.filter(
    (paymentMethod): paymentMethod is DebitCardType =>
      paymentMethod.paymentMethodType === PaymentMethodType.DEBIT_CARD && paymentMethod.currencies.includes(defaultFiat)
  );
  const orderedCards = useMemo(() => orderDebitCards(visibleCards ?? []), [visibleCards]);

  const cash = useMemo(() => {
    if (Object.keys(balances).length === 0) return null;

    const cashBalance = getGeminiBalance(balances, defaultFiat);

    return {
      balance: cashBalance > 1 ? cashBalance : null,
      method: AccountType.BALANCE,
    };
  }, [balances, defaultFiat]);

  const latestBank = useMemo(() => {
    if (paymentMethods.length === 0 || !isCurrency.USD(defaultFiat)) return null;

    const eligibleBanks = getEligibleBanks(paymentMethods, defaultFiat);
    if (eligibleBanks.length === 0) return null;

    const limitReached = limits?.ach.daily ? Number(limits.ach.daily.available.value) === 0 : false;

    return !limitReached ? orderAchMethods(eligibleBanks)[0] : null;
  }, [defaultFiat, paymentMethods, limits]);

  const latestCard = useMemo(() => {
    const verifiedCards = orderedCards.filter(cardInfo => {
      const cardNotVerified = cardInfo.approvalState === "Pending";
      const verificationDisabled = !cardInfo.canGenerateAuth && !cardInfo.canVerifyAuth;

      return !cardNotVerified && !verificationDisabled;
    });

    return verifiedCards.length > 0 && !isRecurringOrderDisabled ? verifiedCards[0] : null;
  }, [isRecurringOrderDisabled, orderedCards]);

  const payPalMethod = useMemo(() => {
    const eligibleAccounts = getEligiblePayPalAccounts(paymentMethods, defaultFiat);

    if (eligibleAccounts.length === 0) return null;
    const limitReached = limits ? Number(limits?.payPal?.daily?.available.value) === 0 : false;

    return !limitReached ? eligibleAccounts[0] : null;
  }, [defaultFiat, paymentMethods, limits]);

  if (cash?.balance) {
    return cash.method;
  } else if (latestBank) {
    return latestBank;
  } else if (latestCard) {
    return latestCard;
  } else if (payPalMethod) {
    return payPalMethod;
  } else {
    return BuyReviewFlowModal.ADD_PAYMENT_METHOD;
  }
};

export function getEarnStakingProvider(balancesObj: Balances): InterestProvider | null {
  const providers = balancesObj?.earnBalanceByProvider;

  if (!providers) return null;

  return highestYieldProvider(
    Object.values(providers).filter(p => p?.providerType === GrowProviderType.POOLED_STAKING)
  );
}

export function getHighestEarnApi(providers: InterestProvider[]): InterestProvider | null {
  if (!providers || providers.length === 0) return null;

  return highestYieldProvider(providers.filter(p => p?.providerType === GrowProviderType.EARN));
}

function getBuyMessage(intl: IntlShape, isBuyAndEarn: boolean, isBuyAndStake: boolean) {
  if (isBuyAndEarn) return intl.formatMessage({ defaultMessage: "Your buy and earn order has been placed." });
  if (isBuyAndStake) return intl.formatMessage({ defaultMessage: "Your buy & stake order has been placed." });

  return intl.formatMessage({ defaultMessage: "Your buy order has been placed." });
}

export function getSuccessTitle(action, intl: IntlShape, isBuyAndEarn: boolean, isBuyAndStake: boolean, currency) {
  const message = {
    [Action.BUY]: getBuyMessage(intl, isBuyAndEarn, isBuyAndStake),
    [Action.SELL]: intl.formatMessage({ defaultMessage: "Your sell order is complete." }),
    [Action.CONVERT]: intl.formatMessage(
      defineMessage({
        defaultMessage: "Your {convertMessage} order is complete.",
        description: "convertMessage values can be: 'wrap', 'unwrap', or 'convert'",
      }),
      {
        convertMessage: getConvertTypeMessage(currency, intl),
      }
    ),
  };
  return message[action];
}

/**
 * Returns the selected asset pair details
 */
export const getPairDetail = (allPairDetails: PairDetail[], tradingPair: SupportedCurrencyPairs): PairDetail => {
  // Note: there may not be pair details for certain pairs. ex: erc-20's with non usd-fiats.
  const pairDetail = allPairDetails.find(o => o.symbol === tradingPair);

  if (!pairDetail) {
    Sentry.captureException(`Could not find asset pair details for trading pair, ${tradingPair}`);
  }

  // This will limit to GUSDUSD only
  if (pairDetail?.symbol === GUSD_PAIR_DETAIL_FIXTURE.symbol) {
    return { ...pairDetail, ...GUSD_CHART_FIXTURE };
  }

  return pairDetail;
};

export const calculateFeeAmount = (feeRate: number, amount: string) =>
  new BigNumber(feeRate).multipliedBy(0.0001).multipliedBy(new BigNumber(amount)).toNumber();

export function calculateTotal(action: Action, fee: number, subtotal: number): number {
  return action === Action.BUY ? subtotal + fee : subtotal - fee;
}

export const sortByBalance = (a: AssetSelectOptionProps, b: AssetSelectOptionProps) => {
  if (a.balance.value > b.balance.value) {
    return -1;
  }
  if (a.balance.value < b.balance.value) {
    return 1;
  }
  return 0;
};

export const getCalculatedReward = (
  tradingFee: string | number,
  rewardsProgress: ReferralProgress,
  referralTiers: RewardTierResponse
): string | null => {
  if (rewardsProgress && referralTiers) {
    try {
      const currentTier = rewardsProgress?.tier;
      const tierData = referralTiers[currentTier];

      if (
        (currentTier === ReferralTier.Tier1 || currentTier === ReferralTier.Tier2) &&
        !rewardsProgress?.tradeProgress &&
        tierData
      ) {
        const reward = formatNumber(Number(tradingFee) * (tierData.commission.value / 100), "en-US", {
          style: "currency",
          currency: "USD",
        });
        return reward;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  return null;
};

export const getIsBankPaymentMethod = (paymentMethod: TradePaymentMethodType) =>
  paymentMethod !== AccountType.BALANCE && paymentMethod.paymentMethodType === PaymentMethodType.BANK;

export const getIsDebitPaymentMethod = (paymentMethod: TradePaymentMethodType) =>
  paymentMethod !== AccountType.BALANCE && paymentMethod.paymentMethodType === PaymentMethodType.DEBIT_CARD;

export const getIsPayPalPaymentMethod = (paymentMethod: TradePaymentMethodType) =>
  paymentMethod !== AccountType.BALANCE && paymentMethod.paymentMethodType === PaymentMethodType.PAYPAL;

export const getInsufficientFunds = (
  paymentMethod: TradePaymentMethodType,
  amount: string | number,
  balances: CurrencyBalances,
  defaultFiat: CurrencyShortNameFiat,
  paymentMethodData: UsePaymentData
) => {
  const isBalancePaymentMethod = paymentMethod === AccountType.BALANCE;
  const isBankPaymentMethod = Boolean(paymentMethod) && getIsBankPaymentMethod(paymentMethod);
  const isDebitPaymentMethod = Boolean(paymentMethod) && getIsDebitPaymentMethod(paymentMethod);
  const isPayPalPaymentMethod = Boolean(paymentMethod) && getIsPayPalPaymentMethod(paymentMethod);
  const exceedsCashBalance = isBalancePaymentMethod && Number(amount) > getGeminiBalance(balances, defaultFiat);
  const exceedsBankLimit =
    isBankPaymentMethod && !isAmountInRemainingLimit(Number(amount), paymentMethodData.limits.ach.daily?.available);
  const exceedsDebitLimit =
    isDebitPaymentMethod && !isAmountInRemainingLimit(Number(amount), paymentMethodData.limits.card.daily?.available);
  const exceedsPayPalLimit =
    isPayPalPaymentMethod &&
    !isAmountInRemainingLimit(Number(amount), paymentMethodData.limits.payPal.daily?.available);

  return {
    insufficientFunds:
      Boolean(amount) && (exceedsCashBalance || exceedsBankLimit || exceedsDebitLimit || exceedsPayPalLimit),
    exceedsCashBalance,
    exceedsBankLimit,
    exceedsDebitLimit,
    exceedsPayPalLimit,
  };
};
