import { CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { LazyModalWrapper, MODAL_TYPES } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const CryptoWithdrawFlow = LazyModalWrapper(() => import("./index"));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  preSelectedCrypto?: CurrencyShortNameCrypto;
}

function LazyLoadCryptoWithdrawFlow({ isOpen, onClose, preSelectedCrypto }: Props) {
  return (
    <CryptoWithdrawFlow
      isOpen={isOpen}
      onClose={onClose}
      modalType={MODAL_TYPES.Sheet}
      sheetConfigs={{
        side: "right",
        disableAnimation: true,
      }}
    />
  );
}

export default LazyLoadCryptoWithdrawFlow;
