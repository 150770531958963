/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationPhone = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M126.438 201.971C125.265 201.971 124.065 201.753 122.892 201.317L75.0716 182.712C70.5432 180.939 68.279 175.838 69.9976 171.309L114.272 54.7997C116.345 49.3984 122.401 46.6432 127.802 48.6619L176.278 66.8026C176.305 66.8026 176.359 66.8299 176.387 66.8299C176.414 66.8572 176.414 66.8572 176.441 66.8572H176.469C181.515 68.8758 183.97 74.6045 181.952 79.6511L135.632 195.752C134.104 199.625 130.367 201.971 126.438 201.971ZM115.008 55.0725L70.7069 171.582C69.1247 175.701 71.1979 180.366 75.3444 181.976L123.192 200.58C127.83 202.381 133.095 200.089 134.922 195.479L181.297 79.3783C183.152 74.7409 180.888 69.4487 176.25 67.5937C176.223 67.5664 176.169 67.5664 176.141 67.5664C176.114 67.5391 176.114 67.5391 176.087 67.5391L127.557 49.3984C122.537 47.5434 116.918 50.0804 115.008 55.0725Z"
      fill={contentPrimary}
    />
    <path
      d="M130.313 208C129.14 208 127.939 207.782 126.766 207.346L78.9185 188.741C75.9178 187.595 73.79 184.867 73.3535 181.703L74.1173 181.594C74.4992 184.513 76.4633 186.968 79.1913 188.032L127.039 206.609C131.677 208.409 136.941 206.118 138.769 201.508L185.144 85.4073C186.699 81.5336 185.417 77.1689 182.061 74.7138L182.525 74.0864C186.153 76.7325 187.517 81.5063 185.88 85.7073L139.506 201.808C137.951 205.654 134.241 208 130.313 208Z"
      fill={contentPrimary}
    />
    <path
      d="M103.143 195.861C103.116 195.861 103.034 195.861 103.007 195.834L93.9774 192.206C93.7865 192.124 93.6773 191.906 93.7592 191.715C93.841 191.524 94.0592 191.415 94.2502 191.497L103.28 195.125C103.471 195.207 103.58 195.425 103.498 195.616C103.416 195.752 103.28 195.861 103.143 195.861Z"
      fill={contentPrimary}
    />
    <path
      d="M143.404 58.9453L161.408 65.8197C162.008 66.0379 162.308 66.7199 162.09 67.32L161.926 67.7565C161.708 68.3567 161.026 68.6567 160.426 68.4385L142.394 61.5641C141.794 61.3459 141.494 60.6639 141.712 60.0638L141.876 59.6273C142.121 59.0272 142.803 58.7271 143.404 58.9453Z"
      fill={contentPrimary}
    />
    <path
      d="M136.97 59.8736C137.904 59.8736 138.662 59.1163 138.662 58.1822C138.662 57.2481 137.904 56.4909 136.97 56.4909C136.036 56.4909 135.279 57.2481 135.279 58.1822C135.279 59.1163 136.036 59.8736 136.97 59.8736Z"
      fill={contentPrimary}
    />
    <path
      d="M115.094 65.3841L77.8033 163.398C76.4121 167.054 78.2398 171.146 81.8679 172.564L124.696 189.205C128.761 190.787 133.371 188.795 134.981 184.731L173.963 87.0711C175.572 83.0065 173.608 78.369 169.516 76.7323C169.462 76.705 169.407 76.705 169.352 76.6777L126.006 60.4465C121.641 58.7552 116.758 60.9649 115.094 65.3841Z"
      fill={black}
    />
    <path
      d="M127.559 190.106C126.55 190.106 125.541 189.942 124.559 189.533L81.7575 172.892C77.9384 171.392 76.0015 167.082 77.4746 163.236L114.765 65.2212C115.611 62.9843 117.248 61.2111 119.43 60.2291C121.585 59.2743 123.986 59.1925 126.195 60.0108L169.542 76.242L169.706 76.2966C171.779 77.1149 173.388 78.6971 174.289 80.7431C175.189 82.789 175.189 85.0805 174.398 87.1264L135.388 184.786C134.052 188.114 130.915 190.106 127.559 190.106ZM123.031 60.2291C121.913 60.2291 120.767 60.4473 119.73 60.9383C117.766 61.8386 116.238 63.448 115.447 65.494L78.1839 163.536C76.8745 166.973 78.6204 170.846 82.0575 172.183L124.859 188.823C128.732 190.324 133.124 188.414 134.652 184.541L173.634 86.8809C174.398 84.9986 174.343 82.9527 173.552 81.0704C172.761 79.2154 171.288 77.7969 169.406 77.0604L169.242 77.0058L125.868 60.7747C124.968 60.42 123.986 60.2291 123.031 60.2291Z"
      fill={contentPrimary}
    />
    <path
      d="M139.235 120.133C139.072 117.433 137.871 114.95 135.853 113.204C134.107 111.704 131.979 110.913 129.524 110.858C125.841 110.804 122.759 112.468 120.686 115.714C119.676 117.296 119.103 118.906 118.858 119.806H118.667V120.024C117.739 120.297 116.13 120.897 114.466 121.988C111.165 124.198 109.419 127.362 109.419 131.154C109.419 135.764 112.365 139.065 117.767 140.456C118.667 140.675 119.54 140.811 120.44 140.811C122.104 140.811 123.714 140.402 125.159 139.638C127.369 138.438 129.879 135.983 129.851 131.072C132.416 130.909 134.652 129.817 136.507 127.799C138.39 125.698 139.399 122.916 139.235 120.133ZM129.333 112.795C129.388 112.795 129.442 112.795 129.497 112.768C131.488 112.795 133.207 113.423 134.598 114.65C136.126 115.96 137.08 117.787 137.299 119.833C134.68 119.806 127.123 119.779 120.849 119.833C121.586 117.542 123.768 112.795 129.333 112.795ZM127.805 129.108C126.36 129.081 123.577 129.026 120.576 128.999V121.743C122.95 121.716 125.487 121.716 127.915 121.716L127.86 129.108H127.805ZM118.64 122.07V128.999C116.048 128.999 113.484 128.999 111.574 129.081C112.584 124.662 116.621 122.752 118.64 122.07ZM124.232 137.919C122.459 138.874 120.331 139.147 118.258 138.601C115.093 137.783 111.329 135.819 111.329 131.154C111.329 131.1 111.329 131.072 111.329 131.018C115.83 130.8 125.023 130.991 127.887 131.045C127.942 134.264 126.714 136.555 124.232 137.919ZM135.062 126.462C133.589 128.099 131.815 128.999 129.797 129.136L129.851 121.716C133.098 121.716 135.88 121.743 137.271 121.743C137.053 123.489 136.289 125.153 135.062 126.462Z"
      fill={white}
    />
  </Fragment>
));
IllustrationPhone.displayName = "IllustrationPhone";
