import { useState } from "react";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { WireTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankDetailsFormType } from "@gemini-ui/constants/giact";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { PaymentTypeScope } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import CompleteDepositModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/CompleteDepositModal";
import ConfirmWireDetailsModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/ConfirmWireDetailsModal";
import AddWireDetailsModal, {
  AddWireDetailsFormData,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/FedWireFlow/AddWireDetailsModal";
import { WireInstructionsModal } from "@gemini-ui/pages/settings/BankSettings/components/WireInstructionsModal";
import { useIntl } from "@gemini-ui/utils/intl";

interface FedWireFlowProps {
  currency: CurrencyShortNameFiat;
  geminiEntity: GeminiEntity;
  initialWireFormValues?: Partial<AddWireDetailsFormData | BankDetailsFormType>;
  isInstitutional: boolean;
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  isSettingsOrOnboarding?: boolean;
  subaccountHashid: string;
  scope?: PaymentTypeScope;
}

enum FlowState {
  ADD_WIRE_DETAILS,
  CONFIRM_WIRE_DETAILS,
  COMPLETE_DEPOSIT,
  SHOW_DEPOSIT_INSTRUCTIONS,
}

const FedWireFlow = ({
  currency,
  geminiEntity,
  initialWireFormValues = {},
  isInstitutional,
  isOpen,
  onBack,
  onClose,
  isSettingsOrOnboarding,
  subaccountHashid,
  scope,
}: FedWireFlowProps) => {
  const { intl } = useIntl();
  const [flowState, setFlowState] = useState(FlowState.ADD_WIRE_DETAILS);
  const [customerBankName, setCustomerBankName] = useState("");
  const [wireDetails, setWireDetails] = useState<WireTransferInfoAPIPayload | null>(null);
  const [userInputWireDetails, setUserInputWireDetails] = useState(initialWireFormValues);
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);

  const handleConfirmWireDetails = ({ customerBankName, wireDepositDetails }) => {
    setCustomerBankName(customerBankName);
    setWireDetails(wireDepositDetails);
    setFlowState(FlowState.COMPLETE_DEPOSIT);
  };

  const handleAddWireDetails = values => {
    setUserInputWireDetails(values);
    setFlowState(FlowState.CONFIRM_WIRE_DETAILS);
  };

  if (flowState === FlowState.ADD_WIRE_DETAILS) {
    return (
      <AddWireDetailsModal
        defaultValues={userInputWireDetails as Partial<AddWireDetailsFormData>}
        isOpen={isOpen}
        onBack={onBack}
        onClose={onClose}
        onSubmit={handleAddWireDetails}
      />
    );
  }

  if (flowState === FlowState.CONFIRM_WIRE_DETAILS) {
    return (
      <ConfirmWireDetailsModal
        currency={currency}
        isInstitutional={isInstitutional}
        isOpen={isOpen}
        onBack={() => setFlowState(FlowState.ADD_WIRE_DETAILS)}
        onClose={onClose}
        onSubmit={handleConfirmWireDetails}
        wireDetails={userInputWireDetails}
        title={intl.formatMessage({
          defaultMessage: "Confirm wire transfer details",
        })}
        subaccountHashid={subaccountHashid}
      />
    );
  }

  if (flowState === FlowState.COMPLETE_DEPOSIT) {
    return isNewAddPaymentsFlowEnabled ? (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={onClose}
        paymentMethodType={AddPaymentMethodType.WIRE}
        showDepositInstructions={() => setFlowState(FlowState.SHOW_DEPOSIT_INSTRUCTIONS)}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        scope={scope}
      />
    ) : (
      <CompleteDepositModal
        currency={currency}
        customerBankName={customerBankName}
        geminiEntity={geminiEntity}
        isOpen={isOpen}
        onClose={onClose}
        wireDetails={wireDetails}
        subaccountHashid={subaccountHashid}
      />
    );
  }

  if (flowState === FlowState.SHOW_DEPOSIT_INSTRUCTIONS && isNewAddPaymentsFlowEnabled) {
    return (
      <WireInstructionsModal
        currency={currency}
        onClose={onClose}
        isOpen={isOpen}
        subaccountHashid={subaccountHashid}
      />
    );
  }
};

export default FedWireFlow;
