import styled from "@emotion/styled";
import {
  IconCardFilled,
  IconCreditCard,
  IconStaticPaymentMethodMastercard,
  IconStaticPaymentMethodVisa,
} from "@hubble/icons";
import { PaymentMethodIconBadgeProps } from "@gemini-ui/components/Icons/PaymentMethod/constants";
import { border, Flex, IconBadge, Spacing } from "@gemini-ui/design-system";

type IconBadgeSize = "sm" | "md" | "lg";

const cardIssuerTypeImageMap = {
  Mastercard: {
    icon: <IconStaticPaymentMethodMastercard />,
    backgroundColor: "#ffffff",
  },
  Visa: {
    icon: <IconStaticPaymentMethodVisa />,
    backgroundColor: "#16216B",
  },
};

type CardIconProps = {
  cardIssuer?: string;
  filled?: boolean;
} & PaymentMethodIconBadgeProps;

export function CardIcon({ cardIssuer, size = "lg", filled, ...props }: CardIconProps) {
  const cardMeta = cardIssuer && cardIssuerTypeImageMap[cardIssuer];
  if (cardMeta) {
    return <IconBadge icon={cardMeta.icon} size={size} backgroundColor={cardMeta.backgroundColor} {...props} />;
  }

  return <IconBadge icon={filled ? <IconCardFilled /> : <IconCreditCard />} size={size} {...props} />;
}

const getRectangularDimensions = (size: IconBadgeSize) => {
  switch (size) {
    case "sm": {
      return {
        width: Spacing.scale[3],
        height: Spacing.scale[2],
      };
    }
    case "md": {
      return {
        width: Spacing.scale[4],
        height: Spacing.scale[3],
      };
    }
    case "lg": {
      return {
        width: Spacing.scale[5],
        height: Spacing.scale[4],
      };
    }
  }
};

export const SquareCardIcon = styled(Flex)<{ size: IconBadgeSize; backgroundColor: string }>`
  border-radius: ${border.radius.sm};
  width: ${({ size }) => getRectangularDimensions(size).width};
  min-width: ${({ size }) => getRectangularDimensions(size).width};
  height: ${({ size }) => getRectangularDimensions(size).height};
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export function CardIconSquare({ cardIssuer, size = "lg", filled, ...props }: CardIconProps) {
  const cardMeta = cardIssuer && cardIssuerTypeImageMap[cardIssuer];
  if (cardMeta) {
    return (
      <SquareCardIcon size={size} backgroundColor={cardMeta.backgroundColor}>
        {cardMeta.icon}
      </SquareCardIcon>
    );
  }

  return <IconBadge icon={filled ? <IconCardFilled /> : <IconCreditCard />} size={size} {...props} />;
}
