import { useTheme } from "@emotion/react";
import { IconCheck } from "@hubble/icons";
import { isEqual } from "lodash";
import { List, ListItem, Text } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { FundingSourceIcon } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSourceIcon";
import { SupportedStakingPaymentMethod, SupportedUnstakingMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";

export interface FundingSourcesListProps {
  options: SelectOptionProps<SupportedStakingPaymentMethod | SupportedUnstakingMethod>[];
  selected?: SelectOptionProps<SupportedStakingPaymentMethod | SupportedUnstakingMethod>;
  onDidSelectFundingSource?: (
    value: SelectOptionProps<SupportedStakingPaymentMethod | SupportedUnstakingMethod>
  ) => void;
}

export const FundingSourcesList = ({ onDidSelectFundingSource, options, selected }: FundingSourcesListProps) => {
  const { colorScheme } = useTheme();
  return (
    <List>
      {options.map((option, idx) => (
        <ListItem
          key={`paymentMethod-${idx}`}
          data-testid="staking-payment-method-option"
          as="button"
          left={<FundingSourceIcon fundingSource={option.value} />}
          right={isEqual(option.value, selected?.value) ? <IconCheck /> : null}
          size="dense"
          padding="sm"
          alignItems="center"
          onClick={() => onDidSelectFundingSource(option)}
        >
          <Text.Body size="sm">{option.label}</Text.Body>
          <Text.Body size="xs" color={colorScheme.content.secondary}>
            {option.subLabel}
          </Text.Body>
        </ListItem>
      ))}
    </List>
  );
};
