import { Fragment, useState } from "react";
import TransferControl from "@gemini-ui/components/TransferControl";
import TransferControlAlert, { TransferControlVariant } from "@gemini-ui/components/TransferControl/Alert";
import { useGetAwaitingOwnershipAttestations } from "@gemini-ui/pages/transfers/hooks/useGetAwaitingOwnershipAttestations";

interface DepositAttestationAlertProps {
  fetchLockout?: () => Promise<void>;
  children?: string;
  variantOverride?: TransferControlVariant;
}

export const DepositAttestationAlert = ({ fetchLockout, children, variantOverride }: DepositAttestationAlertProps) => {
  const {
    canShowTransferControlAlert,
    singleAttestation,
    hasMultipleAttestations,
    loadingHasMultipleAttestations,
    getAwaitingOwnershipAttestations,
  } = useGetAwaitingOwnershipAttestations();
  const [showAttestationTransferControl, setShowAttestationTransferControl] = useState(false);

  return (
    <Fragment>
      {canShowTransferControlAlert && !loadingHasMultipleAttestations && (
        <TransferControlAlert
          variant={TransferControlVariant.Warning}
          variantOverride={variantOverride}
          onConfirmDeposit={() => setShowAttestationTransferControl(true)}
          hasMultiple={hasMultipleAttestations}
          disabled={!Boolean(singleAttestation) && !hasMultipleAttestations}
          mt={0}
        >
          {children}
        </TransferControlAlert>
      )}
      {singleAttestation && (
        <TransferControl
          attestation={singleAttestation}
          isOpen={showAttestationTransferControl}
          onModalClose={() => setShowAttestationTransferControl(false)}
          onSubmitAttestation={() => {
            if (fetchLockout) fetchLockout();

            getAwaitingOwnershipAttestations();
          }}
        />
      )}
    </Fragment>
  );
};
