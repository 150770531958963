import { AxiosError, AxiosResponse } from "axios";
import axios from "@gemini-ui/services/axios";

export interface AssetInfoResponse {
  assetName: string;
  contentLong: string;
  contentShort: string;
}

export const fetchContentfulAssetInfo = cryptoShortName => {
  const url =
    jsRoutes.com.gemini.web.server.onboarding.controllers.services.ContentfulController.getAsset(cryptoShortName).url;

  return axios
    .get(url)
    .then((response: AxiosResponse<AssetInfoResponse>) => {
      const {
        data: { contentLong, contentShort, assetName },
      } = response;
      return {
        contentLong: contentLong.replace(/<br\/>/g, ""),
        contentShort,
        assetName,
      };
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};
