import { Fragment } from "react";
import { BADGE_VARIANT, BadgeProps, DEPRECATED_VARIANTS } from "@gemini-ui/design-system/Badge/constants";
import { BaseBadge } from "@gemini-ui/design-system/Badge/styles";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Text } from "@gemini-ui/design-system/Text";

export const Badge = ({
  backgroundColor,
  children,
  icon,
  iconPosition = "left",
  size = "md",
  status = "info",
  variant = "label",
  ...props
}: BadgeProps) => {
  const deprecatedProps = {
    backgroundColor: backgroundColor,
    variant: variant,
  };

  // The following variables are only used for backwards compatibility
  const isLegacy = Boolean(backgroundColor) || Boolean(DEPRECATED_VARIANTS[variant]);

  const isSingleCounterDigit = typeof children === "string" && children.length === 1;

  const content = () => (
    <Fragment>
      {icon && iconPosition === "left" && (
        <Flex as="span" mr={0.5}>
          {icon}
        </Flex>
      )}
      {isLegacy ? (
        children
      ) : (
        <Text.Body as="strong" bold lineHeight="16px" size="xs">
          {children}
        </Text.Body>
      )}
      {icon && iconPosition === "right" && (
        <Flex as="span" ml={0.5}>
          {icon}
        </Flex>
      )}
    </Fragment>
  );

  return (
    <BaseBadge
      isLegacy={isLegacy}
      isSingleCounterDigit={isSingleCounterDigit}
      size={size}
      status={!isLegacy ? status : undefined}
      {...deprecatedProps}
      {...props}
    >
      {variant !== BADGE_VARIANT.pip && content()}
    </BaseBadge>
  );
};
