import { ReactNode } from "react";
import { CurrencyShortName, CurrencyShortNameFiat, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import {
  CurrencyOrderQuote,
  OrderQuote,
  TradePaymentMethodType,
} from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { DAI_ERROR } from "@gemini-ui/pages/RetailTrade/PaymentMethod/SelectPaymentMethod/constants";
import {
  BankType,
  DebitCardType,
  PaymentMethodDataType,
  PayPalAccountType,
} from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export function getEligibleBanks(
  paymentMethods: PaymentMethodDataType[],
  defaultFiat: CurrencyShortNameFiat
): Array<BankType> {
  return paymentMethods.filter(
    (paymentMethod): paymentMethod is BankType =>
      paymentMethod.eligibility.buy &&
      paymentMethod.paymentMethodType === PaymentMethodType.BANK &&
      paymentMethod.achLevel === "Approved" &&
      paymentMethod.currencies?.includes(defaultFiat)
  );
}

export function getVerifiedDebitCards(
  paymentMethods: PaymentMethodDataType[],
  defaultFiat: CurrencyShortNameFiat
): Array<DebitCardType> {
  return paymentMethods.filter(
    (paymentMethod): paymentMethod is DebitCardType =>
      paymentMethod.paymentMethodType === PaymentMethodType.DEBIT_CARD &&
      paymentMethod.eligibility.buy &&
      paymentMethod.isVerified &&
      paymentMethod.currencies.includes(defaultFiat)
  );
}

export function getEligiblePayPalAccounts(
  paymentMethods: PaymentMethodDataType[],
  defaultFiat: CurrencyShortNameFiat
): Array<PayPalAccountType> {
  return paymentMethods.filter(
    (paymentMethod): paymentMethod is PayPalAccountType =>
      paymentMethod.eligibility.buy &&
      paymentMethod.paymentMethodType === PaymentMethodType.PAYPAL &&
      paymentMethod.currencies.includes(defaultFiat)
  );
}

export function getGeminiBalance(balances: CurrencyBalances, defaultFiat: CurrencyShortName): number {
  const balanceCurrency = defaultFiat.toLowerCase();

  if (Object.keys(balances).length === 0 || !balances.hasOwnProperty(balanceCurrency)) return 0;

  return Number(balances[balanceCurrency]?.availableForTrading?.value ?? 0);
}

export function getAvailableForTrading(balances: CurrencyBalances, currency: CurrencyShortName): MoneyProps {
  const balanceCurrency = currency.toLowerCase();
  const emptyBalance = { currency, value: "0" };

  if (Object.keys(balances).length === 0 || !balances.hasOwnProperty(balanceCurrency)) {
    return emptyBalance;
  }

  return balances[balanceCurrency]?.availableForTrading ?? emptyBalance;
}

export function getPaymentMethodType(paymentMethod: TradePaymentMethodType): AccountType {
  if (paymentMethod === AccountType.BALANCE) {
    return AccountType.BALANCE;
  }

  switch (paymentMethod?.paymentMethodType) {
    case PaymentMethodType.BANK:
      return AccountType.BANK;
    case PaymentMethodType.DEBIT_CARD:
      return AccountType.DEBIT_CARD;
    case PaymentMethodType.PAYPAL:
      return AccountType.PAYPAL;
    default:
      return AccountType.BALANCE;
  }
}

export const isAmountInRemainingLimit = (amount, transferTypeLimit: MoneyProps<CurrencyShortNameFiat>): boolean => {
  if (!transferTypeLimit) return false;
  return Number(transferTypeLimit.value) >= Number(amount);
};

const isDai = (quote: CurrencyOrderQuote) => isCurrency.DAI(quote.quantity.currency);

export const handleDebitErrors = (
  orderQuote: OrderQuote,
  minimum: MoneyProps<CurrencyShortNameFiat>,
  intl: IntlShape,
  quoteError?: string
): { hasError: boolean; error?: ReactNode } => {
  // fetch quote error would be undefined in case of Earn flow/ Select payment method flow.
  if (!orderQuote && (quoteError || quoteError === undefined))
    return { hasError: true, error: intl.formatMessage({ defaultMessage: "No quote found." }) };

  if (orderQuote && isDai(orderQuote.quote)) {
    return { hasError: true, error: DAI_ERROR };
  }

  if (orderQuote && Number(orderQuote?.quote?.totalSpend?.value) < Number(minimum?.value)) {
    return {
      hasError: true,
      error: intl.formatMessage(
        defineMessage({ defaultMessage: "Cannot use for amounts less than <Minimum></Minimum>" }),
        {
          Minimum: () => <Money {...minimum} />,
        }
      ),
    };
  }

  return { hasError: false, error: null };
};

export const track3DSReturnURLMismatch = () => {
  const { name, properties } = EVENTS.ADD_PAYMENT_3DS_RETURN_URL_MISMATCH;
  track(name, { [properties.METHOD]: "DebitCard" }, { braze: true });
};
