import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { GrowAssets, GrowProviderType } from "@gemini-ui/constants/earn";
import useIsMounted from "@gemini-ui/hooks/useIsMounted";
import { GrowLandingPageProps } from "@gemini-ui/pages/Earn/types";
import { SENTRY_TAGS, SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import axios from "@gemini-ui/services/axios";
import { stakingDataUrl } from "@gemini-ui/services/staking/endpoints";
import { getError } from "@gemini-ui/utils/error";

export const fetchStakingAssetData = async () => {
  return await axios
    .get<{ pageProps: GrowLandingPageProps }>(stakingDataUrl())
    .then(res => res.data?.pageProps?.assets)
    .catch(e => {
      Sentry.withScope(scope => {
        scope.setTag(SENTRY_TAGS.Team, SENTRY_TEAMS.Staking);
        Sentry.captureException(e);
      });
      throw e;
    });
};

export const useStakingAssetData = () => {
  const _isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GrowAssets[]>();
  const [error, setError] = useState<string>(null);

  const refetchStakingAssetData = useCallback(async () => {
    setLoading(true);

    try {
      const data = await fetchStakingAssetData();

      /**
       * The `/stake` endpoint will return all Grow assets, including Earn and GUSD promotional assets.
       * To return only staking assets, filter by staking provider types.
       */
      const stakableAssets = data.filter(asset =>
        Object.values(asset).some(value =>
          value?.interestProviders.some(
            provider =>
              provider?.providerType === GrowProviderType.POOLED_STAKING ||
              provider?.providerType === GrowProviderType.PRIVATE_STAKING
          )
        )
      );

      if (_isMounted()) {
        setData(stakableAssets);
        setError(null);
      }
    } catch (err) {
      if (_isMounted()) {
        setData([]);
        setError(getError(err));
      }
    } finally {
      setLoading(false);
    }
  }, [_isMounted]);

  useEffect(() => {
    if (!data && !loading) refetchStakingAssetData();
  }, [data, loading, refetchStakingAssetData]);

  return {
    data,
    refetchStakingAssetData,
    loading,
    error,
  };
};
