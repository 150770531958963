import { ComponentPropsWithoutRef } from "react";
import { CSSObject, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Text } from "@gemini-ui/design-system";

export const truncate: CSSObject = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
};

export const TruncateWrapper = styled.div`
  min-width: 0;
`;

export const AssetLabel = styled(Text.Body)(truncate);

export interface CellTextProps extends ComponentPropsWithoutRef<typeof Text.Body> {
  isSecondary?: boolean;
}

export function CellText({ children, isSecondary, size, ...rest }: CellTextProps) {
  const { colorScheme } = useTheme();

  return (
    <Text.Body
      color={isSecondary ? colorScheme.content.secondary : undefined}
      size={isSecondary ? "sm" : size}
      as="span"
      {...rest}
      css={truncate}
    >
      {children}
    </Text.Body>
  );
}
