import { MouseEvent, useState } from "react";
import { Button, Flex, Modal, Text } from "@gemini-ui/design-system";
import { ReactComponent as WireIcon } from "@gemini-ui/images/icons/wire.svg";
import { ToggleBankCard } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/XfersFlow/ToggleBankCard";
import { XfersAccountType } from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  onClose: () => void;
  autoLinkedAccounts: XfersAccountType[];
  handleClickContinue: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const AutoLinkedSuccessModal = ({ onClose, autoLinkedAccounts, handleClickContinue }: Props) => {
  const { intl } = useIntl();

  const [showModal, setShowModal] = useState(true);
  const [xfersAccounts, setXfersAccounts] = useState(autoLinkedAccounts);

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  const title = (
    <Flex.Column alignItems="center">
      <WireIcon />
      <Text.Subhead center mt={2}>
        {intl.formatMessage({
          defaultMessage: "Link Xfers Bank Accounts",
        })}
      </Text.Subhead>
    </Flex.Column>
  );

  const isButtonEnabled = Boolean(xfersAccounts.find(account => account.enabled));

  return (
    <Modal title={title} isOpen={showModal} shouldCloseOnOverlayClick onClose={closeModal}>
      <Text.Body size="xs" center>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Your bank account(s) have been retrieved. <br></br>Select below to confirm the bank account(s) you wish to have linked to Gemini.",
          }),
          {
            br: () => <br />,
          }
        )}
      </Text.Body>
      <br />
      <div>
        {xfersAccounts.map(account => (
          <ToggleBankCard
            account={account}
            handleChangeBank={bank => {
              setXfersAccounts(xfersAccounts.map(el => (el.id === bank.id ? bank : el)));
            }}
            key={account.id}
          />
        ))}
      </div>
      <Button.Group type="centered">
        <Button.Primary
          disabled={!isButtonEnabled}
          size="lg"
          data-testid="auto-link-xfers-goto-success"
          onClick={handleClickContinue}
          cta={intl.formatMessage({
            defaultMessage: "Continue",
          })}
        />
      </Button.Group>
    </Modal>
  );
};
