import { SyntheticEvent } from "react";
import { optimizelyClient, track } from "@gemini-ui/analytics";
import { AnalyticsEventName, AnalyticsProperty } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import { CRYPTO_DEPOSIT_MIXPANEL_EVENTS } from "@gemini-ui/components/Transfer/CryptoDepositFlow/constants";
import { CRYPTO_WITHDRAW_MIXPANEL_EVENTS } from "@gemini-ui/components/Transfer/CryptoWithdrawFlow/constant";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";

export const isCryptoDepositModalFlowEnabled = () =>
  optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CRYPTO_DEPOSIT_MODAL_FLOW);

export const isCryptoWithdrawModalFlowEnabled = () =>
  optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_CRYPTO_WITHDRAW_MODAL_FLOW);

export const handleCryptoDepositInitiation = (
  e: SyntheticEvent,
  handleOpenModal: () => void,
  analyticsEventName: AnalyticsEventName,
  analyticsProperties: AnalyticsProperty
) => {
  const isEnabled = isCryptoDepositModalFlowEnabled();
  if (isEnabled) {
    e.preventDefault();
    handleOpenModal();
    track(CRYPTO_DEPOSIT_MIXPANEL_EVENTS.START.name, {
      [CRYPTO_DEPOSIT_MIXPANEL_EVENTS.START.properties.INITIATED_FROM]: window.location.pathname,
    });
  } else {
    mixpanelClient.trackLinkClickAndRedirectManually(
      jsRoutes.controllers.transfer.page.TransferPageController.get().url,
      analyticsEventName,
      analyticsProperties
    );
  }
};

export const handleCryptoWithdrawInitiation = (
  e: SyntheticEvent,
  handleOpenModal: () => void,
  analyticsEventName: AnalyticsEventName,
  analyticsProperties: AnalyticsProperty
) => {
  const isEnabled = isCryptoWithdrawModalFlowEnabled();
  if (isEnabled) {
    e.preventDefault();
    handleOpenModal();
    track(CRYPTO_WITHDRAW_MIXPANEL_EVENTS.START.name, {
      [CRYPTO_WITHDRAW_MIXPANEL_EVENTS.START.properties.INITIATED_FROM]: window.location.pathname,
    });
  } else {
    mixpanelClient.trackLinkClickAndRedirectManually(
      jsRoutes.controllers.transfer.page.TransferPageController.get().url,
      analyticsEventName,
      analyticsProperties
    );
  }
};
