import { memo, PropsWithChildren } from "react";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { Flex, Text } from "@gemini-ui/design-system";
import { useCurrencyDetailsV2 } from "@gemini-ui/pages/RetailTrade/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  currency: CurrencyShortName;
  providerType: GrowProviderType;
}

export const GrowBuyHeader = memo(({ currency, providerType, children }: PropsWithChildren<Props>) => {
  const { intl } = useIntl();
  const { Icon, name } = useCurrencyDetailsV2(currency);

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <Icon size="lg" />
        {children}
      </Flex>
      <Text.Heading size="md" as="h1" mt={1}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "{currency} {providerType, select, PrivateStaking {Staking Pro} other{Staking}}",
          }),
          {
            currency: name,
            providerType,
          }
        )}
      </Text.Heading>
    </Flex>
  );
});
