import { Button, SectionMessage } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  "data-testid"?: string;
  refetchData: () => void;
  errorMessage?: string;
};

export const LoadingErrorSection = ({ refetchData, errorMessage, "data-testid": dataTestId }: Props) => {
  const { intl } = useIntl();

  return (
    <SectionMessage
      data-testid={dataTestId || "section-message-loading-error"}
      statusType="alert"
      heading={intl.formatMessage({ defaultMessage: "Error" })}
      renderButton={
        <Button.Primary size="sm" cta={intl.formatMessage({ defaultMessage: "Try again" })} onClick={refetchData} />
      }
    >
      {errorMessage ||
        intl.formatMessage({
          defaultMessage:
            "An error occurred while initialing the Trade Module. Please try again and or contact customer support.",
        })}
    </SectionMessage>
  );
};
