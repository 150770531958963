import BodyText from "@gemini-ui/design-system/Text/BodyText";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import DisplayText from "@gemini-ui/design-system/Text/DisplayText";
import HeadingText from "@gemini-ui/design-system/Text/HeadingText";
import LabelText from "@gemini-ui/design-system/Text/LabelText";
import LegalText from "@gemini-ui/design-system/Text/LegalText";
import LinkText from "@gemini-ui/design-system/Text/LinkText";
import { BaseText } from "@gemini-ui/design-system/Text/styles";
import SubheadingText from "@gemini-ui/design-system/Text/SubheadingText";

export * from "./constants";

function Text(props: BaseTextProps) {
  return <BaseText {...props} />;
}

Text.Heading = HeadingText;
Text.Body = BodyText;
Text.Legal = LegalText;
/** @deprecated  please use the Label component instead */
Text.Label = LabelText;
Text.Link = LinkText;
Text.Display = DisplayText;
Text.Subhead = SubheadingText;
export { Text };
