import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { css, Global } from "@emotion/react";
import { IconClose, IconMenu } from "@hubble/icons";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { NavIcon } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import Drawer from "@gemini-ui/components/Header/navigation/MobileMenu/Drawer";
import OffcanvasMenu from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenu";
import { OffCanvasMenuV2 } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2";
import Popover from "@gemini-ui/components/Popover";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GroupsInfo } from "@gemini-ui/constants/templateProps/users";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { HubbleProvider } from "@gemini-ui/design-system";

// Types
interface Props {
  userName: string;
  institutionName: string;
  advancedTradeUIEnabled: boolean;
  showClearingLink: boolean;
  isCustodyAccount: boolean;
  moreThanOneAccount: boolean;
  groupsInfo: GroupsInfo;
  countryCode: CountryAbbreviation;
  isInstitutional: boolean;
  setMobileMenuActive: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu = ({
  userName,
  institutionName,
  advancedTradeUIEnabled,
  showClearingLink,
  isCustodyAccount,
  moreThanOneAccount,
  groupsInfo,
  countryCode,
  isInstitutional,
  setMobileMenuActive,
}: Props) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const { toggleModal } = useGlobalModal();

  const {
    pageName,
    templateProps: {
      account: { defaultFiat, supportedFiat },
      user: { subaccounts },
    },
  } = usePageData();

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  const isPerpsTransferEligible = subaccounts.some(account => account.derivatives);
  const toggleMenu = () => {
    setDisplayMenu(d => !d);
  };

  return (
    <Fragment>
      {isHeaderV2Enabled ? (
        <OffCanvasMenuV2
          advancedTradeUIEnabled={advancedTradeUIEnabled}
          defaultFiat={defaultFiat}
          groupsInfo={groupsInfo}
          institutionName={institutionName}
          isCustodyAccount={isCustodyAccount}
          isInstitutional={isInstitutional}
          isPerpsTransferEligible={isPerpsTransferEligible}
          moreThanOneAccount={moreThanOneAccount}
          pageName={pageName}
          showCardLink={countryCode === "us" && !isInstitutional}
          showClearingLink={showClearingLink}
          setMobileMenuActive={setMobileMenuActive}
          supportedFiat={supportedFiat}
          userName={userName}
        />
      ) : (
        <Fragment>
          {displayMenu && (
            <Global
              styles={css`
                body {
                  overflow: hidden;
                }
              `}
            />
          )}

          <Popover
            hasBackdrop={true}
            placement="bottom-end"
            isOpen={displayMenu}
            onClose={toggleMenu}
            css={{
              width: "100%",
              transform: "none !important",
            }}
            renderComponent={
              <Drawer id="mobileMenu">
                <OffcanvasMenu
                  toggleMobileMenu={toggleMenu}
                  toggleDepositCashModal={() => toggleModal(GlobalModalType.CashDepositModal)}
                  toggleCashWithdrawModal={() => toggleModal(GlobalModalType.CashWithdrawModal)}
                  togglePerpsOnboardingTransferModal={
                    isPerpsTransferEligible && (() => toggleModal(GlobalModalType.PerpsOnboardingTransferFormModal))
                  }
                  toggleCryptoDepositModal={() =>
                    toggleModal(GlobalModalType.CryptoDepositModal, { preSelectedCrypto: undefined })
                  }
                  toggleCryptoWithdrawModal={() => toggleModal(GlobalModalType.WithdrawCryptoModal)}
                  toggleDerivativeDebitCardFundingModal={() =>
                    toggleModal(GlobalModalType.DerivativeDebitCardFundingModal)
                  }
                  userName={userName}
                  institutionName={institutionName}
                  advancedTradeUIEnabled={advancedTradeUIEnabled}
                  showClearingLink={showClearingLink}
                  defaultFiat={defaultFiat}
                  supportedFiat={supportedFiat}
                  isCustodyAccount={isCustodyAccount}
                  pageName={pageName}
                  moreThanOneAccount={moreThanOneAccount}
                  groupsInfo={groupsInfo}
                  countryCode={countryCode}
                  isInstitutional={isInstitutional}
                />
              </Drawer>
            }
          >
            <HubbleProvider scheme="dark">
              <NavIcon
                type="button"
                active={displayMenu}
                data-testid="mobile-menu-button"
                display="inline"
                onClick={toggleMenu}
                pl={1}
                pr={1}
                size="sm"
              >
                {displayMenu ? <IconClose /> : <IconMenu />}
              </NavIcon>
            </HubbleProvider>
          </Popover>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MobileMenu;
