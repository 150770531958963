import { IconCheckCircleFilled, IconStepperNotStarted } from "@hubble/icons";
import { IntlShape } from "@gemini-ui/utils/intl";

export enum OnboardingNavigationStates {
  CreateAccount,
  SecureAccount,
  FinancialProfile,
  VerifyIdentity,
  CreatePasskey,
  Completed,
}

export const getOnboardingVerticalNavigationSteps = (
  intl: IntlShape,
  activeStep: OnboardingNavigationStates,
  isCreatePasskeyEnabled: boolean
) => {
  const steps = [
    {
      label: intl.formatMessage({ defaultMessage: "Create account" }),
      isActive: activeStep === OnboardingNavigationStates.CreateAccount,
      dataTestId: "create-account-step",
      leftElement: <IconCheckCircleFilled />,
    },
    {
      label: intl.formatMessage({ defaultMessage: "Secure account" }),
      isActive: activeStep === OnboardingNavigationStates.SecureAccount,
      dataTestId: "secure-account-step",
      leftElement:
        activeStep > OnboardingNavigationStates.SecureAccount ? <IconCheckCircleFilled /> : <IconStepperNotStarted />,
    },
    {
      label: intl.formatMessage({ defaultMessage: "Financial profile" }),
      isActive: activeStep === OnboardingNavigationStates.FinancialProfile,
      dataTestId: "financial-profile-step",
      leftElement:
        activeStep > OnboardingNavigationStates.FinancialProfile ? (
          <IconCheckCircleFilled />
        ) : (
          <IconStepperNotStarted />
        ),
    },
    {
      label: intl.formatMessage({ defaultMessage: "Verify Identity" }),
      isActive: activeStep === OnboardingNavigationStates.VerifyIdentity,
      dataTestId: "verify-identity-step",
      leftElement:
        activeStep > OnboardingNavigationStates.VerifyIdentity ? <IconCheckCircleFilled /> : <IconStepperNotStarted />,
    },
    ...(isCreatePasskeyEnabled
      ? [
          {
            label: intl.formatMessage({ defaultMessage: "Set up passkey" }),
            isActive: activeStep === OnboardingNavigationStates.CreatePasskey,
            dataTestId: "create-passkey-step",
            leftElement:
              activeStep > OnboardingNavigationStates.CreatePasskey ? (
                <IconCheckCircleFilled />
              ) : (
                <IconStepperNotStarted />
              ),
          },
        ]
      : []),
  ];

  return steps;
};
