var jsRoutes = {}; (function(_root){
var _nS = function(c,f,b){var e=c.split(f||"."),g=b||_root,d,a;for(d=0,a=e.length;d<a;d++){g=g[e[d]]=g[e[d]]||{}}return g}
var _qS = function(items){var qs = ''; for(var i=0;i<items.length;i++) {if(items[i]) qs += (qs ? '&' : '') + items[i]}; return qs ? ('?' + qs) : ''}
var _wA = function(r){return { method:r.method,type:r.method,url:r.url}}
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.formData = function() {
  return _wA({method: "GET", url: `/web/recurring-orders/new`});
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getMonthlyWinners = function(/* Option[org.joda.time.LocalDate] */ date0, /* com.gemini.leaderboard.models.LeaderboardTypeSymbol */ baseType1) {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/monthlywinners` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("baseType", baseType1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/monthlywinners` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("baseType", baseType1)])}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.list = function() {
  if (true) { return _wA({method: "GET", url: `/settings/approvedAddresses`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/whitelist`}); }
};
_nS('controllers.retail.AssetMarketCapController'); _root.controllers.retail.AssetMarketCapController.get = function() {
  return _wA({method: "GET", url: `/marketcap`});
};
_nS('controllers.mobile.CommonController'); _root.controllers.mobile.CommonController.trigger2fa = function(/* Option[String] */ recency0) {
  return _wA({method: "GET", url: `/mobile/ping/2fa` + _qS([(recency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("recency", recency0))])});
};
_nS('controllers.account.Application'); _root.controllers.account.Application.security = function() {
  return _wA({method: "GET", url: `/security`});
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.getDepositInstructions = function(/* models.money.TransferCurrency.XfersCurrency */ fiat0) {
  if (true) { return _wA({method: "GET", url: `/settings/xfers/deposit/${encodeURIComponent(fiat0)}/instructions`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/xfers/deposit/${encodeURIComponent(fiat0)}/instructions`}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.getMobile = function() {
  return _wA({method: "GET", url: `/mobile/settings/security`});
};
_nS('controllers.security.AuthyWebhookAccountChangeController'); _root.controllers.security.AuthyWebhookAccountChangeController.notifyAuthyPhoneEvents = function() {
  return _wA({method: "POST", url: `/authy/phone-events`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getAssetDepositPage = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0) {
  return _wA({method: "GET", url: `/transfer/deposit/${encodeURIComponent(asset0)}`});
};
_nS('controllers.order.CurrenciesProviderController'); _root.controllers.order.CurrenciesProviderController.internalCustody = function() {
  return _wA({method: "GET", url: `/supported/custody`});
};
_nS('controllers.account.AccountGroupSelectionController'); _root.controllers.account.AccountGroupSelectionController.post = function(/* String */ group0) {
  if (true) { return _wA({method: "POST", url: `/account-group` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("group", group0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/account-group` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("group", group0)])}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.resume = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "PUT", url: `/mobile/recurring-orders/resume/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.settings.DocumentSettingsController'); _root.controllers.settings.DocumentSettingsController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/documents`}); }
  if (true) { return _wA({method: "GET", url: `/settings/documents/account-statements`}); }
  if (true) { return _wA({method: "GET", url: `/settings/documents/transaction-history`}); }
  if (true) { return _wA({method: "GET", url: `/settings/documents/trade-volume`}); }
  if (true) { return _wA({method: "GET", url: `/settings/documents/tax-documents`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.getMobileV2 = function() {
  return _wA({method: "GET", url: `/mobile/v2/recurring-orders`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.removeGroupWithdrawalWhitelist = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/group/remove`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/group/remove`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.transferBetweenAccounts = function() {
  if (true) { return _wA({method: "POST", url: `/transfer/between`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/between`}); }
};
_nS('controllers.risk.KycRefreshController'); _root.controllers.risk.KycRefreshController.update = function() {
  if (true) { return _wA({method: "POST", url: `/kyc-refresh/update`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/kyc-refresh/update`}); }
};
_nS('controllers.admin.AdminController'); _root.controllers.admin.AdminController.metrics = function() {
  return _wA({method: "GET", url: `/admin/metrics`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.updateDepositCryptoAddressLabel = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  if (true) { return _wA({method: "POST", url: `/transfer/deposit/${encodeURIComponent(asset0)}/label`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/deposit/${encodeURIComponent(asset0)}/label`}); }
};
_nS('controllers.settings.LegalSettingsController'); _root.controllers.settings.LegalSettingsController.get = function() {
  return _wA({method: "GET", url: `/settings/legal`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.addPost = function() {
  return _wA({method: "POST", url: `/settings/users/add`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getTradesByPairs = function(/* Option[Long] */ from0, /* Option[List[com.gemini.assets.veneer.TradingPairSymbol]] */ tradingPairs1, /* Option[Boolean] */ partiallyOrFullyExecuted2) {
  return _wA({method: "GET", url: `/history/trades-by-pairs` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (tradingPairs1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPairs", tradingPairs1)), (partiallyOrFullyExecuted2 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("partiallyOrFullyExecuted", partiallyOrFullyExecuted2))])});
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.confirmSettlement = function() {
  return _wA({method: "POST", url: `/settlement/confirm`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getNetworkAddress = function(/* models.ChainNetwork */ network0) {
  return _wA({method: "GET", url: `/transfer/approved-address/${encodeURIComponent(network0)}`});
};
_nS('controllers.external.samsung.SamsungAddressController'); _root.controllers.external.samsung.SamsungAddressController.list = function() {
  return _wA({method: "GET", url: `/mobile/samsung/addresses`});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.createMPCCustodyAddress = function(/* com.gemini.assets.core.AssetCode */ currency0) {
  return _wA({method: "POST", url: `/custody/mpc/createAddress` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("currency", currency0)])});
};
_nS('controllers.settings.ChangePasswordController'); _root.controllers.settings.ChangePasswordController.post = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/password`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/password`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.updateDestinationToTrading = function(/* models.RecurringOrderId */ recurringOrderId0) {
  if (true) { return _wA({method: "PUT", url: `/web/recurring-orders/updateDestination/trading/${encodeURIComponent(recurringOrderId0)}`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/recurring-orders/updateDestination/trading/${encodeURIComponent(recurringOrderId0)}`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.addAchPost = function(/* String */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/settings/bank/add-ach/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/add-ach/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.mobile.CommonController'); _root.controllers.mobile.CommonController.sardineSessionId = function() {
  return _wA({method: "GET", url: `/mobile/session-id`});
};
_nS('com.gemini.earn.controllers.webserver.EarnFinalDistributionController'); _root.com.gemini.earn.controllers.webserver.EarnFinalDistributionController.claimFinalDistribution = function(/* String */ claimId0) {
  if (true) { return _wA({method: "POST", url: `/settings/earn-final-distribution-claim/${claimId0}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/earn-final-distribution-claim/${claimId0}`}); }
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getSinglePairDetailLegacy = function(/* String */ pair0, /* exchange.metrics.Candle.TimeFrame */ time_frame1) {
  return _wA({method: "GET", url: `/mobile/detail/${encodeURIComponent(pair0)}/${encodeURIComponent(time_frame1)}`});
};
_nS('controllers.graphql.GraphQlController'); _root.controllers.graphql.GraphQlController.dispatch = function() {
  return _wA({method: "POST", url: `/graphql`});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.createMPCCustodyAccount = function(/* String */ name0) {
  return _wA({method: "POST", url: `/custody/mpc/createAccount` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("name", name0)])});
};
_nS('controllers.clearing.GCConfirmationController'); _root.controllers.clearing.GCConfirmationController.post = function() {
  return _wA({method: "POST", url: `/web/clearing/confirm`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getAwaitingOwnershipAttestations = function(/* Option[Long] */ from0) {
  if (true) { return _wA({method: "GET", url: `/history/transfers/awaiting-ownership-attestation` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/transfers/awaiting-ownership-attestation` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.getSupportedBanks = function() {
  if (true) { return _wA({method: "GET", url: `/settings/xfers/supportedBanks`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/xfers/supportedBanks`}); }
};
_nS('controllers.order.CurrenciesProviderController'); _root.controllers.order.CurrenciesProviderController.mobileExchange = function() {
  return _wA({method: "GET", url: `/mobile/currencies`});
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.requestCcpaData = function() {
  if (true) { return _wA({method: "POST", url: `/settings/profile/ccpa`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/profile/ccpa`}); }
};
_nS('controllers.settings.UserExchangeSettingsController'); _root.controllers.settings.UserExchangeSettingsController.post = function() {
  return _wA({method: "POST", url: `/settings/users/exchange`});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.finishAuthentication = function(/* Option[com.gemini.util.ValidEmail] */ email0) {
  if (true) { return _wA({method: "POST", url: `/signin/2fa/auth` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/2fa/auth` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.updateCredential = function(/* com.yubico.webauthn.data.ByteArray */ id0) {
  if (true) { return _wA({method: "PATCH", url: `/settings/security/credential/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "PATCH", url: `/mobile/settings/security/credential/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.updateAccountName = function() {
  if (true) { return _wA({method: "POST", url: `/settings/name`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/name`}); }
};
_nS('controllers.settings.WebAuthnAuthenticationController'); _root.controllers.settings.WebAuthnAuthenticationController.finishAuthentication = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/credential/authn/finish`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/credential/authn/finish`}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.blockedView = function() {
  if (true) { return _wA({method: "GET", url: `/register/institution/pending`}); }
  if (true) { return _wA({method: "GET", url: `/register/institution/noaccess`}); }
};
_nS('controllers.external.xfers.XfersWebhookController'); _root.controllers.external.xfers.XfersWebhookController.events = function() {
  return _wA({method: "POST", url: `/external/xfers/events`});
};
_nS('controllers.mobile.DashboardController'); _root.controllers.mobile.DashboardController.notionalBalanceHistory = function(/* engine.repo.NotionalBalanceHistoryCache.TimeSpan */ timespan0) {
  return _wA({method: "GET", url: `/web/notional-balance-history` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("timespan", timespan0)])});
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.tradingBalance = function(/* String */ symbol0) {
  if (true) { return _wA({method: "GET", url: `/derivatives/trading-balance/${encodeURIComponent(symbol0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/derivatives/trading-balance/${encodeURIComponent(symbol0)}`}); }
};
_nS('controllers.social.FeatureElectionController'); _root.controllers.social.FeatureElectionController.optOut = function() {
  if (true) { return _wA({method: "POST", url: `/settings/feature/optOut`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/feature/optOut`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.dynamicWithdrawDetails = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/transfer/withdraw/${encodeURIComponent(asset0)}/details`});
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.getSettlements = function() {
  return _wA({method: "GET", url: `/settlement/settlements`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.post = function() {
  if (true) { return _wA({method: "POST", url: `/trade`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/trade`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getGrowAssetForProvider = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0, /* com.gemini.earn.EarnProviderType */ providerType1) {
  if (true) { return _wA({method: "GET", url: `/grow/${encodeURIComponent(asset0)}/${encodeURIComponent(providerType1)}`}); }
  if (true) { return _wA({method: "GET", url: `/stake/${encodeURIComponent(asset0)}/${encodeURIComponent(providerType1)}`}); }
};
_nS('controllers.social.FeatureElectionController'); _root.controllers.social.FeatureElectionController.optIn = function() {
  if (true) { return _wA({method: "POST", url: `/settings/feature/optIn`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/feature/optIn`}); }
};
_nS('controllers.register.ConfirmPhoneController'); _root.controllers.register.ConfirmPhoneController.verifyPhone = function() {
  if (true) { return _wA({method: "POST", url: `/register/confirm-phone/2fa`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/confirm-phone/2fa`}); }
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.authenticateTransaction = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/authenticate-transaction`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/authenticate-transaction`}); }
};
_nS('controllers.settings.WireSettingsController'); _root.controllers.settings.WireSettingsController.get = function(/* String */ uuid0) {
  if (true) { return _wA({method: "GET", url: `/settings/wire/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/wire/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getRanksHistory = function() {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/rank/history`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/rank/history`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.validateAddress = function(/* models.ChainNetwork */ network0, /* String */ address1) {
  return _wA({method: "GET", url: `/transfer/validate-address/${encodeURIComponent(network0)}/${encodeURIComponent(address1)}`});
};
_nS('controllers.clearing.GCOrderController'); _root.controllers.clearing.GCOrderController.orderHistory = function(/* Int */ startIndex0, /* Int */ count1) {
  return _wA({method: "GET", url: `/web/clearing/history` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startIndex", startIndex0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("count", count1)])});
};
_nS('controllers.bcolo.BcoloAuthController'); _root.controllers.bcolo.BcoloAuthController.auth = function(/* String */ code0) {
  if (true) { return _wA({method: "GET", url: `/bcolo/auth` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("code", code0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/bcolo/auth` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("code", code0)])}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.transactionHistory = function(/* String */ account_hash0, /* org.joda.time.LocalDate */ fromDate1, /* org.joda.time.LocalDate */ toDate2, /* Boolean */ earnHist3) {
  if (true) { return _wA({method: "GET", url: `/history/${encodeURIComponent(account_hash0)}/transactions.xlsx` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("fromDate", fromDate1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("toDate", toDate2), (earnHist3 == null ? null : (function(k,v){return k+'='+(!!v)})("earnHist", earnHist3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/${encodeURIComponent(account_hash0)}/transactions.xlsx` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("fromDate", fromDate1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("toDate", toDate2), (earnHist3 == null ? null : (function(k,v){return k+'='+(!!v)})("earnHist", earnHist3))])}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.fetchFilepickerConfig = function() {
  if (true) { return _wA({method: "GET", url: `/upload/config`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/upload/config`}); }
};
_nS('controllers.retail.InstantOrderController'); _root.controllers.retail.InstantOrderController.getQuoteV2 = function(/* com.gemini.assets.veneer.CurrencyCode */ to0, /* com.gemini.assets.veneer.CurrencyCode */ from1, /* models.money.GenericDecimal */ totalSpend2, /* Option[java.util.UUID] */ paymentMethodUuid3, /* Option[com.gemini.instant.commons.PaymentMethodType] */ paymentMethodType4, /* Option[java.util.UUID] */ internalReference5, /* Option[Boolean] */ isRecurringOrder6, /* Option[models.CardAccountType] */ cardAccountType7, /* Option[com.gemini.earn.EarnProviderId] */ earnProviderId8, /* Option[String] */ side9, /* Option[com.gemini.assets.veneer.CurrencyCode] */ totalSpendCurrency10) {
  if (true) { return _wA({method: "GET", url: `/web/v2/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("to", to0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("from", from1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("totalSpend", totalSpend2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodUuid", paymentMethodUuid3), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodType", paymentMethodType4), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("internalReference", internalReference5), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("isRecurringOrder", isRecurringOrder6), (cardAccountType7 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("cardAccountType", cardAccountType7)), (earnProviderId8 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("earnProviderId", earnProviderId8)), (side9 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("side", side9)), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("totalSpendCurrency", totalSpendCurrency10)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("to", to0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("from", from1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("totalSpend", totalSpend2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodUuid", paymentMethodUuid3), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodType", paymentMethodType4), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("internalReference", internalReference5), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("isRecurringOrder", isRecurringOrder6), (cardAccountType7 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("cardAccountType", cardAccountType7)), (earnProviderId8 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("earnProviderId", earnProviderId8)), (side9 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("side", side9)), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("totalSpendCurrency", totalSpendCurrency10)])}); }
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.deviceDataInitialization = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/device-data-initialization`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/device-data-initialization`}); }
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.tokenAuthCode = function() {
  if (true) { return _wA({method: "POST", url: `/auth/token`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/token`}); }
};
_nS('controllers.notifications.NotificationsController'); _root.controllers.notifications.NotificationsController.getNotificationCount = function() {
  if (true) { return _wA({method: "GET", url: `/web/notifications/count`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/notifications/count`}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.updateAddress = function() {
  if (true) { return _wA({method: "POST", url: `/settings/profile/updateAddress`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/profile/updateAddress`}); }
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.getAttestation = function() {
  if (true) { return _wA({method: "GET", url: `/legal-agreements/attestation`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/legal-agreements/attestation`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.linkBankPost = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bank/link`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/link`}); }
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.changeLeverage = function(/* com.gemini.accounts.models.UiAccountLeverage */ leverage0) {
  if (true) { return _wA({method: "POST", url: `/settings/derivatives/change-leverage` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("leverage", leverage0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/derivatives/change-leverage` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("leverage", leverage0)])}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.accountClosed = function() {
  if (true) { return _wA({method: "GET", url: `/settings/profile/accountClosed`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/profile/accountClosed`}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.addGet = function() {
  return _wA({method: "GET", url: `/settings/users/add`});
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.getSettlement = function(/* exchange.api.settlement.SettlementId */ settlementId0) {
  return _wA({method: "GET", url: `/settlement/${encodeURIComponent(settlementId0)}`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.card3dsAuthenticateTransaction = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/card/authenticate-transaction`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/card/authenticate-transaction`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.withdrawMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/mobile/transfer/withdraw/${encodeURIComponent(asset0)}`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.approveAmuaRequestPost = function(/* Boolean */ isAccountScopeRequest0) {
  return _wA({method: "POST", url: `/settings/users/approve-amua-request` + _qS([(function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest0)])});
};
_nS('controllers.register.DynamicInfoCaptureController'); _root.controllers.register.DynamicInfoCaptureController.post = function() {
  if (true) { return _wA({method: "POST", url: `/register/user-info`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/user-info`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.get = function() {
  if (true) { return _wA({method: "GET", url: `/transfer`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/transfer`}); }
};
_nS('controllers.retail.InstantOrderController'); _root.controllers.retail.InstantOrderController.postMobile = function() {
  return _wA({method: "POST", url: `/mobile/order`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.log3DSactivity = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/log`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/log`}); }
};
_nS('controllers.clearing.GCOrderController'); _root.controllers.clearing.GCOrderController.cancelOrder = function(/* exchange.api.clearing.ClearingId */ clearingId0) {
  return _wA({method: "POST", url: `/web/clearing/cancel/${encodeURIComponent(clearingId0)}`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.isTransferBetweenAccountsAllowed = function() {
  if (true) { return _wA({method: "POST", url: `/transfer/between/allowed`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/between/allowed`}); }
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.resetPost = function(/* com.gemini.util.ValidAlphaNumeric */ secret0, /* com.gemini.util.ValidEmail */ email1) {
  if (true) { return _wA({method: "POST", url: `/reset-password` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("secret", secret0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email1)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/reset-password` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("secret", secret0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email1)])}); }
};
_nS('controllers.transfer.DepositWireController'); _root.controllers.transfer.DepositWireController.wireDepositDetails = function(/* models.money.TransferCurrency.WireCurrency */ fiat0) {
  if (true) { return _wA({method: "GET", url: `/deposit/${encodeURIComponent(fiat0)}/wire/details`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/deposit/${encodeURIComponent(fiat0)}/wire/details`}); }
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.submitAppropriatenessTest = function() {
  if (true) { return _wA({method: "POST", url: `/legal-agreements/appropriateness`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/legal-agreements/appropriateness`}); }
};
_nS('controllers.markets.AdvancedMarketsController'); _root.controllers.markets.AdvancedMarketsController.getMarkets = function() {
  if (true) { return _wA({method: "GET", url: `/advanced/markets`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/advanced/markets`}); }
};
_nS('controllers.settings.AccountCurrencySettingsController'); _root.controllers.settings.AccountCurrencySettingsController.upsertCurrency = function() {
  if (true) { return _wA({method: "POST", url: `/settings/account/bank-currency`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/account/bank-currency`}); }
};
_nS('controllers.settings.GroupApiSettingsController'); _root.controllers.settings.GroupApiSettingsController.createGroup = function() {
  return _wA({method: "POST", url: `/settings/group-api/create`});
};
_nS('controllers.account.ConsentInterstitialController'); _root.controllers.account.ConsentInterstitialController.get = function() {
  if (true) { return _wA({method: "GET", url: `/consent`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/consent`}); }
};
_nS('controllers.settings.GroupApiSettingsController'); _root.controllers.settings.GroupApiSettingsController.disableGroup = function(/* String */ apiKey0) {
  return _wA({method: "POST", url: `/settings/group-api/disable/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.previewOrder = function() {
  if (true) { return _wA({method: "POST", url: `/trade/preview`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/trade/preview`}); }
};
_nS('controllers.mua.CustodyWithdrawalController'); _root.controllers.mua.CustodyWithdrawalController.cancelPendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  return _wA({method: "POST", url: `/custody/withdrawal/pending/cancel/${encodeURIComponent(id0)}`});
};
_nS('controllers.retail.MarketFiltersController'); _root.controllers.retail.MarketFiltersController.getCurrencyMarketFilters = function(/* Option[models.money.TransferCurrency.BankCurrency] */ priceCurrency0, /* Option[Boolean] */ includeRange1) {
  return _wA({method: "GET", url: `/mobile/market` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("includeRange", includeRange1)])});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.textInstallLink = function() {
  return _wA({method: "POST", url: `/signin/2fa/textme`});
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.formDataMobile = function() {
  return _wA({method: "GET", url: `/mobile/recurring-orders/new`});
};
_nS('controllers.retail.AllAssetDetailController'); _root.controllers.retail.AllAssetDetailController.getAllPairsDetailsExternalDaily = function(/* Option[models.money.TransferCurrency.BankCurrency] */ priceCurrency0, /* Option[com.gemini.web.models.DailyTimeFrame] */ dailyTimeFrame1, /* Boolean */ includeRange2) {
  return _wA({method: "GET", url: `/allPublicDetailsExternalDaily` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (dailyTimeFrame1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("dailyTimeFrame", dailyTimeFrame1)), (includeRange2 == null ? null : (function(k,v){return k+'='+(!!v)})("includeRange", includeRange2))])});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.cancelAllOrders = function() {
  if (true) { return _wA({method: "POST", url: `/trade/orders/cancelAll`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/trade/orders/cancelAll`}); }
};
_nS('controllers.settings.ChangePasswordController'); _root.controllers.settings.ChangePasswordController.force = function(/* Option[String] */ redirect0) {
  return _wA({method: "GET", url: `/signin/password-change` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0)])});
};
_nS('controllers.external.singpass.SingPassController'); _root.controllers.external.singpass.SingPassController.getData = function(/* String */ code0, /* String */ state1) {
  if (true) { return _wA({method: "GET", url: `/singpass/getdata` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("code", code0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("state", state1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/singpass/getdata` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("code", code0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("state", state1)])}); }
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.disable = function(/* String */ apiKey0) {
  return _wA({method: "POST", url: `/settings/api/disable/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.mobile.CommonController'); _root.controllers.mobile.CommonController.signInNotify = function() {
  return _wA({method: "POST", url: `/mobile/sign-in-notify`});
};
_nS('controllers.settings.BcoloSettingsController'); _root.controllers.settings.BcoloSettingsController.linkAccounts = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bcolo/payment-methods`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bcolo/payment-methods`}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.toggleWithdrawalWhitelist = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/status`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/status`}); }
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.clientSummary = function() {
  if (true) { return _wA({method: "GET", url: `/auth/overview`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/auth/overview`}); }
};
_nS('controllers.support.ZendeskFeedbackController'); _root.controllers.support.ZendeskFeedbackController.supportATTopics = function() {
  return _wA({method: "GET", url: `/feedback/topics`});
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.pause = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "PUT", url: `/mobile/recurring-orders/pause/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.card3dsDeviceDataInitialization = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/card/device-data-initialization`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/card/device-data-initialization`}); }
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.enableBankAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/xfers/bank/enable`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/xfers/bank/enable`}); }
};
_nS('controllers.external.sendgrid.SendGridWebhookController'); _root.controllers.external.sendgrid.SendGridWebhookController.events = function() {
  return _wA({method: "POST", url: `/external/sendgrid/events`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.get = function(/* String */ symbol0) {
  if (true) { return _wA({method: "GET", url: `/trade/${encodeURIComponent(symbol0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/${encodeURIComponent(symbol0)}`}); }
};
_nS('controllers.external.singpass.SingPassController'); _root.controllers.external.singpass.SingPassController.callback = function(/* Option[String] */ code0, /* Option[String] */ state1) {
  return _wA({method: "GET", url: `/singpass/callback` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("code", code0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("state", state1)])});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.cryptoWithdrawAssetControls = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "GET", url: `/transfer/crypto/withdraw/${encodeURIComponent(asset0)}/controls`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.cryptoWithdrawAssetFee = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/transfer/crypto/withdraw/${encodeURIComponent(asset0)}/fee`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getPositions = function() {
  if (true) { return _wA({method: "GET", url: `/trade/positions`}); }
  if (true) { return _wA({method: "GET", url: `/positions`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/positions`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/positions`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getValidator = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0, /* String */ validatorId1) {
  if (true) { return _wA({method: "GET", url: `/grow/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
  if (true) { return _wA({method: "GET", url: `/stake/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
};
_nS('controllers.retail.BalanceControllerV2'); _root.controllers.retail.BalanceControllerV2.get = function() {
  if (true) { return _wA({method: "GET", url: `/balances`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/balances`}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.setupPasswordless = function(/* com.yubico.webauthn.data.ByteArray */ id0) {
  if (true) { return _wA({method: "PATCH", url: `/settings/security/passwordless/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "PATCH", url: `/mobile/settings/security/passwordless/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.manualAddBankAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bank/manual/add-ach`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/manual/add-ach`}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.approveAccountPendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/pending/approve/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/pending/approve/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.register.OnboardingDoneController'); _root.controllers.register.OnboardingDoneController.verify = function() {
  if (true) { return _wA({method: "POST", url: `/register/submit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/submit`}); }
};
_nS('controllers.settings.WireSettingsController'); _root.controllers.settings.WireSettingsController.post = function(/* String */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/settings/wire/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/wire/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getAsGrow = function() {
  return _wA({method: "GET", url: `/earn`});
};
_nS('controllers.social.SocialProfileController'); _root.controllers.social.SocialProfileController.getSocialProfile = function() {
  if (true) { return _wA({method: "GET", url: `/settings/social/profile`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/social/profile`}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.approveGroupPendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/group/pending/approve/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/group/pending/approve/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.settings.ChangePasswordController'); _root.controllers.settings.ChangePasswordController.getMobile = function() {
  return _wA({method: "GET", url: `/mobile/settings/security/password`});
};
_nS('controllers.retail.AssetMarketCapController'); _root.controllers.retail.AssetMarketCapController.wwwOptions = function() {
  return _wA({method: "OPTIONS", url: `/marketcap`});
};
_nS('controllers.retail.BalanceControllerV2'); _root.controllers.retail.BalanceControllerV2.redirectToAccountHistory = function(/* String */ subaccountHashid0) {
  return _wA({method: "GET", url: `/balances/redirect/history` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("subaccountHashid", subaccountHashid0)])});
};
_nS('controllers.mobile.DashboardController'); _root.controllers.mobile.DashboardController.notionalBalanceHistoryMobile = function(/* engine.repo.NotionalBalanceHistoryCache.TimeSpan */ timespan0) {
  if (true) { return _wA({method: "GET", url: `/mobile/balance` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("timespan", timespan0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/notional-balance-history` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("timespan", timespan0)])}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getMonthlyWinner = function(/* Option[org.joda.time.LocalDate] */ date0, /* com.gemini.leaderboard.models.LeaderboardTypeSymbol */ baseType1) {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/monthlywinner` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("baseType", baseType1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/monthlywinner` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("baseType", baseType1)])}); }
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.signOut = function() {
  if (true) { return _wA({method: "POST", url: `/auth/signOut`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/signOut`}); }
};
_nS('controllers.settings.NotificationSettingsController'); _root.controllers.settings.NotificationSettingsController.getOrderConfirmationSettings = function() {
  return _wA({method: "GET", url: `/settings/notifications/order/confirmation`});
};
_nS('controllers.risk.KycRefreshController'); _root.controllers.risk.KycRefreshController.isKycRefreshRequired = function() {
  return _wA({method: "GET", url: `/mobile/kyc-refresh`});
};
_nS('controllers.mobile.DashboardController'); _root.controllers.mobile.DashboardController.getGeminiEntityConversion = function() {
  return _wA({method: "GET", url: `/mobile/settings/gemini-entity/conversion`});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.updateMobileAdvancedTradeUi = function(/* Boolean */ enabled0) {
  return _wA({method: "POST", url: `/mobile/settings/ui/advanced-trade-enabled` + _qS([(function(k,v){return k+'='+(!!v)})("enabled", enabled0)])});
};
_nS('controllers.mua.CustodyWithdrawalController'); _root.controllers.mua.CustodyWithdrawalController.approvePendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  return _wA({method: "POST", url: `/custody/withdrawal/pending/approve/${encodeURIComponent(id0)}`});
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.createAttestation = function() {
  if (true) { return _wA({method: "POST", url: `/legal-agreements/attestation`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/legal-agreements/attestation`}); }
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getSinglePairDetailsMobile = function(/* String */ pair0, /* exchange.metrics.Candle.TimeFrame */ time_frame1) {
  return _wA({method: "GET", url: `/mobile/details/${encodeURIComponent(pair0)}/${encodeURIComponent(time_frame1)}`});
};
_nS('controllers.settings.BcoloSettingsController'); _root.controllers.settings.BcoloSettingsController.syncAccounts = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bcolo/accounts`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bcolo/accounts`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.betweenInstantMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/mobile/transfer/withdraw/${encodeURIComponent(asset0)}/between`});
};
_nS('controllers.settings.NotificationSettingsController'); _root.controllers.settings.NotificationSettingsController.post = function() {
  return _wA({method: "POST", url: `/settings/notifications`});
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.closeGroup = function() {
  if (true) { return _wA({method: "POST", url: `/settings/profile/close`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/profile/close`}); }
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.rejectSettlement = function() {
  return _wA({method: "POST", url: `/settlement/reject`});
};
_nS('controllers.graphql.bridge.GraphQlBridgeController'); _root.controllers.graphql.bridge.GraphQlBridgeController.dispatch = function() {
  return _wA({method: "POST", url: `/bridge`});
};
_nS('controllers.user.UserController'); _root.controllers.user.UserController.subAccounts = function(/* Long */ offset0, /* Int */ limit1, /* Option[String] */ name2, /* Boolean */ sortAsc3, /* Boolean */ withBalance4, /* Boolean */ onlyTradingAccounts5, /* Option[String] */ sortBy6) {
  if (true) { return _wA({method: "GET", url: `/user/sub-accounts` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("offset", offset0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit1), (name2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("name", name2)), (sortAsc3 == null ? null : (function(k,v){return k+'='+(!!v)})("sortAsc", sortAsc3)), (withBalance4 == null ? null : (function(k,v){return k+'='+(!!v)})("withBalance", withBalance4)), (onlyTradingAccounts5 == null ? null : (function(k,v){return k+'='+(!!v)})("onlyTradingAccounts", onlyTradingAccounts5)), (sortBy6 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("sortBy", sortBy6))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/user/sub-accounts` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("offset", offset0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit1), (name2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("name", name2)), (sortAsc3 == null ? null : (function(k,v){return k+'='+(!!v)})("sortAsc", sortAsc3)), (withBalance4 == null ? null : (function(k,v){return k+'='+(!!v)})("withBalance", withBalance4)), (onlyTradingAccounts5 == null ? null : (function(k,v){return k+'='+(!!v)})("onlyTradingAccounts", onlyTradingAccounts5)), (sortBy6 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("sortBy", sortBy6))])}); }
};
_nS('controllers.settings.NewAccountSettingsController'); _root.controllers.settings.NewAccountSettingsController.get = function() {
  return _wA({method: "GET", url: `/settings/newaccount`});
};
_nS('controllers.order.RiskController'); _root.controllers.order.RiskController.getAccountMargin = function(/* String */ symbol0) {
  if (true) { return _wA({method: "GET", url: `/margin` + _qS([(symbol0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("symbol", symbol0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/margin` + _qS([(symbol0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("symbol", symbol0))])}); }
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.post = function() {
  return _wA({method: "POST", url: `/settlement/create`});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.getUserOverview = function() {
  return _wA({method: "GET", url: `/mobile/user/overview`});
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getWinners = function(/* com.gemini.leaderboard.models.LeaderboardTypeSymbol */ baseType0, /* Option[Int] */ limit1) {
  return _wA({method: "GET", url: `/v1/leaderboard/winners` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("baseType", baseType0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit1)])});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getAccountConfig = function(/* String */ account_hash0) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(account_hash0)}/config`});
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.disableMobile = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "DELETE", url: `/mobile/recurring-orders/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.save = function() {
  return _wA({method: "POST", url: `/register/institution/save`});
};
_nS('controllers.settings.WebAuthnRegistrationController'); _root.controllers.settings.WebAuthnRegistrationController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/security/credential`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/security/credential`}); }
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.list = function() {
  return _wA({method: "GET", url: `/settings/api`});
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getQueueLength = function(/* com.gemini.earn.EarnProviderId */ providerId0, /* com.gemini.assets.veneer.CurrencyCode */ asset1) {
  if (true) { return _wA({method: "GET", url: `/grow/queue/${encodeURIComponent(asset1)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0)])}); }
  if (true) { return _wA({method: "GET", url: `/stake/queue/${encodeURIComponent(asset1)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow/queue/${encodeURIComponent(asset1)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0)])}); }
};
_nS('controllers.register.DynamicInfoCaptureController'); _root.controllers.register.DynamicInfoCaptureController.postPreFillAnalytics = function() {
  if (true) { return _wA({method: "POST", url: `/register/pre-fill/analytics`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/pre-fill/analytics`}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.deletePost = function(/* com.gemini.util.ValidEmail */ email0, /* Boolean */ isAccountScopedRequest1) {
  return _wA({method: "POST", url: `/settings/users/delete-role` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0), (function(k,v){return k+'='+(!!v)})("isAccountScopedRequest", isAccountScopedRequest1)])});
};
_nS('controllers.settings.SettlementSettingsController'); _root.controllers.settings.SettlementSettingsController.get = function() {
  return _wA({method: "GET", url: `/settlement/settings`});
};
_nS('controllers.external.bcb.BcbWebhookController'); _root.controllers.external.bcb.BcbWebhookController.events = function() {
  return _wA({method: "POST", url: `/external/bcb/events`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.attestWithdrawalAddress = function() {
  if (true) { return _wA({method: "POST", url: `/transfer/withdrawal-address-attestation`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/withdrawal-address-attestation`}); }
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.enterEmailPost = function() {
  if (true) { return _wA({method: "POST", url: `/signin/forgot`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/forgot`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getFeeSchedule = function(/* List[exchange.api.OrderSource] */ orderSource0, /* Option[List[com.gemini.assets.veneer.TradingPairSymbol]] */ pair1) {
  if (true) { return _wA({method: "GET", url: `/trade/fees/schedule` + _qS([(function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("orderSource", orderSource0), (pair1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pair", pair1))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/fees/schedule` + _qS([(function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("orderSource", orderSource0), (pair1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pair", pair1))])}); }
};
_nS('controllers.dashboard.DashboardController'); _root.controllers.dashboard.DashboardController.mainWithCurrency = function(/* String */ currency0) {
  if (true) { return _wA({method: "GET", url: `/buy/${encodeURIComponent(currency0)}`}); }
  if (true) { return _wA({method: "GET", url: `/sell/${encodeURIComponent(currency0)}`}); }
  if (true) { return _wA({method: "GET", url: `/convert/${encodeURIComponent(currency0)}`}); }
  if (true) { return _wA({method: "GET", url: `/alert/${encodeURIComponent(currency0)}`}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.requestOcrOnDocument = function() {
  return _wA({method: "POST", url: `/mobile/register/document/ocr`});
};
_nS('controllers.settings.WebAuthnRegistrationController'); _root.controllers.settings.WebAuthnRegistrationController.post = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/credential`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/credential`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.add = function() {
  return _wA({method: "POST", url: `/web/recurring-orders`});
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.closeSubAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/profile/closeSubAccount`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/profile/closeSubAccount`}); }
};
_nS('controllers.support.ZendeskFeedbackController'); _root.controllers.support.ZendeskFeedbackController.supportPFTopics = function() {
  if (true) { return _wA({method: "GET", url: `/feedback/pftopics`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/feedback/pftopics`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.redirectToDefault = function(/* Option[String] */ from0) {
  if (true) { return _wA({method: "GET", url: `/trade` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
};
_nS('controllers.notifications.NotificationsController'); _root.controllers.notifications.NotificationsController.markNotificationsAsRead = function() {
  if (true) { return _wA({method: "POST", url: `/web/notifications/read`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/notifications/read`}); }
};
_nS('controllers.external.complyadvantage.MonitorWebhookController'); _root.controllers.external.complyadvantage.MonitorWebhookController.hook = function() {
  return _wA({method: "POST", url: `/external/complyadvantage/HoBzZDzGRgWRobH7svKP7A`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getNetworkAddressForMobile = function(/* models.ChainNetwork */ network0) {
  return _wA({method: "GET", url: `/mobile/transfer/approved-address/${encodeURIComponent(network0)}`});
};
_nS('controllers.retail.MarketFiltersController'); _root.controllers.retail.MarketFiltersController.getTradingPairMarketFilters = function() {
  if (true) { return _wA({method: "GET", url: `/web/filters/advanced`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/filters/advanced`}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.endAllSessions = function() {
  return _wA({method: "POST", url: `/settings/security/endAllSessions`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.tradeVolume = function(/* String */ account_hash0) {
  if (true) { return _wA({method: "GET", url: `/history/${encodeURIComponent(account_hash0)}/tradeVolume.xlsx`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/${encodeURIComponent(account_hash0)}/tradeVolume.xlsx`}); }
};
_nS('controllers.dashboard.DashboardController'); _root.controllers.dashboard.DashboardController.portfolio = function() {
  if (true) { return _wA({method: "GET", url: `/portfolio`}); }
  if (true) { return _wA({method: "GET", url: `/portfolio/all`}); }
};
_nS('controllers.external.looker.LookerWebhookControllerV2'); _root.controllers.external.looker.LookerWebhookControllerV2.bouncedEmails = function() {
  return _wA({method: "POST", url: `/external/looker/emails/bounced`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.editGroupAddressAttestation = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/group/attest-address-ownership`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/group/attest-address-ownership`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getBetweenPage = function() {
  return _wA({method: "GET", url: `/transfer/between`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.testDatadogTrace = function() {
  return _wA({method: "POST", url: `/testing/dd`});
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.resetGet = function(/* com.gemini.util.ValidAlphaNumeric */ secret0, /* com.gemini.util.ValidEmail */ email1) {
  if (true) { return _wA({method: "GET", url: `/reset-password` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("secret", secret0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/reset-password` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("secret", secret0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email1)])}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.createCustodyAddress = function(/* com.gemini.assets.core.AssetCode */ currency0) {
  return _wA({method: "POST", url: `/custody/createAddress` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("currency", currency0)])});
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.attestToAppropriateness = function() {
  if (true) { return _wA({method: "POST", url: `/legal-agreements/appropriateness/attestation`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/legal-agreements/appropriateness/attestation`}); }
};
_nS('controllers.retail.InstantOrderController'); _root.controllers.retail.InstantOrderController.post = function() {
  return _wA({method: "POST", url: `/web/order`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.fundingPaymentHistory = function(/* String */ account_hash0, /* Option[org.joda.time.LocalDate] */ fromDate1, /* Option[org.joda.time.LocalDate] */ toDate2, /* Option[Int] */ numRows3) {
  return _wA({method: "GET", url: `/history/${encodeURIComponent(account_hash0)}/fundingPayment.xlsx` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("fromDate", fromDate1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("toDate", toDate2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("numRows", numRows3)])});
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.addMobile = function() {
  return _wA({method: "POST", url: `/mobile/recurring-orders`});
};
_nS('controllers.settings.LiveSettingsController'); _root.controllers.settings.LiveSettingsController.checkTimeout = function() {
  return _wA({method: "GET", url: `/settings/check-timeout`});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getInvoiceDetailPdf = function(/* String */ accountId0, /* String */ invoiceDate1, /* Long */ invoiceId2) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(accountId0)}/${encodeURIComponent(invoiceDate1)}/${encodeURIComponent(invoiceId2)}/detail/pdf`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.revokeTokensByClientId = function() {
  if (true) { return _wA({method: "POST", url: `/auth/revoke`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/revoke`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.cancelOrder = function(/* String */ hashid0) {
  if (true) { return _wA({method: "POST", url: `/trade/order/${encodeURIComponent(hashid0)}/cancel`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/trade/order/${encodeURIComponent(hashid0)}/cancel`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getTradesAndTransfers = function(/* Option[Long] */ from0, /* Option[Int] */ limit1, /* Option[com.gemini.assets.veneer.CurrencyCode] */ currency2, /* Option[com.gemini.assets.veneer.TradingPairSymbol] */ tradingPair3) {
  if (true) { return _wA({method: "GET", url: `/v2/history` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (limit1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit1)), (currency2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency2)), (tradingPair3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (limit1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit1)), (currency2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency2)), (tradingPair3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/history` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (limit1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit1)), (currency2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency2)), (tradingPair3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair3))])}); }
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.updatePortfolioDisplayV2 = function(/* String */ setting0) {
  if (true) { return _wA({method: "POST", url: `/settings/portfolio/display/${encodeURIComponent(setting0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/portfolio/display/${encodeURIComponent(setting0)}`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.betweenInstant = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/transfer/withdraw/${encodeURIComponent(asset0)}/between`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.deny = function() {
  if (true) { return _wA({method: "POST", url: `/auth/deny`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/deny`}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getDailyLeaderboard = function(/* Option[org.joda.time.LocalDate] */ date0, /* Option[org.joda.time.DateTime] */ dateTime1, /* Option[com.gemini.leaderboard.models.LeaderboardTypeSymbol] */ baseType2, /* Option[Int] */ standingLimit3) {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/daily` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("dateTime", dateTime1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("standingLimit", standingLimit3)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/daily` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("dateTime", dateTime1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("standingLimit", standingLimit3)])}); }
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.fetchDeviceDetails = function(/* Option[String] */ action0) {
  if (true) { return _wA({method: "GET", url: `/device/approve` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("action", action0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/device/approve` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("action", action0)])}); }
};
_nS('controllers.common.DevNotesController'); _root.controllers.common.DevNotesController.notesFrom = function(/* Option[String] */ id0) {
  return _wA({method: "GET", url: `/devNotes/update` + _qS([(id0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("id", id0))])});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getInvoiceSummaryPdf = function(/* String */ accountId0, /* String */ invoiceDate1, /* Long */ invoiceId2) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(accountId0)}/${encodeURIComponent(invoiceDate1)}/${encodeURIComponent(invoiceId2)}/summary/pdf`});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.emailConflictExists = function(/* com.gemini.util.ValidEmail */ emailAddress0) {
  return _wA({method: "GET", url: `/emailConflict/${encodeURIComponent(emailAddress0)}`});
};
_nS('controllers.account.Application'); _root.controllers.account.Application.forwardToSentry = function(/* Int */ projectId0) {
  return _wA({method: "GET", url: `/sentry/api/${projectId0}/store/`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.depositAndroidPaymentInit = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/mobile/transfer/deposit/${encodeURIComponent(asset0)}/ppi-link-token/android`});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.trigger2fa = function(/* Option[String] */ recency0) {
  return _wA({method: "GET", url: `/ping/2fa` + _qS([(recency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("recency", recency0))])});
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.getStraitsxDepositInstructions = function(/* models.money.TransferCurrency.XfersCurrency */ fiat0) {
  if (true) { return _wA({method: "GET", url: `/settings/straitsx/deposit/${encodeURIComponent(fiat0)}/instructions`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/straitsx/deposit/${encodeURIComponent(fiat0)}/instructions`}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.editGroupWhitelistLabel = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/group/edit-label`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/group/edit-label`}); }
};
_nS('controllers.settings.WebAuthnAuthenticationController'); _root.controllers.settings.WebAuthnAuthenticationController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/security/credential/authn`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/security/credential/authn`}); }
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.getMobileAdvancedTradeUi = function() {
  return _wA({method: "GET", url: `/mobile/settings/ui/advanced-trade-enabled`});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.uploadChallenge = function() {
  if (true) { return _wA({method: "POST", url: `/upload/challenge`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/upload/challenge`}); }
};
_nS('controllers.settings.WireSettingsController'); _root.controllers.settings.WireSettingsController.addBankAccountGet = function(/* Boolean */ notSupported0) {
  if (notSupported0 === true) { return _wA({method: "GET", url: `/settings/bank/add/not-supported`}); }
  if (notSupported0 === false) { return _wA({method: "GET", url: `/settings/bank/add/wire`}); }
};
_nS('controllers.security.AuthyWebhookAccountChangeController'); _root.controllers.security.AuthyWebhookAccountChangeController.processMessageStatusUpdate = function() {
  return _wA({method: "POST", url: `/authy/message-status`});
};
_nS('controllers.user.UserController'); _root.controllers.user.UserController.getPermissions = function() {
  return _wA({method: "GET", url: `/user/permissions`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.attestUkDepositSource = function(/* String */ idHash0) {
  if (true) { return _wA({method: "POST", url: `/history/transfers/attest-uk-deposit` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("idHash", idHash0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/history/transfers/attest-uk-deposit` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("idHash", idHash0)])}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.validateIdentity = function() {
  return _wA({method: "POST", url: `/register/institution/validate-identity`});
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.freezeAccount = function(/* String */ token0) {
  return _wA({method: "POST", url: `/freeze-account/${encodeURIComponent(token0)}`});
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.createPerpetualSwapsAccount = function(/* String */ name0, /* com.gemini.accounts.models.UiAccountLeverage */ leverage1) {
  if (true) { return _wA({method: "POST", url: `/settings/derivatives/perpetual-account` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("name", name0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("leverage", leverage1)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/derivatives/perpetual-account` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("name", name0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("leverage", leverage1)])}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.submitOnboarding = function(/* Boolean */ edit0, /* Int */ fileCount1) {
  if (true) { return _wA({method: "POST", url: `/upload/id` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0)), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("fileCount", fileCount1)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/upload/id` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0)), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("fileCount", fileCount1)])}); }
};
_nS('controllers.external.looker.LookerWebhookController'); _root.controllers.external.looker.LookerWebhookController.sendBrazeCanvas = function(/* models.BrazeCanvasId */ canvasId0) {
  return _wA({method: "POST", url: `/external/looker/braze/canvas/${encodeURIComponent(canvasId0)}`});
};
_nS('controllers.credit.CreditDummyController'); _root.controllers.credit.CreditDummyController.index = function(/* String */ any0) {
  if (true) { return _wA({method: "GET", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "POST", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "PATCH", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "PUT", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "DELETE", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "HEAD", url: `/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "PATCH", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "DELETE", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
  if (true) { return _wA({method: "HEAD", url: `/mobile/credit-card/${encodeURIComponent(any0)}`}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getCompetitions = function() {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/competitions/list`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/competitions/list`}); }
};
_nS('controllers.account.Application'); _root.controllers.account.Application.about = function() {
  return _wA({method: "GET", url: `/about`});
};
_nS('controllers.experiment.ExposureController'); _root.controllers.experiment.ExposureController.publishExposure = function() {
  if (true) { return _wA({method: "POST", url: `/exp/exposure`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/exp/exposure`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.showDepositSectionMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0, /* Option[Long] */ offset1, /* Option[Int] */ limit2) {
  return _wA({method: "GET", url: `/mobile/transfer/deposit/${encodeURIComponent(asset0)}` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("offset", offset1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit2)])});
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.redirect = function() {
  return _wA({method: "GET", url: `/settings`});
};
_nS('controllers.account.FeatureConsentController'); _root.controllers.account.FeatureConsentController.post = function() {
  if (true) { return _wA({method: "POST", url: `/consent-form/consent`}); }
  if (true) { return _wA({method: "POST", url: `/consent-form/v3/consent`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/consent-form/consent`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/consent-form/v3/consent`}); }
};
_nS('controllers.social.FeatureElectionController'); _root.controllers.social.FeatureElectionController.listFeatures = function() {
  if (true) { return _wA({method: "GET", url: `/settings/feature/list`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/feature/list`}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.adminRegistration = function() {
  return _wA({method: "GET", url: `/register/institution/admin`});
};
_nS('controllers.user.UserController'); _root.controllers.user.UserController.getAddress = function() {
  if (true) { return _wA({method: "GET", url: `/user/address`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/user/address`}); }
};
_nS('controllers.clearing.GCOrderController'); _root.controllers.clearing.GCOrderController.post = function() {
  return _wA({method: "POST", url: `/web/clearing/new`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.approve = function() {
  if (true) { return _wA({method: "POST", url: `/auth`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth`}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.endSingleSession = function() {
  return _wA({method: "POST", url: `/settings/security/endSession`});
};
_nS('controllers.transfer.GenericCryptoController'); _root.controllers.transfer.GenericCryptoController.qrCode = function(/* com.gemini.assets.veneer.CurrencyCode */ currency0, /* String */ address1) {
  if (true) { return _wA({method: "GET", url: `/deposit/${encodeURIComponent(currency0)}/qr/${encodeURIComponent(address1)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/deposit/${encodeURIComponent(currency0)}/qr/${encodeURIComponent(address1)}`}); }
};
_nS('controllers.experiment.ExposureController'); _root.controllers.experiment.ExposureController.getExperimentVariantByKey = function(/* String */ key0) {
  if (true) { return _wA({method: "GET", url: `/exp/variant/${encodeURIComponent(key0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/exp/variant/${encodeURIComponent(key0)}`}); }
};
_nS('controllers.transfer.DepositWireController'); _root.controllers.transfer.DepositWireController.wireDepositDetailsV2 = function(/* models.money.TransferCurrency.WireCurrency */ fiat0) {
  return _wA({method: "GET", url: `/v2/deposit/${encodeURIComponent(fiat0)}/wire/details`});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.submit = function(/* Int */ fileCount0) {
  return _wA({method: "POST", url: `/upload` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("fileCount", fileCount0)])});
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.delete = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "DELETE", url: `/cards/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "DELETE", url: `/mobile/cards/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.getV2 = function(/* Option[com.gemini.assets.veneer.CurrencyCode] */ notionalCurrency0) {
  return _wA({method: "GET", url: `/web/recurring-orders` + _qS([(notionalCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("notionalCurrency", notionalCurrency0))])});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.addGroupGet = function() {
  return _wA({method: "GET", url: `/settings/users/addGroup`});
};
_nS('controllers.security.SignInController'); _root.controllers.security.SignInController.post = function(/* Option[String] */ redirect0) {
  if (true) { return _wA({method: "POST", url: `/signin` + _qS([(redirect0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin` + _qS([(redirect0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0))])}); }
};
_nS('controllers.social.FeatureElectionController'); _root.controllers.social.FeatureElectionController.optInAll = function() {
  if (true) { return _wA({method: "POST", url: `/settings/feature/optInAll`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/feature/optInAll`}); }
};
_nS('controllers.account.AccountSelectionController'); _root.controllers.account.AccountSelectionController.getForeignEntityIssuingCountry = function() {
  if (true) { return _wA({method: "GET", url: `/account/check-foreign-id`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/account/check-foreign-id`}); }
};
_nS('controllers.marketing.CarouselCardController'); _root.controllers.marketing.CarouselCardController.getCards = function() {
  if (true) { return _wA({method: "GET", url: `/carousel/cards`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/carousel/cards`}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getInvoiceSummary = function(/* String */ account_hash0, /* String */ invoiceDate1) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(account_hash0)}/${encodeURIComponent(invoiceDate1)}/summary`});
};
_nS('controllers.external.plaid.PlaidWebhookController'); _root.controllers.external.plaid.PlaidWebhookController.events = function() {
  return _wA({method: "POST", url: `/external/plaid/events`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.createAccountWhitelistWithdrawal = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/create`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/create`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getLegacyMobileTransactions = function(/* Option[Long] */ from0) {
  return _wA({method: "GET", url: `/mobile/transactions` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])});
};
_nS('controllers.settings.WireSettingsController'); _root.controllers.settings.WireSettingsController.addBankAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bank/add/wire`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/add/wire`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getDepositPage = function() {
  return _wA({method: "GET", url: `/transfer/deposit`});
};
_nS('controllers.account.CustomerTaxStatementController'); _root.controllers.account.CustomerTaxStatementController.getTaxStatement = function(/* String */ account_hash0, /* Int */ taxYear1, /* com.gemini.accounts.models.TaxForm */ taxForm2) {
  if (true) { return _wA({method: "GET", url: `/statement/${encodeURIComponent(account_hash0)}/taxStatement` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("taxYear", taxYear1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("taxForm", taxForm2)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/statement/${encodeURIComponent(account_hash0)}/taxStatement` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("taxYear", taxYear1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("taxForm", taxForm2)])}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.createGeneralHandler = function() {
  return _wA({method: "POST", url: `/register/institution/create-general`});
};
_nS('controllers.bcolo.BcoloAuthController'); _root.controllers.bcolo.BcoloAuthController.session = function() {
  if (true) { return _wA({method: "POST", url: `/bcolo/session`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/bcolo/session`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.postRetail = function() {
  return _wA({method: "POST", url: `/retail/trade`});
};
_nS('controllers.graphql.GraphQlController'); _root.controllers.graphql.GraphQlController.getGraphQLPlaygroundPage = function() {
  return _wA({method: "GET", url: `/graphql-playground`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.cancelAmuaRequestPost = function(/* Boolean */ isAccountScopeRequest0) {
  return _wA({method: "POST", url: `/settings/users/cancel-amua-request` + _qS([(function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest0)])});
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.get = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "GET", url: `/cards/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/cards/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.registerUser = function() {
  if (true) { return _wA({method: "POST", url: `/settings/xfers/users`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/xfers/users`}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.deleteGet = function(/* com.gemini.util.ValidEmail */ email0, /* Boolean */ isAccountScopedRequest1) {
  return _wA({method: "GET", url: `/settings/users/delete-role` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0), (function(k,v){return k+'='+(!!v)})("isAccountScopedRequest", isAccountScopedRequest1)])});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getWithdrawPage = function() {
  if (true) { return _wA({method: "GET", url: `/transfer/withdraw`}); }
  if (true) { return _wA({method: "GET", url: `/transfer/p2p`}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.createCustodyAccount = function(/* String */ name0) {
  return _wA({method: "POST", url: `/custody/createAccount` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("name", name0)])});
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.fallbackToWire = function() {
  return _wA({method: "GET", url: `/settings/bank/link/failed`});
};
_nS('controllers.account.AccountSelectionController'); _root.controllers.account.AccountSelectionController.switchAccount = function(/* String */ account0) {
  if (true) { return _wA({method: "POST", url: `/switch-account` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("account", account0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/switch-account` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("account", account0)])}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getNfts = function(/* Int */ page0, /* Option[Int] */ pageSize1) {
  return _wA({method: "GET", url: `/custody/nft/${encodeURIComponent(page0)}` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pageSize", pageSize1)])});
};
_nS('controllers.external.looker.LookerWebhookControllerV2'); _root.controllers.external.looker.LookerWebhookControllerV2.setReferralQualifiedAt = function() {
  return _wA({method: "POST", url: `/external/looker/referrals/qualified`});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.createIdentityHandler = function() {
  return _wA({method: "POST", url: `/register/institution/create-identity`});
};
_nS('controllers.order.CurrenciesProviderController'); _root.controllers.order.CurrenciesProviderController.internalExchange = function() {
  return _wA({method: "GET", url: `/supported/exchange`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getAwaitingUkOwnershipAttestations = function(/* Option[Long] */ from0) {
  if (true) { return _wA({method: "GET", url: `/history/transfers/awaiting-uk-ownership-attestation` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/transfers/awaiting-uk-ownership-attestation` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.changeRolePost = function() {
  return _wA({method: "POST", url: `/settings/users/change-role`});
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getAllPairsDetails = function(/* exchange.metrics.Candle.TimeFrame */ time_frame0, /* Option[models.money.TransferCurrency.BankCurrency] */ priceCurrency1, /* Boolean */ allPairs2, /* Boolean */ includeRange3) {
  if (true) { return _wA({method: "GET", url: `/alldetails/${encodeURIComponent(time_frame0)}` + _qS([(priceCurrency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency1)), (allPairs2 == null ? null : (function(k,v){return k+'='+(!!v)})("allPairs", allPairs2)), (includeRange3 == null ? null : (function(k,v){return k+'='+(!!v)})("includeRange", includeRange3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/alldetails/${encodeURIComponent(time_frame0)}` + _qS([(priceCurrency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency1)), (allPairs2 == null ? null : (function(k,v){return k+'='+(!!v)})("allPairs", allPairs2)), (includeRange3 == null ? null : (function(k,v){return k+'='+(!!v)})("includeRange", includeRange3))])}); }
};
_nS('controllers.cxcustomersupport.CwbWsController'); _root.controllers.cxcustomersupport.CwbWsController.uploadTextResponse = function() {
  if (true) { return _wA({method: "POST", url: `/support-experience/api/v1/withdraw/review/upload/text`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/support-experience/api/v1/withdraw/review/upload/text`}); }
};
_nS('controllers.account.FeatureConsentController'); _root.controllers.account.FeatureConsentController.getAgreementV2 = function(/* com.gemini.featureconsent.FeatureConsentType */ featureConsentType0) {
  if (true) { return _wA({method: "GET", url: `/consent-form/v2/agreement/${encodeURIComponent(featureConsentType0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/consent-form/v2/agreement/${encodeURIComponent(featureConsentType0)}`}); }
};
_nS('controllers.common.DevNotesController'); _root.controllers.common.DevNotesController.devNotes = function() {
  return _wA({method: "GET", url: `/devNotes`});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.oneTouchSetEnabled = function(/* Boolean */ enabled0) {
  if (enabled0 === true) { return _wA({method: "GET", url: `/onetouch/enable`}); }
  if (enabled0 === false) { return _wA({method: "GET", url: `/onetouch/disable`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.earnData = function() {
  if (true) { return _wA({method: "GET", url: `/mobile/earn`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/stake`}); }
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.changeEmailPost = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/email`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/email`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.cryptoWithdrawAssetFeeMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/mobile/transfer/crypto/withdraw/${encodeURIComponent(asset0)}/fee`});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.approveOneTouchRequest = function(/* String */ uuid0) {
  return _wA({method: "GET", url: `/onetouch/approve/${encodeURIComponent(uuid0)}`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getHourlyFundingTransferEvents = function(/* Long */ startTimeMs0, /* Long */ endTimeMs1) {
  if (true) { return _wA({method: "GET", url: `/history/perpsHourlyFundingTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/perpsHourlyFundingTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.getNewOnfidoToken = function(/* Option[String] */ applicationId0, /* Option[Boolean] */ inCreditCard1, /* Option[Boolean] */ inCardDetails2, /* Option[Boolean] */ existingApplicant3) {
  if (true) { return _wA({method: "GET", url: `/register/onfido-key` + _qS([(applicationId0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("applicationId", applicationId0)), (inCreditCard1 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCreditCard", inCreditCard1)), (inCardDetails2 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCardDetails", inCardDetails2)), (existingApplicant3 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("existingApplicant", existingApplicant3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/onfido-key` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("applicationId", applicationId0), (inCreditCard1 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCreditCard", inCreditCard1)), (inCardDetails2 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCardDetails", inCardDetails2)), (existingApplicant3 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("existingApplicant", existingApplicant3))])}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getTransferHistoryWithPendingBankTransfers = function(/* Option[Long] */ from0) {
  return _wA({method: "GET", url: `/transfer/history` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0))])});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.revokePost = function(/* com.gemini.util.ValidEmail */ email0, /* Boolean */ isAccountScopedRequest1) {
  return _wA({method: "POST", url: `/settings/users/revoke` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0), (function(k,v){return k+'='+(!!v)})("isAccountScopedRequest", isAccountScopedRequest1)])});
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getAllPairsDetailsExternalDaily = function(/* Option[models.money.TransferCurrency.BankCurrency] */ priceCurrency0, /* Option[Int] */ maxDays1) {
  return _wA({method: "GET", url: `/allDetailsExternalDaily` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (maxDays1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("maxDays", maxDays1))])});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.revokeGet = function(/* com.gemini.util.ValidEmail */ email0, /* Boolean */ isAccountScopedRequest1) {
  return _wA({method: "GET", url: `/settings/users/revoke` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0), (function(k,v){return k+'='+(!!v)})("isAccountScopedRequest", isAccountScopedRequest1)])});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.getMobile = function(/* Option[String] */ redirect0, /* Boolean */ sudo1, /* Option[com.gemini.util.ValidEmail] */ email2, /* Boolean */ sms3, /* Option[Boolean] */ webAuthn4, /* Boolean */ dataOnly5) {
  return _wA({method: "GET", url: `/mobile/signin/2fa` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0), (function(k,v){return k+'='+(!!v)})("sudo", sudo1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email2), (function(k,v){return k+'='+(!!v)})("sms", sms3), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("webAuthn", webAuthn4), (dataOnly5 == null ? null : (function(k,v){return k+'='+(!!v)})("dataOnly", dataOnly5))])});
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.processSettings = function() {
  if (true) { return _wA({method: "POST", url: `/settings/derivatives/toggle-settings`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/derivatives/toggle-settings`}); }
};
_nS('controllers.settings.NotificationSettingsController'); _root.controllers.settings.NotificationSettingsController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/notifications`}); }
  if (true) { return _wA({method: "GET", url: `/settings/interface`}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/profile`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/profile`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getTransfers = function(/* Option[Long] */ from0, /* Option[String] */ currency1, /* Boolean */ includeEarn2) {
  if (true) { return _wA({method: "GET", url: `/history/transfers` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1)), (includeEarn2 == null ? null : (function(k,v){return k+'='+(!!v)})("includeEarn", includeEarn2))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/transfers` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1)), (includeEarn2 == null ? null : (function(k,v){return k+'='+(!!v)})("includeEarn", includeEarn2))])}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.setupPasswordlessForUser = function() {
  if (true) { return _wA({method: "PATCH", url: `/settings/security/passwordless`}); }
  if (true) { return _wA({method: "PATCH", url: `/mobile/settings/security/passwordless`}); }
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.initDisable = function(/* String */ apiKey0) {
  return _wA({method: "GET", url: `/settings/api/disable/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.editAccountAddressAttestation = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/attest-address-ownership`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/attest-address-ownership`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.earnStatus = function() {
  if (true) { return _wA({method: "GET", url: `/grow/status`}); }
  if (true) { return _wA({method: "GET", url: `/stake/status`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/earn/status`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow/status`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/stake/status`}); }
};
_nS('controllers.security.SignInController'); _root.controllers.security.SignInController.get = function(/* Option[String] */ redirect0) {
  return _wA({method: "GET", url: `/signin` + _qS([(redirect0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0))])});
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.freezeUser = function(/* String */ token0) {
  if (true) { return _wA({method: "POST", url: `/freeze-user/${encodeURIComponent(token0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/freeze-user/${encodeURIComponent(token0)}`}); }
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.post = function() {
  if (true) { return _wA({method: "POST", url: `/cards`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards`}); }
};
_nS('controllers.order.CurrenciesProviderController'); _root.controllers.order.CurrenciesProviderController.mobileCombined = function() {
  return _wA({method: "GET", url: `/mobile/supported`});
};
_nS('controllers.social.SocialProfileController'); _root.controllers.social.SocialProfileController.createOrUpdateSocialProfile = function() {
  if (true) { return _wA({method: "POST", url: `/settings/social/profile`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/social/profile`}); }
};
_nS('controllers.settings.CounterpartySettingsController'); _root.controllers.settings.CounterpartySettingsController.addCounterparty = function(/* String */ cpidString0, /* String */ nickname1) {
  return _wA({method: "POST", url: `/settings/counterparties/add` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("cpidString", cpidString0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("nickname", nickname1)])});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.main = function() {
  return _wA({method: "GET", url: `/upload`});
};
_nS('controllers.dogfood.TestWebhookConsumer'); _root.controllers.dogfood.TestWebhookConsumer.post = function() {
  return _wA({method: "POST", url: `/dogfood/webhook`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.withdraw = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/transfer/withdraw/${encodeURIComponent(asset0)}`});
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.updateAuthyFallback = function() {
  if (true) { return _wA({method: "PATCH", url: `/settings/security/authy-fallback`}); }
  if (true) { return _wA({method: "PATCH", url: `/mobile/settings/security/authy-fallback`}); }
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.validateMicroDeposit = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/micro-deposit/validate`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/micro-deposit/validate`}); }
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.disableBankAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/xfers/bank/disable`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/xfers/bank/delete`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getTransferDetails = function() {
  return _wA({method: "GET", url: `/web/transfer`});
};
_nS('controllers.settings.GroupApiSettingsController'); _root.controllers.settings.GroupApiSettingsController.initDisableGroup = function(/* String */ apiKey0) {
  return _wA({method: "GET", url: `/settings/group-api/disable/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.settings.CounterpartySettingsController'); _root.controllers.settings.CounterpartySettingsController.updateCounterparty = function(/* String */ cpidString0, /* String */ newNickname1) {
  return _wA({method: "POST", url: `/settings/counterparties/update` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("cpidString", cpidString0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newNickname", newNickname1)])});
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.confirmFreeze = function(/* String */ token0) {
  if (true) { return _wA({method: "GET", url: `/freeze-user/${encodeURIComponent(token0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/freeze-user/${encodeURIComponent(token0)}`}); }
};
_nS('controllers.settings.WithdrawBanReviewController'); _root.controllers.settings.WithdrawBanReviewController.get = function() {
  return _wA({method: "GET", url: `/settings/review`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getMarketMinimums = function() {
  if (true) { return _wA({method: "GET", url: `/trade/market-minimums`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/market-minimums`}); }
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.accountBalances = function() {
  return _wA({method: "GET", url: `/mobile/account/balances`});
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.linkTokenAndroid = function(/* Option[Boolean] */ useRux0) {
  return _wA({method: "GET", url: `/mobile/settings/bank/linkToken/android` + _qS([(function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("useRux", useRux0)])});
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.enterTokenPost = function(/* com.gemini.util.ValidEmail */ email0) {
  if (true) { return _wA({method: "POST", url: `/signin/forgot/confirm` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/forgot/confirm` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
};
_nS('controllers.mobile.CommonController'); _root.controllers.mobile.CommonController.ping = function() {
  return _wA({method: "GET", url: `/mobile/ping`});
};
_nS('controllers.settings.AccountLevelSettingsController'); _root.controllers.settings.AccountLevelSettingsController.getAccountLevelPage = function() {
  return _wA({method: "GET", url: `/settings/account-level`});
};
_nS('controllers.markets.AdvancedMarketsController'); _root.controllers.markets.AdvancedMarketsController.getPairs = function() {
  if (true) { return _wA({method: "GET", url: `/advanced/markets/pairs`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/advanced/markets/pairs`}); }
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.getAppropriatenessTest = function() {
  if (true) { return _wA({method: "GET", url: `/legal-agreements/appropriateness`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/legal-agreements/appropriateness`}); }
};
_nS('controllers.settings.CounterpartySettingsController'); _root.controllers.settings.CounterpartySettingsController.list = function() {
  return _wA({method: "GET", url: `/settings/clearing`});
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.allowPerpetualAccount = function() {
  if (true) { return _wA({method: "GET", url: `/settings/derivatives/allowed-pepertual-account`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/derivatives/allowed-pepertual-account`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getGrow = function() {
  return _wA({method: "GET", url: `/grow`});
};
_nS('controllers.order.CurrenciesProviderController'); _root.controllers.order.CurrenciesProviderController.supportedBlockchainNetworks = function() {
  if (true) { return _wA({method: "GET", url: `/networks`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/networks`}); }
};
_nS('controllers.register.ConfirmPhoneController'); _root.controllers.register.ConfirmPhoneController.confirmPhone = function() {
  if (true) { return _wA({method: "GET", url: `/register/confirm-phone`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/confirm-phone`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/bank`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/bank`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.showDepositSection = function(/* com.gemini.web.models.TransferPageAsset */ asset0, /* Option[Long] */ offset1, /* Option[Int] */ limit2) {
  return _wA({method: "GET", url: `/transfer/deposit/${encodeURIComponent(asset0)}/details` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("offset", offset1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("limit", limit2)])});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.editAccountWhitelistLabel = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/edit-label`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/edit-label`}); }
};
_nS('controllers.account.AccountGroupSelectionController'); _root.controllers.account.AccountGroupSelectionController.listAccountGroups = function() {
  if (true) { return _wA({method: "GET", url: `/account-group/list`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/account-group/list`}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.changeRoleGroupPost = function() {
  return _wA({method: "POST", url: `/settings/users/change-role-group`});
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.deleteBankAccount = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bank/delete`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/delete`}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.createAddressHandler = function() {
  return _wA({method: "POST", url: `/register/institution/create-address`});
};
_nS('controllers.external.looker.LookerWebhookControllerV2'); _root.controllers.external.looker.LookerWebhookControllerV2.sendBrazeFeefoCampaign = function(/* models.BrazeCampaignId */ campaignId0) {
  return _wA({method: "POST", url: `/external/looker/v2/braze/campaign/feefo/${encodeURIComponent(campaignId0)}`});
};
_nS('controllers.Assets'); _root.controllers.Assets.at = function(/* String */ file1) {
  return _wA({method: "GET", url: `/assets/${file1}`});
};
_nS('controllers.retail.UserMarketWatchListController'); _root.controllers.retail.UserMarketWatchListController.getAllWatchesByUserId = function() {
  if (true) { return _wA({method: "GET", url: `/watchlist`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/watchlist`}); }
};
_nS('controllers.mobile.PushNotificationsController'); _root.controllers.mobile.PushNotificationsController.disable = function(/* com.gemini.accounts.models.BrazeNotificationType */ pushType0) {
  return _wA({method: "POST", url: `/mobile/notifications/push/disable/${encodeURIComponent(pushType0)}`});
};
_nS('controllers.account.AccountSelectionController'); _root.controllers.account.AccountSelectionController.listAccounts = function() {
  if (true) { return _wA({method: "GET", url: `/account/list`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/account/list`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.resumeWeb = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "PUT", url: `/web/recurring-orders/resume/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.register.ConfirmPhoneController'); _root.controllers.register.ConfirmPhoneController.submitPhone = function(/* Boolean */ forceSms0) {
  if (true) { return _wA({method: "POST", url: `/register/confirm-phone/submit` + _qS([(forceSms0 == null ? null : (function(k,v){return k+'='+(!!v)})("forceSms", forceSms0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/confirm-phone/submit` + _qS([(forceSms0 == null ? null : (function(k,v){return k+'='+(!!v)})("forceSms", forceSms0))])}); }
};
_nS('controllers.cxcustomersupport.CwbWsController'); _root.controllers.cxcustomersupport.CwbWsController.getQuestions = function(/* String */ countryCode0) {
  if (true) { return _wA({method: "GET", url: `/support-experience/api/v1/withdraw/review/questions/${encodeURIComponent(countryCode0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/support-experience/api/v1/withdraw/review/questions/${encodeURIComponent(countryCode0)}`}); }
};
_nS('controllers.retail.UserMarketWatchListController'); _root.controllers.retail.UserMarketWatchListController.disableWatch = function(/* String */ currencyOrTradingPair0, /* com.gemini.accounts.models.UserMarketWatchType */ watchType1) {
  if (true) { return _wA({method: "PUT", url: `/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/${encodeURIComponent(watchType1)}/disable`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/${encodeURIComponent(watchType1)}/disable`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/disable` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("watchType", watchType1)])}); }
};
_nS('controllers.register.FullVerificationController'); _root.controllers.register.FullVerificationController.get = function(/* Boolean */ edit0) {
  if (true) { return _wA({method: "GET", url: `/register/verify` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/verify` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0))])}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.cancelAmuaRequestGet = function(/* String */ requestId0, /* Boolean */ isAccountScopeRequest1) {
  return _wA({method: "GET", url: `/settings/users/cancel-amua-request` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("requestId", requestId0), (function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest1)])});
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.approveDeviceMobile = function(/* String */ deviceId0, /* Boolean */ requireSignIn1) {
  return _wA({method: "GET", url: `/mobile/approve-device/${encodeURIComponent(deviceId0)}` + _qS([(requireSignIn1 == null ? null : (function(k,v){return k+'='+(!!v)})("requireSignIn", requireSignIn1))])});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.peerToPeerTransfer = function() {
  return _wA({method: "POST", url: `/transfer/p2p`});
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.postPasswordless = function() {
  if (true) { return _wA({method: "POST", url: `/signin/passwordless`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/passwordless`}); }
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.addJourneyType = function() {
  if (true) { return _wA({method: "POST", url: `/legal-agreements/journey`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/legal-agreements/journey`}); }
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.applicationSettings = function() {
  return _wA({method: "GET", url: `/settings/applications`});
};
_nS('controllers.settings.SecuritySettingsController'); _root.controllers.settings.SecuritySettingsController.get = function() {
  return _wA({method: "GET", url: `/settings/security`});
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.allTimeHigh = function() {
  return _wA({method: "GET", url: `/v1/leaderboard/alltimehigh`});
};
_nS('controllers.settings.BcoloSettingsController'); _root.controllers.settings.BcoloSettingsController.deletePaymentMethod = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bcolo/payment-methods/delete`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bcolo/payment-methods/delete`}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getInvoicePaymentStatus = function(/* String */ account_hash0, /* String */ invoiceDate1, /* Long */ invoiceId2) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(account_hash0)}/${encodeURIComponent(invoiceDate1)}/${encodeURIComponent(invoiceId2)}/paymentstatus`});
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getAllPairsDetailLegacy = function(/* exchange.metrics.Candle.TimeFrame */ time_frame0) {
  return _wA({method: "GET", url: `/mobile/alldetail/${encodeURIComponent(time_frame0)}`});
};
_nS('controllers.transfer.crypto.TransferCryptoController'); _root.controllers.transfer.crypto.TransferCryptoController.getSupportedWithdrawalLimits = function() {
  return _wA({method: "GET", url: `/transfer/crypto/controls/supportedWithdrawalLimits`});
};
_nS('controllers.account.FeatureConsentController'); _root.controllers.account.FeatureConsentController.getAgreement = function(/* com.gemini.featureconsent.FeatureConsentType */ featureConsentType0) {
  if (true) { return _wA({method: "GET", url: `/consent-form/agreement/${encodeURIComponent(featureConsentType0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/consent-form/agreement/${encodeURIComponent(featureConsentType0)}`}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.addGroupPost = function() {
  return _wA({method: "POST", url: `/settings/users/addGroup`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.depositMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/deposit/${encodeURIComponent(asset0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/deposit/${encodeURIComponent(asset0)}/ppi-link-token`}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.onfidoHook = function() {
  return _wA({method: "POST", url: `/register/onfido-hook`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.cancelGroupPendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/group/pending/cancel/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/group/pending/cancel/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.uploadClientIcon = function() {
  if (true) { return _wA({method: "POST", url: `/auth/uploadClientIcon`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/uploadClientIcon`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.getMobile = function() {
  return _wA({method: "GET", url: `/mobile/recurring-orders`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.returnUrl = function() {
  if (true) { return _wA({method: "POST", url: `/cards/verification-service/return-url`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/verification-service/return-url`}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.configureCcpaPreference = function() {
  return _wA({method: "POST", url: `/settings/profile/ccpa/configure`});
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.createPage = function() {
  return _wA({method: "GET", url: `/settings/api/create`});
};
_nS('controllers.common.DevNotesController'); _root.controllers.common.DevNotesController.addToQueue = function() {
  return _wA({method: "POST", url: `/devNotes/addToQueue`});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.accountBalancesAllAccounts = function() {
  if (true) { return _wA({method: "GET", url: `/web/total-portfolio`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/total-portfolio`}); }
};
_nS('controllers.common.CspReportController'); _root.controllers.common.CspReportController.post = function() {
  return _wA({method: "POST", url: `/collect-csp`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.card3dsVerifyChallenge = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/card/verify-challenge`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/card/verify-challenge`}); }
};
_nS('controllers.payment.PaymentMethodCrudController'); _root.controllers.payment.PaymentMethodCrudController.list = function() {
  if (true) { return _wA({method: "GET", url: `/payment-methods`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/payment-methods`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.linkToken = function(/* Option[Boolean] */ useRux0) {
  if (true) { return _wA({method: "GET", url: `/settings/bank/linkToken` + _qS([(function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("useRux", useRux0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/bank/linkToken` + _qS([(function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("useRux", useRux0)])}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.cancelTransfer = function(/* String */ transferInfo0, /* String */ transferHashId1) {
  if (true) { return _wA({method: "POST", url: `/history/transfers/cancel` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("transferInfo", transferInfo0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("transferHashId", transferHashId1)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/history/transfers/cancel` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("transferInfo", transferInfo0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("transferHashId", transferHashId1)])}); }
};
_nS('controllers.external.looker.LookerWebhookController'); _root.controllers.external.looker.LookerWebhookController.sendBrazeCampaign = function(/* models.BrazeCampaignId */ campaignId0) {
  return _wA({method: "POST", url: `/external/looker/braze/campaign/${encodeURIComponent(campaignId0)}`});
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.cancelSettlement = function() {
  return _wA({method: "POST", url: `/settlement/cancel`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.denyAmuaRequestGet = function(/* String */ requestId0, /* Boolean */ isAccountScopeRequest1) {
  return _wA({method: "GET", url: `/settings/users/deny-amua-request` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("requestId", requestId0), (function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest1)])});
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.changeBankAccountName = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bank/change-name`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bank/change-name`}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.get = function() {
  return _wA({method: "GET", url: `/custody`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.editClient = function() {
  if (true) { return _wA({method: "POST", url: `/auth/edit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/edit`}); }
};
_nS('controllers.mobile.PushNotificationsController'); _root.controllers.mobile.PushNotificationsController.enable = function(/* com.gemini.accounts.models.BrazeNotificationType */ pushType0) {
  return _wA({method: "POST", url: `/mobile/notifications/push/enable/${encodeURIComponent(pushType0)}`});
};
_nS('controllers.account.CustomerTaxStatementController'); _root.controllers.account.CustomerTaxStatementController.getGainLossStatementForAccount = function(/* com.gemini.payments.types.tax_statements.GainLossStatementId */ statementId0) {
  if (true) { return _wA({method: "GET", url: `/statement/gainLossStatement/${encodeURIComponent(statementId0)}.xlsx`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/statement/gainLossStatement/${encodeURIComponent(statementId0)}.xlsx`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getPositionTransferEvents = function(/* Long */ startTimeMs0, /* Long */ endTimeMs1) {
  if (true) { return _wA({method: "GET", url: `/history/perpsPositionTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/perpsPositionTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.changeEmailGet = function() {
  return _wA({method: "GET", url: `/settings/security/email`});
};
_nS('controllers.dashboard.DashboardController'); _root.controllers.dashboard.DashboardController.main = function() {
  if (true) { return _wA({method: "GET", url: `/`}); }
  if (true) { return _wA({method: "GET", url: `/market`}); }
  if (true) { return _wA({method: "GET", url: `/home`}); }
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.post = function(/* Option[String] */ redirect0, /* Boolean */ sudo1, /* Option[com.gemini.util.ValidEmail] */ email2, /* Boolean */ sms3) {
  if (true) { return _wA({method: "POST", url: `/signin/2fa` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0), (function(k,v){return k+'='+(!!v)})("sudo", sudo1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email2), (function(k,v){return k+'='+(!!v)})("sms", sms3)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/2fa` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0), (function(k,v){return k+'='+(!!v)})("sudo", sudo1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email2), (function(k,v){return k+'='+(!!v)})("sms", sms3)])}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.depositQuote = function(/* com.gemini.earn.EarnProviderId */ providerId0, /* com.gemini.assets.veneer.CurrencyCode */ asset1, /* String */ amount2) {
  if (true) { return _wA({method: "GET", url: `/earn/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
  if (true) { return _wA({method: "GET", url: `/grow/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
  if (true) { return _wA({method: "GET", url: `/stake/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/earn/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/stake/deposit/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("asset", asset1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount2)])}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.allUnconfirmedDeposits = function() {
  if (true) { return _wA({method: "GET", url: `/transfer/deposit/unconfirmed`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/transfer/deposit/unconfirmed`}); }
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.confirmAccountFreeze = function(/* String */ token0) {
  return _wA({method: "GET", url: `/freeze-account/${encodeURIComponent(token0)}`});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.getApplicationPDF = function(/* models.AccountGroupId */ accountGroupId0) {
  return _wA({method: "GET", url: `/register/institution/pdf/${encodeURIComponent(accountGroupId0)}`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getLiquidationFeeEvents = function(/* Long */ startTimeMs0, /* Long */ endTimeMs1) {
  if (true) { return _wA({method: "GET", url: `/history/perpsLiquidationFee` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/perpsLiquidationFee` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
};
_nS('controllers.external.samsung.SamsungAddressController'); _root.controllers.external.samsung.SamsungAddressController.post = function() {
  return _wA({method: "POST", url: `/mobile/samsung/update`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.removeAccountWithdrawalWhitelist = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/remove`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/remove`}); }
};
_nS('controllers.settings.ProfileSettingsController'); _root.controllers.settings.ProfileSettingsController.getCcpaSettings = function() {
  return _wA({method: "GET", url: `/settings/profile/ccpa/getConfiguration`});
};
_nS('controllers.security.AuthyWebhookAccountChangeController'); _root.controllers.security.AuthyWebhookAccountChangeController.notifyPhoneNumberChange = function() {
  return _wA({method: "POST", url: `/authy/user-phone-change`});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.recordLegacyUpload = function(/* Boolean */ inOnboarding0) {
  if (true) { return _wA({method: "POST", url: `/upload/new` + _qS([(inOnboarding0 == null ? null : (function(k,v){return k+'='+(!!v)})("inOnboarding", inOnboarding0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/upload/new` + _qS([(inOnboarding0 == null ? null : (function(k,v){return k+'='+(!!v)})("inOnboarding", inOnboarding0))])}); }
};
_nS('controllers.dashboard.DashboardController'); _root.controllers.dashboard.DashboardController.dismissGeminiEntityConversionModal = function() {
  if (true) { return _wA({method: "GET", url: `/settings/gemini-entity/dismiss-modal`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/gemini-entity/dismiss-modal`}); }
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.update = function(/* String */ apiKey0) {
  return _wA({method: "POST", url: `/settings/api/update/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.deposit = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  if (true) { return _wA({method: "POST", url: `/transfer/deposit/${encodeURIComponent(asset0)}`}); }
  if (true) { return _wA({method: "POST", url: `/transfer/deposit/${encodeURIComponent(asset0)}/ppi-link-token`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.pauseWeb = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "PUT", url: `/web/recurring-orders/pause/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.settings.XfersSettingsController'); _root.controllers.settings.XfersSettingsController.post = function() {
  if (true) { return _wA({method: "POST", url: `/settings/xfers/bank`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/xfers/bank`}); }
};
_nS('controllers.mobile.PushNotificationsController'); _root.controllers.mobile.PushNotificationsController.testPushMessage = function() {
  return _wA({method: "POST", url: `/mobile/notifications/push/test`});
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getValidatorById = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0, /* String */ validatorId1) {
  if (true) { return _wA({method: "GET", url: `/grow/api/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
  if (true) { return _wA({method: "GET", url: `/stake/api/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/stake/${encodeURIComponent(asset0)}/validators/${encodeURIComponent(validatorId1)}`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.orderDetail = function(/* String */ hashid0) {
  if (true) { return _wA({method: "GET", url: `/trade/order/${encodeURIComponent(hashid0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/order/${encodeURIComponent(hashid0)}`}); }
};
_nS('controllers.account.CustomerTaxStatementController'); _root.controllers.account.CustomerTaxStatementController.getTaxFormsForAccount = function(/* String */ account_hash0) {
  if (true) { return _wA({method: "GET", url: `/statement/${encodeURIComponent(account_hash0)}/taxStatements`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/statement/${encodeURIComponent(account_hash0)}/taxStatements`}); }
};
_nS('controllers.plaid.PlaidPaymentInitController'); _root.controllers.plaid.PlaidPaymentInitController.handlePlaidPaymentInitiationCallback = function() {
  if (true) { return _wA({method: "POST", url: `/transfer/deposit/ppi-link-token-callback`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/deposit/ppi-link-token-callback`}); }
};
_nS('controllers.external.sen.SenWebhookController'); _root.controllers.external.sen.SenWebhookController.balanceHasChangedPost = function() {
  return _wA({method: "POST", url: `/external/sen/balance/update`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getPerpsRelatedTransfers = function(/* Long */ startTimeMs0, /* Long */ endTimeMs1) {
  if (true) { return _wA({method: "GET", url: `/history/perpsRelatedTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/perpsRelatedTransfers` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.institutionalRoleOnlyView = function() {
  return _wA({method: "GET", url: `/register/institution/role`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getVasps = function() {
  if (true) { return _wA({method: "GET", url: `/transfer/vasps/withdrawal`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/transfer/vasps/withdrawal`}); }
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.get = function(/* Option[String] */ redirect0, /* Boolean */ sudo1, /* Option[com.gemini.util.ValidEmail] */ email2, /* Boolean */ sms3, /* Option[Boolean] */ webAuthn4, /* Boolean */ dataOnly5) {
  return _wA({method: "GET", url: `/signin/2fa` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect", redirect0), (function(k,v){return k+'='+(!!v)})("sudo", sudo1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email2), (function(k,v){return k+'='+(!!v)})("sms", sms3), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("webAuthn", webAuthn4), (dataOnly5 == null ? null : (function(k,v){return k+'='+(!!v)})("dataOnly", dataOnly5))])});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.success = function() {
  return _wA({method: "GET", url: `/register/institution/thanks`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.getApprovedAddressStatus = function() {
  if (true) { return _wA({method: "GET", url: `/settings/whitelists/status`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/whitelists/status`}); }
};
_nS('controllers.earn.EarnRedeemController'); _root.controllers.earn.EarnRedeemController.post = function() {
  if (true) { return _wA({method: "POST", url: `/earn/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/grow/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/stake/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/earn/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/grow/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/stake/redeem`}); }
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.addIntermediariesPost = function(/* String */ uuid0) {
  return _wA({method: "POST", url: `/settings/bank/add-intermediary/${encodeURIComponent(uuid0)}`});
};
_nS('controllers.order.RiskController'); _root.controllers.order.RiskController.getInstrumentRiskStats = function(/* String */ symbol0) {
  if (true) { return _wA({method: "GET", url: `/instrument-risk` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("symbol", symbol0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/instrument-risk` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("symbol", symbol0)])}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.disable = function(/* models.RecurringOrderId */ recurringOrderId0) {
  return _wA({method: "DELETE", url: `/web/recurring-orders/${encodeURIComponent(recurringOrderId0)}`});
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.createGroupWhitelistWithdrawal = function() {
  if (true) { return _wA({method: "POST", url: `/settings/whitelist/group/create`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelist/group/create`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getTradesV3 = function(/* Option[Long] */ from0, /* Option[com.gemini.assets.veneer.CurrencyCode] */ currency1) {
  return _wA({method: "GET", url: `/v3/history/trades` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1))])});
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.wwwAllOptions = function(/* exchange.metrics.Candle.TimeFrame */ time_frame0) {
  return _wA({method: "OPTIONS", url: `/alldetails/${encodeURIComponent(time_frame0)}`});
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.attestDepositSource = function(/* String */ idHash0) {
  if (true) { return _wA({method: "POST", url: `/history/transfers/attest-deposit` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("idHash", idHash0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/history/transfers/attest-deposit` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("idHash", idHash0)])}); }
};
_nS('controllers.clearing.GCOrderController'); _root.controllers.clearing.GCOrderController.get = function() {
  return _wA({method: "GET", url: `/web/clearing`});
};
_nS('controllers.cards.VerificationsController'); _root.controllers.cards.VerificationsController.verifyChallenge = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/verification-service/verify-challenge`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/verification-service/verify-challenge`}); }
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getOpenOrders = function() {
  if (true) { return _wA({method: "GET", url: `/trade/open-orders`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/trade/open-orders`}); }
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.sendResetEmail = function(/* com.gemini.util.ValidEmail */ email0) {
  if (true) { return _wA({method: "GET", url: `/signin/forgot/send` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/signin/forgot/send` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
};
_nS('controllers.clearing.GCBrokerOrderController'); _root.controllers.clearing.GCBrokerOrderController.post = function() {
  return _wA({method: "POST", url: `/web/clearing/broker/new`});
};
_nS('controllers.clearing.GCConfirmationController'); _root.controllers.clearing.GCConfirmationController.findForConfirm = function(/* exchange.api.clearing.ClearingId */ clearingId0) {
  return _wA({method: "GET", url: `/web/clearing/confirm/${encodeURIComponent(clearingId0)}`});
};
_nS('controllers.account.CustomerAccountStatementController'); _root.controllers.account.CustomerAccountStatementController.getStatement = function(/* String */ account_hash0, /* Option[org.joda.time.LocalDate] */ date1, /* Option[org.joda.time.LocalDate] */ startDate2, /* Option[org.joda.time.LocalDate] */ endDate3, /* Option[models.money.TransferCurrency.BankCurrency] */ notionalCurrency4) {
  if (true) { return _wA({method: "GET", url: `/statement/${encodeURIComponent(account_hash0)}/accountStatement` + _qS([(date1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date1)), (startDate2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("startDate", startDate2)), (endDate3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("endDate", endDate3)), (notionalCurrency4 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("notionalCurrency", notionalCurrency4))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/statement/${encodeURIComponent(account_hash0)}/accountStatement` + _qS([(date1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date1)), (startDate2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("startDate", startDate2)), (endDate3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("endDate", endDate3)), (notionalCurrency4 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("notionalCurrency", notionalCurrency4))])}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.list = function() {
  return _wA({method: "GET", url: `/settings/users`});
};
_nS('controllers.settings.SettlementSettingsController'); _root.controllers.settings.SettlementSettingsController.post = function() {
  return _wA({method: "POST", url: `/settlement/settings/save`});
};
_nS('controllers.settings.CounterpartySettingsController'); _root.controllers.settings.CounterpartySettingsController.deleteCounterparty = function(/* String */ cpidString0, /* String */ nickname1) {
  return _wA({method: "POST", url: `/settings/counterparties/delete` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("cpidString", cpidString0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("nickname", nickname1)])});
};
_nS('controllers.security.device.DeviceController'); _root.controllers.security.device.DeviceController.approveDevice = function(/* String */ deviceId0, /* Boolean */ requireSignIn1) {
  return _wA({method: "GET", url: `/approve-device/${encodeURIComponent(deviceId0)}` + _qS([(requireSignIn1 == null ? null : (function(k,v){return k+'='+(!!v)})("requireSignIn", requireSignIn1))])});
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.postMicroDeposit = function(/* java.util.UUID */ uuid0) {
  if (true) { return _wA({method: "POST", url: `/cards/${encodeURIComponent(uuid0)}/micro-deposit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/${encodeURIComponent(uuid0)}/micro-deposit`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.dynamicWithdrawDetailsMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "POST", url: `/mobile/transfer/withdraw/${encodeURIComponent(asset0)}/details`});
};
_nS('controllers.settings.ApiSettingsController'); _root.controllers.settings.ApiSettingsController.create = function() {
  return _wA({method: "POST", url: `/settings/api/create`});
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getStake = function() {
  return _wA({method: "GET", url: `/stake`});
};
_nS('controllers.cxcustomersupport.ZendeskWebhookWSController'); _root.controllers.cxcustomersupport.ZendeskWebhookWSController.updateUserTicketStatus = function() {
  if (true) { return _wA({method: "POST", url: `/support-experience/api/v1/zendesk/updateStatus`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/support-experience/api/v1/zendesk/updateStatus`}); }
};
_nS('controllers.social.FeatureElectionController'); _root.controllers.social.FeatureElectionController.optOutAll = function() {
  if (true) { return _wA({method: "POST", url: `/settings/feature/optOutAll`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/feature/optOutAll`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.withdrawalAttestationRequirements = function() {
  if (true) { return _wA({method: "POST", url: `/transfer/withdrawal-address-attestation-requirements`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/transfer/withdrawal-address-attestation-requirements`}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.getDocumentRequirements = function(/* models.CountryCode */ countryCode0) {
  if (true) { return _wA({method: "GET", url: `/register/document/requirement/${encodeURIComponent(countryCode0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/document/requirement/${encodeURIComponent(countryCode0)}`}); }
};
_nS('controllers.settings.WebAuthnAuthenticationController'); _root.controllers.settings.WebAuthnAuthenticationController.post = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/credential/authn`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/credential/authn`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.main = function() {
  return _wA({method: "GET", url: `/history`});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.webAccountBalances = function() {
  return _wA({method: "GET", url: `/web/account/balances`});
};
_nS('com.gemini.earn.controllers.webserver.EarnFinalDistributionController'); _root.com.gemini.earn.controllers.webserver.EarnFinalDistributionController.getEarnFinalDistributionSummary = function() {
  if (true) { return _wA({method: "GET", url: `/settings/earn-final-distribution-summary`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/earn-final-distribution-summary`}); }
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.getPasswordless = function() {
  if (true) { return _wA({method: "GET", url: `/signin/passwordless`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/signin/passwordless`}); }
};
_nS('controllers.transfer.LegacyTransferController'); _root.controllers.transfer.LegacyTransferController.get = function() {
  if (true) { return _wA({method: "GET", url: `/deposit/usd/ach`}); }
  if (true) { return _wA({method: "GET", url: `/deposit/usd/wire`}); }
  if (true) { return _wA({method: "GET", url: `/withdraw/usd/ach`}); }
  if (true) { return _wA({method: "GET", url: `/withdraw/usd/wire`}); }
};
_nS('controllers.cards.CardsController'); _root.controllers.cards.CardsController.registerWallet = function() {
  if (true) { return _wA({method: "POST", url: `/cards/register-wallet`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cards/register-wallet`}); }
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.get = function() {
  return _wA({method: "GET", url: `/settlement`});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.getChallengeResult = function() {
  if (true) { return _wA({method: "POST", url: `/upload/challenge/result`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/upload/challenge/result`}); }
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.enterEmailGet = function(/* Option[String] */ email0) {
  if (true) { return _wA({method: "GET", url: `/signin/forgot` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/signin/forgot` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.resendConfirmEmail = function(/* com.gemini.util.ValidEmail */ newEmail0) {
  if (true) { return _wA({method: "POST", url: `/settings/security/resend-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/resend-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.approveAmuaRequestGet = function(/* String */ requestId0, /* Boolean */ isAccountScopeRequest1) {
  return _wA({method: "GET", url: `/settings/users/approve-amua-request` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("requestId", requestId0), (function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest1)])});
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.legalCheckbox = function() {
  return _wA({method: "POST", url: `/custody/legal/checkbox`});
};
_nS('controllers.external.sift.SiftWebhookController'); _root.controllers.external.sift.SiftWebhookController.hook = function() {
  return _wA({method: "POST", url: `/external/sift/decision`});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.accountBalancesV2 = function() {
  if (true) { return _wA({method: "GET", url: `/web/portfolio`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/portfolio`}); }
};
_nS('controllers.cxcustomersupport.CwbWsController'); _root.controllers.cxcustomersupport.CwbWsController.uploadDocumentResponse = function() {
  if (true) { return _wA({method: "POST", url: `/support-experience/api/v1/withdraw/review/upload/document`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/support-experience/api/v1/withdraw/review/upload/document`}); }
};
_nS('controllers.register.DynamicInfoCaptureController'); _root.controllers.register.DynamicInfoCaptureController.getUserData = function() {
  if (true) { return _wA({method: "GET", url: `/register/user-info`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/user-info`}); }
};
_nS('controllers.retail.RecurringOrdersController'); _root.controllers.retail.RecurringOrdersController.updateDestinationToEarn = function(/* models.RecurringOrderId */ recurringOrderId0, /* com.gemini.earn.EarnProviderId */ earnProviderId1) {
  if (true) { return _wA({method: "PUT", url: `/web/recurring-orders/updateDestination/earn/${encodeURIComponent(recurringOrderId0)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("earnProviderId", earnProviderId1)])}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/recurring-orders/updateDestination/earn/${encodeURIComponent(recurringOrderId0)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("earnProviderId", earnProviderId1)])}); }
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.denyOneTouchRequest = function(/* String */ uuid0) {
  return _wA({method: "GET", url: `/onetouch/deny/${encodeURIComponent(uuid0)}`});
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.getMobile = function() {
  return _wA({method: "GET", url: `/mobile/settings/security/email`});
};
_nS('controllers.mobile.PushNotificationsController'); _root.controllers.mobile.PushNotificationsController.getAllAlerts = function() {
  return _wA({method: "GET", url: `/mobile/notifications/push`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.createdClientSummary = function() {
  if (true) { return _wA({method: "GET", url: `/auth/createdOverview`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/auth/createdOverview`}); }
};
_nS('controllers.settlement.SettlementController'); _root.controllers.settlement.SettlementController.showCreateSettlementPage = function() {
  return _wA({method: "GET", url: `/settlement/create`});
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.recordFilestackUpload = function() {
  if (true) { return _wA({method: "POST", url: `/upload/filestack`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/upload/filestack`}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.fetchFilepickerConfigInstitutional = function() {
  return _wA({method: "GET", url: `/upload/config/institutional`});
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.allowedUiLeverages = function() {
  if (true) { return _wA({method: "GET", url: `/settings/derivatives/allowed-leverages`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/derivatives/allowed-leverages`}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.recordOnfidoUpload = function(/* Boolean */ inOnboarding0, /* Option[Boolean] */ inCreditCard1, /* Option[Boolean] */ inCardDetails2) {
  if (true) { return _wA({method: "POST", url: `/upload/new/record-upload` + _qS([(inOnboarding0 == null ? null : (function(k,v){return k+'='+(!!v)})("inOnboarding", inOnboarding0)), (inCreditCard1 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCreditCard", inCreditCard1)), (inCardDetails2 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCardDetails", inCardDetails2))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/upload/new/record-upload` + _qS([(inOnboarding0 == null ? null : (function(k,v){return k+'='+(!!v)})("inOnboarding", inOnboarding0)), (inCreditCard1 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCreditCard", inCreditCard1)), (inCardDetails2 == null ? null : (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("inCardDetails", inCardDetails2))])}); }
};
_nS('controllers.retail.UserMarketWatchListController'); _root.controllers.retail.UserMarketWatchListController.enableWatch = function(/* String */ currencyOrTradingPair0, /* com.gemini.accounts.models.UserMarketWatchType */ watchType1) {
  if (true) { return _wA({method: "PUT", url: `/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/${encodeURIComponent(watchType1)}/enable`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/${encodeURIComponent(watchType1)}/enable`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/watchlist/${encodeURIComponent(currencyOrTradingPair0)}/enable` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("watchType", watchType1)])}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getAssetBetweenPage = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0) {
  return _wA({method: "GET", url: `/transfer/between/${encodeURIComponent(asset0)}`});
};
_nS('controllers.settings.WebAuthnRegistrationController'); _root.controllers.settings.WebAuthnRegistrationController.finishRegistration = function() {
  if (true) { return _wA({method: "POST", url: `/settings/security/credential/finish`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/credential/finish`}); }
};
_nS('controllers.support.ZendeskFeedbackController'); _root.controllers.support.ZendeskFeedbackController.submitFeedback = function() {
  if (true) { return _wA({method: "POST", url: `/feedback`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/feedback`}); }
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.convertEligibleCryptoPairs = function() {
  return _wA({method: "GET", url: `/convertEligibleCryptoPairs`});
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.getFinPromRequirements = function() {
  if (true) { return _wA({method: "GET", url: `/legal-agreements/finprom-requirements`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/legal-agreements/finprom-requirements`}); }
};
_nS('controllers.external.looker.LookerWebhookController'); _root.controllers.external.looker.LookerWebhookController.sendBrazeFeefoCampaign = function(/* models.BrazeCampaignId */ campaignId0) {
  return _wA({method: "POST", url: `/external/looker/braze/campaign/feefo/${encodeURIComponent(campaignId0)}`});
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.createClient = function() {
  if (true) { return _wA({method: "POST", url: `/auth/create`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/auth/create`}); }
};
_nS('controllers.account.Application'); _root.controllers.account.Application.userAgreement = function() {
  return _wA({method: "GET", url: `/user-agreement`});
};
_nS('controllers.mobile.AssetDetailController'); _root.controllers.mobile.AssetDetailController.getSinglePairDetails = function(/* String */ pair0, /* exchange.metrics.Candle.TimeFrame */ time_frame1) {
  return _wA({method: "GET", url: `/web/details/${encodeURIComponent(pair0)}/${encodeURIComponent(time_frame1)}`});
};
_nS('controllers.account.CustomerAccountStatementController'); _root.controllers.account.CustomerAccountStatementController.downloadStatementPdf = function(/* String */ account_hash0, /* Option[org.joda.time.LocalDate] */ date1, /* Option[org.joda.time.LocalDate] */ startDate2, /* Option[org.joda.time.LocalDate] */ endDate3) {
  if (true) { return _wA({method: "GET", url: `/statement/${encodeURIComponent(account_hash0)}/accountStatement.pdf` + _qS([(date1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date1)), (startDate2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("startDate", startDate2)), (endDate3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("endDate", endDate3))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/statement/${encodeURIComponent(account_hash0)}/accountStatement.pdf` + _qS([(date1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date1)), (startDate2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("startDate", startDate2)), (endDate3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("endDate", endDate3))])}); }
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.getWizard = function() {
  return _wA({method: "GET", url: `/register/institution/wizard`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.denyAmuaRequestPost = function(/* Boolean */ isAccountScopeRequest0) {
  return _wA({method: "POST", url: `/settings/users/deny-amua-request` + _qS([(function(k,v){return k+'='+(!!v)})("isAccountScopeRequest", isAccountScopeRequest0)])});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getDualMarket = function(/* String */ symbol0, /* String */ secondarySymbol1) {
  return _wA({method: "GET", url: `/trade/${encodeURIComponent(symbol0)}/${encodeURIComponent(secondarySymbol1)}`});
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getDailyLeaderboardList = function(/* Option[com.gemini.leaderboard.models.LeaderboardTypeSymbol] */ baseType0) {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/daily/list` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/daily/list` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType0)])}); }
};
_nS('controllers.settings.GroupApiSettingsController'); _root.controllers.settings.GroupApiSettingsController.updateGroup = function(/* String */ apiKey0) {
  return _wA({method: "POST", url: `/settings/group-api/update/${encodeURIComponent(apiKey0)}`});
};
_nS('controllers.external.jpm.JpmCallbackController'); _root.controllers.external.jpm.JpmCallbackController.callbacks = function() {
  return _wA({method: "POST", url: `/external/jpm/callback`});
};
_nS('controllers.settings.BillingSettingsController'); _root.controllers.settings.BillingSettingsController.get = function() {
  return _wA({method: "GET", url: `/settings/billing`});
};
_nS('controllers.settings.LiveSettingsController'); _root.controllers.settings.LiveSettingsController.ping = function() {
  return _wA({method: "GET", url: `/settings/check-timeout/ping`});
};
_nS('controllers.external.plaid.PlaidWebhookController'); _root.controllers.external.plaid.PlaidWebhookController.updatePaymentStatus = function() {
  return _wA({method: "POST", url: `/external/plaid/ppi/payments/webhooks`});
};
_nS('controllers.account.Application'); _root.controllers.account.Application.jsAt = function(/* String */ file0) {
  return _wA({method: "GET", url: `/js/${file0}`});
};
_nS('controllers.settings.NewAccountSettingsController'); _root.controllers.settings.NewAccountSettingsController.createAdditionalTradingSubaccount = function(/* String */ name0) {
  return _wA({method: "POST", url: `/settings/create-trading` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("name", name0)])});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.changeRoleGroupGet = function() {
  return _wA({method: "GET", url: `/settings/users/change-role-group`});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.cryptoWithdrawAssetControlsMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "GET", url: `/mobile/transfer/crypto/withdraw/${encodeURIComponent(asset0)}/controls`});
};
_nS('controllers.retail.InstantOrderController'); _root.controllers.retail.InstantOrderController.quote = function(/* com.gemini.assets.veneer.CurrencyCode */ to0, /* com.gemini.assets.veneer.CurrencyCode */ from1, /* models.money.GenericDecimal */ totalSpend2, /* Option[java.util.UUID] */ paymentMethodUuid3, /* Option[com.gemini.instant.commons.PaymentMethodType] */ paymentMethodType4, /* Option[java.util.UUID] */ internalReference5, /* Option[Boolean] */ isRecurringOrder6, /* Option[models.CardAccountType] */ cardAccountType7, /* Option[com.gemini.earn.EarnProviderId] */ earnProviderId8, /* Option[String] */ side9, /* Boolean */ isDirectlyFundingDerivativeAccount10) {
  if (true) { return _wA({method: "GET", url: `/web/quote/${encodeURIComponent(to0)}/${encodeURIComponent(from1)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("totalSpend", totalSpend2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodUuid", paymentMethodUuid3), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodType", paymentMethodType4), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("internalReference", internalReference5), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("isRecurringOrder", isRecurringOrder6), (cardAccountType7 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("cardAccountType", cardAccountType7)), (earnProviderId8 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("earnProviderId", earnProviderId8)), (side9 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("side", side9)), (isDirectlyFundingDerivativeAccount10 == null ? null : (function(k,v){return k+'='+(!!v)})("isDirectlyFundingDerivativeAccount", isDirectlyFundingDerivativeAccount10))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/quote/${encodeURIComponent(to0)}/${encodeURIComponent(from1)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("totalSpend", totalSpend2), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodUuid", paymentMethodUuid3), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("paymentMethodType", paymentMethodType4), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("internalReference", internalReference5), (function(k,v){return v!=null?(function(k,v){return k+'='+(!!v)})(k,v):''})("isRecurringOrder", isRecurringOrder6), (cardAccountType7 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("cardAccountType", cardAccountType7)), (earnProviderId8 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("earnProviderId", earnProviderId8)), (side9 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("side", side9)), (isDirectlyFundingDerivativeAccount10 == null ? null : (function(k,v){return k+'='+(!!v)})("isDirectlyFundingDerivativeAccount", isDirectlyFundingDerivativeAccount10))])}); }
};
_nS('controllers.register.DocUploadController'); _root.controllers.register.DocUploadController.submitForBasicTierPlusReview = function() {
  if (true) { return _wA({method: "POST", url: `/register/basic-tier-plus-review`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/basic-tier-plus-review`}); }
};
_nS('controllers.account.ConsentInterstitialController'); _root.controllers.account.ConsentInterstitialController.post = function() {
  if (true) { return _wA({method: "POST", url: `/consent`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/consent`}); }
};
_nS('com.gemini.util.play_build_info.BuildInfoController'); _root.com.gemini.util.play_build_info.BuildInfoController.buildInfo = function() {
  return _wA({method: "GET", url: `/admin/internal/buildinfo`});
};
_nS('controllers.risk.KycRefreshController'); _root.controllers.risk.KycRefreshController.get = function() {
  return _wA({method: "GET", url: `/kyc-refresh`});
};
_nS('controllers.register.InstitutionRegisterControllerV1'); _root.controllers.register.InstitutionRegisterControllerV1.validateAddress = function() {
  return _wA({method: "POST", url: `/register/institution/validate-address`});
};
_nS('controllers.support.ZendeskFeedbackController'); _root.controllers.support.ZendeskFeedbackController.supportTopics = function() {
  return _wA({method: "GET", url: `/mobile/feedback/topics`});
};
_nS('controllers.notifications.NotificationsController'); _root.controllers.notifications.NotificationsController.getNotifications = function(/* Int */ limit0, /* Option[String] */ continuationToken1) {
  if (true) { return _wA({method: "GET", url: `/web/notifications/list` + _qS([(limit0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit0)), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("continuationToken", continuationToken1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/notifications/list` + _qS([(limit0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit0)), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("continuationToken", continuationToken1)])}); }
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.retrieveAccountSettings = function() {
  if (true) { return _wA({method: "GET", url: `/settings/derivatives/retrieve-settings`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/derivatives/retrieve-settings`}); }
};
_nS('controllers.transfer.DepositWireController'); _root.controllers.transfer.DepositWireController.sendWireDepositInstructionsEmail = function(/* models.money.TransferCurrency.WireCurrency */ fiat0) {
  if (true) { return _wA({method: "POST", url: `/deposit/${encodeURIComponent(fiat0)}/wire/instructions`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/deposit/${encodeURIComponent(fiat0)}/wire/instructions`}); }
};
_nS('controllers.settings.PerpetualSwapsController'); _root.controllers.settings.PerpetualSwapsController.accountMaxLeverage = function() {
  if (true) { return _wA({method: "GET", url: `/settings/derivatives/maximum-leverage`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/derivatives/maximum-leverage`}); }
};
_nS('controllers.oauth.OAuthController'); _root.controllers.oauth.OAuthController.get = function(/* String */ response_type0, /* String */ client_id1, /* Option[String] */ redirect_uri2, /* String */ scope3, /* String */ state4, /* Option[String] */ code_challenge5, /* Option[String] */ code_challenge_method6) {
  return _wA({method: "GET", url: `/auth` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("response_type", response_type0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("client_id", client_id1), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("redirect_uri", redirect_uri2), (scope3 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("scope", scope3)), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("state", state4), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("code_challenge", code_challenge5), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("code_challenge_method", code_challenge_method6)])});
};
_nS('controllers.account.AccountController'); _root.controllers.account.AccountController.updatePortfolioDisplay = function() {
  if (true) { return _wA({method: "POST", url: `/settings/portfolio/display`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/portfolio/display`}); }
};
_nS('controllers.social.SocialProfileController'); _root.controllers.social.SocialProfileController.uploadAvatarImage = function() {
  if (true) { return _wA({method: "POST", url: `/settings/social/image/upload`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/social/image/upload`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getGrowAsset = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0) {
  if (true) { return _wA({method: "GET", url: `/grow/${encodeURIComponent(asset0)}`}); }
  if (true) { return _wA({method: "GET", url: `/stake/${encodeURIComponent(asset0)}`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getAssetWithdrawPage = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0) {
  if (true) { return _wA({method: "GET", url: `/transfer/withdraw/${encodeURIComponent(asset0)}`}); }
  if (true) { return _wA({method: "GET", url: `/transfer/p2p/${encodeURIComponent(asset0)}`}); }
};
_nS('controllers.user.UserController'); _root.controllers.user.UserController.setLang = function() {
  if (true) { return _wA({method: "POST", url: `/user/lang`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/user/lang`}); }
};
_nS('controllers.security.AuthyController'); _root.controllers.security.AuthyController.resendToken = function(/* Option[com.gemini.util.ValidEmail] */ email0) {
  if (true) { return _wA({method: "POST", url: `/signin/2fa/resend` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/signin/2fa/resend` + _qS([(email0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("email", email0))])}); }
};
_nS('controllers.security.ForgotPasswordController'); _root.controllers.security.ForgotPasswordController.enterTokenGet = function(/* com.gemini.util.ValidEmail */ email0) {
  if (true) { return _wA({method: "GET", url: `/signin/forgot/confirm` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/signin/forgot/confirm` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("email", email0)])}); }
};
_nS('controllers.settings.BcoloSettingsController'); _root.controllers.settings.BcoloSettingsController.setDisplayName = function() {
  if (true) { return _wA({method: "POST", url: `/settings/bcolo/payment-methods/setDisplayName`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/bcolo/payment-methods/setDisplayName`}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getTrades = function(/* Option[Long] */ from0, /* Option[com.gemini.assets.veneer.CurrencyCode] */ currency1, /* Option[com.gemini.assets.veneer.TradingPairSymbol] */ tradingPair2) {
  if (true) { return _wA({method: "GET", url: `/history/trades` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1)), (tradingPair2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair2))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/trades` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1)), (tradingPair2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair2))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/history/trades` + _qS([(from0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("from", from0)), (currency1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("currency", currency1)), (tradingPair2 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("tradingPair", tradingPair2))])}); }
};
_nS('controllers.settings.WhitelistSettingsController'); _root.controllers.settings.WhitelistSettingsController.cancelAccountPendingRequest = function(/* gemini.mua.models.db.MuaRequestId */ id0) {
  if (true) { return _wA({method: "POST", url: `/settings/whitelists/account/pending/cancel/${encodeURIComponent(id0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/whitelists/account/pending/cancel/${encodeURIComponent(id0)}`}); }
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.emailTokenHandlerGet = function(/* com.gemini.util.ValidEmail */ newEmail0) {
  if (true) { return _wA({method: "GET", url: `/settings/security/confirm-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/security/confirm-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
};
_nS('controllers.order.HistoryController'); _root.controllers.order.HistoryController.getRealizedPnLEvents = function(/* Long */ startTimeMs0, /* Long */ endTimeMs1) {
  if (true) { return _wA({method: "GET", url: `/history/perpsRealizedPnL` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/history/perpsRealizedPnL` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("startTimeMs", startTimeMs0), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("endTimeMs", endTimeMs1)])}); }
};
_nS('controllers.external.looker.LookerWebhookControllerV2'); _root.controllers.external.looker.LookerWebhookControllerV2.sendBrazeCanvas = function(/* models.BrazeCanvasId */ canvasId0) {
  return _wA({method: "POST", url: `/external/looker/v2/braze/canvas/${encodeURIComponent(canvasId0)}`});
};
_nS('controllers.external.looker.LookerWebhookControllerV2'); _root.controllers.external.looker.LookerWebhookControllerV2.sendBrazeCampaign = function(/* models.BrazeCampaignId */ campaignId0) {
  return _wA({method: "POST", url: `/external/looker/v2/braze/campaign/${encodeURIComponent(campaignId0)}`});
};
_nS('controllers.settings.UserManagementController'); _root.controllers.settings.UserManagementController.changeRoleGet = function() {
  return _wA({method: "GET", url: `/settings/users/change-role`});
};
_nS('controllers.legalagreements.LegalAgreementsController'); _root.controllers.legalagreements.LegalAgreementsController.getStatement = function(/* com.gemini.consumerexperience.legalagreements.models.UkClientCategory */ category0) {
  if (true) { return _wA({method: "GET", url: `/legal-agreements/statement` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("category", category0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/legal-agreements/statement` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("category", category0)])}); }
};
_nS('controllers.security.LockoutController'); _root.controllers.security.LockoutController.getLockout = function() {
  return _wA({method: "GET", url: `/lockout`});
};
_nS('controllers.settings.BankSettingsController'); _root.controllers.settings.BankSettingsController.addAchGet = function(/* String */ uuid0) {
  if (true) { return _wA({method: "GET", url: `/settings/bank/add-ach/${encodeURIComponent(uuid0)}`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/bank/add-ach/${encodeURIComponent(uuid0)}`}); }
};
_nS('controllers.earn.EarnController'); _root.controllers.earn.EarnController.getValidators = function(/* com.gemini.assets.veneer.CurrencyCode */ asset0) {
  if (true) { return _wA({method: "GET", url: `/grow/api/${encodeURIComponent(asset0)}/validators`}); }
  if (true) { return _wA({method: "GET", url: `/stake/api/${encodeURIComponent(asset0)}/validators`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/grow/${encodeURIComponent(asset0)}/validators`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/stake/${encodeURIComponent(asset0)}/validators`}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.withdrawDetailsMobile = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "GET", url: `/mobile/transfer/withdraw/${encodeURIComponent(asset0)}/details`});
};
_nS('controllers.account.Application'); _root.controllers.account.Application.signOut = function() {
  if (true) { return _wA({method: "GET", url: `/signout`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/signout`}); }
};
_nS('controllers.register.OnboardingDoneController'); _root.controllers.register.OnboardingDoneController.linkBankAccountStub = function() {
  return _wA({method: "GET", url: `/mobile/register/link-bank`});
};
_nS('controllers.retail.BalanceControllerV2'); _root.controllers.retail.BalanceControllerV2.redirectToAccountDashboard = function(/* String */ subaccountHashid0) {
  return _wA({method: "GET", url: `/balances/redirect/dashboard` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("subaccountHashid", subaccountHashid0)])});
};
_nS('controllers.account.FeatureConsentController'); _root.controllers.account.FeatureConsentController.getAgreementV3 = function(/* com.gemini.featureconsent.FeatureConsentType */ featureConsentType0, /* com.gemini.earn.EarnProviderId */ providerId1) {
  if (true) { return _wA({method: "GET", url: `/consent-form/v3/agreement/${encodeURIComponent(featureConsentType0)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/consent-form/v3/agreement/${encodeURIComponent(featureConsentType0)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("providerId", providerId1)])}); }
};
_nS('controllers.custody.CustodyDashboardController'); _root.controllers.custody.CustodyDashboardController.getInvoiceTotal = function(/* String */ account_hash0, /* String */ invoiceDate1) {
  return _wA({method: "GET", url: `/custody/invoices/${encodeURIComponent(account_hash0)}/${encodeURIComponent(invoiceDate1)}/total`});
};
_nS('controllers.register.OnboardingDoneController'); _root.controllers.register.OnboardingDoneController.uploadedDocs = function() {
  return _wA({method: "GET", url: `/upload/done`});
};
_nS('controllers.oauth.handlers.UserInfoHandler'); _root.controllers.oauth.handlers.UserInfoHandler.get = function() {
  return _wA({method: "GET", url: `/userinfo`});
};
_nS('controllers.settings.EarnVotingInfoController'); _root.controllers.settings.EarnVotingInfoController.get = function() {
  if (true) { return _wA({method: "GET", url: `/settings/earn-voting`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/settings/earn-voting`}); }
};
_nS('controllers.settings.ChangePasswordController'); _root.controllers.settings.ChangePasswordController.get = function() {
  return _wA({method: "GET", url: `/settings/security/password`});
};
_nS('controllers.security.ZendeskChatJWTController'); _root.controllers.security.ZendeskChatJWTController.getZendeskChatJwt = function() {
  if (true) { return _wA({method: "GET", url: `/livechat/token`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/livechat/token`}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getRanksV2 = function(/* Option[org.joda.time.LocalDate] */ date0, /* Option[com.gemini.leaderboard.models.LeaderboardTypeSymbol] */ baseType1) {
  if (true) { return _wA({method: "GET", url: `/v2/leaderboard/daily/rank` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/leaderboard/daily/rank` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType1)])}); }
};
_nS('controllers.register.FullVerificationController'); _root.controllers.register.FullVerificationController.post = function(/* Boolean */ edit0) {
  if (true) { return _wA({method: "POST", url: `/register/verify` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0))])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/verify` + _qS([(edit0 == null ? null : (function(k,v){return k+'='+(!!v)})("edit", edit0))])}); }
};
_nS('controllers.earn.EarnDepositController'); _root.controllers.earn.EarnDepositController.post = function() {
  if (true) { return _wA({method: "POST", url: `/earn/deposit`}); }
  if (true) { return _wA({method: "POST", url: `/grow/deposit`}); }
  if (true) { return _wA({method: "POST", url: `/stake/deposit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/earn/deposit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/grow/deposit`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/stake/deposit`}); }
};
_nS('controllers.leaderboard.LeaderboardController'); _root.controllers.leaderboard.LeaderboardController.getRanksV1 = function(/* Option[org.joda.time.LocalDate] */ date0, /* Option[com.gemini.leaderboard.models.LeaderboardTypeSymbol] */ baseType1) {
  if (true) { return _wA({method: "GET", url: `/v1/leaderboard/daily/rank` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType1)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v1/leaderboard/daily/rank` + _qS([(function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("date", date0), (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("baseType", baseType1)])}); }
};
_nS('controllers.settings.ChangeEmailController'); _root.controllers.settings.ChangeEmailController.emailTokenHandlerPost = function(/* com.gemini.util.ValidEmail */ newEmail0) {
  if (true) { return _wA({method: "POST", url: `/settings/security/confirm-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
  if (true) { return _wA({method: "POST", url: `/mobile/settings/security/confirm-email` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("newEmail", newEmail0)])}); }
};
_nS('controllers.mobile.CommonController'); _root.controllers.mobile.CommonController.getConfig = function() {
  return _wA({method: "GET", url: `/mobile/config`});
};
_nS('controllers.order.OrderControllerV2'); _root.controllers.order.OrderControllerV2.getAdvancedPairDetails = function(/* exchange.metrics.Candle.TimeFrame */ time_frame0, /* Boolean */ includeRange1) {
  return _wA({method: "GET", url: `/trade/pair-details/${encodeURIComponent(time_frame0)}` + _qS([(includeRange1 == null ? null : (function(k,v){return k+'='+(!!v)})("includeRange", includeRange1))])});
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.getDepositQuote = function(/* com.gemini.web.models.TransferPageMethod */ mechanism0, /* String */ amount1, /* com.gemini.assets.veneer.CurrencyCode */ currency2) {
  if (true) { return _wA({method: "GET", url: `/transfer/deposit/${encodeURIComponent(mechanism0)}/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("currency", currency2)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/transfer/deposit/${encodeURIComponent(mechanism0)}/quote` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("amount", amount1), (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("currency", currency2)])}); }
};
_nS('controllers.transfer.page.TransferPageController'); _root.controllers.transfer.page.TransferPageController.withdrawDetails = function(/* com.gemini.web.models.TransferPageAsset */ asset0) {
  return _wA({method: "GET", url: `/transfer/withdraw/${encodeURIComponent(asset0)}/details`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getRefereeReferralCommissionTiers = function() {
  if (true) { return _wA({method: "GET", url: `/rewards/referral/tiers`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/referral/tiers`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.CheckLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.CheckLocationController.locationDetails = function() {
  return _wA({method: "POST", url: `/register/location-details`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController.postOptions = function() {
  return _wA({method: "OPTIONS", url: `/subscribe/email`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.listCommissionRewardsHistory = function(/* List[String] */ status0) {
  if (true) { return _wA({method: "GET", url: `/v2/rewards/history` + _qS([(status0 == null ? null : (function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("status", status0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/rewards/history` + _qS([(status0 == null ? null : (function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("status", status0))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController'); _root.com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController.resendEmail = function() {
  if (true) { return _wA({method: "POST", url: `/register/confirm-email/resend`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/confirm-email/resend`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController'); _root.com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController.getMobile = function(/* Option[String] */ token0) {
  return _wA({method: "GET", url: `/mobile/register/confirm-email` + _qS([(token0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("token", token0))])});
};
_nS('com.gemini.web.server.onboarding.controllers.services.ReferralCardController'); _root.com.gemini.web.server.onboarding.controllers.services.ReferralCardController.getStandardReferralCard = function() {
  if (true) { return _wA({method: "GET", url: `/marketing/referral/card`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/marketing/referral/card`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ReferralCardController'); _root.com.gemini.web.server.onboarding.controllers.services.ReferralCardController.getCommissionReferralCard = function() {
  if (true) { return _wA({method: "GET", url: `/marketing/referral/card/commission`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/marketing/referral/card/commission`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsController.getRewardsPage = function() {
  return _wA({method: "GET", url: `/rewards`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getCommissionRewardsSummary = function() {
  if (true) { return _wA({method: "GET", url: `/v2/rewards/referral/summary`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/rewards/referral/summary`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RegisterController'); _root.com.gemini.web.server.onboarding.controllers.services.RegisterController.post = function() {
  if (true) { return _wA({method: "POST", url: `/register/personal`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/personal`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.getSendPage = function() {
  return _wA({method: "GET", url: `/giftingcrypto/send`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getEligibleRewards = function() {
  if (true) { return _wA({method: "GET", url: `/rewards/eligible`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/eligible`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController.subscribeAsset = function(/* String */ subscriptionType0) {
  if (true) { return _wA({method: "POST", url: `/subscribe/asset/${encodeURIComponent(subscriptionType0)}`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/subscribe/asset/${encodeURIComponent(subscriptionType0)}`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.createReferralCode = function() {
  if (true) { return _wA({method: "PUT", url: `/rewards/referral/code`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/rewards/referral/code`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getReferralCode = function(/* Option[String] */ id0) {
  if (true) { return _wA({method: "GET", url: `/rewards/referral/code` + _qS([(id0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("id", id0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/referral/code` + _qS([(id0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("id", id0))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.getGiftHistory = function() {
  if (true) { return _wA({method: "GET", url: `/marketing/giftingcrypto/history`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/marketing/giftingcrypto/history`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.createCommissionReferralCode = function() {
  if (true) { return _wA({method: "PUT", url: `/v2/rewards/referral/code`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/v2/rewards/referral/code`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.hideReward = function(/* String */ id0, /* Boolean */ hidden1) {
  if (true) { return _wA({method: "PUT", url: `/rewards/hide` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("id", id0), (function(k,v){return k+'='+(!!v)})("hidden", hidden1)])}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/rewards/hide` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("id", id0), (function(k,v){return k+'='+(!!v)})("hidden", hidden1)])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.StreamlinedOnboardingRegistrationController'); _root.com.gemini.web.server.onboarding.controllers.services.StreamlinedOnboardingRegistrationController.postPromoCode = function() {
  if (true) { return _wA({method: "POST", url: `/register/promo-code/validate`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/promo-code/validate`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ContentfulController'); _root.com.gemini.web.server.onboarding.controllers.services.ContentfulController.getAsset = function(/* String */ asset0) {
  return _wA({method: "GET", url: `/contentful/${encodeURIComponent(asset0)}`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getCommissionReferralProgress = function() {
  if (true) { return _wA({method: "GET", url: `/v2/rewards/referral/progress`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/rewards/referral/progress`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GeminiEntityController'); _root.com.gemini.web.server.onboarding.controllers.services.GeminiEntityController.getIsPerpEligible = function(/* models.CountryCode */ countryCode0, /* Option[String] */ state1, /* Boolean */ isInstitutionalAccount2) {
  if (true) { return _wA({method: "GET", url: `/register/perps-eligibility` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("countryCode", countryCode0), (state1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("state", state1)), (isInstitutionalAccount2 == null ? null : (function(k,v){return k+'='+(!!v)})("isInstitutionalAccount", isInstitutionalAccount2))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/perps-eligibility` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("countryCode", countryCode0), (state1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("state", state1)), (isInstitutionalAccount2 == null ? null : (function(k,v){return k+'='+(!!v)})("isInstitutionalAccount", isInstitutionalAccount2))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.PromoModalsController'); _root.com.gemini.web.server.onboarding.controllers.services.PromoModalsController.post = function() {
  if (true) { return _wA({method: "POST", url: `/modal/seen`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/modal/seen`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController.subscription = function(/* String */ subscriptionType0, /* String */ id1) {
  return _wA({method: "GET", url: `/subscribe/email/${encodeURIComponent(subscriptionType0)}/${encodeURIComponent(id1)}`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RegisterController'); _root.com.gemini.web.server.onboarding.controllers.services.RegisterController.redirectToHome = function() {
  return _wA({method: "GET", url: `/invitation`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.GeminiEntityController'); _root.com.gemini.web.server.onboarding.controllers.services.GeminiEntityController.getGeminiEntityCountryMapping = function() {
  if (true) { return _wA({method: "GET", url: `/register/gemini-entity-countries`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/gemini-entity-countries`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController.post = function() {
  return _wA({method: "POST", url: `/subscribe/email`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getReferral = function(/* String */ id0) {
  if (true) { return _wA({method: "GET", url: `/rewards/referral` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("id", id0)])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/referral` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("id", id0)])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.sendGift = function() {
  if (true) { return _wA({method: "POST", url: `/marketing/giftingcrypto/send`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/marketing/giftingcrypto/send`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingSubscriptionController.subscriptionOptions = function(/* String */ subscriptionType0, /* String */ id1) {
  return _wA({method: "OPTIONS", url: `/subscribe/email/${encodeURIComponent(subscriptionType0)}/${encodeURIComponent(id1)}`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getReferralProgress = function() {
  if (true) { return _wA({method: "GET", url: `/rewards/referral/progress`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/referral/progress`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RegisterController'); _root.com.gemini.web.server.onboarding.controllers.services.RegisterController.get = function() {
  if (true) { return _wA({method: "GET", url: `/register`}); }
  if (true) { return _wA({method: "GET", url: `/register/check-location`}); }
  if (true) { return _wA({method: "GET", url: `/register/personal`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.getRedeemPage = function() {
  return _wA({method: "GET", url: `/giftingcrypto/redeem`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.GeminiEntityController'); _root.com.gemini.web.server.onboarding.controllers.services.GeminiEntityController.getGeminiEntityByLocationInformation = function(/* models.CountryCode */ countryCode0, /* Option[String] */ state1) {
  if (true) { return _wA({method: "GET", url: `/register/entity` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("countryCode", countryCode0), (state1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("state", state1))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/entity` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("countryCode", countryCode0), (state1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("state", state1))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RegisterController'); _root.com.gemini.web.server.onboarding.controllers.services.RegisterController.postPreFill = function() {
  if (true) { return _wA({method: "POST", url: `/register/pre-fill`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/pre-fill`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.CheckLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.CheckLocationController.checkLocation = function() {
  return _wA({method: "GET", url: `/mobile/register/check-location`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.CryptoAssetNoticeController'); _root.com.gemini.web.server.onboarding.controllers.services.CryptoAssetNoticeController.decline = function() {
  if (true) { return _wA({method: "POST", url: `/cryptoassetnotice/decline`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/cryptoassetnotice/decline`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.listRewardsHistory = function(/* String */ rewardType0, /* Int */ limit1, /* Int */ offset2, /* Option[String] */ rewardId3, /* List[String] */ status4) {
  if (true) { return _wA({method: "GET", url: `/rewards/history` + _qS([(rewardType0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("rewardType", rewardType0)), (limit1 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit1)), (offset2 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("offset", offset2)), (rewardId3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("rewardId", rewardId3)), (status4 == null ? null : (function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("status", status4))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/history` + _qS([(rewardType0 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("rewardType", rewardType0)), (limit1 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("limit", limit1)), (offset2 == null ? null : (function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("offset", offset2)), (rewardId3 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("rewardId", rewardId3)), (status4 == null ? null : (function(k,vs){var l=vs&&vs.length,r=[],i=0;for(;i<l;i++){r[i]=(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,vs[i])}return r.join('&')})("status", status4))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getTransactions = function() {
  if (true) { return _wA({method: "GET", url: `/rewards/transactions`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/transactions`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingLeadsController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingLeadsController.post = function(/* String */ service0) {
  return _wA({method: "POST", url: `/subscribe/${encodeURIComponent(service0)}`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RegisterController'); _root.com.gemini.web.server.onboarding.controllers.services.RegisterController.clearEmail = function() {
  return _wA({method: "GET", url: `/register/clear-email`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getCommissionReferralCode = function(/* Option[String] */ id0) {
  if (true) { return _wA({method: "GET", url: `/v2/rewards/referral/code` + _qS([(id0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("id", id0))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/v2/rewards/referral/code` + _qS([(id0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("id", id0))])}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.StreamlinedOnboardingRegistrationController'); _root.com.gemini.web.server.onboarding.controllers.services.StreamlinedOnboardingRegistrationController.postNewUser = function() {
  if (true) { return _wA({method: "POST", url: `/register/new-user`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/new-user`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.redeemGift = function() {
  if (true) { return _wA({method: "POST", url: `/marketing/giftingcrypto/redeem`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/marketing/giftingcrypto/redeem`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingLocationController.checkLocationByIp = function(/* String */ ip0) {
  return _wA({method: "GET", url: `/locate/${encodeURIComponent(ip0)}`});
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.updatePayoutCurrency = function() {
  if (true) { return _wA({method: "PUT", url: `/rewards/payout/currency`}); }
  if (true) { return _wA({method: "PUT", url: `/mobile/rewards/payout/currency`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.CheckLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.CheckLocationController.optIn = function() {
  if (true) { return _wA({method: "POST", url: `/register/check-location`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/register/check-location/opt-in`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ContentfulController'); _root.com.gemini.web.server.onboarding.controllers.services.ContentfulController.getProductInfo = function(/* String */ productInfoType0, /* String */ messageKey1) {
  return _wA({method: "GET", url: `/contentful/productInfo/${encodeURIComponent(productInfoType0)}` + _qS([(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})("messageKey", messageKey1)])});
};
_nS('com.gemini.web.server.onboarding.controllers.services.CheckLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.CheckLocationController.getOperatingCountries = function() {
  if (true) { return _wA({method: "GET", url: `/register/operating-countries`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/register/operating-countries`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.RewardsHubController'); _root.com.gemini.web.server.onboarding.controllers.services.RewardsHubController.getRewardsSummary = function() {
  if (true) { return _wA({method: "GET", url: `/rewards/summary`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/rewards/summary`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController'); _root.com.gemini.web.server.onboarding.controllers.services.ConfirmEmailController.get = function(/* Option[String] */ token0) {
  return _wA({method: "GET", url: `/register/confirm-email` + _qS([(token0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("token", token0))])});
};
_nS('com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController'); _root.com.gemini.web.server.onboarding.controllers.services.GiftingCryptoController.getGiftInformation = function() {
  if (true) { return _wA({method: "GET", url: `/marketing/giftingcrypto/send`}); }
  if (true) { return _wA({method: "GET", url: `/mobile/marketing/giftingcrypto/send`}); }
};
_nS('com.gemini.web.server.onboarding.controllers.services.MarketingLocationController'); _root.com.gemini.web.server.onboarding.controllers.services.MarketingLocationController.checkLocation = function() {
  return _wA({method: "GET", url: `/locate`});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.getAllAlerts = function(/* Option[com.gemini.assets.veneer.CurrencyCode] */ priceCurrency0, /* Option[com.gemini.assets.veneer.TradingPairSymbol] */ pair1) {
  if (true) { return _wA({method: "GET", url: `/web/userPriceAlerts` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (pair1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pair", pair1))])}); }
  if (true) { return _wA({method: "GET", url: `/mobile/userPriceAlerts` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (pair1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pair", pair1))])}); }
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.getAllAlertsV2 = function(/* Option[com.gemini.assets.veneer.CurrencyCode] */ priceCurrency0, /* Option[com.gemini.assets.veneer.TradingPairSymbol] */ pair1) {
  return _wA({method: "GET", url: `/mobile/v2/userPriceAlerts` + _qS([(priceCurrency0 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("priceCurrency", priceCurrency0)), (pair1 == null ? null : (function(k,v){return v!=null?(function(k,v) {return encodeURIComponent(k)+'='+encodeURIComponent(v)})(k,v):''})("pair", pair1))])});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.disableAlert = function(/* models.PriceAlertId */ alertId0) {
  return _wA({method: "PUT", url: `/mobile/userPriceAlerts/disable/${encodeURIComponent(alertId0)}`});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.enableAlert = function(/* models.PriceAlertId */ alertId0) {
  return _wA({method: "PUT", url: `/mobile/userPriceAlerts/enable/${encodeURIComponent(alertId0)}`});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.webDisableAlert = function(/* models.PriceAlertId */ alertId0) {
  return _wA({method: "PUT", url: `/web/userPriceAlerts/disable/${encodeURIComponent(alertId0)}`});
};
_nS('com.gemini.web.server.trading.controllers.EotcController'); _root.com.gemini.web.server.trading.controllers.EotcController.get = function() {
  return _wA({method: "GET", url: `/eotc`});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.deleteAlert = function(/* models.PriceAlertId */ alertId0) {
  if (true) { return _wA({method: "DELETE", url: `/web/userPriceAlerts/${encodeURIComponent(alertId0)}`}); }
  if (true) { return _wA({method: "DELETE", url: `/mobile/userPriceAlerts/${encodeURIComponent(alertId0)}`}); }
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.webEnableAlert = function(/* models.PriceAlertId */ alertId0) {
  return _wA({method: "PUT", url: `/web/userPriceAlerts/enable/${encodeURIComponent(alertId0)}`});
};
_nS('com.gemini.web.server.trading.controllers.PriceAlertsController'); _root.com.gemini.web.server.trading.controllers.PriceAlertsController.addAlert = function() {
  if (true) { return _wA({method: "POST", url: `/web/userPriceAlerts`}); }
  if (true) { return _wA({method: "POST", url: `/mobile/userPriceAlerts`}); }
};
})(jsRoutes)
module.exports = jsRoutes;
    