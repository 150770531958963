import { Fragment, useMemo } from "react";
import { useEffectOnce } from "react-use";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { EVENTS, track } from "@gemini-ui/analytics";
import { Badge, Button, Flex, Modal, Spacing, Text } from "@gemini-ui/design-system";
import { ModalProps } from "@gemini-ui/design-system/Modal";
import { taxInformation } from "@gemini-ui/pages/Earn/Deposit/CountryTaxInformation/constants";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

export interface CountryTaxInformationProps {
  countryCode: CountryAbbreviation;
  onAccept?: () => void;
}

const CountryTaxInformation = ({ countryCode, onAccept }: CountryTaxInformationProps) => {
  const { intl } = useIntl();

  const content = useMemo(() => taxInformation(intl)?.[countryCode] ?? [], [countryCode, intl]);

  useEffectOnce(() => {
    track(EVENTS.VIEW_TAX_INFORMATION_DISCLAIMER.name, {
      [EVENTS.VIEW_TAX_INFORMATION_DISCLAIMER.properties.COUNTRY_CODE]: countryCode,
    });
  });

  return (
    <Fragment>
      <Text.Heading size="md">{intl.formatMessage({ defaultMessage: "Tax information" })}</Text.Heading>

      <Flex flexDirection="column" gap={Spacing.scale[3]}>
        {content.map(({ title, badge, body }, idx) => (
          <Flex key={`tax-event-${idx}`} flexDirection="column" align="start" gap={Spacing.scale[1]}>
            <Text.Body size="lg" bold>
              {title}
            </Text.Body>
            <Badge>{badge}</Badge>
            <Text.Body size="md">{body}</Text.Body>
          </Flex>
        ))}

        <Text.Legal>
          {intl.formatMessage({
            defaultMessage:
              "Disclaimer: The tax treatment of staking may change and we recommend you review the most recent tax guidance or speak to a tax adviser before deciding whether you should stake your assets.",
          })}
        </Text.Legal>
      </Flex>

      <Button.Group type="action">
        <Button.Primary
          data-testid={testIds.deposit.taxInformation.acceptButton}
          onClick={() => {
            track(EVENTS.ACCEPT_TAX_INFORMATION_DISCLAIMER.name, {
              [EVENTS.ACCEPT_TAX_INFORMATION_DISCLAIMER.properties.COUNTRY_CODE]: countryCode,
            });

            onAccept?.();
          }}
          size="md"
          cta={intl.formatMessage({ defaultMessage: "Agree and continue" })}
        />
      </Button.Group>
    </Fragment>
  );
};

export default CountryTaxInformation;

export const CountryTaxInformationModal = ({
  countryCode,
  onAccept,
  ...modalProps
}: CountryTaxInformationProps & ModalProps) => {
  return (
    <Modal {...modalProps}>
      <CountryTaxInformation countryCode={countryCode} onAccept={onAccept} />
    </Modal>
  );
};
