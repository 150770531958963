import { Dispatch, Fragment, useEffect } from "react";
import { useController } from "react-hook-form";
import { countriesList as list } from "@gemini-common/scripts/constants/Countries";
import { Action, ActionType, State } from "@gemini-ui/components/TransferControl/reducer";
import { OwnerType } from "@gemini-ui/components/TransferControl/types";
import { usePageData } from "@gemini-ui/contexts";
import { Fieldset, Flex, Form, Input, Radio, Select, Text, useForm } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

type ConfirmSenderFormValues = {
  walletOwner: OwnerType;
  ownerCountry: string;
  name?: string;
  firstName?: string;
  lastName?: string;
};

interface Props {
  state: State;
  getIsConfirmSenderIsValid: (isValid: boolean) => void;
  dispatch: Dispatch<Action>;
  isConfirmOwnershipValid: boolean;
  dateReceived: string;
}

function ConfirmSender({ state, getIsConfirmSenderIsValid, dispatch, isConfirmOwnershipValid, dateReceived }: Props) {
  const { intl } = useIntl();
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();
  const { transferControl } = testIds;
  const { ownerCountryCode, ownerFirstName, ownerLastName, ownerName, ownerType } = state;

  const isUkTravelRuleEnabled = getIsUkInboundEnabled(geminiEntity);
  const isIndividual = ownerType === OwnerType.INDIVIDUAL;

  const {
    formState: { errors, isValid },
    register,
    control,
  } = useForm<ConfirmSenderFormValues>({
    defaultValues: {
      walletOwner: ownerType || OwnerType.INDIVIDUAL,
      name: ownerName || "",
      firstName: ownerFirstName || "",
      lastName: ownerLastName || "",
      ownerCountry: ownerCountryCode || "",
    },
  });

  useEffect(() => {
    if (isConfirmOwnershipValid !== isValid) {
      getIsConfirmSenderIsValid(isValid);
    }
  }, [getIsConfirmSenderIsValid, isConfirmOwnershipValid, isValid]);

  const radioProps = {
    ...register("walletOwner"),
    onChange: e => {
      dispatch({ type: ActionType.OWNER_TYPE, payload: e.currentTarget.value });
    },
  };

  const { field } = useController({
    name: "ownerCountry",
    control,
    rules: {
      required: intl.formatMessage({ defaultMessage: "Owner's Country is required" }),
    },
  });

  const countriesList = Object.entries(list).map(([code, name]) => ({ "aria-label": name, label: name, value: code }));

  return (
    <Flex flexDirection="column">
      <Text.Body size="sm" mb={3}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "Please provide additional information for your crypto deposit received {dateReceived}.",
          }),
          { dateReceived }
        )}
      </Text.Body>

      <Text.Body size="sm" mb={2} bold>
        {intl.formatMessage({
          defaultMessage: "Is the owner of the sending wallet a individual or entity?",
        })}
      </Text.Body>

      <Form errors={errors}>
        <Fieldset isInline data-testid={transferControl.confirmSenderWalletOwner}>
          <Radio
            value={OwnerType.INDIVIDUAL}
            data-testid={transferControl.confirmSenderIndividualOwner}
            {...radioProps}
          >
            {intl.formatMessage({
              defaultMessage: "Individual",
            })}
          </Radio>
          <Radio value={OwnerType.ENTITY} data-testid={transferControl.confirmSenderEntityOwnder} {...radioProps}>
            {intl.formatMessage({
              defaultMessage: "Entity",
            })}
          </Radio>
        </Fieldset>

        {isUkTravelRuleEnabled && isIndividual ? (
          <Fragment>
            <Input
              mt={1}
              id="firstName"
              inputSize="lg"
              data-testid={transferControl.confirmSenderFirstName}
              placeholder={intl.formatMessage({
                defaultMessage: "Enter first name",
              })}
              label={intl.formatMessage({
                defaultMessage: "First name",
              })}
              {...register("firstName", { required: intl.formatMessage({ defaultMessage: "First name is required" }) })}
              onChange={e => {
                dispatch({ type: ActionType.OWNER_FIRST_NAME, payload: e.currentTarget.value });
              }}
              error={errors?.firstName?.message}
            />
            <Input
              mt={1}
              id="lastName"
              inputSize="lg"
              data-testid={transferControl.confirmSenderLastName}
              placeholder={intl.formatMessage({
                defaultMessage: "Enter last name",
              })}
              label={intl.formatMessage({
                defaultMessage: "Last name",
              })}
              {...register("lastName", { required: intl.formatMessage({ defaultMessage: "Last name is required" }) })}
              onChange={e => {
                dispatch({ type: ActionType.OWNER_LAST_NAME, payload: e.currentTarget.value });
              }}
              error={errors?.lastName?.message}
            />
          </Fragment>
        ) : (
          <Input
            mt={1}
            id="name"
            inputSize="lg"
            data-testid={transferControl.confirmSenderName}
            placeholder={intl.formatMessage({
              defaultMessage: "e.g. Satoshi Nakamoto",
            })}
            label={
              isUkTravelRuleEnabled
                ? intl.formatMessage({
                    defaultMessage: "Legal name",
                  })
                : intl.formatMessage({
                    defaultMessage: "Full Name",
                  })
            }
            {...register("name", {
              required: isUkTravelRuleEnabled
                ? intl.formatMessage({ defaultMessage: "Legal name is required" })
                : intl.formatMessage({ defaultMessage: "Full Name is required" }),
            })}
            onChange={e => {
              dispatch({ type: ActionType.OWNER_NAME, payload: e.currentTarget.value });
            }}
            error={errors?.name?.message}
          />
        )}

        <Select
          size="lg"
          id="owner-country"
          menuPortalTarget={document.body}
          customSelectStyles={{
            menuPortal: defaultStyles => ({
              ...defaultStyles,
              bottom: 0,
              zIndex: 9999,
            }),
          }}
          data-testid={transferControl.confirmSenderOwnerCountry}
          label={intl.formatMessage({
            defaultMessage: "Owner’s country of residence/incorporation",
          })}
          options={countriesList}
          {...field}
          onChange={countryCode => {
            dispatch({ type: ActionType.OWNER_COUNTRY_CODE, payload: countryCode });
            field.onChange(countryCode);
          }}
          error={errors?.ownerCountry?.message}
        />
      </Form>
    </Flex>
  );
}

export default ConfirmSender;
