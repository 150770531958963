import { Fragment } from "react";
import { optimizelyClient, useTrackLinks } from "@gemini-ui/analytics";
import { HEADER_EVENTS, LinkName } from "@gemini-ui/components/Header/HeaderMenuLink/constants";
import {
  ActiveBar,
  HeaderNavButtonWrap,
  HeaderNavLink,
  NameContainer,
} from "@gemini-ui/components/Header/HeaderMenuLink/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { HubbleButton } from "@gemini-ui/design-system";

interface Props {
  name: string;
  href?: string;
  onClick?: () => void;
  active?: boolean;
  hideMobile?: boolean;
  linkName?: LinkName;
}

const HeaderMenuLink = ({ active = false, name, href, hideMobile, linkName, onClick }: Props) => {
  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  const id = `header-menu-link-${linkName}`;
  const eventName = HEADER_EVENTS[linkName];

  useTrackLinks({
    selector: `#${id}`,
    eventName,
    properties: {
      origin: window.location.pathname,
    },
  });

  return (
    <Fragment>
      {isHeaderV2Enabled ? (
        <HeaderNavButtonWrap alignItems="center" hideMobile={hideMobile}>
          {active ? (
            <HubbleButton.Secondary
              id={id}
              data-testid={id}
              size="sm"
              cta={name}
              href={href}
              onClick={onClick}
              mr={0.5}
            />
          ) : (
            <HubbleButton.Tertiary
              id={id}
              data-testid={id}
              size="sm"
              cta={name}
              href={href}
              onClick={onClick}
              mr={0.5}
            />
          )}
        </HeaderNavButtonWrap>
      ) : (
        <HeaderNavLink id={id} data-testid={id} href={href} active={active} hideMobile={hideMobile} onClick={onClick}>
          <NameContainer>
            {name}
            {active && <ActiveBar />}
          </NameContainer>
        </HeaderNavLink>
      )}
    </Fragment>
  );
};

export default HeaderMenuLink;
