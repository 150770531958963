import { useEffect, useState } from "react";
import { UserPermissions } from "@gemini-ui/services/user/constants";
import { getUserPermissions } from "@gemini-ui/services/user/getUserPermissions";

export type UseGetUserPermissionsState = {
  loading: boolean;
  error: boolean;
  permissions: UserPermissions | null;
  fetchUserPermissions: (loading?: boolean) => Promise<void>;
};

export const useGetUserPermissions = (): UseGetUserPermissionsState => {
  const [userPermissions, setUserPermissionsState] = useState<Omit<UseGetUserPermissionsState, "fetchUserPermissions">>(
    {
      loading: true,
      error: false,
      permissions: null,
    }
  );

  const fetchUserPermissions = async (loading = false) => {
    if (loading) {
      setUserPermissionsState(prev => ({ ...prev, loading: true }));
    }

    const permissions = await getUserPermissions();

    if (!permissions) {
      setUserPermissionsState({ permissions: null, error: true, loading: false });
      return;
    }

    setUserPermissionsState({ error: false, loading: false, permissions });
  };

  useEffect(() => {
    fetchUserPermissions();
  }, []);

  return { ...userPermissions, fetchUserPermissions };
};
