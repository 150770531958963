import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Global } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { SdkError } from "onfido-sdk-ui";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { FailedLazyLoadModal } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { DocumentUploaderProps, ONFIDO_CUSTOM_UI_CONFIG } from "@gemini-ui/components/OnfidoDocumentUploader/constants";
import { commonOnfidoCss, Uploader } from "@gemini-ui/components/OnfidoDocumentUploader/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PhoneNumber } from "@gemini-ui/constants/phoneNumber";
import { Modal } from "@gemini-ui/design-system";
import { onfidoLocales, useIntl } from "@gemini-ui/utils/intl";

const DEFAULT_LOCALE = "en";
const CONTAINER_ID = "onfido-mount";
const formatPhoneNumber = (phoneNumber: PhoneNumber) => {
  return `+${phoneNumber.intlPrefix}${phoneNumber.number}`.split(" ").join("");
};
const ONFIDO_EVENT_LISTENER = "userAnalyticsEvent";
const CONTENTFUL_BRAND_ASSET =
  "//images.ctfassets.net/jg6lo9a2ukvr/3fTLFAWIE5uj7pAIkAST64/5d3b70ef8af71e3d0b31c6177071342b/Stacked_logo_for_OnFido-Light_mode.png";
const trackOnfidoEvent = event => {
  const onfidoEvent = event?.detail?.eventName;
  track(EVENTS.ON_FIDO.name, {
    [EVENTS.ON_FIDO.properties.EVENT_NAME]: onfidoEvent,
  });
};

const onUserExit = userExitCode => {
  if (userExitCode === "USER_CONSENT_DENIED") {
    window.location.assign("/");
  }
  track(EVENTS.ON_FIDO.name, {
    [EVENTS.ON_FIDO.properties.USER_EXIT_CODE]: userExitCode,
  });
  Sentry.captureMessage(`OnFido user exited without completing upload ${userExitCode}`, "warning");
};

const ONFIDO_SDK_STATE = {
  initializing: "initializing",
  ready: "ready",
  failed: "failed",
};

const OnfidoDocumentUploader = (props: DocumentUploaderProps) => {
  const enableIssuingCountrySelection = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_ONFIDO_ENABLE_ISSUING_COUNTRY_SELECTION
  );
  const onfidoInstance = useRef(null);
  const { intl, locale } = useIntl();
  const { onError, onfidoSDKToken, onComplete, phoneNumber, onModalRequestClose, isModalOpen = false } = props;
  const [sdkState, setSdkState] = useState(ONFIDO_SDK_STATE.initializing);

  const handleOnError = useCallback(
    (error: SdkError) => {
      track(EVENTS.ON_FIDO.name, {
        [EVENTS.ON_FIDO.properties.ERROR_TYPE]: error?.type,
        [EVENTS.ON_FIDO.properties.ERROR_MESSAGE]: error?.message,
      });
      Sentry.captureMessage(`OnFido document upload error ${error?.message}`, "error");
      if (onError) {
        onError(error);
      }
    },
    [onError]
  );

  const handleOnModalRequestClose = useCallback(() => {
    onfidoInstance.current.setOptions({ isModalOpen: false });
    onModalRequestClose();
  }, [onModalRequestClose]);

  const getSteps = useCallback(() => {
    const { documentTypes, welcome, confirmation, poa, requestedLivenessVariant, forceCrossDevice = false } = props;
    const steps = [];

    if (welcome) {
      const welcomeStep = {
        type: "welcome",
        options: welcome,
      };
      steps.push(welcomeStep);
    }

    if (poa) {
      const poaStep = {
        type: "poa",
        options: poa,
      };
      steps.push(poaStep);
    }
    if (documentTypes) {
      // Defaults to ID uploader unless proof of address options provided.
      const docTypes = {};
      for (const [docType, docValue] of Object.entries(documentTypes)) {
        if (docValue) {
          docTypes[docType] = { country: null };
        }
      }
      const document = {
        type: "document",
        options: {
          documentTypes: docTypes,
          forceCrossDevice,
          hideCountrySelection: !enableIssuingCountrySelection,
        },
      };
      steps.push(document);
    }
    if (requestedLivenessVariant) {
      const face = {
        type: "face",
        options: {
          requestedVariant: requestedLivenessVariant,
          forceCrossDevice,
        },
      };

      steps.push(face);
    }

    if (confirmation) {
      const confirmationStep = {
        type: "complete",
        options: confirmation,
      };
      steps.push(confirmationStep);
    }
    return steps;
    // intentional, once the steps are set they should not be changed, remount component if you need to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addEventListener(ONFIDO_EVENT_LISTENER, trackOnfidoEvent);

    // only run hook if we are initializing the SDK
    if (sdkState === ONFIDO_SDK_STATE.initializing) {
      const onfidoConfig = {
        token: onfidoSDKToken,
        containerId: CONTAINER_ID,
        steps: getSteps(),
        customUI: {
          ...ONFIDO_CUSTOM_UI_CONFIG,
        },
        language: {
          locale: onfidoLocales[locale] ?? DEFAULT_LOCALE,
          phrases: {
            "doc_select.title": intl.formatMessage({
              defaultMessage: "Which type of photo ID would you like to provide?",
            }),
            "doc_select.button_id": intl.formatMessage({ defaultMessage: "National ID" }),
            "capture.national_identity_card.front.title": intl.formatMessage({
              defaultMessage: "Submit national ID (front)",
            }),
            "capture.national_identity_card.back.title": intl.formatMessage({
              defaultMessage: "Submit national ID (back)",
            }),
            "capture.driving_licence.front.title": intl.formatMessage({
              defaultMessage: "Submit government-issued driver's license (front)",
            }),
            "capture.driving_licence.back.title": intl.formatMessage({
              defaultMessage: "Submit government-issued driver's license (back)",
            }),
          },
        },
        crossDeviceClientIntroProductName: intl.formatMessage({
          defaultMessage: "for Gemini",
          description: "OnFido cross device link text, full text reads: Continue with the verification for Gemini",
        }),
        crossDeviceClientIntroProductLogoSrc: CONTENTFUL_BRAND_ASSET,
        onComplete,
        onUserExit,
        onError: handleOnError,
        useModal: Boolean(isModalOpen),
        isModalOpen,
        onModalRequestClose: handleOnModalRequestClose,
        shouldCloseOnOverlayClick: false, // if using the modal, prevent accidentally closing the modal and losing progress
      };

      if (phoneNumber) {
        onfidoConfig["userDetails"] = { smsNumber: formatPhoneNumber(phoneNumber) };
      }

      import("onfido-sdk-ui")
        .then(module => {
          setSdkState(ONFIDO_SDK_STATE.ready);
          onfidoInstance.current = module.init(onfidoConfig);
        })
        .catch(err => {
          setSdkState(ONFIDO_SDK_STATE.failed);
          track(EVENTS.ON_FIDO.name, {
            [EVENTS.ON_FIDO.properties.ERROR_TYPE]: "Chunk loading error",
            [EVENTS.ON_FIDO.properties.ERROR_MESSAGE]: err.message,
          });
          // err.message: ChunkLoadError: Loading chunk $id failed.
          Sentry.captureMessage(`Error loading onfido sdk ui document uploader - ${err}`, "error");
        });
    }

    return () => {
      if (sdkState === ONFIDO_SDK_STATE.ready) {
        onfidoInstance?.current?.tearDown();
      }
      removeEventListener(ONFIDO_EVENT_LISTENER, trackOnfidoEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkState]);

  return (
    <Fragment>
      <Modal isOpen={sdkState === ONFIDO_SDK_STATE.initializing} loading />
      <FailedLazyLoadModal
        isOpen={sdkState === ONFIDO_SDK_STATE.failed}
        retry={() => setSdkState(ONFIDO_SDK_STATE.initializing)}
      />
      {isModalOpen && <Global styles={commonOnfidoCss} />}
      <Uploader id={CONTAINER_ID} />
    </Fragment>
  );
};
export default OnfidoDocumentUploader;
