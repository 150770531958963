import { Text } from "@gemini-ui/design-system";

const CONTACT_SUPPORT_WEB_FORM_LINK = "https://support.gemini.com/hc/en-us/requests/new?ticket_form_id=1260809732969";

const ContactSupportLink = ({ textToDisplay, link = CONTACT_SUPPORT_WEB_FORM_LINK }) => (
  <Text.Link href={link} rel="noopener noreferrer" target="_blank">
    {textToDisplay}
  </Text.Link>
);

export default ContactSupportLink;
