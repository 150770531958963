import { Fragment, useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconChevronLeftSmall } from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { isEqual } from "lodash";
import { CURRENCIES_DETAIL } from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import {
  getAccountEditCopy,
  getAdvancedAccountSelectorCopy,
  leverage,
  promiseTypes,
  successTypes,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import {
  checkCollateral,
  checkLeverage,
  checkName,
  SettingsPromiseResponse,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/settingsRequests";
import { LoadingErrorSection } from "@gemini-ui/components/Services/LoadingErrorSection";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageRefresh } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Button, Flex, Input, SectionMessage, Select, Text, Toggle } from "@gemini-ui/design-system";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { SubaccountWithBalances } from "@gemini-ui/services/subaccounts";
import { UseGetUserPermissionsState } from "@gemini-ui/services/user/useGetUserPermissions";
import { formatCurrency } from "@gemini-ui/utils/inputNumber";
import { useIntl } from "@gemini-ui/utils/intl";

type AccountEditProps = {
  editAccount: SubaccountWithBalances;
  handleCancel: () => void;
  setEditAccount: (subaccount: SubaccountWithBalances) => void;
  fetchSubaccounts: () => void;
  handleMenuClick?: () => void;
  userPermissions: UseGetUserPermissionsState;
};

interface AccountEditContentProps extends AccountEditProps {
  isModal?: boolean;
  handleMenuClick?: () => void;
}

export const AccountEdit = ({
  editAccount,
  handleCancel,
  setEditAccount,
  fetchSubaccounts,
  handleMenuClick,
  userPermissions,
}: AccountEditProps) => {
  const { intl } = useIntl();
  const copy = getAdvancedAccountSelectorCopy(intl);

  const theme = useTheme();
  const { colorScheme } = theme;

  return (
    <div data-testid="edit-account">
      <Flex
        p={2}
        justifyContent="flex-start"
        alignItems="center"
        borderBottom={`${colorScheme.border.secondary} 1px solid`}
        background={theme.isDark && "#232526"}
      >
        <Button.Tertiary
          aria-label={copy.ARIA.EDIT_ACCOUNT_BACK_BUTTON}
          data-testid="nav-account-selector-edit-mode-back-button"
          mr={1.5}
          size="sm"
          onClick={handleCancel}
          icon={<IconChevronLeftSmall />}
        />
        <Text.Body size="sm" bold>
          {copy.LABELS.EDIT_ACCOUNT}
        </Text.Body>
      </Flex>
      <AccountEditContent
        handleCancel={handleCancel}
        editAccount={editAccount}
        setEditAccount={setEditAccount}
        fetchSubaccounts={fetchSubaccounts}
        handleMenuClick={handleMenuClick}
        userPermissions={userPermissions}
      />
    </div>
  );
};

export const AccountEditContent = ({
  editAccount,
  isModal = false,
  handleCancel,
  setEditAccount,
  fetchSubaccounts,
  handleMenuClick,
  userPermissions,
}: AccountEditContentProps) => {
  const { intl } = useIntl();
  const { toggleModal } = useGlobalModal();
  const { requestRefresh } = usePageRefresh();

  const theme = useTheme();
  const { colorScheme } = theme;

  const [editAccountState, setEditAccountState] = useState<SubaccountWithBalances>(editAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [mav, setMav] = useState("0");

  const [typeOfSuccess, setTypeOfSuccess] = useState(null);
  const [defaultError, setDefaultError] = useState(false);
  const [leverageErrorMessage, setLeverageErrorMessage] = useState(null);
  const [collateralErrorMessage, setCollateralErrorMessage] = useState(null);

  const copy = getAccountEditCopy(intl, formatCurrency(mav, CURRENCIES_DETAIL.GUSD.symbol, true, 2));

  const unchanged = isEqual(editAccount, editAccountState);
  const crossCollateral = editAccountState?.crossCollateralParams?.enableCrossCollateral === "true";
  const negativeBalances = editAccountState?.crossCollateralParams?.enableNegativeBalances === "true";
  const maxLeverage = editAccountState?.crossCollateralParams?.maxLeverage;

  const sidePad = isModal ? 0 : 2;

  useMemo(() => {
    setEditAccountState(editAccount);
  }, [editAccount]);

  useEffect(() => {
    setTypeOfSuccess(null);
    setMav("0");
    setDefaultError(false);
    setLeverageErrorMessage(null);
    setCollateralErrorMessage(null);
  }, [editAccountState]);

  const handleAccountName = (name: string) => {
    setEditAccountState(s => ({ ...s, name }));
  };

  const handleLeverageValue = (maxLeverage: string) => {
    setEditAccountState(s => ({
      ...s,
      crossCollateralParams: { ...s.crossCollateralParams, maxLeverage },
    }));
  };

  const handleCrossCollateral = (value: boolean) => {
    setEditAccountState(s => ({
      ...s,
      crossCollateralParams: { ...s.crossCollateralParams, enableCrossCollateral: `${value}` },
    }));
  };

  const handleNegativeBalances = (value: boolean) => {
    setEditAccountState(s => ({
      ...s,
      crossCollateralParams: { ...s.crossCollateralParams, enableNegativeBalances: `${value}` },
    }));
  };

  const handleOpenTransfers = () => {
    handleCancel();
    handleMenuClick && handleMenuClick();
    toggleModal(GlobalModalType.PerpsOnboardingTransferFormModal);
  };

  const handleSubmit = () => {
    const promises = [];

    // Check each setting for changes and get a Promise w/ each change request
    if (editAccount.derivatives) {
      const leveragePromise = checkLeverage(editAccountState, editAccount);
      leveragePromise && promises.push(leveragePromise);

      const collateralPromise = checkCollateral(editAccountState, editAccount);
      collateralPromise && promises.push(collateralPromise);
    }
    const namePromise = checkName(editAccountState, editAccount);
    namePromise && promises.push(namePromise);

    // Check for any Promises and initiate their requests
    if (promises.length > 0) {
      setIsLoading(true);
      Promise.allSettled(promises).then(res => handleResponses(res));
    }
  };

  const handleResponses = (responses: SettingsPromiseResponse[]) => {
    const successes = [];
    const errors = [];

    responses.map(response => {
      response.value.err ? errors.push(response) : successes.push(response);
    });

    successes.length > 0 && handleSuccesses(successes);
    errors.length > 0 && handleErrors(errors);

    setIsLoading(false);

    setEditAccount(editAccountState);
    requestRefresh();
  };

  const tradingPair = "BTCGUSDPERP";
  const riskStatsUrl = jsRoutes.controllers.order.RiskController.getAccountMargin(tradingPair).url;
  const showMav =
    editAccountState?.crossCollateralParams?.enableCrossCollateral !==
    editAccount?.crossCollateralParams?.enableCrossCollateral;

  const handleSuccesses = (successes: SettingsPromiseResponse[]) => {
    const collateralSuccess = successes.filter(success => success.value.promise.includes(promiseTypes.COLLATERAL));
    if (collateralSuccess.length > 0 && showMav) {
      axios
        .get(riskStatsUrl, {
          headers: { [HEADERS.ACCOUNT_ID]: editAccount.hashid },
        })
        .then(({ data }) => {
          setMav(data.mav.value);
          const showButton =
            editAccountState?.crossCollateralParams?.enableCrossCollateral === "true" && data.mav.value === "0";
          setTypeOfSuccess(showButton ? successTypes.BUTTON : successTypes.MAV);
        })
        .catch(err => {
          setTypeOfSuccess(successTypes.DEFAULT);
          Sentry.captureException(err);
        });
    } else {
      setTypeOfSuccess(successTypes.DEFAULT);
    }

    fetchSubaccounts();
  };

  const handleErrors = (errors: SettingsPromiseResponse[]) => {
    const nameError = errors.filter(error => error.value.promise.includes(promiseTypes.NAME));
    const leverageError = errors.filter(error => error.value.promise.includes(promiseTypes.LEVERAGE));
    const collateralError = errors.filter(error => error.value.promise.includes(promiseTypes.COLLATERAL));

    nameError.length > 0 && setDefaultError(true);

    if (leverageError.length > 0) {
      leverageError[0].value.err === successTypes.DEFAULT
        ? setDefaultError(true)
        : setLeverageErrorMessage(leverageError[0].value.err);
    }

    if (collateralError.length > 0) {
      collateralError[0].value.err === successTypes.DEFAULT
        ? setDefaultError(true)
        : setCollateralErrorMessage(collateralError[0].value.err);
    }
  };

  const isCrossCollateralUiEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CROSS_COLLATERAL_UI
  );

  const isDerivativesCrossCollateral = editAccount.derivatives && isCrossCollateralUiEnabled;

  if (isDerivativesCrossCollateral && userPermissions.error) {
    return (
      <LoadingErrorSection
        data-testid="account-edit-content-section-error"
        errorMessage={intl.formatMessage({
          defaultMessage:
            "An error occurred while initiating the Account Edit Module. Please try again and or contact customer support.",
        })}
        refetchData={userPermissions.fetchUserPermissions}
      />
    );
  }

  return (
    <Fragment>
      <Input
        data-testid="rename-account"
        name={copy.LABELS.RENAME_ACCOUNT}
        label={copy.LABELS.RENAME_ACCOUNT}
        value={editAccountState?.name}
        onChange={e => handleAccountName(e.target.value)}
        pt={1.5}
        pl={sidePad}
        pr={sidePad}
      />
      {editAccount.derivatives && (
        <Fragment>
          <Select
            data-testid="leverage-select"
            label={copy.LABELS.LEVERAGE}
            options={leverage(intl)}
            value={`${maxLeverage}`}
            onChange={v => handleLeverageValue(v)}
            pt={1.5}
            pl={sidePad}
            pr={sidePad}
            mb={1}
          />
          <Text.Body size="xs" mb={3} pl={sidePad} pr={sidePad} color={colorScheme.content.secondary}>
            {copy.LABELS.LEVERAGE_DESCRIPTION}
          </Text.Body>
        </Fragment>
      )}
      {isDerivativesCrossCollateral && (
        <Fragment>
          <Flex alignItems="center" mb={3} pl={sidePad} pr={sidePad} justifyContent="space-between">
            <div>
              <Text.Body size="sm">{copy.LABELS.CROSS_COLLATERAL}</Text.Body>
              <Text.Body size="xs" pr={sidePad} color={colorScheme.content.secondary}>
                {copy.LABELS.CROSS_COLLATERAL_DESCRIPTION}
              </Text.Body>
            </div>
            <Toggle
              data-testid="cross-collateral"
              aria-label={copy.ARIA.CROSS_COLLATERAL_TOGGLE}
              checked={crossCollateral}
              onChange={e => handleCrossCollateral(e.target.checked)}
              disabled={!Boolean(userPermissions.permissions?.canPlaceOrder)}
            />
          </Flex>

          {(crossCollateral || negativeBalances) && (
            <Flex alignItems="center" pl={sidePad} pr={sidePad} justifyContent="space-between" mb={2}>
              <div>
                <Text.Body size="sm">{copy.LABELS.NEGATIVE_BALANCES}</Text.Body>
                <Text.Body size="xs" mb={1} color={colorScheme.content.secondary}>
                  {copy.LABELS.NEGATIVE_BALANCES_DESCRIPTION}
                </Text.Body>
              </div>
              <Toggle
                data-testid="negative-balances"
                aria-label={copy.ARIA.NEGATIVE_BALANCES_TOGGLE}
                checked={negativeBalances}
                onChange={e => handleNegativeBalances(e.target.checked)}
                disabled={!Boolean(userPermissions.permissions?.canPlaceOrder)}
              />
            </Flex>
          )}

          {!crossCollateral && negativeBalances && (
            <SectionMessage statusType="warning" ml={sidePad} mr={sidePad}>
              {copy.MESSAGES.NEGATIVE_BALANCES_STILL_ON}
            </SectionMessage>
          )}
        </Fragment>
      )}

      {typeOfSuccess && (
        <SectionMessage
          statusType="success"
          mt={!editAccount.derivatives && 2}
          ml={sidePad}
          mr={sidePad}
          mb={(defaultError || leverageErrorMessage || collateralErrorMessage) && 1}
          renderButton={
            typeOfSuccess === successTypes.BUTTON && (
              <Button.Secondary onClick={handleOpenTransfers} cta={copy.CTA.TRANSFER_BUTTON}></Button.Secondary>
            )
          }
        >
          {typeOfSuccess === successTypes.DEFAULT ? copy.MESSAGES.SUCCESS : copy.MESSAGES.MAV_SUCCESS}
          {typeOfSuccess === successTypes.BUTTON && (
            <Fragment>
              <br />
              <br />
              {copy.MESSAGES.TRANSFER_SUCCESS}
            </Fragment>
          )}
        </SectionMessage>
      )}

      {defaultError && (
        <SectionMessage
          statusType="alert"
          ml={sidePad}
          mr={sidePad}
          mb={(leverageErrorMessage || collateralErrorMessage) && 1}
        >
          {copy.MESSAGES.DEFAULT_ERROR}
        </SectionMessage>
      )}

      {leverageErrorMessage && (
        <SectionMessage statusType="alert" ml={sidePad} mr={sidePad} mb={collateralErrorMessage && 1}>
          {leverageErrorMessage}
        </SectionMessage>
      )}

      {collateralErrorMessage && (
        <SectionMessage
          statusType="alert"
          ml={sidePad}
          mr={sidePad}
          renderButton={
            <Button.Secondary onClick={handleOpenTransfers} cta={copy.CTA.TRANSFER_BUTTON}></Button.Secondary>
          }
        >
          {collateralErrorMessage}
        </SectionMessage>
      )}

      <Flex justifyContent="flex-end" mt={3} mb={2}>
        <Button.Primary
          cta={copy.CTA.SAVE_BUTTON}
          disabled={unchanged || (!crossCollateral && negativeBalances) || isLoading}
          tone="neutral"
          mr={!isModal && 1.5}
          onClick={handleSubmit}
          loading={isLoading}
        />
      </Flex>
    </Fragment>
  );
};
