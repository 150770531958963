import { Fragment, ReactNode } from "react";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { InterestProvider, ProviderProductMap, YieldProduct } from "@gemini-ui/constants/earn";
import { Colors, Text } from "@gemini-ui/design-system";
import LinkText from "@gemini-ui/design-system/Text/LinkText";
import {
  DEFAULT_EARN_PROVIDER_FULL_NAME,
  DEFAULT_EARN_PROVIDER_NAME,
  GEMINI_STAKING_AGREEMENT_URL,
} from "@gemini-ui/pages/Earn/Deposit/utils";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

interface Props {
  currency: CurrencyShortName;
  totalMoney: any;
  quoteMoney: any;
  isRecurring: boolean;
  geminiEntityLegalName: string;
  provider: InterestProvider;
  intl: IntlShape;
}

export const buyAndEarn = ({
  currency,
  totalMoney,
  quoteMoney,
  isRecurring,
  geminiEntityLegalName,
  provider,
  intl,
}: Props) => {
  const providerName = provider?.name || DEFAULT_EARN_PROVIDER_NAME;
  const productName = ProviderProductMap[provider?.providerType] || YieldProduct.EARN;

  //TODO: These entity names will be provided by the `interestProvider` prop.
  // See: https://gemini-spaceship.atlassian.net/browse/GEM-44004
  const providerEntityName = provider?.fullName || DEFAULT_EARN_PROVIDER_FULL_NAME;

  // TODO: These legal names should be provided in `templateProps.account.geminiEntity` to
  // keep source-of-truth on the backend.
  // See: https://iceland.slack.com/archives/C02C1B55RB2/p1650669446553389?thread_ts=1650645819.191079&cid=C02C1B55RB2

  const recurringOrderTerms = intl.formatMessage(
    defineMessage({
      defaultMessage: `{providerType, select, 
        Staking {staking rewards offered by the blockchain network} 
        other {interest rate offered by {providerName}}
      }`,
    }),
    { providerType: provider?.providerType, providerName }
  );

  return {
    balance: {
      short: (
        <Text.Legal>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>You agree to the terms of this transaction for a {currency} purchase at a Quoted Priced of {quoteMoney}. This order will be funded from your Fiat Account balance. You acknowledge that {currency} purchased in {isRecurring, select, true {each recurring} other {this}} transaction will participate in {productName}, where it will be staked according to the terms of the Gemini Trust Company, LLC Staking Agreement{isRecurring, select, true { and subject to the {recurringOrderTerms} at the time of the recurring transaction} other {}}.",
            }),
            {
              br: () => <br />,
              currency,
              quoteMoney,
              isRecurring,
              productName,
              providerType: provider?.providerType,
              providerEntityName,
              providerName,
              recurringOrderTerms,
            }
          )}
        </Text.Legal>
      ),
      long: (
        <CommonLongEarnDisclaimers
          providerName={providerName}
          productName={productName}
          intl={intl}
          additionalSentences={intl.formatMessage(
            defineMessage({
              defaultMessage: "You authorize {geminiEntityLegalName} to debit your account balance {totalMoney}.",
            }),
            { geminiEntityLegalName, totalMoney }
          )}
        />
      ),
    },
    bank: {
      short: (
        <Text.Legal>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>You agree to the terms of this transaction for a {currency} purchase at a Quoted Price of {quoteMoney}. You authorize {geminiEntityLegalName} to debit {totalMoney} from your bank account to fund this order. You acknowledge that {currency} purchased in {isRecurring, select, true {each recurring} other {this}} transaction will participate in {productName}, where it will be staked according to the terms of the Gemini Trust Company, LLC Staking Agreement{isRecurring, select, true { and subject to the {recurringOrderTerms} at the time of the recurring transaction} other {}}.",
            }),
            {
              br: () => <br />,
              currency,
              quoteMoney,
              geminiEntityLegalName,
              totalMoney,
              isRecurring,
              productName,
              providerType: provider?.providerType,
              providerEntityName,
              providerName,
              recurringOrderTerms,
            }
          )}
        </Text.Legal>
      ),
      long: (
        <Fragment>
          <CommonLongEarnDisclaimers providerName={providerName} productName={productName} intl={intl} />
          <br />
          <Text.Legal>
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "You authorize {geminiEntityLegalName} to debit your bank account for {totalMoney} via Bank Transfer (ACH) and, if necessary, to initiate credit entries/ adjustments for any debits made in error to your account at the above Financial Institution. You acknowledge that the origination of ACH transactions to your account comply with the provisions of U.S. law. You agree that this authorization cannot be revoked.",
              }),
              { geminiEntityLegalName, totalMoney }
            )}
          </Text.Legal>
          <br />
          <Text.Legal>
            {intl.formatMessage({
              defaultMessage:
                "Your deposit will be credited to your Gemini account and will be applied to your trade immediately. Your ACH deposit should fully clear into your Gemini account within 4-5 business days.",
            })}
          </Text.Legal>
        </Fragment>
      ),
    },
    card: {
      short: (
        <Text.Legal>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>You agree to the terms of this transaction for a {currency} purchase at a Quoted Price of {quoteMoney}. You authorize {totalMoney} to be debited from your bank account via your debit card{isRecurring, select, true { on a recurring basis} other {}}. You acknowledge that {currency} purchased in {isRecurring, select, true {each recurring} other {this}} transaction will participate in {productName}, where it will be staked according to the terms of the Gemini Trust Company, LLC Staking Agreement{isRecurring, select, true { and subject to the {recurringOrderTerms} at the time of the recurring transaction} other {}}.",
            }),
            {
              br: () => <br />,
              currency,
              quoteMoney,
              totalMoney,
              isRecurring,
              productName,
              providerType: provider?.providerType,
              providerEntityName,
              providerName,
              recurringOrderTerms,
            }
          )}
        </Text.Legal>
      ),
      long: (
        <Fragment>
          <CommonLongEarnDisclaimers providerName={providerName} productName={productName} intl={intl} />
          <br />
          <Text.Legal>
            {intl.formatMessage(
              defineMessage({
                defaultMessage:
                  "You authorize {geminiEntityLegalName} to debit your bank account for {totalMoney} via your debit card and, if necessary, to initiate credit entries/ adjustments for any debits made in error to your account at the above Financial Institution. You acknowledge that the origination of debit card transactions to your account comply with the provisions of U.S. law. You agree that this authorization cannot be revoked.",
              }),
              { geminiEntityLegalName, totalMoney }
            )}
          </Text.Legal>
          <br />
          <Text.Legal>
            {intl.formatMessage({
              defaultMessage:
                "Your deposit will be credited to your Gemini account and will be applied to your trade immediately. Your debit card deposit should fully clear into your Gemini account within 1 hour.",
            })}
          </Text.Legal>
        </Fragment>
      ),
    },
    paypal: {
      short: (
        <Text.Legal>
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>By clicking “confirm,” you {isRecurring, select, true {authorize {geminiEntityLegalName} to charge your PayPal account for up to {totalMoney} (the recurring purchase amount) on the frequency described above. Your authorization will remain in effect until canceled, and you may cancel it at any time in your portfolio} other {agree to the terms of this transaction}}. You also acknowledge that {currency} purchased in this transaction will participate in {productName}, where it will be staked according to the terms of the Gemini Trust Company, LLC Staking Agreement.",
            }),
            {
              br: () => <br />,
              currency,
              quoteMoney,
              totalMoney,
              isRecurring,
              geminiEntityLegalName,
              productName,
              providerType: provider?.providerType,
              providerEntityName,
              providerName,
            }
          )}
        </Text.Legal>
      ),
      long: (
        <Fragment>
          <CommonLongEarnDisclaimers providerName={providerName} productName={productName} intl={intl} />
        </Fragment>
      ),
    },
  };
};

const CommonLongEarnDisclaimers = ({ providerName, productName, intl, additionalSentences = null }) => {
  return (
    <Fragment>
      <br />
      <Text.Legal>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Please refer to the <AgreementLink>Staking Agreement</AgreementLink> for a complete description of {productName}.",
          }),
          {
            AgreementLink: (v: ReactNode) => (
              <LinkText
                href={GEMINI_STAKING_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
                color={Colors.gray[500]}
                data-testid="staking-agreement-link"
                underline
              >
                {v}
              </LinkText>
            ),
            productName,
            providerName,
          }
        )}
      </Text.Legal>
      <br />
      <Text.Legal>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Orders placed on Gemini are filled immediately against resting orders on the Gemini Exchange at the current best available price. By confirming this order, you accept the risk of price movement.{withAdditionalSentences, select, true { {additionalSentences}} other {}}",
          }),
          {
            withAdditionalSentences: Boolean(additionalSentences),
            additionalSentences,
          }
        )}
      </Text.Legal>
    </Fragment>
  );
};
