import { useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import lottie from "lottie-web/build/player/lottie_light";
import { useInterval } from "react-use";
import {
  DELAY,
  INITIAL_FRAME,
  INITIAL_NOTIFICATION_STATE,
  NotificationCountResponse,
  NOTIFICATIONS_ENDPOINT,
  UNREAD_COUNT_COOKIE_NAME,
} from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import {
  AnimationContainer,
  PositionedBadge,
} from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationCenterButton/styles";
import { Flex } from "@gemini-ui/design-system";
import notificationCenterBell from "@gemini-ui/images/animations/notification-center-bell.json";
import axios from "@gemini-ui/services/axios";

const INITIAL_STATE = {
  isLoading: true,
  notificationCount: INITIAL_NOTIFICATION_STATE.notificationCount,
  showNotificationBadge: false,
};

interface NotificationCenterButtonState {
  isLoading: boolean;
  notificationCount: NotificationCountResponse;
  showNotificationBadge: boolean;
}

interface NotificationCenterButtonProps {
  canShowNotificationBadge: boolean;
  "data-testid"?: string;
}

export function NotificationCenterButton({
  canShowNotificationBadge,
  ["data-testid"]: dataTestId,
}: NotificationCenterButtonProps) {
  const animationRef = useRef<HTMLDivElement>(null);

  const [notificationCenterButtonState, setNotificationCenterButtonState] =
    useState<NotificationCenterButtonState>(INITIAL_STATE);

  const { isLoading, notificationCount, showNotificationBadge } = notificationCenterButtonState;
  const { unreadCount, unreadMore } = notificationCount;

  // To display # of notifications; "99+" if count > 99, hidden if user opens panel & then closes
  let unreadCountStr = unreadCount && unreadCount > 0 ? String(unreadCount) : "";
  if (unreadMore) unreadCountStr = `${unreadCount}+`;

  const getNotificationBadge = () => {
    if (unreadCountStr.length > 0) {
      return (
        <PositionedBadge
          data-testid="notification-center-count"
          size="xs"
          status="alert"
          variant="counter"
          bordered
          aria-live="polite"
        >
          {unreadCountStr}
        </PositionedBadge>
      );
    } else return null;
  };

  const fetchNotificationCountData = useCallback(async () => {
    try {
      const res = await axios.get<NotificationCountResponse>(`${NOTIFICATIONS_ENDPOINT}/count`, {
        withCredentials: true,
      });

      if (res && res.data)
        setNotificationCenterButtonState({
          isLoading: false,
          notificationCount: res.data,
          showNotificationBadge: true,
        });
    } catch (e) {
      Sentry.captureException(e);

      setNotificationCenterButtonState(prev => ({ ...prev, isLoading: false, showNotificationBadge: true }));
    }
  }, []);

  /**
   * Polling for the notification count to be refreshed & reflected on the bell icon when
   * the notification center panel is closed
   */
  useInterval(() => {
    if (canShowNotificationBadge) fetchNotificationCountData();
  }, DELAY);

  /**
   * The polling will fetch after the delay but we need to fetch on initial load as well,
   * or if a reset has been triggered when closing the panel. So this handles that and
   * prevents the bell from animating or showing the count badge until the count has
   * in fact been updated
   */
  useEffect(() => {
    if (canShowNotificationBadge) {
      if (isLoading || !showNotificationBadge) fetchNotificationCountData(); // On initial load or when the panel was closed
    } else setNotificationCenterButtonState(prev => ({ ...prev, showNotificationBadge: false }));
  }, [canShowNotificationBadge, fetchNotificationCountData, isLoading, showNotificationBadge]);

  /**
   * Ring notification bell animation; plays at the start of a new session when user signs in
   */
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: notificationCenterBell,
    });
    // only looking for presence of the cookie, don't need to parse the string
    if (showNotificationBadge && unreadCount > 0 && !Cookies.get(UNREAD_COUNT_COOKIE_NAME)) {
      animation.play();
      Cookies.set(UNREAD_COUNT_COOKIE_NAME, unreadCount.toString());
    } else animation.goToAndStop(INITIAL_FRAME, true);

    animation.addEventListener("complete", () => animation.goToAndStop(INITIAL_FRAME, true));

    return () => {
      animation.removeEventListener("complete", () => animation.goToAndStop(INITIAL_FRAME, true));
      animation.destroy(); // Cleanup to prevent duplicates
    };
  }, [showNotificationBadge, unreadCount]);

  return (
    <Flex data-testid={dataTestId}>
      <AnimationContainer ref={animationRef} />
      {showNotificationBadge && getNotificationBadge()}
    </Flex>
  );
}
