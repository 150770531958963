import { FunctionComponent, PropsWithChildren, SVGAttributes } from "react";
import { useTheme } from "@emotion/react";
import { Colors } from "@gemini-ui/design-system/primitives";

export default function create(
  childrenAsFn: (props: { black: string; white: string; contentPrimary: string; bgPrimary: string }) => JSX.Element
): FunctionComponent<PropsWithChildren<SVGAttributes<SVGElement>>> {
  return props => {
    const { colorScheme } = useTheme();

    return (
      <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        {childrenAsFn({
          black: Colors.black,
          white: Colors.white,
          contentPrimary: colorScheme.content.primary,
          bgPrimary: colorScheme.background.primary,
        })}
      </svg>
    );
  };
}
