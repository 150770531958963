import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { GrowAsset, GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, SectionMessage, Spacing } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { getMaxNumValidators } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/utils";
import { SourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { CurrencyAmountInputs } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/CurrencyAmountInputs";
import { ValidatorAmountInputs } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/ValidatorAmountInputs";
import { DepositFormFields } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositForm/constants";
import { StakingPresetFunding } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/constants";
import { FundingSource } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingFundingSource/FundingSource";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType, SupportedStakingPaymentMethod } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { DepositFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingDepositForm";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { ETH_PER_VALIDATOR } from "@gemini-ui/pages/Earn/utils";
import { useIntl } from "@gemini-ui/utils/intl";

const StakingDepositForm = ({
  asset,
  formController,
  onSubmit,
  lastTradePrice,
  isLoadingFundingSources,
  onFundingSourceClick,
  selectedFundingSource,
}: {
  asset: GrowAsset;
  formController: UseFormReturn<DepositFormValues>;
  onSubmit: (data: DepositFormValues) => void;
  lastTradePrice: string;
  isLoadingFundingSources?: boolean;
  onFundingSourceClick?: () => void;
  selectedFundingSource?: SelectOptionProps<SupportedStakingPaymentMethod>;
}) => {
  const { intl } = useIntl();
  const { depositStatus: depositStatusState } = useGrowBuy();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const { currency } = asset;

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = formController;
  const { amount, providerType, paymentMethodId, validatorCount } = formController.watch();

  const currenciesForInputDisplay: [CurrencyShortName, CurrencyShortName] = useMemo(() => {
    let currencies: [CurrencyShortName, CurrencyShortName] = [currency, defaultFiat];
    if (paymentMethodId !== SourceType.TRADING_BALANCE) {
      currencies = [defaultFiat, currency];
    }
    return currencies;
  }, [paymentMethodId, currency, defaultFiat]);

  const maxNumValidators = getMaxNumValidators(asset, ETH_PER_VALIDATOR);

  return (
    <form data-testid={testIds.buyModule.screens.placeDeposit} onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection="column" gap={Spacing.scale[2]}>
        {providerType === GrowProviderType.PRIVATE_STAKING ? (
          <ValidatorAmountInputs
            key={`deposit-${currency}-validator-input`}
            currency={currency}
            maxValidators={maxNumValidators}
            controls={{
              amount: {
                name: DepositFormFields.AMOUNT,
                control: control,
              },
              validatorCount: {
                name: DepositFormFields.VALIDATOR_COUNT,
                control: control,
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Enter a valid amount" }),
                },
              },
            }}
          />
        ) : (
          <CurrencyAmountInputs
            key={`deposit-${currenciesForInputDisplay[0]}-amount-input`}
            currency={currency}
            displayCurrencies={currenciesForInputDisplay}
            fundingType={
              paymentMethodId === SourceType.TRADING_BALANCE ? StakingPresetFunding.CRYPTO : StakingPresetFunding.FIAT
            }
            lastTradePrice={lastTradePrice}
            maxValue={asset.availableForEarningInterest.value}
            controls={{
              amount: {
                name: DepositFormFields.AMOUNT,
                control: control,
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Enter a valid amount" }),
                },
              },
            }}
          />
        )}

        {depositStatusState?.error && (
          <SectionMessage statusType="alert">
            {depositStatusState?.error ||
              intl.formatMessage({ defaultMessage: "Something went wrong. Please try again." })}
          </SectionMessage>
        )}

        <FundingSource
          isLoading={isLoadingFundingSources}
          isDisabled={providerType === GrowProviderType.PRIVATE_STAKING}
          onClick={onFundingSourceClick}
          transactionType={GrowTransactionType.STAKE}
          {...selectedFundingSource}
        />

        <Button.Secondary
          data-testid={testIds.buyModule.form.reviewBtn}
          type="submit"
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Review" })}
          disabled={!(isDirty && (Number(amount) || Number(validatorCount)) && paymentMethodId)}
        />
      </Flex>
    </form>
  );
};

export default StakingDepositForm;
