import { FC, Fragment, PropsWithChildren } from "react";
import { IconChevronRightSmall } from "@hubble/icons";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { PaymentMethodItem } from "@gemini-ui/components/PaymentMethodItem";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { OrderQuote } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { PaymentMethodButton, PaymentMethodButtonError } from "@gemini-ui/pages/RetailTrade/PaymentMethod/styles";
import { handleDebitErrors } from "@gemini-ui/pages/RetailTrade/PaymentMethod/utils";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { Limit } from "@gemini-ui/services/transfer/types";
import { DebitCardType } from "@gemini-ui/transformers/PaymentMethods";
import { useIntl } from "@gemini-ui/utils/intl";

interface DebitCardMethodProps {
  onClick: () => void;
  selectedDebitCard: DebitCardType;
  orderQuote: OrderQuote;
  limit: Limit;
  quoteError?: string;
}
const DebitCardMethod: FC<PropsWithChildren<DebitCardMethodProps>> = props => {
  const { intl } = useIntl();

  const { onClick, selectedDebitCard, orderQuote, limit } = props;

  const { hasError, error } = handleDebitErrors(orderQuote, limit.minimum, intl, props.quoteError);

  return (
    <Fragment>
      {hasError && <PaymentMethodButtonError>{error}</PaymentMethodButtonError>}
      <PaymentMethodButton
        data-testid={testIds.buySell.paymentMethodBtn}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        error={hasError}
      >
        <PaymentMethodItem
          displayText={
            <PaymentMethodName
              institutionName={selectedDebitCard.cardIssuer}
              rawName={selectedDebitCard.displayName}
              accountType={intl.formatMessage({
                defaultMessage: "Debit",
              })}
              lastFour={selectedDebitCard.lastFour}
            />
          }
          icon={
            <PaymentMethodIcon
              cardIssuer={selectedDebitCard.cardIssuer}
              paymentMethodType={PaymentMethodType.DEBIT_CARD}
              size="md"
            />
          }
        />
        <IconChevronRightSmall />
      </PaymentMethodButton>
    </Fragment>
  );
};

export default DebitCardMethod;
