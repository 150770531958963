import { Fragment, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Card, List, ListItem, Text } from "@gemini-ui/design-system";
import {
  EditAddressModal,
  EditDateOfBirthModal,
  SubmitButton,
} from "@gemini-ui/pages/Credit/CreditApplication/components";
import { FormValues } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const SSN_MASK = "XXX-XX-";

enum ModalState {
  EDIT_ADDRESS,
  EDIT_DATE_OF_BIRTH,
  NONE,
}

interface PrefillSuccessInfoProps {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
  email: string;
}

export const PrefillSuccessInfo = ({ initialValues, onSubmit, isSubmitting, email }: PrefillSuccessInfoProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  const [modalState, setModalState] = useState<ModalState>(ModalState.NONE);
  const [values, setValues] = useState<FormValues>(initialValues);
  const { firstName, middleName, lastName, street, apt, city, state, zip, dob, ssn, phone } = values;
  const name = `${firstName} ${middleName ? middleName : ""} ${lastName}`;
  const addressLine1 = `${street}${apt ? ` ${apt}` : ""}`;
  const addressLine2 = `${city}, ${state} ${zip}`;

  const onClose = () => setModalState(ModalState.NONE);

  const onSubmitEdit = (values: FormValues) => {
    setValues(currState => ({ ...currState, ...values }));
    setModalState(ModalState.NONE);
  };

  const { socureId } = initialValues;
  useEffect(() => {
    if (socureId) {
      setValues(currState => ({ ...currState, socureId }));
    }
  }, [socureId]);

  return (
    <Fragment>
      <Card variant="filled" padding="none">
        <List>
          <ListItem size="dense" padding="sm" alignItems="center">
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Legal name" })}</Text.Body>
            <Text.Body>{name}</Text.Body>
          </ListItem>
          <ListItem
            size="dense"
            padding="sm"
            alignItems="center"
            right={
              <Button.Secondary
                data-testid={testIds.button.editAddress}
                size="sm"
                onClick={() => setModalState(ModalState.EDIT_ADDRESS)}
              >
                {intl.formatMessage({ defaultMessage: "Edit" })}
              </Button.Secondary>
            }
          >
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Primary address" })}</Text.Body>
            <Text.Body>{addressLine1}</Text.Body>
            <Text.Body>{addressLine2}</Text.Body>
          </ListItem>
          <ListItem
            size="dense"
            padding="sm"
            alignItems="center"
            right={
              <Button.Secondary
                data-testid={testIds.button.editDateOfBirth}
                size="sm"
                onClick={() => setModalState(ModalState.EDIT_DATE_OF_BIRTH)}
              >
                {intl.formatMessage({ defaultMessage: "Edit" })}
              </Button.Secondary>
            }
          >
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Date of birth" })}</Text.Body>
            <Text.Body>{dob}</Text.Body>
          </ListItem>
          <ListItem size="dense" padding="sm">
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Social security number" })}</Text.Body>
            <Text.Body>
              {SSN_MASK}
              {ssn.slice(-4)}
            </Text.Body>
          </ListItem>
          <ListItem size="dense" padding="sm">
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Mobile phone number" })}</Text.Body>
            <Text.Body>{phone}</Text.Body>
          </ListItem>
          <ListItem size="dense" padding="sm">
            <Text.Body bold>{intl.formatMessage({ defaultMessage: "Email address" })}</Text.Body>
            <Text.Body>{email}</Text.Body>
          </ListItem>
        </List>
      </Card>
      <Text.Body mt={3} mb={1} color={colorScheme.content.secondary}>
        {intl.formatMessage({
          defaultMessage: `You are providing 'written instructions' to WebBank under the Fair Credit Reporting Act 
            authorizing WebBank to obtain information from your personal credit profile or other information from one 
            or more credit reporting agencies ("Consumer Report"). You authorize WebBank, Gemini Constellation, and 
            their agents to obtain your Consumer Report to validate your identity and to determine if you are prequalified 
            for credit options.`,
        })}
      </Text.Body>
      <Text.Body color={colorScheme.content.secondary}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage: "By submitting your info, you agree to our {link}. This will not affect your credit score.",
          }),
          {
            link: (
              <Text.Link
                href="https://www.gemini.com/legal/credit-report-authorization"
                target="_blank"
                color={colorScheme.content.secondary}
              >
                Credit Report Authorization
              </Text.Link>
            ),
          }
        )}
      </Text.Body>
      <SubmitButton loading={isSubmitting} onClick={() => onSubmit(values)} disabled={modalState !== ModalState.NONE}>
        {intl.formatMessage({ defaultMessage: "Pre-qualify" })}
      </SubmitButton>
      {modalState === ModalState.EDIT_ADDRESS && (
        <EditAddressModal
          onClose={onClose}
          street={street}
          apt={apt}
          city={city}
          state={state}
          zip={zip}
          onSubmit={onSubmitEdit}
        />
      )}
      {modalState === ModalState.EDIT_DATE_OF_BIRTH && (
        <EditDateOfBirthModal onClose={onClose} dob={dob} onSubmit={onSubmitEdit} />
      )}
    </Fragment>
  );
};
