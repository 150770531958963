import { InputAmount } from "@gemini-ui/components/InputAmount";
import { usePageData } from "@gemini-ui/contexts";
import { Card } from "@gemini-ui/design-system";
import { PresetDepositAmountButton, PresetDepositButtonsContainer } from "@gemini-ui/pages/Earn/Deposit/styles";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const CURRENCY_FORMATTER_STYLE = "currency";

type Props = {
  isBuying: boolean;
  isAmountInputDisabled: boolean;
  values: any; // wish this was typed :(
  touched: any; // wish this was typed :(
  errors: any; // wish this was typed :(

  quoteError: any; // wish this was typed :(

  showHelperText: () => React.ReactNode;

  amountChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;

  setAmountWithAvailableBalancePercent: (val: number) => void;
  setAmountWithAvailableBalanceFiat: (val: number) => void;
};

export const PooledStakingAmountInput = ({
  isBuying,
  isAmountInputDisabled,
  quoteError,
  values,
  touched,
  errors,
  setAmountWithAvailableBalanceFiat,
  setAmountWithAvailableBalancePercent,
  amountChangeHandler,
  showHelperText,
}: Props) => {
  const { intl, locale } = useIntl();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const { currency } = useGrowBuy();

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: CURRENCY_FORMATTER_STYLE,
    currency: defaultFiat,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const formatCurrency = (amount: number) => currencyFormatter.format(amount);
  const currencySign = currencyFormatter.formatToParts(0)[0]?.value;

  return (
    <Card variant="filled" padding="none" p={2}>
      <InputAmount
        data-testid={testIds.deposit.placeDeposit.amountInput}
        inputSize="lg"
        label={intl.formatMessage(
          defineMessage({ defaultMessage: "{isBuying, select, true {Stake amount} other {Transfer amount}}" }),
          {
            isBuying,
          }
        )}
        leftElement={isBuying ? currencySign : null}
        currency={isBuying ? defaultFiat : null}
        name="amount"
        type="number"
        value={values?.amount}
        placeholder="0"
        rightElement={!isBuying ? currency : defaultFiat}
        onChange={amountChangeHandler}
        error={quoteError || (touched?.amount && errors?.amount)}
        message={showHelperText()}
        disabled={isAmountInputDisabled}
      />

      {isBuying ? (
        <PresetDepositButtonsContainer>
          <PresetDepositAmountButton
            data-testid={testIds.deposit.placeDeposit.amountInputPresetOneButton}
            onClick={() => setAmountWithAvailableBalanceFiat(100)}
            cta={formatCurrency(100)}
            size="sm"
          />
          <PresetDepositAmountButton
            data-testid={testIds.deposit.placeDeposit.amountInputPresetTwoButton}
            onClick={() => setAmountWithAvailableBalanceFiat(250)}
            cta={formatCurrency(250)}
            size="sm"
          />
          <PresetDepositAmountButton
            data-testid={testIds.deposit.placeDeposit.amountInputPresetThreeButton}
            onClick={() => setAmountWithAvailableBalanceFiat(500)}
            cta={formatCurrency(500)}
            size="sm"
          />
        </PresetDepositButtonsContainer>
      ) : (
        <PresetDepositButtonsContainer>
          <PresetDepositAmountButton
            data-testid={testIds.deposit.placeDeposit.amountInputHalfButton}
            onClick={() => setAmountWithAvailableBalancePercent(0.5)}
            cta={intl.formatMessage({ defaultMessage: "1/2" })}
            size="sm"
            css={{ width: "100%" }}
          />
          <PresetDepositAmountButton
            data-testid={testIds.deposit.placeDeposit.amountInputMaxButton}
            onClick={() => setAmountWithAvailableBalancePercent(1)}
            cta={intl.formatMessage({ defaultMessage: "Max" })}
            size="sm"
            css={{ width: "100%" }}
          />
        </PresetDepositButtonsContainer>
      )}
    </Card>
  );
};
