export const DATA_IDS = {
  PRE_ASSET: {
    LOADING: "pre-asset-loading",
    ERROR: "pre-asset-error",
    RETRY: "pre-asset-retry",
  },
  SELECT_ASSETS: {
    SEARCHABLE_INPUT: "select-assets-searchable-input",
    NO_BALANCE: "select-assets-no-balance",
  },
  CONFIRMATION_TABLE: {
    ADDRESS_LABEL: "address_label",
    ADDRESS: "address",
    EDIT_EXCHANGE: "edit_exchange",
    EDIT_ENTITY: "edit_entity",
    EDIT_COUNTRY: "edit_country",
    SKELETON_LOADER: "skeleton_loader",
    MEMO: "memo",
    NETWORK: "network",
    VASP_ID: "vasp_id",
    ATTESTATION_TYPE: "attestation_type",
    COUNTRY: "country",
    FEE: "fee",
    MAX_FEE: "max_fee",
    AMOUNT: "amount",
    TOTAL: "total",
    CONTINUE: "continue",
  },
  SELECT_APPROVED_ADDRESS: {
    SEARCHABLE_INPUT: "select-approved-address-searchable-input",
    EMPTY_STATE: "empty-state",
  },
  NON_WHITELISTED_ADDRESS: {
    EDIT_ADDRESS: "edit-non-whitelisted-address",
    CLOSE_ICON: "close-icon",
    SELECTED_ADDRESS: "selected-address",
    CLIPBOARD_ADDRESS: "clipboard-address",
  },
  ASSET_ERROR: {
    GO_BACK: "asset-error-go-back",
    TRANSACTION_DISABLED: "transaction-disabled",
    CONTENT: "asset-error-content",
  },
  UK_ATTESTATION: {
    CONTENT: "uk-attestation-content",
  },
  CWB: {
    CONTENT: "cwb-content",
  },
  AMOUNT_WITHDRAWAL_SCREEN: {
    AMOUNT_INPUT: "amount-input",
    CONTINUE_BUTTON: "continue-button",
    DESTINATION_ADDRESS_CTA: "destination-address-cta",
    DESTINATION_ADDRESS_CARD: "destination-address-card",
    NOTIONAL_VALUE: "notional-value",
    AVAILABLE_FOR_WITHDRAWAL: "available-for-withdrawal",
    MAX_BUTTON: "max-button",
  },
  MAS_ATTESTATION: {
    SUBMIT_ATTESTATION_BUTTON: "submit-attestation-button",
    DECLINE_ATTESTATION_BUTTON: "decline-attestation-button",
    ATTESTATION_DONE_BUTTON: "address-attested-done-button",
  },
  NEW_ADDRESS_FLOW: {
    CONFIRM: "new-address-flow-confirm",
    DECLINE: "new-address-flow-decline",
    UK_SELECT_REASON_CONTINUE: "uk-select-reason-continue",
    UK_FEEDBACK_CONFIRM: "uk-feedback-confirm",
    UK_FEEDBACK_DECLINE: "uk-feedback-decline",
  },
  ADD_MEMO: {
    INPUT: "memo-input",
    CLIPBOARD: "clipboard",
  },
  WITHDRAWAL_WARNING: {
    UNDERSTAND_BUTTON: "understand-button",
    LEARN_MORE: "learn-more",
    CONTENT: "withdrawal-warning-content",
  },
  WITHDRAW_APPROVAL: {
    WITHDRAW_CANCEL_BUTTON: "withdraw-button-cancelWithdraw",
    WITHDRAW_CONFIRM_BUTTON: "withdraw-button-confirmWithdraw",
  },
  COMPLETE_WITHDRAWAL: {
    COMPLETE_WITHDRAWAL_CONTENT: "complete-withdrawal-content",
  },
  WITHDRAWAL_SUCCESS: {
    WITHDRAWAL_SUCCESS_CONTENT: "withdrawal-success-content",
    DONE: "withdrawal-success-done",
    VIEW_DETAILS: "withdrawal-success-view-details",
  },
};

export const DATA_LABELS = {
  ASSET_ERROR: {
    LOCKOUT_LABEL: "Lockout restriction",
    DEPOSITS_NOT_SUPPORTED_LABEL: "Deposits not supported",
    TRANSACTION_DISABLED_LABEL: "Transaction disabled",
  },
};
export const ARIA_LABELS = {
  ASSET_SEARCH: "asset-search",
  APPROVED_ADDRESS_SEARCH: "approved-address-search",
  AMOUNT_WITHDRAWAL_SCREEN: {
    DESTINATION_ADDRESS_CTA: "destination-address-cta",
    AMOUNT_INPUT: "amount-input",
  },
};

export const AMOUNT_KEY = "amount";
export const CRYPTO_WITHDRAW_STATES = {
  LOADING: "LOADING",
  SELECT_ASSETS: "SELECT_ASSETS",
  DATA_LOAD_ERROR: "DATA_LOAD_ERROR",
  ASSETS_ERROR: "ASSETS_ERROR",
  AMOUNT_SCREEN: "AMOUNT_SCREEN",
  SELECT_APPROVED_ADDRESS: "SELECT_APPROVED_ADDRESS",
  NON_WHITELISTED_ADDRESS: "NON_WHITELISTED_ADDRESS",
  ADDRESS_MEMO: "ADDRESS_MEMO",
  WITHDRAWAL_WARNING: "WITHDRAWAL_WARNING",
  UK_ATTESTATION: "UK_ATTESTATION",
  MAS_ATTESTATION: "MAS_ATTESTATION",
  NEW_ADDRESS_FLOW: "NEW_ADDRESS_FLOW",
  COMPLETE_WITHDRAWAL: "COMPLETE_WITHDRAWAL",
  WITHDRAWAL_SUCCESS: "WITHDRAWAL_SUCCESS",
} as const;

export const CRYPTO_WITHDRAW_EVENTS = {
  FETCH_INITIAL_DATA: "FETCH_INITIAL_DATA",
  GO_BACK: "GO_BACK",
  SET_CURRENCY_AND_ADDRESS_CONTROL: "SET_CURRENCY_AND_ADDRESS_CONTROL",
  GO_TO_DATA_LOAD_ERROR: "GO_TO_DATA_LOAD_ERROR",
  SET_ADDRESS_CONTROLS: "SET_ADDRESS_CONTROLS",
  ON_ADDRESS_CHANGE: "ON_ADDRESS_CHANGE",
  GO_TO_ADDRESS_MEMO: "GO_TO_ADDRESS_MEMO",
  ON_ATTESTATION_CHANGE: "ON_ATTESTATION_CHANGE",
  ADD_ACKNOWLEDGEMENT: "ADD_ACKNOWLEDGEMENT",
  ON_AMOUNT_CHANGE: "ON_AMOUNT_CHANGE",
  RESET_ADDRESS: "RESET_ADDRESS",
  ON_ADDRESS_CHANGE_IN_ADDRESS_MEMO: "ON_ADDRESS_CHANGE_IN_ADDRESS_MEMO",
  GO_TO_UK_ATTESTATION: "GO_TO_UK_ATTESTATION",
  SET_AMOUNT: "SET_AMOUNT",
  ON_WITHDRAW_SUCCESS: "ON_WITHDRAW_SUCCESS",
} as const;
export const ADDRESS_TYPE = {
  MEMO: "memo",
  ADDRESS: "address",
  TAG: "tag",
} as const;

export const ADDRESS_FLOW_SOURCE = {
  ASSETS: "ASSETS",
  AMOUNT_ADDRESS: "AMOUNT_ADDRESS",
  AMOUNT_TAG: "AMOUNT_TAG",
} as const;

export enum WithdrawalAuthyPushStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  SUCCESS = "Success",
  FALLBACK = "Fallback",
  DENIED = "Denied",
}

export const FINAL = "final" as const;

export const CRYPTO_WITHDRAW_MIXPANEL_EVENTS = {
  START: {
    name: "Withdraw - Start",
    properties: {
      INITIATED_FROM: "Initiated From",
    },
  },
};
export const MIXPNAEL_PROPERTY_VALUE = {};
