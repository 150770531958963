import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, CancelTokenSource } from "axios";
import { HEADERS } from "@gemini-ui/services/constants";

export enum ErrorCode {
  SERVER_ERROR = "SERVER_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  REQUEST_ERROR = "REQUEST_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR",
}

enum ErrorStatus {
  TEMP_REDIRECT = 307,
  PERM_REDIRECT = 308,
}

const UNKNOWN_URL = "unknown";

interface Refresher {
  requestRefresh<T = any>(url: string): AxiosPromise<T>;
}

export const AXIOS_BASE_CONFIG: AxiosRequestConfig = {
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Csrf-Token": "nocheck",
  },
};

const instance: AxiosInstance = axios.create(AXIOS_BASE_CONFIG);

export function credentialsRequestInterceptor(config) {
  // creditcard -> exchange requests should be made with credentials
  if (window.location.host.includes("creditcard.") && !config.baseURL.includes("creditcard.")) {
    // Remove the creditcard subdomain from the request
    const cleanHost = window.location.host.split("creditcard.")[1];
    // Set the baseURL to https://exchange.gemini.com
    config.baseURL = `https://${cleanHost}`;
    config.withCredentials = true;
  }
  // exchange -> creditcard requests should be made with credentials
  if (
    window.location.host.includes("exchange.") &&
    !window.location.host.includes("creditcard.") &&
    config.url.includes("credit-card")
  ) {
    config.withCredentials = true;
  }

  return config;
}

export function loginRedirectInterceptor(error: AxiosError<{ error?: string; redirect?: string }>) {
  const response = error.response;
  error.code = getErrorCode(error);
  if (response && response.data && response.data.error === "needsLogin") {
    // Redirect users who have been logged out
    // Note: return so the code doesn't fall through to the reject call
    // Otherwise it will throw an unhandled error before directing
    window.location.assign(response.data.redirect);
    return Promise.reject(error);
  }

  const status = error?.response?.status;
  if (status === ErrorStatus.TEMP_REDIRECT || status === ErrorStatus.PERM_REDIRECT) {
    // retry originalRequest at newLocation
    const newLocation = error?.response?.headers?.location;
    const originalRequest = error.config;
    if (location) {
      return this[originalRequest.method](newLocation, originalRequest);
    }
  }

  return Promise.reject(error);
}

instance.interceptors.request.use(credentialsRequestInterceptor);
instance.interceptors.response.use(undefined, loginRedirectInterceptor.bind(instance));
// sourced from: https://github.com/axios/axios/issues/164#issuecomment-327837467
instance.interceptors.response.use(undefined, function axiosRetryInterceptor(error: AxiosError) {
  const config = error.config;
  // If config does not exist or the retry option is not set, or we are in jest tests, reject
  if (!config || !config.retry || process.env.NODE_ENV === "test") return Promise.reject(error);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(error);
  }

  // Increase the retry count
  config.__retryCount += 1;

  const backOffDelay = Boolean(config.retry) ? (1 / 2) * (Math.pow(2, config.__retryCount) - 1) * 500 : 1;
  // Create new promise to handle exponential backoff
  const backoff = new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, backOffDelay);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(() => {
    return instance(config);
  });
});

// Better error messages for error tracking in Sentry
instance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.config && error.response) {
      const { url, method } = error.config;
      const { status } = error.response;

      // Strip query parameters from the URL
      const strippedUrl = url ? url.split("?")[0] : UNKNOWN_URL;

      // Create the custom error message
      const customMessage = `${strippedUrl} ${method?.toUpperCase()} ${status}`;

      // Modify the error object
      error.message = customMessage;
    }

    return Promise.reject(error);
  }
);

const getErrorCode = (error: AxiosError): ErrorCode => {
  if (error.response) {
    // Non 2xx response received
    return ErrorCode.SERVER_ERROR;
  } else if (error.request) {
    if (error.code === "ECONNABORTED") {
      // Request timeout
      return ErrorCode.TIMEOUT_ERROR;
    } else {
      // No response received
      return ErrorCode.NETWORK_ERROR;
    }
  } else if (axios.isCancel(error)) {
    // Response was cancelled by the client
    return ErrorCode.CANCEL_ERROR;
  } else {
    // Error setting up request
    return ErrorCode.REQUEST_ERROR;
  }
};

export default instance;

const CANCELLED_REQUEST = "Canceled in-flight request.";

export const createRefresher = (): Refresher => {
  let cancelToken: CancelTokenSource | null;

  return {
    requestRefresh: url => {
      // Cancel in flight request if applicable
      if (cancelToken) cancelToken.cancel(CANCELLED_REQUEST);

      cancelToken = axios.CancelToken.source();

      return instance
        .get(url, {
          cancelToken: cancelToken.token,
          headers: {
            [HEADERS.REFRESH_ONLY]: "refresh",
          },
        })
        .then(res => {
          // If the request completes cleanup the cancel promise
          if (cancelToken) cancelToken.cancel();
          return res;
        });
    },
  };
};

export const getDedupedRequest = (method: AxiosRequestConfig["method"]) => {
  let cancelToken: CancelTokenSource | null;
  return <T = any>(url: string, config: AxiosRequestConfig): AxiosPromise<T> => {
    if (cancelToken) cancelToken.cancel(CANCELLED_REQUEST);
    cancelToken = axios.CancelToken.source();
    return instance.get(url, { cancelToken: cancelToken.token, method, ...config }).then(res => {
      if (cancelToken) {
        cancelToken.cancel();
      }
      return res;
    });
  };
};
