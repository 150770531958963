import { range } from "lodash";
import { LocaleUtils } from "react-day-picker";
import { YearMonthDropdownContainer } from "@gemini-ui/components/DatePicker/styles";
import { Select } from "@gemini-ui/design-system";
import { DefaultDropdownItemProps } from "@gemini-ui/design-system/Dropdown/constants";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  date: Date;
  localeUtils: LocaleUtils;
  onChange: (val: any) => void;
  yearRange: string[];
}

const defaultYearRange = range(2015, new Date().getFullYear() + 1).map(x => x.toString());

const YearMonthForm = ({ date, localeUtils, yearRange = defaultYearRange, onChange = () => {} }: Props) => {
  const { intl } = useIntl();

  const selectedMonth = date.getMonth();
  const selectedYear = date.getFullYear();

  const months: DefaultDropdownItemProps[] = localeUtils
    .getMonths()
    .map((month, value) => ({ value: `${value}`, label: month }));

  const years: DefaultDropdownItemProps[] = yearRange.map(value => ({
    value,
    label: Number(value),
  }));

  const yearValue = years.find(year => year.label === selectedYear);

  return (
    <YearMonthDropdownContainer>
      <Select
        label={intl.formatMessage({ defaultMessage: "Month" })}
        variant="md"
        data-testid="dropdown-month"
        name="month"
        onChange={value => {
          onChange({ month: Number(value) });
        }}
        value={months[selectedMonth].value}
        options={months}
      />
      <Select
        label={intl.formatMessage({ defaultMessage: "Year" })}
        variant="md"
        data-testid="dropdown-year"
        name="year"
        onChange={label => {
          onChange({ year: label });
        }}
        value={yearValue?.value || ""}
        options={years}
      />
    </YearMonthDropdownContainer>
  );
};

export default YearMonthForm;
