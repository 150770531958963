/* THIS IS A GENERATED FILE. DO NOT MODIFY DIRECTLY. READ THE README TO SEE HOW TO UPDATE OR ADD ILLUSTRATIONS */
import { Fragment } from "react";
import create from "@gemini-ui/design-system/illustrations/create";

export const IllustrationWatchlist = create(({ black, bgPrimary, contentPrimary, white }) => (
  <Fragment>
    <path
      d="M203.425 171.872C203.361 173.504 184.513 174.432 146.849 176.256C130.209 177.056 121.281 177.248 116.513 177.312C92.7052 177.632 78.6892 176.672 71.3292 176.064C67.0732 175.712 44.2252 173.76 44.2892 171.904C44.3532 170.464 57.6972 169.664 71.3292 168.832C82.2732 168.16 87.3292 168.192 104.161 167.488C114.657 167.04 120.929 166.688 123.873 166.56C155.297 165.024 203.521 169.248 203.425 171.872Z"
      fill={black}
    />
    <path
      d="M107.138 178.176C86.8822 178.176 75.3942 177.216 71.2662 176.864C69.0582 176.672 62.3382 176.096 56.0982 175.36C52.5462 174.912 49.7302 174.496 47.7462 174.08C44.5462 173.408 43.4582 172.864 43.5222 171.872C43.6182 170.048 47.7462 169.472 71.2982 168.032C77.8582 167.616 82.3062 167.488 88.4502 167.264C92.6422 167.136 97.4102 166.976 104.13 166.688C111.97 166.368 117.378 166.08 120.93 165.888C122.114 165.824 123.074 165.792 123.842 165.728C137.41 165.088 156.194 165.472 174.082 166.752C180.514 167.2 201.698 168.896 203.874 171.072C204.194 171.392 204.226 171.712 204.226 171.904C204.194 172.864 203.17 173.312 199.97 173.792C197.666 174.112 194.178 174.464 189.282 174.784C179.842 175.456 166.018 176.128 146.882 177.056C130.754 177.824 121.826 178.016 116.514 178.112C113.218 178.144 110.082 178.176 107.138 178.176ZM45.7622 171.904C47.5542 172.48 53.2182 173.696 71.3942 175.232C76.0982 175.616 90.4342 176.832 116.514 176.48C121.826 176.416 130.722 176.224 146.818 175.424C176.738 173.984 197.346 172.96 201.922 171.808C198.978 170.848 189.058 169.408 173.858 168.32C156.066 167.04 137.378 166.656 123.906 167.296C123.17 167.328 122.21 167.392 120.994 167.456C117.41 167.648 112.002 167.904 104.162 168.256C97.4102 168.544 92.6742 168.704 88.4502 168.832C82.3062 169.024 77.8902 169.184 71.3622 169.6C61.8582 170.176 49.1862 170.976 45.7622 171.904ZM202.626 171.84L203.426 171.872L202.626 171.84Z"
      fill={contentPrimary}
    />
    <path
      d="M70.1484 163.264V171.584C70.1484 172.832 80.0684 173.824 92.3244 173.824C104.58 173.824 114.5 172.8 114.5 171.584V163.264"
      fill={white}
    />
    <path
      d="M92.3226 174.528C86.3706 174.528 80.7706 174.304 76.5786 173.856C70.0186 173.184 69.4746 172.384 69.4746 171.584V163.264C69.4746 162.88 69.7946 162.592 70.1466 162.592C70.4986 162.592 70.8186 162.912 70.8186 163.264V171.392C71.9706 172.16 79.8426 173.152 92.3226 173.152C104.803 173.152 112.643 172.16 113.827 171.392V163.264C113.827 162.88 114.147 162.592 114.499 162.592C114.851 162.592 115.171 162.912 115.171 163.264V171.584C115.171 172.352 114.627 173.184 108.067 173.856C103.843 174.272 98.2426 174.528 92.3226 174.528Z"
      fill={black}
    />
    <path
      d="M92.3244 165.504C104.572 165.504 114.5 164.502 114.5 163.264C114.5 162.027 104.572 161.024 92.3244 161.024C80.077 161.024 70.1484 162.027 70.1484 163.264C70.1484 164.502 80.077 165.504 92.3244 165.504Z"
      fill={black}
    />
    <path
      d="M92.3226 166.208C86.3706 166.208 80.7706 165.984 76.5786 165.536C70.0186 164.864 69.4746 164.064 69.4746 163.264C69.4746 162.464 70.0186 161.664 76.5786 160.992C80.8026 160.576 86.3706 160.32 92.3226 160.32C98.2746 160.32 103.875 160.544 108.067 160.992C114.627 161.664 115.171 162.464 115.171 163.264C115.171 164.064 114.627 164.864 108.067 165.536C103.843 165.952 98.2426 166.208 92.3226 166.208ZM71.2346 163.264C73.2826 164 80.8346 164.832 92.3226 164.832C103.811 164.832 111.363 164 113.411 163.264C111.363 162.528 103.811 161.696 92.3226 161.696C80.8346 161.696 73.2506 162.528 71.2346 163.264Z"
      fill={black}
    />
    <path
      d="M73.8281 152.48V160.8C73.8281 162.048 83.7481 163.04 96.0041 163.04C108.26 163.04 118.18 162.016 118.18 160.8V152.48"
      fill={white}
    />
    <path
      d="M96.0023 163.745C90.0503 163.745 84.4503 163.521 80.2583 163.073C73.6983 162.401 73.1543 161.601 73.1543 160.801V152.481C73.1543 152.097 73.4743 151.809 73.8263 151.809C74.1783 151.809 74.4983 152.129 74.4983 152.481V160.609C75.6503 161.377 83.5223 162.369 96.0023 162.369C108.482 162.369 116.322 161.377 117.506 160.609V152.481C117.506 152.097 117.826 151.809 118.178 151.809C118.53 151.809 118.85 152.129 118.85 152.481V160.801C118.85 161.569 118.306 162.401 111.746 163.073C107.554 163.489 101.954 163.745 96.0023 163.745Z"
      fill={black}
    />
    <path
      d="M96.0041 154.72C108.252 154.72 118.18 153.717 118.18 152.48C118.18 151.243 108.252 150.24 96.0041 150.24C83.7567 150.24 73.8281 151.243 73.8281 152.48C73.8281 153.717 83.7567 154.72 96.0041 154.72Z"
      fill={black}
    />
    <path
      d="M96.0023 155.424C90.0503 155.424 84.4503 155.2 80.2583 154.752C73.6983 154.08 73.1543 153.28 73.1543 152.48C73.1543 151.68 73.6983 150.88 80.2583 150.208C84.4823 149.792 90.0503 149.536 96.0023 149.536C101.954 149.536 107.554 149.76 111.746 150.208C118.306 150.88 118.85 151.68 118.85 152.48C118.85 153.28 118.306 154.08 111.746 154.752C107.554 155.168 101.954 155.424 96.0023 155.424ZM74.9143 152.48C76.9623 153.216 84.5143 154.048 96.0023 154.048C107.49 154.048 115.042 153.216 117.09 152.48C115.042 151.744 107.49 150.912 96.0023 150.912C84.5143 150.912 76.9623 151.744 74.9143 152.48Z"
      fill={black}
    />
    <path
      d="M76.3867 142.528V150.848C76.3867 152.096 86.3067 153.088 98.5627 153.088C110.819 153.088 120.739 152.064 120.739 150.848V142.528"
      fill={white}
    />
    <path
      d="M98.5628 153.76C92.6108 153.76 87.0108 153.536 82.8188 153.088C76.2588 152.416 75.7148 151.616 75.7148 150.816V142.496C75.7148 142.112 76.0348 141.824 76.3868 141.824C76.7388 141.824 77.0588 142.144 77.0588 142.496V150.624C78.2108 151.392 86.0828 152.384 98.5628 152.384C111.043 152.384 118.883 151.392 120.067 150.624V142.496C120.067 142.112 120.387 141.824 120.739 141.824C121.091 141.824 121.411 142.144 121.411 142.496V150.816C121.411 151.584 120.867 152.416 114.307 153.088C110.115 153.536 104.515 153.76 98.5628 153.76Z"
      fill={black}
    />
    <path
      d="M98.5627 144.768C110.81 144.768 120.739 143.765 120.739 142.528C120.739 141.291 110.81 140.288 98.5627 140.288C86.3152 140.288 76.3867 141.291 76.3867 142.528C76.3867 143.765 86.3152 144.768 98.5627 144.768Z"
      fill={black}
    />
    <path
      d="M98.5628 145.472C92.6108 145.472 87.0108 145.248 82.8188 144.8C76.2588 144.128 75.7148 143.328 75.7148 142.528C75.7148 141.728 76.2588 140.928 82.8188 140.256C87.0428 139.84 92.6108 139.584 98.5628 139.584C104.515 139.584 110.115 139.808 114.307 140.256C120.867 140.928 121.411 141.728 121.411 142.528C121.411 143.328 120.867 144.128 114.307 144.8C110.115 145.216 104.515 145.472 98.5628 145.472ZM77.4748 142.528C79.5228 143.264 87.0748 144.096 98.5628 144.096C110.051 144.096 117.603 143.264 119.651 142.528C117.603 141.792 110.051 140.96 98.5628 140.96C87.0748 140.96 79.5228 141.792 77.4748 142.528Z"
      fill={black}
    />
    <path
      d="M71.459 133.792V142.112C71.459 143.36 81.379 144.352 93.635 144.352C105.891 144.352 115.811 143.328 115.811 142.112V133.792"
      fill={white}
    />
    <path
      d="M93.6351 145.056C87.6831 145.056 82.0831 144.832 77.8911 144.384C71.3311 143.712 70.7871 142.912 70.7871 142.112V133.792C70.7871 133.408 71.1071 133.12 71.4591 133.12C71.8111 133.12 72.1311 133.44 72.1311 133.792V141.92C73.2831 142.688 81.1551 143.68 93.6351 143.68C106.115 143.68 113.955 142.688 115.139 141.92V133.792C115.139 133.408 115.459 133.12 115.811 133.12C116.163 133.12 116.483 133.44 116.483 133.792V142.112C116.483 142.88 115.939 143.712 109.379 144.384C105.187 144.8 99.5871 145.056 93.6351 145.056Z"
      fill={black}
    />
    <path
      d="M93.635 136.032C105.882 136.032 115.811 135.029 115.811 133.792C115.811 132.555 105.882 131.552 93.635 131.552C81.3875 131.552 71.459 132.555 71.459 133.792C71.459 135.029 81.3875 136.032 93.635 136.032Z"
      fill={black}
    />
    <path
      d="M93.6351 136.736C87.6831 136.736 82.0831 136.512 77.8911 136.064C71.3311 135.392 70.7871 134.592 70.7871 133.792C70.7871 132.992 71.3311 132.192 77.8911 131.52C82.1151 131.104 87.6831 130.848 93.6351 130.848C99.5871 130.848 105.187 131.072 109.379 131.52C115.939 132.192 116.483 132.992 116.483 133.792C116.483 134.592 115.939 135.392 109.379 136.064C105.187 136.48 99.5871 136.736 93.6351 136.736ZM72.5471 133.792C74.5951 134.528 82.1471 135.36 93.6351 135.36C105.123 135.36 112.675 134.528 114.723 133.792C112.675 133.056 105.123 132.224 93.6351 132.224C82.1471 132.224 74.5951 133.056 72.5471 133.792Z"
      fill={black}
    />
    <path
      d="M74.5312 123.84V132.16C74.5312 133.408 84.4513 134.4 96.7073 134.4C108.963 134.4 118.883 133.376 118.883 132.16V123.84"
      fill={white}
    />
    <path
      d="M96.7074 135.072C90.7554 135.072 85.1554 134.848 80.9634 134.4C74.4034 133.728 73.8594 132.928 73.8594 132.128V123.808C73.8594 123.424 74.1794 123.136 74.5314 123.136C74.8834 123.136 75.2034 123.456 75.2034 123.808V131.936C76.3554 132.704 84.2274 133.696 96.7074 133.696C109.187 133.696 117.027 132.704 118.211 131.936V123.808C118.211 123.424 118.531 123.136 118.883 123.136C119.235 123.136 119.555 123.456 119.555 123.808V132.128C119.555 132.896 119.011 133.728 112.451 134.4C108.259 134.848 102.659 135.072 96.7074 135.072Z"
      fill={black}
    />
    <path
      d="M96.7073 126.08C108.955 126.08 118.883 125.077 118.883 123.84C118.883 122.602 108.955 121.6 96.7073 121.6C84.4598 121.6 74.5312 122.602 74.5312 123.84C74.5312 125.077 84.4598 126.08 96.7073 126.08Z"
      fill={black}
    />
    <path
      d="M96.7074 126.784C90.7554 126.784 85.1554 126.561 80.9634 126.113C74.4034 125.441 73.8594 124.64 73.8594 123.84C73.8594 123.04 74.4034 122.24 80.9634 121.568C85.1874 121.152 90.7554 120.896 96.7074 120.896C102.659 120.896 108.259 121.12 112.451 121.568C119.011 122.24 119.555 123.04 119.555 123.84C119.555 124.64 119.011 125.441 112.451 126.113C108.259 126.529 102.659 126.784 96.7074 126.784ZM75.6194 123.84C77.6674 124.576 85.2194 125.408 96.7074 125.408C108.195 125.408 115.747 124.576 117.795 123.84C115.747 123.104 108.195 122.273 96.7074 122.273C85.2194 122.273 77.6674 123.104 75.6194 123.84Z"
      fill={black}
    />
    <path
      d="M101.508 153.76C101.508 154.816 101.508 156.416 101.54 158.4C101.572 160.8 101.604 161.28 101.636 163.392C101.668 165.664 101.7 167.68 101.796 169.28C101.86 170.752 102.02 172.64 101.956 174.752C116.772 177.792 135.012 180 156.612 180C171.748 180 190.564 178.752 211.716 174.4C211.716 173.536 211.716 172.256 211.716 170.656C211.716 167.84 211.716 167.36 211.716 164.352C211.716 161.152 211.716 160.576 211.716 157.504C211.716 155.904 211.716 154.592 211.716 153.76"
      fill={white}
    />
    <path
      d="M156.612 180.704C137.22 180.704 118.788 178.944 101.796 175.456C101.476 175.392 101.252 175.104 101.252 174.784C101.316 173.12 101.22 171.584 101.156 170.24C101.124 169.92 101.124 169.632 101.124 169.376C101.06 167.776 100.996 165.76 100.964 163.456C100.964 162.368 100.932 161.696 100.932 160.992C100.9 160.32 100.9 159.616 100.868 158.464C100.836 156.896 100.836 155.328 100.836 153.824C100.836 153.44 101.156 153.152 101.508 153.152C101.892 153.152 102.18 153.472 102.18 153.824C102.18 155.328 102.18 156.896 102.212 158.464C102.244 159.616 102.244 160.32 102.276 160.992C102.308 161.696 102.308 162.368 102.308 163.456C102.34 165.76 102.372 167.744 102.468 169.344C102.468 169.632 102.5 169.92 102.5 170.208C102.564 171.36 102.628 172.768 102.628 174.304C119.364 177.696 137.54 179.424 156.612 179.424C174.852 179.424 193.156 177.568 211.044 173.952C211.044 173.12 211.044 172.032 211.044 170.784C211.044 169.344 211.044 168.512 211.044 167.648C211.044 166.816 211.044 165.952 211.044 164.48C211.044 162.976 211.044 162.048 211.044 161.152C211.044 160.16 211.044 159.232 211.044 157.6C211.044 156 211.044 154.688 211.044 153.856C211.044 153.472 211.364 153.184 211.716 153.184C212.1 153.184 212.388 153.504 212.388 153.856C212.388 154.688 212.388 156 212.388 157.6C212.388 159.232 212.388 160.16 212.388 161.12C212.388 162.016 212.388 162.912 212.388 164.448C212.388 165.92 212.388 166.784 212.388 167.616C212.388 168.48 212.388 169.312 212.388 170.752C212.388 172.32 212.388 173.632 212.388 174.496C212.388 174.816 212.164 175.104 211.844 175.168C193.7 178.816 175.108 180.704 156.612 180.704Z"
      fill={black}
    />
    <path
      d="M156.61 159.36C187.043 159.36 211.714 156.853 211.714 153.76C211.714 150.667 187.043 148.16 156.61 148.16C126.177 148.16 101.506 150.667 101.506 153.76C101.506 156.853 126.177 159.36 156.61 159.36Z"
      fill={black}
    />
    <path
      d="M156.611 160.032C141.859 160.032 128.003 159.456 117.571 158.4C112.483 157.888 108.483 157.28 105.667 156.608C102.275 155.776 100.803 154.944 100.803 153.76C100.803 152.576 102.243 151.744 105.667 150.912C108.483 150.24 112.483 149.632 117.571 149.12C128.003 148.064 141.859 147.488 156.611 147.488C171.363 147.488 185.219 148.064 195.651 149.12C200.739 149.632 204.739 150.24 207.555 150.912C210.947 151.744 212.419 152.576 212.419 153.76C212.419 154.944 210.979 155.776 207.555 156.608C204.739 157.28 200.739 157.888 195.651 158.4C185.219 159.456 171.331 160.032 156.611 160.032ZM102.179 153.76C102.307 153.984 103.715 155.68 118.339 157.12C128.643 158.144 142.243 158.688 156.579 158.688C170.915 158.688 184.515 158.144 194.819 157.12C209.443 155.68 210.851 153.984 210.979 153.76C210.851 153.536 209.443 151.84 194.819 150.4C184.515 149.376 170.915 148.832 156.579 148.832C142.243 148.832 128.643 149.376 118.339 150.4C103.715 151.872 102.307 153.536 102.179 153.76Z"
      fill={black}
    />
    <path
      d="M98.1484 132.512C98.1484 133.568 98.1484 135.168 98.1804 137.152C98.2124 139.552 98.2444 140.032 98.2764 142.144C98.3084 144.416 98.3404 146.432 98.4364 148.032C98.5004 149.504 98.6604 151.392 98.5964 153.504C113.412 156.544 131.652 158.752 153.252 158.752C168.388 158.752 187.204 157.504 208.356 153.152C208.356 152.288 208.356 151.008 208.356 149.408C208.356 146.592 208.356 146.112 208.356 143.104C208.356 139.904 208.356 139.328 208.356 136.256C208.356 134.656 208.356 133.344 208.356 132.512"
      fill={white}
    />
    <path
      d="M153.251 159.456C133.859 159.456 115.427 157.696 98.4346 154.208C98.1146 154.144 97.8906 153.856 97.8906 153.536C97.9546 151.872 97.8586 150.336 97.7946 148.992C97.7626 148.672 97.7626 148.384 97.7626 148.128C97.6986 146.528 97.6346 144.512 97.6026 142.208C97.6026 141.12 97.5706 140.448 97.5706 139.744C97.5386 139.072 97.5386 138.368 97.5066 137.216C97.4746 135.648 97.4746 134.08 97.4746 132.576C97.4746 132.192 97.7946 131.904 98.1466 131.904C98.5306 131.904 98.8186 132.224 98.8186 132.576C98.8186 134.08 98.8186 135.648 98.8506 137.216C98.8826 138.368 98.8826 139.072 98.9146 139.744C98.9466 140.448 98.9466 141.12 98.9466 142.208C98.9786 144.512 99.0106 146.496 99.1066 148.096C99.1066 148.384 99.1386 148.672 99.1386 148.96C99.2026 150.112 99.2666 151.52 99.2666 153.056C116.003 156.448 134.179 158.176 153.251 158.176C171.491 158.176 189.795 156.32 207.683 152.704C207.683 151.872 207.683 150.784 207.683 149.536C207.683 148.096 207.683 147.264 207.683 146.4C207.683 145.568 207.683 144.704 207.683 143.232C207.683 141.696 207.683 140.768 207.683 139.904C207.683 138.912 207.683 137.984 207.683 136.384C207.683 134.784 207.683 133.472 207.683 132.64C207.683 132.256 208.003 131.968 208.355 131.968C208.739 131.968 209.027 132.288 209.027 132.64C209.027 133.472 209.027 134.784 209.027 136.384C209.027 138.016 209.027 138.912 209.027 139.904C209.027 140.8 209.027 141.728 209.027 143.232C209.027 144.704 209.027 145.568 209.027 146.4C209.027 147.264 209.027 148.096 209.027 149.536C209.027 151.104 209.027 152.416 209.027 153.28C209.027 153.6 208.803 153.888 208.483 153.952C190.339 157.568 171.747 159.456 153.251 159.456Z"
      fill={black}
    />
    <path
      d="M153.25 138.112C183.684 138.112 208.354 135.605 208.354 132.512C208.354 129.419 183.684 126.912 153.25 126.912C122.817 126.912 98.1465 129.419 98.1465 132.512C98.1465 135.605 122.817 138.112 153.25 138.112Z"
      fill={black}
    />
    <path
      d="M153.251 138.784C138.499 138.784 124.643 138.208 114.211 137.152C109.123 136.64 105.123 136.032 102.307 135.36C98.9154 134.528 97.4434 133.696 97.4434 132.512C97.4434 131.328 98.8834 130.496 102.307 129.664C105.123 128.992 109.123 128.384 114.211 127.872C124.643 126.816 138.499 126.24 153.251 126.24C168.003 126.24 181.859 126.816 192.291 127.872C197.379 128.384 201.379 128.992 204.195 129.664C207.587 130.496 209.059 131.328 209.059 132.512C209.059 133.696 207.619 134.528 204.195 135.36C201.379 136.032 197.379 136.64 192.291 137.152C181.859 138.208 167.971 138.784 153.251 138.784ZM98.8194 132.512C98.9474 132.736 100.355 134.432 114.979 135.872C125.283 136.896 138.883 137.44 153.219 137.44C167.587 137.44 181.155 136.896 191.459 135.872C206.083 134.432 207.491 132.736 207.619 132.512C207.491 132.288 206.083 130.592 191.459 129.152C181.155 128.128 167.555 127.584 153.219 127.584C138.883 127.584 125.283 128.128 114.979 129.152C100.355 130.624 98.9474 132.288 98.8194 132.512Z"
      fill={black}
    />
    <path
      d="M181.922 75.4561H157.922C145.73 75.4561 135.842 87.744 135.842 102.88C135.842 118.048 145.73 130.304 157.922 130.304H181.922"
      fill={black}
    />
    <path
      d="M181.922 130.88H157.922C145.442 130.88 135.266 118.336 135.266 102.88C135.266 87.4239 145.442 74.8799 157.922 74.8799H181.922C182.242 74.8799 182.466 75.1359 182.466 75.4239C182.466 75.7119 182.21 75.9679 181.922 75.9679H157.922C146.05 75.9679 136.386 88.0319 136.386 102.848C136.386 117.664 146.05 129.728 157.922 129.728H181.922C182.242 129.728 182.466 129.984 182.466 130.272C182.466 130.56 182.242 130.88 181.922 130.88Z"
      fill={white}
    />
    <path
      d="M183.011 130.336C191.865 130.336 199.043 118.058 199.043 102.912C199.043 87.7664 191.865 75.4883 183.011 75.4883C174.156 75.4883 166.979 87.7664 166.979 102.912C166.979 118.058 174.156 130.336 183.011 130.336Z"
      fill={black}
    />
    <path
      d="M183.011 130.88C173.891 130.88 166.467 118.336 166.467 102.912C166.467 87.4883 173.891 74.9443 183.011 74.9443C192.131 74.9443 199.555 87.4883 199.555 102.912C199.555 118.336 192.131 130.88 183.011 130.88ZM183.011 76.0003C174.467 76.0003 167.491 88.0643 167.491 102.912C167.491 117.76 174.435 129.824 183.011 129.824C191.587 129.824 198.531 117.76 198.531 102.912C198.531 88.0643 191.555 76.0003 183.011 76.0003Z"
      fill={white}
    />
    <path
      d="M82.2094 95.4561H77.9854C77.7294 95.4561 77.4734 95.5201 77.2174 95.6481C76.7694 95.8721 76.2254 96.2241 75.6494 96.7361C74.7214 97.6321 74.2734 98.5601 73.9854 99.2001C73.1534 101.056 73.1534 102.656 73.1854 102.976C73.2174 104.192 73.5054 105.088 73.7294 105.792C74.0494 106.72 74.3694 107.744 75.2654 108.736C75.6174 109.152 76.2574 109.76 77.2174 110.304C77.4414 110.432 78.1454 110.496 78.4334 110.496H82.2734"
      fill={white}
    />
    <path
      d="M82.307 111.008H78.467C78.467 111.008 77.443 111.008 77.027 110.752C76.227 110.304 75.523 109.76 74.915 109.088C73.955 108 73.603 106.912 73.283 105.952C73.059 105.248 72.739 104.288 72.707 102.976C72.707 102.368 72.739 100.768 73.571 98.9763C73.859 98.3683 74.307 97.3443 75.331 96.3523C75.843 95.8723 76.387 95.4883 76.995 95.1683C77.283 95.0083 77.635 94.9443 77.987 94.9443H82.211C82.499 94.9443 82.723 95.1683 82.723 95.4563C82.723 95.7443 82.499 95.9683 82.211 95.9683H77.987C77.795 95.9683 77.635 96.0003 77.475 96.0963C76.963 96.3843 76.451 96.7043 76.035 97.1203C75.139 97.9523 74.755 98.8163 74.499 99.4243C73.763 101.024 73.731 102.432 73.731 102.976C73.763 104.128 74.051 104.96 74.243 105.632C74.531 106.528 74.851 107.456 75.683 108.416C76.195 108.992 76.803 109.472 77.475 109.856C77.603 109.92 78.147 109.984 78.435 109.984H82.275C82.563 109.984 82.787 110.208 82.787 110.496C82.787 110.784 82.595 111.008 82.307 111.008Z"
      fill={white}
    />
    <path
      d="M80.2268 95.1357H78.6908C78.3068 95.4237 77.7628 95.8717 77.2508 96.5117C76.5788 97.4077 76.2908 98.2397 75.9708 99.3277C75.6828 100.256 75.3308 101.376 75.2988 102.944C75.2668 104.736 75.6508 106.08 75.7468 106.4C75.8108 106.656 76.1308 107.648 76.8348 108.832C77.3468 109.696 77.8908 110.368 78.3388 110.848C78.7228 110.848 79.1388 110.848 79.5228 110.848"
      fill={black}
    />
    <path
      d="M79.5544 111.008H78.3704C78.3064 111.008 78.2744 110.976 78.2424 110.944C77.6664 110.304 77.1544 109.632 76.7064 108.896C76.0664 107.808 75.7464 106.88 75.6184 106.432C75.4264 105.824 75.1384 104.544 75.1704 102.912C75.2024 101.312 75.5864 100.128 75.8424 99.2643C76.1624 98.2403 76.4504 97.3443 77.1544 96.3843C77.5704 95.8403 78.0504 95.3603 78.6264 94.9763C78.6584 94.9443 78.6904 94.9443 78.7544 94.9443H80.2904C80.3864 94.9443 80.4824 95.0403 80.4824 95.1363C80.4824 95.2323 80.3864 95.3283 80.2904 95.3283H78.8184C78.3064 95.7123 77.8584 96.1283 77.4744 96.6403C76.8024 97.5363 76.5464 98.3363 76.2264 99.3923C75.9384 100.288 75.6184 101.408 75.5544 102.944C75.5224 104.544 75.8104 105.76 76.0024 106.336C76.0984 106.688 76.4184 107.648 77.0584 108.704C77.4744 109.408 77.9544 110.048 78.4984 110.624H79.5864C79.6824 110.624 79.7784 110.72 79.7784 110.816C79.7784 110.912 79.6504 111.008 79.5544 111.008Z"
      fill={white}
    />
    <path
      d="M90.4337 92.0322H83.7457C80.2257 92.0322 77.3457 96.9282 77.3457 102.976C77.3457 109.024 80.1937 113.92 83.7457 113.92H90.5617"
      fill={black}
    />
    <path
      d="M90.5615 114.464H83.7135C79.8095 114.464 76.7695 109.408 76.7695 102.976C76.7695 96.5443 79.8095 91.4883 83.7135 91.4883H90.4015C90.7215 91.4883 90.9455 91.7443 90.9455 92.0323C90.9455 92.3203 90.6895 92.5763 90.4015 92.5763H83.7135C80.4815 92.5763 77.8895 97.2483 77.8895 102.944C77.8895 108.64 80.5135 113.312 83.7135 113.312H90.5615C90.8815 113.312 91.1055 113.568 91.1055 113.856C91.1055 114.144 90.8495 114.464 90.5615 114.464Z"
      fill={white}
    />
    <path
      d="M91.9068 92.0322H89.7628C89.1228 92.4482 88.1308 93.2162 87.2988 94.4322C86.5628 95.4882 86.3068 96.4162 85.8588 97.8562C85.5708 98.8162 85.0908 100.544 85.0268 102.912C84.9948 103.744 84.9948 105.024 85.3468 106.688C85.4748 107.328 85.9228 109.088 87.1068 111.104C87.8428 112.352 88.6428 113.312 89.2508 113.952C89.7948 113.952 90.3388 113.952 90.9148 113.952"
      fill={white}
    />
    <path
      d="M90.9789 114.176H89.3149C89.2509 114.176 89.1869 114.144 89.1229 114.08C88.2909 113.216 87.5549 112.224 86.9149 111.168C85.8589 109.376 85.3789 107.744 85.1229 106.688C84.7389 104.992 84.7709 103.648 84.8029 102.848C84.8669 100.416 85.3789 98.5921 85.6349 97.7281C86.0509 96.2881 86.3389 95.3281 87.1069 94.2401C87.7789 93.2801 88.6109 92.4481 89.6349 91.7761C89.6669 91.7441 89.7309 91.7441 89.7949 91.7441H91.9389C92.0989 91.7441 92.1949 91.8721 92.1949 92.0001C92.1949 92.1281 92.0669 92.2561 91.9389 92.2561H89.8909C88.9629 92.8641 88.1949 93.6321 87.5869 94.5281C86.8829 95.5521 86.6269 96.4481 86.2109 97.8881C85.9549 98.7521 85.4429 100.512 85.3789 102.88C85.3789 103.648 85.3469 104.928 85.6989 106.592C85.9229 107.616 86.4029 109.184 87.4269 110.912C88.0029 111.904 88.6749 112.8 89.4749 113.632H91.0109C91.1709 113.632 91.2669 113.76 91.2669 113.888C91.2669 114.016 91.1069 114.176 90.9789 114.176Z"
      fill={white}
    />
    <path
      d="M170.883 108.256C172.675 93.5364 178.467 85.5684 184.131 85.5684C192.803 85.5684 196.323 104.128 196.323 104.128C196.323 104.128 193.123 120.256 185.347 120.832C177.603 121.408 170.883 108.256 170.883 108.256Z"
      fill={white}
    />
    <path
      d="M184.803 121.408C181.571 121.408 178.211 119.296 174.755 115.136C172.099 111.904 170.435 108.672 170.371 108.544C170.307 108.448 170.307 108.32 170.307 108.224C171.971 94.3677 177.539 85.0557 184.099 85.0557C187.939 85.0557 191.267 88.2877 193.955 94.6557C195.907 99.2957 196.803 104 196.835 104.032C196.835 104.096 196.835 104.16 196.835 104.256C196.803 104.416 196.003 108.384 194.211 112.512C191.779 118.176 188.771 121.152 185.347 121.408C185.187 121.408 184.995 121.408 184.803 121.408ZM171.459 108.16C172.227 109.6 178.435 120.768 185.283 120.288C192.227 119.776 195.459 105.504 195.747 104.128C195.459 102.624 191.939 86.1437 184.131 86.1437C181.443 86.1437 178.755 88.1277 176.515 91.7117C174.051 95.6797 172.291 101.376 171.459 108.16Z"
      fill={black}
    />
    <path
      d="M190.947 117.76C190.915 117.76 190.883 117.76 190.883 117.76C186.019 117.12 183.011 111.936 183.043 104.288C183.075 99.1043 184.739 94.2723 187.267 91.9683C188.547 90.8163 189.987 90.3043 191.587 90.4323C191.907 90.4643 192.131 90.7203 192.099 91.0403C192.067 91.3603 191.811 91.5523 191.491 91.5523C190.243 91.4243 189.091 91.8403 188.035 92.8003C185.731 94.8803 184.195 99.4883 184.163 104.288C184.131 109.824 185.923 115.968 191.043 116.64C191.331 116.672 191.555 116.96 191.523 117.28C191.459 117.568 191.235 117.76 190.947 117.76Z"
      fill={black}
    />
    <path
      d="M193.443 112.864C190.275 112.448 188.867 108.896 188.867 104.864C188.867 100.8 190.659 96.3199 193.795 96.6079C194.243 97.4079 194.691 98.3359 195.107 99.4239C195.779 101.184 196.131 102.816 196.291 104.128C196.003 105.472 195.619 106.976 195.107 108.576C194.595 110.176 194.019 111.616 193.443 112.864Z"
      fill={black}
    />
    <path
      d="M193.443 113.216H193.411C190.403 112.8 188.547 109.6 188.547 104.864C188.547 101.664 189.603 98.6561 191.171 97.2161C191.971 96.5121 192.867 96.1921 193.827 96.2881C193.955 96.2881 194.051 96.3521 194.083 96.4481C194.595 97.3761 195.043 98.3361 195.427 99.2961C196.003 100.832 196.419 102.432 196.643 104.064C196.643 104.096 196.643 104.128 196.643 104.192C196.323 105.696 195.907 107.2 195.427 108.672C194.947 110.144 194.371 111.584 193.731 112.992C193.699 113.12 193.571 113.216 193.443 113.216ZM193.507 96.9281C192.835 96.9281 192.195 97.1841 191.619 97.6961C190.211 98.9761 189.219 101.856 189.219 104.832C189.219 108.16 190.275 111.872 193.219 112.448C193.795 111.136 194.339 109.792 194.787 108.448C195.267 107.04 195.651 105.568 195.971 104.096C195.747 102.528 195.363 100.992 194.787 99.5201C194.435 98.6241 194.051 97.7601 193.571 96.9281C193.539 96.9281 193.507 96.9281 193.507 96.9281Z"
      fill={black}
    />
    <path
      d="M121.698 83.3603C119.842 83.2963 118.05 83.4243 116.226 83.3603C116.034 83.3603 115.81 83.3603 115.618 83.3603C113.986 83.4563 112.258 83.3923 110.626 83.4883C110.434 83.4883 110.242 83.4883 110.018 83.4883C108.386 83.4563 106.754 83.3923 105.09 83.3603H101.25C99.49 83.3603 97.73 83.7123 96.162 84.5123C95.586 84.8003 94.978 85.1523 94.402 85.6003C91.298 87.9683 90.402 91.3923 89.442 94.9763C89.026 96.4803 88.418 99.2963 88.514 102.912C88.546 103.68 88.642 106.432 89.57 109.952C90.53 113.472 91.266 116.256 93.634 118.72C94.466 119.584 96.098 121.056 98.754 122.048C99.49 122.336 100.258 122.432 101.026 122.432H119.426"
      fill={black}
    />
    <path
      d="M119.426 123.008H101.026C100.13 123.008 99.2977 122.848 98.5297 122.56C96.4497 121.76 94.6577 120.608 93.2177 119.072C90.7537 116.48 89.9857 113.632 89.0257 110.048C88.1617 106.816 87.9697 104.16 87.9377 102.88C87.8737 100.128 88.1937 97.4078 88.8977 94.7838C89.8897 91.0718 90.8177 87.5837 94.0817 85.1197C94.6577 84.6717 95.2657 84.2878 95.9377 83.9678C97.5057 83.1998 99.3617 82.7838 101.282 82.7838H105.122L110.05 82.9118C110.242 82.9118 110.402 82.9118 110.594 82.9118C111.426 82.8478 112.29 82.8478 113.122 82.8478C113.954 82.8478 114.786 82.8478 115.586 82.7838C115.81 82.7838 116.034 82.7518 116.258 82.7838C117.186 82.8158 118.082 82.7838 118.978 82.7838C119.874 82.7838 120.802 82.7518 121.762 82.7838C122.082 82.7838 122.306 83.0397 122.306 83.3597C122.306 83.6797 122.05 83.9038 121.73 83.9038C120.802 83.8718 119.906 83.9038 119.01 83.9038C118.114 83.9038 117.186 83.9358 116.226 83.9038C116.034 83.9038 115.842 83.9038 115.65 83.9038C114.818 83.9678 113.954 83.9678 113.122 83.9678C112.29 83.9678 111.458 83.9678 110.658 84.0318C110.434 84.0318 110.21 84.0318 110.018 84.0318L105.09 83.9038H101.282C99.5217 83.9038 97.8577 84.2878 96.4497 84.9918C95.8737 85.2798 95.2977 85.6318 94.7857 86.0478C91.8417 88.3198 90.9457 91.6158 90.0177 95.1358C89.3457 97.6638 89.0257 100.256 89.0897 102.912C89.1217 104.16 89.2817 106.688 90.1457 109.824C91.0737 113.248 91.8097 115.968 94.0817 118.368C95.3937 119.744 97.0577 120.832 98.9777 121.568C99.6177 121.824 100.322 121.92 101.058 121.92H119.458C119.778 121.92 120.002 122.176 120.002 122.464C120.002 122.752 119.714 123.008 119.426 123.008Z"
      fill={white}
    />
    <path
      d="M119.266 83.3604H116.994C115.65 84.3204 113.826 85.7924 112.098 87.9684C109.762 90.9124 108.706 93.6324 108.45 94.3364C107.266 97.6004 107.17 100.256 107.106 102.656C107.01 106.144 107.49 108.928 107.874 110.656C108.354 112.736 108.77 114.624 109.954 116.8C111.458 119.488 113.346 121.216 114.626 122.208C115.618 122.208 116.578 122.208 117.57 122.208"
      fill={white}
    />
    <path
      d="M117.571 122.656H114.627C114.531 122.656 114.403 122.624 114.339 122.56C112.355 121.024 110.723 119.136 109.539 117.024C108.291 114.784 107.875 112.832 107.395 110.752C106.819 108.096 106.563 105.376 106.627 102.624C106.691 100.224 106.755 97.5037 108.003 94.1437C108.483 92.8637 109.571 90.3357 111.715 87.6477C113.155 85.8557 114.819 84.2877 116.707 82.9437C116.803 82.8797 116.899 82.8477 116.995 82.8477H119.267C119.523 82.8477 119.747 83.0717 119.747 83.3277C119.747 83.5837 119.523 83.8077 119.267 83.8077H117.155C115.395 85.0557 113.827 86.5597 112.483 88.2237C110.403 90.8157 109.347 93.2477 108.899 94.4637C107.715 97.6637 107.651 100.288 107.587 102.624C107.523 105.28 107.779 107.936 108.323 110.528C108.803 112.608 109.187 114.432 110.371 116.544C111.459 118.528 112.963 120.256 114.787 121.696H117.539C117.795 121.696 118.019 121.92 118.019 122.176C118.019 122.432 117.827 122.656 117.571 122.656Z"
      fill={white}
    />
    <path
      d="M149.57 77.0883C147.362 77.0883 145.154 77.0883 142.978 77.0883C141.218 77.0243 139.458 76.9283 137.698 76.8643C137.378 76.8643 137.026 76.8643 136.706 76.8643C134.466 76.9283 132.226 77.0243 129.986 77.0883C124.354 77.0883 120.194 78.8483 116.898 85.0243C116.002 86.6883 113.538 91.4883 113.25 93.5043C112.642 97.3443 111.714 100.48 111.938 104.864C112.194 110.112 112.322 111.008 113.602 116.192C114.562 120.064 116.962 124.224 118.018 125.632C121.218 129.984 125.41 132.608 130.018 132.608C136.578 132.608 143.106 132.608 149.666 132.608"
      fill={black}
    />
    <path
      d="M149.57 133.152H129.922C125.314 133.152 120.898 130.592 117.474 125.952C116.45 124.544 113.954 120.32 112.962 116.288C111.65 111.04 111.522 110.08 111.266 104.864C111.106 101.536 111.586 98.9443 112.098 96.1923C112.258 95.2963 112.45 94.3683 112.578 93.4083C112.93 91.0403 115.97 85.4083 116.29 84.7683C119.394 79.0083 123.458 76.5443 129.89 76.5443L136.61 76.3203C136.962 76.3203 137.314 76.3203 137.666 76.3203L142.946 76.5443H149.538C149.858 76.5443 150.082 76.8003 150.082 77.0883C150.082 77.3763 149.826 77.6323 149.538 77.6323H142.946L137.634 77.4083C137.314 77.4083 136.994 77.4083 136.674 77.4083L129.954 77.6323C123.906 77.6323 120.258 79.8403 117.314 85.2803C116.098 87.5203 113.986 91.8083 113.698 93.5683C113.538 94.5603 113.378 95.4883 113.218 96.3843C112.706 99.0723 112.258 101.6 112.386 104.8C112.61 109.952 112.738 110.816 114.05 116C115.01 119.808 117.41 123.968 118.37 125.248C121.57 129.6 125.666 132 129.922 132H149.57C149.89 132 150.114 132.256 150.114 132.544C150.114 132.832 149.89 133.152 149.57 133.152Z"
      fill={white}
    />
    <path
      d="M150.691 132.608C159.651 132.608 166.915 120.187 166.915 104.864C166.915 89.5415 159.651 77.1201 150.691 77.1201C141.731 77.1201 134.467 89.5415 134.467 104.864C134.467 120.187 141.731 132.608 150.691 132.608Z"
      fill={black}
    />
    <path
      d="M150.689 133.12C141.441 133.12 133.953 120.448 133.953 104.832C133.953 89.2479 141.473 76.5439 150.689 76.5439C159.905 76.5439 167.425 89.2159 167.425 104.832C167.425 120.448 159.905 133.12 150.689 133.12ZM150.689 77.6319C142.049 77.6319 135.009 89.8559 135.009 104.864C135.009 119.872 142.049 132.096 150.689 132.096C159.329 132.096 166.369 119.872 166.369 104.864C166.369 89.8239 159.329 77.6319 150.689 77.6319Z"
      fill={white}
    />
    <path
      d="M138.945 109.888C140.737 95.1682 146.529 87.2002 152.193 87.2002C160.865 87.2002 164.385 105.76 164.385 105.76C164.385 105.76 161.185 121.888 153.409 122.464C145.633 123.04 138.945 109.888 138.945 109.888Z"
      fill={white}
    />
    <path
      d="M152.865 123.04C149.633 123.04 146.273 120.928 142.817 116.768C140.161 113.536 138.497 110.304 138.433 110.176C138.369 110.08 138.369 109.952 138.369 109.856C140.033 96.0005 145.601 86.6885 152.161 86.6885C156.001 86.6885 159.329 89.9205 162.017 96.2885C163.969 100.928 164.865 105.632 164.897 105.664C164.897 105.728 164.897 105.792 164.897 105.888C164.865 106.048 164.065 110.016 162.273 114.144C159.841 119.808 156.833 122.784 153.409 123.04C153.217 123.008 153.057 123.04 152.865 123.04ZM139.489 109.792C140.257 111.232 146.465 122.4 153.313 121.92C160.257 121.408 163.489 107.136 163.777 105.76C163.489 104.256 159.969 87.7765 152.161 87.7765C149.473 87.7765 146.785 89.7605 144.545 93.3445C142.081 97.3125 140.353 103.008 139.489 109.792Z"
      fill={black}
    />
    <path
      d="M159.01 119.392C158.978 119.392 158.946 119.392 158.946 119.392C154.082 118.752 151.074 113.568 151.106 105.92C151.138 100.736 152.802 95.9041 155.33 93.6001C156.61 92.4481 158.05 91.9361 159.65 92.0641C159.97 92.0961 160.194 92.3521 160.162 92.6721C160.13 92.9921 159.874 93.1841 159.554 93.1841C158.306 93.0561 157.154 93.4721 156.098 94.4321C153.794 96.5121 152.258 101.12 152.226 105.92C152.194 111.456 153.986 117.6 159.106 118.272C159.394 118.304 159.618 118.592 159.586 118.912C159.522 119.168 159.266 119.392 159.01 119.392Z"
      fill={black}
    />
    <path
      d="M161.474 114.496C158.306 114.08 156.898 110.528 156.898 106.496C156.898 102.432 158.69 97.9518 161.826 98.2398C162.274 99.0398 162.722 99.9678 163.138 101.056C163.81 102.816 164.162 104.448 164.322 105.76C164.034 107.104 163.65 108.608 163.138 110.208C162.626 111.808 162.05 113.216 161.474 114.496Z"
      fill={black}
    />
    <path
      d="M161.474 114.848H161.442C158.434 114.432 156.578 111.232 156.578 106.496C156.578 103.296 157.634 100.288 159.202 98.848C160.002 98.144 160.898 97.824 161.858 97.92C161.986 97.92 162.082 97.984 162.114 98.08C162.626 99.008 163.074 99.968 163.458 100.928C164.034 102.464 164.45 104.064 164.674 105.696C164.674 105.728 164.674 105.76 164.674 105.824C164.354 107.328 163.938 108.832 163.458 110.304C162.978 111.776 162.402 113.216 161.762 114.624C161.73 114.752 161.602 114.848 161.474 114.848ZM161.538 98.56C160.866 98.56 160.226 98.816 159.65 99.328C158.242 100.608 157.25 103.488 157.25 106.464C157.25 109.792 158.306 113.504 161.25 114.08C161.826 112.768 162.37 111.424 162.818 110.08C163.298 108.672 163.682 107.2 164.002 105.728C163.778 104.16 163.394 102.624 162.818 101.152C162.466 100.256 162.082 99.392 161.602 98.56C161.602 98.56 161.57 98.56 161.538 98.56Z"
      fill={black}
    />
  </Fragment>
));
IllustrationWatchlist.displayName = "IllustrationWatchlist";
