import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { IconLightningFilled, IconRewardOutlined, IconStaking } from "@hubble/icons";
import { Money, MoneyProps } from "@gemini-ui/components/Money";
import { GrowProviderType, InterestProvider } from "@gemini-ui/constants/earn";
import { IconBadge, Select } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { DropdownItem } from "@gemini-ui/pages/Earn/Deposit/PlaceDeposit/PrivateStakingInputs/DropdownItem";
import { UnstakeSourceType } from "@gemini-ui/pages/Earn/Deposit/types";
import { unstakeSourceProviderTypeMap } from "@gemini-ui/pages/Earn/Redeem/utils";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { calculateValidatorsAvailableToUnstake, getUnstakeProviderTypes } from "@gemini-ui/pages/Earn/utils";
import { useIntl } from "@gemini-ui/utils/intl";

interface SelectionItemProps extends SelectOptionProps<UnstakeSourceType> {
  balance?: MoneyProps;
  sourceType: UnstakeSourceType;
  validatorCount?: number;
}

interface Props {
  onChange?: (source: UnstakeSourceType) => void;
  providers: InterestProvider[];
  providerType?: GrowProviderType;
  unstakeSource: UnstakeSourceType;
}

/**
 * TODO: This component should be moved to a directory that makes more sense.
 */
export const UnstakeSourceSelect = ({ unstakeSource, providerType, onChange, providers }: Props) => {
  const { intl } = useIntl();

  const availableProviderTypes = useMemo(() => {
    const { canUnstakeAssetPooled, canUnstakeAssetPrivate } = getUnstakeProviderTypes(providers);

    return [
      ...(canUnstakeAssetPooled
        ? [
            {
              value: GrowProviderType.POOLED_STAKING,
              label: intl.formatMessage({ defaultMessage: "Staking" }),
            },
          ]
        : []),
      ...(canUnstakeAssetPrivate
        ? [
            {
              value: GrowProviderType.PRIVATE_STAKING,
              label: intl.formatMessage({ defaultMessage: "Staking Pro" }),
            },
          ]
        : []),
    ];
  }, [providers, intl]);

  const [selectedProviderType, setSelectedProviderType] = useState(providerType ?? availableProviderTypes[0].value);

  /**
   * Create the generic rendering props each select options.
   */
  const optionRenderProps = useMemo(
    () => ({
      [UnstakeSourceType.pooledBalance]: {
        label: intl.formatMessage({ defaultMessage: "Staked balance" }),
        icon: <IconBadge size="md" icon={<IconStaking />} />,
      },
      [UnstakeSourceType.validatorBalance]: {
        label: intl.formatMessage({ defaultMessage: "Validators" }),
        icon: <IconBadge size="md" icon={<IconLightningFilled />} />,
      },
      [UnstakeSourceType.availableRewardsBalance]: {
        label: intl.formatMessage({ defaultMessage: "Available Rewards" }),
        icon: <IconBadge size="md" icon={<IconRewardOutlined />} />,
      },
    }),
    [intl]
  );

  /**
   * Parse through all of the possible unstake sources for the provider type and create the data-related props for the select options.
   */
  const availableUnstakeSources = Object.entries(unstakeSourceProviderTypeMap)
    ?.filter(([source, providerType]) => providerType === selectedProviderType)
    ?.map(([source, providerType]) => {
      const provider = providers?.find(p => p.providerType === providerType);

      // TODO: The value should come from the BE. This is a temporary solution.
      // See: https://iceland.slack.com/archives/C03A8QEUQV6/p1683740892648309
      // const validatorsAbleToUnstake = Number(provider?.validatorDetails?.validators?.length ?? 0);
      const validatorsAbleToUnstake = calculateValidatorsAvailableToUnstake(
        Number(provider?.availableBalance?.totalAvailableDepositBalance?.value ?? 0)
      );

      // If the provider type is Staking Pro, we need to break down the balances by deposits and rewards. Otherwise, use the total amount earning interest for pooled providers.
      const balance =
        providerType === GrowProviderType.PRIVATE_STAKING
          ? source === UnstakeSourceType.validatorBalance
            ? provider?.availableBalance?.totalAvailableDepositBalance
            : provider?.availableBalance?.totalAvailableRewardBalance
          : provider?.earningInterest;

      // Disable the option if:
      // - There is no provider for the source
      // - There is no balance
      // - The balance is 0
      // - There are no validators
      const isDisabled =
        !Boolean(provider) ||
        !Boolean(balance) ||
        !(Number(balance?.value) > 0) ||
        (source === UnstakeSourceType.validatorBalance && !Boolean(validatorsAbleToUnstake));

      return {
        isDisabled,
        balance,
        validatorCount: validatorsAbleToUnstake,
        sourceType: source,
        value: source,
        ...optionRenderProps[source],
        subLabel: (
          <Fragment>
            {source === UnstakeSourceType.pooledBalance && (
              <Fragment>
                <Money {...provider?.earningInterestNotional} hideTrailingSign />
                {` • `}
              </Fragment>
            )}
            {source === UnstakeSourceType.validatorBalance && `${validatorsAbleToUnstake} • `}
            {Boolean(balance) ? (
              <Money data-testid="unstake-source-redeemable-balance" {...balance} />
            ) : (
              intl.formatMessage({ defaultMessage: "No balance" })
            )}
          </Fragment>
        ),
      };
    }, {} as Record<GrowProviderType, SelectionItemProps[]>);

  const handleChange = useCallback(
    (newVal: UnstakeSourceType) => {
      onChange?.(newVal);
    },
    [onChange]
  );

  useEffect(() => {
    if (!Boolean(availableUnstakeSources.length)) return;
    if (!Boolean(availableUnstakeSources.find(s => s.value === unstakeSource))) {
      handleChange(availableUnstakeSources?.[0]?.value);
    }
  }, [availableUnstakeSources, unstakeSource, handleChange]);

  return (
    <Fragment>
      {availableProviderTypes?.length > 1 && (
        <Select<GrowProviderType>
          data-testid={testIds.redeem.placeRedeem.unstakeSourceProviderType}
          label={intl.formatMessage({ defaultMessage: "Staking method" })}
          name="unstake-provider-type"
          options={availableProviderTypes}
          onChange={setSelectedProviderType}
          value={selectedProviderType}
          isSearchable={false}
          size="lg"
        />
      )}
      <Select<UnstakeSourceType>
        data-testid={testIds.redeem.placeRedeem.unstakeSourceSelection}
        label=""
        name="unstake-source"
        options={availableUnstakeSources}
        onChange={handleChange}
        value={unstakeSource}
        formatOptionLabel={DropdownItem}
        isSearchable={false}
        size="lg"
        disabled={!(availableUnstakeSources?.length > 1)}
      />
    </Fragment>
  );
};
