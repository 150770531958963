import { CurrencyShortName, CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { Validator } from "@gemini-ui/constants/earn";
import { UnstakeRedeemSource } from "@gemini-ui/pages/Earn/Redeem/types";

export enum StakingDepositMethod {
  TRANSFER = "transfer",
  BUY_AND_STAKE = "buyAndStake",
}

export type StakingTransferQueryParams = {
  providerId: string;
  currency: CurrencyShortName;
  amount: string | number;
};

export type StakingValidatorQueryParams = {
  currency: CurrencyShortName;
  validatorId: string | number;
};

export type StakingWithdrawQueryParams = {
  amount: string | number;
  currency: CurrencyShortName;
  convert?: boolean;
  providerId: string;
  validatorCount?: string | number;
  redeemSource?: UnstakeRedeemSource;
};

export type StakingValidatorsQueryParams = {
  currency: CurrencyShortNameSupportedCryptos;
};

export type StakingValidatorsResponse = {
  activeValidatorCount: number;
  pendingValidatorCount: number;
  validators: Validator[];
};

export type StakingDataUrlParams = never;
export type StakingDepositUrlParams = never;
export type StakingTransferQuoteUrlParams = StakingTransferQueryParams;
export type StakingValidatorDetailsUrlParams = StakingValidatorQueryParams;
export type StakingValidatorsListUrlParams = StakingValidatorsQueryParams;
export type StakingPagetUrlParams = StakingValidatorsQueryParams;
export type StakingWithdrawUrlParams = never;
