import { createElement, FunctionComponent, PropsWithChildren } from "react";
import { useTheme } from "@emotion/react";
import { IconProps } from "@hubble/icons/web";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils/themeUtils";

export const iconSizeToDimension = {
  xs: Spacing.scale[2],
  sm: Spacing.scale[2.5],
  md: Spacing.scale[3],
  lg: Spacing.scale[3.5],
  xl: Spacing.scale[4],
  xxl: Spacing.scale[5],
};

const createIconComponent =
  (content, viewBoxWidth, viewBoxHeight): FunctionComponent<PropsWithChildren<IconProps>> =>
  ({ size = "md", color, ...props }: IconProps) => {
    const theme = useTheme();
    const svgColor = color ? color : getColor(Colors.black, Colors.white)({ theme });
    return createElement(
      "svg",
      {
        ...props,
        width: iconSizeToDimension[size],
        height: iconSizeToDimension[size],
        fill: "currentcolor",
        viewBox: `0 0 ${viewBoxWidth} ${viewBoxHeight}`,
        style: {
          color: svgColor,
        },
      },
      content
    );
  };
export default createIconComponent;
