import { Button, Feedback, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

const PlaidUnsupportedModal = ({ isOpen, onClose, onSubmit }) => {
  const { intl } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        defaultMessage: "Instant linking not supported by your bank",
      })}
    >
      <Feedback status="warning">
        <Feedback.Title>
          {intl.formatMessage({
            defaultMessage: "Unfortunately, your bank is not supported by Plaid.",
          })}
        </Feedback.Title>
        <Feedback.Body>
          <Text.Body size="sm">
            {intl.formatMessage({
              defaultMessage: "Please manually register your account.",
            })}
          </Text.Body>
        </Feedback.Body>
      </Feedback>

      <Button.Group>
        <Button.Tertiary onClick={onClose} data-testid="cancel-plaid-unsupported">
          {intl.formatMessage({
            defaultMessage: "Cancel",
          })}
        </Button.Tertiary>
        <Button.Primary onClick={onSubmit} data-testid="plaid-unsupported-manually-link">
          {intl.formatMessage({
            defaultMessage: "Manually link bank",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

export default PlaidUnsupportedModal;
