import { Component, Fragment } from "react";

interface PaymentMethodProps {
  handleSubmit: (SessionId: string) => void;
  jwt: string;
  bin: string;
  url: string;
}
class DeviceData extends Component<PaymentMethodProps, {}> {
  componentDidMount() {
    window.addEventListener("message", this.handleMessage, false);
    (document.getElementById("collectionForm") as HTMLFormElement).submit();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage, false);
  }

  handleMessage = (x: MessageEvent) => {
    if (x.origin.endsWith(".worldpay.com") || x.origin.endsWith(".cardinalcommerce.com")) {
      this.props.handleSubmit(JSON.parse(x.data).SessionId);
    }
  };

  render() {
    const { jwt, bin, url } = this.props;

    return (
      <Fragment>
        <iframe id="blank" name="blank" title="blank" height="1" width="1" style={{ display: "none" }}></iframe>
        <iframe id="iframe" name="iframe" title="3ds" height="1" width="1" style={{ display: "none" }}>
          <form id="collectionForm" name="devicedata" method="POST" action={url} target="blank">
            <input type="hidden" name="Bin" value={bin} />
            <input type="hidden" name="JWT" value={jwt} />
          </form>
        </iframe>
      </Fragment>
    );
  }
}

export default DeviceData;
