import { ReactNode } from "react";
import { useTheme } from "@emotion/react";
import { PaymentMethodInfo, PaymentMethodItemContainer } from "@gemini-ui/components/PaymentMethodItem/styles";
import { Spacer, Text } from "@gemini-ui/design-system";
import { Flex, FlexProps } from "@gemini-ui/design-system/Flex";

type PaymentMethodItemType = {
  displayText: ReactNode;
  infoText?: ReactNode;
  error?: ReactNode;
  icon: ReactNode;
  rightIcon?: ReactNode;
} & FlexProps;

export const PaymentMethodItem = ({
  error,
  displayText,
  infoText,
  icon,
  rightIcon,
  ...props
}: PaymentMethodItemType) => {
  const { colorScheme } = useTheme();

  return (
    <PaymentMethodItemContainer {...props}>
      <Flex>{icon}</Flex>
      <PaymentMethodInfo flexDirection="column" ml={2}>
        <Text.Body bold>{displayText}</Text.Body>
        {Boolean(infoText) && (
          <Text.Body size="xs" color={colorScheme.content.secondary}>
            {infoText}
          </Text.Body>
        )}
        {Boolean(error) && (
          <Text.Body as="span" size="xs" color={colorScheme.status.default.content.negative}>
            {error}
          </Text.Body>
        )}
      </PaymentMethodInfo>
      {rightIcon && <Spacer ml={1}>{rightIcon}</Spacer>}
    </PaymentMethodItemContainer>
  );
};
