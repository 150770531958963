import { useController, UseControllerProps } from "react-hook-form";
import { Flex } from "@gemini-ui/design-system";
import StakingProInput, { InputProps } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/StakingProInput";
import { approxValidatorETHValue } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/utils";
import { ValidatorIncrementDecrementDirection } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/constants";
import { StakingProPresets } from "@gemini-ui/pages/Earn/GrowBuy/components/StakingDepositPresets/StakingProPresets";
import { DepositFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingDepositForm";
import { WithdrawalFormValues } from "@gemini-ui/pages/Earn/GrowBuy/useStakingWithdrawalForm";

type UseStakingFormControllerType = UseControllerProps<DepositFormValues | WithdrawalFormValues>;

export interface ValidatorAmountInputsProps extends InputProps {
  controls: {
    amount: UseStakingFormControllerType;
    validatorCount: UseStakingFormControllerType;
  };
  maxValidators: number;
}

export const ValidatorAmountInputs = ({ maxValidators, controls, ...rest }: ValidatorAmountInputsProps) => {
  const {
    field: { value: approxAmount, onChange: amountOnChange },
  } = useController(controls.amount);

  const {
    field: { value: validatorCount, onChange, onBlur },
    fieldState: { error },
  } = useController(controls.validatorCount);

  const handleUpdateFields = (newValidatorCount: number | string) => {
    const _validatorCount = newValidatorCount.toString();
    onChange(_validatorCount);
    amountOnChange(approxValidatorETHValue(_validatorCount).toString());
  };

  const handlePercent = (percent: number) => {
    handleUpdateFields(Math.floor(maxValidators * percent));
  };

  const handleIncrementDecrement = (direction: ValidatorIncrementDecrementDirection) => {
    const currentCount = Number(validatorCount ?? "0");
    const newCount = direction === ValidatorIncrementDecrementDirection.INCREMENT ? currentCount + 1 : currentCount - 1;
    handleUpdateFields(newCount);
  };

  return (
    <Flex flexDirection="column">
      <StakingProInput
        {...rest}
        value={validatorCount}
        approxValue={approxAmount ?? "0"}
        onBlur={onBlur}
        error={error ? error.message : null}
        onChange={handleUpdateFields}
      />

      <StakingProPresets
        isIncrementDisabled={Number(validatorCount ?? "0") >= maxValidators}
        isDecrementDisabled={Number(validatorCount ?? "0") <= 0}
        isHalfDisabled={maxValidators < 2}
        onClickPercent={handlePercent}
        onIncrementClick={() => {
          handleIncrementDecrement(ValidatorIncrementDecrementDirection.INCREMENT);
        }}
        onDecrementClick={() => {
          handleIncrementDecrement(ValidatorIncrementDecrementDirection.DECREMENT);
        }}
      />
    </Flex>
  );
};
