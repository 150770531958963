import { Fragment } from "react";
import { optimizelyClient } from "@gemini-ui/analytics";
import { HubbleTitle, Title } from "@gemini-ui/components/Header/navigation/GeminiHome/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { isMpcCustodyAccountSelected } from "@gemini-ui/utils/custody";
import { isDerivativesEnabled } from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";

export const HeaderTitle = () => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user,
      user: { isCustodyAccount },
    },
  } = usePageData();

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  const getTitle = () => {
    if (isDerivativesEnabled(user)) {
      return intl.formatMessage({ defaultMessage: "Derivatives" });
    }

    if (isMpcCustodyAccountSelected(user)) {
      return intl.formatMessage({ defaultMessage: "MPC Custody" });
    }

    if (isCustodyAccount) {
      return intl.formatMessage({ defaultMessage: "Custody" });
    }

    return intl.formatMessage({ defaultMessage: "Exchange" });
  };

  return (
    <Fragment>
      {isHeaderV2Enabled ? (
        <HubbleTitle data-testid="header-title" size="xs" ml={1} bold>
          {getTitle()}
        </HubbleTitle>
      ) : (
        <Title>{getTitle()}</Title>
      )}
    </Fragment>
  );
};
