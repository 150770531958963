import { Fragment } from "react";
import { useTheme } from "@emotion/react";
import {
  IconChartAreaBaseline,
  IconChartLineStep,
  IconCreditCard,
  IconGeminiPay,
  IconHomeOutlined,
  IconMarket,
  IconPortfolio,
  IconStaking,
} from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import {
  PageLink,
  StakeLink,
} from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/components";
import { PageLinksProps } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/types";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { CC_PATH } from "@gemini-ui/pages/Credit/utils/constants";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { useIntl } from "@gemini-ui/utils/intl";

export const PageLinks = ({
  advancedTradeUIEnabled,
  isCustodyAccount,
  pageName,
  showCardLink,
  showClearingLink,
}: PageLinksProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  const { AdvancedMarketsPageEnabled, GeminiCreditCardEnabled } = useFeatureFlags();
  const { eligibleForGrow } = useGrowFeatureFlags();

  const isConsolidatedPortfolioEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PORTFOLIO_CONSOLIDATION
  );

  return (
    <Fragment>
      {isCustodyAccount ? (
        <PageLink
          href="/custody"
          title={intl.formatMessage({ defaultMessage: "Dashboard" })}
          active={pageName === PageName.CustodyDashboard}
          icon={<IconHomeOutlined size="sm" color={colorScheme.content.primary} />}
        />
      ) : (
        <PageLink
          href="/"
          title={intl.formatMessage({ defaultMessage: "Home" })}
          icon={<IconHomeOutlined size="sm" color={colorScheme.content.primary} />}
        />
      )}
      {!isCustodyAccount &&
        (advancedTradeUIEnabled ? (
          <Fragment>
            <PageLink
              href="/"
              title={intl.formatMessage({ defaultMessage: "Trade" })}
              active={pageName === PageName.Trade}
              icon={<IconMarket size="sm" color={colorScheme.content.primary} />}
            />
            {AdvancedMarketsPageEnabled && (
              <PageLink
                href="/advanced/markets"
                title={intl.formatMessage({ defaultMessage: "Markets" })}
                icon={<IconChartLineStep size="sm" color={colorScheme.content.primary} />}
              />
            )}
            {eligibleForGrow && (
              <StakeLink
                title={intl.formatMessage({ defaultMessage: "Stake" })}
                active={pageName === PageName.Stake}
                icon={<IconStaking size="sm" color={colorScheme.content.primary} />}
              />
            )}
            {showCardLink && GeminiCreditCardEnabled && (
              <PageLink
                href={CC_PATH.DASHBOARD}
                title={intl.formatMessage({ defaultMessage: "Card" })}
                active={pageName === PageName.CreditDashboard}
                icon={<IconCreditCard data-testid="card-link-icon" size="sm" color={colorScheme.content.primary} />}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <PageLink
              href="/market"
              title={intl.formatMessage({ defaultMessage: "Market" })}
              active={pageName === PageName.Market || window?.location?.pathname === "/"}
              icon={<IconMarket size="sm" color={colorScheme.content.primary} />}
            />
            {eligibleForGrow && (
              <StakeLink
                title={intl.formatMessage({ defaultMessage: "Stake" })}
                active={pageName === PageName.Stake}
                icon={<IconStaking size="sm" color={colorScheme.content.primary} />}
              />
            )}
            {showCardLink && GeminiCreditCardEnabled && (
              <PageLink
                href={CC_PATH.DASHBOARD}
                title={intl.formatMessage({ defaultMessage: "Card" })}
                active={pageName === PageName.CreditDashboard}
                icon={<IconCreditCard data-testid="card-link-icon" size="sm" color={colorScheme.content.primary} />}
              />
            )}
          </Fragment>
        ))}

      {(isConsolidatedPortfolioEnabled || (!advancedTradeUIEnabled && !isCustodyAccount)) && (
        <PageLink
          href="/portfolio"
          title={intl.formatMessage({ defaultMessage: "Portfolio" })}
          active={pageName === PageName.Portfolio}
          icon={<IconPortfolio size="sm" color={colorScheme.content.primary} />}
        />
      )}

      {showClearingLink && (
        <PageLink
          testId="header-clearing"
          title={intl.formatMessage({ defaultMessage: "Clearing" })}
          active={pageName === PageName.ClearingDashboard}
          href={jsRoutes.controllers.clearing.GCOrderController.get().url}
          icon={<IconGeminiPay size="sm" color={colorScheme.content.primary} />}
        />
      )}

      {!isCustodyAccount && advancedTradeUIEnabled && !isConsolidatedPortfolioEnabled && (
        <PageLink
          title={intl.formatMessage({ defaultMessage: "Balances" })}
          active={pageName === PageName.Balances || pageName === PageName.BalancesV2}
          href={jsRoutes.controllers.retail.BalanceControllerV2.get().url}
          icon={<IconChartAreaBaseline size="sm" color={colorScheme.content.primary} />}
        />
      )}
    </Fragment>
  );
};
