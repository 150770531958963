import { DerivativeDebitCardFundingProps } from "@gemini-ui/components/Header/DerivativeDebitCardFunding/constants";
import { LazyModalWrapper } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";

const DerivativeDebitCardFundingModal = LazyModalWrapper(() => import("./index"));

const LazyLoadDerivativeDebitCardFundingModal = ({
  onClose,
  isOpen,
  initialModalState,
}: DerivativeDebitCardFundingProps) => {
  return <DerivativeDebitCardFundingModal isOpen={isOpen} onClose={onClose} initialModalState={initialModalState} />;
};

export default LazyLoadDerivativeDebitCardFundingModal;
