import { ChangeEvent, Fragment } from "react";
import { IconArrowsHorizontal } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import { AnalyticsEventName, AnalyticsProperty } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import {
  getOtherTransferMenuData,
  getTransferMenuData,
  HEADER_MENU,
} from "@gemini-ui/components/Header/navigation/data/geminiMenuData";
import { HubbleMenuAccordion } from "@gemini-ui/components/Header/navigation/MobileMenu/HubbleMenuAccordion";
import { ItemTitle } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/MobileMenuComponents/components";
import { StyledItemLink } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/styles";
import { TransferAccordionProps } from "@gemini-ui/components/Header/navigation/MobileMenu/OffcanvasMenuV2/types";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Text } from "@gemini-ui/design-system";
import { handleCashDepositClick } from "@gemini-ui/utils/handleCashDepositClick";
import { handleCashWithdrawClick } from "@gemini-ui/utils/handleCashWithdrawClick";
import {
  handleCryptoDepositInitiation,
  handleCryptoWithdrawInitiation,
} from "@gemini-ui/utils/handleCryptoDepositInitiation";
import { useIntl } from "@gemini-ui/utils/intl";

export const TransferAccordion = ({
  isPerpsTransferEligible,
  moreThanOneAccount,
  pageName,
}: TransferAccordionProps) => {
  const { intl } = useIntl();
  const { toggleModal } = useGlobalModal();

  const isDerivativeDebitCardFundingEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING
  );

  const handleTransferClick = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    href: string,
    analyticsEventName: AnalyticsEventName,
    analyticsProperties: AnalyticsProperty
  ) => {
    e.preventDefault();
    if (id === "deposit-cash") {
      handleCashDepositClick(e, () => toggleModal(GlobalModalType.CashDepositModal), HEADER_MENU);
    } else if (id === "withdraw-cash") {
      handleCashWithdrawClick(e, () => toggleModal(GlobalModalType.CashWithdrawModal), HEADER_MENU, href);
    } else if (id === "deposit-crypto") {
      handleCryptoDepositInitiation(
        e,
        () => toggleModal(GlobalModalType.CryptoDepositModal, { preSelectedCrypto: undefined }),
        analyticsEventName,
        analyticsProperties
      );
    } else if (id === "withdraw-crypto") {
      handleCryptoWithdrawInitiation(
        e,
        () => toggleModal(GlobalModalType.WithdrawCryptoModal),
        analyticsEventName,
        analyticsProperties
      );
    } else if (id === "transfer-between" && pageName === PageName.Trade && isPerpsTransferEligible) {
      toggleModal(GlobalModalType.PerpsOnboardingTransferFormModal);
    } else if (id === "derivative-debit-card-funding" && isDerivativeDebitCardFundingEnabled) {
      toggleModal(GlobalModalType.DerivativeDebitCardFundingModal);
    } else {
      mixpanelClient.trackLinkClickAndRedirectManually(href, analyticsEventName, analyticsProperties);
    }
  };

  return (
    <HubbleMenuAccordion
      leftElement={
        <ItemTitle
          icon={<IconArrowsHorizontal size="sm" />}
          title={intl.formatMessage({ defaultMessage: "Transfer" })}
        />
      }
      content={
        <Fragment>
          {getTransferMenuData(intl).map(({ name, url, id, icon: Icon, analyticsProperties, analyticsEventName }) => (
            <StyledItemLink
              indent
              label=""
              key={id}
              href="#"
              onSelect={e => handleTransferClick(e, id, url, analyticsEventName, analyticsProperties)}
              leftElement={
                <Text.Body fontSize="12px" lineHeight="16px" bold>
                  {name}
                </Text.Body>
              }
            />
          ))}
          {moreThanOneAccount &&
            getOtherTransferMenuData(moreThanOneAccount, intl).map(
              ({ id, url, name, analyticsEventName, analyticsProperties }) => (
                <StyledItemLink
                  indent
                  label=""
                  key={id}
                  href="#"
                  onSelect={e => handleTransferClick(e, id, url, analyticsEventName, analyticsProperties)}
                  leftElement={
                    <Text.Body fontSize="12px" lineHeight="16px" bold>
                      {name}
                    </Text.Body>
                  }
                />
              )
            )}
        </Fragment>
      }
    />
  );
};
