import { ReactNode } from "react";
import { IntlShape } from "react-intl";
import { Underlined } from "@gemini-ui/design-system/FileUploader/styles";

export const getFileUploaderCopy = (intl: IntlShape) => ({
  BROWSE_FILES: intl.formatMessage({ defaultMessage: "Browse files to upload" }),
  DRAG_N_DROP: (disabled: boolean) =>
    intl.formatMessage(
      { defaultMessage: "Drag and drop or <underlined>browse files</underlined> to upload" },
      {
        underlined: (str: ReactNode) => (
          <Underlined color={disabled && "inherit"} as="span">
            {str}
          </Underlined>
        ),
      }
    ),
  MAX_SIZE: (maxSize: string) =>
    intl.formatMessage(
      { defaultMessage: "File cannot exceed {maxSize}." },
      {
        maxSize,
      }
    ),
  EXISTS: (fileName: string) =>
    intl.formatMessage(
      { defaultMessage: "File {fileName} already exists." },
      {
        fileName,
      }
    ),
  MAX_FILES: (maxAmount: number) =>
    intl.formatMessage(
      { defaultMessage: "You reached the maximum of {maxAmount} files." },
      {
        maxAmount,
      }
    ),
  MAX_POLICY_SIZE: intl.formatMessage({ defaultMessage: "File cannot exceed 15MB by Policy." }),
  NO_FILES: intl.formatMessage({ defaultMessage: "No files uploaded" }),
  CANCELLED: intl.formatMessage({ defaultMessage: "Upload canceled by the user." }),
  FAILED: intl.formatMessage({ defaultMessage: "Upload failed. Please try again." }),
  ARIA_LABELS: {
    CLOSE: intl.formatMessage({ defaultMessage: "Cancel upload" }),
    REMOVE: intl.formatMessage({ defaultMessage: "Remove file" }),
    RETRY: intl.formatMessage({ defaultMessage: "Retry upload file" }),
  },
});
