import { Button, Modal, Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

type GiactErrorProps = {
  isOpen: boolean;
  onClose: () => void;
  handleFallbackToWire: () => void;
};

export const GiactError = ({ isOpen, onClose, handleFallbackToWire }: GiactErrorProps) => {
  const { intl } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      title={intl.formatMessage({
        defaultMessage: "Could not verify the bank account information",
      })}
    >
      <Text.Body mb={6}>
        {intl.formatMessage({
          defaultMessage: "Try adding your bank account for wire transfers, or use another payment method instead.",
        })}
      </Text.Body>
      <Button.Group type="stacked">
        <Button.Primary data-testid="fallback-to-wire-verification-btn" onClick={handleFallbackToWire}>
          {intl.formatMessage({
            defaultMessage: "Add for Wire transfers",
          })}
        </Button.Primary>
        <Button.Secondary data-testid="confirm-bank-details-cancel" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: "Add another payment method",
          })}
        </Button.Secondary>
      </Button.Group>
    </Modal>
  );
};
