import { Fragment } from "react";
import { IconSupportOutlined } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import {
  FEEDBACK_BUTTON_ID,
  FeedbackButtonProps,
} from "@gemini-ui/components/Header/navigation/FeedbackButton/constants";
import { NavIcon, NavIconLabel } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { HubbleButton } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

const FeedbackButton = ({ onClick }: FeedbackButtonProps) => {
  const { isModalOpen } = useGlobalModal();
  const { intl } = useIntl();
  const isATIFeedbackModalOpen = isModalOpen(GlobalModalType.ATIFeedbackModal);

  const isHeaderV2Enabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.HEADER_V2);

  return (
    <Fragment>
      {isHeaderV2Enabled ? (
        <HubbleButton.Tertiary
          data-testid={FEEDBACK_BUTTON_ID}
          data-id={FEEDBACK_BUTTON_ID}
          size="sm"
          cta={intl.formatMessage({ defaultMessage: "Give Feedback" })}
          leftElement={<IconSupportOutlined />}
          onClick={onClick}
          mr={0.5}
        />
      ) : (
        <NavIcon
          data-testid={FEEDBACK_BUTTON_ID}
          data-id={FEEDBACK_BUTTON_ID}
          onClick={onClick}
          pl={1}
          pr={1}
          size="sm"
          active={isATIFeedbackModalOpen}
        >
          <IconSupportOutlined />
          <NavIconLabel>{intl.formatMessage({ defaultMessage: "Give Feedback" })}</NavIconLabel>
        </NavIcon>
      )}
    </Fragment>
  );
};

export default FeedbackButton;
