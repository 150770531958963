import { useMemo } from "react";
import { Flex, Segment, Spacing } from "@gemini-ui/design-system";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

export const StakeUnstakeButtons = () => {
  const { intl } = useIntl();
  const { asset, transactionType, setTransactionType } = useGrowBuy();

  const { enableStake, enableUnstake } = useMemo(() => {
    return {
      enableStake: true, // Leaving this here in case we want to disable staking in the future
      enableUnstake: Number(asset?.earningInterest?.value ?? 0) > 0,
    };
  }, [asset]);

  return (
    <Flex flexDirection="column" gap={Spacing.scale[2]}>
      <Segment
        value={transactionType}
        onChange={val => {
          setTransactionType(val as GrowTransactionType); // todo: the typing on this Segment thing could use work
        }}
        mt={0}
        stretch
      >
        <Segment.Button
          data-testid={`${testIds.buyModule.segmentBtn}-${GrowTransactionType.STAKE}`}
          value={GrowTransactionType.STAKE}
          cta={intl.formatMessage({ defaultMessage: "Stake" })}
          disabled={!enableStake}
        />
        <Segment.Button
          data-testid={`${testIds.buyModule.segmentBtn}-${GrowTransactionType.UNSTAKE}`}
          value={GrowTransactionType.UNSTAKE}
          cta={intl.formatMessage({ defaultMessage: "Unstake" })}
          disabled={!enableUnstake}
        />
      </Segment>
    </Flex>
  );
};
