import { IconCheck, IconChevronDownSmall } from "@hubble/icons";
import { border, Button, Flex, HubbleMenu as Menu, Spacing } from "@gemini-ui/design-system";
import { SelectOptionProps } from "@gemini-ui/design-system/forms/Select/constants";
import { GrowTransactionType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export const StakeDirectionSelector = ({
  onChange,
  options,
  selected,
}: {
  onChange: (direction: GrowTransactionType) => void;
  options: SelectOptionProps<GrowTransactionType>[];
  selected: GrowTransactionType;
}) => {
  const { intl } = useIntl();
  const label = (direction: GrowTransactionType) =>
    intl.formatMessage(
      defineMessage({
        defaultMessage: "{direction, select, stake {Stake} unstake {Unstake} other {Select an action}}",
      }),
      { direction }
    );

  if (options.length === 0) return null;

  return (
    <Menu>
      <Menu.Trigger>
        <Button.Tertiary
          css={{
            borderRadius: border.radius.md,
            paddingRight: Spacing.scale[2],
            paddingLeft: Spacing.scale[2],
          }}
        >
          {label(selected)}
          <IconChevronDownSmall size="sm" css={{ marginLeft: Spacing.scale[1] }} />
        </Button.Tertiary>
      </Menu.Trigger>
      <Menu.Content width="315" align="start">
        {options.map((option, idx) => (
          <Menu.Item
            key={`${option.value}-${idx}`}
            label={option.label as string}
            disabled={option.isDisabled}
            rightElement={<Flex ml={2}>{option.value === selected ? <IconCheck size="sm" /> : undefined}</Flex>}
            onClick={() => {
              if (option?.isDisabled) return;
              onChange(option.value);
            }}
          />
        ))}
      </Menu.Content>
    </Menu>
  );
};
