export function CitizensBankIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#008555" />
      <path
        d="M27.879 27.879a1.34 1.34 0 0 1-1.891 0l-3.09-3.091v2.145a1.212 1.212 0 0 1-1.304 1.328 1.327 1.327 0 0 1-1.345-1.328v-2.648a1.321 1.321 0 0 1 .878-1.255 3.327 3.327 0 0 0 1.904-1.903 1.327 1.327 0 0 1 1.248-.842h2.673a1.327 1.327 0 0 1 1.327 1.345 1.212 1.212 0 0 1-1.327 1.303h-2.146l3.091 3.091c.49.524.481 1.34-.018 1.855ZM27.879 12.121a1.346 1.346 0 0 1 0 1.897l-3.091 3.091h2.145a1.212 1.212 0 0 1 1.328 1.303 1.327 1.327 0 0 1-1.328 1.346h-2.648a1.327 1.327 0 0 1-1.255-.885 3.327 3.327 0 0 0-1.903-1.903c-.51-.2-.845-.694-.842-1.243v-2.685a1.327 1.327 0 0 1 1.346-1.327 1.213 1.213 0 0 1 1.302 1.327v2.146l3.091-3.091a1.34 1.34 0 0 1 1.855.024ZM12.121 12.121a1.34 1.34 0 0 1 1.897 0l3.091 3.091v-2.145a1.212 1.212 0 0 1 1.31-1.328 1.327 1.327 0 0 1 1.345 1.328v2.672a1.321 1.321 0 0 1-.891 1.23 3.333 3.333 0 0 0-1.903 1.904c-.2.51-.694.845-1.243.842h-2.684a1.327 1.327 0 0 1-1.328-1.345 1.212 1.212 0 0 1 1.328-1.31h2.145l-3.067-3.072a1.34 1.34 0 0 1 0-1.867ZM12.121 27.879a1.34 1.34 0 0 1 0-1.897l3.091-3.091h-2.145a1.212 1.212 0 0 1-1.327-1.303 1.327 1.327 0 0 1 1.327-1.346h2.673c.555.008 1.046.361 1.23.885a3.333 3.333 0 0 0 1.903 1.903c.51.2.845.694.842 1.243v2.672a1.328 1.328 0 0 1-1.345 1.328 1.212 1.212 0 0 1-1.303-1.328V24.8l-3.079 3.079a1.34 1.34 0 0 1-1.867 0Z"
        fill="#fff"
      />
    </svg>
  );
}
