export function CitiIcon(props) {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z" fill="#07509D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.727 15.758a8.8 8.8 0 0 0-14.43 0l-.085.12h2.09a7.109 7.109 0 0 1 10.377 0h2.084l-.085-.12h.049Z"
        fill="#fff"
      />
      <path
        d="m14.388 23.564-.043.036a3.03 3.03 0 0 1-2.145 1 2.715 2.715 0 0 1-2.758-2.855 2.715 2.715 0 0 1 2.758-2.854 3.03 3.03 0 0 1 2.145 1.006l.043.042 1.024-1.242v-.036a3.915 3.915 0 0 0-3.14-1.503 4.745 4.745 0 0 0-3.248 1.212 4.46 4.46 0 0 0-1.382 3.394 4.473 4.473 0 0 0 1.382 3.4 4.74 4.74 0 0 0 3.249 1.212 3.915 3.915 0 0 0 3.115-1.527v-.03l-1.025-1.243.025-.012Zm2.066 2.612h1.819v-8.873h-1.819v8.873Zm8.915-1.934c-.397.268-.86.42-1.339.443-.606 0-.848-.31-.848-1v-4.703H25v-1.673h-1.818v-2.764l-1.776.952v1.818h-1.534v1.673h1.534v5.006c0 1.364.806 2.297 2.012 2.321a2.86 2.86 0 0 0 1.612-.406l.424-1.727-.109.067.024-.007Zm1.503 1.928h1.819v-8.867h-1.819v8.873-.006Zm5.498-10.03c0 .102-.073.145-.164.145h-.273V16h.273a.145.145 0 0 1 .163.145v-.006Zm.181 0a.303.303 0 0 0-.34-.316h-.454v1.037h.176v-.4h.188l.206.4h.206l-.212-.419a.297.297 0 0 0 .218-.309l.012.006Zm.515.224a.951.951 0 1 0-.945.957.933.933 0 0 0 .945-.957Zm-.151 0a.8.8 0 1 1-.794-.794.782.782 0 0 1 .794.794Z"
        fill="#fff"
      />
    </svg>
  );
}
