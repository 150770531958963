import { useController, useForm } from "react-hook-form";
import { Button, Form, Input, Modal, Select, Text } from "@gemini-ui/design-system";
import { FormRow } from "@gemini-ui/design-system/forms/Form";
import { FIELD_NAMES, FormValues, NEURO_IDS } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { useFormValidation } from "@gemini-ui/pages/Credit/CreditApplication/hooks";
import { ViewTitle } from "@gemini-ui/pages/Credit/CreditApplication/styles";
import { testIds } from "@gemini-ui/pages/Credit/CreditApplication/testIds";
import { objectToDropdownArray } from "@gemini-ui/pages/register/Register/utils";
import US_States from "@gemini-ui/US_States";
import { useIntl } from "@gemini-ui/utils/intl";

interface EditAddressModalProps {
  onClose: () => void;
  street: string;
  apt: string;
  city: string;
  state: string;
  zip: string;
  onSubmit: (values: FormValues) => void;
}

export const EditAddressModal = ({ onClose, street, apt, city, state, zip, onSubmit }: EditAddressModalProps) => {
  const { intl } = useIntl();
  const { handleSubmit, register, control, formState } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      street,
      apt,
      city,
      state,
      zip,
    },
  });
  const { errors, isValid, isSubmitting } = formState;
  const stateItems = objectToDropdownArray(US_States.stateMenu);
  const validation = useFormValidation();
  const { field: stateSelect } = useController({
    name: FIELD_NAMES.state,
    control,
    rules: validation.state,
  });

  return (
    <Modal isOpen onClose={onClose}>
      <ViewTitle>
        <Text.Heading size="md">{intl.formatMessage({ defaultMessage: "Edit your primary address" })}</Text.Heading>
      </ViewTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register(FIELD_NAMES.street, validation.street)}
          data-testid={testIds.input.addressStreet}
          label={intl.formatMessage({ defaultMessage: "Street address" })}
          placeholder={intl.formatMessage({ defaultMessage: "800 E. Main St." })}
          message={intl.formatMessage({ defaultMessage: "Primary residential address" })}
          error={errors?.street?.message}
          data-nid-target={NEURO_IDS.address}
          inputSize="lg"
        />
        <Input
          {...register(FIELD_NAMES.apt, validation.apt)}
          data-testid={testIds.input.addressApartment}
          label={intl.formatMessage({ defaultMessage: "Apt, Suite, Bldg (optional)" })}
          placeholder={intl.formatMessage({ defaultMessage: "501" })}
          error={errors?.apt?.message}
          data-nid-target={NEURO_IDS.address}
          inputSize="lg"
        />
        <Input
          {...register(FIELD_NAMES.city, validation.city)}
          data-testid={testIds.input.addressCity}
          label={intl.formatMessage({ defaultMessage: "City" })}
          placeholder={intl.formatMessage({ defaultMessage: "New York City" })}
          error={errors?.city?.message}
          data-nid-target={NEURO_IDS.city}
          inputSize="lg"
        />
        <FormRow>
          <Select
            label={intl.formatMessage({ defaultMessage: "State" })}
            options={stateItems}
            data-testid={testIds.input.addressRegion}
            error={errors?.state?.message}
            size="lg"
            {...stateSelect}
          />
          <Input
            {...register(FIELD_NAMES.zip, validation.zip)}
            data-testid={testIds.input.addressPostalCode}
            label={intl.formatMessage({ defaultMessage: "Zip" })}
            placeholder={intl.formatMessage({ defaultMessage: "55555" })}
            title="55555"
            error={errors?.zip?.message}
            data-nid-target={NEURO_IDS.zip}
            inputSize="lg"
            width="50%"
          />
        </FormRow>
        <Button.Group align="right">
          <Button.Tertiary data-testid={testIds.button.cancel} onClick={onClose}>
            {intl.formatMessage({ defaultMessage: "Cancel" })}
          </Button.Tertiary>
          <Button.Primary
            data-testid={testIds.button.saveAddress}
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          >
            {intl.formatMessage({ defaultMessage: "Save address" })}
          </Button.Primary>
        </Button.Group>
      </Form>
    </Modal>
  );
};
