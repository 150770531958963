import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonGroupProps } from "@gemini-ui/design-system/Button/constants";
import { Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { getColor } from "@gemini-ui/design-system/utils/themeUtils";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";

const baseStyles = css`
  display: flex;
  margin-top: ${Spacing.scale[3]};
`;

const FLEX_ALIGN_MAP = {
  right: "flex-end",
  left: "flex-start",
  center: "center",
};

const ActionButtonGroup = styled("div")<{ align?: ButtonGroupProps["align"] }>`
  ${baseStyles}

  align-items: center;

  > :last-child {
    margin-left: ${Spacing.scale[1]};
  }

  justify-content: ${({ align }) => FLEX_ALIGN_MAP[align] || FLEX_ALIGN_MAP.center};
  ${({ align }) => {
    if (align === "right" || align === "left") {
      return `
      @media ${BREAKPOINTS.mobileDown} {
        flex-direction: column-reverse;
        > :last-child {
          margin-left: 0;
          margin-bottom: ${Spacing.scale[1]};
        }
      }
      `;
    }

    return `
      @media ${BREAKPOINTS.mobileDown} {
        flex-direction: column;
        > :last-child {
          margin-left: 0;
          margin-top: ${Spacing.scale[1]};
        }
      }
    `;
  }}

  /* only-child removes margin when there is only one button */
  > :only-child {
    margin-left: 0;
  }

  @media ${BREAKPOINTS.mobileDown} {
    > :only-child {
      margin: 0;
    }
  }
`;

const StackedButtonGroup = styled("div")<{ anchored?: boolean }>`
  ${baseStyles}

  flex-direction: column;

  > :last-child {
    margin-top: ${Spacing.scale[1]};
  }

  @media ${BREAKPOINTS.mobileDown} {
    ${props => {
      const backgroundColor = getColor(Colors.white, Colors.gray[900])(props);
      return (
        props.anchored &&
        `
          position: sticky;
          padding-top: ${Spacing.scale[4]};
          padding-bottom: ${Spacing.scale[2]};
          bottom: 0;
          mask-image: linear-gradient(to top, ${backgroundColor} 80%, transparent 100%);
          background: ${backgroundColor};
        `
      );
    }}
  }
`;

const CenteredButtonGroup = styled("div")`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${Spacing.scale[3]};
  width: 100%;
  align-items: center;
`;

const JustifiedButtonGroup = styled("div")`
  ${baseStyles}

  justify-content: space-between;
  align-items: center;

  @media ${BREAKPOINTS.mobileDown} {
    flex-direction: column;
    align-items: flex-end;

    > :last-child {
      justify-content: flex-end;
    }
  }
`;

const ButtonGroup = ({ children, type = "action", anchored = true, align = "right", className }: ButtonGroupProps) => {
  switch (type) {
    case "action": {
      return (
        <ActionButtonGroup align={align} className={className}>
          {children}
        </ActionButtonGroup>
      );
    }
    case "stacked": {
      return (
        <StackedButtonGroup anchored={anchored} className={className}>
          {children}
        </StackedButtonGroup>
      );
    }
    case "centered": {
      return <CenteredButtonGroup className={className}>{children}</CenteredButtonGroup>;
    }
    case "justified": {
      return <JustifiedButtonGroup className={className}>{children}</JustifiedButtonGroup>;
    }
  }
};

export default ButtonGroup;
