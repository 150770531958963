import { ElementType, forwardRef } from "react";
import styled from "@emotion/styled";
import { text } from "@gemini-ui/design-system/primitives";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import { BaseText, getLargeTextStyles } from "@gemini-ui/design-system/Text/styles";

type HeadingSize = keyof (typeof text)["typesets"]["heading"]["desktop"];

type CustomizableHeadingProps = Omit<BaseTextProps, "fontSize" | "bold" | "italic" | "underline">;

interface HeadingProps extends CustomizableHeadingProps {
  size?: HeadingSize;
}

const TYPE = "heading";
const COMPONENT_NAME = "Text.Heading";

const HeadingTextResponsive = styled(BaseText)<HeadingProps>`
  ${props => getLargeTextStyles(TYPE, COMPONENT_NAME, props.size, props.numeric)}
`;

const DEFAULT_AS_HEADING_MAP: Record<HeadingSize, ElementType<any>> = {
  xxs: "h4",
  xs: "h4",
  sm: "h3",
  md: "h2",
  lg: "h2",
  xl: "h1",
};

const HeadingText = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ as, size = "lg", ...restProps }: HeadingProps, ref) => {
    return <HeadingTextResponsive ref={ref} as={as ? as : DEFAULT_AS_HEADING_MAP[size]} size={size} {...restProps} />;
  }
);

export default HeadingText;
