import { useEffect, useMemo, useRef } from "react";
import { AnimationConfig, AnimationItem, SVGRendererConfig } from "lottie-web";
import lottie from "lottie-web/build/player/lottie_light";
import { Flex } from "@gemini-ui/design-system";

interface Props extends Pick<AnimationConfig, "loop" | "autoplay" | "rendererSettings"> {
  file: any;
  height?: number;
  delay?: number;
  segmentsToPlay?: AnimationItem["segments"];
  "data-testid"?: string;
}

const defaultRenderSettings: SVGRendererConfig = {
  preserveAspectRatio: "xMidYMid slice",
  progressiveLoad: true,
};

export const AnimatedHeader = ({
  file,
  delay = 1500,
  height = 278,
  loop = false,
  autoplay = false,
  segmentsToPlay,
  rendererSettings = defaultRenderSettings,
  "data-testid": dataTestId,
}: Props) => {
  const animObj = useRef(null);
  const animRef = useRef(null);

  const segments = useMemo(() => {
    // Allow segments to be `undefined` if there is bad file.
    if (segmentsToPlay || !Boolean(file)) return segmentsToPlay;
    // If not segments are provided, default to the first and last frame of the file.
    // Inside a lottie file, `ip` and `op` are keys for the first and last frame.
    return [file.ip ?? 0, file.op ?? 100];
  }, [segmentsToPlay, file]);

  useEffect(() => {
    animObj.current = lottie.loadAnimation({
      animationData: file,
      container: animRef.current,
      renderer: "svg",
      loop,
      autoplay,
      rendererSettings,
    });

    setTimeout(() => {
      if (segments) animObj.current.playSegments(segments, true);
    }, delay);

    return () => {
      // Storybook tests will fail if we don't check for `current.destroy` here.
      if (animObj.current && animObj.current.destroy) {
        animObj.current.destroy();
      }
    };
  }, [loop, autoplay, delay, file, segments, rendererSettings]);

  return (
    <Flex data-testid={dataTestId} align="center" justify="center">
      <div ref={animRef} style={{ height }} />
    </Flex>
  );
};
