import { useState } from "react";
import { ACCOUNT_MENU_VIEWS } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/constants";
import { AccountMenuDefaultView } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/DefaultView";
import { AccountMenuGroupView } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/GroupView";
import { AccountMenuProps } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/types";

export const AccountMenu = ({ userName, institutionName, groupsInfo, onClose }: AccountMenuProps) => {
  const [view, setView] = useState(ACCOUNT_MENU_VIEWS.default);

  return view === ACCOUNT_MENU_VIEWS.default ? (
    <AccountMenuDefaultView
      onClose={onClose}
      setView={setView}
      userName={userName}
      institutionName={institutionName}
      groupsInfo={groupsInfo}
    />
  ) : (
    <AccountMenuGroupView
      onClose={onClose}
      setView={setView}
      userName={userName}
      institutionName={institutionName}
      groupsInfo={groupsInfo}
    />
  );
};
