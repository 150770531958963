import { ElementType, HTMLAttributes, ReactNode } from "react";
import { CSSObject } from "@emotion/react";
import * as CSS from "csstype";
import { FlexboxProps } from "@gemini-ui/design-system/Flex/constants";
import { StyledFlex } from "@gemini-ui/design-system/Flex/styles";
import { cssShorthandPropsFilter, ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives/Spacer";
import { removeDataAndAriaProps } from "@gemini-ui/design-system/utils/removeDataAndAriaProps";

/*
    Modified from Chakra UI flexbox
    https://github.com/chakra-ui/chakra-ui/blob/main/packages/styled-system/src/config/flexbox.ts#L78
*/

interface FlexOptions {
  align?: FlexboxProps["alignItems"];
  justify?: FlexboxProps["justifyContent"];
  wrap?: FlexboxProps["flexWrap"];
  flexDirection?: FlexboxProps["flexDirection"];
  basis?: FlexboxProps["flexBasis"];
  grow?: FlexboxProps["flexGrow"];
  shrink?: FlexboxProps["flexShrink"];
}

export interface FlexProps extends FlexOptions, CSS.Properties, ShorthandSpacingCssProps {
  className?: string;
  children?: ReactNode;
  as?: ElementType;
  "data-testid"?: string;
}

/**
 * React component used to create flexbox layouts.
 *
 * It renders a `div` with `display: flex` and
 * comes with helpful style shorthands:
 *
 * align, justify, wrap, flexDirection, basis, grow, shrink, flex
 */
const Flex = ({
  flexDirection,
  align,
  justify,
  wrap,
  basis,
  grow,
  shrink,
  className,
  as,
  children,
  "data-testid": dataTestId,
  ...rest
}: FlexProps) => {
  const styleProps = {
    display: "flex",
    flexDirection: flexDirection,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
    ...(cssShorthandPropsFilter(removeDataAndAriaProps(rest), true) as ReturnType<
      typeof cssShorthandPropsFilter<typeof rest, true>
    > & {
      // Override React CSS properties that are not supported by emotion
      MozForceBrokenImageIcon: CSSObject["MozForceBrokenImageIcon"];
    }),
  };

  const otherProps = rest as HTMLAttributes<HTMLDivElement>;

  return (
    <StyledFlex
      className={className}
      css={styleProps}
      children={children}
      data-testid={dataTestId}
      {...otherProps}
      as={as}
    />
  );
};

const Column = (props: FlexProps) => {
  return <Flex flexDirection="column" {...props} />;
};

Flex.Column = Column;

export { Flex };
