import { forwardRef } from "react";
import styled from "@emotion/styled";
import { Flex } from "@gemini-ui/design-system/Flex";
import { FieldsetProps } from "@gemini-ui/design-system/forms/Fieldset/constants";
import { Label, StyledLabel } from "@gemini-ui/design-system/forms/Label";
import { Message } from "@gemini-ui/design-system/forms/Message";
import { shorthandSpacingCss } from "@gemini-ui/design-system/primitives/Spacer";

const StyledFieldset = styled.fieldset`
  > ${StyledLabel} {
    margin-bottom: 8px;
  }
  ${shorthandSpacingCss}
`;

const StyledFlex = styled(Flex)<{ isInline?: boolean }>`
  ${StyledLabel} + ${StyledLabel} {
    ${({ isInline }) => !isInline && `margin-top: 8px;`}
  }
`;

export const Fieldset = forwardRef<HTMLFieldSetElement, FieldsetProps>(
  (
    { ["data-testid"]: dataTestId, children, disabled, error, inputSize, isInline, legend, message, name, ...props },
    ref
  ) => (
    <StyledFieldset data-testid={dataTestId} ref={ref} {...props}>
      {Boolean(legend) && <Label isLegend>{legend}</Label>}

      <StyledFlex flexDirection={isInline ? "row" : "column"} isInline={isInline}>
        {children}
      </StyledFlex>

      {(Boolean(error) || Boolean(message)) && <Message error={error} message={message} />}
    </StyledFieldset>
  )
);
