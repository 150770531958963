import { FC, PropsWithChildren } from "react";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { BASE_ZINDEX, BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Spacing } from "@gemini-ui/design-system";
import { ReactComponent as AngleDown } from "@gemini-ui/images/icons/angle-down.svg";

const FakeDropdown = styled("div")<{ displayMenu?: boolean }>`
  min-width: ${Spacing.scale[10]};
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: ${Colors.white};
  font-weight: 700;
  padding: 5px 0 5px 10px;
  border-radius: 3px;
  align-items: center;

  svg {
    transform: rotate(0);
    padding: 0;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    margin-left: ${Spacing.scale[1]};
    margin-right: ${Spacing.scale[0.5]};

    > path {
      fill: #fff;
    }
  }

  ${p =>
    p.displayMenu &&
    css`
      z-index: ${BASE_ZINDEX};
    `}
  @media ${BREAKPOINTS.phoneOnly} {
    padding: 0 0 0 44px;
  }
`;

const ArrowWithRotate = styled(AngleDown, { shouldForwardProp: isPropValid })`
  height: 15px;
  width: 25px;

  @media ${BREAKPOINTS.phoneOnly} {
    border: 1px solid ${Colors.gray[600]};
    border-radius: 8px;
  }

  ${({ displayMenu }) => displayMenu && "transform: rotate(-180deg) !important;"}
`;

interface Props {
  displayMenu: boolean;
}

const HeaderDropdownFauxDisplay: FC<PropsWithChildren<Props>> = props => {
  const { displayMenu, children } = props;
  return (
    <FakeDropdown displayMenu={displayMenu}>
      {children}
      <ArrowWithRotate displayMenu={displayMenu} aria-hidden="true" />
    </FakeDropdown>
  );
};

export default HeaderDropdownFauxDisplay;
