import { forwardRef, MutableRefObject } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { CurrencyDetail } from "@gemini-common/scripts/constants/currencies";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/constants";
import { StyledMaskedInput } from "@gemini-ui/pages/Earn/GrowBuy/components/InputDisplay/styles";

interface Props extends Omit<MaskedInputProps, "ref" | "autoComplete" | "guide" | "mask"> {
  providerType: GrowProviderType;
  currencyDetails: CurrencyDetail;
  isFiat?: boolean;
  render: MaskedInputProps["render"];
}

export const MaskedStakingInput = forwardRef<MaskedInput, Props>(
  ({ providerType, currencyDetails, isFiat, value, ...rest }, ref) => {
    const inputRef = ref as MutableRefObject<MaskedInput>;

    let fontSize = MAX_FONT_SIZE;
    if (inputRef?.current?.inputElement?.clientWidth) {
      const containerWidth = inputRef?.current?.inputElement?.clientWidth;
      const calculatedCharacterWidth = containerWidth / Math.max(value?.toString().length, 1);
      if (!isNaN(calculatedCharacterWidth))
        fontSize = Math.max(Math.min(calculatedCharacterWidth, MAX_FONT_SIZE), MIN_FONT_SIZE);
    }

    const mask =
      providerType === GrowProviderType.PRIVATE_STAKING
        ? createNumberMask({
            prefix: currencyDetails?.leadingSymbol || "",
            suffix: ` VAL`,
            allowDecimal: false,
          })
        : createNumberMask({
            prefix: currencyDetails.leadingSymbol ?? "",
            suffix: isFiat ? "" : ` ${currencyDetails.symbol}`,
            allowDecimal: true,
            decimalLimit: currencyDetails.receiptDecimals,
          });

    const inputProps = { ...rest, fontSize };

    return <StyledMaskedInput ref={inputRef} autoComplete="off" mask={mask} value={value} {...inputProps} />;
  }
);
