import { forwardRef } from "react";
import styled from "@emotion/styled";
import { BaseTextProps } from "@gemini-ui/design-system/Text/constants";
import { BaseText, getLargeTextStyles } from "@gemini-ui/design-system/Text/styles";

type DisplaySize = "xs" | "sm" | "md" | "lg" | "xl";

type CustomizableDisplayProps = Omit<BaseTextProps, "fontSize" | "bold" | "italic" | "underline">;

interface DisplayProps extends CustomizableDisplayProps {
  size?: DisplaySize;
}

const TYPE = "display";
const COMPONENT_NAME = "Text.Display";

const DisplayTextResponsive = styled(BaseText)<DisplayProps>`
  ${props => getLargeTextStyles(TYPE, COMPONENT_NAME, props.size, props.numeric)}
`;

const DisplayText = forwardRef<HTMLHeadingElement, DisplayProps>(
  ({ as = "h1", size = "md", ...restProps }: DisplayProps, ref) => {
    return <DisplayTextResponsive ref={ref} as={as} size={size} {...restProps} />;
  }
);

export default DisplayText;
