import { Fragment } from "react";
import { Colors, Text } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

const signOutUrl = jsRoutes.controllers.account.Application.signOut().url;

export const VerifyPhoneOrRegister = () => {
  const { intl } = useIntl();

  return (
    <Fragment>
      <Text.Body size="xs" color={Colors.white}>
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "For security purposes, you must verify your phone number for your Gemini account in order to apply for the Gemini Credit Card. Please sign in and do so at {link}.",
          }),
          { link: <Text.Link href={signOutUrl}>https://exchange.gemini.com/signin</Text.Link> }
        )}
      </Text.Body>
    </Fragment>
  );
};
