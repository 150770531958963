import { ReactNode } from "react";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconChevronDownSmall, IconClearFilled } from "@hubble/icons";
import { Flex } from "@gemini-ui/design-system/Flex";
import { CustomStylesConfig } from "@gemini-ui/design-system/forms/Select/constants";
import { FormElementSize } from "@gemini-ui/design-system/forms/shared/constants";
import { FORM_ELEMENT_TRANSITION_VALUE, getFontSize, getSize } from "@gemini-ui/design-system/forms/shared/utils";
import { border, Colors, Spacing } from "@gemini-ui/design-system/primitives";
import { shorthandSpacingCss, Spacer } from "@gemini-ui/design-system/primitives/Spacer";
import { getColor } from "@gemini-ui/design-system/utils";

/**
 * Style getters
 */
function getIconSize(size?: FormElementSize) {
  if (size === "sm") return Spacing.scale[2];
  if (size === "md") return Spacing.scale[2.5];
  if (size === "lg") return Spacing.scale[3];

  return Spacing.scale[2];
}

function getControlBgColor(theme: Theme, isDisabled?: boolean, error?: ReactNode, hasValue?: boolean) {
  if (isDisabled) return theme.colorScheme.input.background.disabled;

  if (Boolean(error))
    return theme.isDark ? theme.colorScheme.action.background.primary.negative.active : Colors.red[50]; //TODO: add Colors.red[50] to colorScheme

  if (hasValue) return "#01030414";

  return theme.colorScheme.input.background.enabled;
}

function getControlHoverBorderColor(theme: Theme, isFocused?: boolean, error?: ReactNode) {
  if (!isFocused && !Boolean(error)) return theme.colorScheme.input.border.hover;

  return "";
}

function getOptionBgColor(isSelected?: boolean, isFocused?: boolean) {
  if (isSelected) return getColor(Colors.gray[100], Colors.gray[600]);
  if (isFocused) return getColor(Colors.gray[50], Colors.gray[700]);
  return getColor(Colors.white, Colors.gray[800]);
}

function getControlBorderColor(theme: Theme, error?: ReactNode, isFocused?: boolean, isDisabled?: boolean) {
  if (error) return theme.colorScheme.status.default.border.negative;
  if (isFocused) return theme.colorScheme.input.border.focus;
  if (isDisabled) return theme.colorScheme.input.border.disabled;

  return theme.colorScheme.input.border.enabled;
}

function getTextStyles(theme: Theme, provided: any = {}, size?: FormElementSize) {
  return {
    ...provided,
    alignItems: "center",
    color: getColor(Colors.black, Colors.white)({ theme }),
    fontSize: getFontSize(size),
    lineHeight: "20px",
  };
}

/**
 * Custom Subcomponents
 */

export const OptionLabel = styled.div`
  display: grid;
  grid-template-areas: "icon content";
  grid-template-columns: min-content 1fr;
`;

export const OptionTextWrapper = styled(Flex)`
  grid-area: content;
`;

export const OptionIconWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: icon;
  margin: 0 ${Spacing.scale[2]} 0 0;
  width: max-content;
  max-width: ${Spacing.scale[4]};
`;

// getProps introduces the CSS variables into the scope of the component
export const SelectWrapper = styled.div<{ size: FormElementSize; width?: string }>`
  width: ${({ width }) => (width ? width : "100%")};

  ${OptionIconWrapper} > svg {
    width: ${({ size }) => (size === "lg" ? Spacing.scale[4] : Spacing.scale[3])};
    height: ${({ size }) => (size === "lg" ? Spacing.scale[4] : Spacing.scale[3])};
  }

  ${shorthandSpacingCss};
`;

export const IndicatorIcon = styled(IconChevronDownSmall)<{
  size: FormElementSize;
  disabled?: boolean;
}>`
  ${({ size }) => `
    width: ${getIconSize(size)};
    height: ${getIconSize(size)};
  `}
  ${({ disabled, theme }) => disabled && `fill: ${getColor(Colors.gray[300], Colors.gray[600])({ theme })};`}
`;

export const SubLabel = styled(Spacer)`
  font-size: 12px;
  line-height: 16px;
  color: ${getColor(Colors.gray[600], Colors.gray[400])};
`;

export const ClearIndicator = styled(IconClearFilled)<{ size: FormElementSize; noMarginRight?: boolean }>`
  border-radius: ${border.radius.full};
  margin-right: ${({ noMarginRight }) => (noMarginRight ? 0 : Spacing.scale[1])};

  height: ${({ size }) => getIconSize(size)};
  width: ${({ size }) => getIconSize(size)};

  path {
    fill: ${({ theme }) => theme.colorScheme.content.secondary};
  }

  &:hover path {
    fill: ${({ theme }) => theme.colorScheme.content.primary};
  }
`;

export const MultiValueRemove = styled(IconClearFilled)`
  height: ${Spacing.scale[2]};
  margin-left: ${Spacing.scale[0.5]};
  width: ${Spacing.scale[2]};

  fill: ${getColor(Colors.gray[600], Colors.gray[400])};
`;

/**
 * react-select styles function
 */

export const selectStyles = (theme: Theme): CustomStylesConfig<any> => ({
  control: (provided, state) => {
    const { isFocused, isDisabled, selectProps } = state;
    const { error, size } = selectProps;

    const borderColor = getControlBorderColor(theme, error, isFocused, isDisabled);

    return {
      ...getTextStyles(theme, provided, size),
      backgroundColor: getControlBgColor(theme, isDisabled, error),
      border: `1px solid ${borderColor}`,
      borderRadius: border.radius.md,
      boxShadow: `${isFocused && `0 0 0 1px ${borderColor}`}`,
      color: theme.colorScheme.content.primary,
      cursor: "pointer",
      fontWeight: "400",
      minHeight: getSize(size),
      transition: FORM_ELEMENT_TRANSITION_VALUE,
      "&:hover": { borderColor: getControlHoverBorderColor(theme, isFocused, error) },
    };
  },
  dropdownIndicator: () => ({
    display: "flex",
    paddingRight: Spacing.scale[2],
  }),
  group: () => ({
    padding: 0,
  }),
  groupHeading: () => ({
    fontSize: "12px",
    fontWeight: 600,
    margin: `${Spacing.scale[2]} ${Spacing.scale[1]} ${Spacing.scale[1.5]}`,
  }),
  indicatorsContainer: (provided, state) => {
    const {
      selectProps: { readOnly },
    } = state;
    return {
      ...provided,
      ...(readOnly && { display: "none" }),
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided, state) => {
    const { selectProps } = state;
    const { size } = selectProps;

    return {
      ...getTextStyles(theme, provided, size),
      display: "inline-grid",
      marginLeft: Spacing.scale[1],
    };
  },
  loadingMessage: (provided, state) => {
    const { selectProps } = state;
    const { size } = selectProps;
    return { ...getTextStyles(theme, provided, size), color: Colors.gray[200] };
  },
  menu: provided => {
    return {
      ...provided,
      backgroundColor: getColor(Colors.white, Colors.gray[800])({ theme }),
      borderRadius: border.radius.md,
      color: getColor(Colors.black, Colors.white)({ theme }),
      marginTop: "4px",
      padding: 0,
    };
  },
  menuList: provided => ({
    ...provided,
    padding: `0 ${Spacing.scale[1]}`,
  }),
  multiValue: provided => {
    return {
      ...provided,
      alignItems: "center",
      backgroundColor: getColor(Colors.gray[100], Colors.gray[800])({ theme }),
      borderRadius: border.radius.full,
      display: "flex",
      fontWeight: 600,
      lineHeight: 1,
      marginLeft: Spacing.scale[0.5],
      padding: Spacing.scale[0.5],
      "&:last-child": { marginLeft: 0 },
    };
  },
  multiValueLabel: (provided, state) => {
    const { isDisabled } = state;
    return {
      ...provided,
      color: isDisabled
        ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
        : getColor(Colors.black, Colors.white)({ theme }),
      lineHeight: 1,
      fontSize: "12px",
    };
  },
  multiValueRemove: (provided, state) => {
    const { isDisabled } = state;
    return {
      ...provided,
      path: { fill: isDisabled && getColor(Colors.gray[300], Colors.gray[600])({ theme }) },
      "&:hover": { background: "none" },
      "&:hover path": { fill: getColor(Colors.black, Colors.white)({ theme }) },
    };
  },
  noOptionsMessage: provided => {
    return {
      ...provided,
      color: getColor(Colors.gray[600], Colors.gray[400])({ theme }),
      padding: Spacing.scale[2],
      textAlign: "left",
    };
  },
  option: (provided, state) => {
    const { isFocused, isSelected, isDisabled, data } = state;

    if (data.isDivider) {
      return {
        margin: `${Spacing.scale[0.5]} 0`,
        borderBottom: `1px solid ${getColor(Colors.gray[200], Colors.gray[700])({ theme })}`,
      };
    }

    return {
      ...getTextStyles(theme, provided),
      background: getOptionBgColor(isSelected, isFocused)({ theme }),
      borderRadius: border.radius.md,
      color: isDisabled
        ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
        : getColor(Colors.black, Colors.white)({ theme }),
      cursor: isDisabled ? "default" : "pointer",
      fontWeight: 400,
      margin: `${Spacing.scale[1]} 0`,
      minHeight: "36px",
      padding: Spacing.scale[1],
      "&:hover": { backgroundColor: getColor(Colors.gray[50], Colors.gray[700])({ theme }) },
    };
  },
  placeholder: (provided, state) => {
    const { isDisabled } = state;
    return {
      ...provided,
      color: isDisabled ? Colors.gray[500] : getColor(Colors.gray[600], Colors.gray[400])({ theme }),
      fontWeight: 400,
      paddingLeft: Spacing.scale[1],
    };
  },
  singleValue: (provided, state) => {
    const { selectProps } = state;
    const { size } = selectProps;

    return {
      ...getTextStyles(theme, provided, size),
      paddingLeft: Spacing.scale[1],
      ".form-sublabel": { display: "none" },
    };
  },
  valueContainer: (provided, state) => {
    const { selectProps } = state;
    const { size } = selectProps;

    return {
      ...provided,
      minWidth: 0, // For firefox flex-shrink bug https://stackoverflow.com/questions/33790219/firefox-not-respecting-flex-shrink
      padding: `0 ${Spacing.scale[0.5]}`,
      rowGap: size === "sm" ? Spacing.scale[0.25] : Spacing.scale[0.5],
    };
  },
});

export const DividerItem = {
  isDisabled: true,
  isDivider: true,
  label: "",
  value: undefined,
  "aria-label": "Menu Item Divider",
};

export const MultiSelectV2CustomSelectStyles = (theme: Theme) => {
  return {
    control: (provided, state) => {
      const { isFocused, isDisabled, selectProps, hasValue } = state;
      const { error, size } = selectProps;

      const borderColor = getControlBorderColor(theme, error, isFocused, isDisabled);
      return {
        ...provided,
        backgroundColor: getControlBgColor(theme, isDisabled, error, hasValue),
        border: `1px solid ${borderColor}`,
        borderRadius: border.radius.full,
        boxShadow: `${isFocused && `0 0 0 1px ${borderColor}`}`,
        color: theme.colorScheme.content.primary,
        cursor: "pointer",
        fontSize: getFontSize(size),
        fontWeight: 600,
        minHeight: getSize(size),
        transition: FORM_ELEMENT_TRANSITION_VALUE,
        "&:hover": {
          borderColor: getControlHoverBorderColor(theme, isFocused, error),
        },
      };
    },
    option: (provided, state) => {
      const { isDisabled, data } = state;

      if (data.isDivider) {
        return {
          margin: `${Spacing.scale[0.5]} 0`,
          borderBottom: `1px solid ${getColor(Colors.gray[200], Colors.gray[700])({ theme })}`,
        };
      }

      return {
        ...getTextStyles(theme, provided),
        background: "inherit",
        borderRadius: border.radius.md,
        color: isDisabled
          ? getColor(Colors.gray[300], Colors.gray[600])({ theme })
          : getColor(Colors.black, Colors.white)({ theme }),
        cursor: isDisabled ? "default" : "pointer",
        fontWeight: 400,
        margin: `${Spacing.scale[1]} 0`,
        minHeight: "36px",
        padding: Spacing.scale[1],
        "&:hover": { backgroundColor: getColor(Colors.gray[50], Colors.gray[700])({ theme }) },
      };
    },
    menu: (provided, { selectProps }) => {
      return {
        ...provided,
        backgroundColor: getColor(Colors.white, Colors.gray[800])({ theme }),
        borderRadius: border.radius.md,
        color: getColor(Colors.black, Colors.white)({ theme }),
        marginTop: "4px",
        padding: `0 ${Spacing.scale[1]}`,
        minWidth: 256,
        ...(selectProps.menuHorizontalPosition === "right" ? { right: 0 } : { left: 0 }),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided, state) => {
      const { isDisabled, selectProps } = state;
      const { size } = selectProps;
      return {
        ...provided,
        color: isDisabled
          ? theme.colorScheme.input.content.placeholder
          : getColor(Colors.gray[900], Colors.gray[400])({ theme }), // TODO: Add dark / light mode placeholder colors
        fontWeight: 600,
        paddingLeft: Spacing.scale[1],
        fontSize: getFontSize(size),
      };
    },
    indicatorsContainer: (provided, state) => {
      const {
        selectProps: { readOnly },
      } = state;
      return {
        ...provided,
        ...(readOnly && { display: "none" }),
      };
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    valueContainer: (provided, state) => {
      const { selectProps } = state;
      const { size } = selectProps;

      return {
        ...provided,
        minWidth: 0, // For firefox flex-shrink bug https://stackoverflow.com/questions/33790219/firefox-not-respecting-flex-shrink
        padding: `0 0 0 ${Spacing.scale[1]}`,
        rowGap: size === "sm" ? Spacing.scale[0.25] : Spacing.scale[0.5],
      };
    },
  };
};
