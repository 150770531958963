export enum LOCKOUT_TYPES {
  CONSENT_WITHDRAWALS_ONLY = "consentWithdrawalsOnly",
  DEPOSIT_AND_CRYPTO_SALES_ONLY = "depositAndCryptoSalesOnly",
  FIAT_WITHDRAWALS_ONLY = "fiatWithdrawalsOnly",
  FROZEN = "frozen",
  GUSD_OPERATING_AREA = "oberonOperatingArea", // Oberon was the previous codename for GUSD
  LOOKING = "looking",
  MANAGE_BANK_ACCOUNTS = "manageBankAccounts", // This isn't actually in the scala file either
  PENDING = "pending",
  SANDBOX = "sandbox",
  SANDBOX_WITH_WALLET = "sandboxWithWallet",
  // Can sell crypto and withdraw fiat
  SCHEDULED_FRAUD_CLOSURE = "scheduledFraudClosure",
  UNVERIFIED = "unverified",
  UNVERIFIED_BASIC = "unverifiedBasic",
  WIRE_DEPOSIT_FOR_UNFREEZE = "WireDepositForUnfreeze",
  WIRE_DEPOSIT_FOR_VERIFICATION = "wireDepositForVerification",
  WITHDRAWALS_ONLY = "withdrawalsOnly",
  ATTESTATION_REQUIRED = "attestationRequired",
}
