import { useMemo } from "react";
import { CurrencyShortNameSupportedCryptos } from "@gemini-common/scripts/constants/currencies";
import { GrowAsset } from "@gemini-ui/constants/earn";
import { border, Select } from "@gemini-ui/design-system";
import { CURRENCY_DETAILS_V2 } from "@gemini-ui/pages/RetailTrade/utils";

export const StakingAssetSelector = ({
  availableStakingAssets,
  selectedCurrency,
  onDidSelectCurrency,
}: {
  availableStakingAssets: GrowAsset[];
  selectedCurrency: CurrencyShortNameSupportedCryptos;
  onDidSelectCurrency: (currency: CurrencyShortNameSupportedCryptos) => void;
}) => {
  const options = useMemo(() => {
    return availableStakingAssets.map(asset => {
      const { name, Icon } = CURRENCY_DETAILS_V2(asset.currency);
      return {
        label: name,
        value: asset.currency,
        icon: <Icon size="sm" />,
      };
    });
  }, [availableStakingAssets]);

  return (
    <Select
      data-testid="grow-buy-staking-asset-select"
      label={null}
      value={selectedCurrency}
      onChange={onDidSelectCurrency}
      options={options}
      isSearchable={false}
      isDisabled={availableStakingAssets.length < 2}
      customSelectStyles={{
        control: provided => ({
          ...provided,
          borderRadius: border.radius.full,
        }),
      }}
    />
  );
};
