import { Component, createRef, ReactNode, RefObject } from "react";
import { isDate } from "date-fns";
import Calendar from "@gemini-ui/components/DatePicker/Calendar";
import {
  CURRENT_DATE,
  END_DATE,
  FocusedInputNames,
  GEMINI_OPEN_DATE,
  START_DATE,
} from "@gemini-ui/components/DatePicker/constants";
import { CalendarContainer, DatePickerInputContainer, DayPickerStyles } from "@gemini-ui/components/DatePicker/styles";
import { formatDate } from "@gemini-ui/components/DatePicker/utils";
import { REGEX } from "@gemini-ui/constants";
import { Input } from "@gemini-ui/design-system/__deprecated__/Input";
import { FormElementMessage } from "@gemini-ui/design-system/formElements";
import { DateFormats } from "@gemini-ui/utils/dateTimeFormats";
import { defineMessage, IntlShape, withIntl } from "@gemini-ui/utils/intl";

interface Props {
  onChange: (state: any) => void;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  showRange?: boolean;
  yearRange?: string[];
  intl: IntlShape;
}
interface State {
  startDate: Date;
  endDate: Date;
  focusedInput?: FocusedInputNames;
  initState: {
    startDate: Date;
    endDate: Date;
  };
}

interface Inputs {
  [START_DATE]: RefObject<HTMLInputElement>;
  [END_DATE]: RefObject<HTMLInputElement>;
}
class DatePicker extends Component<Props, State> {
  inputs: Inputs;

  constructor(props) {
    super(props);
    this.inputs = {
      [START_DATE]: createRef(),
      [END_DATE]: createRef(),
    };

    this.state = {
      startDate: props.defaultStartDate || CURRENT_DATE,
      endDate: props.defaultEndDate || CURRENT_DATE,
      initState: {
        startDate: props.defaultStartDate || CURRENT_DATE,
        endDate: props.defaultEndDate || CURRENT_DATE,
      },
    };
  }

  handleChange = newState => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(newState);
    }

    const { focusedInput } = this.state;
    // Moving cursor to the end
    if (focusedInput && this.inputs[focusedInput]?.current) {
      const input = this.inputs[focusedInput].current;
      input.focus();
      input.selectionStart = input.value.length;
      input.selectionEnd = input.value.length;
    }
  };

  onDateChange =
    dateInput =>
    (date, { disabled = false } = {}) => {
      // Disabled is part of click check
      if (disabled) {
        return;
      }

      // TODO: Potentially split this into two methods
      const storedDate = this.state[dateInput];
      const previousDate = storedDate instanceof Date ? storedDate : new Date();

      const day = previousDate.getDate();
      const month = previousDate.getMonth();
      const year = previousDate.getFullYear();

      // We'll get an individual value from the month / year dropdowns
      if (date.year != null) {
        date = new Date(date.year, month, day);
      }
      if (date.month != null) {
        date = new Date(year, date.month, day);
      }

      const dates = {
        ...this.state,
        [dateInput]: date,
      };

      this.setState(dates, () => this.handleChange(this.state));
    };

  onInputChange = e => {
    const { name, value } = e.target;

    if (value.match(REGEX.ALPHA)) return; // Don't allow letters

    // @ts-expect-error
    this.setState({ [name]: value }, () => {
      const dates = {
        startDate: name === "startDate" ? new Date(value) : new Date(this.state.startDate),
        endDate: name === "endDate" ? new Date(value) : new Date(this.state.endDate),
      };

      this.handleChange(dates);
    });
  };

  isValidDate = (date: Date) =>
    formatDate(date, DateFormats.MonthDayYear).toString() !== "Invalid Date" &&
    isDate(new Date(date)) &&
    (formatDate(date, DateFormats.MonthDayYear).toString().match(/\//g) || []).length === 2;

  handleFocus = e => {
    // Handle Invalid dates to app prevent crash
    if (e.target.name === END_DATE) {
      const startDate = this.state[START_DATE];

      if ((startDate as unknown as string) === "" || !this.isValidDate(startDate)) {
        this.setState({ [START_DATE]: new Date(this.state.initState.startDate) });
      }
    }

    if (e.target.name === START_DATE) {
      const endDate = this.state[END_DATE];

      if ((endDate as unknown as string) === "" || !this.isValidDate(endDate)) {
        this.setState({ [END_DATE]: new Date(this.state.initState.endDate) });
      }
    }

    this.setState({ focusedInput: e.target.name });
  };

  render() {
    const { showRange, yearRange, intl } = this.props;
    const { startDate, endDate, focusedInput } = this.state;
    const start = new Date(startDate);
    const end = new Date(endDate);

    const isError = start > end;

    const onStartChange = this.onDateChange(START_DATE);
    const onStartDayChange = this.onDateChange(START_DATE);
    const onEndChange = this.onDateChange(END_DATE);
    const onEndDayChange = this.onDateChange(END_DATE);

    const startValue = formatDate(startDate, DateFormats.MonthDayYear);
    const endValue = formatDate(endDate, DateFormats.MonthDayYear);

    const selectedRange = showRange
      ? {
          after: start,
          before: end,
        }
      : null;

    const highlighted = {
      from: start,
      to: end,
    };

    return (
      <DayPickerStyles>
        <DatePickerInputContainer focusedInput={focusedInput}>
          <Input
            label={intl.formatMessage({
              defaultMessage: "From",
              description: "Used for date ranges, from - to",
            })}
            data-testid="dateInput-from"
            id="dateInput-from"
            name={START_DATE}
            value={startValue.toString()}
            onChange={this.onInputChange}
            error={isError}
            onFocus={this.handleFocus}
            ref={this.inputs[START_DATE]}
          />
          <Input
            label={intl.formatMessage({
              defaultMessage: "To",
              description: "Used for date ranges, from - to",
            })}
            data-testid="dateInput-to"
            id="dateInput-to"
            name={END_DATE}
            value={endValue.toString()}
            onChange={this.onInputChange}
            error={isError}
            onFocus={this.handleFocus}
            ref={this.inputs[END_DATE]}
          />
        </DatePickerInputContainer>
        {isError && (
          <FormElementMessage
            dataTestId="date-picker-error"
            error
            message={
              <span>
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage: "<b>FROM</b> date must be before <b>TO</b> date",
                  }),
                  {
                    b: (v: ReactNode) => <b>{v}</b>,
                  }
                )}
              </span>
            }
          />
        )}
        <CalendarContainer isOpen={Boolean(focusedInput)}>
          {focusedInput === START_DATE && (
            <Calendar
              className="calendar-from"
              month={start}
              startDate={GEMINI_OPEN_DATE}
              endDate={end}
              selectedRange={selectedRange}
              // @ts-expect-error
              highlighted={highlighted}
              onDayClick={onStartDayChange}
              onYearMonthChange={onStartChange}
              yearRange={yearRange}
            />
          )}
          {focusedInput === END_DATE && (
            <Calendar
              className="calendar-to"
              month={end}
              startDate={start}
              endDate={CURRENT_DATE}
              selectedRange={selectedRange}
              // @ts-expect-error
              highlighted={highlighted}
              onDayClick={onEndDayChange}
              onYearMonthChange={onEndChange}
              yearRange={yearRange}
            />
          )}
        </CalendarContainer>
      </DayPickerStyles>
    );
  }
}

export default withIntl(DatePicker);
