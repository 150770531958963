import { FC, PropsWithChildren } from "react";
import { IconChevronRightSmall } from "@hubble/icons";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { Money } from "@gemini-ui/components/Money";
import { PaymentMethodItem } from "@gemini-ui/components/PaymentMethodItem";
import { PaymentMethodName } from "@gemini-ui/components/PaymentMethodName";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { PaymentMethodButton } from "@gemini-ui/pages/RetailTrade/PaymentMethod/styles";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { DepositLimits } from "@gemini-ui/services/transfer/types";
import { BankType } from "@gemini-ui/transformers/PaymentMethods";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import listify from "@gemini-ui/utils/listify";

interface BankMethodProps {
  limits: DepositLimits;
  onClick: () => void;
  selectedBank: BankType;
}
const BankMethod: FC<PropsWithChildren<BankMethodProps>> = props => {
  const { intl } = useIntl();
  const {
    limits,
    onClick,
    selectedBank: { institutionName, accountType, displayName, lastFour, currencies },
  } = props;
  const {
    ach: { daily },
  } = limits;

  const hasLimit = daily?.total && Number(daily.total.value) > 0;
  const infoText = hasLimit
    ? intl.formatMessage(
        defineMessage({
          defaultMessage: "<RemainingLimit></RemainingLimit> limit",
        }),
        {
          RemainingLimit: () => <Money {...daily.available} hideTrailingSign />,
        }
      )
    : listify(currencies, "", false);

  return (
    <PaymentMethodButton
      data-testid={testIds.buySell.paymentMethodBtn}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <PaymentMethodItem
        icon={
          <PaymentMethodIcon institutionName={institutionName} paymentMethodType={PaymentMethodType.BANK} size="md" />
        }
        displayText={
          <PaymentMethodName
            rawName={displayName}
            accountType={accountType}
            institutionName={institutionName}
            lastFour={lastFour}
          />
        }
        infoText={infoText}
        rightIcon={<IconChevronRightSmall />}
      />
    </PaymentMethodButton>
  );
};

export default BankMethod;
