import { useImperativeHandle, useRef } from "react";
import * as React from "react";
import { IconArrowsVertical } from "@hubble/icons";
import BigNumber from "bignumber.js";
import {
  bigNumberToDecimalString,
  CURRENCIES_DETAIL,
  CurrencyShortName,
} from "@gemini-common/scripts/constants/currencies";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Button, Flex, Input, Text } from "@gemini-ui/design-system";
import { setReactInputValue } from "@gemini-ui/design-system/__deprecated__/Input/utils";
import { InputProps } from "@gemini-ui/design-system/forms/Input/constants";
import { getComponentCopy } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { Action, BuyingFrequency } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { testIds } from "@gemini-ui/pages/RetailTrade/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

interface AmountInputProps extends InputProps {
  action?: Action;
  onButtonClick?: (e) => void;
  currency: CurrencyShortName;
  selectedCurrency?: CurrencyShortName;
  buttonLabel?: React.ReactNode;
  maxValue?: React.ReactText;
  isLimitOrder?: boolean;
  isAmountDenotedInPriceCurrency?: boolean;
  setIsAmountDenotedInPriceCurrency?: () => void;
  schedule?: BuyingFrequency;
}

export const InputAmount = React.forwardRef<HTMLInputElement, AmountInputProps>(
  (
    {
      action,
      buttonLabel,
      className,
      currency,
      selectedCurrency,
      "data-testid": dataTestId,
      label,
      inputSize = "md",
      maxValue,
      message,
      onButtonClick,
      isLimitOrder,
      isAmountDenotedInPriceCurrency = true,
      setIsAmountDenotedInPriceCurrency,
      schedule,
      ...props
    },
    forwardRef
  ) => {
    const { intl } = useIntl();

    const isCurrencySwitcherEnabled = optimizelyClient.isFeatureEnabled(
      OPTIMIZELY_FEATURE_FLAGS.WEB_RETAIL_CURRENCY_SWITCHER
    );

    const isBuyOrSell = action === Action.BUY || action === Action.SELL;
    const instantOrder = schedule === BuyingFrequency.Once;
    const showCurrencySwitcher = isLimitOrder || (isBuyOrSell && isCurrencySwitcherEnabled && instantOrder);

    const inputRef = useRef(null);
    const copy = getComponentCopy(intl, currency, selectedCurrency);

    let leadingSymbol =
      Boolean(currency) && CURRENCIES_DETAIL[currency]?.isNotional && CURRENCIES_DETAIL[currency].leadingSymbol;

    if (showCurrencySwitcher && !isAmountDenotedInPriceCurrency) leadingSymbol = selectedCurrency;

    useImperativeHandle(forwardRef, () => inputRef.current);

    return (
      <Input
        autoComplete="off"
        data-testid={dataTestId}
        inputSize={inputSize}
        message={message}
        ref={inputRef}
        step="any"
        type="number"
        numeric
        label={label || copy.AMOUNT_LABEL}
        leftElement={
          Boolean(leadingSymbol) &&
          isAmountDenotedInPriceCurrency && <Text.Body data-testid={`${dataTestId}-symbol`}>{leadingSymbol}</Text.Body>
        }
        rightElement={
          showCurrencySwitcher ? (
            <Flex alignItems="center">
              {Boolean(leadingSymbol) && !isAmountDenotedInPriceCurrency && (
                <Text.Body mr={0.5} data-testid={`${dataTestId}-symbol`}>
                  {leadingSymbol}
                </Text.Body>
              )}
              <Button.Tertiary
                aria-label={isAmountDenotedInPriceCurrency ? copy.ENTER_AS_CRYPTO : copy.ENTER_AS_CURRENCY}
                data-testid={testIds.buySell.currencySwitcherIconBtn}
                icon={<IconArrowsVertical />}
                onClick={setIsAmountDenotedInPriceCurrency}
              />
            </Flex>
          ) : (
            <Text.Body size="sm" data-testid={`${dataTestId}-currency`}>
              {currency}
            </Text.Body>
          )
        }
        rightOuterElement={
          (Boolean(maxValue) || Boolean(onButtonClick)) && (
            <Button.Secondary
              data-testid={`${dataTestId}-max-button`}
              size={inputSize}
              onClick={e => {
                if (maxValue) {
                  // Set max value based on currency precision. Then fire onChange.
                  const val = bigNumberToDecimalString(new BigNumber(maxValue), currency);
                  setReactInputValue(inputRef.current, val);
                  inputRef.current.focus();
                }
                onButtonClick && onButtonClick(e);
              }}
              ml={2}
            >
              {buttonLabel ||
                intl.formatMessage({
                  defaultMessage: "Max",
                  description: "The maximum amount of a currency to populate the input value with.",
                })}
            </Button.Secondary>
          )
        }
        {...props}
      />
    );
  }
);
