import { Fragment } from "react";
import { CurrencyShortName, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { PaymentMethodIcon } from "@gemini-ui/components/Icons/PaymentMethod";
import { TruncatedText } from "@gemini-ui/components/PaymentMethodDisplay/styles";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { Flex, Text } from "@gemini-ui/design-system";
import { TradePaymentMethodType } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AccountType } from "@gemini-ui/pages/RetailTrade/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface PaymentMethodDisplayProps {
  defaultFiat?: CurrencyShortNameFiat | CurrencyShortName;
  selectedPaymentMethod: TradePaymentMethodType;
}

export function PaymentMethodDisplay({ defaultFiat, selectedPaymentMethod }: PaymentMethodDisplayProps) {
  const { intl } = useIntl();

  if (selectedPaymentMethod === AccountType.BALANCE) {
    return (
      <Flex flexDirection="row" align="center">
        <Text.Body size="sm" mr={1.5}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "{defaultFiat} balance",
              description: "To indicate the selected payment method, e.g. USD balance",
            }),
            {
              defaultFiat,
            }
          )}
        </Text.Body>
        <PaymentMethodIcon defaultFiat={defaultFiat} paymentMethodType={AccountType.BALANCE} size="sm" />
      </Flex>
    );
  }

  const { displayName, institutionName, lastFour } = selectedPaymentMethod;

  return (
    <Flex flexDirection="row" align="center">
      {[PaymentMethodType.BANK, PaymentMethodType.DEBIT_CARD].includes(selectedPaymentMethod.paymentMethodType) && (
        <Fragment>
          <Text.Body size="sm" mr={lastFour ? 0.5 : 1.5}>
            &bull;&bull;&bull;&bull;
          </Text.Body>

          {lastFour && (
            <Text.Body size="sm" mr={1.5}>
              {lastFour}
            </Text.Body>
          )}
        </Fragment>
      )}

      {selectedPaymentMethod.paymentMethodType === PaymentMethodType.PAYPAL && (
        <TruncatedText size="sm" mr={1.5}>
          {displayName}
        </TruncatedText>
      )}

      <PaymentMethodIcon
        institutionName={institutionName}
        paymentMethodType={selectedPaymentMethod.paymentMethodType}
        size="sm"
      />
    </Flex>
  );
}
