import { FC, PropsWithChildren } from "react";
import cx from "classnames";

export interface HeaderWrapperProps {
  center?: boolean;
  className?: string;
}

export const HeaderWrapper: FC<PropsWithChildren<HeaderWrapperProps>> = props => {
  const classNames = cx("HeaderOuter", props.className, {
    Center: props.center,
  });
  return (
    <div className={classNames} data-testid="HeaderWrapper">
      {props.children}
    </div>
  );
};

export default HeaderWrapper;
